import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useMemo } from 'react'

export const useQuoteEditorLayout = () => {
  const cubeContext = useCubeContext()

  const ready = useMemo(() => {
    return (
      cubeContext.queries.rawData.editor.loaded &&
      cubeContext.queries.rawData.data.common.id !== 'uninitialized'
    )
  }, [cubeContext])

  return {
    ready,
    isATemplate: cubeContext.queries.quote.isATemplate
  }
}
