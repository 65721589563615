import { Box } from '@chakra-ui/react'
import {
  Badge,
  KebabMenu,
  KebabMenuItem,
  MenuItemBuilder
} from '@sequencehq/core-components'
import { toInvoiceStatusBadgeProps, toMoney } from '@sequencehq/core-models'
import { ColumnConfig } from '@sequencehq/tables'
import { openOverlay } from 'features/overlay'
import { flow } from 'fp-ts/lib/function'
import { localDateWithFormat, noWrap } from '@sequencehq/formatters'
import { AppDispatch } from 'features/store'
import { DownloadCreditNotePdfProps } from './useCreditNotePdfDownload'
import { CreditNote } from 'CreditNotes/types'
import { formatDateRange } from '@sequencehq/utils/dist/dates'
import { RedRed60 } from '@sequencehq/design-tokens'

const finalizeAndSendBuilder: MenuItemBuilder<CreditNote> = (
  creditNote: CreditNote,
  options
) => {
  return {
    status: creditNote.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Send Credit Note',
    testId: `creditNotes.${creditNote.id}.menu.finalizeAndSend`,
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeAndSendCreditNoteModal',
          data: {
            id: creditNote.id,
            customerName: creditNote.customerLegalCompanyName,
            customerEmails: creditNote.customerEmails,
            onSuccess: options?.onSuccess
          }
        })
      )
  }
}

const finalizeBuilder: MenuItemBuilder<CreditNote> = (
  creditNote: CreditNote,
  options
) => {
  return {
    testId: `creditNotes.${creditNote.id}.menu.finalize`,
    status: creditNote.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Finalize Credit Note',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeCreditNoteModal',
          data: {
            id: creditNote.id,
            customerName: creditNote.customerLegalCompanyName,
            onSuccess: options?.onSuccess
          }
        })
      )
  }
}

const sendBuilder: MenuItemBuilder<CreditNote> = (
  creditNote: CreditNote,
  options
) => {
  return {
    status: creditNote.status === 'FINAL' ? 'LIVE' : 'DISABLED',
    testId: `creditNotes.${creditNote.id}.menu.send`,
    label: 'Send Credit Note',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'sendCreditNoteModal',
          data: {
            id: creditNote.id,
            customerName: creditNote.customerLegalCompanyName,
            customerEmails: creditNote.customerEmails,
            onSuccess: options?.onSuccess
          }
        })
      )
  }
}

const previewBuilder: MenuItemBuilder<CreditNote> = (
  creditNote: CreditNote
) => {
  return {
    status: 'LIVE',
    action: ({ navigate }) => navigate(`/credit-notes/${creditNote.id}`),
    label:
      creditNote.status === 'DRAFT'
        ? 'View/Edit Credit Note'
        : 'View Credit Note'
  }
}

const voidBuilder: MenuItemBuilder<CreditNote> = (
  creditNote: CreditNote,
  options
) => {
  return {
    status:
      creditNote.status === 'DRAFT' || creditNote.status === 'FINAL'
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'voidCreditNoteModal',
          data: {
            id: creditNote.id,
            customerName: creditNote.customerLegalCompanyName,
            onSuccess: options?.onSuccess
          }
        })
      ),
    label: (
      <KebabMenuItem>
        <Box color={RedRed60}>Void Credit Note</Box>
      </KebabMenuItem>
    ),
    testId: `creditNotes.${creditNote.id}.menu.void`
  }
}

const downloadBuilder: (
  downloadPdf: (props: DownloadCreditNotePdfProps) => void
) => MenuItemBuilder<CreditNote> = download => (creditNote: CreditNote) => ({
  status: 'LIVE',
  action: () => {
    download({
      creditNoteId: creditNote.id,
      creditNoteNumber: creditNote.creditNoteNumber,
      creditNoteStatus: creditNote.status
    })
  },
  label: 'Download Credit Note as PDF'
})

export const menuItemBuilders = (
  downloadPdf: (props: DownloadCreditNotePdfProps) => void
) => [
  previewBuilder,
  finalizeAndSendBuilder,
  finalizeBuilder,
  sendBuilder,
  voidBuilder,
  downloadBuilder(downloadPdf)
]

export const creditNotesTableConfig = (
  _dispatch: AppDispatch,
  flags: Record<string, boolean>,
  downloadPdf: (props: DownloadCreditNotePdfProps) => void
) => {
  const columns: ColumnConfig<CreditNote>[] = [
    {
      heading: 'Amount',
      formatter: flow(
        ({ grossTotal, currency }) => ({
          currency: currency,
          value: grossTotal
        }),
        toMoney,
        noWrap
      ),
      format: 'number',
      width: '95px'
    },
    {
      heading: 'Status',
      formatter: flow(({ status }) => (
        <Badge {...toInvoiceStatusBadgeProps({ status })} />
      )),
      width: '126px'
    },
    {
      heading: 'Credit Note #',
      formatter: flow(({ creditNoteNumber }) => creditNoteNumber || 'Pending'),
      width: '166px'
    },
    {
      heading: 'Customer',
      formatter: flow(
        ({ customerLegalCompanyName }) => customerLegalCompanyName
      ),
      minWidth: '200px',
      width: '200px',
      maxWidth: '391px'
    },
    {
      heading: 'Billing Period',
      formatter: flow(({ billingPeriodStart, billingPeriodEnd }) =>
        billingPeriodStart && billingPeriodEnd
          ? formatDateRange({
              from: new Date(billingPeriodStart),
              to: new Date(billingPeriodEnd)
            })
          : ''
      ),
      width: '175px'
    },
    {
      heading: 'Sent',
      formatter: flow(
        ({ issueDate }) => localDateWithFormat(issueDate, 'd MMM') || '',
        noWrap
      ),
      width: '73px'
    }
  ]

  columns.push({
    width: '56px',
    sticky: true,
    contentTextAlign: 'center',
    action: true,
    formatter: flow(
      model =>
        menuItemBuilders(downloadPdf)
          .map(builder => builder(model))
          .filter(builder => builder.status === 'LIVE'),
      items => <KebabMenu menuItems={items} flags={flags} />
    )
  })

  return {
    columns,
    rowPath: (creditNote: CreditNote) => `/credit-notes/${creditNote.id}`
  }
}
