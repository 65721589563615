import { Flex, Spinner, Center } from '@chakra-ui/react'

import { GreyGrey50 } from '@sequencehq/design-tokens'

import { useNavigationV3Context } from 'components/NavigationV3/hooks/useNavigationV3Context'
import { MainMenu } from 'components/NavigationV3/components/Header/MainMenu'
import { AddMenu } from 'components/NavigationV3/components/Header/AddMenu'

export const Header = () => {
  const { data } = useNavigationV3Context()
  const { isNavigationCollapsed } = data

  return (
    <Flex
      px="8px"
      py="10px"
      justifyContent={'flex-start'}
      alignItems="center"
      width="100%"
      gap="8px"
    >
      {data.isSwitchingWorkspace ? (
        <Center height="32px">
          <Spinner boxSize="16px" color={GreyGrey50} />
        </Center>
      ) : (
        <MainMenu isCollapsed={isNavigationCollapsed} />
      )}

      {!isNavigationCollapsed && <Flex flex="1" />}
      {!isNavigationCollapsed && <AddMenu />}
    </Flex>
  )
}
