import { Box } from '@chakra-ui/react'
import { Button, StandardLayout } from '@sequencehq/core-components'
import { useSelectedCurrency } from '../utils/useSelectedCurrency'
import { ExportCSVModal } from '../common/ExportCSVModal'
import { ArrowDownTrayIcon } from '@heroicons/react/16/solid'
import { useSelectedDateRange } from '../utils/useSelectedDateRange'

export const DeferredRevenueDashboardHeader = () => {
  const { currency } = useSelectedCurrency()
  const { dateRange } = useSelectedDateRange()

  return (
    <Box>
      <StandardLayout.Header
        title="Deferred revenue"
        actions={
          !!currency && (
            <ExportCSVModal
              from={dateRange[0]}
              to={dateRange[1]}
              currency={currency}
              trigger={
                <Button
                  variant="secondary"
                  leadingIcon={<ArrowDownTrayIcon width="16px" />}
                  data-testid="deferred-revenue.detail.exportCSV"
                >
                  Export to CSV
                </Button>
              }
            />
          )
        }
      />
    </Box>
  )
}
