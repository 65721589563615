import { FC, ReactNode } from 'react'
import { SparklesIcon } from '@heroicons/react/24/solid'
import { Box, BoxProps } from '@chakra-ui/react'
import { GreyGrey50, IndigoIndigo50 } from '@sequencehq/design-tokens'
import { useCurrentContractMetadata } from 'ContractExtraction/hooks/useContractMetadata'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isMatchedFieldPath } from 'ContractExtraction/utils/isMatchedFieldPath'
import { match } from 'ts-pattern'
import { visiblePreviewFieldPathsAtom } from 'ContractExtraction/atoms'
import { useAtom } from 'jotai'

type ExtractedDataIndicatorPosition = 'top-right' | 'inline'

interface ExtractedDataIndicatorProps {
  fields: string[]
  children?: ReactNode
  position: ExtractedDataIndicatorPosition
}

export const ExtractedDataIndicator: FC<ExtractedDataIndicatorProps> = ({
  fields,
  children,
  position
}) => {
  const [, setVisiblePreviewFieldPaths] = useAtom(visiblePreviewFieldPathsAtom)

  const flags = useFlags()

  const fieldsMetadata = useCurrentContractMetadata()

  if (!flags.useContractIntakePrototype || !fieldsMetadata) {
    return children
  }

  const matchingFieldPaths = fieldsMetadata
    .map(({ fieldPath }) => fieldPath)
    .filter(fieldPath =>
      fields.some(selectedField =>
        isMatchedFieldPath({ fieldPath, pattern: selectedField })
      )
    )

  if (!matchingFieldPaths.length) {
    return children
  }

  const wrapperStyle = match<ExtractedDataIndicatorPosition, BoxProps>(position)
    .with('top-right', () => ({
      position: 'relative'
    }))
    .with('inline', () => ({
      display: 'flex',
      gap: '4px',
      alignItems: 'center'
    }))
    .exhaustive()

  const indicatorStyle = match<ExtractedDataIndicatorPosition, BoxProps>(
    position
  )
    .with('top-right', () => ({
      position: 'absolute',
      top: 0,
      right: 0
    }))
    .with('inline', () => ({
      pointerEvents: 'all'
    }))
    .exhaustive()

  return (
    <Box
      {...wrapperStyle}
      data-testid={`extracted-data-indicator-${fields.join(',')}`}
    >
      {children}
      <Box
        {...indicatorStyle}
        color={GreyGrey50}
        _hover={{ color: IndigoIndigo50 }}
        transition="color 300ms"
        onMouseOver={() => {
          setVisiblePreviewFieldPaths(matchingFieldPaths)
        }}
        onMouseOut={() => {
          setVisiblePreviewFieldPaths(undefined)
        }}
      >
        <SparklesIcon
          width={16}
          height={16}
          color="inherit"
          pointerEvents="none"
        />
      </Box>
    </Box>
  )
}
