import {
  Text,
  Button,
  Center,
  Box,
  Grid,
  GridItem,
  Tooltip
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import {
  GreyGrey30,
  GreyGrey80,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'

import { SubNavLink as SubNavLinkProps } from 'components/NavigationV3/types'

type Props = SubNavLinkProps & {
  subLinks: SubNavLinkProps[]
  index: number
  pathname: string
  isCollapsed?: boolean
}

export const SubNavLink = ({
  href,
  label,
  enabled = true,
  subLinks,
  index,
  pathname,
  isCollapsed = false
}: Props) => {
  if (!enabled) {
    return null
  }

  const isActive: boolean = pathname.startsWith(href)

  const isFirst: boolean = index === 0
  const isLast: boolean = index === subLinks.length - 1
  const isMiddle: boolean = !isFirst && !isLast

  const linkTextStyle = isActive ? Lato13Bold : Lato13Regular

  // If collapsed, don't render sub-navigation items
  if (isCollapsed) {
    return null
  }

  return (
    <Grid
      as={Link}
      to={href}
      templateColumns="32px 1fr"
      height="32px"
      alignContent="center"
      cursor="pointer"
      color={GreyGrey80}
      backgroundColor="transparent"
    >
      <GridItem height="32px" position="relative">
        <Center height="100%" width="100%">
          {isFirst && (
            <Box
              position="absolute"
              height="50%"
              top="50%"
              width="1px"
              bg={GreyGrey30}
            />
          )}

          {isMiddle && (
            <Box
              position="absolute"
              top="0"
              height="100%"
              width="1px"
              bg={GreyGrey30}
            />
          )}

          {isLast && (
            <Box
              position="absolute"
              height="50%"
              top="0"
              width="1px"
              bg={GreyGrey30}
            />
          )}

          <Box
            position="relative"
            height="16px"
            width="1px"
            bg={isActive ? GreyGrey90 : GreyGrey30}
          />
        </Center>
      </GridItem>
      <GridItem
        {...linkTextStyle}
        color="inherit"
        display="flex"
        alignItems="center"
      >
        {label}
      </GridItem>
    </Grid>
  )
}
