import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { ComponentProps, useEffect, useState } from 'react'
import { useCustomer } from '../../../hooks/useCustomer'
import { TaxRatesModalUI } from 'Settings/view/taxRatesSettings/modals/TaxRatesModal'
import { CustomerContactsContent } from './CustomerContactsContent'
import { match } from 'ts-pattern'
import { useCreateTaxRate } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxRate'
import { ValidationItem } from 'common/components/SteppedModal/useSteppedModal'
import { countriesAlpha2 } from '@sequencehq/api/dist/utils/commonEnums'
import { useQuery } from '@sequencehq/api/dist/utils'
import { useTaxIntegrationProvider } from 'lib/hooks/useTaxIntegrationProvider.ts'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { BillingScheduleContextData } from 'Cube/domain'
import { CubeStatus } from 'Cube/domain/cube.domain.types'
import { createBaseBillingSchedulePhasesApiData } from 'Cube/communication/external/billingSchedule.api.v1/adapters/billingSchedule.adapters.phases.out'
import {
  v1ApiNewBillingSchedule,
  v1ApiUpdateBillingSchedule
} from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSaveBillingSchedule'
import {
  parseValidationResult,
  processValidationErrors
} from 'Cube/utils/validation'
import { CountriesAlpha2 } from '@sequencehq/api/commonEnums'
import { TaxRate } from 'Settings/domain/taxRates.types.ts'

const baseContactValidation = {
  stepName: 'Primary billing contact',
  stepKey: 'CONTACTS',
  component: CustomerContactsContent
}

const baseTaxRatesValidation = {
  stepKey: 'TAX_RATES',
  stepName: 'Tax rates',
  component: TaxRatesModalUI
}

const baseValidation = {
  contacts: baseContactValidation,
  taxRates: baseTaxRatesValidation
}

const getNextStep = (key: string, allKeys: string[]) =>
  match(key)
    .with('CONTACTS', () => 'CONFIRM')
    .with('TAX_RATES', () =>
      allKeys.includes('contacts') ? 'CONTACTS' : 'CONFIRM'
    )
    .otherwise(() => 'CONFIRM')

const isUpdate = (
  billingScheduleData: v1ApiUpdateBillingSchedule | v1ApiNewBillingSchedule
): billingScheduleData is v1ApiUpdateBillingSchedule =>
  !('customerId' in billingScheduleData)

const buildValidationRequest = (
  billingScheduleQueries: BillingScheduleContextData['queries']
) => {
  const baseValidationData = createBaseBillingSchedulePhasesApiData(
    billingScheduleQueries
  ).billingSchedule

  const requestData: Omit<
    DashboardApi20240730.PostBillingScheduleValidateTaxes.PostBillingScheduleValidateTaxesBody,
    'isDraft'
  > = isUpdate(baseValidationData)
    ? {
        ...baseValidationData,
        customerId: billingScheduleQueries.rawData.data.common.customerId
      }
    : baseValidationData

  return requestData
}

export const useSteppedConfirmStartModal = (props: {
  onClose: () => void
  billingScheduleQueries: BillingScheduleContextData['queries']
}) => {
  const { billingScheduleQueries, onClose } = props
  const [validation, setValidation] = useState<ValidationItem[] | null>(null)
  const customer = useCustomer()
  const { address, contacts, id: customerId } = customer ?? {}
  const { data: taxRatesData, isLoading: taxRatesLoading } = useQuery(
    dashboardv99990101Client.getTaxRates
  )
  const { isAvalara } = useTaxIntegrationProvider()

  const validationRequestData = buildValidationRequest(billingScheduleQueries)
  const validationResponse = useQuery(
    dashboard20240730Client.postBillingScheduleValidateTaxes,
    {
      body: {
        ...validationRequestData,
        isDraft:
          billingScheduleQueries.rawData.data.common.status ===
          CubeStatus.ScheduleDraft
      }
    },
    {
      retry: false
    }
  )

  const { validating, hasValidationError, validationErrors } =
    parseValidationResult(validationResponse)

  useEffect(() => {
    if (
      taxRatesLoading ||
      validating ||
      validation !== null ||
      !customerId ||
      !address
    ) {
      return
    }

    setValidation(
      Object.entries(validData).reduce((acc, [key, value]) => {
        if (value === true) {
          return acc
        }

        const componentProps = match(key)
          .with(
            'contacts',
            (): ComponentProps<typeof CustomerContactsContent> => ({
              customerId,
              onClose: onClose,
              onSubmit: () => Promise.resolve()
            })
          )
          .with(
            'taxRates',
            (): ComponentProps<typeof TaxRatesModalUI> => ({
              countriesWithTaxRates: {
                [address.country]: taxRates.filter(
                  taxRate => taxRate.country === address.country
                )
              } as Record<CountriesAlpha2, TaxRate[]>,
              preselectedCountry: address.country,
              preselectedState: 'state' in address ? address.state : undefined,
              onSubmit: async values => {
                await createTaxRate(values)
              },
              options: {
                closeOnCancel: false,
                closeOnSubmit: false,
                submitText: validData.contacts
                  ? 'Save tax rates'
                  : 'Save and continue',
                onCancel: onClose,
                onClose: onClose
              },
              filterTaxCategories: taxCategories =>
                taxCategories.filter(({ id }) =>
                  missingRatesForCategories.includes(id)
                )
            })
          )
          .otherwise(() => ({}))

        const validationMessage = match(key as keyof typeof validData)
          .with('contacts', () => 'Primary billing contact')
          .with(
            'taxRates',
            () => `Tax rates for ${countriesAlpha2[address.country]}`
          )
          .exhaustive()

        return [
          ...acc,
          {
            ...baseValidation[key as keyof typeof baseValidation],
            message: validationMessage,
            nextStep: getNextStep(key, Object.keys(validData)),
            componentProps
          } as ValidationItem
        ]
      }, [] as ValidationItem[])
    )
  }, [taxRatesLoading, address, customerId, onClose, validating, validation])

  const createTaxRate = useCreateTaxRate({ invalidateQueries: false })

  const taxRates = taxRatesData?.items ?? []
  const missingRatesForCategories = processValidationErrors(validationErrors)
  const hasTaxCategoryError = missingRatesForCategories.length > 0

  const taxRatesValid =
    !hasValidationError || (hasValidationError && !hasTaxCategoryError)

  const validData = {
    taxRates: taxRatesValid,
    contacts: Boolean(contacts?.length)
  }

  const showAvalaraBlocker = isAvalara && validData.taxRates === false

  return { loading: taxRatesLoading, validation, showAvalaraBlocker }
}
