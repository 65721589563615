import { Box } from '@chakra-ui/react'
import { StandardLayout } from '@sequencehq/core-components'

export const DeferredRevenueHeader = () => {
  return (
    <Box>
      <StandardLayout.Header title="Deferred revenue" />
    </Box>
  )
}
