import {
  BillingFrequency,
  SeatOveragesBillingFrequency
} from 'common/drawers/PricingEditor/domain'

export const overagesFrequenciesOptions: {
  value: SeatOveragesBillingFrequency
  label: string
  description: string
}[] = [
  {
    value: 'MONTHLY',
    label: 'Monthly',
    description:
      'Overages will be charged at the end of every month where seats are added'
  },
  {
    value: 'QUARTERLY',
    label: 'Quarterly',
    description:
      'Overages will be charged at the end of every quarter where seats are added'
  },
  {
    value: 'HALF_YEARLY',
    label: 'Semi-annually',
    description:
      'Overages will be charged at the end of every half year where seats are added'
  },
  {
    value: 'YEARLY',
    label: 'Annually',
    description:
      'Overages will be charged at the end of every year where seats are added'
  }
]

export const frequencyOrder: Record<BillingFrequency, number> = {
  MONTHLY: 1,
  QUARTERLY: 2,
  HALF_YEARLY: 3,
  YEARLY: 4,
  ONE_TIME: 0,
  ON_DEMAND: 0
}

export const getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency = (
  selectedPriceFrequency: BillingFrequency
) => {
  if (selectedPriceFrequency === 'MONTHLY') {
    return []
  }

  return overagesFrequenciesOptions.filter(({ value }) => {
    const overageFrequency = value as BillingFrequency
    return (
      frequencyOrder[overageFrequency] <= frequencyOrder[selectedPriceFrequency]
    )
  })
}
