import { IntegrationConfig } from 'modules/Integrations/domain'
import salesforceLogo from './salesforceLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { SalesforceIntegrationDetail } from './SalesforceIntegrationDetail/SalesforceIntegrationDetail'

export const salesforceIntegrationConfig: IntegrationConfig = {
  logoUrl: salesforceLogo,
  smallLogoUrl: salesforceLogo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/salesforce',
  title: 'Salesforce',
  features: {
    canDeleteLinks: false,
    canUninstall: true,
    isDocumentationOnly: false
  },
  description:
    'Sync deal and customer records from Salesforce. Install the app to enrich accounts and opportunities with Sequence data.',
  components: {
    detail: SalesforceIntegrationDetail,
    management: NoConfiguration
  }
}
