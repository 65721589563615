import { Box, Center, Flex, Text } from '@chakra-ui/react'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import {
  KebabMenu,
  HorizontalSelectorField,
  TextField,
  SelectField
} from '@sequencehq/core-components'
import { GreyGrey20, GreyGrey90, Lato14Bold } from '@sequencehq/design-tokens'
import { FixedTierTableInput } from '../common/inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from '../common/inputs/PercentageTierTableInput/PercentageTierTableInput'
import { PercentageTierWithLimitsTableInput } from '../common/inputs/PercentageTierWithLimitsTableInput/PercentageTierWithLimitsTableInput'
import { useVolumePriceForm } from './useVolumePriceForm'
import { PricingModelField } from '../common/PricingModelField'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { UsageTierType } from 'common/drawers/PricingEditor/domain'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { kebabMenuItems } from './volumePrice.utils'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { UsageMetricField } from '../common/UsageMetrics/UsageMetricField'
import { BillingFrequencyField } from '../common/BillingFrequencyField'

export const VolumePriceForm = () => {
  const { fieldsConfig, currency } = useVolumePriceForm()

  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingModelField />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="volume.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <Box paddingBottom="8px">
        <BillingFrequencyField
          data-testid="volume.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={fieldsConfig.billingFrequency.onChange}
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <UsageMetricField
          data-testid="volume.usageMetric"
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
        <HorizontalSelectorField
          label="Type"
          value={fieldsConfig.usageTierType.value}
          options={fieldsConfig.usageTierType.options}
          onChange={newValue =>
            fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
          }
          validationErrors={fieldsConfig.usageTierType.validationErrors}
          isDisabled={fieldsConfig.usageTierType.disabled}
          data-testid="volume.usageTierType"
        />
        <Box
          paddingTop="8px"
          marginBottom="16px"
          paddingBottom="8px"
          width="100%"
        >
          <Flex gap={4} alignItems="center" marginBottom="8px">
            <Box {...Lato14Bold}>Tiers</Box>
            {!fieldsConfig.currency.hidden && (
              <SelectField
                data-testid="volume.currency"
                value={fieldsConfig.currency.value}
                onChange={newCurrency =>
                  fieldsConfig.currency.onChange(newCurrency as Currency)
                }
                options={fieldsConfig.currency.options}
                validationErrors={fieldsConfig.currency.validationErrors}
                isDisabled={fieldsConfig.currency.disabled}
                placeholder="Select currency"
                width="90px"
                height="24px"
                backgroundColor={GreyGrey20}
                styles={{
                  wrapper: {
                    marginBottom: 0
                  }
                }}
              />
            )}
            <Flex grow="1"></Flex>
            {fieldsConfig.usageTierType.value === 'PERCENTAGE' && (
              <Center height="14px" paddingBottom="8px">
                <KebabMenu
                  kebabIcon={<EllipsisHorizontalIcon height="20px" />}
                  menuItems={kebabMenuItems({
                    includePercentageLimits:
                      fieldsConfig.includePercentageLimits.value,
                    onChange: fieldsConfig.includePercentageLimits.onChange
                  })}
                  data-testid="volume.percentageTier.kebabMenu"
                />
              </Center>
            )}
          </Flex>
          {fieldsConfig.usageTierType.value === 'FIXED' ? (
            <FixedTierTableInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          ) : fieldsConfig.includePercentageLimits.value ? (
            <PercentageTierWithLimitsTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          ) : (
            <PercentageTierTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          )}
        </Box>
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
