import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import RecalculateInvoiceForm from 'components/RecalculateInvoice/RecalculateInvoiceForm'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { QueryParams } from 'lib/types'

const RecalculateInvoiceModal = createFormModal<
  QueryParams,
  {
    id: string
  }
>({
  FormComponent: RecalculateInvoiceForm,
  useMutation: apiPackageAdapter20240730<
    {
      id: string
    },
    typeof dashboard20240730Client.postRecalculateInvoice
  >('postRecalculateInvoice', ({ id }) => ({ id })),
  modalTitle: 'Recalculate Invoice',
  successMessage: 'Invoice recalculated',
  submitTitle: 'Recalculate invoice',
  formId: 'recalculateInvoiceForm'
})

export default RecalculateInvoiceModal
