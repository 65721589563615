import {
  GreyGrey40,
  GreyGrey60,
  IndigoIndigo50
} from '@sequencehq/design-tokens'
import { buildChartTheme } from '@visx/xychart'

export const primaryColor = IndigoIndigo50

export const theme = buildChartTheme({
  backgroundColor: 'transparent',
  colors: [primaryColor],
  gridColor: GreyGrey40,
  gridColorDark: GreyGrey40,
  svgLabelSmall: {
    fill: GreyGrey60
  },
  tickLength: 0,
  xTickLineStyles: {
    stroke: 'transparent'
  },
  yTickLineStyles: {
    stroke: 'transparent'
  },
  gridStyles: {
    strokeDasharray: '3, 3'
  }
})
