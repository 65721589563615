import { ErrorBoundary } from '@sentry/react'
import PageError from 'components/ErrorPages/PageError'
import {
  Modal,
  ModalContextProvider,
  withUnmountOnModalClose,
  useModalContext
} from '@sequencehq/core-components'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { Button } from '@sequencehq/core-components'
import { DefRevScheduleProgressGraph } from 'RevenueRecognition/view/deferredRevenue/content/DefRevScheduleDetailDrawer/DefRevScheduleProgressGraph'
import { RecognizeRevenueModal } from 'RevenueRecognition/view/deferredRevenue/content/DefRevScheduleDetailDrawer/recognitionDrawerLayouts/milestone/RecognizeRevenueModal'
import { DeferredRevenueProgressVisualization } from '../common/DeferredRevenueProgressVisualization'
import { DeferredRevenueDetailDrawerSummary } from './DeferredRevenueDetailDrawerSummary'
import { DeferredRevenueDetailDrawerBreakdown } from './DeferredRevenueDetailDrawerBreakdown'

type DeferredRevenueDetailDrawerProps = {
  selectedScheduleId: string
  canRecognizeRevenue: boolean
}

const DeferredRevenueDetailDrawerContent = withUnmountOnModalClose(
  (props: DeferredRevenueDetailDrawerProps) => {
    const modalCtx = useModalContext()

    return (
      <Grid
        gridTemplateAreas={`
    "header"
    "content"
    ${props.canRecognizeRevenue ? '"footer"' : ''}
    `}
        gridTemplateRows={`
          auto
          1fr
          ${props.canRecognizeRevenue ? 'auto' : ''}
        `}
        overflow="hidden"
        height="100%"
        width="100%"
      >
        <GridItem gridArea="header">
          <Modal.Header showCloseButton withBorder>
            Overview
          </Modal.Header>
        </GridItem>
        <GridItem gridArea="content" overflow="auto">
          <ErrorBoundary fallback={PageError}>
            <Modal.Content>
              <Flex flexDirection="column" gap="24px">
                <DeferredRevenueProgressVisualization
                  defRevScheduleId={props.selectedScheduleId}
                />
                <DeferredRevenueDetailDrawerSummary
                  defRevScheduleId={props.selectedScheduleId}
                />
                <DeferredRevenueDetailDrawerBreakdown
                  defRevScheduleId={props.selectedScheduleId}
                />
                <DefRevScheduleProgressGraph
                  defRevScheduleId={props.selectedScheduleId}
                />
              </Flex>
            </Modal.Content>
          </ErrorBoundary>
        </GridItem>
        {props.canRecognizeRevenue && (
          <GridItem gridArea="footer">
            <Modal.Footer>
              <RecognizeRevenueModal
                defRevScheduleId={props.selectedScheduleId}
                onSuccess={() => {
                  modalCtx.setIsOpen(false)
                }}
                trigger={open => (
                  <Button
                    style={{
                      width: '100%'
                    }}
                    onClick={open}
                    variant="secondary"
                    data-testid="revrec.defrevDetail.modal.milestoneRecognition.new"
                  >
                    Recognize revenue
                  </Button>
                )}
              />
            </Modal.Footer>
          </GridItem>
        )}
      </Grid>
    )
  }
)

export const DeferredRevenueDetailDrawer = ({
  selectedScheduleId,
  closeDrawer,
  canRecognizeRevenue
}: DeferredRevenueDetailDrawerProps & { closeDrawer: () => void }) => {
  if (!selectedScheduleId) {
    return null
  }

  return (
    <ModalContextProvider isOpen>
      <Modal
        onClose={closeDrawer}
        variant="drawer"
        unmountOnClose
        data-testid="revrec.deferredRevenueDetailDrawer"
      >
        <ErrorBoundary fallback={PageError}>
          <DeferredRevenueDetailDrawerContent
            selectedScheduleId={selectedScheduleId}
            canRecognizeRevenue={canRecognizeRevenue}
          />
        </ErrorBoundary>
      </Modal>
    </ModalContextProvider>
  )
}
