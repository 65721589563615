import { SimpleDialogUI } from '@sequencehq/core-components'
import { Text } from '@chakra-ui/react'
import { GreyGrey80, Lato14ExtendedRegular } from '@sequencehq/design-tokens'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications'
import { Product } from 'Products/types'

type Props = {
  product: Product
  onSuccess?: VoidFunction
}

export const ArchiveProductModal = ({ product, onSuccess }: Props) => {
  const notifications = useNotifications()

  const onSubmit = async () => {
    try {
      await dashboard20240730Client
        .postProductArchive({
          id: product.id
        })
        .then(async result => {
          if (result?.error) {
            const errorResponse = await result.res.json()

            throw new Error(
              errorResponse?.error?.errors?.[0]?.message ??
                'Something went wrong, please try again.'
            )
          }
        })

      notifications.displayNotification(`Product archived`, {
        type: 'success'
      })

      onSuccess?.()
    } catch (e) {
      const error = e as Error

      notifications.displayNotification(`Unable to archive ${product.name}`, {
        type: 'error',
        description: error.message
      })
    }
  }

  return (
    <SimpleDialogUI
      title={`Archive ${product.name}`}
      cancelButtonText="Cancel"
      confirmButtonText="Continue"
      onConfirm={() => void onSubmit()}
    >
      <Text color={GreyGrey80} {...Lato14ExtendedRegular}>
        This will archive the product immediately.
      </Text>
    </SimpleDialogUI>
  )
}
