import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import { FC } from 'react'
import { useCustomer } from '../../../hooks/useCustomer'
import { useCustomerContactsContext } from 'CustomerContacts/communication/internal/CustomerContacts.domain.context'
import {
  ContactModalForm,
  CustomerContactsRoot,
  useContactModalForm
} from 'CustomerContacts/index'
import { Box } from '@chakra-ui/react'
import { GreyGrey10 } from '@sequencehq/design-tokens'
import { Label } from '@sequencehq/forms'
import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext'

type ConfirmStartScheduleModalProps = {
  onClose: () => void
  onConfirm: () => Promise<void>
  submitting: boolean
}

export const ConfirmStartScheduleModal: FC<
  ConfirmStartScheduleModalProps
> = props => {
  const customer = useCustomer()

  if (!customer) {
    return null
  }

  const { contacts, id: customerId } = customer

  return (
    <ModalContextProvider isOpen>
      {contacts?.length ? (
        <RequirementsMetContent
          onCancel={props.onClose}
          onConfirm={() => void props.onConfirm()}
        />
      ) : (
        <CustomerContextProvider customerId={customerId}>
          <CustomerContactsRoot customerId={customerId}>
            <ContactNeededContent
              onCancel={props.onClose}
              onConfirm={() => void props.onConfirm()}
            />
          </CustomerContactsRoot>
        </CustomerContextProvider>
      )}
    </ModalContextProvider>
  )
}

const RequirementsMetContent: FC<{
  onCancel: () => void
  onConfirm: () => void
}> = ({ onCancel, onConfirm }) => {
  return (
    <SimpleDialogUI
      data-testid="schedule.confirmStart"
      title="Start schedule"
      onCancel={onCancel}
      confirmButtonText="Start schedule"
      onConfirm={onConfirm}
    >
      The schedule will start generating invoices based on the defined prices
      and billing dates.
    </SimpleDialogUI>
  )
}

const ContactNeededContent: FC<{
  onCancel: () => void
  onConfirm: () => void
}> = ({ onCancel, onConfirm }) => {
  const ctx = useCustomerContactsContext()

  const { fieldsConfig, buttonSubmit } = useContactModalForm({
    contactId: 'new',
    fieldsValues: {
      ...ctx.queries.formData,
      billingPreference: 'PRIMARY'
    },
    updateFields: ctx.mutators.internal.updateForm,
    createContact: ctx.mutators.external.out.createContact,
    updateContact: ctx.mutators.external.out.updateContact
  })

  return (
    <SimpleDialogUI
      title="Start schedule"
      onCancel={onCancel}
      confirmButtonText="Start schedule"
      onConfirm={() => {
        buttonSubmit.onSubmit(onConfirm)
      }}
      confirmDisabled={buttonSubmit.disabled}
    >
      You need to add a billing contact to start this schedule. After that, the
      schedule will start generating invoices based on the defined prices and
      billing dates.
      <Box background={GreyGrey10} borderRadius={6} p={4} mt={4}>
        <Label>Billing Contact</Label>
        <ContactModalForm fieldsConfig={fieldsConfig} hideLabels />
        <Box mt={-3} />
      </Box>
    </SimpleDialogUI>
  )
}
