import { ImageBlockConfig } from '@sequencehq/quote-content'
import { BlockAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/blockAdapter.types'

export const imageAdapter: BlockAdapter<'image'> = {
  in: block => {
    const blockConfig = block.config as ImageBlockConfig
    return [
      {
        type: 'image',
        props: {
          url: blockConfig.src,
          caption: blockConfig.alt,
          previewWidth: blockConfig.width
        }
      }
    ]
  },
  out: () => block => {
    return {
      blockType: 'image',
      config: {
        src: block.props.url,
        alt: block.props.caption,
        width: block.props.previewWidth
      },
      version: 1
    }
  }
}
