import { useProductContext } from 'Products/hooks/useProductContext'
import { Product } from 'Products/types'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useEditProductOutletConnector = () => {
  const navigate = useNavigate()
  const context = useProductContext()

  const onSuccess = useCallback(
    (updatedProduct: Product) => {
      context.functions.updateProductState(updatedProduct)
    },
    [context.functions]
  )

  const onCancel = useCallback(() => {
    navigate(`/products/${context.data.product.id}`)
  }, [navigate, context.data.product.id])

  return {
    onSuccess,
    onCancel
  }
}
