import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  IconButton,
  MenuButton,
  Text
} from '@chakra-ui/react'
import { ArchiveBoxIcon } from '@heroicons/react/16/solid'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import {
  KebabMenu,
  MenuItemBuilder,
  RadioButtonGroup
} from '@sequencehq/core-components'
import {
  GreyGrey20,
  GreyGrey30,
  GreyGrey50,
  GreyGrey60,
  GreyGrey70,
  Lato12Bold,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { useArchiveBuilder } from 'components/Customers/utils/buildMenuItems'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { Customer } from 'components/Customers/types.ts'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { ViewCustomerPortalButton } from 'components/ViewCustomerPortalButton/ViewCustomerPortalButton.tsx'

export const CustomerHeader = () => {
  const navigate = useNavigate()
  const { customer, customerPortalEnabled } = useCustomerContext()

  const onContactsPage = useMatch('/customers/:customerId/contacts')

  return (
    <Flex
      paddingY="8px"
      paddingLeft="20px"
      paddingRight="12px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid"
      borderColor={GreyGrey30}
      h={47}
      gap={2}
    >
      <RadioButtonGroup
        onChange={value => navigate(`/customers/${customer?.id}/${value}`)}
        initialValue={onContactsPage ? 'contacts' : ''}
        options={[
          { label: 'Overview', value: '' },
          { label: 'Contacts', value: 'contacts' }
        ].filter(Boolean)}
      />
      <Flex grow="1" minWidth={0} overflow="hidden">
        <Breadcrumb
          display="flex"
          sx={{ '> ol': { maxWidth: '100%' } }}
          justifyContent="center"
          spacing="4px"
          width="100%"
          whiteSpace="nowrap"
          separator={
            <Box
              as="span"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mx={1}
              color={GreyGrey50}
            >
              /
            </Box>
          }
        >
          <BreadcrumbItem {...Lato14Bold} color={GreyGrey60}>
            <Link to="/customers">Customers</Link>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage {...Lato14Bold}>
            <BreadcrumbLink href="#">{customer?.legalName}</BreadcrumbLink>
            {!!customer?.archivedAt && (
              <Flex
                bg="gray.20"
                gap={1}
                borderRadius="md"
                p={1.5}
                ml={2}
                h="24px"
                alignItems="center"
              >
                <ArchiveBoxIcon color={GreyGrey70} height={16} width={16} />
                <Text {...Lato12Bold} color={GreyGrey70}>
                  Archived
                </Text>
              </Flex>
            )}
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <Flex flexDirection="row" gap="8px" alignItems="center">
        {customerPortalEnabled && (
          <ViewCustomerPortalButton
            path={`/customers/${customer?.id}`}
            isDisabled={Boolean(customer?.archivedAt)}
          />
        )}

        <Button
          variant="component-library-secondary"
          aria-label="Edit customer"
          onClick={() => navigate(`/customers/${customer?.id}/edit`)}
          isDisabled={!!customer?.archivedAt}
          data-testid="edit-customer-button"
        >
          Edit customer
        </Button>

        <KebabMenu
          isLazy
          menuItems={[useArchiveBuilder].map(
            (builder: MenuItemBuilder<Customer>) => builder(customer)
          )}
          buttonComponent={
            <MenuButton
              as={IconButton}
              variant="unstyled"
              icon={
                <EllipsisHorizontalIcon
                  style={{ height: '24px', width: '24px', color: GreyGrey60 }}
                />
              }
              onClick={e => {
                e.stopPropagation()
              }}
              minWidth="initial"
              width="32px"
              height="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="6px"
              _hover={{
                backgroundColor: GreyGrey20
              }}
            />
          }
        />
      </Flex>
    </Flex>
  )
}
