import { useStandardPriceForm } from './useStandardPriceForm'
import { TextField, SelectField, PriceField } from '@sequencehq/core-components'
import { PricingModelField } from '../common/PricingModelField'
import { Box, InputGroup } from '@chakra-ui/react'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  ShowAdditionalFieldsToggle,
  useShowAdditionalFields
} from '../common/ShowAdditionalFieldsToggle'
import { ListPriceField } from '../common/ListPriceField'
import { BillingTypeField } from '../common/BillingTypeField'
import { BillingFrequencyField } from '../common/BillingFrequencyField'

export const StandardPriceVariantForm = () => {
  const { fieldsConfig, priceDetails } = useStandardPriceForm()
  const { showAdditionalFields } = useShowAdditionalFields()

  return (
    <>
      <ListPriceField />
      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <TextField
        label="Display name (appears on invoice)"
        data-testid="standard.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <Box paddingBottom="8px">
        <InputGroup display="flex" alignItems="flex-start">
          <PriceField
            label="Price"
            data-testid="standard.price"
            value={fieldsConfig.price.value}
            onChange={newValue => fieldsConfig.price.onChange(newValue)}
            validationErrors={fieldsConfig.price.validationErrors}
            currency={fieldsConfig.currency.value}
            isDisabled={fieldsConfig.price.disabled}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
          />
          {!fieldsConfig.currency.hidden && (
            <Box mt={6} minHeight="72px">
              <SelectField
                data-testid="standard.currency"
                value={fieldsConfig.currency.value}
                onChange={newCurrency =>
                  fieldsConfig.currency.onChange(newCurrency as Currency)
                }
                options={fieldsConfig.currency.options}
                validationErrors={fieldsConfig.currency.validationErrors}
                isDisabled={fieldsConfig.currency.disabled}
                placeholder="Select currency"
                width="150px"
                inputStyle={{
                  borderLeft: 'none',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
              />
            </Box>
          )}
        </InputGroup>
      </Box>

      <ShowAdditionalFieldsToggle />
      <Box
        width="100%"
        marginBottom="16px"
        display={!showAdditionalFields ? 'none' : 'block'}
      >
        <PricingModelField />

        <BillingFrequencyField
          data-testid="standard.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={fieldsConfig.billingFrequency.onChange}
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <BillingTypeField
          value={fieldsConfig.billingType.value}
          onChange={newValue => {
            fieldsConfig.billingType.onChange(newValue)
          }}
          isDisabled={fieldsConfig.billingType.disabled}
          data-testid="standard.billingType"
        />
        <Box mt={2} />
        <ExternalLedgerForm />
      </Box>
    </>
  )
}
