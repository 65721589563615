import { Box, Checkbox, Flex, InputGroup, Link, Text } from '@chakra-ui/react'
import { Currency } from '@sequencehq/core-models'
import {
  GreyGrey20,
  GreyGrey70,
  IndigoIndigo50,
  Lato14Bold,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import {
  NumberField,
  PriceField,
  SelectField,
  TextField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { useSeatBasedPriceForm } from './useSeatBasedPriceForm'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { PricingModelField } from '../common/PricingModelField'
import { SeatBasedTierInput } from './SeatBasedTierInput/SeatBasedTierInput'
import {
  SeatBasedProrationStrategy,
  SeatOveragesBillingFrequency
} from 'common/drawers/PricingEditor/domain'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import SeatProrationDropdown from 'common/drawers/PricingEditor/view/editors/SeatBasedPrice/SeatProrationDropdown/SeatProrationDropdown.tsx'
import {
  ShowAdditionalFieldsToggle,
  useShowAdditionalFields
} from '../common/ShowAdditionalFieldsToggle'
import { ListPriceField } from '../common/ListPriceField'
import { BillingTypeField } from '../common/BillingTypeField'
import { BillingFrequencyField } from '../common/BillingFrequencyField'

export const SeatBasedPriceVariantForm = () => {
  const { fieldsConfig, currency, priceDetails, conditions } =
    useSeatBasedPriceForm()
  const { showAdditionalFields } = useShowAdditionalFields()
  const flags = useFlags()
  const [showMinimumSeats, setShowMinimumSeats] = useState(
    conditions.isContractedMinimumSet
  )

  const toolTipBoxes = fieldsConfig.overagesBillingFrequency.options.map(
    value => (
      <>
        <Box {...Lato14Bold} marginBottom="8px" key={value.label}>
          {value.label}
        </Box>
        <Box marginBottom="16px" key={value.description}>
          {value.description}
        </Box>
      </>
    )
  )

  return (
    <>
      <ListPriceField />
      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <TextField
        label="Display name (appears on invoice)"
        data-testid="seat.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <Box paddingBottom="16px">
        {!fieldsConfig.pricePerSeat.hidden ? (
          <InputGroup display="flex" alignItems="center">
            <PriceField
              label="Seat price"
              data-testid="seat.pricePerSeat"
              value={fieldsConfig.pricePerSeat.value}
              onChange={newValue =>
                fieldsConfig.pricePerSeat.onChange(newValue)
              }
              validationErrors={fieldsConfig.pricePerSeat.validationErrors}
              currency={currency}
              isDisabled={fieldsConfig.pricePerSeat.disabled}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
            />
            {!fieldsConfig.currency.hidden && (
              <Box mt={6}>
                <SelectField
                  data-testid="seat.currency"
                  value={fieldsConfig.currency.value}
                  onChange={newCurrency =>
                    fieldsConfig.currency.onChange(newCurrency as Currency)
                  }
                  options={fieldsConfig.currency.options}
                  validationErrors={fieldsConfig.currency.validationErrors}
                  isDisabled={fieldsConfig.currency.disabled}
                  placeholder="Select currency"
                  width="150px"
                  inputStyle={{
                    borderLeft: 'none',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                  }}
                />
              </Box>
            )}
          </InputGroup>
        ) : (
          <Box paddingBottom="8px" width="100%">
            <Flex gap={4} alignItems="center" marginBottom="8px">
              <Box {...Lato14Bold}>Pricing Tiers</Box>
              {!fieldsConfig.currency.hidden && (
                <SelectField
                  data-testid="seat.currency"
                  value={fieldsConfig.currency.value}
                  onChange={newCurrency =>
                    fieldsConfig.currency.onChange(newCurrency as Currency)
                  }
                  options={fieldsConfig.currency.options}
                  validationErrors={fieldsConfig.currency.validationErrors}
                  isDisabled={fieldsConfig.currency.disabled}
                  placeholder="Select currency"
                  width="90px"
                  height="24px"
                  backgroundColor={GreyGrey20}
                  styles={{
                    wrapper: {
                      marginBottom: 0
                    }
                  }}
                />
              )}
            </Flex>
            <SeatBasedTierInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          </Box>
        )}
        <Checkbox
          data-testid="seat.showMinimumSeats"
          disabled={fieldsConfig.contractedMinimumSeats.disabled}
          isChecked={showMinimumSeats}
          onChange={() => {
            const newValue = !showMinimumSeats

            if (!newValue) {
              fieldsConfig.contractedMinimumSeats.onChange('0')
            }

            setShowMinimumSeats(newValue)
          }}
          size="md"
          spacing="8px"
        >
          <Text {...Lato14Bold}>Contract a minimum number of seats</Text>
        </Checkbox>
        {showMinimumSeats && (
          <Box display="flex" gap="8px" mt="16px">
            <NumberField
              data-testid="seat.contractedMinimumSeats"
              label=""
              value={fieldsConfig.contractedMinimumSeats.value}
              precision={0}
              onChange={newValue =>
                fieldsConfig.contractedMinimumSeats.onChange(newValue)
              }
              validationErrors={
                fieldsConfig.contractedMinimumSeats.validationErrors
              }
              isDisabled={fieldsConfig.contractedMinimumSeats.disabled}
              width="100px"
              styles={{ wrapper: { marginBottom: '0px' } }}
            />
            <Text {...Lato14ExtendedRegular} color={GreyGrey70} pt={2}>
              Included
            </Text>
          </Box>
        )}
      </Box>

      <ShowAdditionalFieldsToggle />
      <Box
        width="100%"
        marginBottom="16px"
        display={!showAdditionalFields ? 'none' : 'block'}
      >
        <PricingModelField />
        <SelectFieldWithActions
          label="Seat metric"
          data-testid="seat.seatMetric"
          options={fieldsConfig.seatMetricId.options}
          validationErrors={fieldsConfig.seatMetricId.validationErrors}
          value={fieldsConfig.seatMetricId.value}
          onChange={newValue => fieldsConfig.seatMetricId.onChange(newValue)}
          isDisabled={fieldsConfig.seatMetricId.disabled}
          placeholder="Select seat metric"
          onAddNew={fieldsConfig.seatMetricId.onAddNew}
        />
        <BillingFrequencyField
          data-testid="seat.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={fieldsConfig.billingFrequency.onChange}
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <BillingTypeField
          value={fieldsConfig.billingType.value}
          onChange={newValue => {
            fieldsConfig.billingType.onChange(newValue)
          }}
          isDisabled={fieldsConfig.billingType.disabled}
          data-testid="seat.billingType"
        />
        <Box mt={4} />
        {fieldsConfig.overagesBillingFrequency.value &&
          conditions.isInAdvanceSelected &&
          conditions.isBillingFrequencyLessThanMonthly && (
            <SelectField
              label="Overages billing frequency"
              data-testid="seat.overagesBillingFrequency"
              tooltip={{
                title: 'Overages billing frequency',
                content: <Box paddingTop="8px">{toolTipBoxes}</Box>
              }}
              value={fieldsConfig.overagesBillingFrequency.value}
              onChange={newValue =>
                fieldsConfig.overagesBillingFrequency.onChange(
                  newValue as SeatOveragesBillingFrequency
                )
              }
              options={fieldsConfig.overagesBillingFrequency.options}
              validationErrors={
                fieldsConfig.overagesBillingFrequency.validationErrors
              }
              isDisabled={fieldsConfig.overagesBillingFrequency.disabled}
              placeholder="Select overage billing frequency"
            />
          )}
        {!flags.useSeatsProrateAllChanges ? (
          <Box marginBottom="20px">
            <Checkbox
              display="flex"
              data-testid="seat.isProrateStrategyIncrements"
              alignItems="flex-start"
              isChecked={conditions.isProrateStrategyIncrements}
              isDisabled={fieldsConfig.prorationStrategy.disabled}
              onChange={() => {
                const newValue = conditions.isProrateStrategyIncrements
                  ? 'USE_MAXIMUM'
                  : 'PRORATE_INCREMENTS'
                fieldsConfig.prorationStrategy.onChange(
                  newValue as SeatBasedProrationStrategy
                )
              }}
            >
              <Box>
                <Text {...Lato14Bold}>
                  Prorate overage fees for added seats
                </Text>
                <Text {...Lato14ExtendedRegular} color="inherit">
                  Charge a prorated overage fee for seats added mid-billing
                  period (default). Disable to charge the full price for added
                  seats.{' '}
                  <Link
                    href="https://docs.sequencehq.com"
                    isExternal
                    color={IndigoIndigo50}
                  >
                    Learn more
                  </Link>
                </Text>
              </Box>
            </Checkbox>
          </Box>
        ) : (
          <SeatProrationDropdown
            data-testid="seat.prorationStrategy"
            value={fieldsConfig.prorationStrategy.value}
            disabled={fieldsConfig.prorationStrategy.disabled}
            onChange={fieldsConfig.prorationStrategy.onChange}
          />
        )}
        {!fieldsConfig.prorateFlatFees.hidden && (
          <Checkbox
            mt="8px"
            mb="8px"
            isChecked={fieldsConfig.prorateFlatFees.value}
            placeItems="start"
            onChange={() => {
              fieldsConfig.prorateFlatFees.onChange(
                !fieldsConfig.prorateFlatFees.value
              )
            }}
            data-testid="seat.prorateFlatFees"
            size="md"
            spacing="8px"
          >
            <Flex flexDirection="column">
              <Text {...Lato14Bold}>Pro-rate flat fees</Text>
              <Text {...Lato14ExtendedRegular} color="inherit">
                Charge a prorated flat fee for partial billing periods.
              </Text>
            </Flex>
          </Checkbox>
        )}
        <ExternalLedgerForm />
      </Box>
    </>
  )
}
