import Sidebar from 'components/Sidebar'
import {
  Box,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react'
import invariant from 'tiny-invariant'
import { useParams } from 'react-router-dom'
import {
  GreyGrey60,
  Lato12Regular,
  Lato16Bold,
  Lato20Bold,
  RedRed60,
  TurquoiseTurquoise80
} from '@sequencehq/design-tokens'
import { Badge, InfoBox, InfoRow } from '@sequencehq/core-components'
import JSONPretty from 'react-json-pretty'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const ViewUsageEvent = () => (
  <Sidebar>
    <ViewUsageEventContent />
  </Sidebar>
)

const ViewUsageEventContent = () => {
  const { eventId } = useParams<{ eventId: string }>()

  invariant(eventId, 'eventId is required')

  const usageEventQuery = useQuery(dashboard20240730Client.getUsageEvent, {
    id: eventId
  })

  if (usageEventQuery.isPending) {
    return (
      <DrawerContent>
        <DrawerCloseButton top={4} right={4} />

        <DrawerBody>
          <Spinner />
        </DrawerBody>
      </DrawerContent>
    )
  }

  const usageEvent = usageEventQuery.data

  if (!usageEvent) {
    return (
      <DrawerContent>
        <DrawerCloseButton top={4} right={4} />
        <DrawerBody>Something went wrong. Please try again later.</DrawerBody>
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      <DrawerHeader as="h2" borderBottom="none" height="auto" pt={6} pb={1}>
        <Flex flexDirection="column">
          <Text
            {...Lato12Regular}
            textTransform="uppercase"
            color={GreyGrey60}
            display="block"
            pb="6px"
          >
            Usage
          </Text>
          <Text {...Lato20Bold} display="block">
            {usageEvent?.id}
          </Text>
        </Flex>
      </DrawerHeader>
      <DrawerCloseButton top={4} right={4} />

      <DrawerBody>
        <InfoBox>
          <InfoRow label="Event ID">{usageEvent.id}</InfoRow>
          <InfoRow label="Date">{usageEvent.eventTimestamp}</InfoRow>
          <InfoRow label="Event type">
            <Badge sentiment="monospaced" textTransform="none">
              {usageEvent.eventType}
            </Badge>
          </InfoRow>
          <InfoRow label="Customer alias">{usageEvent.customerAlias}</InfoRow>
        </InfoBox>
        <Box height={6} />
        <Text {...Lato16Bold}>Data</Text>
        <Box height={4} />

        <InfoBox>
          <Flex flexDirection="column">
            <JSONPretty
              id="json-pretty"
              data={usageEvent}
              theme={{
                main: 'font-size: 14px; line-height: 20px;',
                key: `color:${TurquoiseTurquoise80};`,
                string: `color:${RedRed60};`
              }}
            />
          </Flex>
        </InfoBox>
      </DrawerBody>
    </DrawerContent>
  )
}
