import { useState, useEffect, useMemo } from 'react'
import { format } from '@sequencehq/utils/dates'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

interface DateRange {
  start: Date
  end: Date
}

interface EventTypeData {
  date: string
  value: number
}

interface UseEventTypeWidgets {
  unit: string
  eventType: string
  setEventType: (eventType: string) => void
  data: EventTypeData[]
  isFetching: boolean
  total: number
  eventTypeField: {
    value: string
    label: string
    onChange: (eventType: string) => void
    options: { value: string; label: string }[]
  }
  unitTickValues: number[]
}

export const useEventTypesWidget = (
  dateRange: DateRange
): UseEventTypeWidgets => {
  const { data: eventTypesData, isPending: isFetchingEventTypes } = useQuery(
    dashboard20240730Client.getUniqueUsageEventTypes
  )

  const eventTypes = useMemo(() => {
    return eventTypesData?.items.map(v => ({ label: v, value: v })) || []
  }, [eventTypesData])

  const [eventType, setEventType] = useState<string>('')

  const usageEventsQuery = useQuery(
    dashboard20240730Client.getInsightsUsageEvents,
    {
      dateFrom: format(dateRange.start, 'yyyy-MM-dd'),
      dateTo: format(dateRange.end, 'yyyy-MM-dd'),
      eventType
    }
  )

  const insightsUsageEvents = usageEventsQuery.data

  const transformedData = useMemo(() => {
    if (!insightsUsageEvents) {
      return []
    }

    return insightsUsageEvents.eventsByDay.map(
      ({ date, eventCount }: { date: string; eventCount: number }) => ({
        date,
        value: eventCount
      })
    )
  }, [insightsUsageEvents])

  const unitTickValues = useMemo(() => {
    if (!transformedData || transformedData.length === 0) {
      return [0, 0]
    }

    const max = Math.max(...transformedData.map(d => d.value))
    return [0, max / 2, max]
  }, [transformedData])

  const eventTypeField = {
    value: eventType,
    label: eventType,
    onChange: setEventType,
    options: eventTypes
  }

  useEffect(() => {
    setEventType(eventTypes[0]?.value || '')
  }, [eventTypes])

  return {
    eventType,
    setEventType,
    data: transformedData,
    isFetching: usageEventsQuery.isPending || isFetchingEventTypes,
    total: insightsUsageEvents?.totalEventCount || 0,
    unit: '',
    eventTypeField,
    unitTickValues
  }
}
