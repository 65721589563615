import { atom } from 'jotai'
import { loadable } from 'jotai/utils'
import {
  dashboardv99990101Client,
  Dashboardv99990101Api
} from '@sequencehq/api/dashboard/v99990101'

export type ExtractionData =
  Dashboardv99990101Api.PostContractsProcess.PostContractsProcessResponse

const extractionResultAtom = atom<null | Promise<ExtractionData | null>>(null)

export const loadableExtractionResultAtom = loadable(extractionResultAtom)

export const performExtractionAtom = atom(null, (_, set, assetId: string) => {
  set(extractionResultAtom, getExtractionResult(assetId))
})

const getExtractionResult = async (
  assetId: string
): Promise<ExtractionData> => {
  const result = await dashboardv99990101Client.postContractsProcess({
    assetId
  })

  if (!result.data || result.error) {
    throw new Error('Unable to process contract')
  }

  return result.data
}

export const contractIdAtom = atom<string | undefined>()

export const visiblePreviewFieldPathsAtom = atom<string[] | undefined>()
