import {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useEffect
} from 'react'

import { INITIAL_STATE } from 'components/NavigationV3/NavigationV3Provider.constants'
import { NavigationV3ContextType } from 'components/NavigationV3/NavigationV3Provider.types'
import { useNavigationV3Queries } from 'components/NavigationV3/hooks/useNavigationV3Queries'
import { useNavigationV3Functions } from 'components/NavigationV3/hooks/useNavigationV3Functions'
import { useFetchStytchDataForNavigationV3 } from 'components/NavigationV3/hooks/useFetchStytchDataForNavigationV3'
import { getStorageItem, setStorageItem } from 'lib/utils/storageUtils'

export const NavigationV3Context =
  createContext<NavigationV3ContextType>(INITIAL_STATE)

// Session storage key for navigation collapsed state
const NAVIGATION_COLLAPSED_KEY = 'sequence_navigation_collapsed'

export const NavigationV3Provider = ({ children }: PropsWithChildren) => {
  const [isSwitchingWorkspace, setIsSwitchingWorkspace] =
    useState<boolean>(false)

  // Initialize isNavigationCollapsed from session storage or default to false (expanded)
  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState<boolean>(
    () => {
      // In test environments, always start expanded
      if (process.env.NODE_ENV === 'test') {
        return false
      }

      return getStorageItem(NAVIGATION_COLLAPSED_KEY, false, sessionStorage)
    }
  )

  const { userEmail, userWorkspaces, workspaceId, workspaceName } =
    useFetchStytchDataForNavigationV3()
  const { queries } = useNavigationV3Queries({ userWorkspaces })

  // Toggle navigation collapsed state and persist to session storage
  const toggleNavigationCollapsed = () => {
    setIsNavigationCollapsed(prevState => {
      const newState = !prevState
      setStorageItem(NAVIGATION_COLLAPSED_KEY, newState, sessionStorage)
      return newState
    })
  }

  const { functions } = useNavigationV3Functions({
    setIsSwitchingWorkspace,
    toggleCollapsed: toggleNavigationCollapsed
  })

  const data = useMemo(() => {
    return {
      isSwitchingWorkspace,
      workspaceId: workspaceId || '',
      workspaceName: workspaceName || '',
      userEmail: userEmail || '',
      userWorkspaces: userWorkspaces || [],
      isNavigationCollapsed
    }
  }, [
    isSwitchingWorkspace,
    userEmail,
    userWorkspaces,
    workspaceId,
    workspaceName,
    isNavigationCollapsed
  ])

  return (
    <NavigationV3Context.Provider value={{ data, queries, functions }}>
      {children}
    </NavigationV3Context.Provider>
  )
}
