import { Grid } from '@chakra-ui/react'
import { Skeleton } from '@sequencehq/core-components'
import { useMemo } from 'react'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { format } from '@sequencehq/utils/dates'
import { calculateYAxisLabels } from 'RevenueRecognition/view/utils/graphUtils'
import { useSelectedCurrency } from '../../../utils/useSelectedCurrency'
import { useSelectedDateRange } from '../../../utils/useSelectedDateRange'
import { RecognizedRevenueCard } from './RecognizedRevenueCard'
import { DeferredRevenueCard } from './DeferredRevenueCard'

export interface RevenueForMonth {
  date: string
  endDate: string
  value: number
}

export interface RevenueData {
  startDate: Date
  endDate: Date
  revenueByMonth: Array<RevenueForMonth>
  total: number
}

export const useRevenueGraphAxisScale = (props: {
  currency?: Currency | null
  dateRange?: [Date, Date]
}) => {
  const { currency, dateRange } = props
  const recognizedRevenueGraphData = useQuery(
    dashboardv99990101Client.getRevRecChartsData,
    {
      currency: currency ?? 'GBP',
      startDate: dateRange?.[0]
        ? format(dateRange[0], 'yyyy-MM-dd')
        : undefined,
      endDate: dateRange?.[1] ? format(dateRange[1], 'yyyy-MM-dd') : undefined
    },
    {
      enabled: !!currency,
      staleTime: 60000,
      select: data => {
        if (!data) {
          return { recognizedValues: [], deferredValues: [] }
        }

        const recognizedValues = data.period.buckets.map(bucket => {
          return (
            bucket.recognizedAndDeferred.ledgerAccountTotals.find(
              i => i.name === 'RECOGNIZED_REVENUE'
            )?.total || 0
          )
        })

        const deferredValues = data.period.buckets.map(bucket => {
          return (
            bucket.recognizedAndDeferred.ledgerAccountTotals.find(
              i => i.name === 'DEFERRED_REVENUE'
            )?.total || 0
          )
        })

        return { recognizedValues, deferredValues }
      }
    }
  )

  const yAxisLabels = useMemo(() => {
    if (
      recognizedRevenueGraphData.isPending ||
      !recognizedRevenueGraphData.data
    ) {
      return [0, 0, 0]
    }

    return calculateYAxisLabels([
      ...recognizedRevenueGraphData.data.recognizedValues,
      ...recognizedRevenueGraphData.data.deferredValues
    ])
  }, [recognizedRevenueGraphData])

  return { yAxisLabels, isLoading: recognizedRevenueGraphData.isPending }
}

export const RevRecDashboardGraphs = () => {
  const { currency } = useSelectedCurrency()
  const { dateRange } = useSelectedDateRange()
  const { yAxisLabels, isLoading } = useRevenueGraphAxisScale({
    currency,
    dateRange
  })

  return (
    <Grid gridTemplateColumns="1fr 1fr" gridColumnGap="16px" padding="16px">
      {currency && !isLoading ? (
        <>
          <RecognizedRevenueCard
            currency={currency}
            dateRange={dateRange}
            yAxisLabels={yAxisLabels}
          />
          <DeferredRevenueCard
            currency={currency}
            dateRange={dateRange}
            yAxisLabels={yAxisLabels}
          />
        </>
      ) : (
        <>
          <Skeleton width="100%" height="108px" borderRadius="8px" />
          <Skeleton width="100%" height="108px" borderRadius="8px" />
        </>
      )}
    </Grid>
  )
}
