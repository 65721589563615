import { FC, useCallback, useState } from 'react'
import { Box, Checkbox } from '@chakra-ui/react'
import { Form } from 'react-final-form'
import { DateInputField } from 'components/FormFields'
import { required, skip } from '@sequencehq/validation'
import { handleFormResponse, handleFormResponseV2 } from 'lib/formValidation'
import { UsageEventModel } from '@sequencehq/core-models'
import { ExtractMutationParams } from 'lib/types'
import { DrawerForm, useHandleMutationResult } from 'components/Modal'
import { EventPropertyList } from 'components/FormComponents'
import arrayMutators from 'final-form-arrays'
import { Blocker } from 'components/Form'
import { UsageEventTypeInput } from 'components/CreateUsageMetric/UsageEventTypeInput'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { CustomerAliasComboInput } from 'components/FormComponents/CustomerAliasComboInput'
import { format } from '@sequencehq/utils/dates'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications'

type FormValues = Omit<UsageEventModel, 'eventProperties'> & {
  eventPropertyList: { name: string; value: string }[]
  customerId: string
}

type CreateUsageEventFormProps = {
  formContainer: typeof DrawerForm
  onSuccess: (usageEvent: UsageEventModel) => void
  handleCancel: () => void
}

export const CreateUsageEventForm: FC<CreateUsageEventFormProps> = ({
  formContainer: FormContainer,
  onSuccess,
  handleCancel
}) => {
  const { displayNotification } = useNotifications()
  const [withAlias, setWithAlias] = useState<boolean>(false)

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators
      }}
      initialValues={{
        eventPropertyList: [{ name: '', value: '' }]
      }}
      onSubmit={async (values, form) => {
        const res = await dashboard20240730Client.postUsageEvent({
          ...values,
          eventTimestamp: `${format(
            values.eventTimestamp as unknown as Date,
            'yyyy-MM-dd'
          )}T00:00:00Z`,
          customerAlias: withAlias ? values.customerAlias : values.customerId,
          eventProperties: values.eventPropertyList.reduce(
            (acc, { name, value }) => {
              if (!name && !value) {
                return acc
              }

              return { ...acc, [name]: value.trim() }
            },
            {} as Record<string, unknown>
          )
        })

        if (res.error || !res.data) {
          displayNotification('Could not create usage event', {
            type: 'error'
          })
          return
        }

        displayNotification('Usage event created', {
          type: 'success'
        })
        onSuccess(res.data)

        return handleFormResponseV2(res, form.getRegisteredFields())
      }}
      render={formProps => (
        <FormContainer
          {...formProps}
          title="Add test usage event"
          submitLabel="Add test usage event"
          handleCancel={handleCancel}
        >
          <Blocker
            name="usageEvent"
            dirty={formProps.dirty}
            submitting={formProps.submitting}
            modalTitle="Cancel usage event editing"
            message="Progress you made so far will not be saved."
            submitTitle="Yes, Cancel"
            cancelTitle="Keep editing"
          />

          <CustomerComboInputComplete
            fieldName="customerId"
            disabled={withAlias}
            validate={withAlias ? skip : required}
          />
          <Box height={3} />
          <Checkbox
            checked={withAlias}
            onChange={event => {
              setWithAlias(event.target.checked)
            }}
          >
            Use customer alias instead
          </Checkbox>
          {withAlias ? (
            <>
              <Box height={4} />
              <CustomerAliasComboInput fieldName="customerAlias" />
            </>
          ) : null}
          <Box height={4} />
          <DateInputField
            fieldName="eventTimestamp"
            fieldLabel="Event timestamp"
            validate={required}
            isSelectable={() => true}
          />
          <Box height={4} />

          <UsageEventTypeInput fieldName="eventType" />

          <Box height={4} />

          <EventPropertyList
            fieldName="eventPropertyList"
            addPropertyLabel="Add another property"
            preventDuplicateSuggestions={true}
            useMatcher={false}
          />
        </FormContainer>
      )}
    />
  )
}
