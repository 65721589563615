import { Box, Grid } from '@chakra-ui/react'
import { useQuery } from '@sequencehq/api'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { Lato16Bold } from '@sequencehq/design-tokens'
import { useBillingScheduleId } from 'modules/BillingScheduleHome/utils/useBillingScheduleId'

const useInvoicesSection = () => {
  const billingScheduleId = useBillingScheduleId()
  const invoicesQuery = useQuery(dashboardv20240509Client.getInvoices, {
    billingScheduleId,
    limit: 100
  })

  return {
    isLoading: invoicesQuery.isPending,
    invoices: invoicesQuery.data?.items
  }
}

export const InvoicesSection = () => {
  const hook = useInvoicesSection()

  return (
    <Box height="232px">
      <Box {...Lato16Bold} marginBottom="16px">
        Invoices
      </Box>
      <Grid>
        <Box height="200px" overflow="auto">
          <pre>{JSON.stringify(hook.invoices, null, 2)}</pre>
        </Box>
      </Grid>
    </Box>
  )
}
