/**
 * Safely gets a value from localStorage with error handling
 * @param key - The key to retrieve from localStorage
 * @param defaultValue - The default value to return if the key doesn't exist or an error occurs
 * @returns The value from localStorage, or the default value
 */
export function getStorageItem<T>(
  key: string,
  defaultValue: T,
  storage: Storage = localStorage
): T {
  try {
    const item = storage.getItem(key)

    if (item === null) {
      return defaultValue
    }

    // For boolean values
    if (defaultValue === true || defaultValue === false) {
      return (item === 'true') as unknown as T
    }

    // For other JSON values
    try {
      return JSON.parse(item) as T
    } catch {
      // If not valid JSON, return as string
      return item as unknown as T
    }
  } catch (error) {
    console.warn(
      `Could not access ${storage === localStorage ? 'localStorage' : 'sessionStorage'}`,
      error
    )
    return defaultValue
  }
}

/**
 * Safely sets a value in localStorage with error handling
 * @param key - The key to set in localStorage
 * @param value - The value to store
 * @param storage - The storage to use (localStorage by default)
 * @returns true if successful, false if an error occurred
 */
export function setStorageItem<T>(
  key: string,
  value: T,
  storage: Storage = localStorage
): boolean {
  try {
    if (typeof value === 'object') {
      storage.setItem(key, JSON.stringify(value))
    } else {
      storage.setItem(key, String(value))
    }
    return true
  } catch (error) {
    console.warn(
      `Could not access ${storage === localStorage ? 'localStorage' : 'sessionStorage'}`,
      error
    )
    return false
  }
}
