import { TaxRatesSettings } from 'Settings/view/taxRatesSettings/TaxRatesSettings'
import { AvalaraTaxRatesSettings } from 'Settings/view/taxRatesSettings/avalara/AvalaraTaxRatesSettings'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

const TaxRatesSettingsRoute = () => {
  const result = useQuery(dashboardv99990101Client.getEnabledTaxIntegration)

  if (result.data?.taxIntegrationProvider?.toUpperCase() === 'AVALARA') {
    return <AvalaraTaxRatesSettings />
  }

  return <TaxRatesSettings />
}

export default TaxRatesSettingsRoute
