import { UseQueryResult } from '@tanstack/react-query'
import {
  isSequenceApiError,
  isSequenceApiErrorResponse,
  SequenceApiError,
  SequenceError
} from '@sequencehq/api'

export const parseValidationResult = (
  response: UseQueryResult<null, SequenceApiError>
) => {
  if (
    isSequenceApiError(response.error) &&
    isSequenceApiErrorResponse(response.error.response)
  ) {
    const error = response.error.response
    const hasValidationError = error.error.type === 'validationError'
    const validationErrors = hasValidationError
      ? error.error?.errors.filter(error => error.code === 'validationError') ??
        []
      : []

    return {
      validating: response.isFetching,
      hasValidationError,
      validationErrors
    }
  }

  return {
    validating: false,
    hasValidationError: false,
    validationErrors: []
  }
}

export const processValidationErrors = (
  validationErrors: Array<SequenceError>
): Array<string> => {
  const productErrors =
    validationErrors
      .filter(({ errors }) => errors.some(error => error.key === 'product'))
      .flatMap(({ errors }) => errors) ?? []

  const missingRatesForCategories = productErrors
    .flatMap(({ errors }) => errors)
    .filter(error => error.key === 'taxCategory')
    .map(error => error.detail)
    .filter(detail => typeof detail === 'string')

  return missingRatesForCategories
}
