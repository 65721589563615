import { FC, useState } from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { Section } from 'modules/Cube/view/common/atomic/Section'
import ChevronDownIcon from '@heroicons/react/24/solid/ChevronDownIcon'
import ChevronUpIcon from '@heroicons/react/24/solid/ChevronUpIcon'
import { GreyGrey60, Lato14Bold } from '@sequencehq/design-tokens'

type WidgetSectionProps = {
  title: string
  options?: React.ReactNode
  children: React.ReactNode
  collapsible?: boolean
  initialExpandedState?: boolean
  width?: string
  hideTitleWhenOpen?: boolean
}

export const WidgetSection: FC<WidgetSectionProps> = ({
  title,
  options,
  children,
  initialExpandedState = true,
  collapsible = false,
  width,
  hideTitleWhenOpen = false
}) => {
  const [expanded, setExpanded] = useState(initialExpandedState)

  const showTitle = hideTitleWhenOpen ? !expanded : true

  return (
    <Section width={width}>
      <Grid
        templateColumns="1fr auto"
        templateRows="auto auto"
        templateAreas={`"title options"
        "content content"
        `}
      >
        {showTitle && (
          <GridItem
            area="title"
            justifyContent="space-between"
            width="100%"
            pt="20px"
            pb="16px"
            px="24px"
            userSelect="none"
          >
            <Text {...Lato14Bold}>{title}</Text>
          </GridItem>
        )}
        <Flex
          gridArea="options"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          width="100%"
          pt="20px"
          pb="16px"
          px="24px"
        >
          {options}
          {collapsible && (
            <>
              {expanded ? (
                <ChevronUpIcon
                  cursor="pointer"
                  height="16px"
                  color={GreyGrey60}
                  onClick={() => setExpanded(false)}
                />
              ) : (
                <ChevronDownIcon
                  cursor="pointer"
                  height="16px"
                  color={GreyGrey60}
                  onClick={() => setExpanded(true)}
                />
              )}
            </>
          )}
        </Flex>
        {expanded && <GridItem area="content">{children}</GridItem>}
      </Grid>
    </Section>
  )
}
