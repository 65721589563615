import { useMutation, useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useNotifications } from 'lib/hooks/useNotifications'
import { Customer } from '../types'
import { Dashboardv99990101Api } from '@sequencehq/api/dist/clients/dashboard/v99990101'

type UpdateCustomerData =
  Partial<Dashboardv99990101Api.PutCustomer.UpdateCustomerBody>

export const useUpdateCustomer = (customerOrId: Customer | string) => {
  const notifications = useNotifications()
  const customerId =
    typeof customerOrId === 'string' ? customerOrId : customerOrId.id

  const { data: fetchedCustomer } = useQuery(
    dashboardv99990101Client.getCustomer,
    { id: customerId },
    {
      enabled: typeof customerOrId === 'string' && !!customerId
    }
  )

  const customer =
    typeof customerOrId === 'string' ? fetchedCustomer : customerOrId

  const updateCustomerMutation = useMutation(
    dashboardv99990101Client.putCustomer
  )

  const updateCustomer = async (data: UpdateCustomerData) => {
    if (!customer) return

    try {
      const res = await updateCustomerMutation.mutateAsync({
        id: customer.id,
        body: {
          ...customer,
          ...data
        }
      })

      notifications.displayNotification('Customer updated successfully', {
        type: 'success'
      })
      return res
    } catch (error) {
      notifications.displayNotification('Failed to update customer', {
        type: 'error'
      })
      throw error
    }
  }

  return {
    updateCustomer,
    isUpdating: updateCustomerMutation.isPending,
    isLoading: typeof customerOrId === 'string' && !fetchedCustomer
  }
}
