import { deleteIntermediateSessionTokenCookie } from 'lib/auth/stytch'

export const redirectToLogin = () =>
  (window.location.href = `${window.location.origin}/auth/login?return_to=${window.location.pathname}`)

export const redirectToDashboard = () => {
  deleteIntermediateSessionTokenCookie()

  const returnToPath = window.localStorage.getItem('return_to') ?? '/home'

  if (!returnToPath.startsWith('/')) {
    window.location.href = `${window.location.origin}/home`
    return
  }

  window.location.href = `${window.location.origin}${returnToPath}`
}
