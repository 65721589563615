import { FC, memo } from 'react'

import { Flex, Text } from '@chakra-ui/react'
import {
  IdentificationIcon,
  TagIcon,
  ViewColumnsIcon,
  CalendarIcon,
  EnvelopeIcon
} from '@heroicons/react/16/solid'
import CalendarDateRangeIcon from '@heroicons/react/24/solid/CalendarDateRangeIcon'
import {
  Badge,
  SequenceKebabMenu,
  SequenceMenuItem
} from '@sequencehq/core-components'
import {
  BillingScheduleStatus,
  billingScheduleStatus,
  toBillingScheduleStatusBadgeProps
} from '@sequencehq/core-models'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat, formatISODate } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellScheduleStatus,
  MagicTableFilterConfig,
  MagicTable,
  useMagicTableInfiniteQuery,
  useLinkMagicTableWithSearchParams,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import { BillingSchedulesSegmentedFilters } from 'modules/BillingSchedules/components/BillingSchedulesSegmentedFilters'
import {
  BillingSchedulesFilters,
  BillingSchedulesView
} from 'modules/BillingSchedules/types'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import Page from 'components/Page'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ExtractAttributeTypeFromUnion } from 'lib/types/extractAttributeTypeFromUnion'
import {
  type DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dashboard/v20240730'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { BillingSchedulesExportToCsv } from 'modules/BillingSchedules/components/BillingSchedulesExportToCsv'
import { formatScheduleEndDate } from 'Cube/index'
import { useNavigate } from 'react-router-dom'
import { add, sub } from '@sequencehq/utils/dates'
import { useLoadAllCustomersWithAliases } from 'components/UsageEvents/useLoadAllCustomersWithAliases'
import { ArchiveScheduleMenuItem } from 'common/menuItems/schedules/ArchiveScheduleMenuItem'
import { DuplicateScheduleMenuItem } from 'common/menuItems/schedules/DuplicateScheduleMenuItem'

type BillingSchedule = DashboardApi20240730.GetBillingSchedules.BillingSchedule

export const statusFilterOptions = () => {
  const statuses: BillingScheduleStatus[] = Object.keys(
    billingScheduleStatus.keys
  ) as BillingScheduleStatus[]
  const options = []

  for (const status of statuses) {
    // PENDING is stored as ACTIVE in the database,
    // so we need to do some backend work to make it possible to filter by PENDING
    if (status === 'PENDING') {
      continue
    } else {
      options.push({
        label: toBillingScheduleStatusBadgeProps({ status }).children,
        value: status
      })
    }
  }

  return options
}

const createDateFilterOptions = (type: 'before' | 'after') => {
  const today = new Date()
  const baseOptions = [
    {
      label: 'Today',
      value: formatISODate(today)
    }
  ]

  const monthOptions = [1, 3, 6].map(months => {
    if (type === 'before') {
      return {
        label: `${months} month${months > 1 ? 's' : ''} from now`,
        value: formatISODate(add(today, { months }))
      }
    } else {
      return {
        label: `${months} month${months > 1 ? 's' : ''} ago`,
        value: formatISODate(sub(today, { months }))
      }
    }
  })

  return [...baseOptions, ...monthOptions]
}

const startDateBeforeFilterOptions = createDateFilterOptions('before')
const startDateAfterFilterOptions = createDateFilterOptions('after')
const endDateBeforeFilterOptions = createDateFilterOptions('before')
const endDateAfterFilterOptions = createDateFilterOptions('after')

const BillingSchedules: FC = memo(() => {
  const flags = useFlags()
  const navigate = useNavigate()

  const { customersWithAliases } = useLoadAllCustomersWithAliases()

  const filters: MagicTableFilterConfig<BillingSchedulesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options:
        customersWithAliases?.map(customer => ({
          label: customer.customerName,
          value: customer.id
        })) ?? [],
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'billingScheduleStatus',
      options: statusFilterOptions(),
      format: status => (
        <Badge
          {...toBillingScheduleStatusBadgeProps({
            status: status as BillingScheduleStatus
          })}
        />
      ),
      label: 'Status',
      optionsSortFn: () => 0,
      icon: ViewColumnsIcon
    },
    {
      type: 'date',
      paramName: 'starting',
      paramNameBefore: 'startingBefore',
      paramNameAfter: 'startingAfter',
      optionsBefore: startDateBeforeFilterOptions,
      optionsAfter: startDateAfterFilterOptions,
      format: (_, label) => label,
      label: 'Start date',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'date',
      paramName: 'ending',
      paramNameBefore: 'endingBefore',
      paramNameAfter: 'endingAfter',
      optionsBefore: endDateBeforeFilterOptions,
      optionsAfter: endDateAfterFilterOptions,
      format: (_, label) => label,
      label: 'End date',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    ...(flags?.enableAutoIssueInvoices
      ? [
          {
            type: 'multiSelect' as const,
            paramName: 'autoSendInvoices' as const,
            options: [
              { label: 'Enabled', value: 'true' },
              { label: 'Disabled', value: 'false' }
            ],
            label: 'Auto-send invoices',
            icon: EnvelopeIcon,
            format: (value: string) => (
              <Text>{value === 'true' ? 'Yes' : 'No'}</Text>
            ),
            optionsSortFn: (a: { label: string }, b: { label: string }) => {
              if (a.label === 'Enabled') return -1
              if (b.label === 'Enabled') return 1
              return 0
            }
          }
        ]
      : []),
    {
      paramName: 'includeArchivedSchedules',
      type: 'toggle',
      label: 'Show archived schedules'
    }
  ]

  if (flags?.showBillingScheduleLabelField) {
    filters.push({
      type: 'text',
      paramName: 'label',
      label: 'Label',
      icon: TagIcon
    })
  }

  const { activeFilters, onChangeActiveFilters, sortBy, onChangeSortBy } =
    useLinkMagicTableWithSearchParams(filters)

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getBillingSchedules,
    filters,
    activeFilters,
    sortBy
  )

  const additionalToolbarComponentsV3 = {
    additionalToolbarComponentsV3: {
      start: <BillingSchedulesSegmentedFilters />
    }
  }

  const addNewConfig = {
    buttonText: 'New schedule',
    href: '/billing-schedules/new'
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Schedules"
          addNewConfig={addNewConfig}
          paddingBottom={0}
          menuButtons={<BillingSchedulesExportToCsv />}
        >
          <CurrentUserId>
            {userId => (
              <MagicTable<BillingSchedulesView>
                activeFilters={activeFilters}
                onChangeActiveFilters={onChangeActiveFilters}
                sortBy={sortBy}
                onChangeSortBy={onChangeSortBy}
                entityNamePlural="schedules"
                sequenceUserId={userId}
                infiniteQuery={infiniteQuery}
                rowPath={(row: BillingSchedulesView['model']) =>
                  flags.showBillingScheduleHome
                    ? `/billing-schedules/view/${row.id}`
                    : `/billing-schedules/${row.id}`
                }
                columns={[
                  {
                    id: 'customer',
                    accessorFn: row => ({
                      customerId: row.customerId
                    }),
                    header: 'Customer',
                    cell: value => {
                      const { customerId } = value.getValue<{
                        customerId: BillingSchedule['customerId']
                      }>()

                      return (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          height="40px"
                          pl="6px"
                        >
                          <CustomerPreviewCardPill
                            customerId={customerId}
                            variant="ghost"
                          />
                        </Flex>
                      )
                    }
                  },
                  {
                    id: 'status',
                    header: 'Status',
                    accessorFn: (row: BillingSchedule) => ({
                      status: row.status,
                      archivedAt: row.archivedAt
                    }),
                    cell: value => {
                      const { status, archivedAt } = value.getValue<{
                        status: BillingSchedule['status']
                        archivedAt: BillingSchedule['archivedAt']
                      }>()

                      return (
                        <MagicTableCellScheduleStatus
                          status={status}
                          isArchived={!!archivedAt}
                        />
                      )
                    }
                  },
                  {
                    id: 'products',
                    header: 'Products',
                    accessorFn: (model: BillingSchedule) => ({
                      planId: undefined,
                      prices: model.prices,
                      archivedAt: model.archivedAt
                    }),
                    cell: value => {
                      const { prices, archivedAt } = value.getValue<{
                        prices: ExtractAttributeTypeFromUnion<
                          BillingSchedule,
                          'prices'
                        >
                        archivedAt: ExtractAttributeTypeFromUnion<
                          BillingSchedule,
                          'archivedAt'
                        >
                      }>()

                      if (prices) {
                        return (
                          <MagicTableCell
                            textColor={archivedAt && GreyGrey60}
                            text={prices.map(p => p.name).join(', ')}
                          />
                        )
                      }

                      return null
                    }
                  },
                  {
                    id: 'startDate',
                    accessorFn: (row: BillingSchedule) => ({
                      archivedAt: row.archivedAt,
                      startDate: row.startDate
                    }),
                    header: 'Start date',
                    cell: value => {
                      const { startDate, archivedAt } = value.getValue<{
                        startDate: BillingSchedule['startDate']
                        archivedAt: BillingSchedule['archivedAt']
                      }>()

                      return (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={dateTimeWithFormat(startDate, 'd MMM yyyy')}
                        />
                      )
                    }
                  },
                  {
                    id: 'endDate',
                    accessorFn: (row: BillingSchedule) => ({
                      archivedAt: row.archivedAt,
                      endDate: row.endDate
                    }),
                    header: 'End date',
                    cell: value => {
                      const { endDate, archivedAt } = value.getValue<{
                        endDate: BillingSchedule['endDate']
                        archivedAt: BillingSchedule['archivedAt']
                      }>()

                      return (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={formatScheduleEndDate(endDate)}
                        />
                      )
                    }
                  },
                  ...(flags?.enableAutoIssueInvoices
                    ? [
                        {
                          id: 'autoIssueInvoices',
                          accessorFn: (row: BillingSchedule) => ({
                            archivedAt: row.archivedAt,
                            autoIssueInvoices: row.autoIssueInvoices
                          }),
                          header: 'Auto-send invoices',
                          hiddenByDefault: true,
                          cell: (value: { getValue: () => unknown }) => {
                            const { autoIssueInvoices, archivedAt } =
                              value.getValue() as {
                                autoIssueInvoices: BillingSchedule['autoIssueInvoices']
                                archivedAt: BillingSchedule['archivedAt']
                              }

                            return (
                              <MagicTableCell
                                textColor={archivedAt && GreyGrey60}
                                text={autoIssueInvoices ? 'Yes' : 'No'}
                              />
                            )
                          }
                        }
                      ]
                    : []),
                  ...(flags?.showBillingScheduleLabelField
                    ? [
                        {
                          id: 'label',
                          accessorFn: (row: BillingSchedule) => ({
                            archivedAt: row.archivedAt,
                            label: row.label
                          }),
                          header: 'Label',
                          hiddenByDefault: true,
                          cell: (value: { getValue: () => unknown }) => {
                            const { label, archivedAt } = value.getValue() as {
                              label: BillingSchedule['label']
                              archivedAt: BillingSchedule['archivedAt']
                            }

                            return (
                              <MagicTableCell
                                textColor={archivedAt && GreyGrey60}
                                text={label || ''}
                              />
                            )
                          }
                        }
                      ]
                    : [])
                ]}
                filters={filters}
                emptyStateUnfiltered={entityNamePlural => (
                  <MagicTableEmptyState
                    entityNamePlural={entityNamePlural}
                    imageSrc="/img/empty/schedules.png"
                    learnMoreText="Create a new billing schedule for a customer and put invoicing on autopilot"
                    learnMoreHref="https://docs.sequencehq.com/billing/create-billing-schedules"
                    buttonNew={{
                      label: addNewConfig.buttonText,
                      onClick: () => navigate(addNewConfig.href)
                    }}
                  />
                )}
                emptyStateFiltered={(entityNamePlural, clearFilters) => (
                  <MagicTableFilterEmptyState
                    entityNamePlural={entityNamePlural}
                    entityIcon={CalendarDateRangeIcon}
                    actionClearFilters={clearFilters}
                    actionViewAll={() => navigate('/billing-schedules')}
                    variant="TABLE"
                  />
                )}
                kebabMenu={(model: BillingSchedule, props) => (
                  <SequenceKebabMenu
                    data-testid={`schedules.${model.id}.menu`}
                    buttonSize="small"
                    alignment="bottom-right"
                    items={[
                      ...(flags.showBillingScheduleHome
                        ? [
                            <SequenceMenuItem
                              uuid="view-schedule"
                              key="view-schedule"
                              label="View schedule"
                              data-testid={`schedules.${model.id}.menuItem.view`}
                              onClick={() => {
                                navigate(`/billing-schedules/view/${model.id}`)
                              }}
                            />
                          ]
                        : []),
                      <SequenceMenuItem
                        uuid="edit-schedule"
                        key="edit-schedule"
                        label="Edit schedule"
                        data-testid={`schedules.${model.id}.menuItem.edit`}
                        onClick={() => {
                          navigate(`/billing-schedules/${model.id}`)
                        }}
                      />,
                      <DuplicateScheduleMenuItem
                        key="duplicate-schedule"
                        scheduleId={model.id}
                        onSuccess={(newScheduleId: string) =>
                          navigate(`/billing-schedules/${newScheduleId}`)
                        }
                      />,
                      ({ setIsOpen }) =>
                        !model.archivedAt && (
                          <ArchiveScheduleMenuItem
                            key="archive-schedule"
                            data-testid={`schedules.${model.id}.menuItem.archive`}
                            scheduleId={model.id}
                            onSuccess={() => {
                              setIsOpen?.(false)
                            }}
                          />
                        )
                    ]}
                    {...props}
                  />
                )}
                {...additionalToolbarComponentsV3}
              />
            )}
          </CurrentUserId>
        </Page>
      </Flex>
    </Flex>
  )
})

export default BillingSchedules
