import { Box, Flex, Grid } from '@chakra-ui/react'
import { Skeleton } from '@sequencehq/core-components'
import {
  GreyGrey40,
  GreyGrey60,
  GreyGrey80,
  IndigoIndigo10,
  IndigoIndigo50,
  Lato12Bold,
  Lato12Regular,
  Lato16Bold,
  ShadowXs
} from '@sequencehq/design-tokens'
import { ProgressDonut } from 'RevenueRecognition/view/common/ProgressDonut'

import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { Currency, useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
import { toMoney } from '@sequencehq/utils'

const ProgressBox = (props: {
  title: string
  absolute: number
  percentage: number
  currency: Currency
  'data-testid'?: string
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="8px"
      height="100px"
      data-testid={props['data-testid']}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="4px"
      >
        <Box {...Lato12Regular} fontWeight="semibold" color={GreyGrey60}>
          {props.title}
        </Box>
        <Box {...Lato16Bold} color={GreyGrey80}>
          {toMoney({
            value: props.absolute.toString(),
            currency: props.currency
          })}
        </Box>
      </Flex>
      <Flex
        borderRadius="6px"
        backgroundColor={IndigoIndigo10}
        color={IndigoIndigo50}
        {...Lato12Bold}
        height="24px"
        padding="0 6px"
        alignItems="center"
        gap="4px"
      >
        <ProgressDonut
          animation={{ delayBeforeStart: 150 }}
          percentage={props.percentage}
          size="14px"
        />
        {props.percentage}%
      </Flex>
    </Flex>
  )
}

const PrettyOrbs = () => {
  return (
    <Flex gap="4px">
      <Box
        borderRadius="100%"
        width="4px"
        height="4px"
        backgroundColor={IndigoIndigo50}
        opacity={0.4}
      />
      <Box
        borderRadius="100%"
        width="4px"
        height="4px"
        backgroundColor={IndigoIndigo50}
        opacity={0.6}
      />
      <Box
        borderRadius="100%"
        width="4px"
        height="4px"
        backgroundColor={IndigoIndigo50}
        opacity={0.8}
      />
      <Box
        borderRadius="100%"
        width="4px"
        height="4px"
        backgroundColor={IndigoIndigo50}
        opacity={1}
      />
    </Flex>
  )
}

export interface DeferredRevenueScheduleDetail {
  currency: Currency
  recognized: {
    percentage: number
    absolute: number
  }
  remaining: {
    percentage: number
    absolute: number
  }
}

type UseDefRevScheduleProgressVisualization = (props: {
  scheduleId: string
}) =>
  | {
      data: {
        currency: Currency
        recognized: {
          percentage: number
          absolute: number
        }
        remaining: {
          percentage: number
          absolute: number
        }
      }
      isLoading: false
    }
  | {
      data: null
      isLoading: true
    }

/**
 * Data acquisition for the visualization
 * @param props
 * @returns
 */
export const useDefRevScheduleProgressVisualization: UseDefRevScheduleProgressVisualization =
  (props: { scheduleId: string }) => {
    const scheduleQuery = useQuery(
      dashboardv99990101Client.getDeferredRevenueSchedule,
      { id: props.scheduleId },
      {
        select: res => {
          if (!res) {
            return null
          }

          return {
            currency: res.progress.currency,
            recognized: {
              percentage: Math.round(
                (parseFloat(res.progress.recognized) /
                  parseFloat(res.progress.original)) *
                  100
              ),
              absolute: parseFloat(res.progress.recognized)
            },
            remaining: {
              percentage: Math.round(
                (parseFloat(res.progress.remaining) /
                  parseFloat(res.progress.original)) *
                  100
              ),
              absolute: parseFloat(res.progress.remaining)
            }
          }
        }
      }
    )

    /**
     * We throw this error to be caught by the appropriate error boundary.
     */
    if (scheduleQuery.error) {
      throw new Error('Schedule visualization could not be loaded')
    }

    if (scheduleQuery.isPending) {
      return {
        isLoading: true,
        data: null
      }
    }

    invariant(scheduleQuery.data, 'Data should be defined, if loaded')

    return {
      isLoading: false,
      data: scheduleQuery.data
    }
  }

export const DeferredRevenueProgressVisualization = (props: {
  defRevScheduleId: string
}) => {
  const { isLoading, data } = useDefRevScheduleProgressVisualization({
    scheduleId: props.defRevScheduleId
  })

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <Grid
      width="100%"
      gridTemplateColumns={'1fr auto 1fr'}
      gridColumnGap="12px"
      alignItems="center"
      borderRadius="6px"
      border={`1px solid ${GreyGrey40}`}
      shadow={ShadowXs}
      p="8px"
    >
      {isLoading ? (
        <Skeleton height="140px" width="100%" />
      ) : (
        <ProgressBox
          title="Remaining"
          absolute={data.remaining.absolute}
          currency={data.currency}
          percentage={data.remaining.percentage}
          data-testid="revrec.defrevDetail.totals.remaining"
        />
      )}
      <PrettyOrbs />
      {isLoading ? (
        <Skeleton height="140px" width="100%" />
      ) : (
        <ProgressBox
          title="Recognized"
          absolute={data.recognized.absolute}
          currency={data.currency}
          percentage={data.recognized.percentage}
          data-testid="revrec.defrevDetail.totals.recognized"
        />
      )}
    </Grid>
  )
}
