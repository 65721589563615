import { Invoice, useLoadInvoice } from 'InvoiceEditor/hooks/useLoadInvoice'
import type {
  MerchantModel,
  UsageDataResponseModel
} from '@sequencehq/core-models'
import { useMemo } from 'react'
import { useLoadLineItemsAndGroups } from 'InvoiceEditor/hooks/useLoadLineItemsAndGroups'
import {
  ApiMerchantDetails,
  useLoadMerchantDetails
} from 'InvoiceEditor/hooks/useLoadMerchantDetails'
import { useLoadMerchant } from 'InvoiceEditor/hooks/useLoadAccountMerchant'
import { Customer, useLoadCustomer } from 'InvoiceEditor/hooks/useLoadCustomer'
import { useLoadSubAccountUsage } from 'InvoiceEditor/hooks/useLoadSubAccountUsage'
import { useLoadUsageData } from 'InvoiceEditor/hooks/useLoadUsageData'
import type {
  ApiLineItem,
  ApiLineItemGroup,
  LoaderError
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import {
  type Price,
  useLoadPrices
} from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadPrices'
import {
  TaxRegistration,
  useLoadTaxRegistration
} from './useLoadTaxRegistration'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type ApiUsageItemGroup =
  DashboardApi20240730.GetInvoiceUsageItemGroups.UsageItemGroup
type UseLoadInvoiceEditor = ({ invoiceId }: { invoiceId: string }) => {
  loading: boolean
  errors: {
    invoice?: LoaderError
    merchant?: boolean
    customer?: LoaderError
    lineItems?: LoaderError
    lineItemGroups?: LoaderError
  }
  data?: {
    invoice: Invoice
    invoiceMerchantDetails?: ApiMerchantDetails
    merchant?: MerchantModel
    lineItemsAndGroups?: {
      lineItems: ApiLineItem[]
      lineItemGroups: ApiLineItemGroup[]
      lineItemGroupUsage: (UsageDataResponseModel & {
        lineItemGroupId: string
      })[]
    }
    customer?: Customer
    subAccountUsageBreakdown: ApiUsageItemGroup[]
    prices?: Price[]
    taxRegistration?: TaxRegistration
  }
  reloadInvoice: () => void
}

export const useLoadInvoiceEditor: UseLoadInvoiceEditor = ({ invoiceId }) => {
  const {
    data: invoiceData,
    error: invoiceError,
    loading: invoiceLoading,
    reloadInvoice
  } = useLoadInvoice({
    invoiceId
  })
  const invoice = invoiceData?.invoice

  const {
    data: merchantData,
    loading: merchantLoading,
    error: merchantError
  } = useLoadMerchant()
  const merchant = merchantData?.merchant

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError
  } = useLoadCustomer(invoice?.customerId)
  const { data: subAccountUsageBreakdown, loading: subAccountUsageLoading } =
    useLoadSubAccountUsage({ invoiceId, async: false })
  const customer = customerData?.customer

  const { data: taxRegistrationData, loading: taxRegistrationLoading } =
    useLoadTaxRegistration({
      customerId: invoice?.customerId
    })
  const taxRegistration = taxRegistrationData?.taxRegistration

  const merchantDetailProps = useMemo(() => {
    if (!invoice) {
      return {}
    }

    return {
      currency: invoice.currency,
      country: invoice.customerBillingAddress.country,
      state: invoice.customerBillingAddress.state,
      invoiceMerchantDetails: invoice.merchantDetails
    }
  }, [invoice])
  const { loading: loadingMerchantDetails, data: invoiceMerchantData } =
    useLoadMerchantDetails(merchantDetailProps)

  const {
    data: lineItemData,
    loading: loadingLineItemData,
    lineItemGroupsError,
    lineItemsError
  } = useLoadLineItemsAndGroups(invoiceId)

  const lineItemGroupUsageProps = useMemo(() => {
    return {
      invoiceId,
      lineItemGroups: lineItemData?.lineItemGroups ?? []
    }
  }, [invoiceId, lineItemData])
  const { data: lineItemGroupUsageData } = useLoadUsageData(
    lineItemGroupUsageProps
  )

  const priceProps = useMemo(() => {
    return {
      currency: invoice?.currency
    }
  }, [invoice])

  const { data: priceData, loading: loadingPrices } = useLoadPrices(priceProps)

  const loading = useMemo(() => {
    return [
      invoiceLoading,
      loadingLineItemData,
      loadingMerchantDetails,
      merchantLoading,
      customerLoading,
      subAccountUsageLoading,
      taxRegistrationLoading,
      loadingPrices
    ].some(Boolean)
  }, [
    invoiceLoading,
    loadingLineItemData,
    loadingMerchantDetails,
    merchantLoading,
    customerLoading,
    subAccountUsageLoading,
    taxRegistrationLoading,
    loadingPrices
  ])

  const data = useMemo(() => {
    if (loading || !invoice || !customer) {
      return
    }

    const lineItemsAndGroups =
      lineItemData && lineItemGroupUsageData
        ? {
            ...lineItemData,
            lineItemGroupUsage: lineItemGroupUsageData.lineItemGroupUsage
          }
        : undefined

    return {
      invoice,
      invoiceMerchantDetails: invoiceMerchantData?.merchantDetails ?? undefined,
      lineItemsAndGroups,
      merchant,
      customer,
      subAccountUsageBreakdown:
        subAccountUsageBreakdown?.subAccountUsageBreakdown ?? [],
      prices: priceData?.prices ?? [],
      taxRegistration
    }
  }, [
    loading,
    invoice,
    customer,
    invoiceMerchantData?.merchantDetails,
    lineItemData,
    lineItemGroupUsageData,
    merchant,
    subAccountUsageBreakdown?.subAccountUsageBreakdown,
    priceData?.prices,
    taxRegistration
  ])

  const errors = useMemo(() => {
    return {
      invoice: invoiceError,
      merchant: merchantError,
      customer: customerError,
      lineItems: lineItemsError,
      lineItemGroups: lineItemGroupsError
    }
  }, [
    invoiceError,
    merchantError,
    customerError,
    lineItemsError,
    lineItemGroupsError
  ])

  return {
    data,
    loading,
    errors,
    reloadInvoice
  }
}
