import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { required } from '@sequencehq/validation'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { ModalForm } from 'components/Modal'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { handleFormResponseV2 } from 'lib/formValidation'
import { useNotifications } from 'lib/hooks/useNotifications'
import { FC } from 'react'
import { Form } from 'react-final-form'

type LinkAliasToCustomerFormValues = {
  customerId: string
}

interface LinkAliasToCustomerFormProps {
  alias: string
}

const LinkAliasToCustomerForm: FC<LinkAliasToCustomerFormProps> = ({
  alias
}) => {
  const selfQuery = useQuery(dashboardv99990101Client.getSelf)
  const { displayNotification } = useNotifications()
  const dispatch = useDispatch()

  const handleSuccess = () => {
    displayNotification('Alias linked to customer', {
      type: 'success'
    })
    dispatch(closeOverlay())
  }

  const self = selfQuery.data

  if (!self) {
    return <Spinner></Spinner>
  }

  return (
    <Form<LinkAliasToCustomerFormValues>
      keepDirtyOnReinitialize
      initialValues={{
        customerId: undefined
      }}
      onSubmit={async (values, form) => {
        const res = await dashboard20240730Client.postCustomerAlias({
          customerId: values.customerId,
          value: alias
        })

        if (res.error) {
          displayNotification('Could not link alias to customer', {
            type: 'error'
          })
          return handleFormResponseV2(res, form.getRegisteredFields())
        }

        handleSuccess()
      }}
      render={formProps => (
        <ModalForm
          {...formProps}
          title="Link customer to alias"
          submitLabel="Link"
          data-testid="linkAliasToCustomer.modal"
        >
          <Flex data-testid="linkAliasToCustomer.form" alignItems="center">
            <Text fontWeight={700}>Alias:</Text>
            <Box width="4px"></Box>
            <Text>{alias}</Text>
          </Flex>
          <Box height={5}></Box>

          <CustomerComboInputComplete
            fieldName="customerId"
            validate={required}
          />
        </ModalForm>
      )}
    />
  )
}

export default LinkAliasToCustomerForm
