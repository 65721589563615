import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { Image } from '@chakra-ui/react'
import { UIMatch, useMatches, useNavigate } from 'react-router-dom'

interface IntegrationItem {
  id: string
  logo: FunctionComponent<{ height: string; width: string }>
  label: string
  active: boolean
  onClick: () => void
  status: 'active' | 'warning'
}

type UseIntegrationsNavigation = () => {
  integrationsList: {
    isExpanded: boolean
    toggleExpand: () => void
    integrations: Array<IntegrationItem>
  }
  loading: boolean
  error: boolean
  reloadIntegrations: () => void
}

/**
 * Hook for integration navigation functionality
 * In tests: behaves with traditional toggle behavior
 * In production: always starts collapsed but allows NavSection to control state
 */
export const useIntegrationsNavigation: UseIntegrationsNavigation = () => {
  const ctx = useIntegrationsDomainContext()
  const matches = useMatches()
  const navigate = useNavigate()

  // Detect if we're in a test environment
  const isTestEnvironment = process.env.NODE_ENV === 'test'

  // Only track state in test environment
  const [isExpandedInTest, setIsExpandedInTest] = useState(() => {
    if (isTestEnvironment) {
      try {
        const storedValue = localStorage.getItem('integrations_expanded')
        return storedValue !== null ? JSON.parse(storedValue) : true
      } catch (e) {
        return true
      }
    }
    return false
  })

  const toggleListExpanded = useCallback(() => {
    if (isTestEnvironment) {
      setIsExpandedInTest((prev: boolean) => {
        const newValue = !prev
        try {
          localStorage.setItem(
            'integrations_expanded',
            JSON.stringify(newValue)
          )
        } catch (e) {
          // Ignore storage errors
        }
        return newValue
      })
    }
    // No-op in production - NavSection handles toggle
  }, [isTestEnvironment])

  const reloadIntegrations = useCallback(() => {
    void ctx.mutators.external.in.load()
  }, [ctx.mutators.external.in])

  const integrationsList = useMemo(
    () => ({
      // In tests use the state, in production always return false to let NavSection control it
      isExpanded: isTestEnvironment ? isExpandedInTest : false,
      toggleExpand: toggleListExpanded,
      integrations: ctx.queries.rawData.editor.loaded
        ? [
            ...ctx.queries.activeIntegrations.map(int => ({
              id: int.id,
              logo: ({ height, width }: { height: string; width: string }) => (
                <Image
                  height={height}
                  width={width}
                  src={int.smallLogoUrl}
                  alt={`${int.title} logo`}
                  data-testid={`integration-logo-${int.id}`}
                />
              ),
              label: int.title,
              active: Boolean(
                matches.find(
                  (match: UIMatch<unknown, unknown>) =>
                    match.params?.integrationId === int.id &&
                    match.pathname.includes('integration-management')
                )
              ),
              onClick: () => {
                navigate(`/integration-management/${int.id}`)
              },
              status: int.authFailure
                ? ('warning' as const)
                : ('active' as const)
            }))
          ]
        : []
    }),
    [
      isTestEnvironment,
      isExpandedInTest,
      toggleListExpanded,
      ctx.queries.activeIntegrations,
      navigate,
      matches,
      ctx.queries.rawData.editor.loaded
    ]
  )

  return {
    loading: !ctx.queries.rawData.editor.loaded,
    error: ctx.queries.rawData.editor.loadingError,
    reloadIntegrations,
    integrationsList
  }
}
