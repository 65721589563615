import { Box } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { downloadBlob } from '@sequencehq/utils'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type InvoiceProps = {
  invoiceId: string
  invoiceNumber: string
}

const InvoicePdf: FC<InvoiceProps> = ({ invoiceId, invoiceNumber }) => {
  const navigate = useNavigate()

  useEffect(() => {
    dashboard20240730Client
      .getInvoicePdf({ id: invoiceId })
      .then(res => {
        if (!res.data) {
          throw new Error('An error occurred while downloading the invoice')
        }
        downloadBlob(`${invoiceNumber}.pdf`)(res.data)
      })
      .catch(e => Sentry.captureException(e))

    navigate(-1)
  }, [invoiceId, invoiceNumber, navigate])

  return <></>
}

const InvoiceParamsHandler: FC = () => {
  const { invoiceId, invoiceNumber } = useParams<InvoiceProps>()
  if (!invoiceId) {
    return <Box>Missing invoice parameters</Box>
  }
  return (
    <InvoicePdf invoiceId={invoiceId} invoiceNumber={invoiceNumber || ''} />
  )
}

export default InvoiceParamsHandler
