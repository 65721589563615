import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import invariant from 'tiny-invariant'
import { Skeleton } from '@sequencehq/core-components'
import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { toMoney } from '@sequencehq/utils'
import { ProgressBar } from 'RevenueRecognition/view/common/ProgressBar'

export const useDeferredRevenueDetailBreakdown = (props: {
  scheduleId: string
}) => {
  const scheduleQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedule,
    { id: props.scheduleId },
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          id: res.id,
          progress: {
            currency: res.progress.currency,
            recognized: {
              percentage: Math.round(
                (parseFloat(res.progress.recognized) /
                  parseFloat(res.progress.original)) *
                  100
              ),
              amount: parseFloat(res.progress.recognized)
            },
            remaining: {
              percentage: Math.round(
                (parseFloat(res.progress.remaining) /
                  parseFloat(res.progress.original)) *
                  100
              ),
              amount: parseFloat(res.progress.remaining)
            }
          }
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (scheduleQuery.error) {
    throw new Error('Schedule summary could not be loaded')
  }

  if (scheduleQuery.isPending) {
    return {
      isLoading: true,
      summary: null
    }
  }

  invariant(scheduleQuery.data, 'Summary should be defined, if loaded')

  return {
    isLoading: false,
    summary: scheduleQuery.data
  }
}

export const DeferredRevenueDetailDrawerBreakdown = ({
  defRevScheduleId
}: { defRevScheduleId: string }) => {
  const { isLoading, summary } = useDeferredRevenueDetailBreakdown({
    scheduleId: defRevScheduleId
  })

  if (isLoading) {
    return <Skeleton height="96px" width="100%" />
  }

  return (
    <Flex flexDirection="column">
      <Flex px="4px" pt="4px" pb="8px">
        <Text {...Lato13Bold}>Breakdown</Text>
      </Flex>

      {summary && (
        <>
          <Flex
            px="4px"
            py="8px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text {...Lato13Regular}>Recognised</Text>
            <Flex alignItems="center" gap="12px">
              <Text width="40px" textAlign="right" {...Lato13Regular}>
                {summary.progress.recognized.percentage}%
              </Text>
              <ProgressBar
                width="56px"
                height={8}
                percentage={summary.progress.recognized.percentage}
              />
              <Text minWidth="80px" textAlign="right" {...Lato13Regular}>
                {toMoney({
                  value: `${summary.progress.recognized.amount}`,
                  currency: summary.progress.currency
                })}
              </Text>
            </Flex>
          </Flex>

          <Flex
            px="4px"
            py="8px"
            justifyContent="space-between"
            alignItems="center"
            borderTop="1px solid"
            borderColor={GreyGrey30}
          >
            <Text {...Lato13Regular}>Remaining balance</Text>
            <Flex alignItems="center" gap="12px">
              <Text width="40px" textAlign="right" {...Lato13Regular}>
                {summary.progress.remaining.percentage}%
              </Text>
              <ProgressBar
                width="56px"
                height={8}
                percentage={summary.progress.remaining.percentage}
              />
              <Text minWidth="80px" textAlign="right" {...Lato13Regular}>
                {toMoney({
                  value: `${summary.progress.remaining.amount}`,
                  currency: summary.progress.currency
                })}
              </Text>
            </Flex>
          </Flex>

          <Flex
            px="4px"
            py="8px"
            justifyContent="space-between"
            alignItems="center"
            borderTop="1px solid"
            borderColor={GreyGrey50}
          >
            <Text {...Lato13Bold} fontWeight="semibold">
              Total
            </Text>
            <Text {...Lato13Bold} fontWeight="semibold">
              {toMoney({
                value: `${summary.progress.recognized.amount + summary.progress.remaining.amount}`,
                currency: summary.progress.currency
              })}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  )
}
