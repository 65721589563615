import { Attachments } from 'modules/Assets/Attachments'
import isEqual from 'lodash/isEqual'
import type { ServerAsset } from '@sequencehq/api/utils/commonModels'
import {
  useSelectBillingScheduleAttachments,
  useShouldDisableModifyBillingScheduleAttachments,
  useStoreBillingScheduleAttachments
} from 'Cube/domain/subdomain/attachments'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { usePerformContractExtraction } from 'ContractExtraction/index'

export const BillingScheduleAttachments = () => {
  const flags = useFlags()

  const attachments = useSelectBillingScheduleAttachments()
  const storeAttachments = useStoreBillingScheduleAttachments()
  const disableModifyAttachments =
    useShouldDisableModifyBillingScheduleAttachments()

  const handleAttachmentChange = (assets: ServerAsset[]) => {
    if (
      !isEqual(
        assets.map(({ id }) => id),
        attachments.map(({ id }) => id)
      )
    ) {
      storeAttachments(assets)
    }
  }

  const [attachmentToPreview, setAttachmentToPreview] = useState<
    ServerAsset | undefined
  >()

  const performExtraction = usePerformContractExtraction()

  const handleAllUploadsComplete = (assets: ServerAsset[]) => {
    if (!flags?.useContractIntakePrototype || assets.length !== 1) {
      return
    }

    performExtraction(assets[0].id)
    setAttachmentToPreview(assets[0])
  }

  return (
    <Attachments
      attachmentToPreview={attachmentToPreview}
      initialAttachments={attachments}
      onChange={handleAttachmentChange}
      onAllUploadsComplete={handleAllUploadsComplete}
      disableModifyAttachments={disableModifyAttachments}
    />
  )
}
