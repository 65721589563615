import {
  ModalContextProvider,
  Button,
  PopoverWrapper,
  Pill,
  Modal,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { Link } from 'react-router-dom'
import { Flex, Text, Box, Img } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular,
  Lato13Bold,
  GreyGrey50
} from '@sequencehq/design-tokens'
import { useJournalDetailTotals } from 'RevenueRecognition/view/journalReportDetail/content/JournalDetailDrawer/JournalDetailTotals'
import { useJournalDetailSummary } from 'RevenueRecognition/view/journalReportDetail/content/JournalDetailDrawer/JournalDetailSummary'
import { CustomerPreviewCard } from 'Customer/components/CustomerPreviewCard'
import { getLogoSrc } from 'Customer/utils/logo'
import { format } from '@sequencehq/utils/dates'
import { toMoney } from '@sequencehq/utils'
import { humanize } from 'inflection'
import { IdentificationIcon } from '@heroicons/react/24/solid'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { ManualJournalModal } from 'RevenueRecognition/view/journalReportDetail/content/JournalDetailDrawer/ManualJournalModal/ManualJournalModal'
import PageError from 'components/ErrorPages/PageError'
import { ErrorBoundary } from '@sentry/react'
import { Summary } from 'RevenueRecognition/view/common/Summary'

interface JournalDetailOverviewDrawerProps {
  journalId: string | null
  selectedCurrency: Currency | null
  closeDrawer: () => void
}

const JournalDetailOverviewDrawerContent = withUnmountOnModalClose(
  ({
    journalId,
    selectedCurrency,
    closeDrawer
  }: {
    journalId: string
    selectedCurrency: Currency | null
    closeDrawer: () => void
  }) => {
    const summaryData = useJournalDetailSummary({ journalId: journalId })
    const totalsData = useJournalDetailTotals({ journalId: journalId })

    const summary = summaryData.summary

    const summaryRows = [
      {
        title: 'Customer',
        value: summary?.customer ? (
          <PopoverWrapper
            popover={<CustomerPreviewCard customerId={summary.customer.id} />}
            offset={[-4, 4]}
            openDelay={850}
          >
            <Link
              to={`/customers/${summary.customer.id}`}
              data-testid="revrec.journalDetail.summary.customer"
            >
              <Pill
                icon={
                  summary.customer.domain ? (
                    <Img
                      height="16px"
                      width="16px"
                      src={getLogoSrc({
                        domain: summary.customer.domain
                      })}
                    />
                  ) : (
                    <IdentificationIcon
                      height="16px"
                      width="16px"
                      color={GreyGrey70}
                    />
                  )
                }
                interactive
                variant="ghost"
              >
                {summary.customer.name}
              </Pill>
            </Link>
          </PopoverWrapper>
        ) : (
          '-'
        )
      },
      {
        title: 'Invoice',
        value: summary?.invoice ? (
          <Link
            to={`/invoices/${summary.invoice.id}`}
            data-testid="revrec.journalDetail.summary.product"
          >
            <Text
              {...Lato13Regular}
              color={IndigoIndigo50}
              fontWeight="semibold"
            >
              {summary.invoice.name}
            </Text>
          </Link>
        ) : (
          '-'
        )
      },
      {
        title: 'Product',
        value: summary?.product ? (
          <Link
            to={`/products/${summary.product.id}`}
            data-testid="revrec.journalDetail.summary.product"
          >
            <Text
              {...Lato13Regular}
              color={IndigoIndigo50}
              fontWeight="semibold"
            >
              {summary.product.name}
            </Text>
          </Link>
        ) : (
          '-'
        )
      },
      {
        title: 'Type',
        value:
          summary?.journalType === 'MANUAL'
            ? 'Adjustment journal'
            : 'Core journal'
      },
      {
        title: 'Entry date',
        value: summary?.date ? format(summary.date, 'd MMM yyyy') : ''
      },
      {
        title: 'Narrative',
        value: summary?.narrative,
        borderBottom: false
      }
    ]

    return (
      <>
        <Modal.Header showCloseButton withBorder>
          Overview
        </Modal.Header>
        <ErrorBoundary fallback={PageError}>
          <Modal.Content>
            <Flex flexDirection="column" gap="24px">
              <Summary rows={summaryRows} />

              <Flex flexDirection="column">
                <Flex px="8px" pt="4px" pb="8px">
                  <Text {...Lato13Bold}>Breakdown</Text>
                </Flex>

                <Box mb="16px">
                  <Flex
                    borderBottom={`1px solid ${GreyGrey30}`}
                    px="8px"
                    py="8px"
                  >
                    <Text
                      {...Lato13Bold}
                      fontWeight="semibold"
                      color={GreyGrey60}
                      flex="1"
                    >
                      Name
                    </Text>
                    <Text
                      {...Lato13Bold}
                      fontWeight="semibold"
                      color={GreyGrey60}
                      width="100px"
                      textAlign="right"
                    >
                      Debit
                    </Text>
                    <Text
                      {...Lato13Bold}
                      fontWeight="semibold"
                      color={GreyGrey60}
                      width="100px"
                      textAlign="right"
                    >
                      Credit
                    </Text>
                  </Flex>
                  <Flex flexDirection="column">
                    {totalsData.data?.entries?.map(entry => (
                      <Flex
                        key={entry.id}
                        px="8px"
                        py="8px"
                        sx={{
                          '&:not(:last-child)': {
                            borderBottom: `1px solid ${GreyGrey30}`
                          }
                        }}
                      >
                        <Text {...Lato13Regular} flex="1">
                          {humanize(entry.account.name)}
                        </Text>
                        <Text
                          {...Lato13Regular}
                          width="100px"
                          textAlign="right"
                        >
                          {entry.direction === 'DEBIT'
                            ? toMoney({
                                currency: selectedCurrency as Currency,
                                value: entry.amount.toString()
                              })
                            : ''}
                        </Text>
                        <Text
                          {...Lato13Regular}
                          width="100px"
                          textAlign="right"
                        >
                          {entry.direction === 'CREDIT'
                            ? toMoney({
                                currency: selectedCurrency as Currency,
                                value: entry.amount.toString()
                              })
                            : ''}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>

                  <Flex px="8px" py="8px" borderTop={`1px solid ${GreyGrey50}`}>
                    <Text {...Lato13Bold} flex="1">
                      Total
                    </Text>
                    <Text {...Lato13Bold} width="100px" textAlign="right">
                      {toMoney({
                        currency: selectedCurrency as Currency,
                        value: (
                          totalsData.data?.totals.debit.amount || 0
                        ).toString()
                      })}
                    </Text>
                    <Text {...Lato13Bold} width="100px" textAlign="right">
                      {toMoney({
                        currency: selectedCurrency as Currency,
                        value: (
                          totalsData.data?.totals.credit.amount || 0
                        ).toString()
                      })}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Modal.Content>
        </ErrorBoundary>
        <Modal.Footer>
          <ManualJournalModal
            journalId={journalId}
            onSuccess={closeDrawer}
            trigger={open => (
              <Button
                data-testid="revrec.journalDetail.createManualJournal.new"
                style={{
                  width: '100%'
                }}
                onClick={open}
                variant="secondary"
              >
                Create adjustment journal
              </Button>
            )}
          />
        </Modal.Footer>
      </>
    )
  }
)

/**
 * A controlled version of the JournalDetailDrawer that handles opening and closing the drawer
 * based on the journalId prop. When journalId changes, the drawer will automatically open.
 * When journalId is null, the drawer will not be rendered.
 */
export const JournalDetailOverviewDrawer = ({
  journalId,
  selectedCurrency,
  closeDrawer
}: JournalDetailOverviewDrawerProps) => {
  if (!journalId) {
    return null
  }

  return (
    <ModalContextProvider isOpen>
      <Modal
        onClose={closeDrawer}
        variant="drawer"
        unmountOnClose
        data-testid="revrec.journalDetailOverview.drawer"
      >
        <ErrorBoundary fallback={PageError}>
          <JournalDetailOverviewDrawerContent
            journalId={journalId}
            selectedCurrency={selectedCurrency}
            closeDrawer={closeDrawer}
          />
        </ErrorBoundary>
      </Modal>
    </ModalContextProvider>
  )
}
