import { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  CustomerAliasModel,
  emptyPaginatedResponseNew
} from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import { selectPlaceholder } from 'lib/form'
import {
  InfoPopoverProps,
  CompoundInputFieldNew,
  ComboInputRemoteFilterNew,
  Label
} from '@sequencehq/forms'
import { Badge } from '@sequencehq/core-components'
import { Box } from '@chakra-ui/react'
import { useLoadAllCustomersWithAliases } from 'components/UsageEvents/useLoadAllCustomersWithAliases'

type CustomerAliasComboInputProps = {
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
  postChange?: () => void
  handleBlur?: () => void
  addNewAction?: () => void
  labelPadding?: number
}

type CustomerAlias = {
  id: string
  customerId: string
  value: string
  label?: string | undefined
}

export const CustomerAliasComboInput: FC<CustomerAliasComboInputProps> = ({
  fieldName,
  disabled,
  labelPadding
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [query, setQuery] = useState('')
  const [aliasById] = useState<Record<string, CustomerAlias>>({})

  /**
   * Because of the caching benefits of this, it makes sense to make this
   * 'load everything' hook rather than making individual calls for every
   * customer we load in, with no caching.
   *
   * Eventually we'll have lovely aggregates and this all goes away!
   */
  const {
    customersWithAliases,
    isLoading: customersLoading,
    aliases
  } = useLoadAllCustomersWithAliases()

  const [aliasId, setAliasId] = useState<string>()
  const [aliasObject, setAliasObject] = useState<CustomerAliasModel>()

  const customerByAliasDictionary: Record<
    string,
    { id: string; customerName: string; aliases: string[] }
  > = useMemo(() => {
    if (customersLoading) {
      return {}
    }

    return (
      customersWithAliases?.reduce((acc, customer) => {
        return {
          ...acc,
          ...customer.aliases.reduce((acc, alias) => {
            return { ...acc, [alias]: customer }
          }, {})
        }
      }, {}) ?? {}
    )
  }, [customersWithAliases, customersLoading])

  useEffect(() => {
    if (!aliasId) {
      return setAliasObject(undefined)
    }

    const alias = aliases?.find(alias => alias.id === aliasId)

    if (!alias) {
      return setAliasObject(undefined)
    }

    setAliasObject(alias)
  }, [aliasId])

  return (
    <Box>
      <Label>Customer alias</Label>
      {!!labelPadding && labelPadding > 0 && <Box height={labelPadding || 2} />}
      <CompoundInputFieldNew<ComboInputRemoteFilterNew<CustomerAliasModel>>
        inputRef={inputRef}
        items={
          /**
           * Match against both customer name and alias, for easier and more intuitive
           * searching.
           */
          aliases?.filter(alias => {
            const matchedAlias = alias.value
              .toLowerCase()
              .includes(query.toLowerCase())
            const matchedCustomerName = customerByAliasDictionary[
              alias.value
            ]?.customerName
              .toLowerCase()
              .includes(query.toLowerCase())

            return matchedAlias || matchedCustomerName
          }) ?? []
        }
        itemToString={item => item?.value ?? ''}
        itemToContent={({ value }) => ({
          title: customerByAliasDictionary[value]?.customerName ?? '',
          tag: (
            <Badge sentiment="neutral" size="sm" textTransform="none">
              {value}
            </Badge>
          )
        })}
        setQuery={setQuery}
        query={query}
        validate={required}
        placeholder={'Search for a customer alias'}
        disabled={disabled}
        Component={ComboInputRemoteFilterNew}
        fieldName={fieldName}
        model={aliasObject ?? null}
        modelId={aliasObject?.value ?? ''}
        setModelId={setAliasId}
      />
    </Box>
  )
}
