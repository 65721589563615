import { Box, Flex, Grid, Spinner } from '@chakra-ui/react'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  Button,
  Dialog,
  Modal,
  ModalContextProvider,
  SequenceMenuItem,
  Skeleton,
  useModalContext
} from '@sequencehq/core-components'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Regular,
  RedRed50
} from '@sequencehq/design-tokens'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ReactNode, useState } from 'react'
import { match } from 'ts-pattern'

const useArchiveScheduleModal = (props: {
  scheduleId: string
  onSuccess?: () => void
}) => {
  const modal = useModalContext()
  const scheduleQuery = useQuery(
    dashboard20240730Client.getBillingSchedule,
    {
      id: props.scheduleId
    },
    {
      select: data => {
        return data?.status
      }
    }
  )

  /**
   * We maintain this state separately to allow for any follow
   * up async work done in the `onSuccess` function to stall
   * the 'submitting' state of this modal. If we just references
   * the mutation, the submitting UI state would end too soon.
   */
  const [archiving, setArchiving] = useState(false)
  const { displayNotification } = useNotifications()

  const archiveScheduleMutator = useMutation(
    dashboard20240730Client.postBillingScheduleArchive
  )

  return {
    archiving,
    isLoading: scheduleQuery.isPending,
    scheduleStatus: scheduleQuery.data,
    onClick: async () => {
      setArchiving(true)
      const res = await archiveScheduleMutator.mutateAsync({
        id: props.scheduleId
      })

      if (!res) {
        displayNotification('Could not archive schedule', { type: 'error' })
        setArchiving(false)
        return
      }

      if (props.onSuccess) {
        await props.onSuccess()
      }

      setArchiving(false)
      modal.setIsOpen(false)
      displayNotification(`Schedule archived`, {
        type: 'success'
      })
    }
  }
}

const ArchiveScheduleModalUI = (props: {
  scheduleId: string
  onClose?: () => void
  onSuccess?: () => void
}) => {
  const hook = useArchiveScheduleModal({
    scheduleId: props.scheduleId,
    onSuccess: props.onSuccess
  })

  return (
    <>
      <Dialog.Header showCloseButton>Archive schedule</Dialog.Header>
      <Dialog.Content>
        <Box color={GreyGrey80} {...Lato13Regular} flex={1}>
          {hook.isLoading || !hook.scheduleStatus ? (
            <Skeleton width="100%" height="32px" />
          ) : (
            <Box>
              {match(hook.scheduleStatus)
                .with('DRAFT', () => (
                  <>This will archive the billing schedule immediately.</>
                ))
                .with('PENDING', () => (
                  <>
                    This will cancel and archive the billing schedule
                    immediately.
                  </>
                ))
                .with('ACTIVE', () => (
                  <>
                    This will cancel and archive the billing schedule
                    immediately. All in progress invoices will be deleted and
                    all draft invoices will be voided. Finalised and sent
                    invoices will remain.
                  </>
                ))
                .with('SUSPENDED', () => (
                  <>
                    This will cancel and archive the billing schedule
                    immediately. All in progress invoices will be deleted and
                    all draft invoices will be voided. Finalised and sent
                    invoices will remain.
                  </>
                ))
                .with('CANCELLED', () => (
                  <>
                    This will archive the billing schedule immediately. All
                    draft invoices will be voided. Finalised and sent invoices
                    will remain.
                  </>
                ))
                .with('COMPLETED', () => (
                  <>
                    This will archive the billing schedule immediately. All
                    draft invoices will be voided. Finalised and sent invoices
                    will remain.
                  </>
                ))
                .exhaustive()}
            </Box>
          )}
        </Box>
      </Dialog.Content>
      <Modal.Footer>
        <Grid
          gridTemplateColumns="1fr 1fr"
          gap="8px"
          width="100%"
          paddingTop="12px"
        >
          <Modal.CloseTrigger>
            <Box width="100%">
              <Button
                variant="secondary"
                style={{ width: '100%' }}
                data-testid="archiveScheduleModal.cancel"
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </Box>
          </Modal.CloseTrigger>
          <Button
            variant="destructive"
            onClick={() => {
              void hook.onClick()
            }}
            disabled={hook.archiving}
            data-testid="archiveScheduleModal.submit"
          >
            {hook.archiving ? (
              <Flex gap="8px">
                <Spinner height="16px" width="16px" color={GreyGrey60} />
                Archiving...
              </Flex>
            ) : (
              'Archive'
            )}
          </Button>
        </Grid>
      </Modal.Footer>
    </>
  )
}

/**
 * Displays a modal which will allows for archiving the schedule.
 * @param props
 * @returns
 */
export const ArchiveScheduleModal = (props: {
  scheduleId: string
  trigger?: ReactNode
  isOpen?: boolean
  onClose?: () => void
  onSuccess?: () => void
}) => {
  return (
    <ModalContextProvider isOpen={props.isOpen}>
      {props.trigger && <Dialog.Trigger>{props.trigger}</Dialog.Trigger>}
      <Dialog data-testid="archiveScheduleModal" unmountOnClose>
        <ArchiveScheduleModalUI {...props} />
      </Dialog>
    </ModalContextProvider>
  )
}

export const ArchiveScheduleMenuItem = (props: {
  scheduleId: string
  onSuccess?: () => void
  disabled?: boolean
  'data-testid'?: string
}) => {
  return (
    <ArchiveScheduleModal
      scheduleId={props.scheduleId}
      onSuccess={props.onSuccess}
      trigger={
        <SequenceMenuItem
          uuid="archive-schedule"
          label="Archive schedule"
          isDisabled={props.disabled}
          style={{ color: RedRed50 }}
          data-testid={props['data-testid']}
        />
      }
    />
  )
}
