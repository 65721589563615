import {
  AggregatedCreditNote,
  AggregatedLineItemGroup,
  ServerLineItem
} from 'CreditNotes/types'
import differenceBy from 'lodash/differenceBy'
import { removeUnselectedLineItems } from 'CreditNotes/utils/lineItemGroup'
import { useMutation } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useCallback } from 'react'
import { developerLog } from '@sequencehq/utils'

export const useSaveAggregatedCreditNote = (creditNoteId: string) => {
  const putCreditNoteMutation = useMutation(
    dashboardv99990101Client.putCreditNote
  )

  const postLineItemMutation = useMutation(
    dashboardv99990101Client.postCreditNoteLineItem
  )

  const putLineItemMutation = useMutation(
    dashboardv99990101Client.putCreditNoteLineItem
  )

  const deleteLineItemGroupMutation = useMutation(
    dashboardv99990101Client.deleteCreditNoteLineItemGroup
  )

  const deleteLineItemMutation = useMutation(
    dashboardv99990101Client.deleteCreditNoteLineItem
  )

  const performSave = useCallback(
    async (
      lineItemGroupDiffs: ReturnType<typeof diffLineItemGroups>,
      billingPeriodsDiff: ReturnType<typeof diffBillingPeriods>
    ) => {
      const deletions = lineItemGroupDiffs.lineItemsToDelete.map(id =>
        deleteLineItemMutation.mutateAsync({ id, creditNoteId })
      )
      const additions = lineItemGroupDiffs.lineItemsToAdd.map(item =>
        postLineItemMutation.mutateAsync({ body: item, creditNoteId })
      )
      const updates = lineItemGroupDiffs.lineItemsToUpdate.map(item =>
        putLineItemMutation.mutateAsync({
          body: item,
          id: item.id,
          creditNoteId
        })
      )
      const groupDeletions = lineItemGroupDiffs.groupsToDelete.map(id =>
        deleteLineItemGroupMutation.mutateAsync({ id, creditNoteId })
      )

      try {
        await Promise.all([
          ...deletions,
          ...additions,
          ...updates,
          ...groupDeletions
        ])

        if (billingPeriodsDiff) {
          await putCreditNoteMutation.mutateAsync({
            id: creditNoteId,
            body: {
              billingPeriodStart: billingPeriodsDiff.billingPeriodStart,
              billingPeriodEnd: billingPeriodsDiff.billingPeriodEnd
            }
          })
        }
      } catch (e) {
        developerLog('[CreditNot] perform save error', e)
      }
    },
    [
      creditNoteId,
      deleteLineItemMutation,
      deleteLineItemGroupMutation,
      postLineItemMutation,
      putLineItemMutation,
      putCreditNoteMutation
    ]
  )

  return useCallback(
    (
      serverCreditNote: AggregatedCreditNote,
      clientCreditNote: AggregatedCreditNote
    ) => {
      const lineItemGroupDiffs = diffLineItemGroups(
        serverCreditNote.lineItemGroups.map(removeUnselectedLineItems),
        clientCreditNote.lineItemGroups.map(removeUnselectedLineItems)
      )

      const billingPeriodsDiff = diffBillingPeriods({
        serverCreditNote,
        clientCreditNote
      })

      performSave(lineItemGroupDiffs, billingPeriodsDiff)
    },
    [performSave]
  )
}

const diffBillingPeriods = ({
  serverCreditNote,
  clientCreditNote
}: {
  serverCreditNote: AggregatedCreditNote
  clientCreditNote: AggregatedCreditNote
}) => {
  const hasBillingPeriodStartChanged =
    serverCreditNote.billingPeriodStart !== clientCreditNote.billingPeriodStart
  const hasBillingPeriodEndChanged =
    serverCreditNote.billingPeriodEnd !== clientCreditNote.billingPeriodEnd

  if (
    [hasBillingPeriodStartChanged, hasBillingPeriodEndChanged].includes(true)
  ) {
    return {
      billingPeriodStart: clientCreditNote.billingPeriodStart,
      billingPeriodEnd: clientCreditNote.billingPeriodEnd
    }
  }

  return undefined
}

export const diffLineItemGroups = (
  storedLineItemGroups: AggregatedLineItemGroup[],
  changedLineItemGroups: AggregatedLineItemGroup[]
) => {
  const diffs = {
    groupsToDelete: [] as string[],
    lineItemsToDelete: [] as string[],
    lineItemsToAdd: [] as Omit<ServerLineItem, 'id'>[],
    lineItemsToUpdate: [] as ServerLineItem[]
  }

  for (const storedGroup of storedLineItemGroups) {
    const changedGroup = changedLineItemGroups.find(
      g => g.id === storedGroup.id
    )
    if (!changedGroup) {
      diffs.groupsToDelete.push(storedGroup.id)
      diffs.lineItemsToDelete.push(...storedGroup.lineItems.map(i => i.id))
      continue
    }

    diffs.lineItemsToDelete.push(
      ...differenceBy(storedGroup.lineItems, changedGroup.lineItems, 'id').map(
        item => item.id
      )
    )

    for (const item of changedGroup.lineItems) {
      const storedItem = storedGroup.lineItems.find(i => i.id === item.id)
      if (!storedItem) {
        const { id, ...itemToAdd } = item
        diffs.lineItemsToAdd.push(itemToAdd)
        continue
      }
      if (
        item.quantity !== storedItem.quantity ||
        item.netTotal !== storedItem.netTotal
      ) {
        diffs.lineItemsToUpdate.push(item)
      }
    }
  }

  return diffs
}
