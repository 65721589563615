import { useState } from 'react'
import {
  InputSelectControlField,
  SimpleModalUI,
  withUnmountOnModalClose,
  Button
} from '@sequencehq/core-components'
import { Flex, Text, keyframes, Box } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import {
  isSequenceApiError,
  isQuickBooksErrorResponse,
  useMutation,
  useQuery
} from '@sequencehq/api'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import Spinner from 'components/Loading'
import { QBO_ACCOUNT_TYPES_ALLOWING_ITEM_PUSH } from 'Integrations/integrationsConfig/quickBooks/constants'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

const spin = keyframes`
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    `
const spinAnimation = `${spin} 1s linear infinite`

interface Props {
  onConfirm?: () => void
  productId: string
}

const parseMutationError = (error: unknown) => {
  if (!isSequenceApiError(error)) {
    return {
      isValidationError: false,
      message: error instanceof Error ? error.message : 'Unknown error'
    }
  }

  if (isQuickBooksErrorResponse(error.response)) {
    return {
      isValidationError: error.response?.Fault.type === 'ValidationFault',
      message: error.response?.Fault.Error[0].Message
    }
  }
}

export const LinkQuickbooksItemModal = withUnmountOnModalClose(
  ({ onConfirm, productId }: Props) => {
    const [isCreating, setIsCreating] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const { displayNotification } = useNotifications()
    const syncQuickbooksAccountsMutator = useMutation(
      dashboard20240730Client.postSyncQuickbooksAccounts
    )

    const { data, isLoading } = useQuery(
      dashboardv99990101Client.getQuickbooksAccounts
    )

    const quickbooksAccounts = data?.items ?? []
    const linkProductToQuickbooksMutation = useMutation(
      dashboardv99990101Client.postPushProductToQuickbooks
    )

    const quickbooksAccountsOptions = quickbooksAccounts
      .filter(account =>
        QBO_ACCOUNT_TYPES_ALLOWING_ITEM_PUSH.includes(account.type ?? '')
      )
      .map(account => ({
        label: account.name ?? account.id,
        value: account.id
      }))

    const { fields, queries } = useForm<{ quickbooksAccountId: string }>({
      value: {
        quickbooksAccountId: ''
      },
      fieldConfiguration: [
        {
          property: 'quickbooksAccountId',
          validation: [required]
        }
      ]
    })

    if (isLoading) {
      return (
        <SimpleModalUI
          title={`Create item in QuickBooks`}
          cancelButtonText="Cancel"
          submitButtonText="Create item"
          submitDisabled
          onSubmit={() => null}
          contentProps={{
            minHeight: '0px'
          }}
        >
          <Flex
            height="56px"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner color={GreyGrey70} />
          </Flex>
        </SimpleModalUI>
      )
    }

    const linkProductToQuickbooksAccount = async () => {
      const result = await linkProductToQuickbooksMutation.mutateAsync({
        productId,
        accountId: fields.quickbooksAccountId.value
      })

      return result
    }

    const handleSubmit = async () => {
      setIsCreating(true)
      displayNotification('Creating item in QuickBooks...', {
        duration: 30000
      })

      try {
        await linkProductToQuickbooksAccount()
      } catch (e) {
        const parsedError = parseMutationError(e)

        if (
          parsedError?.isValidationError &&
          // We could also use an enum/list of error types instead of matching on `message`.
          // But we would have to match on message *somewhere* with the current response payload.
          parsedError?.message === 'Duplicate Name Exists Error'
        ) {
          displayNotification(`This item already exists in QuickBooks`, {
            type: 'error'
          })
        } else {
          displayNotification(`Could not create item in QuickBooks`, {
            type: 'error'
          })
        }
        setIsCreating(false)
        return
      }

      displayNotification(`Item created in QuickBooks`, {
        type: 'success'
      })

      setIsCreating(false)
      onConfirm?.()
    }

    return (
      <SimpleModalUI
        title={`Create item in QuickBooks`}
        cancelButtonText="Cancel"
        submitButtonText="Create item"
        submitDisabled={!queries.isValid || isCreating}
        onSubmit={handleSubmit}
        contentProps={{
          minHeight: '0px'
        }}
      >
        <Flex
          color={GreyGrey80}
          {...Lato13Regular}
          flexDirection="column"
          gap="16px"
        >
          <InputSelectControlField
            {...fields.quickbooksAccountId}
            label="Account code"
            data-testid="linkToAccount"
            placeholder="Select an account code..."
            options={quickbooksAccountsOptions}
            styles={{
              wrapper: { marginBottom: '0' }
            }}
            disabled={isRefreshing}
          />

          <Flex
            justify="space-between"
            alignContent="center"
            alignItems="center"
            width="100%"
            mt="-16px"
          >
            <Text color={GreyGrey60} {...Lato13Regular}>
              Account not showing up?
            </Text>
            <Flex>
              <Button
                data-testid="refreshAccounts"
                style={{
                  height: '32px',
                  width: '100%'
                }}
                variant="unstyled"
                size="sm"
                onClick={async () => {
                  setIsRefreshing(true)

                  await syncQuickbooksAccountsMutator.mutateAsync()

                  setTimeout(() => {
                    setIsRefreshing(false)
                  }, 1000)
                }}
                aria-label="Refresh accounts"
                disabled={isRefreshing}
                leadingIcon={
                  <Box
                    as="span"
                    animation={isRefreshing ? spinAnimation : 'none'}
                    display="inline-flex"
                  >
                    <ArrowPathIcon width={16} height={16} />
                  </Box>
                }
              >
                <Text {...Lato13Regular} color={GreyGrey60}>
                  Trigger a refresh
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </SimpleModalUI>
    )
  }
)
