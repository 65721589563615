import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Mousetrap from 'mousetrap'
import { navigationShortcuts } from '../../components/KeyboardShortcutsOverlay/shortcuts'
import { useNavigationV3Context } from '../../components/NavigationV3/hooks/useNavigationV3Context'

// Make sure Mousetrap doesn't get stuck waiting for sequences
Mousetrap.stopCallback = function (e, element) {
  // if the element has the class "mousetrap" then no need to stop
  if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
    return false
  }
  // stop for input, select, and textarea
  return (
    element.tagName === 'INPUT' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'TEXTAREA' ||
    element.getAttribute('contenteditable') === 'true'
  )
}

export const useKeyboardNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data, functions } = useNavigationV3Context()
  const { isNavigationCollapsed } = data
  const { toggleNavigationCollapsed } = functions

  useEffect(() => {
    if (!navigate) return

    let isWaitingForSecondKey = false
    let firstKey = ''
    let sequenceTimer: NodeJS.Timeout | null = null

    const resetSequence = () => {
      isWaitingForSecondKey = false
      firstKey = ''
      if (sequenceTimer) {
        clearTimeout(sequenceTimer)
        sequenceTimer = null
      }
    }

    const handleNavigation = (route: string) => {
      try {
        // Only navigate if we're not already on the route
        if (location.pathname !== route.split('?')[0]) {
          navigate(route)
        }
        return false // Prevent default browser behavior
      } catch (error) {
        console.error('Navigation error:', error)
        return false
      }
    }

    // Bind navigation expansion/collapse shortcuts
    Mousetrap.bind('[', () => {
      if (isNavigationCollapsed) return true // Already collapsed
      toggleNavigationCollapsed()
      return false // Prevent default browser behavior
    })

    Mousetrap.bind(']', () => {
      if (!isNavigationCollapsed) return true // Already expanded
      toggleNavigationCollapsed()
      return false // Prevent default browser behavior
    })

    // Bind 'g' key to start sequence
    Mousetrap.bind('g', () => {
      if (!isWaitingForSecondKey) {
        isWaitingForSecondKey = true
        firstKey = 'g'
        // Reset after 1 second if no second key is pressed
        sequenceTimer = setTimeout(resetSequence, 1000)
        return false
      }
      return true
    })

    // Bind individual keys for the second part of the sequence
    navigationShortcuts.forEach(shortcut => {
      const secondKey = shortcut.keys[1]
      if (secondKey && shortcut.route) {
        Mousetrap.bind(secondKey, () => {
          if (isWaitingForSecondKey && firstKey === 'g') {
            resetSequence()
            handleNavigation(shortcut.route)
            return false
          }
          return true
        })
      }
    })

    // Cleanup function to unbind shortcuts when component unmounts
    return () => {
      resetSequence()
      Mousetrap.unbind('g')
      Mousetrap.unbind('[')
      Mousetrap.unbind(']')
      navigationShortcuts.forEach(shortcut => {
        const secondKey = shortcut.keys[1]
        if (secondKey) {
          Mousetrap.unbind(secondKey)
        }
      })
    }
  }, [
    navigate,
    location.pathname,
    isNavigationCollapsed,
    toggleNavigationCollapsed
  ])

  // Return the list of shortcuts for documentation purposes
  return navigationShortcuts
}
