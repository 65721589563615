import { IntegrationConfig } from 'modules/Integrations/domain'
import hubSpotLogo from './hubSpotLogo.svg'
import { HubSpotConfigurationManagement } from './HubSpotConfigurationManagement/HubSpotConfigurationManagement'
import { hubSpotConfigurationLoader } from './hubSpot.loader'
import { hubSpotConfigurationMutator } from './hubSpot.mutator'
import { HubspotIntegrationDetail } from './HubspotIntegrationDetail/HubspotIntegrationDetail'

export const hubSpotIntegrationConfig: IntegrationConfig = {
  logoUrl: hubSpotLogo,
  smallLogoUrl: hubSpotLogo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/hubspot',
  features: {
    canDeleteLinks: true,
    canUninstall: true,
    isDocumentationOnly: false
  },
  title: 'Hubspot',
  description: `Sync deal and customer records from Hubspot.`,
  components: {
    detail: HubspotIntegrationDetail,
    management: HubSpotConfigurationManagement
  },
  communication: {
    configuration: {
      loader: hubSpotConfigurationLoader,
      mutator: hubSpotConfigurationMutator
    }
  }
}

export type HubSpotConfig = {
  /**
   * This is a value which does not exist on the API; it's effectively how we control
   * if an integration is 'paused' or not for now. It's expected that a way to more
   * directly configure this value will be exposed at some point!
   */
  importCustomerWorkflow: boolean
}
