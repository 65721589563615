import { SystemStyleObject } from '@chakra-ui/react'
import { NAVIGATION_ANIMATION } from './animation'

/**
 * Common animation properties for performance optimization
 */
export const PERFORMANCE_PROPERTIES = {
  willChange: 'transform, opacity',
  transform: 'translateZ(0)',
  backfaceVisibility: 'hidden' as const
}

/**
 * Generate fade animation styles
 * @param isVisible Whether the element is visible
 * @returns Style object with fade properties
 */
export function getFadeAnimationStyles(isVisible: boolean): SystemStyleObject {
  return {
    opacity: isVisible ? 1 : 0,
    transition: `opacity ${NAVIGATION_ANIMATION.FADE_TRANSITION}`,
    ...PERFORMANCE_PROPERTIES
  }
}

/**
 * Generate width animation styles
 * @param isCollapsed Whether the navigation is collapsed
 * @returns Style object with width animation properties
 */
export function getWidthAnimationStyles(
  isCollapsed: boolean
): SystemStyleObject {
  return {
    transition: `width ${NAVIGATION_ANIMATION.WIDTH_TRANSITION}`,
    willChange: 'width, transform',
    transform: 'translateZ(0)',
    // Additional styles to prevent layout shifts during transitions
    '& svg': {
      // Prevent icon jumps during transitions
      transition: 'transform 0.3s ease',
      transform: 'translateZ(0)',
      willChange: 'transform',
      flexShrink: 0
    },
    // Ensure GridItems maintain position during transitions
    '& [role="gridcell"]': {
      alignSelf: 'center'
    }
  }
}

/**
 * Generate text animation styles for nav items
 * @param isCollapsed Whether the navigation is collapsed
 * @returns Style object with text animation properties
 */
export function getNavTextAnimationStyles(
  isCollapsed: boolean
): SystemStyleObject {
  return {
    opacity: isCollapsed ? 0 : 1,
    transition: `opacity ${NAVIGATION_ANIMATION.TEXT_TRANSITION}`,
    maxWidth: isCollapsed ? '0' : '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const,
    ...PERFORMANCE_PROPERTIES
  }
}
