import { Address } from '@sequencehq/api/utils/commonModels'
import { states, toCountryLabel } from '@sequencehq/core-models'

export const formatApiAddress = (address: Address): string[] =>
  [
    address.line1,
    address.line2,
    address.town,
    address.postcode,
    'state' in address
      ? states[address.state as keyof typeof states] ?? ''
      : undefined,
    toCountryLabel(address.country)
  ].filter(Boolean) as string[]
