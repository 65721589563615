import { CustomerComboInput } from 'components/FormComponents'
import { FC, useRef, useState, useEffect } from 'react'
import { Label, ListContentProps } from '@sequencehq/forms'
import { Box } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { FieldValidator } from 'final-form'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type ApiCustomer = DashboardApi20240730.GetCustomers.CustomersList['items'][0]
type CustomerComboInputCompleteProps = {
  fieldName: string
  disabled?: boolean
  addNewAction?: () => void
  filterProperty?:
    | 'sortBy'
    | 'legalName'
    | 'alias'
    | 'email'
    | 'includeArchived'
  fieldLabel?: string
  itemToContent?: (item: ApiCustomer) => ListContentProps
  validate: FieldValidator<string> | undefined
  setCustomer?: (customer: ApiCustomer) => void
  labelPadding?: number
}

export const CustomerComboInputComplete: FC<
  CustomerComboInputCompleteProps
> = ({ fieldLabel = 'Customer', labelPadding, ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [customerId, setCustomerId] = useState<string>()

  useEffect(() => {
    if (!customerId) {
      return
    }

    dashboard20240730Client
      .getCustomer({ id: customerId })
      .then(res => {
        const customerValue = res.data
        if (customerValue && props.setCustomer) {
          props.setCustomer(customerValue)
        }
      })
      .catch(err => Sentry.captureException(err))
  }, [customerId, props.setCustomer])

  return (
    <Box data-testid="customerComboInput">
      <Label>{fieldLabel}</Label>
      {!!labelPadding && labelPadding > 0 && <Box height={labelPadding || 2} />}
      <CustomerComboInput
        inputRef={inputRef}
        setModelId={setCustomerId}
        {...props}
      />
    </Box>
  )
}
