import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { apiQueryClient } from 'features/api/apiQueryClient'

/**
 * We use a one hour stale time, because cache busting will result in this value being refreshed
 * anyway. At this stage, all interactions with the customer and alias data is done via the API
 * package.
 */
export const usePrefetchUsageEventTypesAndProperties = () => {
  apiQueryClient.prefetchQuery({
    queryKey: dashboard20240730Client.getUniqueUsageEventProperties.queryKey,
    queryFn: async () => {
      return (
        await dashboard20240730Client.getUniqueUsageEventProperties.request()
      )?.data
    },
    staleTime: 60000
  })

  apiQueryClient.prefetchQuery({
    queryKey: dashboard20240730Client.getUniqueUsageEventTypes.queryKey,
    queryFn: async () => {
      return (await dashboard20240730Client.getUniqueUsageEventTypes.request())
        ?.data
    },
    staleTime: 60000
  })
}
