import { Center } from '@chakra-ui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'

import { Button, SequenceMenu } from '@sequencehq/core-components'
import { GreyGrey70, RedRed60 } from '@sequencehq/design-tokens'

import { useQuoteEditorKebabMenu } from './useQuoteEditorKebabMenu'
import { ConfirmDeleteLatestQuoteDraftModal } from '../modals/DeleteLatestQuoteDraftModal'
import { ArchiveQuoteModal } from 'common/modals/quotes/ArchiveQuote'
import { RevokeQuoteModal } from 'common/modals/quotes/RevokeQuote'
import { CreateTemplateFromQuoteMenuItem } from 'common/menuItems/quotes/CreateTemplateFromQuoteMenuItem'

export const QuoteEditorKebabMenu = () => {
  const {
    duplicateQuote,
    archiveQuote,
    discardChanges,
    saveAsTemplate,
    modals,
    quoteId,
    revokeQuote
  } = useQuoteEditorKebabMenu()

  return (
    <>
      <SequenceMenu
        width={226}
        data-testid="quote.menu"
        alignment="bottom-right"
        items={[
          <CreateTemplateFromQuoteMenuItem
            key="create-template"
            onSuccess={saveAsTemplate.onSuccess}
            quoteId={quoteId}
          />,
          ...(duplicateQuote.visible
            ? [
                {
                  label: 'Duplicate quote',
                  onClick: duplicateQuote.onClick,
                  divider: true
                }
              ]
            : []),
          ...(revokeQuote.visible
            ? [
                {
                  label: 'Remove quote access',
                  onClick: revokeQuote.onClick,
                  style: { color: RedRed60 }
                }
              ]
            : []),
          ...(archiveQuote.visible
            ? [
                {
                  label: 'Archive quote',
                  onClick: archiveQuote.onClick,
                  style: { color: RedRed60 }
                }
              ]
            : []),
          ...(discardChanges.visible
            ? [
                {
                  label: 'Discard unpublished changes',
                  onClick: discardChanges.onClick,
                  style: { color: RedRed60 }
                }
              ]
            : [])
        ]}
        menuButton={
          <Button variant="ghost" size="32px">
            <Center flexShrink="0">
              <EllipsisHorizontalIcon
                width="20px"
                height="20px"
                color={GreyGrey70}
              />
            </Center>
          </Button>
        }
      />

      {modals.deleteLatestDraft.active && (
        <ConfirmDeleteLatestQuoteDraftModal
          submitting={modals.deleteLatestDraft.submitting}
          onClose={modals.deleteLatestDraft.onClose}
          onConfirm={modals.deleteLatestDraft.onConfirm}
        />
      )}

      {modals.archive.active && (
        <ArchiveQuoteModal
          isOpen
          quoteId={quoteId}
          onClose={modals.archive.onClose}
          onSuccess={modals.archive.onConfirm}
        />
      )}

      {modals.revoke.active && (
        <RevokeQuoteModal
          isOpen
          quoteId={quoteId}
          onClose={modals.revoke.onClose}
          onSuccess={modals.revoke.onConfirm}
        />
      )}
    </>
  )
}
