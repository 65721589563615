import * as Sentry from '@sentry/react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { downloadBlob } from '@sequencehq/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'

type UseCreditNotesCsvExport = () => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (
      queryParams: DashboardApi20240730.GetCreditNotesCsvDownload.Params
    ) => void
  }
}

export const useCreditNotesCsvExport: UseCreditNotesCsvExport = () => {
  const notifications = useNotifications()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-csv',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const downloadCsvFile = useCallback(
    async (
      queryParams: DashboardApi20240730.GetCreditNotesCsvDownload.Params
    ) => {
      try {
        const creditNoteCsvRes =
          await dashboard20240730Client.getCreditNotesCsvDownload(queryParams)

        if (!creditNoteCsvRes.data) {
          throw new Error(
            'An error occurred while downloading the credit notes'
          )
        }

        downloadBlob('credit-notes-export.csv')(creditNoteCsvRes.data)

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [showToastError, showToastSuccess]
  )

  const downloadCsv = useCallback(
    (queryParams: DashboardApi20240730.GetCreditNotesCsvDownload.Params) => {
      showToastPreparing()

      void downloadCsvFile(queryParams)
    },
    [showToastPreparing, downloadCsvFile]
  )

  return {
    download: {
      available: true,
      disabled: false,
      onClick: queryParams => downloadCsv(queryParams)
    }
  }
}
