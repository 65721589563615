import { Flex, Text } from '@chakra-ui/react'
import type { ServerAsset } from '@sequencehq/api/utils/commonModels'
import { loadableExtractionResultAtom } from 'ContractExtraction/atoms'
import { useAtom } from 'jotai'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  GreyGrey10,
  GreyGrey60,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { SparklesIcon } from '@heroicons/react/16/solid'
import { Button } from '@sequencehq/core-components'
import { performExtractionAtom } from 'ContractExtraction/atoms'

type Props = {
  asset: ServerAsset
  attachments: ServerAsset[]
}

export const ContractExtractionBanner = ({ asset, attachments }: Props) => {
  const flags = useFlags()

  const [extractionResult] = useAtom(loadableExtractionResultAtom)
  const [, performExtraction] = useAtom(performExtractionAtom)

  const isExtracting = extractionResult.state === 'loading'
  const isAlreadyExtracted =
    extractionResult.state === 'hasData' && extractionResult.data
  const hasOneAttachment = attachments.length === 1

  if (
    !flags?.useContractIntakePrototype ||
    isExtracting ||
    isAlreadyExtracted ||
    hasOneAttachment
  ) {
    return null
  }

  return (
    <Flex
      background={GreyGrey10}
      borderRadius="8px"
      padding="12px"
      gap="16px"
      marginTop="8px"
      alignItems="center"
    >
      <Flex flexDir="column" flex="1" gap="4px">
        <Text {...Lato13Bold} color={GreyGrey80}>
          Speed up setting up this schedule
        </Text>
        <Text {...Lato13Regular} color={GreyGrey60}>
          Extract data from this file and prefill the schedule
        </Text>
      </Flex>

      <Button
        variant="ai"
        leadingIcon={<SparklesIcon width="16px" />}
        onClick={() => {
          performExtraction(asset.id)
        }}
      >
        Run
      </Button>
    </Flex>
  )
}
