import { PricingEditorRoot } from 'common/drawers/PricingEditor/view/pricingEditor.root'
import { EditorMode as PriceEditorMode } from 'common/drawers/PricingEditor/domain'
import { useDispatch } from 'features/store'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useConnector = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { displayNotification } = useNotifications()

  const onSuccess = useCallback(
    (newData: { price: any; product?: any }) => {
      /**
       * In the situation where only one entity is created, we consider that a partial success.
       */
      if (!newData.price) {
        displayNotification(
          `${newData.product.name} created; failed to create price`,
          {
            type: 'warning'
          }
        )
        navigate(`/products/${newData.product.id}`)
        return
      }

      displayNotification(`${newData.product.name} created`, {
        type: 'success'
      })
      navigate('..')
    },
    [dispatch, navigate, displayNotification]
  )

  const onCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    onSuccess,
    onCancel
  }
}

export const AddProductRouteComponent = () => {
  const { onSuccess, onCancel } = useConnector()

  return (
    <PricingEditorRoot
      variant="list"
      initialMode={PriceEditorMode.ADD_PRODUCT}
      onSave={onSuccess}
      onCancel={onCancel}
    />
  )
}
