import deepmerge from 'deepmerge'
import {
  ActionHandler,
  PricingEditorReducerState,
  UpdatePricingEditorDataAction
} from '../pricingEditor.domain.types'
import omit from 'lodash/fp/omit'

export const updatePricingEditorData: ActionHandler<
  UpdatePricingEditorDataAction
> = prevState => action => {
  /**
   * We want to ensure that pricingModel can't be updated via this
   * action - it's a rather important property, and we should only
   * update it via its dedicated action handler. This ensures the
   * appropriate additional updates (i.e. side effects) are also
   * triggered.
   *
   * We could also do this via a post-action, but this is a bit more
   * explicit that something different is happening when changing the
   * pricing model, and thus lure developers to actions like this to
   * learn more!
   *
   * Hi!
   */
  return {
    ...prevState,
    data: {
      ...prevState.data,
      pricingEditorData: {
        ...deepmerge(
          omit('common')(prevState.data.pricingEditorData),
          omit('common')(action.payload),
          {
            arrayMerge: (_, source: unknown[]) => source
          }
        ),
        common: deepmerge(
          prevState.data.pricingEditorData.common,
          omit('pricingModel')(action.payload.common),
          {
            arrayMerge: (_, source: unknown[]) => source
          }
        )
      }
    }
  } as PricingEditorReducerState
}
