import { ReactNode } from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api'

type CurrentUserSelfRequestWrapperProps = {
  children: (userId: string) => ReactNode
}

export const CurrentUserSelfRequestWrapper = ({
  children
}: CurrentUserSelfRequestWrapperProps) => {
  const selfQuery = useQuery(dashboardv99990101Client.getSelf)

  const self = selfQuery.data

  if (!self || selfQuery.isPending) {
    return (
      <Center width="100%" height="100%">
        <Spinner />
      </Center>
    )
  }

  return children(self.sequenceUser.id)
}
