import { Box, Button, Flex } from '@chakra-ui/react'
import { ArrowDownTrayIcon } from '@heroicons/react/16/solid'
import Page from 'components/Page'
import { openOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, memo } from 'react'
import { Outlet } from 'react-router-dom'
import { CustomersMagicTable } from './CustomersMagicTable'
import { CustomersExportToCsv } from 'components/Customers/components/CustomersExportToCsv.tsx'

const Customers: FC = memo(() => {
  const dispatch = useDispatch()

  const menuButtons = (
    <>
      <CustomersExportToCsv />
      <Box width="8px"></Box>
      <Button
        variant="component-library-secondary"
        data-testid="customers.import"
        onClick={() =>
          dispatch(
            openOverlay({
              content: 'importCustomersModal'
            })
          )
        }
        leftIcon={
          <ArrowDownTrayIcon width="16px" height="16px" color="inherit" />
        }
        iconSpacing="4px"
      >
        Import
      </Button>
    </>
  )

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Customers"
          addNewConfig={{
            buttonText: 'New customer',
            href: 'create'
          }}
          menuButtons={menuButtons}
          paddingBottom={0}
        >
          <CustomersMagicTable />
        </Page>
        <Outlet />
      </Flex>
    </Flex>
  )
})

export default Customers
