import { Flex } from '@chakra-ui/react'
import { MutationFormProps } from 'components/Form/types'
import { UpdateInvoiceStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import { noReturn } from 'lib/noReturn'
import { QueryParams } from 'lib/types'
import { FC } from 'react'
import { Form } from 'react-final-form'

const VoidInvoiceForm: FC<
  MutationFormProps<QueryParams> & UpdateInvoiceStatusFormProps
> = ({ id, submitForm, formId }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          })
        ]

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            You won't be able to make any further changes to this Invoice after
            voiding.
          </Flex>
        )
      }}
    />
  )
}

export default VoidInvoiceForm
