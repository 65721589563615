import { useCallback, useEffect, useMemo, useState } from 'react'

import { Text, usePrevious } from '@chakra-ui/react'
import CurrencyPoundIcon from '@heroicons/react/16/solid/CurrencyPoundIcon'
import ViewColumnsIcon from '@heroicons/react/16/solid/ViewColumnsIcon'
import CalendarIcon from '@heroicons/react/16/solid/CalendarIcon'
import IdentificationIcon from '@heroicons/react/16/solid/IdentificationIcon'

import { Badge } from '@sequencehq/core-components'
import {
  InvoicePaymentStatus,
  InvoiceStatus,
  toInvoicePaymentStatusBadgeProps,
  toInvoiceStatusBadgeProps
} from '@sequencehq/core-models'
import {
  ActiveFilter,
  MagicTableFilterConfig,
  magicTableToApiQueryParams,
  useLinkMagicTableWithSearchParams,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import { formatISODate } from '@sequencehq/formatters'

import { InvoicesFilters } from '../types'
import {
  dueAfterFilterOptions,
  dueBeforeFilterOptions,
  invoiceAfterFilterOptions,
  invoiceBeforeFilterOptions,
  paymentStatusFilterOptions,
  sentFilterOptions,
  statusFilterOptions
} from './filters'
import { useEnabledCurrencies } from 'components/CurrencySettings/useCurrencies'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client,
  WithPagination
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { dequal } from 'dequal'
import { useLoadAllCustomersWithAliases } from 'components/UsageEvents/useLoadAllCustomersWithAliases'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice
type UseInvoicesMagicTable = ({
  forcedActiveFilters,
  onFiltersApiQueryParamsChanged
}: {
  forcedActiveFilters?: ActiveFilter[]
  onFiltersApiQueryParamsChanged: (newParams: string) => void
}) => {
  isLoading: boolean
  filters: MagicTableFilterConfig<InvoicesFilters>[]
  activeFilters: ActiveFilter[]
  sortBy: string | null
  onChangeActiveFilters: (newActiveFilters: ActiveFilter[]) => void
  onChangeSortBy: (sortBy: string | null) => void
  selectedInvoices: Array<string>
  setSelectedInvoices: (selectedInvoices: Array<string>) => void
  infiniteQuery: {
    data?:
      | {
          pages: WithPagination<{ items: ReadonlyArray<Invoice> }>[]
        }
      | null
      | undefined
    isFetching: boolean
    hasNextPage: boolean
    isFetchingNextPage: boolean
    fetchNextPage: () => void
  }
  selectAll: {
    showClearSelection: boolean
    showPrompt: boolean
    loading: boolean
    error: boolean
    totalInvoices: number
    totalSelectedInvoices: number
    onSelectAll: () => void
    onClearSelection: () => void
  }
}

export const useInvoicesMagicTable: UseInvoicesMagicTable = ({
  forcedActiveFilters = [],
  onFiltersApiQueryParamsChanged
}) => {
  const [selectedInvoices, setSelectedInvoices] = useState<Array<string>>([])
  const [loadAllInvoices, setLoadAllInvoices] = useState({
    loading: false,
    error: false,
    loaded: false
  })
  const enabledCurrencyRes = useEnabledCurrencies()

  const currencyFilterOptions = useMemo(() => {
    if (enabledCurrencyRes.status === 'SUCCESS') {
      return enabledCurrencyRes.enabledCurrencies.map(currency => ({
        value: currency,
        label: currency
      }))
    }
    return []
  }, [enabledCurrencyRes])

  const isLoading = enabledCurrencyRes.status !== 'SUCCESS'

  const { customersWithAliases } = useLoadAllCustomersWithAliases()

  const filtersConfig: MagicTableFilterConfig<InvoicesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options:
        customersWithAliases?.map(customer => ({
          value: customer.id,
          label: customer.customerName
        })) ?? [],
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'invoiceStatus',
      options: statusFilterOptions(),
      format: status => (
        <Badge
          {...toInvoiceStatusBadgeProps({
            status: status as InvoiceStatus
          })}
        />
      ),
      label: 'Status',
      icon: ViewColumnsIcon,
      optionsSortFn: () => 0
    },
    {
      type: 'multiSelect',
      paramName: 'invoicePaymentStatus',
      options: paymentStatusFilterOptions(),
      format: status => (
        <Badge
          {...toInvoicePaymentStatusBadgeProps({
            status: status as InvoicePaymentStatus
          })}
        />
      ),
      label: 'Payment status',
      icon: ViewColumnsIcon,
      optionsSortFn: () => 0
    },
    {
      type: 'multiSelect',
      paramName: 'invoiceCurrency',
      label: 'Currency',
      icon: CurrencyPoundIcon,
      options: currencyFilterOptions,
      format: currency => currency
    },
    {
      type: 'date',
      paramName: 'due',
      paramNameBefore: 'dueBefore',
      paramNameAfter: 'dueAfter',
      optionsBefore: dueBeforeFilterOptions,
      optionsAfter: dueAfterFilterOptions,
      format: (_, label) => label,
      label: 'Due',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'date',
      paramName: 'sent',
      paramNameBefore: 'sentBefore',
      paramNameAfter: 'sentAfter',
      optionsBefore: sentFilterOptions,
      optionsAfter: sentFilterOptions,
      format: (_, label) => label,
      label: 'Sent',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'date',
      paramName: 'invoice',
      paramNameBefore: 'invoiceBefore',
      paramNameAfter: 'invoiceAfter',
      optionsBefore: invoiceBeforeFilterOptions,
      optionsAfter: invoiceAfterFilterOptions,
      format: (_, label) => label,
      label: 'Invoice date',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'toggle',
      paramName: 'excludeZeroQuantity',
      label: 'Hide zero-usage invoices'
    }
  ]

  const filters = filtersConfig.map(filter => ({
    ...filter,
    disabled: !!forcedActiveFilters.find(
      forcedFilter => forcedFilter.paramName === filter.paramName
    )
  }))

  const {
    activeFilters: searchActiveFilters,
    onChangeActiveFilters: onChangeSearchActiveFilters,
    sortBy,
    onChangeSortBy
  } = useLinkMagicTableWithSearchParams(filters)

  const activeFilters = useMemo(
    () => [...forcedActiveFilters, ...searchActiveFilters],
    [forcedActiveFilters, searchActiveFilters]
  )

  const onChangeActiveFilters = useCallback(
    (newActiveFilters: ActiveFilter[]) => {
      onChangeSearchActiveFilters(
        newActiveFilters.filter(
          filter =>
            !forcedActiveFilters.find(
              forcedFilter => forcedFilter.paramName === filter.paramName
            )
        )
      )
    },
    [onChangeSearchActiveFilters, forcedActiveFilters]
  )

  const [prevQueryParams, setPrevQueryParams] = useState<string>('')

  useEffect(() => {
    const nextQueryParams = new URLSearchParams(
      magicTableToApiQueryParams(filters, activeFilters, sortBy)
    ).toString()

    if (nextQueryParams === prevQueryParams) {
      return
    }

    setPrevQueryParams(nextQueryParams)
    onFiltersApiQueryParamsChanged(nextQueryParams)
  }, [
    filters,
    activeFilters,
    sortBy,
    onFiltersApiQueryParamsChanged,
    prevQueryParams
  ])

  const maximumPagesOfInvoices = 30
  const invoicesPerPage = 100
  const { infiniteQuery } = useMagicTableInfiniteQuery<
    WithPagination<{ items: Invoice[] }>
  >(
    dashboardv20240509Client.getInvoices,
    filters,
    activeFilters,
    sortBy,
    invoicesPerPage,
    maximumPagesOfInvoices
  )

  /**
   * Select all logic - worth us extracting and generalising this in
   * the event we do this elsewhere! But for now, we can keep it within
   * this hook as there's quite a lot of dependencies with filters,
   * the infinite query, and the selection state that would be an
   * interface reasonably broad. It may be that this is the only way
   * to make it work for magic tables, but worth giving it some time
   * to make it as shiny as possible.
   */
  const totalInvoices =
    infiniteQuery.data?.pages[0]?.pagination.totalResultSize ?? 0
  const totalSelectedInvoices = selectedInvoices.length
  const totalLoadedInvoices =
    infiniteQuery.data?.pages.flatMap(x => x.items).length ?? 0

  const selectAllPromptAvailable = useMemo(() => {
    if (loadAllInvoices.loading) return true
    if (
      totalInvoices === totalSelectedInvoices &&
      !loadAllInvoices.loaded &&
      totalInvoices <= invoicesPerPage
    ) {
      return false
    }

    return totalSelectedInvoices === totalLoadedInvoices
  }, [
    loadAllInvoices.loading,
    totalInvoices,
    totalSelectedInvoices,
    totalLoadedInvoices,
    loadAllInvoices.loaded
  ])

  const clearSelectionAvailable =
    selectAllPromptAvailable && totalInvoices === totalSelectedInvoices

  const onSelectAll = () => {
    setLoadAllInvoices({
      loading: true,
      error: false,
      loaded: false
    })
  }

  const onClearSelection = () => {
    setSelectedInvoices([])
    setLoadAllInvoices({
      loading: false,
      error: false,
      loaded: false
    })
  }

  useEffect(() => {
    if (!loadAllInvoices.loading) return

    if (infiniteQuery.error) {
      const allInvoiceIds =
        infiniteQuery.data?.pages
          .flatMap(x => x.items)
          .map(invoice => invoice.id) ?? []
      setSelectedInvoices(allInvoiceIds)
      setLoadAllInvoices({
        loading: false,
        error: true,
        loaded: false
      })
      return
    }

    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetchingNextPage) {
      infiniteQuery.fetchNextPage()
    }

    if (!infiniteQuery.hasNextPage && !infiniteQuery.isFetchingNextPage) {
      const allInvoiceIds =
        infiniteQuery.data?.pages
          .flatMap(x => x.items)
          .map(invoice => invoice.id) ?? []
      setSelectedInvoices(allInvoiceIds)
      setLoadAllInvoices({
        loading: false,
        error: false,
        loaded: true
      })
    }
  }, [loadAllInvoices, infiniteQuery])

  /**
   * When changing filters, reset the selected invoices. This ensures everything
   * behaves neatly when combined with select all
   */
  const prevActiveFilters = usePrevious(activeFilters)
  useEffect(() => {
    if (!dequal(prevActiveFilters, activeFilters)) {
      setSelectedInvoices([])
    }
  }, [activeFilters, prevActiveFilters])
  return {
    isLoading,
    selectedInvoices,
    setSelectedInvoices,
    filters,
    activeFilters,
    sortBy,
    onChangeActiveFilters,
    onChangeSortBy,
    infiniteQuery,
    selectAll: {
      showPrompt: selectAllPromptAvailable,
      showClearSelection: clearSelectionAvailable,
      onClearSelection,
      onSelectAll,
      loading: loadAllInvoices.loading,
      error: loadAllInvoices.error,
      totalInvoices,
      totalSelectedInvoices
    }
  }
}
