import {
  Box,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey50,
  GreyGrey60,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import {
  Badge,
  Button,
  Pill,
  SequenceMenu,
  SequenceMenuItem,
  type SequenceMenuItemProps
} from '@sequencehq/core-components'
import AddAvalaraTaxCodeToTaxCategoryModal from 'Settings/view/taxRatesSettings/avalara/modals/AddAvalaraTaxCodeToTaxCategoryModal'
import EditTaxCategoryNameModal from 'Settings/view/taxRatesSettings/avalara/modals/EditTaxCategoryNameModal'
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/16/solid'
import type { AvalaraTaxCategoryMapping } from 'Settings/domain/taxRates.types'

interface Props {
  taxCategoriesWithAvalaraMappings: AvalaraTaxCategoryMapping[]
  onAddAvalaraTaxCode: ({
    taxCategoryId,
    avalaraTaxCode
  }: {
    taxCategoryId: string
    avalaraTaxCode: string
  }) => Promise<void>
  onRemoveAvalaraTaxCode: (taxCategoryId: string) => Promise<void>
  onMarkTaxCategoryAsDefault: (taxCategoryId: string) => Promise<void>
  onUpdateTaxCategoryName: (
    taxCategoryId: string,
    name: string
  ) => Promise<void>
}

const AvalaraTaxCodeMappingTable = ({
  taxCategoriesWithAvalaraMappings,
  onAddAvalaraTaxCode,
  onRemoveAvalaraTaxCode,
  onMarkTaxCategoryAsDefault,
  onUpdateTaxCategoryName
}: Props) => {
  return (
    <TableContainer
      borderRadius="lg"
      overflow="hidden"
      border={`1px solid ${GreyGrey30}`}
      width="100%"
    >
      <Table
        variant="v2"
        width="100%"
        // tableLayount: 'fixed' which was set by default
        // would cause the inspector to not show up on the page
        style={{
          tableLayout: 'initial'
        }}
      >
        <Thead>
          <Tr>
            <Th width="30%" borderRight={`1px solid ${GreyGrey30}`}>
              Tax category
            </Th>
            <Th width="20%" borderRight={`1px solid ${GreyGrey30}`}>
              Avalara tax code
            </Th>
            <Th>Tax name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {taxCategoriesWithAvalaraMappings.map(mapping => (
            <Tr key={mapping.taxCategoryId}>
              <Td
                borderRight={`1px solid ${GreyGrey30}`}
                minWidth="200px"
                maxWidth="200px"
              >
                <Flex justifyContent="space-between" align="center">
                  <Box
                    maxWidth={mapping.isDefault ? '80%' : '100%'}
                    overflowWrap="break-word"
                    whiteSpace="normal"
                  >
                    <Text {...Lato13Bold}>{mapping.taxCategoryName}</Text>
                  </Box>
                  {mapping.isDefault ? (
                    <Badge sentiment="neutral" size="sm">
                      Default
                    </Badge>
                  ) : null}
                </Flex>
              </Td>
              <Td
                minWidth="100px"
                maxWidth="100px"
                borderRight={`1px solid ${GreyGrey30}`}
                style={{
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}
              >
                {mapping.avalaraTaxCode ? (
                  <Pill
                    data-testid={`avalaraTaxCodeMappingTable.remove.${mapping.taxCategoryId}`}
                    interactive
                    onClear={() =>
                      void onRemoveAvalaraTaxCode(mapping.taxCategoryId)
                    }
                  >
                    {mapping.avalaraTaxCode}
                  </Pill>
                ) : (
                  <AddAvalaraTaxCodeToTaxCategoryModal
                    onAddTaxCode={({ avalaraTaxCode }) =>
                      void onAddAvalaraTaxCode({
                        taxCategoryId: mapping.taxCategoryId,
                        avalaraTaxCode
                      })
                    }
                    trigger={
                      <Button
                        data-testid={`avalaraTaxCodeMappingTable.add.${mapping.taxCategoryId}`}
                        variant="unstyled"
                        style={{
                          color: IndigoIndigo50,
                          ...Lato13Bold
                        }}
                        leadingIcon={
                          <PlusIcon
                            height="16px"
                            width="16px"
                            style={{
                              marginRight: '-4px'
                            }}
                          />
                        }
                      >
                        Add
                      </Button>
                    }
                  />
                )}
              </Td>
              <Td>
                <Flex justify="space-between" align="center" width="100%">
                  {!mapping.taxName ? (
                    <Text {...Lato13Regular} color={GreyGrey50}>
                      No tax code selected yet
                    </Text>
                  ) : (
                    <Box
                      maxWidth="600px"
                      overflowWrap="break-word"
                      whiteSpace="normal"
                    >
                      <Text>{mapping.taxName}</Text>
                    </Box>
                  )}
                  {!mapping.isDefault ? (
                    <Box onClick={e => e.stopPropagation()}>
                      <SequenceMenu
                        width={226}
                        alignment="bottom-right"
                        menuButton={
                          <Button
                            variant="ghost"
                            size="24px"
                            data-testid={`taxCategoryMenu.${mapping.taxCategoryName}`}
                          >
                            <Center flexShrink="0">
                              <EllipsisHorizontalIcon
                                width="16px"
                                height="16px"
                                color={GreyGrey60}
                              />
                            </Center>
                          </Button>
                        }
                        items={[
                          (props: Partial<SequenceMenuItemProps>) => (
                            <EditTaxCategoryNameModal
                              key="edit-name"
                              initialName={mapping.taxCategoryName}
                              onSubmit={async ({ name }) => {
                                await onUpdateTaxCategoryName(
                                  mapping.taxCategoryId,
                                  name
                                )
                                return props?.setIsOpen?.(false)
                              }}
                              trigger={
                                <SequenceMenuItem
                                  data-testid={`editName.${mapping.taxCategoryName}`}
                                  key="edit-name"
                                  uuid="edit-name"
                                  label="Edit name"
                                />
                              }
                            />
                          ),
                          (props: Partial<SequenceMenuItemProps>) => (
                            <SequenceMenuItem
                              data-testid={`markAsDefault.${mapping.taxCategoryName}`}
                              key="mark-as-default"
                              uuid="mark-as-default"
                              label="Mark as default"
                              onClick={() => {
                                void onMarkTaxCategoryAsDefault(
                                  mapping.taxCategoryId
                                )
                                return props?.setIsOpen?.(false)
                              }}
                            />
                          )
                        ]}
                      />
                    </Box>
                  ) : null}
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default AvalaraTaxCodeMappingTable
