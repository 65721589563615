import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { constructAssetPath } from '@sequencehq/utils'
import { MAX_FILE_SIZE } from 'Assets/constants'

export async function uploadFile(file: File): Promise<string> {
  if (!isValidFile(file)) {
    throw 'invalid file'
  }

  const formData = new FormData()
  formData.append('file', file)

  const resAsset = await dashboard20240730Client.postAssets({
    formData
  })

  if (!resAsset.data?.url) {
    throw new Error('unable to upload asset')
  }

  const resAccount = await dashboard20240730Client.getSequenceAccountSettings()

  if (!resAccount.data?.sequenceAccountId) {
    throw Error('unable to get sequence account id')
  }

  const assetPath = constructAssetPath({
    accountId: resAccount.data.sequenceAccountId,
    assetId: resAsset.data.id
  })

  return assetPath
}

const VALID_TYPES = ['image/jpeg', 'image/png']

function isValidFile(file: File) {
  return VALID_TYPES.includes(file.type) && file.size <= MAX_FILE_SIZE
}
