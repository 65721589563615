import { contractIdAtom } from 'ContractExtraction/atoms'
import { useAtom } from 'jotai'
import { useContract } from './useContract'

export const useContractMetadata = (contractId: string | undefined) => {
  return useContract(contractId).data?.fieldsMetadata
}

export const useCurrentContractMetadata = () => {
  const [contractId] = useAtom(contractIdAtom)
  return useContractMetadata(contractId)
}
