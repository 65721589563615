import { Avatar, Box, Flex, Text, Spinner } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { CurrencyInputWrapper } from 'components/CurrencyInputWrapper/CurrencyInputWrapper'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { ModalForm } from 'components/Modal'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { handleFormResponseV2 } from 'lib/formValidation'
import { FC, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useDefaultCurrency } from 'components/CurrencySettings/useCurrencies'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { Currency, useMutation } from '@sequencehq/api/utils'
import { useNotifications } from 'lib/hooks/useNotifications'

type CreditNoteFormValues = {
  currency: Currency
  customerId: string
}

interface CreateCreditNoteFormProps {
  customer: { id: string; legalName: string } | undefined
  variant?: 'v2'
}

const CreateCreditNoteForm: FC<CreateCreditNoteFormProps> = ({
  customer,
  variant
}) => {
  const { displayNotification } = useNotifications()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const defaultCurrency = useDefaultCurrency()
  const { mutateAsync: postLineItemGroup } = useMutation(
    dashboardv99990101Client.postCreditNoteLineItemGroup
  )
  const flags = useFlags()

  const createEmptyGroup = useCallback(
    (creditNoteId: string) => {
      return postLineItemGroup({
        creditNoteId,
        body: {
          title: ''
        }
      })
    },
    [postLineItemGroup]
  )

  const onSuccess = useCallback(
    async ({ id = '' }: { id: string }) => {
      if (flags.useCreditNotesV2) {
        await createEmptyGroup(id)
      }
      navigate(`/credit-notes/${id}`)
      dispatch(closeOverlay())
    },
    [flags.useCreditNotesV2, createEmptyGroup, navigate, dispatch]
  )

  if (!defaultCurrency) {
    return <Spinner />
  }

  return (
    <Form<CreditNoteFormValues>
      keepDirtyOnReinitialize
      initialValues={{
        currency: defaultCurrency,
        customerId: customer?.id
      }}
      onSubmit={async (values, form) => {
        const res = await dashboardv99990101Client.postCreditNote({
          body: {
            currency: values.currency,
            customerId: values.customerId,
            customerBillingAddress: undefined,
            customerEmails: undefined,
            customerLegalCompanyName: undefined,
            customerShippingAddress: undefined,
            memo: undefined,
            purchaseOrderNumber: undefined
          }
        })

        if (res.data && !res.error) {
          displayNotification('New draft credit note added', {
            type: 'success'
          })
          return onSuccess(res.data)
        }

        displayNotification('Error creating credit note', {
          type: 'error'
        })
        return handleFormResponseV2(res, form.getRegisteredFields())
      }}
      render={formProps => (
        <ModalForm
          {...formProps}
          title="Create draft credit note"
          submitLabel="Save draft credit note"
          variant={variant}
        >
          <CurrencyInputWrapper width="100%" />
          <Box height={5} />
          {customer ? (
            <>
              <Label>Customer</Label>
              <Box height={2} />
              <Flex flexDirection="row" alignItems="center">
                <Avatar name={customer.legalName} mr={2} size="sm" />
                <Text>{customer.legalName}</Text>
              </Flex>
            </>
          ) : (
            <CustomerComboInputComplete
              fieldName="customerId"
              /**
               * TODO: This is incorrect, and should use a credit notes scoped
               * customer drawer. https://linear.app/sequence-hq/issue/BADGE-2892/add-credit-notes-customer-drawer
               */
              addNewAction={() => navigate('/invoices/customers/new')}
              validate={required}
            />
          )}
        </ModalForm>
      )}
    />
  )
}

export default CreateCreditNoteForm
