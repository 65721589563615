import { useCallback, useMemo, useState } from 'react'
import { Currency, toCurrencySymbol } from '@sequencehq/core-models'
import {
  useDefaultCurrency,
  useEnabledCurrencies
} from 'components/CurrencySettings/useCurrencies'
import type { XYChartItem } from '@sequencehq/core-components'
import { format } from '@sequencehq/utils/dates'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type InvoicedRevenueData = XYChartItem & {
  //TODO: apply when api data is available
  breakdown?: {
    current: number
    overdue: number
    paid: number
  }
}

interface DateRange {
  start: Date
  end: Date
}

export const useInvoicedRevenueWidget = (dateRange: DateRange) => {
  const res = useEnabledCurrencies()
  const enabledCurrencies = useMemo(() => {
    if (res.status === 'SUCCESS') {
      return res.enabledCurrencies
    }
    return []
  }, [res])
  const currencyOptions = useMemo(
    () =>
      enabledCurrencies.map(currency => ({
        value: currency,
        label: currency
      })),
    [enabledCurrencies]
  )

  const [currency, setCurrency] = useState(useDefaultCurrency() || 'GBP')

  const invoicedRevenueQuery = useQuery(
    dashboard20240730Client.getInsightsInvoicedRevenue,
    {
      currency,
      dateFrom: format(dateRange.start, 'yyyy-MM-dd'),
      dateTo: format(dateRange.end, 'yyyy-MM-dd')
    }
  )

  const insightsInvoicedRevenue = invoicedRevenueQuery.data

  const transformedData = useMemo(() => {
    if (!insightsInvoicedRevenue) {
      return []
    }
    return insightsInvoicedRevenue.invoicedRevenueByMonth.map(
      ({ amount, periodStart }) => ({
        value: amount,
        date: periodStart
      })
    )
  }, [insightsInvoicedRevenue])

  const tickValues = useMemo(() => {
    if (transformedData.length === 0) {
      return [0, 0]
    }
    const max = Math.max(...transformedData.map(d => d.value))
    return [0, max / 2, max]
  }, [transformedData])

  const currencyField = {
    value: currency,
    label: currency,
    onChange: (newCurrency: string) => setCurrency(newCurrency as Currency),
    options: currencyOptions
  }

  const tooltipFormat = useCallback(
    (value: number) =>
      `${toCurrencySymbol(currencyField.value)}${Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(value)}`,
    [currencyField.value]
  )

  return {
    data: transformedData,
    total: insightsInvoicedRevenue?.totalAmount || 0,
    tickValues,
    tooltipFormat,
    isLoading: invoicedRevenueQuery.isPending,
    currencyField
  }
}
