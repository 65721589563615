import { formatDecimalPlaces } from '@sequencehq/formatters'
import { parseFloatOrZero } from 'lib/parsers'
import mapValues from 'lodash/mapValues'

interface Totals {
  netTotal: string
  totalTax: string
  grossTotal: string
}

interface LineItem extends Totals {
  quantity: string
  taxRate: string
  rate: string
  selected: boolean
  deleted: boolean
}

export function recalculateCreditNote<
  CreditNote extends Totals & {
    lineItemGroups: (Totals & { lineItems: LineItem[] })[]
  }
>(creditNote: CreditNote): CreditNote {
  const lineItemGroups = creditNote.lineItemGroups.map(group => {
    const lineItems = group.lineItems.map(recalculateLineItem)
    const totals = recalculateTotals(
      lineItems.filter(item => item.selected && !item.deleted)
    )

    return {
      ...group,
      lineItems,
      ...totals
    }
  })

  return { ...creditNote, lineItemGroups, ...recalculateTotals(lineItemGroups) }
}

function recalculateTotals(items: Totals[]): Totals {
  const totals = items.reduce(
    (memo, item) => {
      memo.netTotal += parseFloatOrZero(item.netTotal)
      memo.totalTax += parseFloat(item.totalTax)
      memo.grossTotal += parseFloat(item.grossTotal)
      return memo
    },
    { netTotal: 0, totalTax: 0, grossTotal: 0 }
  )

  const stringTotals = mapValues(totals, value => value.toString())

  return stringTotals
}

function recalculateLineItem(lineItem: LineItem) {
  const netTotal =
    parseFloatOrZero(lineItem.rate) * parseFloatOrZero(lineItem.quantity)
  const tax = netTotal * parseFloat(lineItem.taxRate)
  const grossTotal = netTotal + tax

  return {
    ...lineItem,
    netTotal: lineItem.netTotal == '' ? '' : formatDecimalPlaces(netTotal, 2),
    totalTax: formatDecimalPlaces(tax, 2),
    grossTotal: formatDecimalPlaces(grossTotal, 2)
  }
}
