import { Box, Text, List, ListItem, Center, Spinner } from '@chakra-ui/react'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  GreyGrey10,
  GreyGrey70,
  GreyGrey80,
  Lato14Regular
} from '@sequencehq/design-tokens'

type CustomersMagicTableTypeaheadProps = {
  value: string
  setValue: (newValue: string) => void
}

export const CustomersMagicTableTypeahead = ({
  value,
  setValue
}: CustomersMagicTableTypeaheadProps) => {
  const customersResult = useQuery(dashboard20240730Client.getCustomers, {
    limit: value ? 50 : 5,
    legalName: value
  })

  const { isLoading, data } = customersResult
  const items = data?.items ?? []

  if (isLoading) {
    return (
      <Center padding="8px" width="200px">
        <Spinner />
      </Center>
    )
  }

  return (
    <List padding="8px" width="200px" maxHeight="320px" overflowY="scroll">
      {items.length === 0 && (
        <Box padding="8px" textAlign="center">
          <Text color={GreyGrey70} {...Lato14Regular}>
            No results found
          </Text>
        </Box>
      )}

      {items.map(i => {
        return (
          <ListItem
            key={i.id}
            onClick={() => setValue(i.legalName)}
            padding="8px"
            borderRadius="6px"
            _hover={{ background: GreyGrey10 }}
            cursor="pointer"
          >
            <Text color={GreyGrey80} {...Lato14Regular}>
              {i.legalName}
            </Text>
          </ListItem>
        )
      })}
    </List>
  )
}
