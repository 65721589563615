import { dequal } from 'dequal'
import { useNotifications } from 'lib/hooks/useNotifications'
import isEmpty from 'lodash/fp/isEmpty'
import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

export type UseIntegrationManagementActions = () => {
  modals: {
    remove: {
      active: boolean
      onClose: () => void
      onSuccess: (args: { removeLinks: boolean }) => Promise<void>
      service: ResolvedIntegration
    }
  }
  actions: {
    remove: {
      label: string
      disabled: boolean
      onClick: () => void
    }
    save: {
      label: string
      saving: boolean
      disabled: boolean
      onClick: () => Promise<void>
      hidden: boolean
    }
  }
  blocker: {
    enabled: boolean
    submitting: boolean
    label: string
  }
}

export const useIntegrationManagementActions: UseIntegrationManagementActions =
  () => {
    const { integrationId } = useParams<{ integrationId: string }>()
    const navigate = useNavigate()
    const { displayNotification } = useNotifications()
    const ctx = useIntegrationsDomainContext()

    const { updateEditor } = ctx.mutators
    useEffect(() => {
      updateEditor({
        configuration: {
          showValidationErrors: false
        }
      })
    }, [integrationId, updateEditor])

    const integration: ResolvedIntegration | null = useMemo(() => {
      if (!integrationId) {
        return null
      }

      return (
        ctx.queries.resolvedIntegrations[
          integrationId as ResolvedIntegration['id']
        ] ?? null
      )
    }, [ctx.queries.resolvedIntegrations, integrationId])

    invariant(integration, 'Integration not found')

    const [removeIntegrationModalActive, setRemoveIntegrationModalActive] =
      useState(false)

    const saveAvailable = useMemo(() => {
      if (
        integration.features?.disableSubmitUntilValid &&
        !ctx.queries.rawData.editor.configuration.valid
      ) {
        return false
      }

      if (
        ctx.queries.rawData.editor.updating ||
        !ctx.queries.rawData.editor.configuration.dataToSave
      ) {
        return false
      }

      return !dequal(
        integration.configuration,
        ctx.queries.rawData.editor.configuration.dataToSave
      )
    }, [
      integration,
      ctx.queries.rawData.editor.updating,
      ctx.queries.rawData.editor.configuration.dataToSave
    ])

    return {
      blocker: {
        enabled: saveAvailable,
        submitting: ctx.queries.rawData.editor.updating,
        label: `${integration.title} configuration editor`
      },
      modals: {
        remove: {
          service: integration,
          active: removeIntegrationModalActive,
          onClose: () => setRemoveIntegrationModalActive(false),
          onSuccess: async ({ removeLinks }: { removeLinks: boolean }) => {
            const { success } =
              await ctx.mutators.external.out.removeIntegration(
                integration.id,
                { removeLinks }
              )

            if (!success) {
              displayNotification(`Could not uninstall ${integration.title}`, {
                type: 'error'
              })
              return
            }

            setRemoveIntegrationModalActive(false)
            displayNotification(`${integration.title} uninstalled`, {
              type: 'success'
            })
            navigate('/home')
          }
        }
      },
      actions: {
        remove: {
          disabled:
            !ctx.queries.availableFeatures.integrations[integration.id]
              ?.canUninstall.available.enabled,
          label: 'Remove integration',
          onClick: () => {
            setRemoveIntegrationModalActive(true)
          }
        },
        save: {
          label: ctx.queries.rawData.editor.updating
            ? 'Saving...'
            : 'Save changes',
          disabled: !saveAvailable,
          hidden: isEmpty(integration.configuration),
          saving: ctx.queries.rawData.editor.updating,
          onClick: async () => {
            if (!ctx.queries.rawData.editor.configuration.valid) {
              ctx.mutators.updateEditor({
                configuration: {
                  showValidationErrors: true
                }
              })
              return
            }

            const res =
              await ctx.mutators.external.out.updateIntegrationConfiguration(
                integration.id
              )

            if (res.success) {
              displayNotification(
                `${integration.title} configuration updated`,
                {
                  type: 'success'
                }
              )

              ctx.mutators.updateEditor({
                configuration: {
                  dataToSave: undefined
                }
              })
              return
            }

            displayNotification(
              `Could not update ${integration.title} configuration`,
              {
                type: 'error'
              }
            )
          }
        }
      }
    }
  }
