import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  CompoundInputFieldNew,
  TagInput,
  TagInputProps
} from '@sequencehq/forms'
import { FieldValidator } from 'final-form'
import { FC, useState } from 'react'

type TagValue = {
  id: string
  value: string
}

type UsageEventPropertyInputProps = {
  fieldName: string
  fieldLabel?: string
  validate?: FieldValidator<string>
  filterNames?: string[]
}

export const UsageEventPropertyInput: FC<UsageEventPropertyInputProps> = ({
  fieldName,
  fieldLabel,
  validate,
  filterNames = []
}) => {
  const [query, setQuery] = useState('')

  const usageEventPropertiesQuery = useQuery(
    dashboard20240730Client.getUniqueUsageEventProperties
  )

  const eventProperties =
    usageEventPropertiesQuery.data?.items
      .filter(item => !filterNames.includes(item))
      .map(v => ({ id: v, value: v })) ?? []

  return (
    <CompoundInputFieldNew<TagInputProps<TagValue>>
      fieldName={fieldName}
      fieldLabel={fieldLabel}
      validate={validate}
      Component={TagInput}
      placeholder="Select event property"
      items={eventProperties}
      query={query}
      setQuery={setQuery}
      itemToContent={item => ({ title: item.value })}
      filterTags={filterNames}
    />
  )
}
