import { FC, MutableRefObject, useEffect, useMemo, useState } from 'react'
import {
  InfoPopoverProps,
  CompoundInputFieldNew,
  ComboInputRemoteFilterNew
} from '@sequencehq/forms'
import { FieldValidator } from 'final-form'
import { useDebouncedCallback } from 'use-debounce'
import { useLoadAllCustomersWithAliases } from 'components/UsageEvents/useLoadAllCustomersWithAliases'

type CustomerComboInputProps = {
  id?: string
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
  inputRef: MutableRefObject<HTMLInputElement | null>
  postChange?: () => void
  handleBlur?: () => void
  addNewAction?: () => void
  setModelId: (id: string | undefined) => void
  validate: FieldValidator<string> | undefined
}

export const CustomerComboInput: FC<CustomerComboInputProps> = ({
  disabled,
  ...rest
}) => {
  /**
   * Debounce the lookup so we don't spam the API on every letter typed!
   */
  const [inputQuery, setInputQuery] = useState('')
  const [model, setModel] = useState<{
    id: string
    legalName: string
  } | null>(null)
  /**
   * Given we already have this information in the cache, making use of it
   * is more network efficient. We will eventually need to move
   * to some good - standard - search based customer inputs. But, for now,
   * make use of the same approach as the other customer inputs and take
   * advantage of the cache to simplify and speed things up.
   */
  const { isLoading, customersWithAliases } = useLoadAllCustomersWithAliases()

  const allCustomers = useMemo(() => {
    return (
      customersWithAliases?.map(({ id, customerName }) => ({
        id,
        legalName: customerName
      })) ?? []
    )
  }, [customersWithAliases])

  const filteredItems = useMemo(() => {
    return allCustomers.filter(c =>
      c.legalName.toUpperCase().includes(inputQuery.toUpperCase())
    )
  }, [allCustomers, inputQuery])

  return (
    <CompoundInputFieldNew<
      ComboInputRemoteFilterNew<{
        id: string
        legalName: string
      }>
    >
      items={filteredItems}
      itemToString={item => item?.legalName ?? ''}
      itemToContent={({ legalName }) => ({
        title: legalName
      })}
      model={model}
      modelId={model?.id}
      setQuery={setInputQuery}
      query={inputQuery}
      placeholder="Search for a customer"
      disabled={isLoading || disabled}
      Component={ComboInputRemoteFilterNew}
      {...rest}
      handleBlur={() => {
        setInputQuery(model?.legalName ?? '')
      }}
      setModelId={id => {
        rest.setModelId(id)
        setModel(allCustomers.find(c => c.id === id) ?? null)
      }}
    />
  )
}
