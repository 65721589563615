import { Summary } from 'RevenueRecognition/view/common/Summary'

import { Img, Text } from '@chakra-ui/react'
import { Pill } from '@sequencehq/core-components'
import { Link } from 'react-router-dom'
import { CustomerPreviewCard } from 'Customer/components/CustomerPreviewCard'
import { PopoverWrapper } from '@sequencehq/core-components'
import { useDefRevScheduleSummary } from 'RevenueRecognition/view/deferredRevenue/content/DefRevScheduleDetailDrawer/common/DefRevScheduleSummary'
import { getLogoSrc } from 'Customer/utils/logo'
import { format } from '@sequencehq/utils/dates'
import {
  GreyGrey70,
  Lato13Regular,
  IndigoIndigo50
} from '@sequencehq/design-tokens'
import { IdentificationIcon } from '@heroicons/react/24/solid'
import { humanize } from 'inflection'

export const DeferredRevenueDetailDrawerSummary = ({
  defRevScheduleId
}: {
  defRevScheduleId: string
}) => {
  const { isLoading, summary } = useDefRevScheduleSummary({
    scheduleId: defRevScheduleId
  })

  const summaryRows = [
    {
      title: 'Customer',
      value: summary?.customer ? (
        <PopoverWrapper
          popover={<CustomerPreviewCard customerId={summary.customer.id} />}
          offset={[-4, 4]}
          openDelay={850}
        >
          <Link
            to={`/customers/${summary.customer.id}`}
            data-testid="revrec.journalDetail.summary.customer"
          >
            <Pill
              icon={
                summary.customer.domain ? (
                  <Img
                    height="16px"
                    width="16px"
                    src={getLogoSrc({
                      domain: summary.customer.domain
                    })}
                  />
                ) : (
                  <IdentificationIcon
                    height="16px"
                    width="16px"
                    color={GreyGrey70}
                  />
                )
              }
              interactive
              variant="ghost"
            >
              {summary.customer.name}
            </Pill>
          </Link>
        </PopoverWrapper>
      ) : (
        '-'
      )
    },
    {
      title: 'Invoice',
      value: summary?.invoice ? (
        <Link
          to={`/invoices/${summary.invoice.id}`}
          data-testid="revrec.journalDetail.summary.product"
        >
          <Text {...Lato13Regular} color={IndigoIndigo50} fontWeight="semibold">
            {summary.invoice.name}
          </Text>
        </Link>
      ) : (
        '-'
      )
    },
    {
      title: 'Product',
      value: summary?.product ? (
        <Link
          to={`/products/${summary.product.id}`}
          data-testid="revrec.journalDetail.summary.product"
        >
          <Text {...Lato13Regular} color={IndigoIndigo50} fontWeight="semibold">
            {summary.product.name}
          </Text>
        </Link>
      ) : (
        '-'
      )
    },
    {
      title: 'Method',
      value: summary?.method ? humanize(summary.method) : '-'
    },
    {
      title: 'Recognition term',
      value: summary?.recognitionTerm
        ? format(summary.recognitionTerm.start, 'd MMM yyyy') +
          ' - ' +
          format(summary.recognitionTerm.end, 'd MMM yyyy')
        : ''
    }
  ]

  return <Summary rows={summaryRows} isLoading={isLoading} />
}
