import { Grid, Link, List, ListItem, Text } from '@chakra-ui/react'
import ArrowRightIcon from '@heroicons/react/20/solid/ArrowRightIcon'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Badge } from '@sequencehq/core-components'
import {
  IndigoIndigo50,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { PropsWithChildren } from 'react'

const SnapshotInformation = ({
  snapshot
}: {
  snapshot: {
    id: string
    eventTimestamp: string
    seatType: string
    customerAlias: string
    total: number
  }
}) => {
  const { id, eventTimestamp, seatType, customerAlias, total } = snapshot

  const { data: customer } = useQuery(
    dashboard20240730Client.getCustomerByAlias,
    {
      alias: customerAlias
    }
  )

  return (
    <>
      <List spacing={3.5}>
        <LineItem>
          <Text {...Lato14Regular}>Event ID</Text>
          <Text {...Lato14Regular} data-testid="seatEvent.inspector.eventId">
            {id}
          </Text>
        </LineItem>
        <LineItem>
          <Text {...Lato14Regular}>Timestamp</Text>
          <Text {...Lato14Regular} data-testid="seatEvent.inspector.timestamp">
            {eventTimestamp}
          </Text>
        </LineItem>
        <LineItem>
          <Text {...Lato14Regular}>Seat type</Text>
          <Badge
            sentiment="neutral"
            size="sm"
            data-testid="seatEvent.inspector.seatType"
          >
            {seatType}
          </Badge>
        </LineItem>
        {customer && (
          <LineItem>
            <Text {...Lato14Regular}>Customer alias</Text>
            <Text
              {...Lato14Regular}
              data-testid="seatEvent.inspector.customerAlias"
            >
              {customer?.legalName}
            </Text>
          </LineItem>
        )}
        <LineItem>
          <Text {...Lato14Regular}>Total (new balance)</Text>
          <Text {...Lato14Regular} data-testid="seatEvent.inspector.total">
            {total}
          </Text>
        </LineItem>
      </List>

      {customer && (
        <Grid gap={2} mt={4}>
          <Text
            as={Link}
            {...Lato14Bold}
            href={`/billing-schedules/?customerId=${customerAlias}`}
            color={IndigoIndigo50}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <ArrowRightIcon height="16px" width="16px" className="inline" />
            View billing schedules for {customer?.legalName}
          </Text>
          <Text
            as={Link}
            {...Lato14Bold}
            href={`/customers/${customerAlias}`}
            color={IndigoIndigo50}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <ArrowRightIcon height="16px" width="16px" className="inline" />
            View customer profile
          </Text>
        </Grid>
      )}
    </>
  )
}

const LineItem = ({ children }: PropsWithChildren) => {
  return (
    <ListItem
      minH={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {children}
    </ListItem>
  )
}

export default SnapshotInformation
