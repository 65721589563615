import { useQuery } from '@sequencehq/api/dist/utils'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type TaxRegistration =
  DashboardApi20240730.GetTaxRegistrations.TaxRegistration

export const useLoadTaxRegistration = ({
  customerId
}: {
  customerId?: string
}) => {
  const fetchTaxRegistration = useQuery(
    dashboard20240730Client.getTaxRegistrations,
    {
      customerId: customerId ?? ''
    },
    {
      enabled: Boolean(customerId)
    }
  )

  const taxRegistrations = fetchTaxRegistration.data?.items ?? []
  const taxRegistration =
    taxRegistrations.length > 0
      ? taxRegistrations[taxRegistrations.length - 1]
      : undefined
  const data = taxRegistration ? { taxRegistration } : undefined

  return {
    loading: fetchTaxRegistration.isLoading,
    data
  }
}
