import { Center, Flex, Spinner, Box, Text } from '@chakra-ui/react'
import { toMoney } from '@sequencehq/core-models'
import {
  MagicTable,
  MagicTableCell,
  MagicTableEmptyState,
  MagicTableFilterEmptyState,
  RowSpanningRule
} from '@sequencehq/tables'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { useState } from 'react'
import { useSelectedCurrency } from '../../utils/useSelectedCurrency'
import { useSelectedDateRange } from '../../utils/useSelectedDateRange'
import { format } from '@sequencehq/utils/dates'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { Link, useNavigate } from 'react-router-dom'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { RectangleStackIcon as SolidRectangleStackIcon } from '@heroicons/react/24/solid'
import { humanize } from 'inflection'
import { RevRecDashboardDateFilter } from './RevRecDashboardDateFilter'
import { RevRecDashboardCurrencyFilter } from './RevRecDashboardCurrencyFilter'
import { CellContext } from '@tanstack/react-table'
import { useRevRecDashboardTableQuery } from './useRevRecDashboardTableQuery'
import { RevRecDashboardGraphs } from './graphs/RevRecDashboardGraphs'
import { JournalDetailOverviewDrawer } from './JournalDetailOverviewDrawer'
import { isSamePeriod } from 'RevenueRecognition/view/utils/isSamePeriod'
import { IndigoIndigo50, Lato13Regular } from '@sequencehq/design-tokens'

export type RevRecTableJournalEntry = {
  id: string
  period: string
  customer?: {
    id: string
    name: string
  }
  product?: {
    id: string
    name: string
  }
  invoice?: {
    id: string
    name: string
  }
  journalType?: 'MANUAL' | 'SYSTEM'
  ledgerAccount: {
    name: string
    debit?: number
    credit?: number
  }
}

type JournalFilters = {
  currency?: string[]
  dateFrom?: string
  dateTo?: string
}

type JournalView = {
  model: RevRecTableJournalEntry
  controlFilters: JournalFilters
  propFilters: Record<string, unknown>
}

export type RevRecTableGroupBy = 'customer' | 'product' | 'period' | undefined

const columnOrderByGroup: Record<string, string[]> = {
  customer: [
    'customer',
    'period',
    'product',
    'invoice',
    'ledger',
    'debit',
    'credit'
  ],
  product: [
    'product',
    'period',
    'customer',
    'invoice',
    'ledger',
    'debit',
    'credit'
  ],
  period: [
    'period',
    'customer',
    'product',
    'invoice',
    'ledger',
    'debit',
    'credit'
  ]
}

const getOrderedColumns = (
  selectedCurrency: Currency | null,
  groupBy: RevRecTableGroupBy
) => {
  const baseColumns = [
    {
      id: 'period',
      header: 'Period',
      accessorFn: (row: RevRecTableJournalEntry) =>
        format(new Date(row.period), 'MMMM yyyy'),
      cell: (value: CellContext<RevRecTableJournalEntry, unknown>) => (
        <MagicTableCell
          text={value.getValue<string>()}
          textAlign={value.column.columnDef?.meta?.textAlign}
        />
      )
    },
    {
      id: 'customer',
      header: 'Customer',
      accessorFn: (row: RevRecTableJournalEntry) => row.customer?.name,
      cell: (value: CellContext<RevRecTableJournalEntry, unknown>) => {
        const customer = value.row.original.customer
        if (!customer) {
          return null
        }
        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            height="40px"
            pl="6px"
          >
            <CustomerPreviewCardPill customerId={customer.id} variant="ghost" />
          </Flex>
        )
      }
    },
    {
      id: 'product',
      header: 'Product',
      accessorFn: (row: RevRecTableJournalEntry) => row.product?.name,
      cell: (value: CellContext<RevRecTableJournalEntry, unknown>) => {
        const product = value.row.original.product

        if (!product) {
          return null
        }

        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            height="40px"
            pl="6px"
          >
            <Link
              to={`/products/${product.id}`}
              data-testid="revrec.journalDetail.summary.product"
            >
              <Text
                {...Lato13Regular}
                color={IndigoIndigo50}
                fontWeight="semibold"
              >
                {product.name}
              </Text>
            </Link>
          </Flex>
        )
      }
    },
    {
      id: 'invoice',
      header: 'Invoice',
      accessorFn: (row: RevRecTableJournalEntry) => row.invoice?.name,
      cell: (value: CellContext<RevRecTableJournalEntry, unknown>) => {
        const invoice = value.row.original.invoice

        if (!invoice) {
          return null
        }

        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            height="40px"
            pl="6px"
          >
            <Link
              to={`/invoices/${invoice.id}`}
              data-testid="revrec.journalDetail.summary.product"
            >
              <Text
                {...Lato13Regular}
                color={IndigoIndigo50}
                fontWeight="semibold"
              >
                {invoice.name}
              </Text>
            </Link>
          </Flex>
        )
      }
    },
    {
      id: 'ledger',
      header: 'Ledger',
      accessorFn: (row: RevRecTableJournalEntry) => row.ledgerAccount.name,
      cell: (value: CellContext<RevRecTableJournalEntry, unknown>) => (
        <MagicTableCell text={humanize(value.getValue<string>())} />
      )
    },
    {
      id: 'debit',
      header: 'Debit',
      accessorFn: (row: RevRecTableJournalEntry) => {
        const totalDebit = row.ledgerAccount.debit
        return {
          currencyCode: selectedCurrency as Currency,
          debitValue: totalDebit
        }
      },
      cell: (value: CellContext<RevRecTableJournalEntry, unknown>) => {
        const { currencyCode, debitValue } = value.getValue<{
          currencyCode: Currency
          debitValue: number
        }>()

        return (
          <MagicTableCell
            text={toMoney({
              currency: currencyCode,
              value: debitValue?.toString()
            })}
            textAlign="right"
          />
        )
      },
      meta: { textAlign: 'right' as const }
    },
    {
      id: 'credit',
      header: 'Credit',
      accessorFn: (row: RevRecTableJournalEntry) => {
        const totalCredit = row.ledgerAccount.credit
        return {
          currencyCode: selectedCurrency as Currency,
          creditValue: totalCredit
        }
      },
      cell: (value: CellContext<RevRecTableJournalEntry, unknown>) => {
        const { currencyCode, creditValue } = value.getValue<{
          currencyCode: Currency
          creditValue: number
        }>()

        return (
          <MagicTableCell
            text={toMoney({
              currency: currencyCode,
              value: creditValue?.toString()
            })}
            textAlign="right"
          />
        )
      },
      meta: { textAlign: 'right' as const }
    }
  ]

  if (!groupBy) {
    return baseColumns
  }

  const currentOrder = columnOrderByGroup[groupBy]
  return baseColumns.sort((a, b) => {
    const aIndex = currentOrder.indexOf(a.id)
    const bIndex = currentOrder.indexOf(b.id)
    return aIndex - bIndex
  })
}

export const RevRecDashboardTable = () => {
  const navigate = useNavigate()
  const { currency: selectedCurrency } = useSelectedCurrency()
  const { dateRange } = useSelectedDateRange()
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [groupBy, setGroupBy] = useState<RevRecTableGroupBy>(undefined)
  const [selectedJournalId, setSelectedJournalId] = useState<string | null>(
    null
  )

  const journalQuery = useRevRecDashboardTableQuery({
    groupBy,
    dateRange,
    selectedCurrency
  })

  const infiniteQuery = {
    data: {
      pages: [
        {
          items: journalQuery.data?.journals ?? [],
          pagination: {
            after: '',
            before: '',
            totalResultSize: journalQuery.data?.journals?.length ?? 0
          }
        }
      ]
    },
    isFetching: journalQuery.isPending,
    hasNextPage: false,
    isFetchingNextPage: false,
    fetchNextPage: () => Promise.resolve()
  }

  if (journalQuery.isPending) {
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    )
  }

  const columns = getOrderedColumns(selectedCurrency, groupBy)

  const rowSpanningRules: RowSpanningRule<RevRecTableJournalEntry>[] = [
    {
      columnId: 'period',
      spanBy: (
        row1: RevRecTableJournalEntry,
        row2: RevRecTableJournalEntry
      ) => {
        if (groupBy === 'period') {
          return isSamePeriod(row1.period, row2.period)
        }
        return row1.id === row2.id && isSamePeriod(row1.period, row2.period)
      }
    },
    {
      columnId: 'product',
      spanBy: (
        row1: RevRecTableJournalEntry,
        row2: RevRecTableJournalEntry
      ) => {
        if (groupBy === 'product') {
          return row1.product?.id === row2.product?.id
        }
        return row1.id === row2.id && row1.product?.id === row2.product?.id
      }
    },
    {
      columnId: 'customer',
      spanBy: (
        row1: RevRecTableJournalEntry,
        row2: RevRecTableJournalEntry
      ) => {
        if (groupBy === 'customer') {
          return row1.customer?.id === row2.customer?.id
        }

        return row1.id === row2.id && row1.customer?.id === row2.customer?.id
      }
    },
    {
      columnId: 'ledger',
      spanBy: (
        row1: RevRecTableJournalEntry,
        row2: RevRecTableJournalEntry
      ) => {
        return (
          row1.ledgerAccount.name === row2.ledgerAccount.name &&
          row1.invoice?.id === row2.invoice?.id
        )
      }
    },
    {
      columnId: 'invoice',
      spanBy: (
        row1: RevRecTableJournalEntry,
        row2: RevRecTableJournalEntry
      ) => {
        return row1.id === row2.id && row1.invoice?.id === row2.invoice?.id
      }
    },
    {
      columnId: 'debit',
      spanBy: (
        row1: RevRecTableJournalEntry,
        row2: RevRecTableJournalEntry
      ) => {
        return (
          row1.id === row2.id &&
          row1.ledgerAccount.debit === row2.ledgerAccount.debit
        )
      }
    },
    {
      columnId: 'credit',
      spanBy: (
        row1: RevRecTableJournalEntry,
        row2: RevRecTableJournalEntry
      ) => {
        return (
          row1.id === row2.id &&
          row1.ledgerAccount.credit === row2.ledgerAccount.credit
        )
      }
    }
  ]

  return (
    <Box position="relative" height="100%">
      <JournalDetailOverviewDrawer
        journalId={selectedJournalId}
        selectedCurrency={selectedCurrency}
        closeDrawer={() => setSelectedJournalId(null)}
      />
      <CurrentUserId>
        {userId => (
          <MagicTable<JournalView>
            key={groupBy}
            entityNamePlural="journals"
            sortBy={sortBy}
            onChangeSortBy={setSortBy}
            columns={columns}
            additionalHeaderRows={<RevRecDashboardGraphs />}
            sequenceUserId={userId}
            infiniteQuery={infiniteQuery}
            onRowClick={row => {
              setSelectedJournalId(row.id)
            }}
            enableColumnReordering={false}
            showAddColumn={false}
            additionalToolbarComponentsV3={{
              start: <RevRecDashboardDateFilter />,
              end: <RevRecDashboardCurrencyFilter />
            }}
            emptyStateUnfiltered={entityNamePlural => (
              <MagicTableEmptyState
                entityNamePlural={entityNamePlural}
                imageSrc="/img/empty/invoices.png"
                learnMoreText="Learn more about revenue recognition journals"
                learnMoreHref="https://docs.sequencehq.com/revenue-recognition"
              />
            )}
            emptyStateFiltered={(entityNamePlural, clearFilters) => (
              <MagicTableFilterEmptyState
                entityNamePlural={entityNamePlural}
                entityIcon={SolidRectangleStackIcon}
                actionClearFilters={clearFilters}
                actionViewAll={() => navigate('/revenue-recognition')}
                variant="TABLE"
              />
            )}
            grouping={{
              onGroupBy: columnId => {
                setGroupBy(columnId as RevRecTableGroupBy)
              },
              rowSpanningRules,
              groupableColumns: ['customer', 'product', 'period'],
              groupBy,
              highlightRowGroup: row => row.id,
              getGroupKey: row => {
                switch (groupBy) {
                  case 'customer':
                    return row.customer?.id ?? row.id
                  case 'product':
                    return row.product?.id ?? row.id
                  case 'period': {
                    const date = new Date(row.period)
                    return `${date.getFullYear()}-${date.getMonth()}`
                  }
                  default:
                    return row.id
                }
              }
            }}
          />
        )}
      </CurrentUserId>
    </Box>
  )
}
