import { Center, Spinner } from '@chakra-ui/react'
import { TaxRegistrationModel } from '@sequencehq/core-models'

import { useFetchCustomer } from 'Customer/hooks/useFetchCustomer'
import { useFetchCustomerAliases } from 'Customer/hooks/useFetchCustomerAliases'
import {
  Customer,
  CustomerAlias,
  Integration
} from 'components/Customers/types'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useFetchIntegrations } from 'Customer/hooks/useFetchIntegrations.ts'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type CustomerContextData = {
  customer: Customer
  customerPortalEnabled: boolean
  customerAliases: CustomerAlias[]
  taxRegistration: TaxRegistrationModel | undefined
  integrations: Integration[]
  refetchCustomer: () => Promise<void>
  refetchCustomerAliases: () => Promise<void>
}

const CustomerContext = createContext<CustomerContextData | undefined>(
  undefined
)

export const CustomerContextProvider = ({
  customerId,
  children
}: PropsWithChildren<{ customerId: string }>) => {
  const { customerDetails, refetch: refetchCustomer } =
    useFetchCustomer(customerId)

  const { customerAliases, refetch: refetchCustomerAliases } =
    useFetchCustomerAliases(customerId)

  const merchantQuery = useQuery(
    dashboard20240730Client.getMerchantForSequenceAccount
  )

  const taxRegistrationQuery = useQuery(
    dashboard20240730Client.getTaxRegistrations,
    {
      customerId
    }
  )

  const { integrations } = useFetchIntegrations()

  const context = useMemo(() => {
    if (customerDetails) {
      return {
        customer: customerDetails,
        customerPortalEnabled:
          merchantQuery.data?.customerPortalEnabled || false,
        customerAliases: customerAliases?.items ?? [],
        taxRegistration: (taxRegistrationQuery?.data?.items ?? [])?.[0],
        integrations: integrations ?? [],
        refetchCustomer,
        refetchCustomerAliases
      }
    }
  }, [
    customerDetails,
    merchantQuery.data,
    customerAliases?.items,
    integrations,
    refetchCustomer,
    refetchCustomerAliases
  ])

  if (!context?.customer) {
    return (
      <Center height="100%" width="100%">
        <Spinner />
      </Center>
    )
  }
  return (
    <CustomerContext.Provider value={context}>
      {children}
    </CustomerContext.Provider>
  )
}

export const useCustomerContext = () => {
  const context = useContext(CustomerContext)
  if (!context) {
    throw new Error(
      'useCustomerContext must be used within a CustomerContextProvider'
    )
  }
  return context
}
