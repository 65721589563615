import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { IntegrationServices } from '@sequencehq/api'

type ApiIntegrationCustomer =
  DashboardApi20240730.GetIntegrationCustomersService.IntegrationCustomer
type UseLoadIntegrationCustomers = (props: {
  integrationService: IntegrationServices
}) => {
  loading: boolean
  data?: {
    integrationCustomers: Array<ApiIntegrationCustomer>
  }
}

export const useLoadIntegrationCustomers: UseLoadIntegrationCustomers = ({
  integrationService
}) => {
  const [integrationCustomers, setIntegrationCustomers] = useState<
    Array<ApiIntegrationCustomer> | undefined
  >()
  const [loaded, setLoaded] = useState(false)

  const loadIntegrationCustomers = useCallback(
    async (service: IntegrationServices) => {
      setLoaded(false)
      return await dashboard20240730Client
        .getIntegrationCustomersService(service)
        .then(response => response.data?.items ?? [])
        .finally(() => setLoaded(true))
    },
    []
  )

  useEffect(() => {
    if (integrationService) {
      loadIntegrationCustomers(integrationService)
        .then(loadedIntegrationCustomers => {
          setIntegrationCustomers(loadedIntegrationCustomers)
          setLoaded(true)
        })
        .catch(error => Sentry.captureException(error))
    }
  }, [loadIntegrationCustomers, integrationService])

  const data = useMemo(() => {
    if (!integrationCustomers) {
      return
    }

    return {
      integrationCustomers
    }
  }, [integrationCustomers])

  return {
    loading: !loaded,
    data
  }
}
