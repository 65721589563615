import { Text, VStack, HStack, Box } from '@chakra-ui/react'
import { FC, useState } from 'react'
import {
  GreyGrey30,
  GreyGrey70,
  GreyGrey80,
  Lato13Regular,
  Lato13Bold
} from '@sequencehq/design-tokens'
import {
  Modal,
  ModalContextProvider,
  TextFieldInput
} from '@sequencehq/core-components'
import { ShortcutKey } from './ShortcutKey'
import { ShortcutGroup, leftColumnGroups, rightColumnGroups } from './shortcuts'

interface KeyboardShortcutsOverlayProps {
  isOpen: boolean
  onClose: () => void
}

interface ShortcutListProps {
  groups: ShortcutGroup[]
  searchQuery: string
}

const ShortcutList: FC<ShortcutListProps> = ({ groups, searchQuery }) => {
  const filteredGroups = groups
    .map(group => ({
      ...group,
      shortcuts: group.shortcuts.filter(shortcut =>
        shortcut.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }))
    .filter(group => group.shortcuts.length > 0)

  return (
    <VStack spacing={0} align="stretch">
      {filteredGroups.map((group, groupIndex) => (
        <Box key={group.title}>
          <Box height="40px">
            <Text {...Lato13Bold} color={GreyGrey80} pt={2}>
              {group.title}
            </Text>
          </Box>
          <VStack spacing={3} align="stretch">
            {group.shortcuts.map((shortcut, index) => (
              <HStack key={index} justify="space-between" spacing={4}>
                <Text {...Lato13Regular} color={GreyGrey70}>
                  {shortcut.description}
                </Text>
                <ShortcutKey
                  keys={shortcut.keys}
                  isSimultaneous={shortcut.isSimultaneous}
                />
              </HStack>
            ))}
          </VStack>
          {groupIndex < filteredGroups.length - 1 && <Box height="24px" />}
        </Box>
      ))}
    </VStack>
  )
}

export const KeyboardShortcutsOverlay: FC<KeyboardShortcutsOverlayProps> = ({
  isOpen,
  onClose
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const allGroups = [...leftColumnGroups, ...rightColumnGroups]

  if (!isOpen) return null

  return (
    <ModalContextProvider isOpen={true}>
      <Modal onClose={onClose} width="490px">
        <Modal.Header showCloseButton withBorder>
          Keyboard shortcuts
        </Modal.Header>
        <Box position="relative">
          <Modal.Content padding={0}>
            <Box px={4} pt={4}>
              <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                height="579px"
              >
                <Box mb={4}>
                  <TextFieldInput
                    placeholder="Search keyboard shortcuts"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    height="32px"
                  />
                </Box>
                <Box
                  flex={1}
                  overflowY="auto"
                  marginRight="-24px"
                  paddingRight="24px"
                  paddingBottom="40px"
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: GreyGrey30,
                      borderRadius: '24px'
                    }
                  }}
                >
                  <ShortcutList groups={allGroups} searchQuery={searchQuery} />
                </Box>
              </Box>
              <Box
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                height="40px"
                pointerEvents="none"
                background="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)"
                opacity={0.8}
              />
            </Box>
          </Modal.Content>
        </Box>
      </Modal>
    </ModalContextProvider>
  )
}
