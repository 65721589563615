import { Box, Checkbox, Flex } from '@chakra-ui/react'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { MutationFormProps } from 'components/Form/types'
import { UpdateAndSendInvoiceStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { noReturn } from 'lib/noReturn'
import { QueryParams } from 'lib/types'
import { FC, useState } from 'react'
import { Form } from 'react-final-form'

type FormValues = {
  value: string
}

const SendInvoiceForm: FC<
  MutationFormProps<QueryParams> & UpdateAndSendInvoiceStatusFormProps
> = ({
  id,
  customerName,
  customerEmails,
  billingScheduleId,
  submitForm,
  formId
}) => {
  const flags = useFlags()
  const enableAutoIssueInvoices = flags?.enableAutoIssueInvoices

  const billingScheduleQuery = useQuery(
    dashboard20240730Client.getBillingSchedule,
    {
      id: billingScheduleId ?? ''
    }
  )
  const updateBillingScheduleMutation = useMutation(
    dashboard20240730Client.putBillingSchedule
  )

  const [autoIssue, setAutoIssue] = useState<boolean>(false)

  const showAutoInvoiceCheckbox =
    enableAutoIssueInvoices &&
    billingScheduleQuery.data &&
    !billingScheduleQuery.data.autoIssueInvoices

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          })
        ]

        if (autoIssue && billingScheduleQuery.data) {
          promises.push(
            updateBillingScheduleMutation.mutateAsync({
              id: billingScheduleQuery.data.id,
              body: {
                ...billingScheduleQuery.data,
                autoIssueInvoices: true
              }
            })
          )
        }

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Email this invoice to ${customerName} (${customerEmails.join(
              ', '
            )}). After sending you can no longer edit the invoice. To make further changes, use the Credit Note feature.`}

            {showAutoInvoiceCheckbox && (
              <>
                <Box h="40px"></Box>
                <Box>
                  <Checkbox
                    isChecked={autoIssue}
                    width="100%"
                    pl={3}
                    onChange={e => setAutoIssue(e.target.checked)}
                  >
                    Auto-send future invoices created by this Billing Schedule
                  </Checkbox>
                </Box>
              </>
            )}
            {isSandboxEnv() && (
              <Box pt={5} color={GreyGrey60}>
                Note: No end-customer emails will be sent in {getEnvironment()}.
              </Box>
            )}
          </Flex>
        )
      }}
    />
  )
}

export default SendInvoiceForm
