import { Flex } from '@chakra-ui/react'
import { Button, StandardLayout } from '@sequencehq/core-components'
import { useBillingScheduleId } from 'BillingScheduleHome/utils/useBillingScheduleId'
import { useNavigate } from 'react-router-dom'

const useBillingScheduleHomeHeader = () => {
  const billingScheduleId = useBillingScheduleId()
  const navigate = useNavigate()

  return {
    actions: {
      onEdit: () => navigate(`/billing-schedules/${billingScheduleId}`)
    }
  }
}
export const BillingScheduleHomeHeader = () => {
  const hook = useBillingScheduleHomeHeader()

  return (
    <StandardLayout.Header
      title="Billing Schedule"
      actions={
        <Flex>
          <Button onClick={hook.actions.onEdit}>Edit schedule</Button>
        </Flex>
      }
    />
  )
}
