import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { SequenceMenuItem } from '@sequencehq/core-components'
import { downloadBlob } from '@sequencehq/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import { FC, useCallback } from 'react'

interface DownloadQuotePdfMenuItemProps {
  quoteId: string
  quoteNumber: string
  isPublished: boolean
  onSuccess?: () => void
}

export const DownloadQuotePdfMenuItem: FC<DownloadQuotePdfMenuItemProps> = ({
  quoteId,
  quoteNumber,
  isPublished,
  onSuccess
}) => {
  const notifications = useNotifications()

  const handleDownloadPdf = useCallback(async () => {
    try {
      notifications.displayNotification('Downloading PDF...', {
        type: 'info'
      })

      const response = await dashboardv20240509Client.downloadQuotePdf({
        id: quoteId
      })

      if (!response || !response.data) {
        throw new Error('Failed to download PDF: No data in response')
      }

      downloadBlob(`${quoteNumber}.pdf`)(response.data)

      notifications.displayNotification('PDF downloaded successfully', {
        type: 'success'
      })

      onSuccess?.()
    } catch (error) {
      notifications.displayNotification('Failed to download PDF', {
        type: 'error',
        description: error instanceof Error ? error.message : undefined
      })
    }
  }, [quoteId, quoteNumber, notifications, onSuccess])

  if (!isPublished) {
    return null
  }

  return (
    <SequenceMenuItem
      uuid="download-pdf"
      label="Download PDF"
      data-testid={`quotes.${quoteId}.download-pdf`}
      onClick={handleDownloadPdf}
    />
  )
}
