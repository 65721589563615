import { FC, Fragment, useEffect, useMemo } from 'react'
import {
  isDemoEnv,
  isProductionEnv,
  isSandboxEnv,
  isTestEnv
} from 'lib/environment/environment'
import { redirectToLogin } from 'lib/auth/session'
import { useStytchSession } from 'modules/LoginApp/hooks/useStytchSession'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Wordmark } from '@sequencehq/core-components'
import { Section } from 'modules/LoginApp/components/common/Section'
import Spinner from 'components/Loading/Spinner'
import posthog from 'posthog-js'
import { developerLog } from '@sequencehq/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api'

type Props = { children?: React.ReactNode }

const DashboardAuth: FC<Props> = ({ children }) => {
  const isAtAuthPath = useMemo(() => {
    return window.location.pathname.startsWith('/auth')
  }, [])
  const isAtRootPath = useMemo(() => {
    return window.location.pathname === '/'
  }, [])

  const { data: session, error: sessionError } = useStytchSession()
  const noSelfRequestRequired = !session || isDemoEnv()
  const selfQuery = useQuery(dashboardv99990101Client.getSelf, undefined, {
    enabled: !noSelfRequestRequired
  })

  const self = selfQuery.data

  useEffect(() => {
    /**
     * If we're in an auth path, don't do anything and leave it up to
     * the login process to handle what needs to be done!
     */
    if (!isDemoEnv() && !isAtAuthPath && (sessionError || selfQuery.isError)) {
      redirectToLogin()
    }

    if (isAtAuthPath) {
      return
    }

    if ((isProductionEnv() || isSandboxEnv()) && session && self) {
      developerLog('[Posthog] Identifying user')
      posthog.identify(self.sequenceUser.id, {
        email: self.sequenceUser.email,
        vercelBuild: __DEPLOYMENT_ID__ ?? 'unknown'
      })
    }

    if ((isDemoEnv() || isTestEnv() || (session && self)) && isAtRootPath) {
      window.history.pushState({}, '', '/home')
      return
    }
  }, [
    session,
    sessionError,
    selfQuery.isError,
    isAtRootPath,
    self,
    isAtAuthPath
  ])

  if (isDemoEnv() || (session && self)) {
    return <Fragment>{children}</Fragment>
  }

  return (
    <PageContainer paddingTop="32px">
      <Wordmark width="126" height="26.74" />
      <Section gap="8px">
        <Spinner />
      </Section>
    </PageContainer>
  )
}

export default DashboardAuth
