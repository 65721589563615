import { Flex } from '@chakra-ui/react'
import { Container } from 'components/NavigationV3/components/Container/Container'
import { Header } from 'components/NavigationV3/components/Header/Header'
import { Footer } from 'components/NavigationV3/components/Footer/Footer'
import { NavLinks } from 'components/NavigationV3/components/NavLinks/NavLinks'
import { IntegrationsNavigation } from 'modules/Integrations'
import { SavedViewsNavigation } from 'modules/SavedViews'
import { useKeyboardNavigation } from '../../lib/keyboardShortcuts/useKeyboardNavigation'
import { useNavigationV3Context } from './hooks/useNavigationV3Context'

export const NavigationV3 = () => {
  useKeyboardNavigation()
  const { data } = useNavigationV3Context()
  const { isNavigationCollapsed } = data

  return (
    <Container>
      <Flex
        direction="column"
        width="100%"
        justifyContent={'flex-start'}
        height="100%"
      >
        <Header />
        <NavLinks isCollapsed={isNavigationCollapsed} />
        <SavedViewsNavigation isCollapsed={isNavigationCollapsed} />
        <IntegrationsNavigation isCollapsed={isNavigationCollapsed} />
        <Flex flex="1" />
        <Footer />
      </Flex>
    </Container>
  )
}
