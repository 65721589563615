import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import FinalizeInvoiceForm from 'components/UpdateInvoiceStatus/FinalizeInvoiceForm'
import { UpdateInvoiceStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { QueryParams } from 'lib/types'

const FinalizeInvoiceModal = createFormModal<
  QueryParams,
  UpdateInvoiceStatusFormProps
>({
  FormComponent: FinalizeInvoiceForm,
  useMutation: apiPackageAdapter20240730<
    {
      id: string
    },
    typeof dashboard20240730Client.postFinalizeInvoice
  >('postFinalizeInvoice', ({ id }) => ({ id })),
  modalTitle: 'Finalize Invoice',
  successMessage: 'Invoice finalized',
  submitTitle: 'Finalize Invoice',
  formId: 'finalizeInvoiceForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Invoice ${
        response.invoiceNumber ? response.invoiceNumber : ''
      } finalized`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue',
  errorText: 'Failed to finalize invoice'
})

export default FinalizeInvoiceModal
