import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { RevRecTableJournalEntry } from './RevRecDashboardTable'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { useQuery } from '@sequencehq/api'
import { format } from '@sequencehq/utils/dates'

const sortByPeriod = (
  a: RevRecTableJournalEntry[],
  b: RevRecTableJournalEntry[]
): number => {
  const periodA = new Date(a[0].period)
  const periodB = new Date(b[0].period)
  const yearDiff = periodA.getFullYear() - periodB.getFullYear()
  if (yearDiff !== 0) return yearDiff
  return periodA.getMonth() - periodB.getMonth()
}

const sortByProductName = (
  a: RevRecTableJournalEntry[],
  b: RevRecTableJournalEntry[]
): number => {
  const productNameA = a[0].product?.name || ''
  const productNameB = b[0].product?.name || ''
  return productNameA.localeCompare(productNameB)
}

const sortByCustomerName = (
  a: RevRecTableJournalEntry[],
  b: RevRecTableJournalEntry[]
): number => {
  const customerNameA = a[0].customer?.name || ''
  const customerNameB = b[0].customer?.name || ''
  return customerNameA.localeCompare(customerNameB)
}

const sortByInvoiceName = (
  a: RevRecTableJournalEntry[],
  b: RevRecTableJournalEntry[]
): number => {
  const invoiceNameA = a[0].invoice?.name || ''
  const invoiceNameB = b[0].invoice?.name || ''
  return invoiceNameA.localeCompare(invoiceNameB)
}

const sortByCustomerGrouping = (
  a: RevRecTableJournalEntry[],
  b: RevRecTableJournalEntry[]
): number => {
  const periodComparison = sortByPeriod(a, b)
  if (periodComparison !== 0) return periodComparison

  const productComparison = sortByProductName(a, b)
  if (productComparison !== 0) return productComparison

  return sortByInvoiceName(a, b)
}

const sortByProductGrouping = (
  a: RevRecTableJournalEntry[],
  b: RevRecTableJournalEntry[]
): number => {
  const periodComparison = sortByPeriod(a, b)
  if (periodComparison !== 0) return periodComparison

  const customerComparison = sortByCustomerName(a, b)
  if (customerComparison !== 0) return customerComparison

  return sortByInvoiceName(a, b)
}

const sortByPeriodGrouping = (
  a: RevRecTableJournalEntry[],
  b: RevRecTableJournalEntry[]
): number => {
  const periodComparison = sortByPeriod(a, b)
  if (periodComparison !== 0) return periodComparison

  const customerComparison = sortByCustomerName(a, b)
  if (customerComparison !== 0) return customerComparison

  const productComparison = sortByProductName(a, b)
  if (productComparison !== 0) return productComparison

  return sortByInvoiceName(a, b)
}

const sortBy: Record<
  'customer' | 'product' | 'period',
  (a: RevRecTableJournalEntry[], b: RevRecTableJournalEntry[]) => number
> = {
  customer: sortByCustomerGrouping,
  product: sortByProductGrouping,
  period: sortByPeriodGrouping
}

function transformJournalData(
  entries: RevRecTableJournalEntry[],
  groupBy?: 'customer' | 'product' | 'period'
): RevRecTableJournalEntry[] {
  // First sort all entries by period
  const sortedEntries = [...entries].sort((a, b) => {
    const dateA = new Date(a.period)
    const dateB = new Date(b.period)
    const yearDiff = dateB.getFullYear() - dateA.getFullYear() // Reverse year comparison for newest first
    if (yearDiff !== 0) return yearDiff
    return dateB.getMonth() - dateA.getMonth() // Reverse month comparison for newest first
  })

  // Grouping entries by their journal id to keep debit/credit pairs together
  const entriesByJournal = sortedEntries.reduce(
    (acc, entry) => {
      if (!acc[entry.id]) {
        acc[entry.id] = []
      }
      acc[entry.id].push(entry)
      return acc
    },
    {} as Record<string, RevRecTableJournalEntry[]>
  )

  const result: RevRecTableJournalEntry[] = []

  const getGroupKey = (entry: RevRecTableJournalEntry): string => {
    if (!groupBy) {
      return entry.id // When no grouping is selected, use journal ID to maintain original order
    }

    switch (groupBy) {
      case 'period': {
        const date = new Date(entry.period)
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
      }
      case 'customer':
        return entry.customer?.id || 'no-customer'
      case 'product':
        return entry.product?.id || 'no-product'
      default:
        return entry.id
    }
  }

  // Group journal entries by the primary grouping key
  const groupedJournals = Object.values(entriesByJournal).reduce<
    Record<string, RevRecTableJournalEntry[][]>
  >((acc, journalEntries) => {
    const key = getGroupKey(journalEntries[0])
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(journalEntries)
    return acc
  }, {})

  // Sort the groups by their keys in reverse chronological order for periods
  const sortedGroups = Object.entries(groupedJournals).sort(
    ([keyA], [keyB]) => {
      if (groupBy === 'period') {
        return keyB.localeCompare(keyA) // Reverse comparison for newest first
      }
      return keyA.localeCompare(keyB)
    }
  )

  sortedGroups.forEach(([, groupJournals]) => {
    // Sort within each group using the appropriate sort function
    groupJournals.sort(sortBy[groupBy || 'period'])

    // Flatten all entries in this group while maintaining journal relationships
    groupJournals.forEach(journalEntries => {
      // Sort entries so unbilled comes before billed
      const sortedEntries = [...journalEntries].sort((a, b) =>
        a.ledgerAccount.name.localeCompare(b.ledgerAccount.name)
      )

      // Add each entry while maintaining the relationship
      sortedEntries.forEach(entry => {
        result.push({
          id: entry.id,
          period: entry.period,
          customer: entry.customer,
          product: entry.product,
          invoice: entry.invoice,
          journalType: entry.journalType,
          ledgerAccount: {
            name: entry.ledgerAccount.name,
            debit: entry.ledgerAccount.debit,
            credit: entry.ledgerAccount.credit
          }
        })
      })
    })
  })

  return result
}

export const useRevRecDashboardTableQuery = ({
  groupBy,
  dateRange,
  selectedCurrency
}: {
  groupBy?: 'customer' | 'period' | 'product'
  dateRange: [Date, Date]
  selectedCurrency: Currency | null
}) => {
  const journalQuery = useQuery(
    dashboardv99990101Client.getJournals,
    {
      from: dateRange[0] ? format(dateRange[0], 'yyyy-MM-dd') : '',
      to: dateRange[1] ? format(dateRange[1], 'yyyy-MM-dd') : '',
      currency: selectedCurrency ?? 'USD'
    },
    {
      staleTime: 5000,
      enabled: !!selectedCurrency && !!dateRange[0] && !!dateRange[1],
      select: data => {
        if (!data) {
          return null
        }

        const baseData = {
          totals: {
            debit: parseFloat(data.summary.debits),
            credit: parseFloat(data.summary.credits)
          },
          currency: data.summary.currency
        }

        const allJournalEntries = data.journals.flatMap(journal =>
          journal.entries.map(entry => ({
            id: journal.id,
            period: journal.date,
            customer: journal.customer,
            product: journal.product,
            invoice: journal.invoice,
            journalType: journal.journalType,
            ledgerAccount: {
              name: entry.account.name,
              debit: entry.direction === 'DEBIT' ? entry.amount : undefined,
              credit: entry.direction === 'CREDIT' ? entry.amount : undefined
            }
          }))
        )

        return {
          ...baseData,
          journals: transformJournalData(allJournalEntries, groupBy)
        }
      }
    }
  )

  return journalQuery
}
