import { Box, Grid } from '@chakra-ui/react'
import { useComplexQuery } from '@sequencehq/api'
import { Lato16Bold } from '@sequencehq/design-tokens'
import {
  fetchBillingScheduleAggregate,
  getBillingScheduleAggregateKey
} from 'modules/BillingScheduleHome/utils/useBillingScheduleAggregate'
import { useBillingScheduleId } from 'modules/BillingScheduleHome/utils/useBillingScheduleId'

const usePricingSection = () => {
  const billingScheduleId = useBillingScheduleId()

  const billingScheduleAggregateQuery = useComplexQuery({
    queryFn: () => fetchBillingScheduleAggregate(billingScheduleId),
    queryKey: getBillingScheduleAggregateKey(billingScheduleId),
    staleTime: 60000,
    select: data => {
      return {
        phases: data.billingSchedule.phases
      }
    }
  })

  return {
    isLoading: billingScheduleAggregateQuery.isPending,
    phases: billingScheduleAggregateQuery.data?.phases
  }
}

export const PricingSection = () => {
  const hook = usePricingSection()

  return (
    <Box height="232px">
      <Box {...Lato16Bold} marginBottom="16px">
        Pricing
      </Box>
      <Grid>
        <Box height="200px" overflow="auto">
          <pre>{JSON.stringify(hook.phases, null, 2)}</pre>
        </Box>
      </Grid>
    </Box>
  )
}
