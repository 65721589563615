import { Button, ButtonProps } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'

import { useCreditNotesCsvExport } from './useCreditNotesCsvExport'

export const CreditNotesExportToCsv = () => {
  const [searchParams] = useSearchParams()

  const { download } = useCreditNotesCsvExport()

  const buttonProps: ButtonProps = {
    variant: 'component-library-secondary',
    leftIcon: <ArrowDownTrayIcon width="16px" height="16px" color="initial" />,
    iconSpacing: '4px'
  }

  if (!download.available) {
    return null
  }

  return (
    <Button
      {...buttonProps}
      onClick={() => download.onClick(Object.fromEntries(searchParams))}
    >
      Export to CSV
    </Button>
  )
}
