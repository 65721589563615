import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const useBulkActions = (
  props: {
    refetchInterval?: number
  } = {}
) => {
  const bulkActionsQuery = useQuery(
    dashboard20240730Client.getBulkActions,
    undefined,
    {
      refetchInterval: props.refetchInterval,
      select: res => {
        if (!res?.items) {
          return []
        }
        return res.items
      }
    }
  )

  return {
    bulkActions: bulkActionsQuery.data ?? [],
    isLoading: bulkActionsQuery.isPending
  }
}
