import { ReactNode } from 'react'

import { Flex, Button, Text, Center } from '@chakra-ui/react'

import {
  GreyGrey20,
  Lato13Regular,
  GreyGrey80,
  GreyGrey60,
  GreyGrey40,
  GreyGrey70,
  RedRed50
} from '@sequencehq/design-tokens'
import { XMarkIcon } from '@heroicons/react/16/solid'

type Props = {
  isMenuOpen: boolean
  icon: ReactNode
  labelSelectedOption?: string
  labelWhenNoSelection: string
  handleClear?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  errorMessage?: string
  disabled?: boolean
  more?: number
  'data-testid'?: string
}

export const SidebarSelectControlTrigger = ({
  isMenuOpen,
  icon,
  labelSelectedOption,
  labelWhenNoSelection,
  handleClear,
  errorMessage,
  disabled = false,
  more,
  'data-testid': dataTestId
}: Props) => (
  <Flex
    paddingX="6px"
    minHeight="24px"
    gap="6px"
    borderRadius="6px"
    _hover={!disabled ? { background: GreyGrey20 } : undefined}
    background={isMenuOpen ? GreyGrey20 : undefined}
    justifyContent="center"
    alignItems="center"
    maxWidth="190px"
    data-testid={dataTestId}
  >
    <Center flexShrink="0" color={GreyGrey70}>
      {icon}
    </Center>

    <Text
      {...Lato13Regular}
      color={
        errorMessage ? RedRed50 : labelSelectedOption ? GreyGrey80 : GreyGrey60
      }
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      {errorMessage ?? labelSelectedOption ?? labelWhenNoSelection}
    </Text>

    {typeof more === 'number' && (
      <Center
        height="20px"
        position="relative"
        flexShrink="0"
        _after={{
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: '1px',
          borderBottom: `1px dashed ${GreyGrey70}`
        }}
      >
        <Text {...Lato13Regular} color={GreyGrey70}>
          + {more}
        </Text>
      </Center>
    )}

    {handleClear && !disabled && (
      <Button
        variant="unstyled"
        minWidth="initial"
        minHeight="initial"
        height="initial"
        onClick={handleClear}
        borderRadius="4px"
        _hover={{ background: GreyGrey40 }}
      >
        <XMarkIcon width="16px" height="16px" color={GreyGrey80} />
      </Button>
    )}
  </Flex>
)
