import { Button, ButtonProps } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'
import { useBillingSchedulesCsvExport } from 'modules/BillingSchedules/hooks/useBillingSchedulesCsvExport'

export const BillingSchedulesExportToCsv = () => {
  const [searchParams] = useSearchParams()

  const { download } = useBillingSchedulesCsvExport()

  const buttonProps: ButtonProps = {
    variant: 'component-library-secondary',
    leftIcon: <ArrowDownTrayIcon width="16px" height="16px" color="initial" />,
    iconSpacing: '4px'
  }

  if (!download.available) {
    return null
  }

  return (
    <Button {...buttonProps} onClick={() => download.onClick(searchParams)}>
      Export to CSV
    </Button>
  )
}
