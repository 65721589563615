import { Attachments } from 'modules/Assets/Attachments'
import isEqual from 'lodash/isEqual'
import type { ServerAsset } from '@sequencehq/api/utils/commonModels'
import {
  useSelectQuoteAttachments,
  useStoreQuoteAttachments,
  useShouldDisableModifyQuoteAttachments
} from 'Cube/domain/subdomain/attachments'

export const QuoteAttachments = () => {
  const attachments = useSelectQuoteAttachments()
  const storeAttachments = useStoreQuoteAttachments()
  const disableModifyAttachments = useShouldDisableModifyQuoteAttachments()

  const handleAttachmentChange = (assets: ServerAsset[]) => {
    if (
      !isEqual(
        assets.map(({ id }) => id),
        attachments.map(({ id }) => id)
      )
    ) {
      storeAttachments(assets)
    }
  }

  return (
    <Attachments
      initialAttachments={attachments}
      onChange={handleAttachmentChange}
      disableModifyAttachments={disableModifyAttachments}
    />
  )
}
