import { Text } from '@chakra-ui/react'
import {
  InputSelectControlLegacyStyleBridge,
  ModalContextProvider,
  useModalContext
} from '@sequencehq/core-components'
import { Option } from '@sequencehq/core-models'
import { GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'
import { getPrimaryBillingContact } from 'CustomerContacts/utils/getPrimaryBillingContact'
import { setPrimaryBillingContactById } from 'CustomerContacts/utils/setPrimaryBillingContact'
import { CreateContactModalUI } from 'CustomerContacts/view/modals/CreateContactModal'
import { useEditCustomer } from './hooks/useEditCustomer'
import { FC, useCallback, useEffect, useState } from 'react'
import Spinner from 'components/Loading/Spinner'
import { useCustomerContactsLayout } from 'CustomerContacts/view/layout/useCustomerContactsLayout'

interface Props {
  fieldConfig: ReturnType<typeof useEditCustomer>['fieldsConfig']['contacts']
}

export const ContactSelector: FC<Props> = props => {
  return (
    <ModalContextProvider>
      <ContactSelectorInner {...props} />
    </ModalContextProvider>
  )
}

const ContactSelectorInner: FC<Props> = ({ fieldConfig }) => {
  const { setIsOpen } = useModalContext()
  const [loading, setLoading] = useState<boolean>(false)
  const { refetchCustomer, contacts } = useEditCustomer()
  const [initialLength] = useState(contacts.length)
  const { buttonAddContact } = useCustomerContactsLayout()

  const updateField = useCallback(
    (newPrimaryContactId: string) => {
      fieldConfig.onChange(
        setPrimaryBillingContactById(fieldConfig.value, newPrimaryContactId)
      )
    },
    [fieldConfig]
  )

  const awaitRefetch = async () => {
    await refetchCustomer()
    setLoading(false)
  }

  useEffect(() => {
    if (contacts.length !== initialLength) {
      fieldConfig.onChange(contacts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, initialLength])

  return (
    <>
      <CreateContactModalUI
        billingPreference="PRIMARY"
        onSubmit={() => void awaitRefetch()}
        onCancel={() => setLoading(false)}
      />
      <Text {...Lato14Bold} color={GreyGrey80}>
        Billing contact
      </Text>
      {loading ? (
        <Spinner />
      ) : (
        <InputSelectControlLegacyStyleBridge
          onChange={newPrimaryContactId => {
            updateField(newPrimaryContactId)
          }}
          createLabel="New contact"
          onCreate={() => {
            buttonAddContact.onClick()
            setIsOpen(true)
            setLoading(true)
          }}
          options={fieldConfig.options as unknown as Option[]}
          matchWidth
          initialValue={getPrimaryBillingContact(fieldConfig.value)?.id}
          placeholder="Select or search for a contact"
        />
      )}
    </>
  )
}
