import React, { FC, useEffect, useState } from 'react'
import { BulkActionsPositioner, ScrollFade } from '@sequencehq/core-components'
import { useEnrichedBulkActions } from './hooks/useEnrichedBulkActions'
import isEmpty from 'lodash/isEmpty'
import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useMarkCompletedBulkActionsAsReviewed } from './hooks/useMarkCompletedBulkActionsAsReviewed'
import { usePrevious } from 'lib/usePrevious'
import { BulkActionInFlight } from './BulkActionInFlight'
import { BulkAction } from './types'

const INACTIVE_REFETCH_INTERVAL = 2000
const ACTIVE_REFETCH_INTERVAL = 2000

type Props = {
  fallback: React.ReactNode
  selectionCount?: number
  onBulkActionCountChange?: (numberOfBulkActions: number) => void
}

/**
 * When we load into the module, we don't want to display previously completed
 * that are successful. It is particularly important when releasing this feature,
 * or using the bulk action api directly, as we will have large numbers of unreviewed
 * bulk actions.
 *
 * Unsuccessful bulk actions will still be shown!
 *
 * This means that this is actually quite a fiddly lil' beastie. It needs to:
 * - Only trigger once, once the bulk actions have loaded in for the first time.
 * - Don't cause a 'flicker' of bulk actions that are about to be dismissed
 * - Don't trigger for successful bulk actions that happen whilst the component is mounted.
 *
 * The latter is pretty much impossible to nail, well, since in theory a bulk action may
 * instantly resolve successfully so the first poll result is success. It would require
 * this component to be pre-mounted in the callsite. Which, in itself, is reasonable but can
 * be easily forgotten in novel use cases. It'll be a smidge easier if/when we move this to
 * be a global module that is ever present.
 *
 * Pragmatically, though, that scenario is incredibly unlikely.
 *
 * @param props
 */
const useAutomaticallyReviewSuccessfullyCompletedBulkActionsOnLoad = (props: {
  bulkActionsLoading: boolean
  bulkActions: Array<{
    id: string
    status: BulkAction['status']
    reviewedAt?: BulkAction['reviewedAt']
  }>
}): {
  automaticallyReviewedBulkActionIds: string[]
} => {
  /**
   * We only want to run this once per mount. Once the bulk actions have loaded for the
   * first time, lock things out from running again until a remount.
   */
  const reviewBulkAction = useMutation(
    dashboard20240730Client.postReviewBulkAction
  )
  const [autoCompleted, setAutoCompleted] = useState(false)

  const bulkActionsToAutoComplete = !autoCompleted
    ? props.bulkActions?.filter(
        ba => ba.status === 'COMPLETED' && !ba.reviewedAt
      ) ?? []
    : []

  useEffect(() => {
    if (
      autoCompleted ||
      reviewBulkAction.isPending ||
      props.bulkActionsLoading
    ) {
      return
    }
    Promise.all(
      bulkActionsToAutoComplete.map(bulkAction =>
        reviewBulkAction.mutateAsync({ id: bulkAction.id })
      )
    ).then(() => {
      setAutoCompleted(true)
    })
  }, [
    bulkActionsToAutoComplete,
    autoCompleted,
    props.bulkActionsLoading,
    reviewBulkAction
  ])

  return {
    automaticallyReviewedBulkActionIds: bulkActionsToAutoComplete.map(
      ba => ba.id
    )
  }
}

const useBulkActionsInFlight = (props: {
  selectionCount: number
}) => {
  const [reviewingId, setReviewingId] = useState<string | null>(null)
  const [refetchInterval, setRefetchInterval] = useState(
    INACTIVE_REFETCH_INTERVAL
  )

  // Memoize the refetch interval change logic
  const updateRefetchInterval = React.useCallback((isActive: boolean) => {
    setRefetchInterval(
      isActive ? ACTIVE_REFETCH_INTERVAL : INACTIVE_REFETCH_INTERVAL
    )
  }, [])

  const enrichedBulkActions = useEnrichedBulkActions(refetchInterval)

  // Memoize the active bulk actions check
  const hasActiveBulkActions = React.useMemo(
    () =>
      enrichedBulkActions.bulkActions.some(
        bulkAction => bulkAction.status === 'PROCESSING'
      ),
    [enrichedBulkActions.bulkActions]
  )

  const {
    automaticallyReviewedBulkActionIds: automaticallyReviewedBulkActions
  } = useAutomaticallyReviewSuccessfullyCompletedBulkActionsOnLoad({
    bulkActions: enrichedBulkActions.bulkActions,
    bulkActionsLoading: enrichedBulkActions.isLoading
  })

  // Memoize filtered bulk actions
  const enrichedBulkActionsWithoutAutoCompletedActions = React.useMemo(
    () => ({
      ...enrichedBulkActions,
      bulkActions: enrichedBulkActions.bulkActions.filter(
        ba => !automaticallyReviewedBulkActions.includes(ba.id)
      )
    }),
    [enrichedBulkActions, automaticallyReviewedBulkActions]
  )

  const reviewBulkAction = useMutation(
    dashboard20240730Client.postReviewBulkAction
  )

  const dismiss = React.useCallback(
    (id: string) => {
      reviewBulkAction.mutate({ id })
      setReviewingId(prev => (prev === id ? null : prev))
    },
    [reviewBulkAction]
  )

  // Stabilize the effect with useCallback
  const refetchIntervalEffect = React.useCallback(() => {
    let timeoutId: NodeJS.Timeout | null = null

    if (hasActiveBulkActions !== undefined) {
      timeoutId = setTimeout(() => {
        updateRefetchInterval(hasActiveBulkActions)
      }, 300)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [hasActiveBulkActions, updateRefetchInterval])

  useEffect(refetchIntervalEffect, [refetchIntervalEffect])

  const markCompletedBulkActionsAsReviewed =
    useMarkCompletedBulkActionsAsReviewed()
  const previousSelectionCount = usePrevious(props.selectionCount)

  // Stabilize the selection count effect
  useEffect(() => {
    if (!props.selectionCount) return

    if (
      previousSelectionCount !== props.selectionCount &&
      props.selectionCount > 0
    ) {
      markCompletedBulkActionsAsReviewed()
    }

    return () => {
      if (props.selectionCount > 0) {
        markCompletedBulkActionsAsReviewed()
      }
    }
  }, [
    props.selectionCount,
    previousSelectionCount,
    markCompletedBulkActionsAsReviewed
  ])

  return {
    enrichedBulkActions: enrichedBulkActionsWithoutAutoCompletedActions,
    dismiss,
    reviewingId,
    setReviewingId
  }
}

export const BulkActionsInFlight: FC<Props> = ({
  fallback,
  selectionCount = 0,
  onBulkActionCountChange
}) => {
  const { enrichedBulkActions, dismiss, reviewingId, setReviewingId } =
    useBulkActionsInFlight({
      selectionCount
    })

  const activeBulkActionsCount = enrichedBulkActions.bulkActions.filter(
    ba => ba.status === 'PROCESSING'
  ).length

  const prevActiveBulkActionsCount = usePrevious(activeBulkActionsCount)
  useEffect(() => {
    if (prevActiveBulkActionsCount === activeBulkActionsCount) {
      return
    }
    onBulkActionCountChange?.(activeBulkActionsCount)
  }, [
    activeBulkActionsCount,
    onBulkActionCountChange,
    prevActiveBulkActionsCount
  ])

  if (
    !enrichedBulkActions.bulkActions.length ||
    isEmpty(enrichedBulkActions.invoices)
  )
    return fallback

  return (
    <BulkActionsPositioner>
      <ScrollFade maxHeight={400}>
        {enrichedBulkActions.bulkActions.map(bulkAction => {
          return (
            <BulkActionInFlight
              key={bulkAction.id}
              bulkAction={bulkAction}
              onDismiss={() => dismiss(bulkAction.id)}
              invoices={enrichedBulkActions.invoices}
              isReviewing={reviewingId === bulkAction.id}
              onChangeReviewing={isReviewing =>
                setReviewingId(isReviewing ? bulkAction.id : null)
              }
            />
          )
        })}
      </ScrollFade>
    </BulkActionsPositioner>
  )
}
