import { Flex, Box, IconButton, Portal } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey20,
  GreyGrey30,
  GreyGrey40,
  GreyGrey60,
  GreyGrey70
} from '@sequencehq/design-tokens'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigationV3Context } from 'components/NavigationV3/hooks/useNavigationV3Context'
import ChevronLeftIcon from '@heroicons/react/16/solid/ChevronLeftIcon'
import ChevronRightIcon from '@heroicons/react/16/solid/ChevronRightIcon'
import { useRef, useEffect, useState } from 'react'
import {
  NAVIGATION_ANIMATION,
  getNavigationAnimationStyles
} from 'components/NavigationV3/constants/animation'
import { getWidthAnimationStyles } from 'components/NavigationV3/constants/animationUtils'
import { useTransitionState } from 'components/NavigationV3/hooks/useTransitionState'

// Navigation widths
export const EXPANDED_WIDTH = 190
export const EXPANDED_WIDTH_REV_REC = 206
export const COLLAPSED_WIDTH = 48

export const Container = ({ children }: { children: React.ReactNode }) => {
  /**
   * We track useRevRec because when revenue recognition is enabled we will
   * have a slightly wider navigation to accomodate the module title. However,
   * before then we'll retain the previous width to prevent the Nav being overly
   * wide.
   */
  const flags = useFlags()
  const { data, functions } = useNavigationV3Context()
  const { isNavigationCollapsed } = data
  const { toggleNavigationCollapsed } = functions
  const containerRef = useRef<HTMLDivElement>(null)
  const [buttonPosition, setButtonPosition] = useState({
    top: '50%',
    left: '190px'
  })
  const { isTransitioning, startTransition } = useTransitionState(300)

  // Determine the width based on collapsed state and flags
  const expandedWidth = flags.useRevRec
    ? EXPANDED_WIDTH_REV_REC
    : EXPANDED_WIDTH
  const width = isNavigationCollapsed ? COLLAPSED_WIDTH : expandedWidth

  // Update button position when the container size changes
  useEffect(() => {
    const updatePosition = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        setButtonPosition({
          top: `${rect.top + rect.height / 2}px`,
          left: `${rect.width - 12}px`
        })
      }
    }

    // Initial position
    updatePosition()

    // Update on resize
    window.addEventListener('resize', updatePosition)

    // Update when width changes
    const observer = new ResizeObserver(updatePosition)
    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      window.removeEventListener('resize', updatePosition)
      observer.disconnect()
    }
  }, [width, isNavigationCollapsed])

  const handleToggle = () => {
    startTransition()
    toggleNavigationCollapsed()
  }

  return (
    <>
      <Flex
        ref={containerRef}
        direction="column"
        justifyContent={'flex-start'}
        width={`${width}px`}
        height="100vh"
        bg={GreyGrey0}
        flexShrink="0"
        position="sticky"
        top="0"
        left="0"
        zIndex="2"
        overflow="hidden"
        sx={{
          ...getWidthAnimationStyles(isNavigationCollapsed),
          ...getNavigationAnimationStyles(isNavigationCollapsed)
        }}
      >
        {children}
      </Flex>

      {/* Toggle handle in portal to ensure it's always visible */}
      <Portal>
        <Box
          position="fixed"
          top={buttonPosition.top}
          left={
            isNavigationCollapsed
              ? `${COLLAPSED_WIDTH - 12}px`
              : flags.useRevRec
                ? `${EXPANDED_WIDTH_REV_REC - 12}px`
                : `${EXPANDED_WIDTH - 12}px`
          }
          transform="translateY(-50%)"
          zIndex="999999"
          pointerEvents="auto"
          transition={`left ${NAVIGATION_ANIMATION.WIDTH_TRANSITION}`}
          sx={{
            willChange: 'left, transform',
            transform: 'translateY(-50%) translateZ(0)'
          }}
        >
          <IconButton
            aria-label={isNavigationCollapsed ? 'Expand' : 'Collapse'}
            icon={
              isNavigationCollapsed ? (
                <ChevronRightIcon
                  width="12px"
                  height="12px"
                  color={GreyGrey60}
                  style={{
                    transition: `transform 0.2s ${NAVIGATION_ANIMATION.EASE_OUT}`,
                    transform: isTransitioning ? 'translateX(2px)' : 'none',
                    willChange: 'transform'
                  }}
                />
              ) : (
                <ChevronLeftIcon
                  width="12px"
                  height="12px"
                  color={GreyGrey60}
                  style={{
                    transition: `transform 0.2s ${NAVIGATION_ANIMATION.EASE_OUT}`,
                    transform: isTransitioning ? 'translateX(-2px)' : 'none',
                    willChange: 'transform'
                  }}
                />
              )
            }
            size="md"
            width="24px"
            height="24px"
            minWidth="24px"
            minHeight="24px"
            padding="0"
            borderRadius="full"
            bg="white"
            color={GreyGrey60}
            border={`0.56px solid ${GreyGrey40}`}
            boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
            _hover={{
              bg: 'white',
              color: GreyGrey70,
              transform: 'scale(1.05)',
              boxShadow:
                '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06)'
            }}
            transition="transform 0.2s ease, box-shadow 0.2s ease"
            onClick={handleToggle}
            sx={{
              willChange: 'transform, box-shadow'
            }}
          />
        </Box>
      </Portal>
    </>
  )
}
