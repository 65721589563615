import { useInvoicePdfPreview } from 'InvoiceEditor/components/drawers/InvoicePdfPreview/useInvoicePdfPreview'
import { PDFPreviewDrawer } from 'components/PDFPreviewDrawer/PDFPreviewDrawer'

type Props = {
  isOpen: boolean
  onClose: () => void
  invoiceNumber?: string
}

export const InvoicePdfPreview = ({
  isOpen,
  onClose,
  invoiceNumber
}: Props) => {
  const { previewData } = useInvoicePdfPreview({ isOpen })

  return (
    <PDFPreviewDrawer
      title="Preview invoice"
      fileName={invoiceNumber ? `${invoiceNumber}.pdf` : 'Invoice.pdf'}
      data={previewData}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}
