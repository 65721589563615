import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { useModalContext } from '@sequencehq/core-components'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  dashboardv99990101Client,
  type Dashboardv99990101CommonModels
} from '@sequencehq/api/dist/clients/dashboard/v99990101'

interface LoadedData {
  customer: Dashboardv99990101CommonModels.Customers.Customer | null
  serviceAccounts:
    | DashboardApi20240730.GetIntegrationCustomersService.IntegrationCustomer[]
    | null
}

enum Status {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  LINKING = 'LINKING',
  LOADED = 'LOADED'
}

type FormData = {
  linkToAccount: string
}

type UseLinkCustomerModal = (props: {
  onConfirm?: () => void
  onClose?: () => void
  customerId: string
  service: IntegrationServices
}) => {
  fields: FormFields<FormData>
  customerName: string
  serviceName: string
  loaded: boolean
  externalAccountDetails: {
    name: string
    externalUrl: string
  } | null
  submit: {
    disabled: boolean
    linking: boolean
    onClick: () => void
  }
}

export const useLinkCustomerModal: UseLinkCustomerModal = props => {
  const [status, setStatus] = useState<Status>(Status.UNINITIALIZED)
  const modalContext = useModalContext()
  const { displayNotification } = useNotifications()
  const [loadedData, setLoadedData] = useState<LoadedData>({
    customer: null,
    serviceAccounts: null
  })

  /**
   * Grab the current data, for this service, from the customer object.
   */
  const selectedOption = useMemo(() => {
    return loadedData.customer?.integrationIds.find(
      int => int.service === props.service
    )
  }, [loadedData, props.service])

  const serviceName = useMemo(() => {
    return getIntegrationName(props.service)
  }, [props.service])

  /**
   * Form management
   */
  const accountLinkingOptions = useMemo(() => {
    return loadedData.serviceAccounts
      ?.filter(acc => !acc.isLinked)
      .map(acc => ({
        label: acc.companyName,
        value: acc.id
      }))
  }, [loadedData.serviceAccounts])

  const form = useForm<FormData>({
    value: {
      linkToAccount: selectedOption?.id ?? ''
    },
    fieldConfiguration: [
      {
        property: 'linkToAccount',
        validation: [required],
        options: accountLinkingOptions
      }
    ]
  })

  /**
   * API interactions
   */

  /**
   * A simple load function which will grab the customer and the integration
   * customers for the given service.
   */
  const load = useCallback(
    async ({
      customerId,
      service
    }: {
      customerId: string
      service: IntegrationServices
    }) => {
      setStatus(Status.LOADING)

      const [customer, serviceAccounts] = await Promise.all([
        dashboardv99990101Client.getCustomer({ id: customerId }),
        dashboard20240730Client.getIntegrationCustomersService(service)
      ])

      setLoadedData({
        customer: customer.data ?? null,
        serviceAccounts: serviceAccounts.data?.items ?? null
      })

      setStatus(Status.LOADED)
    },
    []
  )

  /**
   * Link the customer to an account on the third party service.
   */
  const linkAccount = useCallback(async (): Promise<boolean> => {
    if (status === Status.LINKING || !loadedData.customer) {
      return false
    }

    setStatus(Status.LINKING)
    const res = await dashboardv99990101Client.putCustomer({
      id: props.customerId,
      body: {
        ...loadedData.customer,
        contacts: loadedData.customer.contacts.map(contact => ({
          email: contact.email,
          name: contact.name ?? '',
          billingPreference: contact.billingPreference
        })),
        integrationIds: [
          ...(loadedData.customer?.integrationIds ?? []).filter(
            int => int.service !== props.service
          ),
          {
            service: props.service,
            id: form.fields.linkToAccount.value
          }
        ]
      }
    })

    if (res.error) {
      displayNotification(`Could not link customer to ${serviceName}`, {
        type: 'error'
      })
      setStatus(Status.LOADED)
      return false
    }

    displayNotification(`Customer linked to ${serviceName}`, {
      type: 'success'
    })
    setStatus(Status.LOADED)
    return true
  }, [
    form.fields.linkToAccount.value,
    loadedData,
    props.customerId,
    props.service,
    status,
    displayNotification,
    serviceName
  ])

  const externalAccountDetails = useMemo(() => {
    const externalAccount = loadedData.serviceAccounts?.find(
      account => account.id === form.fields.linkToAccount.value
    )

    if (!externalAccount) {
      return null
    }

    return {
      name: externalAccount?.companyName ?? '',
      externalUrl: externalAccount?.externalUrl ?? ''
    }
  }, [loadedData.serviceAccounts, form.fields.linkToAccount.value])

  const submit = useMemo(() => {
    return {
      disabled: !form.queries.isValid || status === Status.LINKING,
      linking: status === Status.LINKING,
      onClick: async () => {
        const success = await linkAccount()
        if (success) {
          modalContext.setIsOpen(false)
          props.onConfirm?.()
        }
      }
    }
  }, [form.queries.isValid, linkAccount, modalContext, props.onConfirm, status])

  /**
   * Side effects
   */
  useEffect(() => {
    void load({ customerId: props.customerId, service: props.service })
  }, [props.customerId, props.service, load])

  return {
    loaded: ![Status.LOADING, Status.LOADING].includes(status),
    fields: form.fields,
    serviceName,
    externalAccountDetails,
    customerName: loadedData.customer?.legalName ?? '',
    submit
  }
}
