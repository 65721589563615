import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  GreyWhite,
  Lato12Bold,
  Lato12Regular,
  ShadowM,
  ShadowXs
} from '@sequencehq/design-tokens'
import {
  SequenceKebabMenu,
  SequenceMenuDivider,
  Skeleton,
  TextOverflow
} from '@sequencehq/core-components'
import {
  CreateQuoteFromTemplateMenuItem,
  useCreateQuoteFromTemplate
} from 'common/menuItems/quotes/CreateQuoteFromTemplateMenuItem'
import { EditQuoteTemplateMenuItem } from './ViewQuoteTemplateMenuItem'
import { ArchiveQuoteTemplateMenuItem } from 'common/menuItems/quotes/ArchiveQuoteTemplateMenuItem'
import {
  differenceInMinutes,
  formatDistanceToNow
} from '@sequencehq/utils/dist/dates'
import { DuplicateQuoteTemplateMenuItem } from 'common/menuItems/quotes/DuplicateQuoteTemplateMenuItem'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNavigate } from 'react-router-dom'
import { ReactQuoteRenderer } from '@sequencehq/quote-content'
import { useEffect, useState } from 'react'
import { PlusIcon } from '@heroicons/react/16/solid'

const useQuoteTemplateItem = (props: {
  quoteTemplateId: string
}) => {
  const navigate = useNavigate()
  const quoteTemplateQuery = useQuery(
    dashboard20240730Client.getQuoteTemplate,
    {
      id: props.quoteTemplateId
    },
    {
      select: res => {
        if (!res?.template) {
          return
        }

        return {
          title: res?.template.title,
          /**
           * Round up to 1 minute ago, since the default formatting
           * for less than a minute is too long and triggers the
           * text overflow.
           */
          updatedAt: new Date(
            res?.template.updatedAt ?? res?.template.createdAt
          ),
          template: res
        }
      }
    }
  )

  const onQuoteCreation = (newQuoteId: string) => {
    navigate(`/quotes/${newQuoteId}`)
  }

  const onQuoteTemplateCreation = (newQuoteTemplateId: string) => {
    navigate(`/quote-templates/${newQuoteTemplateId}`)
  }

  const { createQuoteFromTemplate, creationInProgress } =
    useCreateQuoteFromTemplate({
      quoteTemplateId: props.quoteTemplateId,
      onSuccess: onQuoteCreation
    })

  return {
    title: quoteTemplateQuery.data?.title || 'Untitled',
    updatedAt: quoteTemplateQuery.data?.updatedAt ?? new Date(),
    isLoading: quoteTemplateQuery.isLoading,
    actions: {
      createQuoteFromTemplate: () => {
        if (creationInProgress) {
          return
        }
        createQuoteFromTemplate()
      }
    },
    onQuoteCreation,
    onQuoteTemplateCreation,
    creationInProgress,
    rendererData: quoteTemplateQuery.data?.template
  }
}

export const TemplateItemSkeleton = () => {
  return (
    <Box width="144px">
      <Box marginBottom="8px">
        <Skeleton width="100%" height="180px" />
      </Box>
      <Box marginBottom="4px">
        <Skeleton width="50%" height="16px" />
      </Box>
      <Skeleton width="100%" height="16px" />
    </Box>
  )
}

const getDurationLabel = (updatedAt: Date) =>
  differenceInMinutes(new Date(), updatedAt) < 1
    ? 'just now'
    : `${formatDistanceToNow(updatedAt)} ago`

const LastEditedAt = (props: { updatedAt: Date }) => {
  const [durationLabel, setDurationLabel] = useState(
    getDurationLabel(props.updatedAt)
  )

  /**
   * Keep the label in sync! We don't show seconds,
   * so every half minute should be plenty.
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDurationLabel(getDurationLabel(props.updatedAt))
    }, 30000)

    return () => {
      clearInterval(intervalId)
    }
  }, [props.updatedAt])

  return (
    <TextOverflow splitPercentage={100}>Edited {durationLabel}</TextOverflow>
  )
}

export const QuoteTemplateItem = (props: {
  quoteTemplateId: string
}) => {
  const hook = useQuoteTemplateItem({
    quoteTemplateId: props.quoteTemplateId
  })

  if (hook.isLoading) {
    return <TemplateItemSkeleton />
  }

  return (
    <Box
      width="144px"
      height="224px"
      role="group"
      data-testid={`quoteTemplates.${props.quoteTemplateId}.item`}
    >
      <Box
        marginBottom="8px"
        height="180px"
        borderRadius="6px"
        border={`1px solid rgba(0,0,0,0.08)`}
        boxShadow={ShadowXs}
        cursor="pointer"
        backgroundColor={GreyWhite}
        transition={`box-shadow 0.25s`}
        _hover={{
          boxShadow: ShadowM
        }}
        overflow="hidden"
        position="relative"
        data-testid={`quoteTemplates.${props.quoteTemplateId}.item.preview`}
        onClick={() => hook.actions.createQuoteFromTemplate()}
      >
        {hook.creationInProgress && (
          <Box>
            <Box
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              backgroundColor="rgba(0,0,0,0.08)"
              zIndex="1"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner color={GreyGrey70} />
            </Box>
          </Box>
        )}
        {!hook.creationInProgress && (
          <Flex
            position="absolute"
            top="8px"
            right="8px"
            color={GreyWhite}
            boxShadow={ShadowXs}
            borderRadius="6px"
            zIndex="2"
            visibility="hidden"
            _groupHover={{
              visibility: 'visible'
            }}
            onClick={e => e.stopPropagation()}
          >
            <SequenceKebabMenu
              buttonSize="small"
              data-testid={`quoteTemplates.${props.quoteTemplateId}.item.menu`}
              items={[
                <CreateQuoteFromTemplateMenuItem
                  key="create-quote-from-template"
                  quoteTemplateId={props.quoteTemplateId}
                  onSuccess={hook.onQuoteCreation}
                />,
                <>
                  <SequenceMenuDivider key="duplicate-divider" />
                  <EditQuoteTemplateMenuItem
                    key="view-quote-template"
                    quoteTemplateId={props.quoteTemplateId}
                  />
                  <DuplicateQuoteTemplateMenuItem
                    key="duplicate-quote-template"
                    quoteTemplateId={props.quoteTemplateId}
                    onSuccess={hook.onQuoteTemplateCreation}
                  />
                </>,
                <>
                  <SequenceMenuDivider key="archive-quote-divider" />
                  <ArchiveQuoteTemplateMenuItem
                    key="archive-quote-template"
                    quoteTemplateId={props.quoteTemplateId}
                  />
                </>
              ]}
            />
          </Flex>
        )}
        <Box
          height="100%"
          width="100%"
          overflow="hidden"
          transition="transform 0.25s"
          _groupHover={{
            transform: 'scale(1.05)'
          }}
          zIndex="1"
        >
          <Flex
            opacity={0}
            zIndex={1}
            position="absolute"
            alignItems="center"
            justifyContent="center"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backgroundColor="rgba(0,0,0,0.08)"
            transition="opacity 0.25s"
            _groupHover={{
              opacity: 1
            }}
          >
            <Flex
              backgroundColor="rgba(0,0,0,0.6)"
              borderRadius="4px"
              height="24px"
              padding="0 8px"
              alignItems="center"
              gap="4px"
              color={GreyWhite}
              {...Lato12Bold}
            >
              <PlusIcon color={GreyWhite} height={16} width={16} />
              New quote
            </Flex>
          </Flex>
          <Box
            width="944px"
            transform={`scale(0.15)`}
            transformOrigin={'0 0'}
            zIndex={0}
          >
            <ReactQuoteRenderer
              data={{
                title: hook.rendererData?.template.title ?? '',
                merchantName: 'Template',
                merchantAddress: [],
                merchantEmailAddress: '',
                phases: hook.rendererData?.phases ?? [],
                presentation: hook.rendererData?.template.presentation,
                dealType:
                  hook.rendererData?.template.dealType ?? 'NEW_BUSINESS',
                contacts: [],
                counterSigners: [],
                attachmentAssets: [],
                prospectAddress: undefined,
                prospectName: undefined,
                merchantLogoUrl: undefined,
                preliminaryBillingStartDate: undefined,
                expiresAt: undefined
              }}
              assetLoader={async ({ assetId }) => {
                const res = await dashboard20240730Client.getAsset({
                  id: assetId
                })

                if (!res.data || res.error) {
                  return undefined
                }

                return { urlSigned: res.data.url }
              }}
            />
          </Box>
        </Box>
      </Box>
      <Text
        noOfLines={2}
        _groupHover={{
          noOfLines: 1
        }}
        overflow="hidden"
        textOverflow="ellipsis"
        {...Lato12Regular}
        color={GreyGrey80}
      >
        {hook.title}
      </Text>
      <Box
        {...Lato12Regular}
        color={GreyGrey60}
        height="0"
        opacity={0}
        _groupHover={{
          height: 'auto',
          opacity: 1,
          transition: 'opacity 0.25s'
        }}
      >
        <LastEditedAt updatedAt={hook.updatedAt} />
      </Box>
    </Box>
  )
}
