import { Box, Flex, Link, Text } from '@chakra-ui/react'
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  DocumentCheckIcon,
  FlagIcon
} from '@heroicons/react/16/solid'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { ActivityCard } from '@sequencehq/core-components'
import {
  BorderRadius4,
  BorderRadius8,
  GreyGrey10,
  GreyGrey30,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import Spinner from 'components/Loading'
import { useFlags } from 'launchdarkly-react-client-sdk'

const DISPLAY_LIMIT = 3

export const QuotesReadyToExecute = () => {
  const flags = useFlags()

  const getLabel = (preliminaryBillingStartDate?: string) => {
    if (!preliminaryBillingStartDate) return 'Awaiting start date'
    return 'Billing set to start '
  }

  const { data: quotes, isLoading } = useQuery(
    dashboard20240730Client.getQuotes,
    {
      limit: 100,
      status: 'ACCEPTED'
    }
  )

  if (!flags?.showQuoteBuilder) return null

  const filteredQuotes = quotes?.items.filter(quote => quote.expiresAt)

  const displayedQuotes = filteredQuotes?.slice(0, DISPLAY_LIMIT) ?? []
  const remainingQuotes = filteredQuotes?.slice(DISPLAY_LIMIT).length ?? 0

  const allQuotes = filteredQuotes?.length ?? 0

  return (
    <Flex direction="column" paddingTop="24px">
      <Text textStyle="sectionHeader" {...Lato14Bold}>
        Start billing for
        <Text
          as="span"
          fontWeight="inherit"
          opacity={isLoading ? 0 : 1}
          transition="opacity 200ms"
        >
          {allQuotes > 99 ? ' 99+' : ` ${allQuotes}`} signed{' '}
          {allQuotes === 1 ? ' quote' : ' quotes'}
        </Text>
      </Text>

      <Flex
        direction="column"
        gap="8px"
        marginTop="12px"
        borderRadius={BorderRadius8}
        background={GreyGrey10}
        p="12px"
      >
        <Text {...Lato13Bold} color={GreyGrey70} paddingBottom="4px">
          Ready to execute
        </Text>

        {isLoading && (
          <Box paddingY="24px">
            <Spinner />
          </Box>
        )}

        {displayedQuotes.map(quote => (
          <ActivityCard
            key={quote.id}
            href={`/quotes/${quote.id}`}
            dateIcon={<FlagIcon />}
            title={quote.title}
            date={quote.preliminaryBillingStartDate ?? ''}
            dateLabel={getLabel(quote.preliminaryBillingStartDate)}
          >
            <Flex gap="4px" alignItems="center">
              <DocumentCheckIcon
                width="16px"
                height="16px"
                color={GreyGrey60}
              />
              <Text color={GreyGrey70} {...Lato13Regular}>
                {quote.quoteNumber}
              </Text>
            </Flex>
          </ActivityCard>
        ))}

        {!isLoading && displayedQuotes.length === 0 && (
          <Flex
            alignItems="center"
            flexDir="column"
            justifyContent="center"
            paddingY="16px"
          >
            <Box
              backgroundColor={GreyGrey30}
              borderRadius={BorderRadius4}
              width="24px"
              height="24px"
              p="4px"
            >
              <CalendarDaysIcon width="16px" height="16px" color={GreyGrey70} />
            </Box>
            <Text
              {...Lato13Bold}
              color={GreyGrey90}
              paddingTop="8px"
              paddingBottom="4px"
            >
              No quotes to display
            </Text>
            <Text {...Lato13Regular} color={GreyGrey80}>
              No quotes are ready to be converted into billing schedules
            </Text>
          </Flex>
        )}

        {remainingQuotes > 0 && (
          <Link
            href="/quotes"
            color="indigo.50"
            _hover={{ textDecoration: 'none' }}
            display="flex"
            gap="4px"
            paddingTop="4px"
            alignItems="center"
          >
            <Text {...Lato13Bold} color={IndigoIndigo50}>
              View {allQuotes > 99 ? '' : remainingQuotes} more
            </Text>
            <ArrowRightIcon width="16px" height="16px" />
          </Link>
        )}
      </Flex>
    </Flex>
  )
}
