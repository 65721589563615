import { useCallback } from 'react'
import {
  LineItemCreditNoteModel,
  LineItemGroupCreditNoteModel
} from '@sequencehq/core-models'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type ApiCreditNote = Dashboardv99990101Api.GetCreditNote.GetCreditNoteResponse
type UseFetchDataForLocalPdfCreditNote = () => {
  fetchData: ({
    creditNoteId
  }: {
    creditNoteId: string
  }) => Promise<{
    creditNote: Dashboardv99990101Api.GetCreditNote.GetCreditNoteResponse
    lineItems: LineItemCreditNoteModel[]
    lineItemGroups: LineItemGroupCreditNoteModel[]
    merchantDetails: DashboardApi20240730.GetInvoiceMerchantDetails.InvoiceMerchantDetails
    merchant: DashboardApi20240730.GetMerchantForSequenceAccount.Merchant
  }>
}

export const useFetchDataForLocalPdfCreditNote: UseFetchDataForLocalPdfCreditNote =
  () => {
    /**
     * Credit note
     */
    const getCreditNote = useCallback(
      async (args: { creditNoteId: ApiCreditNote['id'] }) =>
        dashboardv99990101Client
          .getCreditNote({ id: args.creditNoteId })
          .then(response => response.data),
      []
    )

    /**
     * Line items
     */
    const getLineItems = useCallback(
      async (args: { creditNoteId: ApiCreditNote['id'] }) =>
        dashboardv99990101Client
          .getCreditNoteLineItems({ creditNoteId: args.creditNoteId })
          .then(response => response.data?.items),
      []
    )

    /**
     * Line item groups
     */
    const getLineItemGroups = useCallback(
      async (args: { creditNoteId: ApiCreditNote['id'] }) =>
        dashboardv99990101Client
          .getCreditNoteLineItemGroups({ creditNoteId: args.creditNoteId })
          .then(response => response.data?.items),
      []
    )

    /**
     * Merchant details
     */
    const getMerchantDetails = useCallback(
      async (args: {
        currency: ApiCreditNote['currency']
        country: ApiCreditNote['customerBillingAddress']['country']
        state: ApiCreditNote['customerBillingAddress']['state']
      }) =>
        dashboard20240730Client
          .getInvoiceMerchantDetails({
            currency: args.currency,
            country: args.country,
            state: args.state
          })
          .then(response => response.data),
      []
    )

    /**
     * Merchant
     */
    const getMerchant = useCallback(
      async () =>
        dashboard20240730Client
          .getMerchantForSequenceAccount()
          .then(response => response.data),
      []
    )

    /**
     * Fetch data
     */
    const fetchData = useCallback(
      async ({ creditNoteId }: { creditNoteId: ApiCreditNote['id'] }) => {
        const fetchedCreditNote = await getCreditNote({ creditNoteId })

        if (!fetchedCreditNote) {
          throw new Error('fetchedCreditNote is undefined')
        }

        const fetchedLineItems = await getLineItems({ creditNoteId })
        const fetchedLineItemGroups = await getLineItemGroups({ creditNoteId })
        const fetchedMerchantDetails = await getMerchantDetails({
          currency: fetchedCreditNote.currency,
          country: fetchedCreditNote.customerBillingAddress.country,
          state: fetchedCreditNote.customerBillingAddress.state
        })
        const fetchedMerchant = await getMerchant()

        if (
          typeof fetchedCreditNote === 'undefined' ||
          typeof fetchedLineItems === 'undefined' ||
          typeof fetchedLineItemGroups === 'undefined' ||
          !fetchedMerchantDetails ||
          !fetchedMerchant
        ) {
          throw new Error('Incomplete data to generate credit note PDF locally')
        }

        return {
          creditNote: fetchedCreditNote,
          lineItems: fetchedLineItems,
          lineItemGroups: fetchedLineItemGroups,
          merchantDetails: fetchedMerchantDetails,
          merchant: fetchedMerchant
        }
      },
      [
        getCreditNote,
        getLineItemGroups,
        getLineItems,
        getMerchant,
        getMerchantDetails
      ]
    )

    return { fetchData }
  }
