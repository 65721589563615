import { Box, Flex, Grid, Spinner } from '@chakra-ui/react'
import { useIntegrationsNavigation } from 'modules/Integrations/view/integrationsNavigation/useIntegrationsNavigation'
import {
  GreyGrey60,
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular,
  YellowYellow50
} from '@sequencehq/design-tokens'
import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
  SquaresPlusIcon
} from '@heroicons/react/16/solid'
import { IntegrationsBrowserModal } from 'modules/Integrations/view/integrationsBrowserModal/IntegrationsBrowserModal'
import { IntegrationsNavLink } from 'modules/Integrations/view/integrationsNavigation/IntegrationsNavLink'
import { NavLinksLoading } from 'components/NavigationV3/components/NavLinks/NavLinksLoading'
import { NavSection } from 'components/NavigationV3/components/NavSection'

interface IntegrationsNavigationProps {
  isCollapsed?: boolean
}

/**
 * Navigation component for Integrations section
 * Auto-expands when on integration management pages
 */
export const IntegrationsNavigation = ({
  isCollapsed = false
}: IntegrationsNavigationProps) => {
  const { integrationsList, loading, error, reloadIntegrations } =
    useIntegrationsNavigation()

  // Only expand when on an integration management page
  const handlePathMatching = (pathname: string) =>
    pathname.includes('integration-management')

  return (
    <Box padding="8px 8px" userSelect="none">
      <NavSection
        id="integrations"
        title="Integrations"
        expandOnMatchingPath={handlePathMatching}
        isCollapsed={isCollapsed}
        data-testid="navgroup.integrations"
      >
        {loading &&
          (isCollapsed ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              height="32px"
              padding="0 8px"
            >
              <Spinner height="16px" width="16px" color={GreyGrey60} />
            </Flex>
          ) : (
            <NavLinksLoading />
          ))}

        {error && (
          <Grid
            alignItems="center"
            height="32px"
            padding="0 8px"
            gridColumnGap="8px"
            gridTemplateColumns="auto 1fr auto"
            {...Lato13Regular}
            color={IndigoIndigo50}
            onClick={reloadIntegrations}
            cursor="pointer"
            data-testid="integrations.tryAgain"
          >
            <ArrowPathIcon height="16px" width="16px" />
            {!isCollapsed && (
              <span data-testid="integrations.tryAgainText">Try again</span>
            )}
            <ExclamationTriangleIcon
              height="16px"
              width="16px"
              style={{
                position: 'relative',
                top: '1px'
              }}
              color={YellowYellow50}
            />
          </Grid>
        )}

        {!loading && !error && (
          <>
            {integrationsList.integrations.map(integration => (
              <IntegrationsNavLink
                key={integration.id}
                active={integration.active}
                logo={integration.logo}
                label={integration.label}
                onClick={integration.onClick}
                status={integration.status}
                isCollapsed={isCollapsed}
              />
            ))}
            <IntegrationsBrowserModal
              trigger={
                <IntegrationsNavLink
                  logo={(props: { height: string; width: string }) => (
                    <SquaresPlusIcon {...props} />
                  )}
                  label="Browse integrations"
                  isCollapsed={isCollapsed}
                />
              }
            />
          </>
        )}
      </NavSection>
    </Box>
  )
}
