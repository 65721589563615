import { Box, Flex } from '@chakra-ui/react'
import { DocumentDuplicateIcon } from '@heroicons/react/16/solid'
import {
  BorderRadius8,
  GreyGrey20,
  GreyGrey50,
  GreyGrey70,
  IndigoIndigo10,
  IndigoIndigo50,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { Status } from '../usePublishedStatus'

const styles = {
  base: {
    backgroundColor: GreyGrey20,
    height: '32px',
    alignItems: 'center',
    borderRadius: BorderRadius8,
    justifyContent: 'center',
    ...Lato13Bold,
    width: '100%',
    gap: '4px',
    userSelect: 'none' as const
  }
}

const STATUS_TO_LABEL: Record<Status, string> = {
  [Status.Accepted]: 'Signed',
  [Status.Executed]: 'Executed',
  [Status.Published]: 'Published',
  [Status.NotPublished]: 'Not published',
  [Status.Template]: 'Template'
}

interface StatusBadgeProps {
  status: Status
  'data-testid'?: string
}

export const StatusBadge = ({
  status,
  'data-testid': dataTestId
}: StatusBadgeProps) => {
  if (status === Status.Template) {
    return (
      <Flex
        {...styles.base}
        color={IndigoIndigo50}
        backgroundColor={IndigoIndigo10}
        data-testid={dataTestId}
      >
        <DocumentDuplicateIcon
          width="16px"
          height="16px"
          color={IndigoIndigo50}
        />
        Template
      </Flex>
    )
  }

  return (
    <Flex
      {...styles.base}
      color={status === Status.NotPublished ? GreyGrey50 : GreyGrey70}
      data-testid={dataTestId}
    >
      {status !== Status.NotPublished && (
        <Box
          width="8px"
          height="8px"
          borderRadius="100%"
          backgroundColor="#3BB273"
        />
      )}
      {STATUS_TO_LABEL[status]}
    </Flex>
  )
}
