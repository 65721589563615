import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/16/solid'
import { SequenceMenuItemType } from '@sequencehq/core-components'
import { ActiveFilter } from '@sequencehq/tables'
import Page from 'components/Page'
import QuotesMagicTable from 'modules/Quotes/QuotesMagicTable'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Dispatch, FC, memo, SetStateAction, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { SavedViewEditor } from 'SavedViews/components/SavedViewEditor'
import { SavedViewTitle } from 'SavedViews/components/SavedViewTitle'
import { useQuotesSavedView } from './hooks/useQuotesSavedView'

const Quotes: FC<{
  title?: string
  forcedActiveFilters?: ActiveFilter[]
  kebabMenuItems?: (
    setSavingFilters: Dispatch<SetStateAction<false | ActiveFilter[]>>
  ) => SequenceMenuItemType[] | undefined
  onUpdateSavedFilters?: (updatedActiveFilters: ActiveFilter[]) => void
  showSegmentedFilters?: boolean
}> = memo(
  ({
    title = 'Quotes',
    forcedActiveFilters = [],
    kebabMenuItems,
    onUpdateSavedFilters,
    showSegmentedFilters = true
  }) => {
    const flags = useFlags()
    const navigate = useNavigate()

    useEffect(() => {
      if (!flags?.showQuoteBuilder) {
        navigate('/home')
      }
    })

    const {
      isSavedView,
      savingFilters,
      setSavingFilters,
      forceNewSavedView,
      onSaveFilters,
      onDismissSaveFilters
    } = useQuotesSavedView()

    const buttonProps: ButtonProps = {
      variant: 'component-library-primary',
      leftIcon: <PlusIcon width="16px" height="16px" color="inherit" />,
      iconSpacing: '4px'
    }

    const richTitle = flags.useSavedViews ? (
      savingFilters ? (
        <SavedViewEditor
          activeFilters={savingFilters}
          onDismiss={onDismissSaveFilters}
          forceNewSavedView={forceNewSavedView}
          entityType="QUOTES"
        />
      ) : (
        <SavedViewTitle
          title={title}
          kebabMenuItems={kebabMenuItems?.(setSavingFilters)}
        />
      )
    ) : undefined

    return (
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <Page
            title={title}
            richTitle={richTitle}
            paddingBottom={0}
            menuButtons={
              savingFilters ? null : (
                <Button
                  {...buttonProps}
                  onClick={() => navigate('/quotes/new')}
                >
                  New quote
                </Button>
              )
            }
          >
            <QuotesMagicTable
              onSaveFilters={flags.useSavedViews ? onSaveFilters : undefined}
              onUpdateSavedFilters={
                isSavedView ? onUpdateSavedFilters : undefined
              }
              forcedActiveFilters={forcedActiveFilters}
              lockedControls={savingFilters && savingFilters.length > 0}
              showSegmentedFilters={showSegmentedFilters}
            />
          </Page>
        </Flex>

        <Outlet />
      </Flex>
    )
  }
)

export default Quotes
