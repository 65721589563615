import { Box, Flex, Spinner, useToast } from '@chakra-ui/react'
import { PhotoIcon, TrashIcon } from '@heroicons/react/16/solid'
import type { ServerAsset } from '@sequencehq/api/utils/commonModels'
import { Button, Toast } from '@sequencehq/core-components'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import {
  useSelectCoverImageSrc,
  useSelectSignedCoverImageSrc,
  useStoreCoverImageAsset
} from 'Cube/domain/subdomain/coverImage'
import { UploadAssetButton } from 'modules/Assets/UploadAssetButton'
import { FC, PropsWithChildren, useMemo } from 'react'
import { useCoverImageEditorContext } from './CoverImageEditorContext'
import { constructAssetPath } from '@sequencehq/utils'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { useQuery } from '@sequencehq/api'

const TRANSITION_DURATION = 150

type CoverImageEditorUploadButtonProps = {
  text?: string
}

export const CoverImageEditorUploadButton = ({
  text = 'Add cover'
}: CoverImageEditorUploadButtonProps) => {
  const storeAsset = useStoreCoverImageAsset()
  const toast = useToast()
  const { data: sequenceAccountSettings } = useQuery(
    dashboard20240730Client.getSequenceAccountSettings
  )

  const { setFile, setImageSrc, prevImageSrc } = useCoverImageEditorContext()

  const handleFilePicked = (pickedFile: File) => {
    setFile(pickedFile)
    setImageSrc(fileToImageSrc(pickedFile))
  }

  const handleUploadSuccess = (serverAsset: ServerAsset) => {
    if (!sequenceAccountSettings) {
      handleUploadFail('Unable to get account ID')
      return
    }

    setFile(undefined)
    storeAsset(
      constructAssetPath({
        accountId: sequenceAccountSettings.sequenceAccountId,
        assetId: serverAsset.id
      })
    )
  }

  const handleUploadFail = (error: string) => {
    setFile(undefined)
    setImageSrc(prevImageSrc)

    toast({
      render: () => (
        <Toast
          type="error"
          title="Upload failed"
          description={error}
          isClosable
        />
      )
    })
  }

  return (
    <ShowOnGroupHover>
      <UploadAssetButton
        leadingIcon={<PhotoIcon width={16} />}
        variant="secondary"
        onFilePicked={handleFilePicked}
        onUploadSuccess={handleUploadSuccess}
        onUploadFailure={handleUploadFail}
        accept="image/jpeg,image/png"
      >
        {text}
      </UploadAssetButton>
    </ShowOnGroupHover>
  )
}

export const CoverImageEditor: FC = () => {
  const assetSrc = useSelectCoverImageSrc()
  const storeAsset = useStoreCoverImageAsset()
  const { file, imageSrc, setImageSrc, isDisabled } =
    useCoverImageEditorContext()
  const signedImageSrc = useSelectSignedCoverImageSrc()

  const removeCoverImage = () => {
    storeAsset(null)
    setTimeout(() => {
      setImageSrc(undefined)
    }, TRANSITION_DURATION)
  }

  const showImage = !!assetSrc || !!file

  const showControls = !!assetSrc && !file && !isDisabled

  const backgroundImage = useMemo(() => {
    if (imageSrc?.startsWith('blob:')) {
      return `url(${imageSrc})`
    }

    if (signedImageSrc) {
      return `url(${signedImageSrc})`
    }

    return undefined
  }, [imageSrc, signedImageSrc])

  return (
    <Box position="relative" role="group">
      <Flex
        transition={`height ${TRANSITION_DURATION}ms ease-out, opacity ${TRANSITION_DURATION}ms ease-out`}
        position="relative"
        justifyContent="center"
        alignItems="center"
        height={showImage ? '240px' : 0}
        opacity={showImage ? 1 : 0}
        backgroundImage={backgroundImage}
        backgroundSize="100% auto"
        backgroundPosition="50% 50%"
        backgroundRepeat="no-repeat"
        mt={!imageSrc ? 3 : undefined}
      >
        {!!file && (
          <Spinner color={IndigoIndigo50} width="36px" height="36px" />
        )}
        <Box opacity={showControls ? 1 : 0}>
          <ShowOnGroupHover>
            <Flex gap="4px" style={{ position: 'absolute', top: 8, right: 8 }}>
              <CoverImageEditorUploadButton text="Replace cover" />
              <Button
                variant="secondary"
                icon={<TrashIcon width={16} />}
                aria-label="remove cover image"
                onClick={removeCoverImage}
              />
            </Flex>
          </ShowOnGroupHover>
        </Box>
      </Flex>
    </Box>
  )
}

const ShowOnGroupHover: FC<PropsWithChildren> = ({ children }) => (
  <Box
    _groupHover={{ opacity: 1 }}
    opacity={0}
    transition="opacity 0.15s ease-out"
  >
    {children}
  </Box>
)

function fileToImageSrc(file: File): string {
  return URL.createObjectURL(file)
}
