import { Flex } from '@chakra-ui/react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { ReactQuoteRenderer } from '@sequencehq/quote-content'

import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'
import { useQuoteEditorPreviewRoot } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/useQuoteEditorPreviewRoot'
import { Header } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/widgets/Header'

export const QuoteEditorPreviewRoot = (props: QuoteEditorPreviewProps) => {
  const rootHook = useQuoteEditorPreviewRoot(props)

  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      background="white"
      width="100%"
      height="100%"
      zIndex="110"
      flexDirection="column"
      data-testid="quote.preview-content"
    >
      <Header hook={rootHook} />
      <Flex overflowY="scroll" flex="1">
        <Flex width="100%" height="fit-content">
          <ReactQuoteRenderer
            data={rootHook.reactRendererData}
            assetLoader={async ({ assetId }) => {
              const res = await dashboard20240730Client.getAsset({
                id: assetId
              })

              if (!res.data || res.error) {
                return undefined
              }

              return { urlSigned: res.data.url }
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
