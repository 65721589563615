import { Box, Grid, Flex, Text, Center } from '@chakra-ui/react'
import { Button, SequenceMenu } from '@sequencehq/core-components'
import {
  RedRed60,
  Lato12Bold,
  Lato14Regular,
  GreyGrey60,
  GreyGrey10,
  Lato14Bold
} from '@sequencehq/design-tokens'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import { FC, Fragment } from 'react'
import { minimumRowActions, productRowActions } from './pricingTableRowActions'
import { usePricingTable } from './usePricingTable'
import { BillingFrequency } from 'Cube/domain/cube.domain.types'
import { match } from 'ts-pattern'
import { endOfMonth, format, isValid, parse } from '@sequencehq/utils/dates'
import { billingFrequency } from '@sequencehq/core-models'
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/16/solid'
import { ExtractedDataIndicator } from 'ContractExtraction/index'

const styles = {
  tableHeader: {
    ...Lato12Bold,
    color: GreyGrey60,
    textTransform: 'uppercase' as const,
    border: 'none',
    padding: '10px 8px'
  },
  tableCell: {
    alignItems: 'center',
    ...Lato14Regular,
    border: 'none',
    textAlign: 'left' as const,
    padding: '8px'
  }
}

export const getRepeatsDescription = (args: {
  recurrenceDayOfMonth?: number
  monthRecurrenceStarted?: number
  billingFrequency?: BillingFrequency
}) => {
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' })
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th']
  ])
  const formatOrdinals = (n: number) => {
    const rule = pr.select(n)
    const suffix = suffixes.get(rule)
    return `${n}${suffix}`
  }

  return match(args.billingFrequency)
    .with(undefined, () => '')
    .with('MONTHLY', () => {
      if (!args.recurrenceDayOfMonth) {
        return 'Monthly'
      }

      return `Monthly on the ${formatOrdinals(args.recurrenceDayOfMonth)}`
    })
    .with('QUARTERLY', () => {
      if (!args.recurrenceDayOfMonth) {
        return 'Quarterly'
      }

      return `Quarterly on the ${formatOrdinals(args.recurrenceDayOfMonth)}`
    })
    .with('YEARLY', () => {
      if (typeof args.monthRecurrenceStarted === 'undefined') {
        return 'Annually'
      }
      /**
       * 2025 is not a leap year, so it's a safe one to use. We need to set this hard coded year to account
       * for the potential for leap years to throw off the recurrence day if it's in February.
       */
      const recurrenceMonthAndDay = isValid(
        parse(
          `2025-${args.monthRecurrenceStarted + 1}-${args.recurrenceDayOfMonth}`,
          'yyyy-MM-dd',
          new Date()
        )
      )
        ? parse(
            `2025-${args.monthRecurrenceStarted + 1}-${args.recurrenceDayOfMonth}`,
            'yyyy-MM-dd',
            new Date()
          )
        : endOfMonth(new Date(`2025-${args.monthRecurrenceStarted + 2}-01`))
      /**
       * To do this well we need to grab the month that the product was introduced,
       * that is also still joined up with the product.
       */

      return `Annually on the ${format(recurrenceMonthAndDay, 'do MMM')}`
    })
    .with('ON_DEMAND', () => {
      return 'On demand'
    })
    .with('HALF_YEARLY', () => {
      if (!args.recurrenceDayOfMonth) {
        return 'Semi-annually'
      }

      return `Semi-annually on the ${formatOrdinals(args.recurrenceDayOfMonth)}`
    })
    .with('ONE_TIME', () => {
      return 'Once'
    })
    .exhaustive()
}

export const PricingTableExpanded: FC<{
  phaseId: string
}> = props => {
  const { validationErrors, pricingGroups, showArrCalculation } =
    usePricingTable({
      phaseId: props.phaseId
    })
  const columnWidths = `1fr 202px 148px 140px ${showArrCalculation ? '132px' : ''} 1fr 20px`

  return (
    <Box data-testid="phase.pricingTable">
      <Box {...Lato14Bold} padding="16px 8px 8px 8px">
        Pricing
      </Box>
      <Box
        marginBottom="8px"
        border={validationErrors.length ? `1px solid ${RedRed60}` : ''}
        borderRadius="8px"
      >
        <Grid templateColumns={columnWidths} columnGap="8px">
          <Flex {...styles.tableHeader}>Name</Flex>
          <Flex {...styles.tableHeader}>Repeats</Flex>
          <Flex {...styles.tableHeader}>Bill</Flex>
          <Flex {...styles.tableHeader}>Discount</Flex>
          {showArrCalculation && (
            <Flex {...styles.tableHeader}>ARR calculation</Flex>
          )}
          <Flex {...styles.tableHeader} justifyContent="flex-end">
            Price
          </Flex>
          <Flex {...styles.tableHeader}></Flex>
        </Grid>
        {pricingGroups.length > 0 &&
          pricingGroups.map(group => {
            return (
              <Fragment
                key={`group${
                  group.minimumRow?.id || group.pricingRows[0]?.id
                }}`}
              >
                {group.minimumRow && (
                  <Grid
                    templateColumns={columnWidths}
                    columnGap="8px"
                    alignItems="center"
                    key={`group${
                      group.minimumRow?.id || group.pricingRows[0]?.id
                    }}`}
                    _hover={{
                      backgroundColor: GreyGrey10
                    }}
                  >
                    <Flex
                      {...styles.tableCell}
                      style={{ textWrap: 'wrap' }}
                      onClick={group.minimumRow.actions.edit.handler}
                      {...Lato14Bold}
                    >
                      <ExtractedDataIndicator
                        fields={[
                          `billingSchedule.phases[${props.phaseId}].minimums[0].*`
                        ]}
                        position="inline"
                      >
                        {group.minimumRow.name}
                      </ExtractedDataIndicator>
                    </Flex>
                    <Flex {...styles.tableCell} color={GreyGrey60} gap="6px">
                      <ArrowPathRoundedSquareIcon
                        height="16px"
                        width="16px"
                        color={GreyGrey60}
                      />
                      {getRepeatsDescription({
                        recurrenceDayOfMonth:
                          group.minimumRow.recurrenceDayOfMonth,
                        billingFrequency: group.minimumRow.billingFrequency
                      })}
                    </Flex>
                    <Flex {...styles.tableCell}></Flex>
                    <Flex {...styles.tableCell}></Flex>
                    {showArrCalculation && <Flex {...styles.tableCell}></Flex>}
                    <Flex {...styles.tableCell} justifyContent="flex-end">
                      {group.minimumRow.value}
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      maxWidth="36px"
                      height="36px"
                      padding="0px"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <SequenceMenu
                        alignment="bottom-right"
                        menuButton={
                          <Button variant="ghost" size="24px">
                            <Center flexShrink="0">
                              <EllipsisHorizontalIcon
                                width="16px"
                                height="16px"
                                color={GreyGrey60}
                              />
                            </Center>
                          </Button>
                        }
                        items={minimumRowActions(group.minimumRow)}
                      />
                    </Flex>
                  </Grid>
                )}
                {group.pricingRows.map(priceRow => (
                  <Grid
                    data-test-has-minimum={Boolean(group.minimumRow)}
                    key={priceRow.id}
                    templateColumns={columnWidths}
                    columnGap="8px"
                    cursor="pointer"
                    borderRadius="8px"
                    minHeight="36px"
                    onClick={priceRow.onClick}
                    _hover={{
                      backgroundColor: GreyGrey10
                    }}
                    data-testid={`cube.priceRow.${priceRow.id}`}
                  >
                    <Flex
                      {...styles.tableCell}
                      style={{ textWrap: 'wrap' }}
                      paddingLeft={group.minimumRow ? '16px' : '8px'}
                    >
                      {/* Show the contract extraction sparkle if:
                          - This row shows a price that was extracted
                          - This row represents a global discount and there is a discount in fieldsMetadata
                          - This row shows a discounted price and there is a discount in fieldsMetadata
                      */}
                      <ExtractedDataIndicator
                        fields={[
                          `billingSchedule.prices[${priceRow.id}].*`,
                          ...(priceRow.isGlobalDiscountRow || priceRow.discount
                            ? [
                                `billingSchedule.phases[${props.phaseId}].discounts[0].*`
                              ]
                            : [])
                        ]}
                        position="inline"
                      >
                        {priceRow.name}
                      </ExtractedDataIndicator>
                    </Flex>
                    <Flex {...styles.tableCell} color={GreyGrey60} gap="6px">
                      {billingFrequency ? (
                        <>
                          <ArrowPathRoundedSquareIcon
                            height="16px"
                            width="16px"
                            color={GreyGrey60}
                          />
                          {getRepeatsDescription({
                            recurrenceDayOfMonth: priceRow.recurrenceDayOfMonth,
                            monthRecurrenceStarted:
                              priceRow.monthRecurrenceStarted,
                            billingFrequency: priceRow.billingFrequency
                          })}
                        </>
                      ) : (
                        ''
                      )}
                    </Flex>
                    <Flex {...styles.tableCell} color={GreyGrey60}>
                      {priceRow.billingType === 'IN_ADVANCE'
                        ? 'In advance'
                        : 'In arrears'}
                    </Flex>
                    <Flex {...styles.tableCell}>
                      {priceRow.discount ? (
                        <Flex
                          key={priceRow.discount.id}
                          alignContent="center"
                          gap="4px"
                          _hover={{
                            opacity: 0.5
                          }}
                          onClick={e => {
                            e.stopPropagation()
                            priceRow.availableFeatures.canEditDiscount?.handler()
                          }}
                        >
                          {priceRow.discount.label}
                        </Flex>
                      ) : null}
                    </Flex>
                    {showArrCalculation && (
                      <Flex
                        {...styles.tableCell}
                        data-testid={`cube.priceRow.${priceRow.id}.arrCalculation`}
                      >
                        {priceRow.phasePriceMetadata?.arrCalculation ===
                        'EXCLUDE'
                          ? 'Exclude'
                          : 'Include'}
                      </Flex>
                    )}
                    <Flex
                      {...styles.tableCell}
                      justifyContent="flex-end"
                      gap="6px"
                    >
                      {priceRow.price !== priceRow.preDiscountPrice && (
                        <Text
                          textDecoration="line-through"
                          textAlign="right"
                          color={GreyGrey60}
                        >
                          {priceRow.preDiscountPrice}
                        </Text>
                      )}
                      <Text textAlign="right">{priceRow.price}</Text>
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      maxWidth="36px"
                      height="100%"
                      padding="0px"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <SequenceMenu
                        alignment="bottom-right"
                        data-testid={`cube.priceRow.${priceRow.id}.menu`}
                        menuButton={
                          <Button variant="ghost" size="24px">
                            <Center flexShrink="0">
                              <EllipsisHorizontalIcon
                                width="16px"
                                height="16px"
                                color={GreyGrey60}
                              />
                            </Center>
                          </Button>
                        }
                        items={productRowActions(priceRow)}
                      />
                    </Flex>
                  </Grid>
                ))}
              </Fragment>
            )
          })}
      </Box>
    </Box>
  )
}
