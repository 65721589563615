import { Link } from 'react-router-dom'
import { Text, Flex, Button, Box, Grid, GridItem } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold
} from '@sequencehq/design-tokens'

import { SubNavLink } from 'components/NavigationV3/components/NavLinks/SubNavLink'
import { NavLink as NavLinkProps } from 'components/NavigationV3/types'
import { NavTooltip } from 'components/NavigationV3/components/NavTooltip'

import ChevronUpIcon from '@heroicons/react/16/solid/ChevronUpIcon'
import ChevronDownIcon from '@heroicons/react/16/solid/ChevronDownIcon'
import { FC, PropsWithChildren } from 'react'
import { match } from 'ts-pattern'

type Props = NavLinkProps & {
  pathname: string
  isCollapsed?: boolean
}

const pathWithoutParams = (path: string): string => path.split('?')[0]

export const NavLink = ({
  icon: Icon,
  label,
  href,
  pathname,
  enabled = true,
  subLinks,
  isCollapsed = false
}: Props) => {
  if (!enabled) {
    return null
  }

  const isSubLinkActive: boolean =
    subLinks?.some(subLink =>
      pathname.startsWith(pathWithoutParams(subLink.href))
    ) || false

  const isActive: boolean =
    pathname.startsWith(pathWithoutParams(href)) || isSubLinkActive

  const isExpanded: boolean = isSubLinkActive

  return (
    <>
      <NavLinkButton
        href={href}
        icon={Icon}
        isActive={isActive}
        isExpanded={subLinks ? isExpanded : undefined}
        isCollapsed={isCollapsed}
      >
        {label}
      </NavLinkButton>

      {subLinks && isExpanded && (
        <Flex direction="column">
          {subLinks.map((subLink, index) => (
            <SubNavLink
              key={subLink.href}
              {...subLink}
              index={index}
              subLinks={subLinks}
              pathname={pathname}
              isCollapsed={isCollapsed}
            />
          ))}
        </Flex>
      )}
    </>
  )
}

export const NavLinkButton: FC<
  PropsWithChildren<{
    href: string
    icon: NavLinkProps['icon']
    isActive: boolean
    isExpanded?: boolean // if not specified, we don't show a chevron
    isCollapsed?: boolean
  }>
> = ({
  href,
  isActive,
  isExpanded,
  icon: Icon,
  children,
  isCollapsed = false
}) => {
  const navLink = (
    <Grid
      as={Link}
      to={href}
      padding="0 8px"
      templateColumns={isCollapsed ? 'auto' : 'auto 1fr auto'}
      height="32px"
      gap="8px"
      borderRadius="6px"
      alignContent="center"
      alignItems="center"
      justifyContent={'flex-start'}
      cursor="pointer"
      color={isActive ? GreyGrey90 : GreyGrey70}
      backgroundColor={isActive ? GreyGrey20 : 'transparent'}
      width={isCollapsed ? '32px' : 'auto'}
      style={{
        willChange: 'width, transform',
        transform: 'translateZ(0)',
        transition: 'background-color 0.2s ease'
      }}
      _hover={{
        backgroundColor: GreyGrey20
      }}
    >
      <GridItem
        height="fit-content"
        display="flex"
        alignContent="center"
        justifyContent={'flex-start'}
        flexShrink={0}
        alignSelf="center"
        style={{
          willChange: 'transform',
          transform: 'translateZ(0)',
          transition: 'transform 0.3s ease'
        }}
      >
        <Icon width="16px" height="16px" color="inherit" />
      </GridItem>
      {!isCollapsed && (
        <GridItem
          {...Lato13Bold}
          color="inherit"
          overflow="hidden"
          textOverflow="ellipsis"
          className="nav-text"
        >
          {children}
        </GridItem>
      )}
      {isExpanded !== undefined && !isCollapsed && (
        <GridItem className="nav-text">
          {isExpanded ? (
            <ChevronUpIcon width="16px" height="16px" color="inherit" />
          ) : (
            <ChevronDownIcon width="16px" height="16px" color="inherit" />
          )}
        </GridItem>
      )}
    </Grid>
  )

  if (isCollapsed) {
    return (
      <NavTooltip label={String(children)} isDisabled={!isCollapsed}>
        {navLink}
      </NavTooltip>
    )
  }

  return navLink
}
