import { ReactNode } from 'react'
import {
  ModalBody,
  ModalContent,
  Divider,
  ModalHeader,
  ModalCloseButton,
  Flex,
  ModalFooter,
  Button,
  Text
} from '@chakra-ui/react'
import { noReturn } from 'lib/noReturn'
import { FormErrors } from '@sequencehq/forms'
import { FormRenderProps } from 'react-final-form'
import { useDispatch } from 'features/store'
import { closeOverlay } from 'features/overlay'
import { borderColor } from '@sequencehq/core-theme'

export type ModalWrapperProps<FormValues> = {
  title: string
  description?: string
  children: ReactNode
  submitLabel: string
  variant?: 'v2'
  'data-testid'?: string
} & FormRenderProps<FormValues, Partial<FormValues>>

export const ModalForm = <FormValues,>({
  title,
  description,
  children,
  submitError,
  handleSubmit,
  submitting,
  submitLabel,
  variant,
  ['data-testid']: dataTestId = 'modalForm'
}: ModalWrapperProps<FormValues>) => {
  const dispatch = useDispatch()

  return (
    <ModalContent
      borderRadius="8px"
      as="form"
      onSubmit={noReturn(handleSubmit)}
    >
      <Flex alignItems="center" position="relative" height="60px">
        <ModalHeader>
          <Flex>
            <h2>{title}</h2>
            <Text>{description}</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton top={4} right={4} />
      </Flex>

      {!variant && <Divider color={borderColor} />}

      <ModalBody px={5} py={5} flexDirection="column">
        <FormErrors formError={submitError} />
        {children}
      </ModalBody>

      <ModalFooter>
        <Button
          variant={
            variant === 'v2' ? 'component-library-secondary' : 'secondary'
          }
          mr={variant === 'v2' ? 0 : 3}
          onClick={() => dispatch(closeOverlay())}
        >
          Cancel
        </Button>
        <Button
          data-testid={`${dataTestId}.submit`}
          variant={variant === 'v2' ? 'component-library-primary' : 'primary'}
          type="submit"
          isLoading={submitting}
        >
          {submitLabel}
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
