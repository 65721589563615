import { required, validateParameter } from '@sequencehq/validation'
import { FormFieldConfiguration } from '@sequencehq/utils'
import { useMemo } from 'react'
import { useUsageMetrics } from '../../../../communication/external/useUsageMetrics'

export type UsageParameterDataModel = {
  parameters: Record<string, string>
}

export const useUsageMetricParameterFieldConfig = <
  DataModel extends UsageParameterDataModel
>(
  usageMetricId: string | undefined,
  disabled: (ctx: {
    formData: DataModel & UsageParameterDataModel
    property: keyof (DataModel & UsageParameterDataModel)
  }) => boolean
): {
  fields: FormFieldConfiguration<DataModel>[]
} => {
  const { usageMetrics } = useUsageMetrics()

  const usageMetric = usageMetricId
    ? usageMetrics.find(metric => metric.id === usageMetricId)
    : undefined

  const fields = useMemo(() => {
    return usageMetric
      ? (usageMetric.parameters.map(parameter => ({
          property: `parameters.${parameter.id}`,
          disabled: disabled,
          validation: [required, validateParameter(parameter.type)]
        })) as FormFieldConfiguration<DataModel>[])
      : []
  }, [disabled, usageMetric])

  return {
    fields
  }
}
