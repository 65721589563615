import { BulkActions, BulkActionsAction } from '@sequencehq/core-components'
import { match } from 'ts-pattern'
import { BulkAction, InvoiceLite } from './types'
import {
  allCompletedIds,
  allFailedIds,
  allIds,
  allSuccessfulIds
} from './utils'
import { Link } from '@chakra-ui/react'
import { IndigoIndigo50, Lato13Bold } from '@sequencehq/design-tokens'
import { inflect } from 'inflection'
import { FC, useEffect } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useRunInvalidationForMutation } from '@sequencehq/api'

function actionList(
  bulkAction: BulkAction,
  invoices: Record<string, InvoiceLite>
): BulkActionsAction[] {
  const ids = allIds(bulkAction)

  return ids
    .map(id => {
      const status: BulkActionsAction['status'] = match({
        isComplete: bulkAction.completedIds.includes(id),
        isErrored: bulkAction.failedIds.includes(id),
        isSkipped: bulkAction.skippedIds.includes(id)
      })
        .with({ isComplete: true }, () => 'success' as const)
        .with({ isErrored: true }, () => 'failure' as const)
        .with({ isSkipped: true }, () => 'skipped' as const)
        .otherwise(() => 'pending' as const)

      const invoice: InvoiceLite = invoices[id]?.data
        ? invoices[id]
        : {
            id,
            success: false,
            data: {
              id,
              invoiceNumber: '',
              customerLegalCompanyName: ''
            }
          }
      return toAction(invoice, status)
    })
    .filter((action): action is BulkActionsAction => Boolean(action))
}

function titleForAction(invoice: InvoiceLite) {
  /**
   * This can happen in the niche scenario the enrichment fails.
   * The invoice is still, probably, accessible via the ID, but
   * we don't know anything else about it yet.
   */
  if (!invoice.success) {
    return 'Invoice'
  }

  if (invoice.data.invoiceNumber) {
    return invoice.data.invoiceNumber
  }

  return invoice.data.status === 'IN_PROGRESS'
    ? 'Upcoming invoice'
    : 'Draft invoice'
}

function toAction(
  invoice: InvoiceLite,
  status: BulkActionsAction['status']
): BulkActionsAction | null {
  return {
    id: invoice.id,
    title: (
      <Link
        target="_blank"
        href={`/invoices/${invoice.id}`}
        {...Lato13Bold}
        color={IndigoIndigo50}
      >
        {titleForAction(invoice)}
      </Link>
    ),
    status: status
  }
}

export function titleForBulkAction(
  bulkAction: Pick<
    BulkAction,
    | 'type'
    | 'status'
    | 'requestedIds'
    | 'completedIds'
    | 'failedIds'
    | 'skippedIds'
  >
) {
  const singularAction = allIds(bulkAction).length === 1
  const totalSuccessful = allSuccessfulIds(bulkAction).length
  const totalFailed = allFailedIds(bulkAction).length
  const singularFail = totalFailed === 1
  const progress = `${allCompletedIds(bulkAction).length}/${allIds(bulkAction).length}`

  return (
    match({ ...bulkAction, singularAction, singularFail })
      /**
       * Updating status
       */
      .with(
        {
          type: 'UPDATE_INVOICES_STATUS',
          status: 'PROCESSING',
          singularAction: true
        },
        () => `Updating invoice status...`
      )
      .with(
        {
          type: 'UPDATE_INVOICES_STATUS',
          singularAction: true,
          status: 'COMPLETED'
        },
        () => `Invoice status updated`
      )
      .with(
        {
          type: 'UPDATE_INVOICES_STATUS',
          status: 'COMPLETED_WITH_FAILURES',
          singularAction: true
        },
        () => `Failed to update invoice status`
      )
      .with(
        { type: 'UPDATE_INVOICES_STATUS', status: 'PROCESSING' },
        () => `Updating ${progress} invoice statuses...`
      )
      .with(
        { type: 'UPDATE_INVOICES_STATUS', status: 'COMPLETED' },
        () => `${totalSuccessful} invoice statuses updated`
      )
      .with(
        { type: 'UPDATE_INVOICES_STATUS', status: 'COMPLETED_WITH_FAILURES' },
        () =>
          `${totalFailed} ${inflect('invoice status', totalFailed)} failed to update`
      )
      /**
       * Voiding invoices
       */
      .with(
        {
          type: 'VOID_INVOICES',
          status: 'PROCESSING',
          singularAction: true
        },
        () => `Voiding invoice...`
      )
      .with(
        {
          type: 'VOID_INVOICES',
          singularAction: true,
          status: 'COMPLETED'
        },
        () => `Invoice voided`
      )
      .with(
        {
          type: 'VOID_INVOICES',
          status: 'COMPLETED_WITH_FAILURES',
          singularAction: true
        },
        () => `Failed to void invoice`
      )
      .with(
        { type: 'VOID_INVOICES', status: 'PROCESSING' },
        () => `Voiding ${progress} invoices...`
      )
      .with(
        { type: 'VOID_INVOICES', status: 'COMPLETED' },
        () => `${totalSuccessful} invoices voided`
      )
      .with(
        { type: 'VOID_INVOICES', status: 'COMPLETED_WITH_FAILURES' },
        () => `${totalFailed} ${inflect('invoice', totalFailed)} failed to void`
      )
      /**
       * Recalculating invoices
       */
      .with(
        {
          type: 'RECALCULATE_INVOICES',
          status: 'PROCESSING',
          singularAction: true
        },
        () => `Recalculating invoice...`
      )
      .with(
        {
          type: 'RECALCULATE_INVOICES',
          singularAction: true,
          status: 'COMPLETED'
        },
        () => `Invoice recalculated`
      )
      .with(
        {
          type: 'RECALCULATE_INVOICES',
          status: 'COMPLETED_WITH_FAILURES',
          singularAction: true
        },
        () => `Failed to recalculate invoice`
      )
      .with(
        { type: 'RECALCULATE_INVOICES', status: 'PROCESSING' },
        () => `Recalculating ${progress} invoices...`
      )
      .with(
        { type: 'RECALCULATE_INVOICES', status: 'COMPLETED' },
        () => `${totalSuccessful} invoices recalculated`
      )
      .with(
        { type: 'RECALCULATE_INVOICES', status: 'COMPLETED_WITH_FAILURES' },
        () =>
          `${totalFailed} ${inflect('invoice', totalFailed)} failed to recalculate`
      )
      /**
       * Download invoices
       */
      .with(
        {
          type: 'DOWNLOAD_INVOICES',
          status: 'PROCESSING',
          singularAction: true
        },
        () => `Downloading invoice...`
      )
      .with(
        {
          type: 'DOWNLOAD_INVOICES',
          singularAction: true,
          status: 'COMPLETED'
        },
        () => `Invoice downloaded`
      )
      .with(
        {
          type: 'DOWNLOAD_INVOICES',
          status: 'COMPLETED_WITH_FAILURES',
          singularAction: true
        },
        () => `Failed to download invoice`
      )
      .with(
        { type: 'DOWNLOAD_INVOICES', status: 'PROCESSING' },
        () => `Downloading ${progress} invoices...`
      )
      .with(
        { type: 'DOWNLOAD_INVOICES', status: 'COMPLETED' },
        () => `${totalSuccessful} invoices downloaded`
      )
      .with(
        { type: 'DOWNLOAD_INVOICES', status: 'COMPLETED_WITH_FAILURES' },
        () =>
          `${totalFailed} ${inflect('invoice', totalFailed)} failed to download`
      )
      /**
       * Finalising invoices
       */
      .with(
        {
          type: 'FINALISE_INVOICES',
          status: 'PROCESSING',
          singularAction: true
        },
        () => `Finalizing invoice...`
      )
      .with(
        {
          type: 'FINALISE_INVOICES',
          singularAction: true,
          status: 'COMPLETED'
        },
        () => `Invoice finalized`
      )
      .with(
        {
          type: 'FINALISE_INVOICES',
          status: 'COMPLETED_WITH_FAILURES',
          singularAction: true
        },
        () => `Failed to finalize invoice`
      )
      .with(
        { type: 'FINALISE_INVOICES', status: 'PROCESSING' },
        () => `Finalizing ${progress} invoices...`
      )
      .with(
        { type: 'FINALISE_INVOICES', status: 'COMPLETED' },
        () => `${totalSuccessful} invoices finalized`
      )
      .with(
        { type: 'FINALISE_INVOICES', status: 'COMPLETED_WITH_FAILURES' },
        () =>
          `${totalFailed} ${inflect('invoice', totalFailed)} failed to finalize`
      )
      /**
       * Finalising and sending invoices
       */
      .with(
        {
          type: 'FINALISE_AND_SEND_INVOICES',
          status: 'PROCESSING',
          singularAction: true
        },
        () => `Finalizing and sending invoice...`
      )
      .with(
        {
          type: 'FINALISE_AND_SEND_INVOICES',
          singularAction: true,
          status: 'COMPLETED'
        },
        () => `Invoice finalized and sent`
      )
      .with(
        {
          type: 'FINALISE_AND_SEND_INVOICES',
          status: 'COMPLETED_WITH_FAILURES',
          singularAction: true
        },
        () => `Failed to send and finalize invoice`
      )
      .with(
        { type: 'FINALISE_AND_SEND_INVOICES', status: 'PROCESSING' },
        () => `Finalizing and sending ${progress} invoices...`
      )
      .with(
        { type: 'FINALISE_AND_SEND_INVOICES', status: 'COMPLETED' },
        () => `${totalSuccessful} invoices finalized and sent`
      )
      .with(
        {
          type: 'FINALISE_AND_SEND_INVOICES',
          status: 'COMPLETED_WITH_FAILURES'
        },
        () =>
          `${totalFailed} ${inflect('invoice', totalFailed)} failed to finalize and send`
      )
      /**
       * Sending invoices
       */
      .with(
        {
          type: 'SEND_INVOICES',
          status: 'PROCESSING',
          singularAction: true
        },
        () => `Sending invoice...`
      )
      .with(
        {
          type: 'SEND_INVOICES',
          singularAction: true,
          status: 'COMPLETED'
        },
        () => `Invoice sent`
      )
      .with(
        {
          type: 'SEND_INVOICES',
          status: 'COMPLETED_WITH_FAILURES',
          singularAction: true
        },
        () => `Failed to send invoice`
      )
      .with(
        { type: 'SEND_INVOICES', status: 'PROCESSING' },
        () => `Sending ${progress} invoices...`
      )
      .with(
        { type: 'SEND_INVOICES', status: 'COMPLETED' },
        () => `${totalSuccessful} invoices sent`
      )
      .with(
        { type: 'SEND_INVOICES', status: 'COMPLETED_WITH_FAILURES' },
        () =>
          `${totalFailed} ${inflect('invoice', totalFailed)} invoices failed to send`
      )
      .exhaustive()
  )
}

const useInvalidateInvoicesCacheOnProgress = (props: {
  bulkAction: BulkAction
}) => {
  const completedInvoiceCount = allCompletedIds(props.bulkAction).length
  const runMutationInvalidation = useRunInvalidationForMutation()

  useEffect(() => {
    if (completedInvoiceCount > 0) {
      runMutationInvalidation(dashboard20240730Client.postInvoice)
    }
  }, [completedInvoiceCount, runMutationInvalidation])
}

export const BulkActionInFlight: FC<{
  bulkAction: BulkAction
  onDismiss: () => void
  invoices: Record<string, InvoiceLite>
  isReviewing: boolean
  onChangeReviewing: (isReviewing: boolean) => void
}> = ({ bulkAction, onDismiss, invoices, isReviewing, onChangeReviewing }) => {
  const actions = actionList(bulkAction, invoices)
  useInvalidateInvoicesCacheOnProgress({ bulkAction })

  return (
    <BulkActions
      title={titleForBulkAction(bulkAction)}
      status={bulkAction.status}
      actions={actions}
      listTitle="Invoice"
      onDismiss={onDismiss}
      isReviewing={isReviewing}
      onChangeReviewing={onChangeReviewing}
    />
  )
}
