import { QuoteBlock } from '@sequencehq/quote-content'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import { bulletListAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/bulletList.adapter.v1'
import { bulletListAdapterV2 } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/bulletList.adapter.v2'
import { bulletListAdapterV3 } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/bulletList.adapter.v3'
import { heading1Adapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/heading1.adapter.v1'
import { heading2Adapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/heading2.adapter.v1'
import { heading3Adapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/heading3.adapter.v1'
import { imageAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/image.adapter.v1'
import { numberListAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/numberList.adapter.v1'
import { numberListAdapterV2 } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/numberList.adapter.v2'
import { numberListAdapterV3 } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/numberList.adapter.v3'
import { paragraphAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/paragraph.adapter.v1'
import { phasesAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/phases.adapter.v1'
import { blocknoteSchema } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/blocknoteSchema'
import { match } from 'ts-pattern'

export const blockAdapters = {
  in: {
    heading1: heading1Adapter.in,
    heading2: heading2Adapter.in,
    heading3: heading3Adapter.in,
    paragraph: paragraphAdapter.in,
    bulletList: (block: QuoteBlock) => {
      if (block.version === 3) {
        return bulletListAdapterV3.in(block)
      }

      if (block.version === 2) {
        return bulletListAdapterV2.in(block)
      }

      return bulletListAdapter.in(block)
    },
    phases: phasesAdapter.in,
    numberList: (block: QuoteBlock) => {
      if (block.version === 3) {
        return numberListAdapterV3.in(block)
      }

      if (block.version === 2) {
        return numberListAdapterV2.in(block)
      }

      return numberListAdapter.in(block)
    },
    image: imageAdapter.in,
    coverImage: null
  },
  out: {
    heading:
      (ctx: { cubeQueries: CubeDomainInterface['queries'] }) =>
      (block: Extract<typeof blocknoteSchema.Block, { type: 'heading' }>) => {
        return match(block.props.level)
          .with(1, () => heading1Adapter.out(ctx)(block))
          .with(2, () => heading2Adapter.out(ctx)(block))
          .with(3, () => heading3Adapter.out(ctx)(block))
          .exhaustive()
      },
    paragraph: paragraphAdapter.out,
    phases: phasesAdapter.out,
    bulletListItem: bulletListAdapterV3.out,
    numberedListItem: numberListAdapterV3.out,
    image: imageAdapter.out
  } as Record<
    | 'phases'
    | 'table'
    | 'image'
    | 'paragraph'
    | 'heading'
    | 'bulletListItem'
    | 'numberedListItem'
    | 'checkListItem'
    | 'file'
    | 'video'
    | 'audio',
    (ctx: { cubeQueries: CubeDomainInterface['queries'] }) => (
      block: Extract<
        typeof blocknoteSchema.Block,
        {
          type:
            | 'phases'
            | 'table'
            | 'image'
            | 'paragraph'
            | 'heading'
            | 'bulletListItem'
            | 'numberedListItem'
            | 'checkListItem'
            | 'file'
            | 'video'
            | 'audio'
        }
      >
    ) => any
  >
}
