import { useDispatch } from 'features/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { INITIAL_EXAMPLE_STATE } from 'modules/Integrations/domain/integrations.constants'
import {
  AvailableServices,
  FullIntegrationServices,
  IntegrationsDomainInterface,
  IntegrationsPortImplementation,
  IntegrationsReducerState,
  RecursivePartial
} from 'modules/Integrations/domain/integrations.domain.types'
import { integrationsReducer } from 'modules/Integrations/domain/integrations.reducer'
import { useCallback, useMemo, useReducer } from 'react'
type IntegrationsFeatureFlags = {
  useSalesforceSandboxAuth: boolean | undefined
}
export const connectToService =
  (ctx: {
    connectOut: IntegrationsPortImplementation['out']['connect']
    useSalesforceSandboxAuth: boolean | undefined
  }) =>
  async (service: AvailableServices) => {
    const buildRuntimeParams = () => {
      switch (service) {
        case 'Salesforce':
          return {
            is_sandbox: ctx.useSalesforceSandboxAuth
          }
        default:
          return {}
      }
    }
    return await ctx.connectOut({
      service: service as FullIntegrationServices,
      params: buildRuntimeParams()
    })
  }
type UseIntegrationsDomain = (props: {
  ports: IntegrationsPortImplementation
}) => IntegrationsDomainInterface
export const useIntegrationsDomain: UseIntegrationsDomain = props => {
  const globalStoreDispatch = useDispatch()
  const [state, dispatch] = useReducer(
    integrationsReducer,
    INITIAL_EXAMPLE_STATE
  )
  const flags = useFlags<IntegrationsFeatureFlags>()
  const queries = useMemo(() => {
    return {
      ...state.queries,
      rawData: {
        data: state.data,
        configuration: state.configuration,
        editor: state.editor,
        initialData: state.initialData
      }
    }
  }, [state])
  /**
   * Internal mutators
   */
  const updateEditor = useCallback(
    (newState: RecursivePartial<IntegrationsReducerState['editor']>) => {
      dispatch({
        type: 'updateEditor',
        payload: newState
      })
    },
    []
  )
  const updateData = useCallback(
    (newState: RecursivePartial<IntegrationsReducerState['data']>) => {
      dispatch({
        type: 'updateData',
        payload: newState
      })
    },
    []
  )
  /**
   * External mutators
   */
  const load = useCallback(async () => {
    dispatch({
      type: 'updateEditor',
      payload: {
        loadingError: false,
        loaded: false
      }
    })
    const response = await props.ports.in.load()
    if (!response.data) {
      dispatch({
        type: 'updateEditor',
        payload: {
          loadingError: true,
          loaded: true
        }
      })
      return response
    }
    dispatch({
      type: 'load',
      payload: response.data
    })
    return response
  }, [props.ports.in])
  const reloadIntegrations = useCallback(async () => {
    const response = await props.ports.in.load()
    if (!response.data) {
      return response
    }
    dispatch({
      type: 'reloadData',
      payload: {
        integrations: response.data.data.integrations
      }
    })
  }, [props.ports.in, globalStoreDispatch])
  const updateIntegrationConfiguration = useCallback(
    async (service: AvailableServices) => {
      try {
        dispatch({
          type: 'updateEditor',
          payload: {
            updating: true
          }
        })
        const res = await props.ports.out.updateIntegrationConfiguration({
          service: service as FullIntegrationServices,
          configuration: queries.rawData.editor.configuration.dataToSave ?? {}
        })
        if (!res.success) {
          throw new Error('Failed to update integration configuration')
        }
        await reloadIntegrations()
        return {
          success: res.success
        }
      } catch {
        return {
          success: false
        }
      } finally {
        dispatch({
          type: 'updateEditor',
          payload: {
            updating: false
          }
        })
      }
    },
    [props.ports.out, queries, reloadIntegrations]
  )
  const connect = useCallback(
    async (service: AvailableServices) => {
      try {
        dispatch({
          type: 'updateEditor',
          payload: {
            connecting: true
          }
        })
        const connectRes = await connectToService({
          connectOut: props.ports.out.connect,
          useSalesforceSandboxAuth: flags.useSalesforceSandboxAuth
        })(service)
        if (!connectRes.success) {
          throw new Error('Failed to connect with service')
        }
        await reloadIntegrations()
        return {
          success: true
        }
      } catch {
        return {
          success: false
        }
      } finally {
        dispatch({
          type: 'updateEditor',
          payload: {
            connecting: false
          }
        })
      }
    },
    [props.ports.out, reloadIntegrations, flags.useSalesforceSandboxAuth]
  )
  const removeIntegration = useCallback(
    async (
      service: AvailableServices,
      { removeLinks }: { removeLinks: boolean }
    ) => {
      try {
        dispatch({
          type: 'updateEditor',
          payload: {
            removing: true
          }
        })
        const res = await props.ports.out.removeIntegration(
          service as FullIntegrationServices,
          {
            removeLinks
          }
        )
        if (!res.success) {
          throw new Error('Failed to remove integration')
        }
        await reloadIntegrations()
        return {
          success: true
        }
      } catch {
        return {
          success: false
        }
      } finally {
        dispatch({
          type: 'updateEditor',
          payload: {
            removing: false
          }
        })
      }
    },
    [props.ports.out, reloadIntegrations]
  )
  return {
    queries,
    mutators: {
      updateEditor,
      updateData,
      external: {
        in: {
          load
        },
        out: {
          updateIntegrationConfiguration,
          connect,
          removeIntegration
        }
      }
    }
  }
}
