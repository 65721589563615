import { Center, Flex, Spinner, Box, Text } from '@chakra-ui/react'
import { toMoney } from '@sequencehq/utils'
import { MagicTable, MagicTableCell, RowSpanningRule } from '@sequencehq/tables'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { useState } from 'react'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { Link } from 'react-router-dom'
import { CellContext } from '@tanstack/react-table'
import {
  DeferredRevenueSchedule,
  useDeferredRevenueDashboardTableQuery
} from './useDeferredRevenueDashboardTableQuery'
import { IndigoIndigo50, Lato13Regular } from '@sequencehq/design-tokens'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { humanize } from 'inflection'
import { DeferredRevenueDetailDrawer } from './DeferredRevenueDetailDrawer'
import { ProgressDonut } from 'RevenueRecognition/view/common/ProgressDonut'

export type DeferredRevenueTableGroupBy =
  | 'customer'
  | 'product'
  | 'invoice'
  | undefined

const columnOrderByGroup: Record<string, string[]> = {
  customer: [
    'customer',
    'product',
    'invoice',
    'method',
    'deferredAmount',
    'recognizedAmount'
  ],
  product: [
    'product',
    'customer',
    'invoice',
    'method',
    'deferredAmount',
    'recognizedAmount'
  ],
  invoice: [
    'invoice',
    'customer',
    'product',
    'method',
    'deferredAmount',
    'recognizedAmount'
  ]
}

type DefRevTableView = {
  model: DeferredRevenueSchedule
  controlFilters: Record<string, unknown>
  propFilters: Record<string, unknown>
}

const getOrderedColumns = (
  selectedCurrency: Currency,
  groupBy: DeferredRevenueTableGroupBy
) => {
  const baseColumns = [
    {
      id: 'customer',
      header: 'Customer',
      accessorFn: (row: DeferredRevenueSchedule) => row.customer?.name,
      cell: (value: CellContext<DeferredRevenueSchedule, unknown>) => {
        const customer = value.row.original.customer
        if (!customer) {
          return null
        }
        const percentage = value.row.original.progress.recognized.percentage

        return (
          <Flex alignItems="center" height="40px" pl="6px" gap={2}>
            {groupBy !== 'customer' && (
              <ProgressDonut
                animation={{ delayBeforeStart: 150 }}
                percentage={percentage}
                size="14px"
              />
            )}
            <CustomerPreviewCardPill customerId={customer.id} variant="ghost" />
          </Flex>
        )
      }
    },
    {
      id: 'product',
      header: 'Product',
      accessorFn: (row: DeferredRevenueSchedule) => row.product?.name,
      cell: (value: CellContext<DeferredRevenueSchedule, unknown>) => {
        const product = value.row.original.product

        if (!product) {
          return null
        }

        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            height="40px"
            pl="6px"
          >
            <Link
              to={`/products/${product.id}`}
              data-testid="deferred-revenue.detail.product"
            >
              <Text
                {...Lato13Regular}
                color={IndigoIndigo50}
                fontWeight="semibold"
              >
                {product.name}
              </Text>
            </Link>
          </Flex>
        )
      }
    },
    {
      id: 'invoice',
      header: 'Invoice',
      accessorFn: (row: DeferredRevenueSchedule) => row.invoice?.name,
      cell: (value: CellContext<DeferredRevenueSchedule, unknown>) => {
        const invoice = value.row.original.invoice

        if (!invoice) {
          return null
        }

        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            height="40px"
            pl="6px"
          >
            <Link
              to={`/invoices/${invoice.id}`}
              data-testid="deferred-revenue.detail.invoice"
            >
              <Text
                {...Lato13Regular}
                color={IndigoIndigo50}
                fontWeight="semibold"
              >
                {invoice.name}
              </Text>
            </Link>
          </Flex>
        )
      }
    },
    {
      id: 'method',
      header: 'Method',
      accessorFn: (row: DeferredRevenueSchedule) => row.method,
      cell: (value: CellContext<DeferredRevenueSchedule, unknown>) => (
        <MagicTableCell
          text={humanize(value.getValue<string>())}
          textAlign="right"
        />
      )
    },
    {
      id: 'recognizedAmount',
      header: 'Recognized',
      accessorFn: (row: DeferredRevenueSchedule) =>
        row.progress.recognized.amount,
      cell: (value: CellContext<DeferredRevenueSchedule, unknown>) => (
        <MagicTableCell
          text={toMoney({
            value: `${value.getValue<number>()}`,
            currency: selectedCurrency
          })}
          textAlign="right"
        />
      )
    },
    {
      id: 'deferredAmount',
      header: 'Remaining',
      accessorFn: (row: DeferredRevenueSchedule) =>
        row.progress.remaining.amount,
      cell: (value: CellContext<DeferredRevenueSchedule, unknown>) => (
        <MagicTableCell
          text={toMoney({
            value: `${value.getValue<number>()}`,
            currency: selectedCurrency
          })}
          textAlign="right"
        />
      )
    }
  ]

  if (!groupBy) {
    return baseColumns
  }

  const currentOrder = columnOrderByGroup[groupBy]
  return baseColumns.sort((a, b) => {
    const aIndex = currentOrder.indexOf(a.id)
    const bIndex = currentOrder.indexOf(b.id)
    return aIndex - bIndex
  })
}

export const DeferredRevenueDashboardTable = () => {
  const [groupBy, setGroupBy] = useState<DeferredRevenueTableGroupBy>()
  const { detail, isLoading } = useDeferredRevenueDashboardTableQuery({
    groupBy
  })

  const [selectedRow, setSelectedRow] =
    useState<DeferredRevenueSchedule | null>(null)

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    )
  }

  if (!detail?.schedules.length) {
    return null
  }

  const columns = getOrderedColumns(detail.currency, groupBy)

  const infiniteQuery = {
    data: {
      pages: [
        {
          items: detail.schedules ?? [],
          pagination: {
            after: '',
            before: '',
            totalResultSize: detail.schedules?.length ?? 0
          }
        }
      ]
    },
    isFetching: isLoading,
    hasNextPage: false,
    isFetchingNextPage: false,
    fetchNextPage: () => Promise.resolve()
  }

  return (
    <Box height="100%">
      {selectedRow && (
        <DeferredRevenueDetailDrawer
          selectedScheduleId={selectedRow.id}
          canRecognizeRevenue={selectedRow.method === 'MILESTONE'}
          closeDrawer={() => setSelectedRow(null)}
        />
      )}
      <CurrentUserId>
        {userId => (
          <MagicTable<DefRevTableView>
            key={groupBy}
            entityNamePlural="Deferred Revenue Schedules"
            enableColumnReordering={false}
            showAddColumn={false}
            infiniteQuery={infiniteQuery}
            columns={columns}
            sequenceUserId={userId}
            onRowClick={row => {
              setSelectedRow(row)
            }}
            grouping={{
              rowSpanningRules: [
                {
                  columnId: 'invoice',
                  spanBy: (current, previous) => {
                    if (groupBy === 'invoice') {
                      return current.invoice?.id === previous.invoice?.id
                    }

                    return false
                  }
                } as RowSpanningRule<DeferredRevenueSchedule>,
                {
                  columnId: 'customer',
                  spanBy: (current, previous) => {
                    if (groupBy === 'customer') {
                      return current.customer?.id === previous.customer?.id
                    }

                    return false
                  }
                } as RowSpanningRule<DeferredRevenueSchedule>,
                {
                  columnId: 'product',
                  spanBy: (current, previous) => {
                    if (groupBy === 'product') {
                      return current.product?.id === previous.product?.id
                    }

                    return false
                  }
                } as RowSpanningRule<DeferredRevenueSchedule>
              ],
              groupBy,
              onGroupBy: (columnId?: string) => {
                setGroupBy(columnId as DeferredRevenueTableGroupBy)
              },
              groupableColumns: ['invoice', 'customer', 'product'],
              highlightRowGroup: row => row.id,
              getGroupKey: row => {
                switch (groupBy) {
                  case 'invoice':
                    return row.invoice?.id ?? row.id
                  case 'customer':
                    return row.customer?.id ?? row.id
                  case 'product':
                    return row.product?.id ?? row.id
                  default:
                    return row.id
                }
              }
            }}
          />
        )}
      </CurrentUserId>
    </Box>
  )
}
