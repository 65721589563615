import {
  useSaveBillingSchedule,
  v1ApiNewBillingSchedule,
  v1ApiUpdateBillingSchedule
} from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSaveBillingSchedule'
import {
  useSaveBillingScheduleSettings,
  v1ApiNewBillingScheduleSettings
} from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSaveBillingScheduleSettings'
import {
  useSavePrices,
  v1ApiNewPrice
} from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSavePrices'
import {
  useSaveProducts,
  v1ApiNewProduct
} from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSaveProducts'
import uniq from 'lodash/fp/uniq'
import uniqBy from 'lodash/fp/uniqBy'
import { useCallback } from 'react'
import { v1ApiBillingSchedule } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entityLoaders/useLoadBillingSchedule'
import { v1ApiPrice } from '../../common.api.v1/ports/entityLoaders/useLoadPrices'
import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type SaveBillingScheduleData = {
  billingSchedule: v1ApiNewBillingSchedule | v1ApiUpdateBillingSchedule
  billingScheduleSettings: {
    paymentProvider: v1ApiNewBillingScheduleSettings['paymentProvider']
    autoCharge?: boolean
  }
  prices: {
    all: (v1ApiNewPrice | v1ApiPrice)[]
    new: v1ApiNewPrice[]
    deleted: v1ApiNewPrice[]
  }
  products: {
    new: v1ApiNewProduct[]
  }
}

export type UseSaveBillingScheduleEditor = () => {
  updateBillingSchedule: (scheduleId: string) => (
    data: SaveBillingScheduleData
  ) => Promise<{
    schedule: v1ApiBillingSchedule | null
    success: boolean
  }>
  createBillingScheduleDraft: (data: SaveBillingScheduleData) => Promise<{
    newSchedule: v1ApiBillingSchedule | null
    success: boolean
  }>
  activateSchedule: (scheduleId?: string) => (
    data: SaveBillingScheduleData
  ) => Promise<{
    schedule: v1ApiBillingSchedule | null
    success: boolean
  }>
}

export const applyNewPriceIdsToSchedule =
  (billingSchedule: SaveBillingScheduleData['billingSchedule']) =>
  (
    newPrices: { temporaryId: string; price: v1ApiNewPrice }[]
  ): SaveBillingScheduleData['billingSchedule'] => {
    /** Apply transform to all prices within tax rates and phases for the phased schedule */
    return {
      ...billingSchedule,
      phases: billingSchedule.phases.map(phase => ({
        ...phase,
        priceIds: uniq(
          phase.priceIds.map(
            priceId =>
              newPrices.find(({ temporaryId }) => temporaryId === priceId)
                ?.price.id ?? priceId
          )
        ),
        phasePriceMetadata: phase.phasePriceMetadata.map(metadata => ({
          ...metadata,
          priceId:
            newPrices.find(
              ({ temporaryId }) => temporaryId === metadata.priceId
            )?.price.id ?? metadata.priceId
        })),
        minimums:
          phase.minimums?.map(minimum => ({
            ...minimum,
            restrictToPrices: uniq(
              minimum.restrictToPrices.map(
                priceId =>
                  newPrices.find(({ temporaryId }) => temporaryId === priceId)
                    ?.price.id ?? priceId
              )
            )
          })) ?? [],
        discounts:
          phase.discounts?.map(discount => ({
            ...discount,
            type: discount.type,
            restrictToPrices: uniq(
              discount.restrictToPrices.map(
                priceId =>
                  newPrices.find(({ temporaryId }) => temporaryId === priceId)
                    ?.price.id ?? priceId
              )
            )
          })) ?? []
      })),
      taxRates: uniqBy(
        'priceId',
        billingSchedule.taxRates.map(taxRate => ({
          ...taxRate,
          priceId:
            newPrices.find(({ temporaryId }) => temporaryId === taxRate.priceId)
              ?.price.id ?? taxRate.priceId
        }))
      )
    }
  }

export const applyNewProductIds =
  (productsMapping: { temporaryId: string; realId: string }[]) =>
  (prices: v1ApiNewPrice[]): v1ApiNewPrice[] => {
    return prices.map(price => ({
      ...price,
      productId:
        productsMapping.find(
          productMapping => productMapping.temporaryId === price.productId
        )?.realId ?? price.productId
    }))
  }

export const useSaveBillingScheduleEditor: UseSaveBillingScheduleEditor =
  () => {
    const billingScheduleMutator = useSaveBillingSchedule()
    const billingScheduleSettingsMutator = useSaveBillingScheduleSettings()
    const pricesMutator = useSavePrices()
    const productsMutator = useSaveProducts()
    const activateScheduleMutation = useMutation(
      dashboard20240730Client.putBillingScheduleActivate
    )

    const createNewBillingSchedule = useCallback(
      async (
        data: SaveBillingScheduleData
      ): Promise<{
        newSchedule: v1ApiBillingSchedule | null
        success: boolean
      }> => {
        const newProducts = await productsMutator.createProducts(
          data.products.new
        )
        if (newProducts.find(({ realId }) => !realId)) {
          return {
            newSchedule: null,
            success: false
          }
        }

        const newPrices = await pricesMutator.createPrices(
          applyNewProductIds(
            newProducts as { temporaryId: string; realId: string }[]
          )(data.prices.all)
        )

        if (!newPrices) {
          return {
            newSchedule: null,
            success: false
          }
        }

        const newSchedule = await billingScheduleMutator.createBillingSchedule(
          applyNewPriceIdsToSchedule(data.billingSchedule)(
            newPrices
          ) as v1ApiNewBillingSchedule
        )

        if (!newSchedule) {
          return {
            newSchedule: null,
            success: false
          }
        }

        const newScheduleSettings =
          await billingScheduleSettingsMutator.updateBillingScheduleSettings({
            billingScheduleId: newSchedule.id,
            paymentProvider: data.billingScheduleSettings.paymentProvider,
            autoCharge: !!data.billingScheduleSettings.autoCharge
          })

        if (!newScheduleSettings) {
          return {
            newSchedule,
            success: false
          }
        }

        return {
          newSchedule,
          success: true
        }
      },
      [
        billingScheduleMutator,
        pricesMutator,
        billingScheduleSettingsMutator,
        productsMutator
      ]
    )

    const updateBillingSchedule = useCallback(
      (billingScheduleId: string) =>
        async (
          data: SaveBillingScheduleData
        ): Promise<{
          schedule: v1ApiBillingSchedule | null
          success: boolean
        }> => {
          const newProducts = await productsMutator.createProducts(
            data.products.new
          )

          if (newProducts.find(({ realId }) => !realId)) {
            return {
              success: false,
              schedule: null
            }
          }

          const newPrices = await pricesMutator.createPrices(
            applyNewProductIds(
              newProducts as {
                temporaryId: string
                realId: string
              }[]
            )(data.prices.new)
          )

          if (!newPrices) {
            return {
              schedule: null,
              success: false
            }
          }

          const [updatedScheduleSettings, updatedSchedule] = await Promise.all([
            billingScheduleSettingsMutator.updateBillingScheduleSettings({
              billingScheduleId,
              paymentProvider: data.billingScheduleSettings.paymentProvider,
              autoCharge: !!data.billingScheduleSettings.autoCharge
            }),
            billingScheduleMutator.updateBillingSchedule({
              id: billingScheduleId,
              data: applyNewPriceIdsToSchedule(data.billingSchedule)(newPrices)
            })
          ])

          if (!updatedScheduleSettings || !updatedSchedule) {
            return {
              schedule: null,
              success: false
            }
          }

          return {
            schedule: updatedSchedule,
            success: true
          }
        },
      [
        billingScheduleMutator,
        pricesMutator,
        billingScheduleSettingsMutator,
        productsMutator
      ]
    )

    const createBillingScheduleDraft = useCallback(
      async (
        data: SaveBillingScheduleData
      ): Promise<{
        newSchedule: v1ApiBillingSchedule | null
        success: boolean
      }> => {
        const result = await createNewBillingSchedule(data)
        return result
      },
      [createNewBillingSchedule]
    )

    const activateSchedule = useCallback(
      (billingScheduleId?: string) =>
        async (
          data: SaveBillingScheduleData
        ): Promise<{
          schedule: v1ApiBillingSchedule | null
          success: boolean
        }> => {
          if (!billingScheduleId || billingScheduleId === 'new') {
            const creationResult = await createNewBillingSchedule(data)

            if (!creationResult.success || !creationResult.newSchedule?.id) {
              return {
                schedule: creationResult.newSchedule,
                success: false
              }
            }

            const activationResult = await activateScheduleMutation.mutateAsync(
              {
                id: creationResult.newSchedule.id
              }
            )

            if (!activationResult) {
              return {
                schedule: creationResult.newSchedule,
                success: false
              }
            }

            return {
              schedule: creationResult.newSchedule,
              success: true
            }
          }

          const updateResult =
            await updateBillingSchedule(billingScheduleId)(data)

          if (!updateResult.success || !updateResult.schedule) {
            return {
              schedule: updateResult.schedule,
              success: false
            }
          }

          const activationResult = await activateScheduleMutation.mutateAsync({
            id: billingScheduleId
          })

          if (!activationResult) {
            return {
              schedule: updateResult.schedule,
              success: false
            }
          }

          return {
            schedule: updateResult.schedule,
            success: true
          }
        },
      [
        activateScheduleMutation,
        updateBillingSchedule,
        createNewBillingSchedule
      ]
    )

    return {
      createBillingScheduleDraft,
      updateBillingSchedule,
      activateSchedule
    }
  }
