import { IconButton } from '@chakra-ui/react'
import { GreyGrey70 } from '@sequencehq/design-tokens'

import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import CalendarDaysIcon from '@heroicons/react/16/solid/CalendarDaysIcon'
import DocumentCheckIcon from '@heroicons/react/16/solid/DocumentCheckIcon'
import IdentificationIcon from '@heroicons/react/16/solid/IdentificationIcon'

import { useNavigate } from 'react-router-dom'
import { SequenceMenu, SequenceMenuItem } from '@sequencehq/core-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const AddMenu = () => {
  const navigate = useNavigate()
  const flags = useFlags()

  const items = [
    <SequenceMenuItem
      key="new-schedule"
      uuid="new-schedule"
      label="New schedule"
      iconRight={
        <CalendarDaysIcon width="16px" height="16px" color="inherit" />
      }
      onClick={() => navigate('/billing-schedules/new?reset=true')}
      data-testid="header.addMenu.newSchedule"
    />,
    !flags?.showQuoteBuilder ? null : (
      <SequenceMenuItem
        key="new-quote"
        uuid="new-quote"
        label="New quote"
        iconRight={
          <DocumentCheckIcon width="16px" height="16px" color="inherit" />
        }
        onClick={() => navigate('/quotes/new?reset=true')}
        data-testid="header.addMenu.newQuote"
      />
    ),
    <SequenceMenuItem
      key="new-customer"
      uuid="new-customer"
      label="New customer"
      iconRight={
        <IdentificationIcon width="16px" height="16px" color="inherit" />
      }
      onClick={() => navigate('/customers/create')}
      data-testid="header.addMenu.newCustomer"
    />
  ].filter(Boolean)

  return (
    <SequenceMenu
      width={180}
      menuButton={
        <IconButton
          height="28px"
          width="28px"
          padding="0"
          minWidth="initial"
          variant="secondary2"
          aria-label="Action"
          icon={<PlusIcon width="16px" height="16px" color={GreyGrey70} />}
          flexShrink="0"
          data-testid="header.addMenu.button"
        />
      }
      items={items}
    />
  )
}
