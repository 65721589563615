import { Flex } from '@chakra-ui/react'
import { RevRecDashboardTable } from './RevRecDashboardTable'

export const RevenueRecognitionDashboardContent = () => {
  return (
    <Flex flexDirection="column" height="100%">
      <RevRecDashboardTable />
    </Flex>
  )
}
