import { useMutation, useApiQueryClient } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useNotifications } from 'lib/hooks/useNotifications'
import * as Sentry from '@sentry/react'
import type { TaxCategory } from 'Settings/domain/taxRates.types'

export function useUpdateTaxCategoryName() {
  const notifications = useNotifications()
  const queryClient = useApiQueryClient()

  const { mutateAsync: postNewTaxCategoryVersion } = useMutation(
    dashboardv99990101Client.postNewTaxCategoryVersion,
    {
      onSuccess: () => {
        // Invalidate both tax categories and avalara mappings queries to refresh the UI
        void queryClient.invalidateQueries(
          dashboardv99990101Client.getTaxCategories
        )
        void queryClient.invalidateQueries(
          dashboardv99990101Client.getAllTaxCategoriesWithAvalaraMappings
        )
      }
    }
  )

  return async (taxCategory: TaxCategory, newName: string) => {
    try {
      await postNewTaxCategoryVersion({
        id: taxCategory.id,
        body: {
          version: taxCategory.version + 1,
          name: newName,
          description: taxCategory.description,
          isPublished: true
        }
      })

      notifications.displayNotification('Tax category updated', {
        type: 'success'
      })
    } catch (error) {
      Sentry.captureException(error)
      notifications.displayNotification('Failed to update tax category', {
        type: 'error'
      })
    }
  }
}
