import { Box, Flex } from '@chakra-ui/react'
import { InputSelectControl } from '@sequencehq/core-components'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { Currency } from '@sequencehq/api'
import { useAvailableCurrencies } from 'RevenueRecognition/view/utils/useAvailableCurrencies'

export const RevRecDashboardCurrencyFilter = () => {
  const { currency, setCurrency } = useSelectedCurrency()
  const { availableCurrencies } = useAvailableCurrencies()

  return (
    <Flex gap="8px">
      <Box width="84px">
        {!!currency && !!availableCurrencies && (
          <InputSelectControl
            data-testid="revrec.dashboard.currencySelect"
            options={
              availableCurrencies?.map(ac => ({
                label: ac,
                value: ac
              })) ?? []
            }
            initialValue={currency}
            onChange={newCurrency => setCurrency(newCurrency as Currency)}
          />
        )}
      </Box>
    </Flex>
  )
}
