import * as Sentry from '@sentry/react'
import { downloadBlob } from '@sequencehq/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type UseInvoicesCsvExport = ({
  filtersApiQueryParams
}: {
  filtersApiQueryParams: string
}) => {
  download: {
    available: boolean
    disabled: boolean
    onClick: () => void
  }
}

export const useInvoicesCsvExport: UseInvoicesCsvExport = ({
  filtersApiQueryParams
}) => {
  const notifications = useNotifications()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification(
      'Preparing download - this may take a few minutes',
      {
        id: 'download-csv',
        type: 'neutral',
        isClosable: false
      }
    )
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const downloadCsvFile = useCallback(async () => {
    try {
      const invoicesCsvRes =
        await dashboard20240730Client.getInvoicesCsvDownload(
          filtersApiQueryParams
        )

      if (!invoicesCsvRes.data) {
        throw new Error('An error occurred while downloading the invoices')
      }

      downloadBlob('invoice-export.csv')(invoicesCsvRes.data)

      showToastSuccess()
    } catch (e) {
      showToastError()
      Sentry.captureException(e)
    }
  }, [showToastError, showToastSuccess, filtersApiQueryParams])

  const downloadCsv = useCallback(() => {
    showToastPreparing()

    void downloadCsvFile()
  }, [showToastPreparing, downloadCsvFile])

  return {
    download: {
      available: true,
      disabled: false,
      onClick: () => downloadCsv()
    }
  }
}
