import { FC } from 'react'

import { Box, Center, Flex } from '@chakra-ui/react'

import { Modal, ModalContextProvider } from '@sequencehq/core-components'
import Spinner from 'components/Loading'
import { PdfViewer } from '@sequencehq/pdf-viewer'

type Props = {
  title: string
  data: Blob | undefined
  fileName: string
  isOpen?: boolean
  onClose?: VoidFunction
}

export const PDFPreviewDrawer: FC<Props> = ({
  title,
  data,
  fileName,
  isOpen,
  onClose
}) => {
  return (
    <ModalContextProvider isOpen={Boolean(isOpen)}>
      <Modal
        variant="drawer"
        onClose={() => {
          onClose?.()
        }}
      >
        <Modal.Header
          showCloseButton
          withBorder
          onClose={() => {
            onClose?.()
          }}
        >
          <Flex justify="space-between" alignItems="center" width="100%">
            {title}
          </Flex>
        </Modal.Header>
        <Modal.Content padding={0}>
          <Center width="100%" height="100%" position="relative">
            {!data ? (
              <Spinner />
            ) : (
              <Box
                width="100%"
                height="100%"
                position="absolute"
                top="0"
                left="0"
              >
                <PdfViewer data={data} fileName={fileName}>
                  <PdfViewer.StandardControls />
                </PdfViewer>
              </Box>
            )}
          </Center>
        </Modal.Content>
      </Modal>
    </ModalContextProvider>
  )
}
