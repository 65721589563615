import { v1ApiLoadedData } from '../communication/external/v1/ports'
import {
  BillingFrequency,
  EditorMode,
  PricingEditorReducerState,
  PricingModel,
  StandardPrice,
  VisibleEnabledFeatureAvailable
} from './pricingEditor.domain.types'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const NEW_PRICE_PREFIX = 'upe-new-price::'
export const NEW_PRICE_PATTERN = /^upe-new-price::/
export const NEW_PRODUCT_PREFIX = 'upe-new-product::'
export const NEW_PRODUCT_PATTERN = /^upe-new-product::/
export const EXTRACTED_PRICE_PREFIX = 'extracted-price::'

export const INITIAL_PRODUCT_DATA: v1ApiLoadedData['product'] = {
  id: `${NEW_PRODUCT_PREFIX}${crypto.randomUUID()}`,
  name: '',
  label: '',
  createdAt: '',
  updatedAt: '',
  integrationIds: []
}

export const PRICING_TYPES_WITH_PERCENTAGE = ['LINEAR', 'GRADUATED', 'VOLUME']

export const PRICING_MODEL_OPTIONS: {
  value: PricingModel
  label: string
  description: string
}[] = [
  {
    value: 'STANDARD',
    label: 'Standard',
    description: 'Charge a recurring or one-off fixed fee'
  },
  {
    value: 'LINEAR',
    label: 'Linear',
    description: 'Charge a single, linear rate for usage.'
  },
  {
    value: 'VOLUME',
    label: 'Volume tiers',
    description: 'All usage is charged a single unit fee based on total volume.'
  },
  {
    value: 'GRADUATED',
    label: 'Graduated tiers',
    description:
      'Usage in each tier is charged separately using respective tier fees.'
  },
  {
    value: 'PACKAGED',
    label: 'Packaged',
    description: 'Charge a fixed fee for a packaged quantity of units.'
  },
  {
    value: 'SEAT_BASED_LINEAR',
    label: 'Seat-based → Linear Pricing',
    description: 'Charge a single, linear rate for seats.'
  },
  {
    value: 'SEAT_BASED_GRADUATED',
    label: 'Seat-based → Graduated Pricing',
    description:
      'Seat usage in each tier is charged separately using respective tier fees.'
  }
]

export const DEFAULT_AVAILABLE_FEATURE: VisibleEnabledFeatureAvailable = {
  available: {
    visible: false,
    enabled: false
  }
}

export const BASE_INITIAL_LIST_PRICE: Omit<
  NonNullable<v1ApiLoadedData['listPrice']>,
  'productId' | 'currency'
> = {
  id: `${NEW_PRICE_PREFIX}${crypto.randomUUID()}`,
  name: '',
  billingFrequency: 'MONTHLY',
  billingType: 'IN_ARREARS',
  structure: {
    pricingType: 'FIXED',
    price: ''
  },
  integrationIds: [],
  customMetricParameters: [],
  createdAt: ''
}

export const BASE_INITIAL_VARIANT_PRICE: Omit<
  NonNullable<v1ApiLoadedData['variantPrice']>,
  'productId' | 'currency'
> = {
  id: `${NEW_PRICE_PREFIX}${crypto.randomUUID()}`,
  name: '',
  billingFrequency: 'MONTHLY',
  billingType: 'IN_ARREARS',
  structure: {
    pricingType: 'FIXED',
    price: ''
  },
  integrationIds: [],
  customMetricParameters: [],
  createdAt: ''
}

export const INITIAL_PRICING_EDITOR_STATE: PricingEditorReducerState = {
  data: {
    pricingEditorData: {
      common: {
        id: '',
        name: '',
        productId: '',
        pricingModel: 'STANDARD' as PricingModel,
        currency: 'GBP' as Currency,
        integrationIds: {}
      },
      STANDARD: {
        price: '0.00',
        billingType: 'IN_ARREARS',
        billingFrequency: 'MONTHLY'
      } as PricingEditorReducerState['data']['pricingEditorData']['STANDARD'],
      LINEAR: {
        price: '0.00',
        percentage: '0',
        usageMetricId: '',
        linearPriceType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        minPrice: '',
        maxPrice: '',
        parameters: {}
      } as PricingEditorReducerState['data']['pricingEditorData']['LINEAR'],
      VOLUME: {
        usageMetricId: '',
        usageTierType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        includePercentageLimits: false,
        parameters: {},
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ],
        percentageTiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0.00',
            lastUnit: '1.00',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '1.01',
            lastUnit: '',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          }
        ]
      } as PricingEditorReducerState['data']['pricingEditorData']['VOLUME'],
      GRADUATED: {
        usageMetricId: '',
        usageTierType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        parameters: {},
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ],
        percentageTiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0.00',
            lastUnit: '1.00',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '1.01',
            lastUnit: '',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          }
        ],
        usageCalculationMode: 'BILLING_PERIOD'
      } as PricingEditorReducerState['data']['pricingEditorData']['GRADUATED'],
      PACKAGED: {
        usageMetricId: '',
        pricePerPackage: '0.00',
        packageSize: '0',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        parameters: {}
      } as PricingEditorReducerState['data']['pricingEditorData']['PACKAGED'],
      SEAT_BASED_LINEAR: {
        seatMetricId: '',
        pricePerSeat: '0.00',
        contractedMinimumSeats: '0',
        prorationStrategy: 'PRORATE_INCREMENTS',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        overagesBillingFrequency: 'MONTHLY',
        prorateFlatFees: false
      } as PricingEditorReducerState['data']['pricingEditorData']['SEAT_BASED_LINEAR'],
      SEAT_BASED_GRADUATED: {
        seatMetricId: '',
        pricePerSeat: '0.00',
        contractedMinimumSeats: '0',
        prorationStrategy: 'PRORATE_INCREMENTS',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        overagesBillingFrequency: 'MONTHLY',
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ],
        prorateFlatFees: false
      } as PricingEditorReducerState['data']['pricingEditorData']['SEAT_BASED_GRADUATED']
    },
    integrations: {},
    listPrices: {},
    product: {
      id: '',
      name: ''
    }
  },
  configuration: {
    availableCurrencies: [],
    mode: EditorMode.CREATE,
    priceType: 'list'
  },
  queries: {
    availableFeatures: {
      common: {
        breadcrumb: DEFAULT_AVAILABLE_FEATURE,
        form: DEFAULT_AVAILABLE_FEATURE,
        review: DEFAULT_AVAILABLE_FEATURE,
        save: DEFAULT_AVAILABLE_FEATURE
      },
      listPrice: {
        addProduct: DEFAULT_AVAILABLE_FEATURE
      },
      variantPrice: {
        canChangeListPrice: DEFAULT_AVAILABLE_FEATURE
      }
    },
    breadcrumbSteps: [],
    formattedPricingEditorData: ''
  },
  editor: {
    loaded: false,
    showValidationErrors: false,
    steps: [],
    valid: false,
    showAdditionalFields: false,
    externalLedgersValid: true
  },
  initialData: {
    mode: EditorMode.CREATE,
    price: {
      id: '',
      common: {
        name: '',
        productId: '',
        pricingModel: 'STANDARD' as PricingModel,
        currency: 'GBP' as Currency,
        integrationIds: {}
      },
      modelSpecific: {
        price: '0.00',
        billingType: 'IN_ARREARS',
        billingFrequency: 'MONTHLY'
      }
    } as StandardPrice,
    product: {
      id: '',
      name: ''
    }
  }
}

export const defaultAvailableFrequenciesOptions: {
  value: BillingFrequency
  label: string
}[] = [
  {
    value: 'MONTHLY',
    label: 'Monthly'
  },
  {
    value: 'QUARTERLY',
    label: 'Quarterly'
  },
  {
    value: 'HALF_YEARLY',
    label: 'Semi-annually'
  },
  {
    value: 'YEARLY',
    label: 'Annually'
  }
]
