import { Flex } from '@chakra-ui/react'
import {
  dashboard20240730Client,
  type DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { SequenceKebabMenu, Skeleton } from '@sequencehq/core-components'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellQuoteStatus,
  MagicTable,
  MagicTableEmptyState,
  MagicTableFilterEmptyState,
  ActiveFilter
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { QuoteView } from 'modules/Quotes/types'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { useNavigate } from 'react-router-dom'
import DocumentCheckIcon from '@heroicons/react/24/solid/DocumentCheckIcon'
import { QuoteTemplatesList } from './quoteTemplates/QuoteTemplatesList'
import { CreateTemplateFromQuoteMenuItem } from 'modules/common/menuItems/quotes/CreateTemplateFromQuoteMenuItem'
import { DuplicateQuoteMenuItem } from 'modules/common/menuItems/quotes/DuplicateQuoteMenuItem'
import { ArchiveQuoteMenuItem } from 'modules/common/menuItems/quotes/ArchiveQuoteMenuItem'
import { DownloadQuotePdfMenuItem } from 'modules/common/menuItems/quotes/DownloadQuotePdfMenuItem'
import { FC, useCallback } from 'react'
import { useQuotesMagicTable } from './QuotesMagicTable/useQuotesMagicTable'
import { QuotesSegmentedFilters } from './QuotesSegmentedFilters'
import { useQuery } from '@sequencehq/api'

type Props = {
  onSaveFilters?: (activeFilters: ActiveFilter[]) => void
  onUpdateSavedFilters?: (updatedActiveFilters: ActiveFilter[]) => void
  forcedActiveFilters?: ActiveFilter[]
  lockedControls?: boolean
  onFiltersApiQueryParamsChanged?: (params: string) => void
  showSegmentedFilters?: boolean
}

const QuotesMagicTable: FC<Props> = ({
  onSaveFilters,
  onUpdateSavedFilters,
  forcedActiveFilters = [],
  lockedControls = false,
  onFiltersApiQueryParamsChanged,
  showSegmentedFilters = false
}) => {
  const navigate = useNavigate()

  // Use the custom hook for table logic
  const {
    filters,
    activeFilters,
    sortBy,
    onChangeActiveFilters,
    onChangeSortBy,
    infiniteQuery
  } = useQuotesMagicTable({
    forcedActiveFilters,
    onFiltersApiQueryParamsChanged
  })

  // Create wrapper functions for onSaveFilters and onUpdateSavedFilters
  const handleSaveFilters = useCallback(() => {
    if (onSaveFilters) {
      onSaveFilters(activeFilters)
    }
  }, [onSaveFilters, activeFilters])

  // Only call onUpdateSavedFilters when explicitly triggered by user actions
  // This prevents the constant save requests when viewing a saved view
  const handleUpdateSavedFilters = useCallback(() => {
    if (onUpdateSavedFilters) {
      onUpdateSavedFilters(activeFilters)
    }
  }, [onUpdateSavedFilters, activeFilters])

  type ListQuoteModel = DashboardApi20240730.GetQuotes.Quote

  const additionalToolbarComponentsV3 = showSegmentedFilters
    ? {
        additionalToolbarComponentsV3: {
          start: <QuotesSegmentedFilters />
        }
      }
    : {}

  return (
    <CurrentUserId>
      {userId => (
        <>
          <MagicTable<QuoteView>
            activeFilters={activeFilters}
            onChangeActiveFilters={onChangeActiveFilters}
            sortBy={sortBy}
            onChangeSortBy={onChangeSortBy}
            entityNamePlural="quotes"
            sequenceUserId={userId}
            infiniteQuery={infiniteQuery}
            rowPath={quote => `/quotes/${quote.id}`}
            additionalHeaderRows={<QuoteTemplatesList />}
            onSaveFilters={onSaveFilters ? handleSaveFilters : undefined}
            onUpdateSavedFilters={
              onUpdateSavedFilters ? handleUpdateSavedFilters : undefined
            }
            lockedControls={lockedControls}
            {...additionalToolbarComponentsV3}
            columns={[
              {
                id: 'title',
                accessorFn: row => ({
                  title: row.title,
                  archivedAt: row.archivedAt
                }),
                header: 'Title',
                cell: value => {
                  const { archivedAt, title } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    title: ListQuoteModel['title']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={`${title || 'Untitled'}${
                        archivedAt ? ' (archived)' : ''
                      }`}
                    />
                  )
                }
              },
              {
                id: 'quoteNumber',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  quoteNumber: row.quoteNumber
                }),
                header: 'Number',
                cell: value => {
                  const { archivedAt, quoteNumber } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    quoteNumber: ListQuoteModel['quoteNumber']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={quoteNumber}
                    />
                  )
                }
              },
              {
                id: 'customer',
                accessorKey: 'customerId',
                header: 'Customer',
                cell: value => {
                  const customerId =
                    value.getValue<ListQuoteModel['customerId']>()
                  return customerId ? (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      height="40px"
                      pl="6px"
                    >
                      <CustomerPreviewCardPill
                        customerId={customerId}
                        variant="ghost"
                      />
                    </Flex>
                  ) : (
                    <MagicTableCell text="" />
                  )
                }
              },
              {
                id: 'status',
                header: 'Status',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  publishedAt: row.publishedAt,
                  isExpired: row.isExpired,
                  status: row.status
                }),
                cell: value => {
                  const { archivedAt, status, publishedAt, isExpired } =
                    value.getValue<
                      Pick<
                        ListQuoteModel,
                        'archivedAt' | 'status' | 'publishedAt' | 'isExpired'
                      >
                    >()

                  return (
                    <MagicTableCellQuoteStatus
                      status={status}
                      isArchived={Boolean(archivedAt)}
                      isPublished={Boolean(publishedAt)}
                      isExpired={Boolean(isExpired)}
                    />
                  )
                }
              },
              {
                id: 'user',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  createdBy: row.createdBy
                }),
                header: 'Owner',
                cell: value => {
                  const { archivedAt, createdBy } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    createdBy: ListQuoteModel['createdBy']
                  }>()

                  const userQuery = useQuery(dashboard20240730Client.getUser, {
                    id: createdBy
                  })

                  if (userQuery.isPending) {
                    return <Skeleton width="100%" height="100%" />
                  }

                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={userQuery.data?.email || ''}
                    />
                  )
                }
              },
              {
                id: 'expiresAt',
                accessorFn: row => ({
                  expiresAt: row.expiresAt,
                  expiryDays: row.expiryDays,
                  archivedAt: row.archivedAt
                }),
                header: 'Expires',
                cell: value => {
                  const { expiresAt, expiryDays, archivedAt } = value.getValue<{
                    expiresAt: ListQuoteModel['expiresAt']
                    expiryDays: ListQuoteModel['expiryDays']
                    archivedAt: ListQuoteModel['archivedAt']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={
                        expiresAt
                          ? dateTimeWithFormat(expiresAt, 'd MMM yyyy')
                          : expiryDays
                            ? `${expiryDays} days after publishing`
                            : ''
                      }
                    />
                  )
                }
              }
            ]}
            kebabMenu={(model: ListQuoteModel, props) => (
              <SequenceKebabMenu
                data-testid={`quotes.${model.id}.menu`}
                buttonSize="small"
                alignment="bottom-right"
                items={[
                  <DuplicateQuoteMenuItem
                    key="duplicate-quote"
                    quoteId={model.id}
                    onSuccess={(newQuoteId: string) =>
                      navigate(`/quotes/${newQuoteId}`)
                    }
                  />,
                  <CreateTemplateFromQuoteMenuItem
                    key="create-template-from-quote"
                    quoteId={model.id}
                  />,
                  <DownloadQuotePdfMenuItem
                    key="download-pdf"
                    quoteId={model.id}
                    quoteNumber={model.quoteNumber}
                    isPublished={Boolean(model.publishedAt)}
                  />,
                  ({ setIsOpen }) =>
                    !model.archivedAt && (
                      <ArchiveQuoteMenuItem
                        key="archive-quote"
                        data-testid={`quotes.${model.id}.archive`}
                        quoteId={model.id}
                        onSuccess={() => {
                          setIsOpen?.(false)
                        }}
                      />
                    )
                ]}
                {...props}
              />
            )}
            emptyStateUnfiltered={entityNamePlural => (
              <MagicTableEmptyState
                entityNamePlural={entityNamePlural}
                imageSrc="/img/empty/quotes.png"
                learnMoreText="Create order forms, proposals and sales quotes with custom deal terms in minutes"
                learnMoreHref="https://docs.sequencehq.com/cpq/create-quotes"
                buttonNew={{
                  label: 'New quote',
                  onClick: () => navigate('/quotes/new')
                }}
              />
            )}
            emptyStateFiltered={(entityNamePlural, clearFilters) => (
              <MagicTableFilterEmptyState
                entityNamePlural={entityNamePlural}
                entityIcon={DocumentCheckIcon}
                actionClearFilters={clearFilters}
                actionViewAll={() => navigate('/quotes')}
                variant="TABLE"
              />
            )}
            filters={filters}
          />
        </>
      )}
    </CurrentUserId>
  )
}

export default QuotesMagicTable
