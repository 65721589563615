import { Box } from '@chakra-ui/react'
import { useComplexQuery } from '@sequencehq/api'
import { Inspector, Skeleton } from '@sequencehq/core-components'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { StripeInspectorItem, StripePaymentWidget } from 'Integrations/index'
import {
  fetchBillingScheduleAggregate,
  getBillingScheduleAggregateKey
} from 'modules/BillingScheduleHome/utils/useBillingScheduleAggregate'
import { useBillingScheduleId } from 'modules/BillingScheduleHome/utils/useBillingScheduleId'

const useBillingScheduleHomeInspector = () => {
  const billingScheduleId = useBillingScheduleId()
  const billingScheduleAggregateQuery = useComplexQuery({
    queryFn: () => fetchBillingScheduleAggregate(billingScheduleId),
    queryKey: getBillingScheduleAggregateKey(billingScheduleId),
    staleTime: 60000,
    select: data => {
      return {
        stripe: {
          acceptPaymentsViaStripe:
            data?.billingScheduleSettings?.paymentProvider === 'STRIPE'
        }
      }
    }
  })

  return {
    isLoading: billingScheduleAggregateQuery.isPending,
    stripe: billingScheduleAggregateQuery.data?.stripe ?? {
      acceptPaymentsViaStripe: false
    }
  }
}

const InspectorSkeleton = () => {
  return (
    <Box
      gridArea="inspector"
      borderLeft={`1px solid ${GreyGrey30}`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="8px"
      gap="2px"
    >
      <Skeleton height="32px" width="32px" />
      <Skeleton height="32px" width="32px" />
      <Skeleton height="32px" width="32px" />
    </Box>
  )
}

export const BillingScheduleHomeInspector = () => {
  const hook = useBillingScheduleHomeInspector()

  if (hook.isLoading) {
    return <InspectorSkeleton />
  }

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <StripeInspectorItem alwaysShow>
          <StripePaymentWidget
            fieldData={{
              acceptPaymentsViaStripe: {
                value: hook.stripe.acceptPaymentsViaStripe,
                disabled: true
              }
            }}
            onChange={() => null}
            disabled
          />
        </StripeInspectorItem>
      </Inspector.ItemGroup>
    </Inspector>
  )
}
