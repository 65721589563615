import { Box, Flex, Text } from '@chakra-ui/react'
import {
  MenuItemBuilder,
  Modal,
  ModalContextProvider
} from '@sequencehq/core-components'
import { IntegrationService } from '@sequencehq/core-models'
import { KebabMenuItem } from '@sequencehq/core-components'
import { RedRed60 } from '@sequencehq/design-tokens'
import { openOverlay } from 'features/overlay'
import { AppDispatch } from 'features/store'
import { integrationName } from 'lib/integrations/integrationName'
import { getLogo } from 'lib/integrations/logos'
import { NavigateFunction } from 'react-router-dom'
import { Customer } from 'components/Customers/types.ts'
import {
  customCustomerLinkModals,
  LinkCustomerModal,
  UnlinkCustomerModal
} from 'Integrations/index'
import { useQuickBooksCustomerFields } from 'Integrations/integrationsConfig/quickBooks/InspectorItem/useQuickBooksCustomerFields'

export const useDetailBuilder: MenuItemBuilder<Omit<Customer, 'contacts'>> = (
  customer: Omit<Customer, 'contacts'>
) => {
  return {
    status: 'LIVE',
    action: ({ navigate }: { navigate: NavigateFunction }) =>
      navigate(`/customers/${customer.id}`),
    label: 'View customer details'
  }
}

export const useArchiveBuilder: MenuItemBuilder<Omit<Customer, 'contacts'>> = (
  customer: Omit<Customer, 'contacts'>
) => {
  return {
    styles: {
      color: RedRed60
    },
    status: customer.archivedAt ? 'DISABLED' : 'LIVE',
    label: 'Archive customer',
    action: ({ dispatch }: { dispatch: AppDispatch }) =>
      dispatch(
        openOverlay({
          content: 'archiveCustomerModal',
          data: {
            customer
          }
        })
      )
  }
}

export const useLinkBuilder =
  (ctx: { onLink: () => void; onUnlink: () => void }) =>
  (
    integrationService: IntegrationService,
    linkModalProps: Record<string, unknown>
  ): MenuItemBuilder<Omit<Customer, 'contacts'>> => {
    return (customer: Omit<Customer, 'contacts'>) => {
      const alreadyLinked = customer.integrationIds.some(
        service => service.service === integrationService
      )
      const LinkModalComponent =
        customCustomerLinkModals[integrationService] ?? LinkCustomerModal

      if (alreadyLinked) {
        return {
          status: 'LIVE',
          action: () => {},
          label: (
            <ModalContextProvider>
              <Modal.Trigger>
                <KebabMenuItem>
                  <Flex
                    data-testid={`customers.${customer.id}.unlinkFrom${integrationService}`}
                    flexDirection="row"
                    align="center"
                  >
                    <Text>{`Unlink from ${integrationName(
                      integrationService
                    )}`}</Text>
                    <Box width="6px"></Box>
                    {getLogo(integrationService, 18)}
                  </Flex>
                </KebabMenuItem>
              </Modal.Trigger>
              <UnlinkCustomerModal
                customerId={customer.id}
                service={integrationService}
                onUnlink={ctx.onUnlink}
              />
            </ModalContextProvider>
          )
        }
      }
      return {
        status: 'LIVE',
        action: () => {},
        label: (
          <ModalContextProvider>
            <Modal.Trigger>
              <KebabMenuItem>
                <Flex
                  flexDirection="row"
                  align="center"
                  data-testid={`customers.${customer.id}.linkTo${integrationService}`}
                >
                  <Text>{`Link to ${integrationName(integrationService)}`}</Text>
                  <Box width="6px"></Box>
                  {getLogo(integrationService, 18)}
                </Flex>
              </KebabMenuItem>
            </Modal.Trigger>
            <LinkModalComponent
              customerId={customer.id}
              service={integrationService}
              onLink={ctx.onLink}
              {...linkModalProps}
            />
          </ModalContextProvider>
        )
      }
    }
  }

export const menuItemBuilders =
  (
    ctx: { onLink: () => void; onUnlink: () => void } = {
      onLink: () => null,
      onUnlink: () => null
    }
  ) =>
  (integrations: IntegrationService[]) => {
    return [
      ...integrations.map(integration => {
        // I'm not a fan of this but can't figure out any other way to get up-to-date props through
        const {
          serviceSpecificFields,
          serviceSpecificFormFields,
          serviceSpecificQueries
        } = useQuickBooksCustomerFields({
          skip: integration !== 'QuickBooks_Online'
        })

        const linkModalProps =
          integration === 'QuickBooks_Online'
            ? {
                serviceSpecific: {
                  fields: serviceSpecificFields,
                  formFields: serviceSpecificFormFields,
                  isValid: serviceSpecificQueries.isValid
                }
              }
            : {}

        return useLinkBuilder(ctx)(integration, linkModalProps)
      })
    ]
  }
