import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api/utils'

export type XeroIntegration = {
  enabled: boolean
  defaultLedgerAccount: string | undefined
  creditDefaultLedgerAccount: string | undefined
  ledgerAccounts: {
    id: string
    name: string
    code: string
  }[]
}

export const useXeroIntegration = () => {
  const xeroIntegrationResponse = useQuery(
    dashboard20240730Client.getIntegration,
    'Xero',
    {
      select: result => {
        const xeroResult =
          result as DashboardApi20240730.GetIntegration.XeroIntegration

        return xeroResult
          ? {
              enabled: xeroResult.enabled,
              defaultLedgerAccount:
                xeroResult.ledgerAccounts.defaults.chargeAccount,
              creditDefaultLedgerAccount:
                xeroResult.ledgerAccounts.defaults.creditAccount,
              ledgerAccounts: xeroResult.ledgerAccounts.options
            }
          : undefined
      }
    }
  )

  return {
    loading: xeroIntegrationResponse.isPending,
    hasError: Boolean(xeroIntegrationResponse.error),
    xeroIntegration: xeroIntegrationResponse.data ?? undefined
  }
}
