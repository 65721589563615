import { Box, Flex, Text } from '@chakra-ui/react'
import {
  ArrowUpRightIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/16/solid'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'
import {
  Banner,
  InputSelectControl,
  Tooltip
} from '@sequencehq/core-components'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Regular,
  Lato14Bold,
  RedRed60
} from '@sequencehq/design-tokens'
import { FormFields } from '@sequencehq/utils'
import { Product } from 'common/drawers/PricingEditor/domain'
import { Link } from 'react-router-dom'
import { useLoadAllItemsQuery } from '@sequencehq/api/utils'
import {
  dashboardv99990101Client,
  Dashboardv99990101CommonModels
} from '@sequencehq/api/dist/clients/dashboard/v99990101'

type ProductFormFields = FormFields<Product>

type TaxCategoryFieldProps = {
  'data-testid'?: string
  field: ProductFormFields['taxCategoryId'] & {
    hidden: boolean
    isLoading?: boolean
  }
  formChangeset:
    | Record<keyof ProductFormFields, { oldValue: unknown; newValue: unknown }>
    | Record<string, unknown>
}

const getOptionsFromTaxCategories = (
  taxCategories: Dashboardv99990101CommonModels.TaxCategory.TaxCategory[]
) => {
  const defaultTaxCategory = taxCategories.find(({ isDefault }) => isDefault)

  const taxCategoriesWithDefaultFirst = [
    defaultTaxCategory,
    ...taxCategories.filter(category => !category.isDefault)
  ].filter(Boolean) as typeof taxCategories

  return taxCategoriesWithDefaultFirst.map(category => ({
    value: category.id,
    label: category.name,
    secondaryLabel: category.isDefault ? '(default)' : undefined
  }))
}

export const TaxCategoryField = (props: TaxCategoryFieldProps) => {
  const { data, isLoading } = useLoadAllItemsQuery(
    dashboardv99990101Client.getTaxCategories
  )

  const taxCategories = data?.pages?.flatMap(page => page.items) ?? []

  const taxCategoriesOptions = getOptionsFromTaxCategories(taxCategories)

  const noTaxCategories = !isLoading && taxCategories.length === 0

  return (
    !props.field.hidden && (
      <Box>
        <Box width="100%">
          {/* Note that we're not using the `InputSelectControlField` component here due to `withFormField` not supporting the newest tooltip designs. */}
          {/* When that's updated, we should switch to that instead so we don't have to manage the label and validation error display here */}
          <Flex
            alignItems="center"
            color={GreyGrey70}
            {...Lato14Bold}
            height="16px"
            marginBottom="8px"
            gap="4px"
          >
            Tax category
            <Tooltip label="The tax category determines what tax rate to charge in a customer’s jurisdiction. You can still change this later.">
              <QuestionMarkCircleIcon height="16px" width="16px" />
            </Tooltip>
          </Flex>

          <InputSelectControl
            data-testid={props['data-testid'] ?? 'taxCategoryField'}
            initialValue={props.field.value ?? ''}
            options={taxCategoriesOptions}
            onChange={props.field.onChange}
            disabled={props.field.disabled}
            placeholder={
              noTaxCategories ? 'No tax categories' : 'Select tax category'
            }
          />

          {Boolean(props.field.validationErrors.length) && (
            <Box
              marginTop="8px"
              key={props.field.validationErrors[0].message}
              {...Lato13Regular}
              color={RedRed60}
            >
              {props.field.validationErrors[0].message}
            </Box>
          )}
        </Box>

        {noTaxCategories && (
          <Box mt={4}>
            <Banner
              variant="neutral"
              data-testid="taxCategoryField.noTaxCategoriesBanner"
            >
              <Flex flexDirection="column" width="100%" gap={1}>
                <Text color={GreyGrey90} width="100%">
                  Create a tax category to assign one to this product.
                </Text>
                <Flex width="100%">
                  <Link to="/settings/tax-rates">
                    <Flex alignItems="center" alignContent="center" gap={1}>
                      <Text color={IndigoIndigo50}>
                        Configure tax categories
                      </Text>
                      <ArrowUpRightIcon
                        {...Lato13Regular}
                        style={{
                          display: 'inline',
                          color: IndigoIndigo50
                        }}
                        height="16px"
                        width="16px"
                      />
                    </Flex>
                  </Link>
                </Flex>
              </Flex>
            </Banner>
          </Box>
        )}

        {'taxCategoryId' in props.formChangeset && (
          <Flex
            mt={4}
            borderRadius="8px"
            backgroundColor={GreyGrey20}
            padding="12px 16px"
            gap="8px"
          >
            <InformationCircleIcon
              color={GreyGrey80}
              height="16px"
              width="16px"
            />
            <Text color={GreyGrey80}>
              Recalculate draft invoices to apply the latest changes.
            </Text>
          </Flex>
        )}
      </Box>
    )
  )
}
