import { Flex } from '@chakra-ui/react'
import { DeferredRevenueDashboardTable } from './DeferredRevenueDashboardTable'

export const DeferredRevenueDashboardContent = () => {
  return (
    <Flex flexDirection="column" height="100%">
      <DeferredRevenueDashboardTable />
    </Flex>
  )
}
