import { CreateApiKeyForm } from 'components/CreateApiKey/CreateApiKeyForm'
import { CreateApiKeyResponse } from 'components/CreateApiKey/CreateApiKeyResponse'
import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { QueryParams } from 'lib/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const CreateApiKeyModal = createFormModal<
  QueryParams,
  Record<string, never>
>({
  FormComponent: CreateApiKeyForm,
  useMutation: apiPackageAdapter20240730<
    {
      clientName: string
      accountId: string
      pinApiVersion: boolean
      scopes: Array<string>
    },
    typeof dashboard20240730Client.postOAuthClient
  >('postOAuthClient', params => params),
  modalTitleFromProps: () => 'Create API key',
  successMessage: 'API key created',
  submitTitle: 'Create',
  formId: 'createApiKeyForm',
  successToast: response => (
    <Toast
      title="API key created"
      type="success"
      description={`API key ${response.name} created`}
      isClosable={true}
    ></Toast>
  ),
  //@ts-expect-error - we will swap this out to a different approach later.
  successContent: (response: {
    secret: string
    id: string
    name: string
  }) => (
    <CreateApiKeyResponse
      secret={response.secret}
      clientId={response.id}
      name={response.name}
    ></CreateApiKeyResponse>
  )
})
