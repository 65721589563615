import { StandardLayout } from '@sequencehq/core-components'
import { JournalReportsContent } from './content/JournalReportsContent'
import { JournalReportsHeader } from './JournalReportsHeader'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { RevenueRecognitionDashboard } from '../revenueRecognitionDashboard/RevenueRecognitionDashboard.root'

export const JournalReports = () => {
  const flags = useFlags()

  if (flags?.showRevRecV2) {
    return <RevenueRecognitionDashboard />
  }

  return (
    <StandardLayout
      header={<JournalReportsHeader />}
      content={<JournalReportsContent />}
    />
  )
}
