import { PdfViewerAnnotation } from '@sequencehq/pdf-viewer'

import { ContractPage, FieldMetadata } from 'ContractExtraction/types'

const isPdfViewerAnnotation = (obj: any): obj is PdfViewerAnnotation =>
  obj && 'id' in obj

export const fieldsMetadataToAnnotations = ({
  fieldsMetadata,
  pages
}: {
  fieldsMetadata: FieldMetadata[]
  pages: ContractPage[]
}): PdfViewerAnnotation[] => {
  return fieldsMetadata
    .map(field => {
      /**
       * Assumptions:
       * - `boundingPoly` contains exactly 4 vertices, otherwise it's filtered out
       * - the vertices are ordered top left, top right, bottom right, bottom left
       */
      if (!field.boundingPoly || field.boundingPoly.vertices.length !== 4) {
        return
      }

      const pageData = pages.find(
        page =>
          field.boundingPoly &&
          page.pageNumber === field.boundingPoly.pageNumber
      )

      if (!pageData) {
        return undefined
      }

      const [topLeft, topRight, , bottomLeft] = field.boundingPoly.vertices

      const top = toRelative({
        value: topLeft.y,
        pageDimension: pageData.dimensions.height
      })

      const left = toRelative({
        value: topLeft.x,
        pageDimension: pageData.dimensions.width
      })

      const width = toRelative({
        value: topRight.x - topLeft.x,
        pageDimension: pageData.dimensions.width
      })

      const height = toRelative({
        value: bottomLeft.y - topLeft.y,
        pageDimension: pageData.dimensions.height
      })

      return {
        id: field.fieldPath,
        pageNumber: field.boundingPoly.pageNumber - 1,
        top,
        left,
        width,
        height
      }
    })
    .filter(isPdfViewerAnnotation)
}

const toRelative = ({
  value,
  pageDimension
}: { value: number; pageDimension: number }): string => {
  return `${(value / pageDimension) * 100}%`
}
