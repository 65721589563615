import { useQuery } from '@sequencehq/api'
import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type v1ApiQuote = DashboardApi20240730.GetQuotes.QuotesList

type UseGetQuotes = (
  customerId: string,
  limit: number
) => {
  quotes: v1ApiQuote['items']
  isLoading: boolean
}

export const useGetQuotes: UseGetQuotes = (customerId, limit) => {
  const quotesResponse = useQuery(
    dashboard20240730Client.getQuotes,
    {
      customerId,
      limit
    },
    {
      select: res => {
        if (!res?.items) {
          return
        }
        return res.items
      }
    }
  )

  return {
    quotes: quotesResponse.data ?? [],
    isLoading: quotesResponse.isLoading
  }
}
