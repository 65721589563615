import {
  createFormModal,
  apiPackageAdapter99990101
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { UpdateAndSendCreditNoteStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import FinalizeAndSendCreditNoteForm from 'components/UpdateInvoiceStatus/FinalizeAndSendCreditNoteForm'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { QueryParams } from 'lib/types'

const FinalizeAndSendCreditNoteModal = createFormModal<
  QueryParams,
  UpdateAndSendCreditNoteStatusFormProps
>({
  FormComponent: FinalizeAndSendCreditNoteForm,
  useMutation: apiPackageAdapter99990101<
    UpdateAndSendCreditNoteStatusFormProps,
    typeof dashboardv99990101Client.postFinalizeAndSendCreditNote
  >('postFinalizeAndSendCreditNote', ({ id }) => ({
    id
  })),
  modalTitle: 'Finalize and Send Credit Note',
  successMessage: 'Credit Note sent',
  submitTitle: 'Finalize and Send',
  formId: 'finalizeAndSendCreditNoteForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Credit Note ${
        response.creditNoteNumber ? response.creditNoteNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue',
  errorText: 'Failed to send credit note'
})

export default FinalizeAndSendCreditNoteModal
