import { Address } from '@sequencehq/api'
import { useCurrentContractMetadata } from 'ContractExtraction/index'
import { Customer } from 'Cube/domain/cube.domain.types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import set from 'lodash/set'
import { useMemo } from 'react'

export type ExtractedCustomer = {
  legalName?: string
  billingEmail?: string
  address?: Address
}

export const useExtractedCustomer = (
  customer?: Customer
): ExtractedCustomer => {
  const fieldsMetadata = useCurrentContractMetadata()
  const flags = useFlags()

  return useMemo(() => {
    const extractedFields: ExtractedCustomer = {
      legalName: customer?.legalName,
      address: customer ? { ...customer.address } : undefined
    }

    if (!fieldsMetadata || !flags.useContractIntakePrototype) {
      return extractedFields
    }

    fieldsMetadata.forEach(field => {
      const { fieldPath, updatedValue, status } = field

      if (!fieldPath.startsWith('customer.') || !status.endsWith('UNAPPROVED'))
        return

      const path = fieldPath.replace('customer.', '')

      set(extractedFields, path, updatedValue)
    })

    return extractedFields
  }, [fieldsMetadata, customer, flags.useContractIntakePrototype])
}
