import { XYChartItem } from '@sequencehq/core-components'
import { useMemo } from 'react'
import { ParentSize } from '@visx/responsive'
import { AnimatedLineSeries, XYChart as VisxXYChart } from '@visx/xychart'
import { curveMonotoneX } from '@visx/curve'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import { XYChartTooltip } from '@sequencehq/core-components/src/charts/XYChart/XYChartTooltip'

// TODO: Put into core-components once finalised
export const MinimalLineChart = <T extends XYChartItem>({
  data,
  renderTooltip
}: {
  data: XYChartItem[]
  renderTooltip: (item: XYChartItem) => React.ReactNode
}) => {
  const accessors = useMemo(
    () => ({
      date: (d: XYChartItem) => d.date,
      value: (d: XYChartItem) => d.value
    }),
    []
  )
  const maxY = Math.max(...data.map(d => d.value)) * 1.1 // 10% padding
  const minY = Math.min(...data.map(d => d.value)) * 0.9 // 10% padding

  return (
    <ParentSize>
      {({ width, height }) => {
        return (
          <VisxXYChart
            xScale={{
              type: 'band',
              padding: 0
            }}
            yScale={{
              type: 'linear',
              zero: true,
              domain: data.length > 0 ? [minY, maxY] : undefined
            }}
            height={height}
            width={width}
            margin={{
              top: 0,
              right: 0,
              bottom: 4,
              left: 0
            }}
          >
            <AnimatedLineSeries
              dataKey="value"
              data={data}
              xAccessor={accessors.date}
              yAccessor={accessors.value}
              curve={curveMonotoneX}
              stroke={IndigoIndigo50}
              strokeWidth={2}
            />
            <XYChartTooltip renderTooltip={renderTooltip} />
          </VisxXYChart>
        )
      }}
    </ParentSize>
  )
}
