import { CreateNotificationPolicyForm } from 'components/CreateNotificationPolicy/CreateNotificationPolicyForm'
import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import {
  ModalContextProvider,
  SimpleDialogUI,
  Toast
} from '@sequencehq/core-components'
import CopyInputField from 'components/Form'
import { QueryParams } from 'lib/types'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const CreateNotificationPolicyModal = createFormModal<
  QueryParams,
  Record<string, never>
>({
  FormComponent: CreateNotificationPolicyForm,
  useMutation: apiPackageAdapter20240730<
    DashboardApi20240730.PostNotificationPolicy.Params,
    typeof dashboard20240730Client.postNotificationPolicy
  >('postNotificationPolicy', params => params),
  modalTitleFromProps: () => 'Create notification policy',
  successMessage: 'Notification policy created',
  submitTitle: 'Create',
  formId: 'createNotificationPolicyForm',
  toastDuration: 6000000,
  //@ts-expect-error Deprecated component
  successToast: (response: {
    webhookSecret?: string
    name: string
    channel: 'WEBHOOK' | 'EMAIL'
  }) =>
    response.channel === 'WEBHOOK' ? (
      <ModalContextProvider isOpen>
        <SimpleDialogUI
          title="Policy created"
          cancelButtonText="Close"
          confirmButtonText={null}
        >
          Secret key:
          <CopyInputField value={response.webhookSecret ?? ''} />
        </SimpleDialogUI>
      </ModalContextProvider>
    ) : (
      <Toast
        title="Notification policy created"
        type="success"
        description={`Notification policy ${response.name} created`}
        isClosable={true}
      />
    )
})
