import { Box, InputGroup, Text } from '@chakra-ui/react'
import {
  NumberField,
  PriceField,
  SelectField,
  TextField
} from '@sequencehq/core-components'
import { usePackagedPriceForm } from './usePackagedPriceForm'
import { PricingModelField } from '../common/PricingModelField'
import { GreyGrey90 } from '@sequencehq/design-tokens'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { UsageMetricField } from '../common/UsageMetrics/UsageMetricField'
import { BillingFrequencyField } from '../common/BillingFrequencyField'

export const PackagedPriceForm = () => {
  const { fieldsConfig, currency } = usePackagedPriceForm()
  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingModelField />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="packaged.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <Box paddingBottom="8px">
        <BillingFrequencyField
          data-testid="packaged.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={fieldsConfig.billingFrequency.onChange}
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <UsageMetricField
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          data-testid="packaged.usageMetric"
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
        <InputGroup display="flex" alignItems="flex-start">
          <PriceField
            label="Price per package"
            data-testid="packaged.pricePerPackage"
            validationErrors={fieldsConfig.pricePerPackage.validationErrors}
            value={fieldsConfig.pricePerPackage.value}
            onChange={fieldsConfig.pricePerPackage.onChange}
            isDisabled={fieldsConfig.pricePerPackage.disabled}
            currency={currency}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
          />
          {!fieldsConfig.currency.hidden && (
            <Box mt={6} minHeight="72px">
              <SelectField
                data-testid="packaged.currency"
                value={fieldsConfig.currency.value}
                onChange={newCurrency =>
                  fieldsConfig.currency.onChange(newCurrency as Currency)
                }
                options={fieldsConfig.currency.options}
                validationErrors={fieldsConfig.currency.validationErrors}
                isDisabled={fieldsConfig.currency.disabled}
                placeholder="Select currency"
                width="150px"
                inputStyle={{
                  borderLeft: 'none',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
              />
            </Box>
          )}
        </InputGroup>
        <NumberField
          label="Package size"
          data-testid="packaged.packageSize"
          validationErrors={fieldsConfig.packageSize.validationErrors}
          precision={0}
          value={fieldsConfig.packageSize.value}
          onChange={fieldsConfig.packageSize.onChange}
          isDisabled={fieldsConfig.packageSize.disabled}
        />
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
