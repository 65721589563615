import { StandardLayout } from '@sequencehq/core-components'
import { DeferredRevenueDashboardHeader } from './DeferredRevenueDashboardHeader'
import { DeferredRevenueDashboardContent } from './content/DeferredRevenueDashboardContent'

export const DeferredRevenueDashboard = () => {
  return (
    <StandardLayout
      header={<DeferredRevenueDashboardHeader />}
      content={<DeferredRevenueDashboardContent />}
    />
  )
}
