import {
  Button,
  Flex,
  ModalBody,
  ModalContent,
  OrderedList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  ListItem,
  Box,
  Image,
  useToast,
  Link,
  Alert
} from '@chakra-ui/react'
import { useDispatch } from 'features/store'
import { FC, useState } from 'react'
import { borderColor } from '@sequencehq/core-theme'
import { Form } from 'react-final-form'
import * as Sentry from '@sentry/react'
import {
  composeValidators,
  required,
  isGoogleDocsUrl
} from '@sequencehq/validation'

import { closeOverlay } from 'features/overlay'
import { Toast } from '@sequencehq/core-components'
import { Accordion } from 'components/Accordion'
import { TextInputField } from '@sequencehq/forms'
import { isProductionEnv } from 'lib/environment/environment'
import { noReturn } from 'lib/noReturn'
import CopyInputField from 'components/Form'
import { InsightsTemplateType } from 'components/InsightsV2/widgets/InsightsTemplates/CtaCard'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type AvailableExportData = {
  id: string
  title: string
  description: string
}

interface CreateInsightExportTemplateModalProps {
  template: InsightsTemplateType
  title: string
  description: string
  copyLink: string
  exampleLink?: string
  image: string
  imageAlt: string
  icon: string
  availableExportData: AvailableExportData[]
}

const CreateInsightExportTemplateModal: FC<
  CreateInsightExportTemplateModalProps
> = ({
  template,
  title,
  description,
  copyLink,
  exampleLink,
  imageAlt,
  availableExportData,
  icon
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const toast = useToast()

  const prequelSyncUrl = 'datasync-bobldvms@prql-prod.iam.gserviceaccount.com'

  const connectToGsheet = (url: string) => {
    setLoading(true)

    dashboard20240730Client
      .postCreateGoogleSheetsInsightsExport({
        template: template,
        sheetUrl: url
      })
      .then(() => {
        setLoading(false)
        dispatch(closeOverlay())
        toast({
          position: 'bottom',
          isClosable: true,
          render: () => (
            <Toast
              type="success"
              title={`Template connected successfully`}
              description="Your first data transfer will begin soon. After this, data syncs automatically on a daily basis at midnight."
              isClosable={true}
            />
          )
        })
      })
      .catch(err => {
        Sentry.captureException(err)
        toast({
          position: 'bottom',
          isClosable: true,
          render: () => (
            <Toast
              type="error"
              title={`Template not found`}
              description="Please check you shared the sheet with datasync-bobldvms@prql-prod.iam.gserviceaccount.com. If the error still persists, please reach out to us on "
              isClosable={true}
              linkText="here"
              url="https://docs.google.com/forms/d/e/1FAIpQLSegq7s7XDJcxVBJhRHHZS3_jtsk6csTHpUD7mIjPr54sveiDQ/viewform?usp=sharing"
            />
          )
        })
        setLoading(false)
        dispatch(closeOverlay())
      })
  }

  const instructions = [
    <Text key="1" textStyle="orderedList" ml="8px">
      Click on "
      <Text as="span" textStyle="bold">
        Use template
      </Text>
      " to copy the template to your Google Drive.
    </Text>,
    <>
      <Text key="2" textStyle="orderedList" ml="8px">
        Click on "
        <Text as="span" textStyle="bold">
          Share
        </Text>
        " and enter the following email address:
      </Text>
      <CopyInputField value={prequelSyncUrl} />
    </>,
    <Text key="3" textStyle="orderedList" ml="8px">
      After sharing the file, copy the URL of the template and paste it into the
      field above.
    </Text>,
    <Text key="4" textStyle="orderedList" ml="8px">
      Click "
      <Text as="span" textStyle="bold">
        Connect
      </Text>
      " to connect your data.
    </Text>
  ]

  return (
    <ModalContent borderRadius="8px">
      <ModalBody p={0}>
        {!isProductionEnv() && (
          <Alert
            status="warning"
            borderTopLeftRadius="8px"
            borderTopRightRadius="8px"
          >
            <Text textStyle="bold" mr="8px">
              Warning:
            </Text>
            <Text textStyle="tableTextRegular">
              This feature is only available in a Production environment.
            </Text>
          </Alert>
        )}

        <Flex flexDirection="row">
          <Flex
            flexDirection="column"
            minWidth="483px"
            maxWidth="483px"
            minHeight="524px"
            maxHeight="524px"
            py="8px"
          >
            <Tabs variant="modal-tabs">
              <TabList
                borderBottomStyle="solid"
                borderBottomColor={borderColor}
                borderBottomWidth="1px"
              >
                <Tab>Overview</Tab>
                <Tab>Available data</Tab>
              </TabList>

              <TabPanels>
                <TabPanel p="0px">
                  <Box height="24px" />
                  <Flex flexDirection="column" padding="16px 40px">
                    <Image
                      src={icon}
                      alt={imageAlt}
                      height="103px"
                      width="103px"
                    />
                    <Box height="24px" />
                    <Text textStyle="toastTitle">{title}</Text>
                    <Box height="8px" />
                    <Text textStyle="tableTextRegular">{description}</Text>
                    <Box height="24px" />
                    <Flex flexDirection="row">
                      {exampleLink && (
                        <Button
                          as={Link}
                          href={exampleLink}
                          variant="secondary"
                          isExternal={true}
                          _hover={{ textDecoration: 'none' }}
                          marginRight="8px"
                        >
                          View example
                        </Button>
                      )}
                      {copyLink && (
                        <Button
                          as={Link}
                          href={copyLink}
                          variant="primary"
                          isExternal={true}
                          _hover={{ textDecoration: 'none' }}
                        >
                          Use template
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel p="0px">
                  <Box height="24px" />
                  <Flex flexDirection="column" padding="0px 40px">
                    <Text>
                      To connect your Sequence data with the template, we use
                      the following set of data models/assets:
                    </Text>
                    <Box height="24px" />
                    <Accordion availableExportData={availableExportData} />
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
          <Flex
            minWidth="417px"
            borderLeftStyle="solid"
            borderLeftColor={borderColor}
            borderLeftWidth="1px"
            px="40px"
            py="32px"
            backgroundColor="gray.0"
            borderTopRightRadius="8px"
            borderBottomRightRadius="8px"
          >
            <Flex flexDirection="column">
              <Text textStyle="accordionButtonTitle" mb="16px">
                Connect template
              </Text>

              <Form
                onSubmit={values => {
                  if (isProductionEnv()) {
                    connectToGsheet(values.url as string)
                  } else {
                    dispatch(closeOverlay())
                    toast({
                      position: 'bottom',
                      isClosable: true,
                      render: () => (
                        <Toast
                          type="error"
                          title={`Google sheets export not available`}
                          description="Gsheets export only available in a Production environment"
                          isClosable={true}
                        />
                      )
                    })
                  }
                }}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={noReturn(handleSubmit)}>
                      <TextInputField
                        fieldName="url"
                        placeholder="Enter template URL"
                        validate={composeValidators(required, isGoogleDocsUrl)}
                      />
                      <Box height="16px" />
                      <Button
                        type="submit"
                        variant="primary"
                        isLoading={loading}
                        width="100%"
                        isDisabled={!isProductionEnv()}
                      >
                        Connect
                      </Button>
                    </form>
                  )
                }}
              />
              <Box height="32px" />
              <Text textStyle="bold" mb="12px">
                How to connect your template:
              </Text>
              <OrderedList
                flexDirection="column"
                spacing="4px"
                variant="template-modal"
              >
                {instructions.map((instruction, index) => (
                  <ListItem flexDirection="row" key={index}>
                    {instruction}
                  </ListItem>
                ))}
              </OrderedList>
            </Flex>
          </Flex>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

export default CreateInsightExportTemplateModal
