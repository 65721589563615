import { Dashboardv99990101Api } from '@sequencehq/api/dashboard/v99990101'
import { enforceMinimumPrecision } from '@sequencehq/utils'
import { Validator } from '@sequencehq/validation'
import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'
import reduce from 'lodash/fp/reduce'
import { FormData } from './manualJournalModal.types'
import { floatSafeAdd } from 'RevenueRecognition/view/utils/floatSafeAdd'

export const safeParseFloat = (value: string): number => {
  const parsedValue = parseFloat(value)
  if (Number.isNaN(parsedValue)) {
    return 0
  }
  return parsedValue
}

export const getAmountForAccount =
  (accountType: Dashboardv99990101Api.GetJournal.LedgerAccountName) =>
  (entries: Dashboardv99990101Api.GetJournal.GetJournalResponse['entries']) => {
    const entry = entries.find(e => e.account.name === accountType)
    return {
      debit:
        entry?.direction === 'DEBIT' ? entry?.amount.toString() ?? '0' : '0',
      credit:
        entry?.direction === 'CREDIT' ? entry?.amount.toString() ?? '0' : '0'
    }
  }

export const formatAccountAmountForForm = (account?: {
  debit: string
  credit: string
}): {
  debit: string
  credit: string
  include: boolean
} => {
  if (!account) {
    return {
      debit: '0',
      credit: '0',
      include: false
    }
  }

  if (
    safeParseFloat(account.debit) === 0 &&
    safeParseFloat(account.credit) === 0
  ) {
    return {
      debit: '0.00',
      credit: '0.00',
      include: false
    }
  }

  return {
    debit: enforceMinimumPrecision(2)(account.debit),
    credit: enforceMinimumPrecision(2)(account.credit),
    include: true
  }
}

export const formatAccountDataForApi =
  (accountType: Dashboardv99990101Api.GetJournal.LedgerAccountName) =>
  (accountAmounts: { debit: string; credit: string; include: boolean }) => {
    if (!accountAmounts.include) {
      return []
    }

    return [
      {
        account: accountType,
        direction: 'DEBIT' as const,
        amount: enforceMinimumPrecision(2)(accountAmounts.debit)
      },
      {
        account: accountType,
        direction: 'CREDIT' as const,
        amount: enforceMinimumPrecision(2)(accountAmounts.credit)
      }
    ].filter(entry => safeParseFloat(entry.amount) > 0)
  }

export const calculateTotals = (formData: FormData) => ({
  debit: compose(
    reduce((acc, val: number) => floatSafeAdd(acc, val), 0),
    map(safeParseFloat)
  )([
    formData.accountAmounts.billed.include
      ? formData.accountAmounts.billed.debit
      : '0',
    formData.accountAmounts.unbilled.include
      ? formData.accountAmounts.unbilled.debit
      : '0',
    formData.accountAmounts.deferred.include
      ? formData.accountAmounts.deferred.debit
      : '0',
    formData.accountAmounts.recognized.include
      ? formData.accountAmounts.recognized.debit
      : '0'
  ]),
  credit: compose(
    reduce((acc, val: number) => floatSafeAdd(acc, val), 0),
    map(safeParseFloat)
  )([
    formData.accountAmounts.billed.include
      ? formData.accountAmounts.billed.credit
      : '0',
    formData.accountAmounts.unbilled.include
      ? formData.accountAmounts.unbilled.credit
      : '0',
    formData.accountAmounts.deferred.include
      ? formData.accountAmounts.deferred.credit
      : '0',
    formData.accountAmounts.recognized.include
      ? formData.accountAmounts.recognized.credit
      : '0'
  ])
})

/** Validation Fns */
/**
 * The typing of validators in general could be much better, but at some point
 * (soon, hopefully) we should introduce a 'new form, who dis' which will do
 * all of this well!
 * @param accountName
 * @returns
 */
export const onlyOneDebitOrCredit =
  (accountName: 'billed' | 'unbilled' | 'deferred' | 'recognized'): Validator =>
  (_, allValues) => {
    if (!allValues) {
      return
    }

    if (
      safeParseFloat(
        (allValues as FormData).accountAmounts?.[accountName].debit
      ) === 0 &&
      safeParseFloat(
        (allValues as FormData).accountAmounts?.[accountName].credit
      ) === 0
    ) {
      return 'At least one debit or credit must be entered'
    }

    return
  }

export const totalsMatch = (formData: FormData) => {
  const totals = calculateTotals(formData)
  if (totals.credit !== totals.debit) {
    return ['Debit and credit totals must match']
  }

  return []
}

export const atLeastTwoJournalsSelected = (formData: FormData) => {
  const totalSelectedJournals = Object.values(formData.accountAmounts).filter(
    acc => acc.include
  ).length
  if (totalSelectedJournals < 2) {
    return ['At least two journals must be selected']
  }
  return []
}
