import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Wordmark } from '@sequencehq/core-components'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { FormStage } from './FormStage'
import { match } from 'ts-pattern'
import { CheckEmailStage } from './CheckEmailStage'
import { EmailLoginForm } from './EmailLoginForm'

export type LoginPageStage = 'FORM' | 'CHECK_EMAIL'

export const LoginPage = () => {
  const [searchParams] = useSearchParams()
  const returnTo = searchParams.get('return_to') ?? '/home'
  window.localStorage.setItem('return_to', returnTo)

  const [stage, setStage] = useState<LoginPageStage>('FORM')
  const [email, setEmail] = useState<string>('')

  return (
    <PageContainer>
      <Wordmark width="126" height="26.74" />

      {match(stage)
        .with('FORM', () => (
          <FormStage>
            <EmailLoginForm
              email={email}
              setEmail={setEmail}
              setStage={setStage}
            />
          </FormStage>
        ))
        .with('CHECK_EMAIL', () => <CheckEmailStage email={email} />)
        .exhaustive()}
    </PageContainer>
  )
}

export default LoginPage
