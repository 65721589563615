import { UsageMetricModel } from '@sequencehq/core-models'
import {
  FC,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer
} from 'react'
import { match } from 'ts-pattern'
import * as Sentry from '@sentry/react'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type UsageMetricState = {
  usageMetric: UsageMetricModel | undefined
  usageMetricId: string | undefined
}

type UsageMetricContextType = {
  state: UsageMetricState
  dispatch: (action: UsageMetricAction) => void
}

const UsageMetricContext = createContext<UsageMetricContextType | undefined>(
  undefined
)

type UsageMetricAction =
  | {
      type: 'setUsageMetricId'
      usageMetricId: string | undefined
    }
  | {
      type: 'setUsageMetric'
      usageMetric: UsageMetricModel | undefined
    }

const usageMetricReducer = (
  state: UsageMetricState,
  action: UsageMetricAction
) => {
  return match(action)
    .with({ type: 'setUsageMetricId' }, ({ usageMetricId }) => ({
      ...state,
      usageMetricId,
      usageMetric:
        state.usageMetricId === usageMetricId ? state.usageMetric : undefined
    }))
    .with({ type: 'setUsageMetric' }, ({ usageMetric }) => {
      return usageMetric?.id === state.usageMetricId
        ? {
            ...state,
            usageMetric
          }
        : state
    })
    .exhaustive()
}

type UsageMetricProviderProps = {
  children: ReactNode
}

export const UsageMetricProvider: FC<UsageMetricProviderProps> = ({
  children
}) => {
  const [state, dispatch] = useReducer<
    Reducer<UsageMetricState, UsageMetricAction>
  >(usageMetricReducer, {
    usageMetric: undefined,
    usageMetricId: undefined
  })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  useEffect(() => {
    if (!state.usageMetricId) {
      return dispatch({ type: 'setUsageMetric', usageMetric: undefined })
    }

    if (state.usageMetric?.id === state.usageMetricId) {
      return
    }
    dashboard20240730Client
      .getUsageMetric({ id: state.usageMetricId })
      .then(res => {
        if (res.data) {
          dispatch({ type: 'setUsageMetric', usageMetric: res.data })
        }
      })
  }, [state.usageMetricId, state.usageMetric?.id])

  return (
    <UsageMetricContext.Provider value={value}>
      {children}
    </UsageMetricContext.Provider>
  )
}

export const useUsageMetric = () => {
  const context = useContext(UsageMetricContext)
  if (context === undefined) {
    throw new Error('useUsageMetric must be used within a UsageMetricProvider')
  }
  return context
}
