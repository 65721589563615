import { Box, Flex, Spinner } from '@chakra-ui/react'
import {
  GreyGrey30,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  YellowYellow10,
  YellowYellow60,
  YellowYellow80
} from '@sequencehq/design-tokens'

export const SelectAllInvoices = (props: {
  totalInvoices: number
  visibleTableWidth?: number
  totalSelectedInvoices: number
  showClearSelection: boolean
  onClearSelection: () => void
  onSelectAll: () => void
  loading: boolean
  error: boolean
}) => {
  return (
    <Flex
      data-testid="invoices.selectAllPrompt"
      {...Lato13Regular}
      backgroundColor={YellowYellow10}
      color={YellowYellow80}
      position="relative"
      borderBottom={`1px solid ${GreyGrey30}`}
    >
      <Flex
        padding="0 10px"
        height="36px"
        width={
          props.visibleTableWidth ? `${props.visibleTableWidth}px` : '100%'
        }
        position="relative"
        transition={`width 0.25s`}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          width="100%"
          gap="4px"
        >
          All {props.totalSelectedInvoices} invoices on this page are selected.{' '}
          {props.showClearSelection ? (
            <Box
              {...Lato13Bold}
              color={IndigoIndigo50}
              onClick={() => props.onClearSelection()}
              cursor="pointer"
              data-testid="invoices.selectAllPrompt.clear"
            >
              Clear selection
            </Box>
          ) : (
            <Box
              {...Lato13Bold}
              opacity={props.loading ? 0.7 : 1}
              color={IndigoIndigo50}
              onClick={() => !props.loading && props.onSelectAll()}
              cursor={props.loading ? 'not-allowed' : 'pointer'}
              marginRight="2px"
              transition={`opacity 0.25s`}
              data-testid="invoices.selectAllPrompt.selectAll"
            >
              Select all {props.totalInvoices} invoices
            </Box>
          )}
          {props.loading && (
            <Spinner
              data-testid="invoices.selectAllPrompt.loading"
              height="14px"
              width="14px"
              color={YellowYellow60}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
