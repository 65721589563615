import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  Lato14Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Card, InputSelectControlField } from '@sequencehq/core-components'
import { QuickBooksTaxCodeMapping } from 'Integrations/integrationsConfig/quickBooks/quickbooks.integration.config'
import { Dashboardv99990101CommonModels } from '@sequencehq/api/dist/clients/dashboard/v99990101'

interface Props {
  taxCodeDefaultsFieldsConfig: {
    taxExemptTaxCode: {
      value: string
      onChange: (value: string) => void
    }
    taxableTaxCode: {
      value: string
      onChange: (value: string) => void
    }
    reverseChargedTaxCode: {
      value: string
      onChange: (value: string) => void
    }
  }
  taxCodeMappingFieldsConfig: {
    [key: string]: {
      value: QuickBooksTaxCodeMapping
      onChange: (value: QuickBooksTaxCodeMapping) => void
    }
  }
  mappings: Record<string, QuickBooksTaxCodeMapping>
  quickbooksTaxCodes: Dashboardv99990101CommonModels.QuickbooksTaxCode.QuickbooksTaxCode[]
}

export const TaxCodesCard = ({
  taxCodeDefaultsFieldsConfig,
  taxCodeMappingFieldsConfig,
  mappings,
  quickbooksTaxCodes
}: Props) => {
  const quickbooksTaxCodesOptions = quickbooksTaxCodes.map(taxCode => ({
    value: taxCode.id,
    label: taxCode.name ?? ''
  }))

  return (
    <Card>
      <Flex
        data-testid="quickbooksIntegration.taxCodes"
        gap={4}
        flexDirection="column"
      >
        <Flex gap={1} flexDirection="column">
          <Text {...Lato14Bold}>Tax codes</Text>
          <Text {...Lato13Regular} color={GreyGrey70}>
            Map Sequence invoice line items to QuickBooks tax codes.
          </Text>
        </Flex>
        <Card>
          <Flex gap={4} flexDirection="column">
            <Flex gap={1} flexDirection="column">
              <Text {...Lato14Bold}>
                Map product tax categories to QuickBooks tax codes
              </Text>
            </Flex>
            <Flex gap={2} flexDirection="column">
              {Object.entries(mappings).map(([taxCategoryId, mapping]) => (
                <Flex
                  key={taxCategoryId}
                  backgroundColor={GreyGrey10}
                  borderRadius={6}
                  justifyContent="space-between"
                  alignContent="center"
                  alignItems="center"
                  width="100%"
                  py={1}
                  px={1}
                  gap={4}
                >
                  <Text isTruncated {...Lato13Regular} ml={1}>
                    {mapping.taxCategoryName}
                  </Text>
                  <InputSelectControlField
                    width="180px"
                    data-testid={`quickbooksIntegration.taxCodes.${taxCategoryId}`}
                    placeholder="Select a tax code..."
                    options={quickbooksTaxCodesOptions}
                    initialValue={
                      taxCodeMappingFieldsConfig[
                        `taxCodeMappings.${taxCategoryId}`
                      ].value?.taxCodeId
                    }
                    onChange={(value: string) =>
                      taxCodeMappingFieldsConfig[
                        `taxCodeMappings.${taxCategoryId}`
                      ].onChange({
                        ...mapping,
                        taxCodeId: value
                      })
                    }
                    styles={{
                      wrapper: {
                        marginBottom: 0
                      }
                    }}
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Card>
        <Text {...Lato13Regular} color={GreyGrey70}>
          For exempt and reverse charged customers, the below tax codes apply.
        </Text>
        <Flex
          backgroundColor={GreyGrey10}
          borderRadius={6}
          justifyContent="space-between"
          width="100%"
          p={4}
          // Input select control fields have a bottom 16px padding to account for possible validation errors
          // this pb={0} mitigates that in the layout.
          pb={0}
          gap={4}
        >
          <InputSelectControlField
            flip
            label="Tax exempt customers"
            data-testid="quickbooksIntegration.taxCodes.default.taxExempt"
            placeholder="Select tax code"
            options={quickbooksTaxCodesOptions}
            initialValue={taxCodeDefaultsFieldsConfig.taxExemptTaxCode.value}
            onChange={taxCodeDefaultsFieldsConfig.taxExemptTaxCode.onChange}
          />
          <InputSelectControlField
            flip
            label="Reverse charged customers"
            data-testid="quickbooksIntegration.taxCodes.default.reverseCharged"
            placeholder="Select tax code"
            options={quickbooksTaxCodesOptions}
            initialValue={
              taxCodeDefaultsFieldsConfig.reverseChargedTaxCode.value
            }
            onChange={
              taxCodeDefaultsFieldsConfig.reverseChargedTaxCode.onChange
            }
          />
          <InputSelectControlField
            flip
            label="Fallback tax code"
            data-testid="quickbooksIntegration.taxCodes.default.taxable"
            placeholder="Select tax code"
            options={quickbooksTaxCodesOptions}
            initialValue={taxCodeDefaultsFieldsConfig.taxableTaxCode.value}
            onChange={taxCodeDefaultsFieldsConfig.taxableTaxCode.onChange}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
