import { useQuery } from '@sequencehq/api'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { contractIdAtom } from 'ContractExtraction/atoms'
import { useAtom } from 'jotai'

export const useContract = (contractId: string | undefined) => {
  return useQuery(
    dashboardv99990101Client.getContract,
    { id: contractId ?? '' },
    {
      enabled: !!contractId,
      select: data => {
        return {
          fieldsMetadata: data?.fieldsMetadata ?? [],
          assetId: data?.assetId,
          pages: data?.pages ?? []
        }
      }
    }
  )
}

export const useCurrentContract = () => {
  const [contractId] = useAtom(contractIdAtom)
  return useContract(contractId)
}
