import { Box, Flex, Grid, Link, Text } from '@chakra-ui/react'
import { useOutstandingInvoices } from 'components/InsightsV2/widgets/OutstandingInvoices/useOutstandingInvoices'
import {
  GreyGrey30,
  GreyGrey40,
  GreyGrey50,
  GreyGrey60,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato14Bold,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { InfoPopover } from '@sequencehq/core-components'
import { InformationCircleIcon, WalletIcon } from '@heroicons/react/16/solid'
import { FilterSelectInput } from 'components/InsightsV2/common/FilterSelectInput/FilterSelectInput'
import { OutstandingInvoicesGraph } from 'components/InsightsV2/widgets/OutstandingInvoices/OutstandingInvoicesGraph'
import { OutstandingInvoicesItem } from 'components/InsightsV2/widgets/OutstandingInvoices/OutstandingInvoicesItem'
import { EmptyState } from 'components/InsightsV2/common/EmptyState'
import { WidgetLoading } from 'components/InsightsV2/common/WidgetLoading/WidgetLoading'
import { useMemo } from 'react'
import { sub } from '@sequencehq/utils/dates'

const OutstandingInvoices = () => {
  const { data, isLoading, currencyField, valueFormat } =
    useOutstandingInvoices()

  const links = useMemo(() => {
    const basePath = `/invoices?sortBy=accountingDate_desc&invoicePaymentStatus=UNPAID&invoiceStatus=SENT&invoiceCurrency=${currencyField.value}`

    const NOW = new Date()

    return {
      overdueFrom61days: `${basePath}&dueBefore=${sub(NOW, { days: 61 })}`,
      overdueFrom31to60days: `${basePath}&dueBefore=${sub(NOW, {
        days: 31
      })}&dueAfter=${sub(NOW, { days: 60 })}`,
      overdueFrom0to30days: `${basePath}&dueBefore=${NOW}&dueAfter=${sub(NOW, {
        days: 30
      })}`,
      outstandingNow: `${basePath}&dueAfter=${NOW}`
    }
  }, [currencyField.value])

  if (isLoading || !data) {
    return <WidgetLoading />
  }

  return (
    <Box
      width="390px"
      height="291px"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      border={`1px solid ${GreyGrey40}`}
      borderRadius="lg"
      p={4}
    >
      <Flex gap={2}>
        <Flex flexGrow="1" flexDirection="column" gap={2}>
          <Text as={Flex} {...Lato13Bold} color={GreyGrey60} gap={1}>
            Outstanding Invoices
            <InfoTooltip />
          </Text>
          <Text {...Lato20Bold} color={GreyGrey90}>
            {valueFormat(data.totalAmount)}
          </Text>
        </Flex>
        <Flex width="120px" alignSelf="baseline">
          <FilterSelectInput
            placeholder="Currency"
            value={currencyField.value}
            options={currencyField.options}
            onChange={currencyField.onChange}
          />
        </Flex>
      </Flex>
      {data.totalAmount === 0 ? (
        <Box mt={2} h="full">
          <EmptyState
            label="No outstanding invoices"
            Icon={<WalletIcon width="16px" height="16px" />}
          />
        </Box>
      ) : (
        <>
          <OutstandingInvoicesGraph data={data} />
          <Box mt="12px">
            <Grid
              templateColumns="repeat(2, 1fr)"
              pb={2}
              borderBottom={`1px solid ${GreyGrey30}`}
            >
              <Text {...Lato13Bold} color={GreyGrey70}>
                Invoice Age
              </Text>
              <Grid templateColumns="repeat(2, 1fr)">
                <Text {...Lato13Bold} color={GreyGrey70} pl={6}>
                  Invoices
                </Text>
                <Text
                  {...Lato13Bold}
                  color={GreyGrey70}
                  textAlign="right"
                  fontWeight="600"
                >
                  Amount
                </Text>
              </Grid>
            </Grid>
            <OutstandingInvoicesItem
              to={links.overdueFrom61days}
              firstColumnText="More than 60 days"
              secondColumnText={`${data.overdueFrom61days.numberOfInvoices}x`}
              thirdColumnText={valueFormat(data.overdueFrom61days.amount)}
              indicatorIconColor={IndigoIndigo50}
            />
            <OutstandingInvoicesItem
              to={links.overdueFrom31to60days}
              firstColumnText="30-60 days overdue"
              secondColumnText={`${data.overdueFrom31to60days.numberOfInvoices}x`}
              thirdColumnText={valueFormat(data.overdueFrom31to60days.amount)}
              indicatorIconColor="#6B73FF"
            />
            <OutstandingInvoicesItem
              to={links.overdueFrom0to30days}
              firstColumnText="0-30 days overdue"
              secondColumnText={`${data.overdueFrom0to30days.numberOfInvoices}x`}
              thirdColumnText={valueFormat(data.overdueFrom0to30days.amount)}
              indicatorIconColor="#9FA6FF"
            />
            <OutstandingInvoicesItem
              to={links.outstandingNow}
              firstColumnText="Current (not overdue)"
              secondColumnText={`${data.outstandingNow.numberOfInvoices}x`}
              thirdColumnText={valueFormat(data.outstandingNow.amount)}
              indicatorIconColor={GreyGrey40}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

const InfoTooltip = () => {
  return (
    <InfoPopover
      variant="info"
      minimal
      padding="0"
      placement="top"
      body={
        <Box width={300}>
          The Outstanding Invoices report categorizes your receivables by the
          amount of time an invoice has been outstanding, giving you an view of
          your revenue collections health.{' '}
          <Link
            href="https://docs.sequencehq.com/invoicing/invoicing-customers#payment-status"
            isExternal
            {...Lato14Bold}
            color="indigo.50"
          >
            Learn more
          </Link>
        </Box>
      }
      icon={<InformationCircleIcon height={16} width={16} color={GreyGrey50} />}
    />
  )
}

export default OutstandingInvoices
