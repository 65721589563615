import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  InputSelectControlField,
  Modal,
  ModalContextProvider,
  SequenceMenuItem,
  SimpleAsyncModalUI
} from '@sequencehq/core-components'
import { CreateCustomerForm } from 'components/Customers/components/drawers/CreateCustomer/CreateCustomerForm'
import { useLoadAllCustomersWithAliases } from 'components/UsageEvents/useLoadAllCustomersWithAliases'
import { useNotifications } from 'lib/hooks/useNotifications'
import uniqBy from 'lodash/fp/uniqBy'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import invariant from 'tiny-invariant'

const useDuplicateSchedule = (props: {
  scheduleId: string
}) => {
  const notifications = useNotifications()
  const duplicationMutation = useMutation(
    dashboard20240730Client.postDuplicateBillingSchedule
  )
  const existingScheduleQuery = useQuery(
    dashboard20240730Client.getBillingSchedule,
    {
      id: props.scheduleId
    }
  )

  const [selectedCustomerId, setSelectedCustomerId] = useState(
    existingScheduleQuery.data?.customerId
  )
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false)
  const [latestCreatedCustomer, setLatestCreatedCustomer] = useState<{
    id: string
    customerName: string
  } | null>(null)

  useEffect(() => {
    if (existingScheduleQuery.data?.customerId) {
      setSelectedCustomerId(existingScheduleQuery.data.customerId)
    }
  }, [existingScheduleQuery.data?.customerId])

  const { customersWithAliases, isLoading: customerOptionsLoading } =
    useLoadAllCustomersWithAliases()

  const duplicateSchedule = async (
    scheduleId: string
  ): Promise<string | null> => {
    notifications.displayNotification('Duplicating schedule...', {
      type: 'info'
    })

    try {
      invariant(selectedCustomerId, 'Schedule data not found')
      const duplicatedSchedule = await duplicationMutation.mutateAsync({
        id: scheduleId,
        customerId: selectedCustomerId
      })

      invariant(duplicatedSchedule, 'Schedule not found')

      notifications.displayNotification('Schedule duplicated', {
        type: 'success'
      })
      return duplicatedSchedule.id
    } catch {
      notifications.displayNotification('Unable to duplicate schedule', {
        type: 'error'
      })
      return null
    }
  }

  /**
   * Fold the latest created customer into the list of customers
   * as an optimistic update, to improve perceived performance.
   */
  const customerOptions = useMemo(() => {
    return (
      uniqBy<{ id: string; customerName: string }>('id')([
        ...(latestCreatedCustomer ? [latestCreatedCustomer] : []),
        ...(customersWithAliases ?? [])
      ])?.map(c => ({
        label: c.customerName,
        value: c.id
      })) ?? []
    )
  }, [customersWithAliases, latestCreatedCustomer])

  const isLoading = Boolean(
    existingScheduleQuery.isPending ||
      customerOptionsLoading ||
      !customerOptions.find(c => c.value === selectedCustomerId)
  )

  return {
    duplicateSchedule,
    customer: {
      options: customerOptions ?? [],
      value: selectedCustomerId,
      onChange: (value: string) => {
        setSelectedCustomerId(value)
      },
      onAddNew: () => {
        setShowCreateCustomerModal(true)
      }
    },
    createCustomerModal: {
      show: showCreateCustomerModal,
      onSuccess: (customer: { id: string; legalName: string }) => {
        setLatestCreatedCustomer({
          id: customer.id,
          customerName: customer.legalName
        })
        setSelectedCustomerId(customer.id)
        setShowCreateCustomerModal(false)
      },
      onClose: () => {
        setShowCreateCustomerModal(false)
      }
    },
    selectedCustomerId: existingScheduleQuery.data?.customerId,
    isLoading: existingScheduleQuery.isPending || customerOptionsLoading,
    submitDisabled: isLoading || !selectedCustomerId
  }
}

const DuplicateScheduleModalUI = (props: {
  scheduleId: string
  onSuccess?: (newScheduleId: string) => void
}) => {
  const {
    duplicateSchedule,
    customer,
    createCustomerModal,
    submitDisabled,
    isLoading
  } = useDuplicateSchedule({
    scheduleId: props.scheduleId
  })

  return (
    <>
      <SimpleAsyncModalUI
        data-testid="duplicateScheduleModal"
        title="Duplicate schedule"
        cancelButtonText="Cancel"
        submitButtonText="Duplicate schedule"
        submittingButtonText="Duplicating schedule..."
        submitDisabled={submitDisabled}
        onSubmit={async () => {
          const newScheduleId = await duplicateSchedule(props.scheduleId)
          if (newScheduleId) {
            props.onSuccess?.(newScheduleId)
            return true
          }
          return false
        }}
        contentProps={{
          minHeight: '112px'
        }}
      >
        <InputSelectControlField
          data-testid="duplicateSchedule.customer.select"
          placeholder="Find or add a new customer"
          label="Customer"
          initialValue={customer.value}
          options={customer.options}
          onChange={customer.onChange}
          onCreate={customer.onAddNew}
          createLabel="Create new"
          disabled={isLoading}
          matchWidth
          styles={{ wrapper: { marginBottom: 0 } }}
        />
      </SimpleAsyncModalUI>
      {createCustomerModal.show && (
        <CreateCustomerForm
          onSuccess={createCustomerModal.onSuccess}
          onClose={createCustomerModal.onClose}
        />
      )}
    </>
  )
}

const DuplicateScheduleModal = (props: {
  scheduleId: string
  onSuccess?: (newScheduleId: string) => void
  trigger?: ReactNode
}) => {
  return (
    <ModalContextProvider>
      {props.trigger && <Modal.Trigger>{props.trigger}</Modal.Trigger>}
      <DuplicateScheduleModalUI
        onSuccess={props.onSuccess}
        scheduleId={props.scheduleId}
      />
    </ModalContextProvider>
  )
}

export const DuplicateScheduleMenuItem = (props: {
  scheduleId: string
  disabled?: boolean
  onSuccess?: (newScheduleId: string) => void
}) => {
  return (
    <DuplicateScheduleModal
      scheduleId={props.scheduleId}
      onSuccess={props.onSuccess}
      trigger={
        <SequenceMenuItem
          uuid="duplicate-schedule"
          label="Duplicate schedule"
          data-testid={`schedules.${props.scheduleId}.menuItem.duplicate`}
          isDisabled={props.disabled}
        />
      }
    />
  )
}
