import { Link, Text } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey40,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import { Section } from 'modules/LoginApp/components/common/Section'
import { LightningIcon } from '@sequencehq/core-components'
import { PropsWithChildren } from 'react'

export const FormStage = ({ children }: PropsWithChildren) => {
  return (
    <>
      {children}

      <Section
        bg={GreyGrey0}
        borderColor={GreyGrey40}
        boxShadow="none"
        padding="16px 20px"
        flexDirection="row"
      >
        <LightningIcon />
        <Text {...Lato14ExtendedRegular} color="gray.80">
          We’ll send you an email with a link to sign in without a password.
        </Text>
      </Section>

      <Text position="absolute" bottom="40px" textAlign="center" zIndex={10}>
        New to Sequence?{' '}
        <Link color="indigo.50" href="https://access.sequencehq.com">
          Request early access
        </Link>
      </Text>
    </>
  )
}
