import { contractIdAtom } from 'ContractExtraction/atoms'
import { useAtom } from 'jotai'
import { useCallback } from 'react'

type UseSetContractId = () => (contractId: string | undefined) => void

export const useSetContractId: UseSetContractId = () => {
  const [, setContractIdAtom] = useAtom(contractIdAtom)

  const setContractId = useCallback(
    (contractId: string | undefined) => {
      setContractIdAtom(contractId)
    },
    [setContractIdAtom]
  )

  return setContractId
}
