import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { getSignedAssetUrl } from '@sequencehq/utils'

export const resolveFileUrl = async (url: string) => {
  const signedUrlRes = await getSignedAssetUrl({
    url,
    assetLoader: async ({ assetId }) => {
      const res = await dashboard20240730Client.getAsset({ id: assetId })

      if (!res.data || res.error) {
        return undefined
      }

      return { urlSigned: res.data.url }
    }
  })

  if (!signedUrlRes) {
    return '#'
  }

  return signedUrlRes
}
