import {
  Modal,
  ModalContextProvider,
  SimpleModalUI,
  TextInputField,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { type ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'

interface Props {
  trigger: ReactNode | ((openModal: () => void) => ReactNode)
  onSubmit: (values: { name: string }) => Promise<void>
  initialName: string
}

const EditTaxCategoryNameModalUI = withUnmountOnModalClose(
  ({ onSubmit, initialName }: Omit<Props, 'trigger'>) => {
    const { fields, queries } = useForm({
      value: {
        name: initialName
      },
      fieldConfiguration: [
        {
          property: 'name',
          validation: [required]
        }
      ]
    })

    return (
      <SimpleModalUI
        title="Edit tax category"
        cancelButtonText="Cancel"
        submitButtonText="Save changes"
        submitDisabled={!queries.isValid}
        closeOnCancel
        closeOnSubmit
        contentProps={{
          minHeight: '0px'
        }}
        onSubmit={() => onSubmit({ name: fields.name.value })}
      >
        <Box>
          <Label size="sm">Name</Label>
          <TextInputField
            data-testid="editTaxCategoryName.name"
            value={fields.name.value}
            validationErrors={fields.name.validationErrors}
            onChange={e => fields.name.onChange(e.target.value)}
            placeholder="Tax category name"
          />
        </Box>
      </SimpleModalUI>
    )
  }
)

const EditTaxCategoryNameModal = ({ trigger, ...props }: Props) => {
  return (
    <ModalContextProvider>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <EditTaxCategoryNameModalUI {...props} />
    </ModalContextProvider>
  )
}

export default EditTaxCategoryNameModal
