import { useCallback, useState } from 'react'
import { ActiveFilter } from '@sequencehq/tables'
import { useIsOnSavedViewPage } from 'SavedViews/savedViews.router'

/**
 * Custom hook to manage saved view functionality for the Quotes module
 *
 * @returns Object containing saved view state and handlers
 */
export function useQuotesSavedView() {
  const isSavedView = useIsOnSavedViewPage()

  const [savingFilters, setSavingFilters] = useState<false | ActiveFilter[]>(
    false
  )
  const [forceNewSavedView, setForceNewSavedView] = useState<boolean>(false)

  const onSaveFilters = useCallback(
    (activeFilters: ActiveFilter[]) => {
      if (isSavedView) {
        setForceNewSavedView(true)
      }

      setSavingFilters(activeFilters)
    },
    [isSavedView]
  )

  const onDismissSaveFilters = useCallback(() => {
    setForceNewSavedView(false)
    setSavingFilters(false)
  }, [])

  return {
    isSavedView,
    savingFilters,
    setSavingFilters,
    forceNewSavedView,
    onSaveFilters,
    onDismissSaveFilters
  }
}
