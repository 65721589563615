import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey40,
  GreyGrey60,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { Card, Skeleton } from '@sequencehq/core-components'
import { toMoney } from '@sequencehq/core-models'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { ChartTooltip } from 'RevenueRecognition/view/common/ChartTooltip'
import {
  format,
  formatDateRange,
  lastDayOfMonth
} from '@sequencehq/utils/dates'
import { MinimalLineChart } from './MinimalLineChart'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { floatSafeAdd } from 'RevenueRecognition/view/utils/floatSafeAdd'
import { RevenueData, RevenueForMonth } from './RevRecDashboardGraphs'
import { createDateFromBucket } from 'RevenueRecognition/view/utils/graphUtils'
import { useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
export const useDeferredRevenueGraph = (props: {
  currency?: Currency | null
  dateRange?: [Date, Date]
}):
  | {
      isLoading: true
      data: null
    }
  | {
      isLoading: false
      data: RevenueData
    } => {
  const deferredRevenueResponse = useQuery(
    dashboardv99990101Client.getRevRecChartsData,
    {
      currency: props.currency ?? 'GBP',
      startDate: props.dateRange?.[0]
        ? format(props.dateRange[0], 'yyyy-MM-dd')
        : undefined,
      endDate: props.dateRange?.[1]
        ? format(props.dateRange[1], 'yyyy-MM-dd')
        : undefined
    },
    {
      enabled: !!props.currency,
      staleTime: 60000,
      select: data => {
        if (!data) {
          return
        }

        const deferredRevenueByMonth = data.period.buckets.map(
          (bucket): RevenueForMonth => {
            const deferredRevenue =
              bucket.recognizedAndDeferred.ledgerAccountTotals.find(
                i => i.name === 'DEFERRED_REVENUE'
              )?.total || 0

            const startDate = createDateFromBucket({
              year: bucket.year,
              month: bucket.month
            })

            return {
              date: format(startDate, 'yyyy-MM-dd'),
              endDate: format(lastDayOfMonth(startDate), 'yyyy-MM-dd'),
              value: deferredRevenue
            }
          }
        )

        return {
          revenueByMonth: deferredRevenueByMonth,
          total: deferredRevenueByMonth.reduce((acc, entry) => {
            return floatSafeAdd(acc, entry.value)
          }, 0),
          startDate: new Date(data.period.startDate),
          endDate: new Date(data.period.endDate)
        }
      }
    }
  )

  if (deferredRevenueResponse.isError) {
    throw new Error('Failed to load deferred revenue data')
  }

  if (deferredRevenueResponse.isPending) {
    return {
      isLoading: true,
      data: null
    }
  }

  invariant(
    deferredRevenueResponse.data,
    'Deferred revenue should be defined, if loaded'
  )

  return {
    data: {
      revenueByMonth: deferredRevenueResponse.data.revenueByMonth,
      total: deferredRevenueResponse.data.total,
      startDate: deferredRevenueResponse.data.startDate,
      endDate: deferredRevenueResponse.data.endDate
    },
    isLoading: false
  }
}

const GraphDataTooltip = ({
  date,
  values
}: {
  date: string
  values: {
    recognized: string
  }
}) => {
  return (
    <ChartTooltip>
      <ChartTooltip.Header>
        <Text color={GreyGrey70}>{date}</Text>
      </ChartTooltip.Header>
      <ChartTooltip.Body>
        <Flex width="100%" justifyContent="space-between">
          <Text color={GreyGrey70}>Deferred</Text>
          <Text color={GreyGrey90}>{values.recognized}</Text>
        </Flex>
      </ChartTooltip.Body>
    </ChartTooltip>
  )
}

interface DeferredRevenueCardProps {
  currency: Currency
  dateRange: [Date, Date]
  yAxisLabels?: number[]
}

// TODO: To be filled with the correct data
export const DeferredRevenueCard = ({
  currency,
  dateRange,
  yAxisLabels
}: DeferredRevenueCardProps) => {
  const { isLoading, data } = useDeferredRevenueGraph({
    currency,
    dateRange
  })

  if (isLoading) {
    return (
      <Skeleton
        minWidth="390px"
        minHeight="108px"
        width="100%"
        height="100%"
        borderRadius="8px"
      />
    )
  }

  return (
    <Card
      minWidth="390px"
      width="100%"
      data-testid="revrec.home.deferredRevenueGraph"
      borderRadius="8px"
      border={`1px solid ${GreyGrey40}`}
      boxShadow="0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.05)"
      padding="0"
      overflow="hidden"
      height="104px"
    >
      <Flex padding="16px" justifyContent="space-between">
        <Flex flexDirection="column" gap="8px" minWidth="160px">
          <Text {...Lato13Regular} fontWeight="semibold" color={GreyGrey60}>
            Deferred revenue
          </Text>
          <Text {...Lato20Bold} color={GreyGrey90}>
            {toMoney({ currency, value: data.total.toString() })}
          </Text>
          <Text {...Lato13Regular} color={GreyGrey60}>
            {formatDateRange({
              from: dateRange[0],
              to: dateRange[1],
              separator: ' - '
            })}
          </Text>
        </Flex>

        <Flex height="72px" width="100%">
          <MinimalLineChart<{
            value: number
            date: string
          }>
            data={data.revenueByMonth}
            renderTooltip={({
              date,
              value
            }: { date: string; value: number }) => (
              <GraphDataTooltip
                date={format(new Date(date), 'MMMM yyyy')}
                values={{
                  recognized: toMoney({
                    value: value.toString(),
                    currency
                  })
                }}
              />
            )}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
