import { useCallback } from 'react'
import type {
  InvoiceModel,
  InvoicePaymentStatus,
  PaymentProvider,
  IntegrationService,
  UsageDataResponseModel
} from '@sequencehq/core-models'
import { UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN } from 'InvoiceEditor/domainManagement/invoiceEditor.constants'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'
import { useMutation } from '@sequencehq/api/utils'
import type {
  ApiLineItem,
  ApiLineItemGroup,
  InvoiceEditorReducerState
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { invoiceEditorApiAdapter } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { useLoadLineItemsAndGroups } from 'InvoiceEditor/hooks/useLoadLineItemsAndGroups'
import { useLoadUsageData } from 'InvoiceEditor/hooks/useLoadUsageData'
import { apiQueryClient } from 'features/api/apiQueryClient.ts'
import { Invoice } from 'InvoiceEditor/hooks/useLoadInvoice'

type ApiUsageItemGroup =
  DashboardApi20240730.GetInvoiceUsageItemGroups.UsageItemGroup
type CreateLineItemGroupData = {
  title: string
  taxCategoryId?: string
}

type CreateLineItemData = {
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  rateDisplay: 'ABSOLUTE' | 'PERCENTAGE'
  externalIds: ApiLineItem['externalIds']
  taxCategoryId?: string
}

type UpdateLineItemData = {
  id: string
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  rateDisplay: 'ABSOLUTE' | 'PERCENTAGE'
  externalIds: ApiLineItem['externalIds']
  taxCategoryId?: string
}

type UpdateLineItemGroupData = {
  id: string
  title: string
  taxCategoryId?: string
}

export const useSaveInvoice = (args: { invoiceId: string }) => {
  const createLineItemGroupMutator = useMutation(
    dashboardv99990101Client.postLineItemGroup
  )
  const updateLineItemGroupMutator = useMutation(
    dashboardv99990101Client.putLineItemGroup
  )

  const createLineItemMutator = useMutation(
    dashboardv99990101Client.postLineItem
  )
  const updateLineItemMutator = useMutation(
    dashboardv99990101Client.putLineItem
  )
  const invoiceToDraftMutator = useMutation(
    dashboard20240730Client.convertInvoiceToDraft
  )

  const {
    data,
    refetchLineItemsAndGroups,
    lineItemGroupsError,
    lineItemsError
  } = useLoadLineItemsAndGroups(args.invoiceId)
  const { lineItems, lineItemGroups } = data || {}
  const { loadUsageData } = useLoadUsageData({
    invoiceId: undefined,
    lineItemGroups: [],
    async: true
  })

  const sendInvoice = useCallback(
    (invoiceId: Invoice['id']) =>
      async (): Promise<{
        invoice: InvoiceModel | null
        success: boolean
      }> => {
        const response = await dashboard20240730Client.postSendInvoice({
          id: invoiceId
        })

        if (response.error) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data ?? null,
            success: true
          }
        }
      },
    []
  )

  const finaliseInvoice = useCallback(
    (invoiceId: Invoice['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await dashboard20240730Client.postFinalizeInvoice({
          id: invoiceId
        })

        if (response.error) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data ?? null,
            success: true
          }
        }
      },
    []
  )

  const recalculateInvoice = useCallback(
    (invoiceId: Invoice['id']) =>
      async (): Promise<{
        invoice: InvoiceModel | null
        lineItems: ApiLineItem[] | null
        lineItemGroups: ApiLineItemGroup[] | null
        lineItemGroupUsage:
          | (UsageDataResponseModel & { lineItemGroupId: string })[]
          | null
        subAccountUsageBreakdown: ApiUsageItemGroup[] | null
        success: boolean
      }> => {
        // send request to BE to recalculate invoice
        const recalculateInvoiceResponse =
          await dashboard20240730Client.postRecalculateInvoice({
            id: invoiceId
          })

        // refresh line items and line item groups
        const {
          lineItems: refetchedLineItems,
          lineItemGroups: refetchedLineItemGroups
        } = await refetchLineItemsAndGroups()
        const subAccountUsageResponse =
          await dashboard20240730Client.getInvoiceUsageItemGroups({
            invoiceId
          })

        if (
          recalculateInvoiceResponse.error ||
          subAccountUsageResponse.error ||
          lineItemGroupsError ||
          lineItemsError ||
          !lineItems ||
          !lineItemGroups
        ) {
          return {
            invoice: null,
            lineItems: null,
            lineItemGroups: null,
            lineItemGroupUsage: null,
            subAccountUsageBreakdown: null,
            success: false
          }
        } else {
          const usageData = await loadUsageData({
            invoiceId,
            lineItemGroups
          })

          return {
            invoice: recalculateInvoiceResponse.data ?? null,
            lineItems: refetchedLineItems,
            lineItemGroups: refetchedLineItemGroups,
            lineItemGroupUsage: usageData.data,
            subAccountUsageBreakdown: subAccountUsageResponse.data?.items ?? [],
            success: true
          }
        }
      },
    [
      lineItems,
      lineItemGroups,
      lineItemGroupsError,
      lineItemsError,
      loadUsageData,
      refetchLineItemsAndGroups
    ]
  )

  const voidInvoice = useCallback(
    (invoiceId: Invoice['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await dashboard20240730Client.postVoidInvoice({
          id: invoiceId
        })

        if (response.error) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data ?? null,
            success: true
          }
        }
      },
    []
  )

  const convertInvoiceToDraft = useCallback(
    (invoiceId: Invoice['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await invoiceToDraftMutator.mutateAsync({
          id: invoiceId
        })

        return {
          invoice: response as InvoiceModel | null,
          success: response !== null
        }
      },
    [invoiceToDraftMutator]
  )

  const finaliseAndSendInvoice = useCallback(
    (invoiceId: Invoice['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        // TODO: what about invoice number?
        const response =
          await dashboard20240730Client.postFinalizeAndSendInvoice({
            id: invoiceId
          })

        if (response.error) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data ?? null,
            success: true
          }
        }
      },
    []
  )

  const sendPaymentReminder = useCallback(
    (invoiceId: Invoice['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response =
          await dashboard20240730Client.postSendPaymentReminderForInvoice({
            id: invoiceId
          })

        if (response.error) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data,
            success: true
          }
        }
      },
    []
  )

  const updatePaymentStatus = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        paymentStatus: InvoicePaymentStatus
      ): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await dashboard20240730Client.putInvoicePaymentStatus({
          invoiceId,
          paymentStatus
        })

        if (response.error) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data,
            success: true
          }
        }
      },
    []
  )

  const createLineItemGroup =
    (invoiceId: Invoice['id']) =>
    async (
      lineItemGroup: CreateLineItemGroupData
    ): Promise<{
      lineItemGroup: ApiLineItemGroup | null
      success: boolean
    }> => {
      const createdLineItemGroup = await createLineItemGroupMutator.mutateAsync(
        {
          invoiceId,
          body: {
            title: lineItemGroup.title,
            taxCategoryId: lineItemGroup.taxCategoryId
          }
        }
      )

      return {
        lineItemGroup: createdLineItemGroup,
        success: createdLineItemGroup !== null
      }
    }

  const createLineItem =
    (invoiceId: Invoice['id']) =>
    async (
      lineItemGroupId: string,
      lineItem: CreateLineItemData
    ): Promise<{
      lineItem: ApiLineItem | null
      success: boolean
    }> => {
      const createdLineItem = await createLineItemMutator.mutateAsync({
        invoiceId,
        body: {
          groupId: lineItemGroupId.match(UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN)
            ? undefined
            : lineItemGroupId,
          title: lineItem.title,
          description: lineItem.description,
          quantity: lineItem.quantity.toString(),
          rate: lineItem.rate,
          taxRate: lineItem.taxRate.toString(),
          rateDisplay: lineItem.rateDisplay,
          externalIds: lineItem.externalIds,
          taxCategoryId: lineItem.taxCategoryId
        }
      })

      return {
        lineItem: createdLineItem,
        success: createdLineItem !== null
      }
    }

  const updateLineItem =
    (invoiceId: Invoice['id']) =>
    async (
      lineItemGroupId: string,
      lineItem: UpdateLineItemData
    ): Promise<{
      lineItem: ApiLineItem | null
      success: boolean
    }> => {
      const updatedLineItem = await updateLineItemMutator.mutateAsync({
        invoiceId,
        id: lineItem.id,
        body: {
          groupId: lineItemGroupId.match(UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN)
            ? undefined
            : lineItemGroupId,
          title: lineItem.title,
          description: lineItem.description,
          quantity: lineItem.quantity.toString(),
          rate: lineItem.rate,
          taxRate: lineItem.taxRate.toString(),
          rateDisplay: lineItem.rateDisplay,
          externalIds: lineItem.externalIds,
          taxCategoryId: lineItem.taxCategoryId
        }
      })

      return {
        lineItem: updatedLineItem,
        success: updatedLineItem !== null
      }
    }

  const updateLineItemGroup =
    (invoiceId: Invoice['id']) =>
    async (
      lineItemGroup: UpdateLineItemGroupData,
      lineItemsForGroup: UpdateLineItemData[] = []
    ): Promise<{
      lineItemGroup: ApiLineItemGroup | null
      lineItems: ApiLineItem[]
      success: boolean
    }> => {
      const updatedLineItemGroup = await updateLineItemGroupMutator.mutateAsync(
        {
          invoiceId,
          id: lineItemGroup.id,
          body: {
            title: lineItemGroup.title,
            taxCategoryId: lineItemGroup.taxCategoryId
          }
        }
      )

      const updatedLineItems = await Promise.all(
        lineItemsForGroup.map(lineItem => {
          return updateLineItem(invoiceId)(lineItemGroup.id, lineItem)
        })
      )

      return {
        lineItems: updatedLineItems
          .map(result => result.lineItem)
          .filter(Boolean) as ApiLineItem[],
        lineItemGroup: updatedLineItemGroup,
        success: updatedLineItemGroup !== null
      }
    }

  const deleteLineItem = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        lineItemId: string
      ): Promise<{
        success: boolean
      }> => {
        const response = await dashboard20240730Client.deleteInvoiceLineItem({
          invoiceId,
          lineItemId
        })

        if (response.error) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    []
  )

  const deleteLineItemGroup = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        lineItemGroupId: string
      ): Promise<{
        success: boolean
      }> => {
        const response =
          await dashboard20240730Client.deleteInvoiceLineItemGroup({
            invoiceId,
            lineItemGroupId
          })

        if (response.error) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    []
  )

  const updateMemo = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newMemo: InvoiceModel['memo']
      ): Promise<{
        memo?: InvoiceModel['memo']
        success: boolean
      }> => {
        const response = await dashboard20240730Client.putInvoice({
          id: invoiceId,
          body: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            memo: newMemo
          }
        })

        if (response.error) {
          return {
            success: false
          }
        }

        return {
          memo: response.data?.memo ?? '',
          success: true
        }
      },
    []
  )

  const updateDueDate = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newDueDate: InvoiceModel['dueDate']
      ): Promise<{
        dueDate?: InvoiceModel['dueDate']
        success: boolean
      }> => {
        const response = await dashboard20240730Client.putInvoice({
          id: invoiceId,
          body: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            dueDate: newDueDate
          }
        })

        if (response.error) {
          return {
            success: false
          }
        }

        return {
          dueDate: response.data?.dueDate ?? '',
          success: true
        }
      },
    []
  )

  const updatePurchaseOrderNumber = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newPurchaseOrderNumber: InvoiceModel['purchaseOrderNumber']
      ): Promise<{
        purchaseOrderNumber?: InvoiceModel['purchaseOrderNumber']
        success: boolean
      }> => {
        const response = await dashboard20240730Client.putInvoice({
          id: invoiceId,
          body: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            purchaseOrderNumber: newPurchaseOrderNumber
          }
        })

        if (response.error) {
          return {
            success: false
          }
        }

        return {
          purchaseOrderNumber: response.data?.purchaseOrderNumber ?? '',
          success: true
        }
      },
    []
  )

  const updateReference = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newReference: InvoiceModel['reference']
      ): Promise<{
        reference?: InvoiceModel['reference']
        success: boolean
      }> => {
        const response = await dashboard20240730Client.putInvoice({
          id: invoiceId,
          body: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            reference: newReference
          }
        })

        if (response.error) {
          return {
            success: false
          }
        }

        return {
          reference: response.data?.reference ?? '',
          success: true
        }
      },
    []
  )

  const sendTestInvoice = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        email: string
      ): Promise<{ invoice?: InvoiceModel; success: boolean }> => {
        const response = await dashboard20240730Client.postSendTestInvoice({
          id: invoiceId,
          testEmail: email
        })

        if (response.error || !response.data) {
          return {
            success: false
          }
        } else {
          return {
            invoice: response.data,
            success: true
          }
        }
      },
    []
  )

  const syncToIntegration = useCallback(
    (invoiceId: Invoice['id']) =>
      async (service: IntegrationService): Promise<{ success: boolean }> => {
        const response =
          await dashboard20240730Client.postSyncInvoiceWithExternalService({
            invoiceId: invoiceId,
            service: service
          })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    []
  )

  const createInvoiceSettings = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        paymentProvider: PaymentProvider
      ): Promise<{ success: boolean }> => {
        const response = await dashboard20240730Client.postInvoiceSettings({
          invoiceId,
          paymentProvider
        })

        if (response.error) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    []
  )

  const updateInvoice = useCallback(
    (invoiceId: Invoice['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        payload: Partial<Pick<Invoice, 'billingPeriod' | 'accountingDate'>>
      ): Promise<{
        dueDate?: Invoice['dueDate']
        success: boolean
      }> => {
        const response = await dashboard20240730Client.putInvoice({
          id: invoiceId,
          body: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            ...payload
          }
        })

        if (response.error) {
          return {
            success: false
          }
        }

        return {
          ...payload,
          success: true
        }
      },
    []
  )

  return {
    createInvoiceSettings,
    createLineItemGroup,
    createLineItem,
    deleteLineItem,
    deleteLineItemGroup,
    finaliseAndSendInvoice,
    finaliseInvoice,
    recalculateInvoice,
    sendInvoice,
    sendPaymentReminder,
    sendTestInvoice,
    syncToIntegration,
    updateLineItemGroup,
    updateLineItem,
    updatePaymentStatus,
    voidInvoice,
    updateMemo,
    updateDueDate,
    updatePurchaseOrderNumber,
    updateReference,
    updateInvoice,
    convertInvoiceToDraft
  }
}
