import { useComplexQuery } from '@sequencehq/api'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type ApiPrice = Exclude<DashboardApi20240730.GetPrice.Response['data'], null>
type ApiProduct = Exclude<
  Dashboardv20240509Api.GetProduct.Response['data'],
  null
>

export const BILLING_SCHEDULE_AGGREGATE_BASE_QUERY_KEY =
  'billingScheduleAggregate'

export const fetchBillingScheduleAggregate = async (
  billingScheduleId: string
) => {
  const [billingScheduleResponse, billingScheduleSettingsResponse] =
    await Promise.all([
      dashboard20240730Client.getBillingSchedule({
        id: billingScheduleId
      }),
      dashboard20240730Client.getBillingScheduleSettings({
        billingScheduleId
      })
    ])

  if (!billingScheduleResponse.data || billingScheduleResponse.error) {
    throw new Error('Failed to fetch billing schedule')
  }

  const billingSchedule = billingScheduleResponse.data

  const allPriceIds = billingScheduleResponse.data.phases?.flatMap(
    ({ priceIds }) => priceIds
  )
  const taxRateId = billingSchedule.taxRates[0]?.taxRateId

  const [taxRateResponse, allPriceResponses] = await Promise.all([
    taxRateId
      ? dashboardv20240509Client.getTaxRate({ id: taxRateId })
      : Promise.resolve(null),
    Promise.all(
      allPriceIds.map(priceId =>
        dashboard20240730Client.getPrice({
          id: priceId
        })
      )
    )
  ])

  const prices = allPriceResponses
    .map(res => res.data)
    .filter((price): price is ApiPrice => Boolean(price))

  const productIds = prices.map(price => price.productId)

  const allProductResponses = await Promise.all(
    productIds.map(productId =>
      dashboardv20240509Client.getProduct({
        id: productId
      })
    )
  )

  const products = allProductResponses
    .map(res => res.data)
    .filter((product): product is ApiProduct => Boolean(product))

  return {
    billingSchedule,
    billingScheduleSettings: billingScheduleSettingsResponse.data,
    prices,
    products,
    taxRate: taxRateResponse ? taxRateResponse.data : null
  }
}

export const getBillingScheduleAggregateKey = (billingScheduleId: string) => [
  /**
   * This key ensures that anything that would invalidate a dependency
   * also invalidates the aggregate. This is because our invalidation
   * will invalidate a cache key as long as the key is contained within
   * the overall query key.
   *
   * Note that this pattern, and the change to invalidation made in the same
   * PR, lays the foundations for defining aggregate dependencies within
   * our API package. When we introduce BE aggregates, that will be incredibly
   * useful! Of course, the full version of that will be cleaner than this,
   * but the concept is similar.
   */
  BILLING_SCHEDULE_AGGREGATE_BASE_QUERY_KEY,
  ...dashboard20240730Client.getBillingSchedule.queryKey,
  ...dashboard20240730Client.getBillingScheduleSettings.queryKey,
  ...dashboard20240730Client.getPrice.queryKey,
  ...dashboardv20240509Client.getProduct.queryKey,
  ...dashboardv20240509Client.getTaxRate.queryKey,
  { billingScheduleId }
]
