import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { useLoadInvoiceEditor } from 'InvoiceEditor/hooks/useLoadInvoiceEditor'
import {
  type AdapterCustomerWithIntegrationsModel,
  type AdapterCustomerWithTaxModel,
  invoiceEditorApiAdapter
} from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { useInvoiceEditor } from 'InvoiceEditor/domainManagement/useInvoiceEditor'
import { useSaveInvoice } from 'InvoiceEditor/entitySaving/useSaveInvoiceEditor'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import {
  type IntegrationService,
  type InvoiceModel,
  type InvoicePaymentOption,
  type InvoicePaymentStatus,
  type InvoiceTotalsModel,
  type PaymentProvider,
  paymentProviderToLabel,
  toInvoicePaymentStatusText
} from '@sequencehq/core-models'
import type { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import * as Sentry from '@sentry/react'
import { useConfirmationModals } from 'InvoiceEditor/components/modals/useConfirmationModals'
import type {
  ApiLineItem,
  InvoiceEditorData,
  InvoiceEditorLineItemGroup,
  InvoiceEditorReducerState,
  RecursivePartial
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { useNotifications } from 'lib/hooks/useNotifications'
import { match } from 'ts-pattern'
import { useSaveCreditNote } from 'InvoiceEditor/entitySaving/useSaveCreditNote'
import { useSaveCustomer } from 'InvoiceEditor/entitySaving/useSaveCustomer'
import flow from 'lodash/fp/flow'
import omit from 'lodash/fp/omit'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { integrationName } from 'lib/integrations/integrationName'
import { formatDateRange } from '@sequencehq/utils/dist/dates'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Invoice } from 'InvoiceEditor/hooks/useLoadInvoice'

type UpdateData = {
  totals: InvoiceTotalsModel | Record<string, never>
  lineItem?: ApiLineItem & {
    action?: 'CREATE' | 'EDIT' | 'DELETE'
    temporaryId?: string
  }
  lineItemGroup?: InvoiceEditorLineItemGroup & {
    action?: 'CREATE' | 'EDIT' | 'DELETE'
    temporaryId?: string
  }
}

type UpdateReducerPayload = (
  updateData: UpdateData,
  reducerState: InvoiceEditorReducerState['data']
) => {
  totals?: InvoiceEditorReducerState['data']['totals']
  lineItems?: InvoiceEditorReducerState['data']['lineItems']
  lineItemGroups?: InvoiceEditorReducerState['data']['lineItemGroups']
}

type UpdateReducerStateAction = (
  updateData: UpdateData,
  reducerState: InvoiceEditorReducerState['data']
) => (
  updatedState: Partial<InvoiceEditorReducerState['data']>
) => Partial<InvoiceEditorReducerState['data']>

const invoiceStatusNotificationContent = (status: InvoiceModel['status']) =>
  match(status)
    .with('FINAL', () => 'Invoice finalized')
    .with('SENT', () => 'Invoice sent to customer')
    .with('VOIDED', () => 'Invoice voided')
    .otherwise(() => 'Invoice status updated')

const updateLineItemGroups: UpdateReducerStateAction =
  (updateData, reducerState) => updatedState => {
    if (!updateData.lineItemGroup) {
      return updatedState
    }

    const { action, temporaryId, ...lineItemGroup } = updateData.lineItemGroup

    if (action === 'DELETE') {
      return {
        ...updatedState,
        lineItemGroups: (omit(lineItemGroup.id)(reducerState.lineItemGroups) ??
          {}) as InvoiceEditorReducerState['data']['lineItemGroups']
      }
    }

    if (action === 'CREATE' && temporaryId) {
      return {
        ...updatedState,
        lineItemGroups: {
          ...((omit(temporaryId)(reducerState.lineItemGroups) ??
            {}) as InvoiceEditorReducerState['data']['lineItemGroups']),
          [lineItemGroup.id]: lineItemGroup
        }
      }
    }

    const groupTotals = updateData.totals?.lineItemGroupTotals?.find(
      groupTotal => groupTotal.id === lineItemGroup.id
    )?.total

    if (groupTotals) {
      return {
        ...updatedState,
        lineItemGroups: {
          ...reducerState.lineItemGroups,
          [lineItemGroup.id]: {
            ...lineItemGroup,
            ...groupTotals
          }
        }
      }
    }

    return {
      ...updatedState,
      lineItemGroups: {
        ...reducerState.lineItemGroups,
        [lineItemGroup.id]: lineItemGroup
      }
    }
  }

const updateLineItems: UpdateReducerStateAction =
  (updateData, reducerState) => updatedState => {
    if (!updateData.lineItem) {
      return updatedState
    }

    const { action, temporaryId, ...lineItem } = updateData.lineItem

    if (action === 'CREATE' && temporaryId) {
      return {
        ...updatedState,
        lineItems: {
          ...((omit(temporaryId)(reducerState.lineItems) ??
            {}) as InvoiceEditorReducerState['data']['lineItems']),
          [lineItem.id]: lineItem
        }
      }
    }

    if (action === 'EDIT') {
      return {
        ...updatedState,
        lineItems: {
          ...reducerState.lineItems,
          [lineItem.id]: lineItem
        }
      }
    }

    return {
      ...updatedState,
      lineItems: (omit(lineItem.id)(reducerState.lineItems) ??
        {}) as InvoiceEditorReducerState['data']['lineItems']
    }
  }

const updateTotals: UpdateReducerStateAction =
  (updateData, reducerState) => updatedState => {
    if (updateData.totals.total) {
      return {
        ...updatedState,
        totals: invoiceEditorApiAdapter.in.totals(
          updateData.totals.total,
          reducerState.invoice.currency
        )
      }
    }

    return updatedState
  }

const updateReducerPayload: UpdateReducerPayload = (
  updateData,
  reducerState
) => {
  const updatedState = {}
  return flow(
    updateTotals(updateData, reducerState),
    updateLineItemGroups(updateData, reducerState),
    updateLineItems(updateData, reducerState)
  )(updatedState)
}

export const useInvoiceEditorRoot = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  invariant(invoiceId, 'invoiceId is required')

  const [paymentDetailsDrawerState, setPaymentDetailsDrawerState] = useState<{
    active: boolean
    onClose: () => void
  }>({
    active: false,
    onClose: () => {}
  })

  const [invoicePdfPreviewDrawerState, setInvoicePdfPreviewDrawerState] =
    useState<{
      active: boolean
      onClose: () => void
    }>({
      active: false,
      onClose: () => {}
    })

  const [editCustomerDrawerState, setEditCustomerDrawerState] = useState<{
    active: boolean
    onClose: () => void
  }>({
    active: false,
    onClose: () => {}
  })

  const notifications = useNotifications()
  const flags = useFlags()

  const saveInvoice = useSaveInvoice({ invoiceId })
  const saveCreditNote = useSaveCreditNote()
  const saveCustomer = useSaveCustomer()

  const updatedTotals = useCallback(
    async (
      lineItems: ApiLineItem[]
    ): Promise<
      | DashboardApi20240730.PostCalculateInvoiceTotals.Response
      | Record<string, never>
    > => {
      const updatedTotalsResult =
        await dashboard20240730Client.postCalculateInvoiceTotals({
          lineItems
        })

      if (updatedTotalsResult.error) {
        return {}
      } else {
        const newTotals = updatedTotalsResult.data

        if (!newTotals) {
          return {}
        }

        return newTotals
      }
    },
    []
  )

  const processStatusUpdate = useCallback(
    async (
      saveAction: (
        invoiceId: Invoice['id']
      ) => () => Promise<{ invoice: InvoiceModel | null; success: boolean }>,
      updatingStatusMessage = 'Updating invoice status...'
    ) => {
      notifications.displayNotification(updatingStatusMessage, {
        duration: 30000
      })

      const saveResult = await saveAction(invoiceId)()

      if (saveResult.success && saveResult.invoice) {
        const updatedInvoiceAdapterData = invoiceEditorApiAdapter.in.invoice(
          // @ts-expect-error We need to update the `saveAction` functions to return `Invoice` instead of `InvoiceModel`
          saveResult.invoice
        )

        notifications.displayNotification(
          invoiceStatusNotificationContent(updatedInvoiceAdapterData.status),
          {
            type: 'success'
          }
        )

        return {
          invoice: {
            status: updatedInvoiceAdapterData.status,
            dueDate: updatedInvoiceAdapterData.dueDate,
            invoiceNumber: updatedInvoiceAdapterData.invoiceNumber
          }
        }
      } else {
        notifications.displayNotification('Failed to update invoice status', {
          type: 'error'
        })
      }
    },
    [invoiceId, notifications]
  )

  /*
   * These are all the invoice update actions that can be undertaken on the view page without going into the editor.
   * They all require a confirmation modal before we actually persist any changes, so we have to manage modal state for them.
   *
   * They need to:
   *   - Show a modal
   *   - Block completing the network request until the modal has been interacted with (i.e. confirm or cancel)
   *   - Send a network request to persist the data update
   *   - Resolve ^ then update the reducer state to match what has been persisted (<- we could consider switching this around for the purpose of quicker UI feedback)
   *   - Close modal
   *   - Notify success or failure
   */

  const refetchInvoice = useCallback(() => {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        dashboard20240730Client
          .getInvoice({
            id: invoiceId
          })
          .then(result => {
            if (result.data) {
              invoiceEditorApiAdapter.in.invoice(result.data)
              resolve()
            }
          })
          .catch(e => Sentry.captureException(e))
      }, 2000)
    })
  }, [invoiceId])

  const updateInvoiceToSend = useCallback(
    () =>
      processStatusUpdate(
        () => saveInvoice.sendInvoice(invoiceId),
        'Sending invoice...'
      ),
    [invoiceId, processStatusUpdate, saveInvoice]
  )

  const updateInvoiceToFinalise = useCallback(
    () => processStatusUpdate(() => saveInvoice.finaliseInvoice(invoiceId)),
    [invoiceId, processStatusUpdate, saveInvoice]
  )
  const updateInvoiceToRecalculate = useCallback(async () => {
    notifications.displayNotification('Recalculating invoice...', {
      duration: 30000
    })
    const saveResult = await saveInvoice.recalculateInvoice(invoiceId)()

    if (
      saveResult.success &&
      saveResult.invoice &&
      saveResult.lineItems &&
      saveResult.lineItemGroups &&
      saveResult.lineItemGroupUsage
    ) {
      try {
        const recalculatedTotals = invoiceEditorApiAdapter.in.totals(
          {
            netTotal: saveResult.invoice.netTotal,
            grossTotal: saveResult.invoice.grossTotal,
            totalTax: saveResult.invoice.totalTax
          },
          saveResult.invoice.currency
        )

        notifications.displayNotification('Invoice recalculated', {
          type: 'success'
        })

        return {
          totals: recalculatedTotals,
          lineItems: arrayToIdKeyedObject(saveResult.lineItems),
          lineItemGroups: arrayToIdKeyedObject(saveResult.lineItemGroups),
          lineItemGroupUsage: saveResult.lineItemGroupUsage,
          subAccountUsageBreakdown:
            invoiceEditorApiAdapter.in.subAccountUsageBreakdown(
              saveResult.subAccountUsageBreakdown ?? []
            ),
          calculatedAt: saveResult.invoice.calculatedAt,
          billingPeriod: saveResult.invoice.billingPeriod
            ? formatDateRange({
                from: new Date(saveResult.invoice.billingPeriod.start),
                to: new Date(saveResult.invoice.billingPeriod.endInclusive)
              })
            : null
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    } else {
      notifications.displayNotification('Failed to recalculate invoice', {
        type: 'error'
      })
    }
  }, [invoiceId, notifications, saveInvoice])
  const updateInvoiceToVoid = useCallback(
    () => processStatusUpdate(() => saveInvoice.voidInvoice(invoiceId)),
    [invoiceId, processStatusUpdate, saveInvoice]
  )

  const updateInvoiceToDraft = useCallback(
    () =>
      processStatusUpdate(() => saveInvoice.convertInvoiceToDraft(invoiceId)),
    [invoiceId, processStatusUpdate, saveInvoice]
  )
  const updateInvoiceToSendAndFinalise = useCallback(
    () =>
      processStatusUpdate(() => saveInvoice.finaliseAndSendInvoice(invoiceId)),
    [invoiceId, processStatusUpdate, saveInvoice]
  )
  const updateInvoiceToSendPaymentReminder = useCallback(async () => {
    notifications.displayNotification('Sending payment reminder...', {
      duration: 30000
    })
    const saveResult = await saveInvoice.sendPaymentReminder(invoiceId)()

    if (saveResult.success) {
      notifications.displayNotification('Payment reminder sent', {
        type: 'success'
      })
    } else {
      notifications.displayNotification('Failed to send payment reminder', {
        type: 'error'
      })
    }
  }, [invoiceId, saveInvoice, notifications])

  const createCreditNoteFromInvoice = useCallback(
    async (
      currency: Currency,
      customerId: string,
      billingPeriodStart: string | undefined,
      billingPeriodEndInclusive: string | undefined,
      customerTaxId?: string
    ) => {
      notifications.displayNotification('Creating credit note...', {
        duration: 30000
      })

      const saveResult = await saveCreditNote.createFromInvoice(
        invoiceId,
        currency,
        customerId,
        billingPeriodStart,
        billingPeriodEndInclusive,
        customerTaxId
      )

      if (saveResult.success && saveResult.creditNote) {
        notifications.displayNotification('Credit note created', {
          type: 'success'
        })

        return {
          creditNote: {
            id: saveResult.creditNote.id
          }
        }
      } else {
        notifications.displayNotification('Failed to create credit note', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveCreditNote, notifications]
  )

  const updateCreditNoteLineItems = useCallback(
    async (creditNoteId: string, lineItemIds: string[]) => {
      notifications.displayNotification('Updating credit note line items...', {
        duration: 30000
      })

      const saveResult =
        await saveCreditNote.createLineItems(creditNoteId)(lineItemIds)

      if (saveResult.success && saveResult.creditNote) {
        // We don't need to update any reducer state for creating credit note line items
        notifications.displayNotification('Credit note line items updated', {
          type: 'success'
        })
        window.open(`/credit-notes/${saveResult.creditNote.id}`, '_blank')
      } else {
        notifications.displayNotification(
          'Failed to update credit note line items',
          {
            type: 'error'
          }
        )
      }
    },
    [invoiceId, saveCreditNote, notifications]
  )

  const sendTestInvoiceToEmail = useCallback(
    async (email: string) => {
      notifications.displayNotification('Sending test invoice...', {
        duration: 30000
      })

      const saveResult = await saveInvoice.sendTestInvoice(invoiceId)(email)

      if (saveResult.success && saveResult.invoice) {
        // We don't need to update any reducer state for sending a test invoice
        notifications.displayNotification('Test invoice sent', {
          type: 'success'
        })
      } else {
        notifications.displayNotification('Failed to send test invoice', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications]
  )

  const linkCustomerToIntegration = useCallback(
    async (
      updatedCustomer: AdapterCustomerWithIntegrationsModel,
      integrationService: IntegrationService,
      existingCustomerLinks: InvoiceEditorReducerState['data']['customer']['integrationIds']
    ) => {
      notifications.displayNotification('Updating customer...', {
        duration: 30000
      })

      const saveResult = await saveCustomer.updateWithIntegrations(
        updatedCustomer.id
      )(
        invoiceEditorApiAdapter.out.customer.updateWithIntegrations(
          updatedCustomer
        )
      )

      if (
        saveResult.success &&
        saveResult.customer !== null &&
        typeof saveResult.customer !== 'undefined'
      ) {
        notifications.displayNotification('Customer updated', {
          type: 'success'
        })

        return {
          customer: {
            integrationIds: [
              ...existingCustomerLinks,
              updatedCustomer.integrationIds.find(
                ({ service }) => service === integrationService
              ) ?? {}
            ]
          }
        }
      } else {
        notifications.displayNotification('Failed to update customer', {
          type: 'error'
        })
      }
    },
    [saveCustomer, notifications]
  )

  // TODO: consider splitting out into logically separate modal hooks (e.g. all invoice status changes could live together still)
  const confirmationModals = useConfirmationModals({
    createCreditNoteFromInvoice,
    linkCustomerToIntegration,
    sendTestInvoiceToEmail,
    updateCreditNoteLineItems,
    updateInvoiceToSend,
    updateInvoiceToFinalise,
    updateInvoiceToRecalculate,
    updateInvoiceToVoid,
    updateInvoiceToSendAndFinalise,
    updateInvoiceToSendPaymentReminder,
    refetchInvoice,
    updateInvoiceToDraft
  })

  const updatePaymentStatus = useCallback(
    async (paymentStatus: InvoicePaymentStatus) => {
      notifications.displayNotification('Updating payment status...', {
        duration: 30000
      })
      const saveResult =
        await saveInvoice.updatePaymentStatus(invoiceId)(paymentStatus)

      if (saveResult.success && saveResult.invoice) {
        const updatedInvoiceAdapterData = invoiceEditorApiAdapter.in.invoice(
          // @ts-expect-error We need to update `updatePaymentStatus` to return `Invoice` by using the API package instead of rtk-query
          saveResult.invoice
        )

        notifications.displayNotification(
          `Invoice marked as ${toInvoicePaymentStatusText(
            updatedInvoiceAdapterData.paymentStatus
          ).toLowerCase()}`,
          {
            type: 'success',
            confetti: updatedInvoiceAdapterData.paymentStatus === 'PAID'
          }
        )

        return {
          invoice: {
            paymentStatus: updatedInvoiceAdapterData.paymentStatus
          }
        }
      } else {
        notifications.displayNotification('Failed to update payment status', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications]
  )

  const createLineItemGroup = useCallback(
    async (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      const lineItemGroupToCreate = reducerData.lineItemGroups[lineItemGroupId]

      if (!lineItemGroupToCreate) {
        return
      }

      const saveResult = await saveInvoice.createLineItemGroup(invoiceId)(
        invoiceEditorApiAdapter.out.lineItemGroup.create(lineItemGroupToCreate)
      )

      if (saveResult.success && saveResult.lineItemGroup) {
        const newState = updateReducerPayload(
          {
            totals: {},
            lineItemGroup: {
              ...saveResult.lineItemGroup,
              action: 'CREATE',
              temporaryId: lineItemGroupId
            }
          },
          reducerData
        )

        return { newState, saveResult }
      } else {
        return { saveResult }
      }
    },
    [invoiceId, saveInvoice]
  )

  const updateLineItemGroup = useCallback(
    async (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      const lineItemGroupToUpdate = reducerData.lineItemGroups[lineItemGroupId]

      if (!lineItemGroupToUpdate) {
        return
      }

      const lineItemGroupItems = Object.values(reducerData.lineItems).filter(
        lineItem => lineItem.groupId === lineItemGroupId
      )

      const adaptedLineItemGroup = {
        ...invoiceEditorApiAdapter.out.lineItemGroup.update(
          lineItemGroupToUpdate
        ),
        id: lineItemGroupId
      }

      const saveResult = await saveInvoice.updateLineItemGroup(invoiceId)(
        adaptedLineItemGroup,
        lineItemGroupItems.map(invoiceEditorApiAdapter.out.lineItem.update)
      )

      if (saveResult.success && saveResult.lineItemGroup) {
        const updatedTotalsResult = await updatedTotals(
          Object.values(saveResult.lineItems)
        )

        const newState = updateReducerPayload(
          {
            totals: updatedTotalsResult,
            lineItemGroup: {
              ...saveResult.lineItemGroup,
              action: 'EDIT',
              temporaryId: undefined
            }
          },
          reducerData
        )

        return { newState, saveResult }
      } else {
        return { saveResult }
      }
    },
    [invoiceId, saveInvoice]
  )

  const saveLineItemGroup = useCallback(
    async (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      const creating = lineItemGroupId.includes('new')

      notifications.displayNotification(
        `${creating ? 'Creating' : 'Updating'} line item group...`,
        {
          duration: 30000
        }
      )

      const lineItemGroup = reducerData.lineItemGroups[lineItemGroupId]

      if (!lineItemGroup) {
        notifications.displayNotification(
          `Failed to ${creating ? 'create' : 'update'} line item group`,
          {
            type: 'error'
          }
        )
        return
      }

      const result = creating
        ? await createLineItemGroup(lineItemGroupId, reducerData)
        : await updateLineItemGroup(lineItemGroupId, reducerData)

      if (result && result.saveResult.success && result.newState) {
        notifications.displayNotification(
          `Line item group ${creating ? 'created' : 'updated'}`,
          {
            type: 'success'
          }
        )

        return result.newState
      } else {
        notifications.displayNotification(
          `Failed to ${creating ? 'create' : 'update'} line item group`,
          {
            type: 'error'
          }
        )
      }
    },
    [createLineItemGroup, updateLineItemGroup, notifications]
  )

  const saveLineItem = useCallback(
    async (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      const creating = lineItemId.includes('new')

      notifications.displayNotification(
        `${creating ? 'Creating' : 'Updating'} line item...`,
        {
          duration: 30000
        }
      )

      const lineItem = reducerData.lineItems[lineItemId]

      if (!lineItem) {
        notifications.displayNotification(
          `Failed to ${creating ? 'create' : 'update'} line item`,
          {
            type: 'error'
          }
        )
        return
      }

      const lineItemGroup = reducerData.lineItemGroups[lineItemGroupId]

      // Line items inherit the tax category from their group
      const taxCategoryId = lineItemGroup.taxCategory?.id

      const saveResult = creating
        ? await saveInvoice.createLineItem(invoiceId)(
            lineItemGroupId,
            invoiceEditorApiAdapter.out.lineItem.create({
              ...lineItem,
              taxCategoryId
            })
          )
        : await saveInvoice.updateLineItem(invoiceId)(
            lineItemGroupId,
            invoiceEditorApiAdapter.out.lineItem.update({
              ...lineItem,
              taxCategoryId
            })
          )

      if (saveResult.success && saveResult.lineItem) {
        const updatedLineItems = {
          ...reducerData.lineItems,
          [saveResult.lineItem.id]: saveResult.lineItem
        }

        const lineItems = Object.values(updatedLineItems).filter(
          existingLineItem => !existingLineItem.id.includes('new')
        )

        const updatedTotalsResult = await updatedTotals(
          Object.values(lineItems)
        )
        const newState = updateReducerPayload(
          {
            totals: updatedTotalsResult,
            lineItem: {
              ...saveResult.lineItem,
              action: creating ? 'CREATE' : 'EDIT',
              temporaryId: creating ? lineItemId : undefined
            },
            lineItemGroup: reducerData.lineItemGroups[lineItemGroupId]
          },
          reducerData
        )

        notifications.displayNotification(
          `Line item ${creating ? 'created' : 'updated'}`,
          {
            type: 'success'
          }
        )

        return newState
      } else {
        notifications.displayNotification(
          `Failed to ${creating ? 'create' : 'update'} line item`,
          {
            type: 'error'
          }
        )
      }
    },
    [notifications, saveInvoice, invoiceId, updatedTotals]
  )

  const deleteLineItemGroup = useCallback(
    async (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      notifications.displayNotification('Deleting line item group...', {
        duration: 30000
      })

      const deleteResult =
        await saveInvoice.deleteLineItemGroup(invoiceId)(lineItemGroupId)

      if (deleteResult.success) {
        const lineItems = Object.values(reducerData.lineItems).filter(
          lineItem =>
            lineItem.groupId !== lineItemGroupId && !lineItem.id.includes('new')
        )
        const { [lineItemGroupId]: deletedGroup } = reducerData.lineItemGroups
        const newTotals = await updatedTotals(lineItems)
        const newState = updateReducerPayload(
          {
            totals: newTotals,
            lineItemGroup: {
              ...deletedGroup,
              action: 'DELETE'
            }
          },
          reducerData
        )

        notifications.displayNotification('Line item group deleted', {
          type: 'success'
        })

        return newState
      } else {
        notifications.displayNotification('Failed to delete line item group', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications, updatedTotals]
  )

  const deleteLineItem = useCallback(
    async (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      notifications.displayNotification('Deleting line item...', {
        duration: 30000
      })
      const deleteResult =
        await saveInvoice.deleteLineItem(invoiceId)(lineItemId)

      if (deleteResult.success) {
        const existingLineItemGroup =
          reducerData.lineItemGroups[lineItemGroupId]

        const { [lineItemId]: deletedLineItem, ...remainingLineItems } =
          reducerData.lineItems
        const newTotals = await updatedTotals(Object.values(remainingLineItems))
        const newState = updateReducerPayload(
          {
            totals: newTotals,
            lineItem: {
              ...deletedLineItem,
              action: 'DELETE'
            },
            lineItemGroup: existingLineItemGroup
          },
          reducerData
        )

        notifications.displayNotification('Line item deleted', {
          type: 'success'
        })

        return newState
      } else {
        notifications.displayNotification('Failed to delete line item', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications, updatedTotals]
  )

  const updateMemo = useCallback(
    async (
      reducerState: InvoiceEditorReducerState['data'],
      newMemo: InvoiceModel['memo']
    ) => {
      notifications.displayNotification('Updating memo...', {
        duration: 30000
      })

      const saveResult = await saveInvoice.updateMemo(invoiceId)(
        reducerState,
        newMemo
      )

      if (saveResult.success && typeof saveResult.memo !== 'undefined') {
        notifications.displayNotification('Memo updated', {
          type: 'success'
        })
        return {
          invoice: {
            memo: saveResult.memo
          }
        }
      } else {
        notifications.displayNotification('Failed to update memo', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications]
  )

  const updateDueDate = useCallback(
    async (
      reducerState: InvoiceEditorReducerState['data'],
      newDueDate: InvoiceModel['dueDate']
    ) => {
      notifications.displayNotification('Updating due date...', {
        duration: 30000
      })

      const saveResult = await saveInvoice.updateDueDate(invoiceId)(
        reducerState,
        newDueDate
      )

      if (saveResult.success && typeof saveResult.dueDate !== 'undefined') {
        notifications.displayNotification('Due date updated', {
          type: 'success'
        })
        return {
          invoice: {
            dueDate: saveResult.dueDate
          }
        }
      } else {
        notifications.displayNotification('Failed to update due date', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications]
  )

  const updatePurchaseOrderNumber = useCallback(
    async (
      reducerState: InvoiceEditorReducerState['data'],
      newPurchaseOrderNumber: InvoiceModel['purchaseOrderNumber']
    ) => {
      notifications.displayNotification('Updating purchase order number...', {
        duration: 30000
      })

      const saveResult = await saveInvoice.updatePurchaseOrderNumber(invoiceId)(
        reducerState,
        newPurchaseOrderNumber
      )

      if (
        saveResult.success &&
        typeof saveResult.purchaseOrderNumber !== 'undefined'
      ) {
        notifications.displayNotification('Purchase order number updated', {
          type: 'success'
        })
        return {
          invoice: {
            purchaseOrderNumber: saveResult.purchaseOrderNumber
          }
        }
      } else {
        notifications.displayNotification(
          'Failed to update purchase order number',
          {
            type: 'error'
          }
        )
      }
    },
    [invoiceId, saveInvoice, notifications]
  )

  const updateReference = useCallback(
    async (
      reducerState: InvoiceEditorReducerState['data'],
      newReference: InvoiceModel['reference']
    ) => {
      notifications.displayNotification('Updating reference...', {
        duration: 30000
      })

      const saveResult = await saveInvoice.updateReference(invoiceId)(
        reducerState,
        newReference
      )

      if (saveResult.success && typeof saveResult.reference !== 'undefined') {
        notifications.displayNotification('Reference updated', {
          type: 'success'
        })
        return {
          invoice: {
            reference: saveResult.reference
          }
        }
      } else {
        notifications.displayNotification('Failed to update reference', {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications]
  )

  const updateCustomer = useCallback(
    async (updatedCustomer: AdapterCustomerWithTaxModel) => {
      notifications.displayNotification('Updating customer...', {
        duration: 30000
      })

      const saveResult = await saveCustomer.update(updatedCustomer.id)(
        invoiceEditorApiAdapter.out.customer.updateWithTax(updatedCustomer)
      )

      if (
        saveResult.success &&
        saveResult.customer !== null &&
        typeof saveResult.customer !== 'undefined'
      ) {
        notifications.displayNotification('Customer updated', {
          type: 'success'
        })
        return {
          customer: {
            ...saveResult.customer,
            companyName: saveResult.customer.legalName
          },
          recipient: {
            customerLegalName: saveResult.customer.legalName,
            customerAddressFields: invoiceEditorApiAdapter.in.address(
              saveResult.customer.address
            ),
            customerEmails: saveResult.customer.contacts
              .filter(contact => !contact.billingPreference.includes('NONE'))
              .map(contact => contact.email)
          }
        }
      } else {
        notifications.displayNotification('Failed to update customer', {
          type: 'error'
        })
      }
    },
    [saveCustomer, notifications]
  )

  const showPaymentDetailsDrawer = useCallback(() => {
    setPaymentDetailsDrawerState({
      active: true,
      onClose: () => {
        setPaymentDetailsDrawerState({
          active: false,
          onClose: () => {}
        })
      }
    })
  }, [])

  const showInvoicePdfPreviewDrawer = useCallback(() => {
    setInvoicePdfPreviewDrawerState({
      active: true,
      onClose: () => {
        setInvoicePdfPreviewDrawerState({
          active: false,
          onClose: () => {}
        })
      }
    })
  }, [])

  const showEditCustomerDrawer = useCallback(() => {
    setEditCustomerDrawerState({
      active: true,
      onClose: () => {
        setEditCustomerDrawerState({
          active: false,
          onClose: () => {}
        })
      }
    })
  }, [])

  const syncInvoiceToIntegration = useCallback(
    async (
      integrationService: IntegrationService,
      existingLinkedServices: InvoiceEditorReducerState['data']['invoice']['linkedServices']
    ) => {
      const prettyIntegrationName = integrationName(integrationService)
      notifications.displayNotification(
        `Syncing invoice to ${prettyIntegrationName}`,
        {
          duration: 30000
        }
      )

      const saveResult =
        await saveInvoice.syncToIntegration(invoiceId)(integrationService)

      if (saveResult.success) {
        notifications.displayNotification(
          `Invoice synced to ${prettyIntegrationName}`,
          {
            type: 'success'
          }
        )

        return new Promise<RecursivePartial<InvoiceEditorReducerState['data']>>(
          resolve =>
            setTimeout(() => {
              dashboard20240730Client
                .getInvoice({ id: invoiceId })
                .then(result => {
                  if (result.data) {
                    const adapterInvoice = invoiceEditorApiAdapter.in.invoice(
                      result.data
                    )
                    resolve({
                      invoice: {
                        linkedServices: adapterInvoice.linkedServices
                      }
                    })
                  }

                  resolve({
                    invoice: {
                      linkedServices: [
                        ...existingLinkedServices,
                        {
                          externalId: `${integrationService}-${invoiceId}`,
                          externalService: integrationService,
                          syncTime: new Date().toISOString()
                        }
                      ]
                    }
                  })
                })
                .catch(e => Sentry.captureException(e))
            }, 2000)
        )
      } else {
        notifications.displayNotification(
          `Failed to sync invoice to ${prettyIntegrationName}`,
          {
            type: 'error'
          }
        )
      }
    },
    [invoiceId, notifications, saveInvoice]
  )

  const addPaymentCollection = useCallback(
    async (
      paymentProvider: PaymentProvider,
      existingPaymentOptions: InvoiceEditorReducerState['data']['invoice']['paymentOptions']
    ) => {
      const paymentProviderName = paymentProviderToLabel(paymentProvider)
      notifications.displayNotification(
        `Adding ${paymentProviderName} collection`,
        {
          duration: 30000
        }
      )

      const saveResult =
        await saveInvoice.createInvoiceSettings(invoiceId)(paymentProvider)

      if (saveResult.success) {
        notifications.displayNotification(
          `${paymentProviderName} collection added`,
          {
            type: 'success'
          }
        )

        return {
          invoice: {
            paymentOptions: [
              ...existingPaymentOptions,
              'LINK' as InvoicePaymentOption
            ]
          }
        }
      } else {
        notifications.displayNotification(
          `Failed to add ${paymentProviderName} collection`,
          {
            type: 'error'
          }
        )
      }
    },
    [invoiceId, notifications, saveInvoice]
  )

  const updateInvoice = useCallback(
    async (
      reducerState: InvoiceEditorReducerState['data'],
      payload: Partial<Pick<InvoiceModel, 'billingPeriod' | 'accountingDate'>>
    ) => {
      let translation = 'Invoice'

      if ('billingPeriod' in payload) {
        translation = 'Billing Period'
      } else if ('accountingDate' in payload) {
        translation = 'Invoice Date'
      }

      notifications.displayNotification(`Updating ${translation}...`, {
        duration: 30000
      })

      const saveResult = await saveInvoice.updateInvoice(invoiceId)(
        reducerState,
        payload
      )

      if (saveResult.success) {
        // theres a disconnect between the `billingPeriod` returned by the API vs `billingPeriod` we store in context
        const finalPayload =
          'billingPeriod' in payload
            ? {
                ...payload,
                billingPeriod:
                  payload?.billingPeriod?.start &&
                  payload?.billingPeriod?.endInclusive
                    ? formatDateRange({
                        from: new Date(payload.billingPeriod.start),
                        to: new Date(payload.billingPeriod.endInclusive)
                      })
                    : null
              }
            : payload

        notifications.displayNotification(`${translation} updated`, {
          type: 'success'
        })
        return {
          invoice: {
            ...(finalPayload as InvoiceEditorData['invoice'])
          }
        }
      } else {
        notifications.displayNotification(`Failed to update ${translation}`, {
          type: 'error'
        })
      }
    },
    [invoiceId, saveInvoice, notifications]
  )

  const invoiceEditor = useInvoiceEditor({
    onAddCollection: addPaymentCollection,
    onCreateCreditNote: confirmationModals.functions.createCreditNote,
    onUpdateCreditNoteLineItems:
      confirmationModals.functions.creditNoteLineItems,
    onCreateLineItemGroup: saveLineItemGroup,
    onCreateLineItem: saveLineItem,
    onDeleteLineItemGroup: deleteLineItemGroup,
    onDeleteLineItem: deleteLineItem,
    onFinaliseAndSendInvoice:
      confirmationModals.functions.sendAndFinaliseInvoice,
    onFinaliseInvoice: confirmationModals.functions.finaliseInvoice,
    onLinkCustomerToIntegration:
      confirmationModals.functions.createCustomerIntegrationLink,
    onRecalculateInvoice: confirmationModals.functions.recalculateInvoice,
    onSave: () => {},
    onSendInvoice: confirmationModals.functions.sendInvoice,
    onSendPaymentReminder: confirmationModals.functions.sendPaymentReminder,
    onSendTestInvoice: confirmationModals.functions.sendTestInvoice,
    onShowEditCustomerForm: showEditCustomerDrawer,
    onShowPaymentDetailsDrawer: showPaymentDetailsDrawer,
    onSyncInvoiceToIntegration: syncInvoiceToIntegration,
    onShowInvoicePdfPreviewDrawer: showInvoicePdfPreviewDrawer,
    onUpdateCustomer: updateCustomer,
    onUpdateLineItemGroup: saveLineItemGroup,
    onUpdateLineItem: saveLineItem,
    onUpdatePaymentStatus: updatePaymentStatus,
    onVoidInvoice: confirmationModals.functions.voidInvoice,
    onUpdateMemo: updateMemo,
    onUpdateDueDate: updateDueDate,
    onUpdatePurchaseOrderNumber: updatePurchaseOrderNumber,
    onUpdateReference: updateReference,
    onUpdateInvoice: updateInvoice,
    onConvertInvoiceToDraft: confirmationModals.functions.convertInvoiceToDraft
  })

  const { reloadInvoice, ...invoiceFromApi } = useLoadInvoiceEditor({
    invoiceId
  })
  const invoiceAdapterData = useMemo(() => {
    if (invoiceFromApi.loading || !invoiceFromApi.data) {
      return
    }

    return invoiceEditorApiAdapter.in.fullResponse(invoiceFromApi.data)
  }, [invoiceFromApi.data, invoiceFromApi.loading])

  useEffect(() => {
    if (!invoiceAdapterData) {
      return
    }

    const configuration = {
      features: {
        displaySubAccountUsage: true
      },
      warnings: []
    }

    if (
      invoiceEditor.functions.hasInitializationDataChanged({
        data: invoiceAdapterData,
        configuration
      })
    ) {
      invoiceEditor.functions.loadData({
        data: invoiceAdapterData,
        configuration
      })
    }
  }, [invoiceAdapterData, invoiceEditor.functions, flags])

  return {
    confirmCreateCreditNoteModal:
      confirmationModals.modalStates.confirmCreateCreditNoteModal,
    confirmFinaliseInvoiceModal:
      confirmationModals.modalStates.confirmFinaliseInvoiceModal,
    confirmRecalculateInvoiceModal:
      confirmationModals.modalStates.confirmRecalculateInvoiceModal,
    confirmSendAndFinaliseInvoiceModal:
      confirmationModals.modalStates.confirmSendAndFinaliseInvoiceModal,
    confirmSendInvoiceModal:
      confirmationModals.modalStates.confirmSendInvoiceModal,
    confirmSendPaymentReminderModal:
      confirmationModals.modalStates.confirmSendPaymentReminderModal,
    confirmSendTestInvoiceModal:
      confirmationModals.modalStates.confirmSendTestInvoiceModal,
    confirmVoidInvoiceModal:
      confirmationModals.modalStates.confirmVoidInvoiceModal,
    confirmDraftInvoiceModal:
      confirmationModals.modalStates.confirmDraftInvoiceModal,
    creditNoteLineItemsModal:
      confirmationModals.modalStates.confirmUpdateCreditNoteLineItemsModal,
    context: invoiceEditor,
    loading: !invoiceEditor.editor.loaded,
    errors: invoiceFromApi.errors,
    paymentDetailsDrawerState,
    editCustomerDrawerState,
    customerIntegrationLinkModal:
      confirmationModals.modalStates.customerIntegrationLinkModal,
    invoicePdfPreviewDrawerState,
    reloadInvoice,
    recalculateInvoice: updateInvoiceToRecalculate,
    warnings: invoiceEditor.derived.warnings
  }
}
