export const isMatchedFieldPath = ({
  fieldPath,
  pattern
}: { fieldPath: string; pattern: string }): boolean => {
  if (!pattern.includes('*')) {
    return fieldPath === pattern
  }

  const [prefix] = pattern.split('*')
  return fieldPath.startsWith(prefix)
}
