import { useCallback } from 'react'
import { LineItemGroupModel, LineItemModel } from '@sequencehq/core-models'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type ApiInvoice = DashboardApi20240730.GetInvoice.Invoice
type ApiMerchantDetails =
  DashboardApi20240730.GetInvoiceMerchantDetails.InvoiceMerchantDetails
type ApiUsageGroup =
  DashboardApi20240730.GetInvoiceUsageItemGroups.UsageItemGroup

type UseFetchDataForLocalPdfInvoice = () => {
  fetchData: ({
    invoiceId,
    customerId
  }: {
    invoiceId: string
    customerId: string
  }) => Promise<{
    invoice: ApiInvoice
    lineItems: LineItemModel[]
    lineItemGroups: LineItemGroupModel[]
    creditBalances: DashboardApi20240730.GetCreditGrantBalancesForCustomer.CreditGrantBalance[]
    merchantDetails: ApiMerchantDetails
    merchant: DashboardApi20240730.GetMerchantForSequenceAccount.Merchant
    subAccountUsageBreakdown: Array<ApiUsageGroup>
  }>
}

export const useFetchDataForLocalPdfInvoice: UseFetchDataForLocalPdfInvoice =
  () => {
    /**
     * Invoice
     */
    const getInvoice = useCallback(
      (args: { invoiceId: string }) =>
        dashboard20240730Client
          .getInvoice({ id: args.invoiceId })
          .then(response => response.data),
      []
    )

    /**
     * Line items
     */
    const getLineItems = useCallback(
      (args: { invoiceId: string }) =>
        dashboardv99990101Client
          .getAllLineItems({ invoiceId: args.invoiceId })
          .then(response => response.data?.items ?? []),
      []
    )

    /**
     * Line item groups
     */
    const getLineItemGroups = useCallback(
      (args: { invoiceId: string }) =>
        dashboardv99990101Client
          .getAllLineItemGroups({ invoiceId: args.invoiceId })
          .then(response => response.data?.items ?? []),
      []
    )

    /**
     * Merchant details
     */
    const getMerchantDetails = useCallback(
      (args: {
        currency: ApiInvoice['currency']
        country: ApiInvoice['customerBillingAddress']['country']
        state: ApiInvoice['customerBillingAddress']['state']
      }) =>
        dashboard20240730Client
          .getInvoiceMerchantDetails({
            currency: args.currency,
            country: args.country,
            state: args.state
          })
          .then(response => response.data),
      []
    )

    /**
     * Merchant
     */
    const getMerchant = useCallback(
      () =>
        dashboard20240730Client
          .getMerchantForSequenceAccount()
          .then(response => response.data),
      []
    )

    /**
     * Credit balances
     */
    const getCreditBalances = useCallback(
      (args: { customerId: string }) =>
        dashboard20240730Client
          .getCreditGrantBalancesForCustomer({ customerId: args.customerId })
          .then(response => response.data?.items ?? []),
      []
    )

    /**
     * Sub account usage data
     */

    const getSubAccountUsageData = useCallback(
      (args: { invoiceId: string }) => {
        return dashboard20240730Client
          .getInvoiceUsageItemGroups({ invoiceId: args.invoiceId })
          .then(response => response.data?.items ?? [])
      },
      []
    )

    /**
     * Fetch data
     */
    const fetchData = useCallback(
      async ({
        invoiceId,
        customerId
      }: {
        invoiceId: string
        customerId: string
      }) => {
        const fetchedInvoice = await getInvoice({ invoiceId })

        if (!fetchedInvoice) {
          throw new Error('fetchedInvoice is undefined')
        }

        const [
          fetchedLineItems,
          fetchedLineItemGroups,
          fetchedCreditBalances,
          fetchedMerchantDetails,
          fetchedMerchant,
          fetchedSubAccountUsageData
        ] = await Promise.all([
          getLineItems({ invoiceId }),
          getLineItemGroups({ invoiceId }),
          getCreditBalances({ customerId }),
          getMerchantDetails({
            currency: fetchedInvoice.currency,
            country: fetchedInvoice.customerBillingAddress.country,
            state: fetchedInvoice.customerBillingAddress.state
          }),
          getMerchant(),
          getSubAccountUsageData({ invoiceId })
        ])

        if (
          typeof fetchedInvoice === 'undefined' ||
          typeof fetchedLineItems === 'undefined' ||
          typeof fetchedLineItemGroups === 'undefined' ||
          typeof fetchedCreditBalances === 'undefined' ||
          !fetchedMerchantDetails ||
          !fetchedMerchant
        ) {
          throw new Error('Incomplete data to generate invoice PDF locally')
        }

        return {
          invoice: fetchedInvoice,
          lineItems: fetchedLineItems,
          lineItemGroups: fetchedLineItemGroups,
          creditBalances: fetchedCreditBalances,
          merchantDetails: fetchedMerchantDetails,
          merchant: fetchedMerchant,
          subAccountUsageBreakdown: fetchedSubAccountUsageData
        }
      },
      [
        getCreditBalances,
        getInvoice,
        getLineItemGroups,
        getLineItems,
        getMerchant,
        getMerchantDetails,
        getSubAccountUsageData
      ]
    )

    return { fetchData }
  }
