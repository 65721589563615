import { Flex, Text } from '@chakra-ui/react'
import {
  MagicTableCell,
  MagicTableCellBasic,
  MagicTableCellProductStatus,
  MagicTable,
  useMagicTableInfiniteQuery,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { KebabMenuItem } from '@sequencehq/core-components'
import Page from 'components/Page'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  BillingProduct,
  Product,
  ProductsView,
  PricingStructure
} from 'modules/Products/types'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import { isProductPriceGuard } from 'modules/Products/guards'
import { ProductsGraphs } from 'modules/Products/ProductGraphs/ProductsGraphs'
import { isDemoEnv } from 'lib/environment/environment'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { IndigoIndigo50, RedRed60 } from '@sequencehq/design-tokens'
import {
  KebabMenu,
  MenuItemBuilder,
  ModalContextProvider,
  Modal,
  Badge
} from '@sequencehq/core-components'
import { ArchiveProductModal } from 'Products/modals/ArchiveProduct.tsx'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CubeIcon as CubeIcon24 } from '@heroicons/react/24/solid'

export const deriveIsPricePercentage = (
  structure?: PricingStructure
): boolean => {
  if (!structure) {
    return false
  }

  if (structure.pricingType === 'VOLUME') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'GRADUATED') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'LINEAR') {
    return structure.isPricePercentage
  }

  return false
}

const priceStructureToUrlChunk = (structure: PricingStructure) => {
  switch (structure.pricingType) {
    case 'FIXED':
      return 'standard'
    case 'ONE_TIME':
      return 'standard'
    case 'LINEAR':
      return 'linear'
    case 'PACKAGE':
      return 'packaged'
    case 'VOLUME':
      return `volume${deriveIsPricePercentage(structure) ? '-percentage' : ''}`
    case 'GRADUATED':
      return `graduated${
        deriveIsPricePercentage(structure) ? '-percentage' : ''
      }`
    case 'SEAT_BASED':
      return 'seat'
  }
}

export const archiveProductBuilder: MenuItemBuilder<Product> = (
  product: Product,
  options = {}
) => {
  return {
    status: 'LIVE',
    action: () => {},
    label: (
      <ModalContextProvider>
        <Modal.Trigger>
          {triggerModal => {
            return (
              <KebabMenuItem>
                <Flex
                  flexDirection="row"
                  align="center"
                  gap={2}
                  width="100%"
                  onClick={triggerModal}
                  data-testid="kebab-menu-archive-button"
                >
                  <Text color={RedRed60}>Archive product</Text>
                </Flex>
              </KebabMenuItem>
            )
          }}
        </Modal.Trigger>
        <ArchiveProductModal product={product} onSuccess={options.onSuccess} />
      </ModalContextProvider>
    )
  }
}

export const Products = () => {
  const flags = useFlags()
  const navigate = useNavigate()

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getBillingProducts
  )

  const reload = () => {
    void infiniteQuery.refetch()
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Products"
          addNewConfig={{
            href: '/products/new',
            buttonText: 'New product'
          }}
          paddingBottom={0}
        >
          <CurrentUserId>
            {userId => (
              <>
                {isDemoEnv() && <ProductsGraphs />}
                <MagicTable<ProductsView>
                  entityNamePlural="products"
                  infiniteQuery={infiniteQuery}
                  sequenceUserId={userId}
                  rowPath={(row: BillingProduct, isSubRow: boolean) => {
                    if (isSubRow && isProductPriceGuard(row)) {
                      return `/products/${
                        row.productId
                      }/price/${priceStructureToUrlChunk(row.structure)}/${
                        row.id
                      }`
                    } else {
                      return `/products/${row.id}`
                    }
                  }}
                  columns={[
                    {
                      id: 'name',
                      header: 'Product',
                      accessorKey: 'name',
                      cell: row => {
                        return (
                          <MagicTableCell
                            text={row.getValue<string>()}
                            textColor={IndigoIndigo50}
                          />
                        )
                      }
                    },
                    ...(flags.showNewTaxRatesSettings
                      ? [
                          {
                            id: 'taxCategory',
                            header: 'Tax category',
                            accessorKey: 'taxCategoryName',
                            cell: (row: { getValue: () => any }) => {
                              const value = row.getValue()
                              return (
                                <MagicTableCellBasic>
                                  {value ? (
                                    <Badge textTransform="none">{value}</Badge>
                                  ) : null}
                                </MagicTableCellBasic>
                              )
                            }
                          }
                        ]
                      : []),
                    {
                      id: 'status',
                      header: 'Status',
                      cell: () => {
                        //TODO: get the product status from the row
                        return (
                          <MagicTableCellProductStatus productStatus="ACTIVE" />
                        )
                      }
                    },
                    {
                      id: 'priceVariants',
                      header: 'Price Variants',
                      accessorKey: 'prices',
                      cell: row => {
                        const value = row.getValue<BillingProduct['prices']>()
                        return <MagicTableCell text={value.length || '—'} />
                      }
                    },
                    {
                      id: 'createdAt',
                      header: 'Created',
                      accessorKey: 'createdAt',
                      cell: row => (
                        <div data-testid="price-list-created-at-row">
                          <MagicTableCell
                            text={dateTimeWithFormat(
                              row.getValue<BillingProduct['createdAt']>(),
                              'd MMM, yyyy'
                            )}
                          />
                        </div>
                      )
                    },
                    {
                      id: 'updatedAt',
                      header: 'Updated',
                      accessorKey: 'updatedAt',
                      cell: row => (
                        <div data-testid="price-list-updated-at-row">
                          <MagicTableCell
                            text={dateTimeWithFormat(
                              row.getValue<BillingProduct['updatedAt']>(),
                              'd MMM, yyyy'
                            )}
                          />
                        </div>
                      )
                    }
                  ]}
                  emptyStateUnfiltered={entityNamePlural => (
                    <MagicTableEmptyState
                      entityNamePlural={entityNamePlural}
                      imageSrc="/img/empty/products.png"
                      learnMoreText="Manage products and prices with the product catalog"
                      learnMoreHref="https://docs.sequencehq.com/plans-products/product-catalogue"
                      buttonNew={{
                        label: 'New product',
                        onClick: () => navigate('/products/new')
                      }}
                    />
                  )}
                  emptyStateFiltered={(entityNamePlural, clearFilters) => (
                    <MagicTableFilterEmptyState
                      entityNamePlural={entityNamePlural}
                      entityIcon={CubeIcon24}
                      actionClearFilters={clearFilters}
                      actionViewAll={() => navigate('/products')}
                      variant="TABLE"
                    />
                  )}
                  kebabMenu={(model: Product, props) => (
                    <div data-testid="price-list-kebab-menu">
                      <KebabMenu
                        menuItems={[archiveProductBuilder].map(builder =>
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          builder(model, { onSuccess: reload })
                        )}
                        flags={flags}
                        {...props}
                      />
                    </div>
                  )}
                />
              </>
            )}
          </CurrentUserId>
          <Outlet />
        </Page>
      </Flex>
    </Flex>
  )
}
