import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  CoverImageBlock,
  CoverImageBlockV2,
  QuoteBlock
} from '@sequencehq/quote-content'
import { useGetSignedAssetUrl } from '@sequencehq/utils'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'

const useSelectPresentation = () => {
  const cubeContext = useCubeContext()
  return cubeContext.queries.rawData.data.presentation
}

const useSelectCoverImageBlock = () => {
  const coverImageBlock = useSelectPresentation().blocks.find(
    block => block.blockType === 'coverImage'
  )

  if (!coverImageBlock) {
    return undefined
  }

  if (
    !isCoverImageBlockV2(coverImageBlock) &&
    !isCoverImageBlock(coverImageBlock)
  ) {
    return undefined
  }

  return coverImageBlock
}

export const useSelectSignedCoverImageSrc = () => {
  const coverImageBlockSrc = useSelectCoverImageSrc()

  const signedUrl = useGetSignedAssetUrl({
    url: coverImageBlockSrc ?? '',
    assetLoader: async ({ assetId }) => {
      const res = await dashboard20240730Client.getAsset({ id: assetId })

      if (!res.data || res.error) {
        return undefined
      }

      return { urlSigned: res.data.url }
    }
  })

  return signedUrl
}

export const useSelectCoverImageSrc = () => {
  const coverImageBlock = useSelectCoverImageBlock()

  if (!coverImageBlock) {
    return undefined
  }

  if (isCoverImageBlockV2(coverImageBlock)) {
    return coverImageBlock.config.assetPath
  }

  if (isCoverImageBlock(coverImageBlock)) {
    return coverImageBlock.config.asset.url
  }

  return undefined
}

export const useStoreCoverImageAsset = () => {
  const cubeContext = useCubeContext()

  const presentation = useSelectPresentation()
  const existingBlock = useSelectCoverImageBlock()

  return (assetPath: string | null) => {
    let newPresentation = { ...presentation }

    if (assetPath === null) {
      if (existingBlock) {
        cubeContext.mutators.updateData({
          presentation: {
            ...presentation,
            blocks: presentation.blocks.filter(
              block => block.blockType !== 'coverImage'
            )
          }
        })
      }
      return
    }

    const newCoverImageBlock: QuoteBlock = {
      blockType: 'coverImage',
      version: 2,
      config: {
        assetPath
      }
    }

    if (existingBlock) {
      newPresentation = {
        ...presentation,
        blocks: presentation.blocks.map(block =>
          block.blockType === 'coverImage' ? newCoverImageBlock : block
        )
      }
    } else {
      newPresentation = {
        ...presentation,
        blocks: [newCoverImageBlock, ...presentation.blocks]
      }
    }

    cubeContext.mutators.updateData({
      presentation: newPresentation
    })
  }
}

const isCoverImageBlock = (block: QuoteBlock): block is CoverImageBlock =>
  block.blockType === 'coverImage' && block.version === 1

const isCoverImageBlockV2 = (block: QuoteBlock): block is CoverImageBlockV2 =>
  block.blockType === 'coverImage' && block.version === 2
