import {
  apiPackageAdapter99990101,
  createFormModal
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { UpdateCreditNoteStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import FinalizeCreditNoteForm from 'components/UpdateInvoiceStatus/FinalizeCreditNoteForm'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { QueryParams } from 'lib/types'

const FinalizeCreditNoteModal = createFormModal<
  QueryParams,
  UpdateCreditNoteStatusFormProps
>({
  FormComponent: FinalizeCreditNoteForm,
  useMutation: apiPackageAdapter99990101<
    UpdateCreditNoteStatusFormProps,
    typeof dashboardv99990101Client.postFinalizeCreditNote
  >('postFinalizeCreditNote', ({ id }) => ({ id })),
  modalTitle: 'Finalize Credit Note',
  successMessage: 'Credit Note finalized',
  submitTitle: 'Finalize Credit Note',
  formId: 'finalizeCreditNoteForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Credit Note ${
        response.creditNoteNumber ? response.creditNoteNumber : ''
      } finalized`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue',
  errorText: 'Failed to finalize credit note'
})

export default FinalizeCreditNoteModal
