import { Button, Link } from '@chakra-ui/react'
import { IntegrationService } from '@sequencehq/core-models'
import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import { SelectImportCustomersForm } from 'components/ImportCustomers/SelectImportCustomersForm'
import { Toast } from '@sequencehq/core-components'
import { openOverlay } from 'features/overlay'
import { IntegrationMoreInfo } from 'lib/docsLink'
import { QueryParams } from 'lib/types'
import { integrationName } from 'lib/integrations/integrationName'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export interface SelectImportCustomersProps {
  service: IntegrationService
}

export const SelectImportCustomersModal = createFormModal<
  QueryParams,
  SelectImportCustomersProps
>({
  FormComponent: SelectImportCustomersForm,
  useMutation: apiPackageAdapter20240730<
    {
      service: IntegrationService
      customerIds: string[]
    },
    typeof dashboard20240730Client.postCreateCustomerFromExternalService
  >('postCreateCustomerFromExternalService', params => params),
  modalTitleFromProps: (props: SelectImportCustomersProps) =>
    `${integrationName(props.service)} customers`,
  previousModal: dispatch => {
    dispatch(
      openOverlay({
        content: 'importCustomersModal'
      })
    )
  },
  successMessage: 'Customers imported',
  submitTitle: 'Import',
  formId: 'selectImportCustomersForm',
  successToast: response => (
    <Toast
      title="Customer import successful"
      type="success"
      description={`${response.count} customer${
        response.count === 1 ? '' : 's'
      } ${response.count === 1 ? 'has' : 'have'} been imported`}
      isClosable={true}
    ></Toast>
  ),
  leftButton: (
    <Button
      as={Link}
      href={IntegrationMoreInfo.AccountingOverviewLink}
      variant="ghost"
      width="134px"
      isExternal
    >
      More info
    </Button>
  )
})
