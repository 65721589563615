import { FC } from 'react'
import { LinkSalesforceOpportunityModal } from './LinkSalesforceOpportunityModal'
import { UnlinkSalesforceOpportunityModal } from './UnlinkSalesforceOpportunityModal'
import { LinkEntityWidget } from 'Integrations/integrationsConfig/common/LinkEntities/LinkEntityWidget'

export const LinkSalesforceOpportunityWidget: FC<{
  opportunityId?: string
  opportunityHref?: string
  sequenceEntityLabel: string
  canUnlink?: boolean
  canLink: boolean
  onLink: (args: { opportunityId: string }) => void
  onUnlink: () => void
}> = props => {
  return (
    <LinkEntityWidget
      data-testid={`salesforce.linkOpportunity`}
      sequenceEntityLabel={props.sequenceEntityLabel}
      externalEntityLabel="opportunity"
      serviceName="Salesforce"
      isLoaded
      canLink={props.canLink}
      linkedAccount={
        props.opportunityId
          ? {
              label: props.opportunityId ? `ID: ${props.opportunityId}` : '',
              canUnlink: props.canUnlink ?? true,
              href: props.opportunityHref,
              isPending: false
            }
          : null
      }
      linkModal={<LinkSalesforceOpportunityModal onLink={props.onLink} />}
      unlinkModal={
        <UnlinkSalesforceOpportunityModal onUnlink={props.onUnlink} />
      }
    />
  )
}
