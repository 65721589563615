import { PriceModel } from '@sequencehq/core-models'
import { useMemo, Fragment } from 'react'
import { match, P } from 'ts-pattern'
import { priceTierRowFormatter } from 'modules/Products/formatters'
import { Flex, Text, Grid } from '@chakra-ui/react'
import {
  GreyGrey100,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { ListPrice, PricingTier } from 'modules/Products/types'

type ProductPriceTiersTableProps = {
  price: PriceModel | ListPrice
}

// Adapter to transform SeatBasedPricingTier into a format compatible with priceTierRowFormatter
const adaptSeatBasedTier = (tier: {
  price: string
  upperBound?: string
  fee?: string
}): PricingTier => ({
  price: tier.price,
  upperBound: tier.upperBound,
  fee: tier.fee,
  isPricePercentage: false // Seat-based tiers are never percentage-based
})

export const ProductPriceTiersTable = ({
  price
}: ProductPriceTiersTableProps) => {
  const formattedTiers = useMemo(
    () =>
      match(price)
        .with(
          { structure: { pricingType: P.union('VOLUME', 'GRADUATED') } },
          matchedPrice =>
            matchedPrice.structure.tiers.map((tier, index, tiers) =>
              priceTierRowFormatter(tier, index, tiers, price)
            )
        )
        .with(
          {
            structure: {
              pricingType: 'SEAT_BASED',
              tiers: P.array(P.not(P.nullish))
            }
          },
          matchedPrice => {
            // Use our adapter to make seat-based tiers compatible with priceTierRowFormatter
            const adaptedTiers =
              matchedPrice.structure.tiers.map(adaptSeatBasedTier)
            return adaptedTiers.map((tier, index, tiers) =>
              priceTierRowFormatter(tier, index, tiers, price)
            )
          }
        )
        .otherwise(() => []),
    [price]
  )

  const isSeatBasedPricing = price.structure.pricingType === 'SEAT_BASED'

  if (!formattedTiers.length) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={4} mt={6}>
      <Text {...Lato14Bold} color={GreyGrey100}>
        Tiers
      </Text>

      <Grid templateColumns="160px 1fr" gap={3}>
        {formattedTiers.map((tier, index) => {
          return (
            <Fragment key={index}>
              <Text {...Lato13Regular} color={GreyGrey70}>
                From {tier.firstUnit}{' '}
                {tier.lastUnit === '∞' ? ' and more' : `to ${tier.lastUnit}`}
              </Text>

              <Text {...Lato13Regular} color={GreyGrey90}>
                {tier.perUnit}
                {isSeatBasedPricing ? ' per seat' : ''}{' '}
                {tier.flatFee ? `+ ${tier.flatFee}` : ''}
              </Text>
            </Fragment>
          )
        })}
      </Grid>
    </Flex>
  )
}
