import FinalizeAndSendInvoiceForm from 'components/UpdateInvoiceStatus/FinalizeAndSendInvoiceForm'
import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { QueryParams } from 'lib/types'
import { UpdateAndSendInvoiceStatusFormProps } from './types'

const FinalizeAndSendInvoiceModal = createFormModal<
  QueryParams,
  UpdateAndSendInvoiceStatusFormProps
>({
  FormComponent: FinalizeAndSendInvoiceForm,
  useMutation: apiPackageAdapter20240730<
    {
      id: string
    },
    typeof dashboard20240730Client.postFinalizeAndSendInvoice
  >('postFinalizeAndSendInvoice', ({ id }) => ({ id })),
  modalTitle: 'Finalize and Send Invoice',
  successMessage: 'Invoice sent',
  submitTitle: 'Finalize and Send',
  formId: 'finalizeAndSendInvoiceForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Invoice ${
        response.invoiceNumber ? response.invoiceNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue',
  errorText: 'Failed to send invoice'
})

export default FinalizeAndSendInvoiceModal
