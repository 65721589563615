import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey30,
  GreyGrey70,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { QuoteTemplateItem } from './QuoteTemplateItem'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import templateListBackground from './templateListBackground.png'

const useQuoteTemplatesList = () => {
  const templatesListQuery = useQuery(
    dashboard20240730Client.getListQuoteTemplates,
    undefined,
    {
      select: res => {
        if (!res?.items) {
          return []
        }

        return res.items
          .map(template => ({
            id: template.id,
            title: template.title
          }))
          .sort((a, b) => {
            if (!a.title || !b.title) return 0
            return a.title < b.title ? -1 : 1
          })
      }
    }
  )

  return {
    isLoading: templatesListQuery.isPending,
    quoteTemplates: templatesListQuery.data ?? []
  }
}

export const QuoteTemplatesList = () => {
  const { isLoading, quoteTemplates } = useQuoteTemplatesList()

  if (isLoading || quoteTemplates.length === 0) {
    return null
  }

  return (
    <Box
      data-testid="quoteTemplates.list"
      width="100%"
      position="relative"
      maxWidth={`calc(100vw - 206px)`}
      borderBottom={`1px solid ${GreyGrey30}`}
      overflow="hidden"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `url(${templateListBackground}) ${GreyGrey20} repeat`,
        backgroundSize: '500px 500px',
        opacity: 0.15,
        zIndex: 0
      }}
    >
      <Box
        color={GreyGrey70}
        {...Lato14Bold}
        padding="16px"
        position="relative"
      >
        Create new quote from template
      </Box>
      <Box
        overflowX="auto"
        overflowY="hidden"
        padding="16px"
        paddingTop="0"
        position="relative"
        width="100%"
      >
        <Flex gap="24px" width="fit-content">
          {quoteTemplates.map(quoteTemplate => (
            <QuoteTemplateItem
              key={quoteTemplate.id}
              quoteTemplateId={quoteTemplate.id}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
