import { useEffect, useState } from 'react'
import { PdfViewer, PdfViewerHeightWorkaround } from '@sequencehq/pdf-viewer'
import { useContractExtractionAnnotations } from 'ContractExtraction/index'

type Props = {
  assetId: string
  url: string
  fileName: string
}

export const AttachmentPdfPreview = ({ assetId, url, fileName }: Props) => {
  const [data, setData] = useState<Blob | undefined>()

  const { annotations, visibleAnnotationIds } =
    useContractExtractionAnnotations({ assetId })

  useEffect(() => {
    const fetchUrl = async () => {
      const res = await fetch(url)
      const blob = await res.blob()
      const pdfBlob = blob.slice(0, blob.size, 'application/pdf')
      setData(pdfBlob)
    }

    void fetchUrl()
  }, [url])

  if (!data) {
    return null
  }

  return (
    <PdfViewerHeightWorkaround>
      <PdfViewer data={data} fileName={fileName}>
        <PdfViewer.StandardControls disabled={!!visibleAnnotationIds?.length} />

        <PdfViewer.Annotations visibleAnnotationIds={visibleAnnotationIds}>
          {annotations.map(annotation => (
            <PdfViewer.Annotation key={annotation.id} {...annotation}>
              <PdfViewer.Annotation.Highlighter />
            </PdfViewer.Annotation>
          ))}
        </PdfViewer.Annotations>
      </PdfViewer>
    </PdfViewerHeightWorkaround>
  )
}
