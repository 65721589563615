import { Box, Grid, GridItem } from '@chakra-ui/react'
import { BookmarkIcon } from '@heroicons/react/16/solid'
import { NavLinkButton } from 'components/NavigationV3/components/NavLinks/NavLink'
import { NavSection } from 'components/NavigationV3/components/NavSection'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation, Link } from 'react-router-dom'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { NavLinksLoading } from 'components/NavigationV3/components/NavLinks/NavLinksLoading'
import { savedViewsPorts } from 'SavedViews/savedViews.ports'
import { SAVED_VIEWS_STALE_TIME } from 'SavedViews/savedViews.constants'
import {
  SequenceKebabMenu,
  SequenceMenuItem,
  TextFieldInput,
  SequenceMenuItemProps
} from '@sequencehq/core-components'
import { FC, KeyboardEvent, useState } from 'react'
import { DeleteSavedViewDialog } from './DeleteSavedViewDialog'
import { useNotifications } from 'lib/hooks/useNotifications'
import { SavedView } from '../savedViews.types'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { NavTooltip } from 'components/NavigationV3/components/NavTooltip'
import { NAVIGATION_ANIMATION } from 'components/NavigationV3/constants/animation'

interface EditableSavedViewProps {
  view: SavedView
  isEditing: boolean
  isHovered: boolean
  isActive: boolean
  editingName: string
  onEditingNameChange: (name: string) => void
  onStartEdit: () => void
  onSave: () => void
  onCancel: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  isCollapsed?: boolean
}

const EditableSavedView: FC<EditableSavedViewProps> = ({
  view,
  isEditing,
  isHovered,
  isActive,
  editingName,
  onEditingNameChange,
  onStartEdit,
  onSave,
  onCancel,
  onMouseEnter,
  onMouseLeave,
  isCollapsed = false
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      void onSave()
    } else if (e.key === 'Escape') {
      onCancel()
    }
  }

  const getKebabMenuItems = () => [
    (props: Partial<SequenceMenuItemProps>) => (
      <SequenceMenuItem
        key="edit-name"
        uuid="edit-name"
        label="Edit name"
        onClick={() => {
          onStartEdit()
          props.setIsOpen?.(false)
        }}
      />
    ),
    (props: Partial<SequenceMenuItemProps>) => (
      <DeleteSavedViewDialog
        key="delete-saved-view"
        savedViewId={view.id}
        onCancel={() => props.setIsOpen?.(false)}
      />
    )
  ]

  const navLink = (
    <Box
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isEditing ? (
        <TextFieldInput
          value={editingName}
          onChange={e => onEditingNameChange(e.target.value)}
          autoFocus
          onKeyDown={handleKeyDown}
          onBlur={onSave}
          maxLength={64}
          placeholder="Name"
        />
      ) : (
        <Box position="relative">
          <Grid
            as={Link}
            to={`saved-view/${view.id}`}
            padding="0 8px"
            templateColumns={isCollapsed ? 'auto' : 'auto 1fr auto'}
            height="32px"
            gap="8px"
            borderRadius="6px"
            alignContent="center"
            alignItems="center"
            justifyContent={'flex-start'}
            cursor="pointer"
            color={isActive ? GreyGrey90 : GreyGrey70}
            backgroundColor={isActive ? GreyGrey20 : 'transparent'}
            style={{
              willChange: 'width, transform',
              transform: 'translateZ(0)',
              transition: 'background-color 0.2s ease'
            }}
            _hover={{
              backgroundColor: GreyGrey20
            }}
          >
            <GridItem
              height="fit-content"
              display="flex"
              alignContent="center"
              justifyContent={'flex-start'}
              flexShrink={0}
              alignSelf="center"
              style={{
                willChange: 'transform',
                transform: 'translateZ(0)',
                transition: 'transform 0.3s ease'
              }}
            >
              <BookmarkIcon width="16px" height="16px" color="inherit" />
            </GridItem>
            {!isCollapsed && (
              <GridItem
                {...Lato13Bold}
                color="inherit"
                overflow="hidden"
                textOverflow="ellipsis"
                className="nav-text"
              >
                {view.name}
              </GridItem>
            )}
          </Grid>
          {isHovered && !isCollapsed && (
            <Box
              position="absolute"
              right="8px"
              top="50%"
              transform="translateY(-50%)"
              zIndex={1}
            >
              <SequenceKebabMenu
                variant="ghost"
                buttonSize="small"
                items={getKebabMenuItems()}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )

  if (isCollapsed) {
    return (
      <NavTooltip label={view.name} isDisabled={!isCollapsed}>
        {navLink}
      </NavTooltip>
    )
  }

  return navLink
}

interface SavedViewsNavigationProps {
  isCollapsed?: boolean
}

export const SavedViewsNavigation: FC<SavedViewsNavigationProps> = ({
  isCollapsed = false
}) => {
  const { pathname } = useLocation()
  const flags = useFlags()
  const { displayNotification } = useNotifications()
  const [editingViewId, setEditingViewId] = useState<string | null>(null)
  const [editingName, setEditingName] = useState('')
  const [hoveredViewId, setHoveredViewId] = useState<string | null>(null)

  const { data: savedViews, isLoading } = useQuery(
    dashboard20240730Client.getSavedViews,
    undefined,
    {
      select: data => {
        if (!data) {
          return null
        }

        return savedViewsPorts.savedViews.fromServer(data)
      },
      staleTime: SAVED_VIEWS_STALE_TIME
    }
  )

  const putSavedViewMutator = useMutation(dashboard20240730Client.putSavedView)

  const handleEditName = async () => {
    if (!editingViewId || !editingName.trim()) {
      setEditingViewId(null)
      return
    }

    const savedView = savedViews?.find(view => view.id === editingViewId)
    if (!savedView) {
      setEditingViewId(null)
      return
    }

    const resPutSavedView = await putSavedViewMutator.mutateAsync({
      id: savedView.id,
      body: savedViewsPorts.savedView.toServer({
        name: editingName,
        entityType: savedView.entityType,
        activeFilters: savedView.activeFilters
      })
    })

    if (!resPutSavedView) {
      displayNotification('Failed to update saved view', { type: 'error' })
      return
    }

    displayNotification('Saved view updated', { type: 'success' })
    setEditingViewId(null)
  }

  if (!flags.useSavedViews) {
    return null
  }

  if (!isLoading && !savedViews?.length) {
    return null
  }

  return (
    <Box padding="8px 8px" userSelect="none">
      <NavSection
        id="saved-views"
        title="Saved views"
        expandOnMatchingPath={pathname => pathname.startsWith('/saved-view/')}
        isCollapsed={isCollapsed}
      >
        {isLoading ? (
          <NavLinksLoading />
        ) : (
          savedViews?.map(view => (
            <EditableSavedView
              key={view.id}
              view={view}
              isEditing={editingViewId === view.id}
              isHovered={hoveredViewId === view.id}
              isActive={pathname.startsWith(`/saved-view/${view.id}`)}
              editingName={editingName}
              onEditingNameChange={setEditingName}
              onStartEdit={() => {
                setEditingViewId(view.id)
                setEditingName(view.name)
              }}
              onSave={() => void handleEditName()}
              onCancel={() => setEditingViewId(null)}
              onMouseEnter={() => setHoveredViewId(view.id)}
              onMouseLeave={() => setHoveredViewId(null)}
              isCollapsed={isCollapsed}
            />
          ))
        )}
      </NavSection>
    </Box>
  )
}
