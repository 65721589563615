import { CustomerContactBillingPreference } from 'CustomerContacts/domain/CustomerContacts.domain.types'
import startCase from 'lodash/startCase'

export function contactFromEmail(
  email: string,
  billingPreference: CustomerContactBillingPreference = 'STANDARD'
) {
  return {
    name: emailToName(email),
    email,
    billingPreference,
    // These will be stripped out before sending to the API (by toContactBody).
    // However we need the props in the interim for type compatibility with the form API.
    id: 'new',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }
}

// It would typically make sense for somewhere using this to be co-located with this component,
// but we're going to backfill this value into contacts where `name` is missing, so this is a temp solution.
export function emailToName(email: string) {
  return startCase(email.split('@')[0].replaceAll('.', ' '))
}
