import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api'

export type BillingScheduleList =
  DashboardApi20240730.GetBillingSchedules.BillingScheduleList
export type BillingSchedule =
  DashboardApi20240730.GetBillingSchedule.BillingSchedule

export const useFetchBillingSchedules = ({
  customerId
}: {
  customerId: string
}) => {
  const billingSchedulesResponse = useQuery(
    dashboard20240730Client.getBillingSchedules,
    {
      customerId: customerId,
      limit: 12
    },
    {
      select: res => {
        if (!res?.items) {
          return
        }

        return res.items
      }
    }
  )

  return {
    billingSchedules: billingSchedulesResponse.data ?? [],
    isLoading: billingSchedulesResponse.isLoading
  }
}
