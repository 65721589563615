import { useEffect, useMemo } from 'react'

import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useSequenceInfiniteQuery } from '@sequencehq/api'
import { HOURS } from '@sequencehq/utils/dates'

type Alias = {
  value: string
  customerId: string
  id: string
  sequenceAccountId: string
  createdAt: string
}
type UseCustomerNamesByAliasReturn = {
  isLoading: boolean
  customersWithAliases?: CustomerWithAliases[]
  aliases?: Array<Alias>
}

export type CustomerWithAliases = {
  id: string
  customerName: string
  aliases: string[]
}

export const useLoadAllCustomersWithAliases =
  (): UseCustomerNamesByAliasReturn => {
    const customersInfiniteQuery = useSequenceInfiniteQuery(
      dashboard20240730Client.getCustomers,
      {
        limit: 100
      },
      {
        staleTime: 1 * HOURS,
        maxPages: 30
      }
    )

    const customerAliasesInfiniteQuery = useSequenceInfiniteQuery(
      dashboard20240730Client.getCustomerAliases,
      {
        limit: 100
      },
      {
        staleTime: 1 * HOURS,
        maxPages: 30
      }
    )

    /**
     * Handle the refetching of all the customer data
     */
    useEffect(() => {
      if (
        customersInfiniteQuery.infiniteQuery.hasNextPage &&
        !customersInfiniteQuery.infiniteQuery.isPending
      ) {
        customersInfiniteQuery.infiniteQuery.fetchNextPage()
      }
    }, [
      customersInfiniteQuery.infiniteQuery.hasNextPage,
      customersInfiniteQuery.infiniteQuery.isPending,
      customersInfiniteQuery.infiniteQuery.fetchNextPage,
      /** Needed to trigger the useEffect and exhaust the entire set of aliases */
      customersInfiniteQuery.infiniteQuery.data?.pages.length
    ])

    /**
     * Handle the refetching of all the customer alias data
     */
    useEffect(() => {
      if (
        customerAliasesInfiniteQuery.infiniteQuery.hasNextPage &&
        !customerAliasesInfiniteQuery.infiniteQuery.isPending
      ) {
        customerAliasesInfiniteQuery.infiniteQuery.fetchNextPage()
      }
    }, [
      customerAliasesInfiniteQuery.infiniteQuery.hasNextPage,
      customerAliasesInfiniteQuery.infiniteQuery.isPending,
      customerAliasesInfiniteQuery.infiniteQuery.fetchNextPage,
      /** Needed to trigger the useEffect and exhaust the entire set of aliases */
      customerAliasesInfiniteQuery.infiniteQuery.data?.pages.length
    ])

    const allCustomers = useMemo(() => {
      return (
        customersInfiniteQuery.infiniteQuery.data?.pages.flatMap(
          page => page.items
        ) ?? []
      )
    }, [customersInfiniteQuery])
    const allAliases = useMemo(() => {
      return (
        customerAliasesInfiniteQuery.infiniteQuery.data?.pages.flatMap(
          page => page.items
        ) ?? []
      )
    }, [customerAliasesInfiniteQuery])

    const customersWithAliases = useMemo(() => {
      return allCustomers.reduce((acc: CustomerWithAliases[], customer) => {
        const aliasesForCustomer = allAliases.filter(
          alias => alias.customerId === customer.id
        )

        return [
          ...acc,
          {
            customerName: customer.legalName,
            id: customer.id,
            aliases: [
              customer.id,
              ...aliasesForCustomer.map(alias => alias.value)
            ]
          }
        ]
      }, [])
    }, [allCustomers, allAliases])

    return {
      isLoading:
        customersInfiniteQuery.infiniteQuery.isPending ||
        customerAliasesInfiniteQuery.infiniteQuery.isPending,
      customersWithAliases,
      aliases: allAliases
    }
  }
