import { StandardLayout, Totals } from '@sequencehq/core-components'
import {
  CreditNoteDetailsHeader,
  CreditNoteDetailsSummaryCard
} from 'CreditNotes/components'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { CreditNoteLineItems } from 'CreditNotes/components/CreditNoteLineItems'
import invariant from 'tiny-invariant'
import { Currency } from '@sequencehq/core-models'
import { CreditNoteAdjustmentTotal } from 'CreditNotes/components/CreditNoteAdjustmentTotal'
import { useCreditNoteEditing } from 'CreditNotes/hooks/useCreditNoteEditing'
import { CreditNoteInspector } from 'CreditNotes/components/Inspector/CreditNoteInspector'
import { CreditNoteIntegrationWarningBanners } from 'CreditNotes/components/CreditNoteIntegrationWarningBanners'

export const CreditNoteDetailsPage: FC = () => {
  const { creditNoteId } = useParams<{ creditNoteId: string }>()
  invariant(creditNoteId, 'Credit note ID is required')

  const { creditNote, updateLineItems, updateCreditNoteDetails } =
    useCreditNoteEditing(creditNoteId)

  if (!creditNote) {
    return null
  }

  return (
    <StandardLayout
      header={
        <CreditNoteDetailsHeader
          creditNoteId={creditNoteId}
          creditNoteNumber={creditNote.creditNoteNumber}
          invoiceNumber={creditNote.invoiceNumber}
          status={creditNote.status}
          customerLegalCompanyName={creditNote.customerLegalCompanyName}
          customerEmails={creditNote.customerEmails}
        />
      }
      content={
        <Grid
          templateAreas={`"warnings warnings"
                      "sumup summary"
                      "sumup line-items"
                      "sumup total-summary"`}
          gridTemplateRows="auto auto auto"
          gridTemplateColumns="320px 2.5fr"
          width="100%"
          maxWidth="1537px"
          mx="auto"
          gap={4}
          padding={4}
        >
          <GridItem gridArea="warnings">
            <CreditNoteIntegrationWarningBanners creditNote={creditNote} />
          </GridItem>
          <GridItem area="sumup">
            <CreditNoteAdjustmentTotal
              currency={creditNote.currency}
              lineItems={creditNote.lineItemGroups.flatMap(group =>
                group.lineItems.filter(item => item.selected)
              )}
              netTotal={creditNote.netTotal}
              totalTax={creditNote.totalTax}
              grossTotal={creditNote.grossTotal}
            />
          </GridItem>

          <GridItem area="summary">
            <CreditNoteDetailsSummaryCard
              customerId={creditNote.customerId}
              customerEmails={creditNote.customerEmails}
              customerAddress={creditNote.customerBillingAddress}
              creditNoteNumber={creditNote.creditNoteNumber}
              creditNoteIssueDate={creditNote.issueDate}
              currency={creditNote.currency}
              customerTaxId={creditNote.customerTaxId}
              invoiceNumber={creditNote.invoiceNumber}
              invoiceId={creditNote.invoiceId}
              billingPeriodStart={creditNote.billingPeriodStart}
              billingPeriodEnd={creditNote.billingPeriodEnd}
              updateCreditNoteDetails={updateCreditNoteDetails}
              status={creditNote.status}
            />
          </GridItem>

          <GridItem area="line-items">
            <CreditNoteLineItems
              editable={creditNote.status === 'DRAFT'}
              creditNoteId={creditNote.id}
              lineItemGroups={creditNote.lineItemGroups}
              currency={creditNote.currency}
              updateLineItems={updateLineItems}
            />
            <Flex mt={3}>
              <Box flex={1} />
              <Box minWidth="325px" flex={0} pr={3}>
                <LineItemTotals
                  subtotal={creditNote.netTotal}
                  currency={creditNote.currency}
                  tax={creditNote.totalTax}
                  total={creditNote.grossTotal}
                />
              </Box>
            </Flex>
          </GridItem>

          <GridItem area="total-summary"></GridItem>
        </Grid>
      }
      inspector={<CreditNoteInspector creditNote={creditNote} />}
    />
  )
}

const LineItemTotals: FC<{
  currency: Currency
  subtotal: string
  tax: string
  total: string
}> = ({ currency, subtotal, tax, total }) => {
  return (
    <Totals currency={currency}>
      <Totals.Row
        data-testid="subtotal"
        title="Credit subtotal"
        amount={subtotal}
      />
      <Totals.Row data-testid="tax" title="Tax" amount={tax} />
      <Totals.Divider strong />
      <Totals.Total data-testid="total" title="Credit total" amount={total} />
    </Totals>
  )
}
