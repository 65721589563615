import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CurrencyInput } from 'components/FormComponents'
import { FC } from 'react'

type CurrencyInputWrapperProps = {
  disabled?: boolean
  width?: string
}

export const CurrencyInputWrapper: FC<CurrencyInputWrapperProps> = ({
  disabled,
  width
}) => {
  const accountSettingsQuery = useQuery(
    dashboard20240730Client.getSequenceAccountSettings
  )
  const availableCurrencies = accountSettingsQuery.data?.enabledCurrencies

  return (
    <CurrencyInput
      disabled={disabled || !availableCurrencies}
      width={width}
      availableCurrencies={availableCurrencies || []}
    ></CurrencyInput>
  )
}
