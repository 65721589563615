import { Flex } from '@chakra-ui/react'
import { MutationFormProps } from 'components/Form/types'
import { UpdateInvoiceStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const FinalizeInvoiceForm: FC<
  MutationFormProps & UpdateInvoiceStatusFormProps
> = ({ id, customerName, submitForm, formId }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          })
        ]

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Lock this invoice and prevent editing. Once finalized, you can send the invoice to ${customerName}.`}
          </Flex>
        )
      }}
    />
  )
}

export default FinalizeInvoiceForm
