import { mergeLineItemsIntoGroups } from 'CreditNotes/utils/lineItemGroup'
import partition from 'lodash/partition'

type LineItemGroup = {
  title: string
  id: string
}

type LineItem = {
  id?: string
  taxRate: string
  groupId: string | null
  netTotal: string
  quantity: string
  title: string
  rate: string
}

export function rollUpGroupsIntoLineItems<
  G extends LineItemGroup,
  L extends LineItem,
  A extends G & { lineItems: L[] }
>(lineItemGroups: G[], lineItems: L[], newGroup: LineItemGroup): A[] {
  const [orphanedLineItems, groupedLineItems] = partition(
    lineItems,
    lineItem => lineItem.groupId === null
  )

  const aggregatedGroups = mergeLineItemsIntoGroups<
    G,
    L & { groupId: string },
    A
  >(groupedLineItems as (L & { groupId: string })[], lineItemGroups)

  const [groupsToRollUp, remainingGroups] = partition(aggregatedGroups, group =>
    canBeRolledUp(group.lineItems)
  )

  if (!groupsToRollUp.length) {
    return remainingGroups
  }

  const rolledUp = groupsToRollUp.map(group => rollUpGroup(group, newGroup.id))

  const newGroupLineItems = [
    ...rolledUp,
    ...orphanedLineItems.map(({ id: _, ...item }) => ({
      ...item,
      groupId: newGroup.id
    }))
  ]

  return [
    { ...newGroup, lineItems: newGroupLineItems } as A,
    ...remainingGroups
  ]
}

function canBeRolledUp(lineItems: LineItem[]): boolean {
  const taxRates = new Set()

  for (const lineItem of lineItems) {
    taxRates.add(lineItem.taxRate)
  }

  return taxRates.size === 1
}

function rollUpGroup(
  lineItemGroup: LineItemGroup & { lineItems: LineItem[] },
  parentGroupId: string
): LineItem {
  const netTotal = lineItemGroup.lineItems.reduce((acc, lineItem) => {
    return acc + Number(lineItem.netTotal)
  }, 0)

  return {
    taxRate: lineItemGroup.lineItems[0].taxRate,
    groupId: parentGroupId,
    netTotal: netTotal.toString(),
    rate: netTotal.toString(),
    quantity: '1',
    title: lineItemGroup.title
  }
}
