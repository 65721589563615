import {
  Box,
  Button,
  Flex,
  ModalContent,
  ModalOverlay,
  useToast,
  Modal as Dialogue
} from '@chakra-ui/react'
import { FormSuccess } from 'components/Form'
import { IntegratedFormModalProps } from 'components/Form/types'
import Modal from 'components/Modal'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { SequenceApiFn, useMutation } from '@sequencehq/api'
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BuildMD, QueryParams } from 'lib/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useNotifications } from 'lib/hooks/useNotifications'

/**
 * @deprecated This is rubbish, and just used to keep the entangled modal
 * approach working until we refactor them all away.
 * @param sequenceFn
 * @returns
 */
export const apiPackageAdapter20240730 = <
  FormProps,
  TApiFunction extends SequenceApiFn<any, any>
>(
  /**
   * This is a little odd because we are doing something a bit funky here with the adapter.
   * Because we are resolving the sequenceFn immediately as part of calling this adapter, we
   * can receive the unintialized API function which will have organisation scoping. This
   * isn't an issue for normal usage of our package, since the initialization will replace
   * the functions in place. However, anything that has copied the function by reference
   * will not get updated. Thus, we don't end up calling the full query params necessary
   * to invalidate the cache!
   *
   * It may be worth us considering moving the organisation id to after the entity id, so
   * that the are cached uniquely, but invalidation is more robust. But I'd rather not do it
   * immediately given this was a really weird workaround.
   */
  sequenceFn: keyof typeof dashboard20240730Client,
  adapter: (
    props: FormProps
  ) => TApiFunction extends SequenceApiFn<any, infer V> ? V : void
): UseMutation<BuildMD<QueryParams>> => {
  const adaptedMutation = () => {
    if (!dashboard20240730Client[sequenceFn]) {
      throw new Error('Invalid API function')
    }

    const mutation = useMutation(
      dashboard20240730Client[sequenceFn] as SequenceApiFn<any, any>
    )

    return [
      (props: FormProps) => mutation.mutateAsync(adapter(props)),
      {
        isSuccess: mutation.isSuccess,
        isLoading: mutation.isPending,
        error: mutation.error,
        data: {
          value: () => mutation.data
        }
      }
    ]
  }

  return adaptedMutation as unknown as UseMutation<BuildMD<QueryParams>>
}

/**
 * @deprecated This is rubbish, and just used to keep the entangled modal
 * approach working until we refactor them all away.
 * @param sequenceFn
 * @returns
 */
export const apiPackageAdapter99990101 = <
  FormProps,
  TApiFunction extends SequenceApiFn<any, any>
>(
  /**
   * This is a little odd because we are doing something a bit funky here with the adapter.
   * Because we are resolving the sequenceFn immediately as part of calling this adapter, we
   * can receive the unintialized API function which will have organisation scoping. This
   * isn't an issue for normal usage of our package, since the initialization will replace
   * the functions in place. However, anything that has copied the function by reference
   * will not get updated. Thus, we don't end up calling the full query params necessary
   * to invalidate the cache!
   *
   * It may be worth us considering moving the organisation id to after the entity id, so
   * that the are cached uniquely, but invalidation is more robust. But I'd rather not do it
   * immediately given this was a really weird workaround.
   */
  sequenceFn: keyof typeof dashboardv99990101Client,
  adapter: (
    props: FormProps
  ) => TApiFunction extends SequenceApiFn<any, infer V> ? V : void
): UseMutation<BuildMD<QueryParams>> => {
  const adaptedMutation = () => {
    if (!dashboardv99990101Client[sequenceFn]) {
      throw new Error('Invalid API function')
    }

    const mutation = useMutation(
      dashboardv99990101Client[sequenceFn] as SequenceApiFn<any, any>
    )

    return [
      (props: FormProps) => mutation.mutateAsync(adapter(props)),
      {
        isSuccess: mutation.isSuccess,
        isLoading: mutation.isPending,
        error: mutation.error,
        data: {
          value: () => mutation.data
        }
      }
    ]
  }

  return adaptedMutation as unknown as UseMutation<BuildMD<QueryParams>>
}

/**
 * @deprecated Just nah m8, use the core-components modals
 * @param param0
 * @returns
 */
export const createFormModal =
  <Mutation extends QueryParams, FormProps>({
    FormComponent,
    useMutation,
    successMessage,
    modalTitle,
    navigateTo,
    submitTitle,
    formId,
    buttonVariant,
    overrideSubmitButton,
    successToast,
    errorToast,
    modalTitleFromProps,
    previousModal,
    leftButton,
    successContent,
    variant = 'default',
    toastDuration = 6000,
    errorText
  }: IntegratedFormModalProps<Mutation, FormProps>): FC<
    FormProps & {
      onClose?: () => void
    }
  > =>
  props => {
    const [submitForm, result] = useMutation()
    const { displayNotification } = useNotifications()

    const dispatch = useDispatch()
    const toast = useToast()

    const navigate = useNavigate()

    useEffect(() => {
      if (result.error && errorToast) {
        toast({
          id: `error-${formId}`,
          position: 'bottom',
          duration: toastDuration,
          render: () => errorToast
        })
      }

      if (result.error && errorText) {
        displayNotification(errorText, {
          type: 'error'
        })
      }

      if (!result.isSuccess) {
        return
      }

      const timeoutId = setTimeout(
        () => {
          const validatedData = result.data?.value()
          if (validatedData && navigateTo) {
            const url = navigateTo(validatedData)
            navigate(url)
          }

          if (!successContent) {
            dispatch(closeOverlay())
          }

          if (validatedData && successToast) {
            toast({
              id: `success-${formId}`,
              position: 'bottom',
              duration: toastDuration,
              render: () => successToast(validatedData, props)
            })
          }
        },
        successToast ? 0 : 1000
      )

      return () => clearTimeout(timeoutId)
    }, [result.isSuccess, dispatch, navigate, result, toast, props])

    const response = result.data?.value()

    const onClose = () => {
      if (props.onClose) {
        props.onClose()
      }

      dispatch(closeOverlay())
    }

    if (variant === 'dialogue') {
      return (
        <Dialogue isOpen onClose={onClose} isCentered>
          <ModalOverlay>
            <ModalContent
              data-testid={`modal.${formId}`}
              backgroundColor={GreyGrey0}
              padding="16px"
              borderRadius="8px"
            >
              <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
                {modalTitleFromProps ? modalTitleFromProps(props) : modalTitle}
              </Box>
              <Box
                color={GreyGrey80}
                {...Lato14ExtendedRegular}
                marginBottom="24px"
              >
                {!toast && result.isSuccess && (
                  <FormSuccess message={successMessage} />
                )}
                {successContent && response && result.isSuccess && (
                  <>
                    <FormSuccess message={successMessage} />
                    <Flex py="20px" alignItems="center" flexDirection="column">
                      {successContent(response)}
                    </Flex>
                  </>
                )}
                {!result.isSuccess && (
                  <Box color={GreyGrey80} {...Lato14ExtendedRegular}>
                    <FormComponent
                      {...props}
                      submitForm={submitForm}
                      formId={formId}
                      result={result}
                    />
                  </Box>
                )}
              </Box>
              <Flex gap="8px" justifyContent="flex-end">
                <Flex basis="50%">
                  <Button
                    variant="secondary2"
                    data-testid={`modal.${formId}.cancel`}
                    isDisabled={result.isLoading}
                    onClick={onClose}
                    height="32px"
                    width="100%"
                  >
                    Cancel
                  </Button>
                </Flex>
                <Flex basis="50%">
                  <Button
                    variant="primary2"
                    data-testid={`modal.${formId}.submit`}
                    isLoading={result.isLoading}
                    isDisabled={result.isLoading}
                    form={formId}
                    type="submit"
                    height="32px"
                    width="100%"
                  >
                    {submitTitle}
                  </Button>
                </Flex>
              </Flex>
            </ModalContent>
          </ModalOverlay>
        </Dialogue>
      )
    }

    return (
      <Modal
        previousModal={previousModal}
        leftButton={leftButton}
        title={modalTitleFromProps ? modalTitleFromProps(props) : modalTitle}
        submitTitle={submitTitle}
        showSuccess={result.isSuccess}
        formId={formId}
        buttonVariant={buttonVariant}
        isLoading={result.isLoading}
        overrideSubmitButton={overrideSubmitButton}
        showClose={(successContent && response && result.isSuccess) || false}
        onClose={props.onClose}
      >
        {!toast && result.isSuccess && <FormSuccess message={successMessage} />}
        {successContent && response && result.isSuccess && (
          <>
            <FormSuccess message={successMessage} />
            <Flex py="20px" alignItems="center" flexDirection="column">
              {successContent(response)}
            </Flex>
          </>
        )}
        {!result.isSuccess && (
          <FormComponent
            {...props}
            submitForm={submitForm}
            formId={formId}
            result={result}
          />
        )}
      </Modal>
    )
  }
