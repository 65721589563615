import { filterSuggestionItems } from '@blocknote/core'
import {
  FormattingToolbarController,
  SuggestionMenuController
} from '@blocknote/react'
import { BlockNoteView, lightDefaultTheme } from '@blocknote/mantine'
import { CustomSuggestionMenu } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomSuggestionMenu/CustomSuggestionMenu'
import { getCustomSuggestionMenuItems } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomSuggestionMenu/menuItems'
import { useContentEditor } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/useContentEditor'
import '@blocknote/mantine/style.css'
import 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/BlockNoteOverrides.scss'
import { CustomFormattingToolbar } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomFormattingToolbar/CustomFormattingToolbar'

const blocknoteTheme = {
  light: {
    ...lightDefaultTheme,
    fontFamily: 'Lato, sans-serif'
  },
  dark: {
    ...lightDefaultTheme,
    fontFamily: 'Lato, sans-serif'
  }
}

export const ContentEditor = () => {
  const hook = useContentEditor()

  return (
    <BlockNoteView
      editor={hook.features.contentEditor.editor}
      theme={blocknoteTheme}
      onChange={hook.features.contentEditor.onChange}
      slashMenu={false}
      formattingToolbar={false}
      editable={!hook.features.contentEditor.disabled}
      onKeyDownCapture={hook.features.contentEditor.onKeyDownCapture}
    >
      <SuggestionMenuController
        triggerCharacter="/"
        getItems={query => {
          return Promise.resolve(
            filterSuggestionItems(
              getCustomSuggestionMenuItems(
                hook.features.contentEditor.editor,
                hook.features.contentEditor.addPhaseBlock.enabled
              ),
              query
            )
          )
        }}
        suggestionMenuComponent={CustomSuggestionMenu}
      />
      <FormattingToolbarController
        formattingToolbar={CustomFormattingToolbar}
      />
    </BlockNoteView>
  )
}
