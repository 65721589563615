import { Currency, useQuery } from '@sequencehq/api'
import { DeferredRevenueTableGroupBy } from './DeferredRevenueDashboardTable'
import {
  type Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'

import invariant from 'tiny-invariant'

type UseDeferredRevenueDashboardTableQueryParams = {
  groupBy?: DeferredRevenueTableGroupBy
}

export type DeferredRevenueSchedule = {
  id: string
  customer: {
    id: string
    name: string
  }
  product: {
    id: string
    name: string
  }
  invoice?: {
    id: string
    name: string
  }
  method: Dashboardv99990101Api.GetDeferredRevenueSchedules.DeferredRecognitionMethod
  term?: {
    start: Date
    endInclusive: Date
  }
  progress: {
    currency: Currency
    recognized: {
      percentage: number
      amount: number
    }
    remaining: {
      percentage: number
      amount: number
    }
  }
}

interface DeferredRevenueScheduleData {
  currency: Currency
  schedules: Array<DeferredRevenueSchedule>
}

const sortByCustomer = (schedules: DeferredRevenueSchedule[]) => {
  return schedules.sort((a, b) => {
    return a.customer.id.localeCompare(b.customer.id)
  })
}

const sortByProduct = (schedules: DeferredRevenueSchedule[]) => {
  return schedules.sort((a, b) => {
    return a.product.id.localeCompare(b.product.id)
  })
}

const sortByInvoice = (schedules: DeferredRevenueSchedule[]) => {
  return schedules.sort((a, b) => {
    return a.invoice?.id.localeCompare(b.invoice?.id ?? '') ?? 0
  })
}

export const useDeferredRevenueDashboardTableQuery = ({
  groupBy
}: UseDeferredRevenueDashboardTableQueryParams): {
  detail: DeferredRevenueScheduleData | null
  isLoading: boolean
} => {
  const schedulesQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedules,
    undefined,
    {
      select: res => {
        if (!res) {
          return null
        }

        const schedules = res.items.map(
          (item): DeferredRevenueSchedule => ({
            id: item.id,
            customer: {
              id: item.customer.id,
              name: item.customer.name
            },
            product: item.product,
            invoice: item.invoice,
            method: item.recognitionMethod,
            progress: {
              currency: item.progress.currency,
              recognized: {
                percentage: Math.round(
                  (parseFloat(item.progress.recognized) /
                    parseFloat(item.progress.original)) *
                    100
                ),
                amount: parseFloat(item.progress.recognized)
              },
              remaining: {
                percentage: Math.round(
                  (parseFloat(item.progress.remaining) /
                    parseFloat(item.progress.original)) *
                    100
                ),
                amount: parseFloat(item.progress.remaining)
              }
            }
          })
        )

        const sortingFunction = groupBy
          ? {
              customer: sortByCustomer,
              product: sortByProduct,
              invoice: sortByInvoice
            }[groupBy]
          : undefined

        const sortedSchedules = sortingFunction
          ? sortingFunction(schedules)
          : schedules

        return {
          currency: res.balances[0].currency,
          count: sortedSchedules.length,
          schedules: sortedSchedules
        }
      }
    }
  )

  if (schedulesQuery.error) {
    /**
     * We throw this error to be caught by the appropriate error boundary.
     */
    throw new Error('Schedules detail could not be loaded')
  }

  if (schedulesQuery.isPending) {
    return {
      isLoading: true,
      detail: null
    }
  }

  invariant(schedulesQuery.data, 'Detail should be defined, if loaded')

  return {
    isLoading: false,
    detail: schedulesQuery.data
  }
}
