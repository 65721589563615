import { useCallback, useEffect, useMemo, useState } from 'react'
import { MerchantModel } from '@sequencehq/core-models'
import * as Sentry from '@sentry/react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type UseLoadMerchant = () => {
  loading: boolean
  error?: boolean
  data?: {
    merchant: MerchantModel
  }
}

export const useLoadMerchant: UseLoadMerchant = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<boolean>(false)
  const [merchant, setMerchant] = useState<MerchantModel | undefined>()

  const loadMerchant = useCallback(
    async () =>
      dashboard20240730Client
        .getMerchantForSequenceAccount()
        .then(response => response.data),
    []
  )

  useEffect(() => {
    setIsLoading(true)
    setError(false)
    loadMerchant()
      .then(loadedMerchant => {
        if (loadedMerchant) {
          setMerchant(loadedMerchant)
        }
      })
      .catch(e => {
        Sentry.captureException(e)
        setError(true)
      })
      .finally(() => setIsLoading(false))
  }, [loadMerchant])

  const data = useMemo(() => {
    if (isLoading || !merchant) {
      return
    }

    return {
      merchant
    }
  }, [isLoading, merchant])

  return {
    loading: isLoading,
    error,
    data
  }
}
