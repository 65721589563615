import type { ServerAsset } from '@sequencehq/api/utils/commonModels'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CubeStatus } from '../cube.domain.types'

export const useSelectBillingScheduleAttachments = (): ServerAsset[] => {
  const { queries } = useCubeContext()
  return queries.rawData.data.schedule.attachmentAssets
}

export const useStoreBillingScheduleAttachments = () => {
  const cubeContext = useCubeContext()
  return (assets: ServerAsset[]) => {
    cubeContext.mutators.updateData({ schedule: { attachmentAssets: assets } })
  }
}

const useBillingScheduleStatus = () => {
  const { queries } = useCubeContext()
  queries.rawData.data.common.status
  return queries.rawData.data.common.status
}

export const useShouldDisableModifyBillingScheduleAttachments = () => {
  const status = useBillingScheduleStatus()

  if (
    [
      CubeStatus.ScheduleCancelled,
      CubeStatus.ScheduleSuspended,
      CubeStatus.ScheduleCompleted
    ].includes(status)
  ) {
    const humanReadableStatus =
      status === CubeStatus.ScheduleCancelled
        ? 'cancelled'
        : status === CubeStatus.ScheduleSuspended
          ? 'suspended'
          : 'completed'

    return {
      reason: `Cannot modify attachments for a ${humanReadableStatus} schedule`
    }
  }

  return false
}

export const useSelectQuoteAttachments = (): ServerAsset[] => {
  const { queries } = useCubeContext()
  return queries.rawData.data.quote.attachmentAssets
}

export const useStoreQuoteAttachments = () => {
  const cubeContext = useCubeContext()
  return (assets: ServerAsset[]) => {
    cubeContext.mutators.updateData({ quote: { attachmentAssets: assets } })
  }
}

const useQuoteStatus = () => {
  const { queries } = useCubeContext()
  return queries.rawData.data.common.status
}

export const useShouldDisableModifyQuoteAttachments = () => {
  const status = useQuoteStatus()

  if ([CubeStatus.QuoteAccepted, CubeStatus.QuoteExecuted].includes(status)) {
    const humanReadableStatus =
      status === CubeStatus.QuoteAccepted ? 'a signed' : 'an executed'
    return {
      reason: `Cannot modify attachments for ${humanReadableStatus} quote`
    }
  }

  return false
}
