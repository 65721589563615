import { CubeProvider } from 'modules/Cube/communication/internal/cube.domain.context'
import { BillingScheduleLayout } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleLayout'
import { useCubeRoot } from 'modules/Cube/view/useCube.root'
import { Outlet } from 'react-router-dom'
import { QuoteEditorLayout } from 'modules/Cube/view/layouts/quote/QuoteEditorLayout'
import PageError from 'components/ErrorPages/PageError'
import { match, P } from 'ts-pattern'
import { CorePortErrors } from 'modules/Cube/domain/cube.domain.types'
import NotFound from 'components/ErrorPages/NotFound'
import { Box } from '@chakra-ui/react'
import { QuoteLoadingSkeleton } from './layouts/quote/QuoteLoadingSkeleton'
import { ScheduleLoadingSkeleton } from './layouts/billingSchedule/ScheduleLoadingSkeleton'
import { Provider as JotaiProvider } from 'jotai'

/**
 * The entry point for Cube!
 * ~A whoolleee newww wooorrrlllddd agaaaaiiinnn~
 * @returns
 */
export const CubeRoot = (props: {
  variant: 'schedule' | 'quote' | 'quoteTemplate'
}) => {
  const { variant } = props

  const { editorContext, ready, error } = useCubeRoot({
    variant
  })

  if (error) {
    return match(error)
      .with(CorePortErrors.NotFound, () => <NotFound />)
      .with(CorePortErrors.Other, () => <PageError />)
      .exhaustive()
  }

  return (
    <Box position="relative" width="100%" height="100%" overflow="hidden">
      <JotaiProvider>
        <CubeProvider value={editorContext}>
          {match({ variant, ready })
            .with(
              { variant: P.string.startsWith('quote'), ready: false },
              () => <QuoteLoadingSkeleton />
            )
            .with({ variant: 'schedule', ready: false }, () => (
              <ScheduleLoadingSkeleton />
            ))
            .with(
              { variant: P.string.startsWith('quote'), ready: true },
              () => <QuoteEditorLayout />
            )
            .with({ variant: 'schedule', ready: true }, () => (
              <BillingScheduleLayout />
            ))
            .exhaustive()}
          <Outlet context={editorContext} />
        </CubeProvider>
      </JotaiProvider>
    </Box>
  )
}
