import { useMutation } from '@sequencehq/api'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { contractIdAtom } from 'ContractExtraction/atoms'
import { useAtom } from 'jotai'
import { useCurrentContractMetadata } from './useContractMetadata'
import { useCallback } from 'react'
import { isMatchedFieldPath } from 'ContractExtraction/utils/isMatchedFieldPath'

export const useDismissExtractedFields = () => {
  const [contractId] = useAtom(contractIdAtom)
  const fieldsMetadata = useCurrentContractMetadata()

  const mutation = useMutation(dashboardv99990101Client.patchContract)

  return useCallback(
    (fieldPaths: string[]) => {
      if (!fieldsMetadata) {
        return
      }

      mutation.mutate({
        id: contractId ?? '',
        body: {
          fieldsMetadata: fieldsMetadata.filter(
            field =>
              !fieldPaths.some(pattern =>
                isMatchedFieldPath({ fieldPath: field.fieldPath, pattern })
              )
          )
        }
      })
    },
    [contractId, fieldsMetadata, mutation]
  )
}
