import { InvoiceModel } from '@sequencehq/core-models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api'

type ApiUsageItemGroup =
  DashboardApi20240730.GetInvoiceUsageItemGroups.UsageItemGroup
export const MOCK_SUB_ACCOUNT_USAGE_DATA_2: ApiUsageItemGroup[] = [
  {
    id: '1aaeac35-cdc3-4adb-a741-5fa4816619ad',
    invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
    title: 'Metric 1',
    items: [
      {
        id: 'cf8ad70f-f79a-4803-a41f-9a0b436c865a',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Eldest Child with a super long name that should wrap',
        value: '150',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      },
      {
        id: 'f00fcb1a-76ff-44d9-b8c3-b444e554cc9c',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Youngest Child',
        value: '300',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      }
    ]
  },
  {
    id: '1aaeac35-cdc3-4adb-a741-5fa4816619ad',
    invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
    title: 'Metric 2',
    items: [
      {
        id: 'cf8ad70f-f79a-4803-a41f-9a0b436c865a',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Eldest Child',
        value: '250',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      },
      {
        id: 'f00fcb1a-76ff-44d9-b8c3-b444e554cc9c',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Youngest Child',
        value: '350',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      }
    ]
  },
  {
    id: '1aaeac35-cdc3-4adb-a741-5fa4816619ad',
    invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
    title: 'Metric 3',
    items: [
      {
        id: 'cf8ad70f-f79a-4803-a41f-9a0b436c865a',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Eldest Child',
        value: '50',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      },
      {
        id: 'f00fcb1a-76ff-44d9-b8c3-b444e554cc9c',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Youngest Child',
        value: '450',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298628',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      }
    ]
  }
]

type UseLoadSubAccountUsage = (props: {
  invoiceId?: InvoiceModel['id']
  async: boolean
}) => {
  loading: boolean
  error?: boolean
  data?: {
    subAccountUsageBreakdown: ApiUsageItemGroup[]
  }
}

export const useLoadSubAccountUsage: UseLoadSubAccountUsage = props => {
  const subAccountUsageQuery = useQuery(
    dashboard20240730Client.getInvoiceUsageItemGroups,
    {
      invoiceId: props.invoiceId ?? ''
    },
    {
      enabled: Boolean(props.invoiceId)
    }
  )

  const data = useMemo(() => {
    if (!subAccountUsageQuery.data) {
      return
    }

    return {
      subAccountUsageBreakdown: subAccountUsageQuery.data.items
    }
  }, [subAccountUsageQuery])

  return {
    loading: subAccountUsageQuery.isPending,
    error: subAccountUsageQuery.isError,
    data
  }
}
