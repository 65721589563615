import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  Link,
  Spinner
} from '@chakra-ui/react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { InfoBox, InfoRow } from '@sequencehq/core-components'
import {
  AggregationType,
  CustomParameterModel,
  toAggregationType
} from '@sequencehq/core-models'
import {
  GreyGrey50,
  IndigoIndigo50,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import { format, formatDateRange } from '@sequencehq/utils/dates'
import {
  composeValidators,
  required,
  validateParameter
} from '@sequencehq/validation'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { DateRangeInput } from 'components/FormInputs/Dates/DateRangeInput'
import { useDateRangeEndInput } from 'components/FormInputs/Dates/useDateRangeEndInput'
import { useDateRangeStartInput } from 'components/FormInputs/Dates/useDateRangeStartInput'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC, useState } from 'react'
import { Form } from 'react-final-form'

type UsageMetricPreviewProps = {
  usageMetricId: string
  aggregationType: AggregationType
  parameters: CustomParameterModel[]
}

type FormValues = {
  customerId: string
  startDate: Date
  endDate: Date
  customParameters: Record<string, string>
}

export const UsageMetricPreview: FC<UsageMetricPreviewProps> = ({
  usageMetricId,
  aggregationType,
  parameters
}) => {
  const [calculationResult, setCalculationResult] =
    useState<DashboardApi20240730.GetCalculateUsageMetric.CalculationResult | null>(
      null
    )
  const [isCalculating, setIsCalculating] = useState(false)

  const parameterDefaultValues: Record<string, string> = {}
  parameters.forEach(parameter => {
    parameterDefaultValues[parameter.id] = parameter.defaultValue
  })

  const setCurrentMonth = (form: any) => {
    const now = new Date()
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)

    form.change('startDate', startOfMonth)
    form.change('endDate', endOfMonth)
  }

  const setPreviousMonth = (form: any) => {
    const now = new Date()
    const startOfMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const endOfMonth = new Date(now.getFullYear(), now.getMonth(), 0)

    form.change('startDate', startOfMonth)
    form.change('endDate', endOfMonth)
  }

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{ customParameters: parameterDefaultValues }}
      onSubmit={async (values, form) => {
        try {
          setIsCalculating(true)
          const aliasRes = await dashboard20240730Client.getAliasesForCustomer({
            customerId: values.customerId
          })

          const aliases = aliasRes.data?.items.map(({ value }) => value) ?? []

          const calcRes = await dashboard20240730Client.getCalculateUsageMetric(
            {
              id: usageMetricId,
              customerAliases: [values.customerId, ...aliases],
              periodStart: format(values.startDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
              periodEnd: format(values.endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
              customParameters: values.customParameters
            }
          )
          setCalculationResult(calcRes.data)
          return handleFormResponse(calcRes, form.getRegisteredFields())
        } finally {
          setIsCalculating(false)
        }
      }}
      render={({ handleSubmit, submitError, values, form }) => {
        const renderContent = () => {
          if (isCalculating) {
            return (
              <Flex flex="1" justifyContent="center" alignItems="center">
                <Spinner color={IndigoIndigo50} />
              </Flex>
            )
          }

          if (calculationResult) {
            return (
              <>
                {aggregationType !== 'CUSTOM' && (
                  <InfoRow label="Event count">
                    {calculationResult.eventCount}
                  </InfoRow>
                )}
                <Box height={2} />

                <InfoRow
                  label={`${toAggregationType(calculationResult.aggregationType)}:`}
                >
                  {calculationResult.value}
                </InfoRow>

                <Box height={2} />
                <InfoRow
                  label={formatDateRange({
                    from: values.startDate,
                    to: values.endDate
                  })}
                />
              </>
            )
          }

          return (
            <Flex flex="1" justifyContent="center" alignItems="center">
              <Text color={GreyGrey50} textAlign="center">
                Select a customer and date range to calculate usage
              </Text>
            </Flex>
          )
        }

        return (
          <>
            <Text {...Lato16Bold}>Preview</Text>
            <Box height={4} />
            <InfoBox>
              <Flex
                flexDirection="column"
                minHeight="96px"
                height="100%"
                data-testid="usageMetric.calculationPreview"
              >
                {renderContent()}
              </Flex>
            </InfoBox>
            <Box height={4} />
            <Flex
              as="form"
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
            >
              <FormErrors formError={submitError} />
              <CustomerComboInputComplete
                fieldName="customerId"
                validate={required}
              />
              <Box height={4} />
              <HStack spacing={4} alignItems="start">
                <DateRangeInput useDateRangeInput={useDateRangeStartInput} />

                <DateRangeInput
                  useDateRangeInput={useDateRangeEndInput}
                  className="end-date"
                />
              </HStack>
              <Flex gap={2} mt={1}>
                <Link
                  fontSize="sm"
                  color={IndigoIndigo50}
                  cursor="pointer"
                  onClick={e => {
                    e.preventDefault()
                    setPreviousMonth(form)
                  }}
                >
                  Previous month
                </Link>
                <Text color={GreyGrey50}>|</Text>
                <Link
                  fontSize="sm"
                  color={IndigoIndigo50}
                  data-testid="usageMetric.calculationPreview.currentMonth"
                  cursor="pointer"
                  onClick={e => {
                    e.preventDefault()
                    setCurrentMonth(form)
                  }}
                >
                  Current month
                </Link>
              </Flex>
              <Box height={4} />
              {parameters.map(parameter => (
                <TextInputField
                  key={parameter.id}
                  fieldLabel={parameter.name}
                  fieldName={`customParameters[${parameter.id}]`}
                  validate={composeValidators(
                    required,
                    validateParameter(parameter.type)
                  )}
                  infoPopover={{
                    body: parameter.description
                  }}
                />
              ))}
              <Box height={4} />
              <Flex justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="primary"
                  data-testid="usageMetric.calculationPreview.calculate"
                >
                  Calculate
                </Button>
              </Flex>
            </Flex>
          </>
        )
      }}
    />
  )
}
