import { dequal } from 'dequal'
import pick from 'lodash/fp/pick'
import { Minimum, ResolvedPhase } from 'modules/Cube/domain/cube.domain.types'

const checkableProperties = pick(['scope', 'value'])

export const getInvalidMinimumProration = ({
  previousPhase,
  phase
}: {
  previousPhase?: ResolvedPhase
  phase: Omit<ResolvedPhase, 'analysis'>
}): Minimum[] => {
  const previousPhaseAnalysis = previousPhase?.analysis
  /**
   * If the phase is not misaligned, or is the last phase, then we can safely assume all is well
   * for this phase.
   */
  if (!previousPhaseAnalysis || previousPhaseAnalysis.alignedWithPeriod.end) {
    return []
  }

  const minimumInPreviousPhase = previousPhase.minimums[0]

  if (!minimumInPreviousPhase) {
    return []
  }

  const minimumInThisPhase = phase.minimums[0]

  if (
    !dequal(
      checkableProperties(minimumInPreviousPhase),
      checkableProperties(minimumInThisPhase)
    )
  ) {
    return [minimumInThisPhase]
  }

  return []
}
