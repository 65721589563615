import {
  ActionHandler,
  DeleteAction
} from 'modules/Cube/domain/cube.domain.types'
import compose from 'lodash/fp/compose'
import get from 'lodash/fp/get'
import omit from 'lodash/fp/omit'

export const deleteData: ActionHandler<DeleteAction> = prevState => action => {
  const filteredData = compose(
    omit(action.payload.ids),
    get(action.payload.type)
  )(prevState.data)

  return {
    ...prevState,
    data: {
      ...prevState.data,
      [action.payload.type]: filteredData
    }
  }
}
