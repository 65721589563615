import { Flex, Text } from '@chakra-ui/react'
import { DeleteNotificationPolicyFormProps } from 'components/DeleteNotificationPolicy/DeleteNotificationPolicyModal'
import { FormErrors } from '@sequencehq/forms'
import { MutationFormProps } from 'components/Form/types'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

export const DeleteNotificationPolicyForm: FC<
  MutationFormProps & DeleteNotificationPolicyFormProps
> = ({ id, name, formId, submitForm }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{}}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          id
        })
        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <>
            <Flex
              as="form"
              px={5}
              py={5}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
              id={formId}
            >
              <FormErrors formError={submitError} />
              <Text>Delete notification policy {name}?</Text>
            </Flex>
          </>
        )
      }}
    />
  )
}
