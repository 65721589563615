import { Reducer } from 'react'
import { match } from 'ts-pattern'
import {
  CubeActions,
  PostActionStage,
  CubeReducerState
} from 'modules/Cube/domain/cube.domain.types'
import * as actions from 'modules/Cube/domain/actionHandlers'
import * as postActionStages from 'modules/Cube/domain/postActionStages'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import compose from 'lodash/fp/compose'
import { cubeDeveloperLog } from 'modules/Cube/utils/cubeDeveloperLog'

const handleAction = (prevState: CubeReducerState) => (action: CubeActions) => {
  return match(action)
    .with({ type: 'load' }, matchedAction =>
      actions.load(prevState)(matchedAction)
    )
    .with({ type: 'reset' }, matchedAction =>
      actions.reset(prevState)(matchedAction)
    )
    .with({ type: 'updateData' }, matchedAction =>
      actions.updateData(prevState)(matchedAction)
    )
    .with({ type: 'updateConfiguration' }, matchedAction =>
      actions.updateConfiguration(prevState)(matchedAction)
    )
    .with({ type: 'updateEditor' }, matchedAction =>
      actions.updateEditor(prevState)(matchedAction)
    )
    .with({ type: 'deleteData' }, matchedAction =>
      actions.deleteData(prevState)(matchedAction)
    )
    .with({ type: 'alignPhaseDuration' }, matchedAction =>
      actions.alignPhaseDuration(prevState)(matchedAction)
    )
    .exhaustive()
}

const logStage =
  (stageName: string): PostActionStage =>
  () =>
  prevState => {
    cubeDeveloperLog(
      `[Cube] After post action stage: %c${stageName}`,
      'color: lime;',
      () => ({
        state: prevState
      })
    )
    return prevState
  }

const handlePostActionStages: PostActionStage = ctx => prevState => {
  /**
   * Since this is a compose, the actual order is bottom to top!
   */
  return compose(
    logStage('Validation')(ctx),
    postActionStages.validation(ctx),
    logStage('Queries')(ctx),
    postActionStages.queries(ctx),
    logStage('Cleanup')(ctx),
    postActionStages.cleanup(ctx),
    logStage('Update Price Currencies')(ctx),
    postActionStages.updatePriceCurrencies(ctx)
  )(prevState) as CubeReducerState
}

export const billingScheduleReducer: Reducer<CubeReducerState, CubeActions> = (
  prevState = INITIAL_CUBE_STATE,
  action
) => {
  cubeDeveloperLog(
    `%c[Cube]======== Start of reducer for action ${action.type} =========`,
    'color: cyan;',
    () => ({
      initialState: prevState,
      action
    })
  )

  const postActionState = handleAction(prevState)(action)

  cubeDeveloperLog(
    `%c[Cube]======== beginning of post action functionality for action ${action.type} =========`,
    'color: lime;',
    () => ({
      postActionState
    })
  )

  const finalState = handlePostActionStages({
    preActionState: prevState,
    action
  })(postActionState)

  cubeDeveloperLog(
    `%c[Cube]======== End of reducer for action ${action.type} =========`,
    'color: hotpink;',
    () => ({
      finalState
    }),
    `
    `
  )

  return finalState
}
