import { useCallback, useEffect, useState } from 'react'
import { Customer } from 'components/Customers/types'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

export const useFetchCustomer = (customerId: string) => {
  const [customerDetails, setCustomerDetails] = useState<Customer | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchCustomer = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const customerData = await dashboardv99990101Client.getCustomer({
        id: customerId
      })

      setCustomerDetails(customerData.data ?? null)
    } catch (e) {
      setError(e as Error)
      console.error(e)
      setCustomerDetails(null)
    } finally {
      setLoading(false)
    }
  }, [customerId])

  useEffect(() => {
    void fetchCustomer()
  }, [fetchCustomer])

  return { customerDetails, loading, error, refetch: fetchCustomer }
}
