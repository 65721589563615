import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'
import Mousetrap from 'mousetrap'

/**
 * Global state for keyboard shortcuts overlay visibility
 */
const keyboardShortcutsOverlayAtom = atom(false)

/**
 * Hook to manage the keyboard shortcuts overlay visibility and its keyboard shortcut (⌘ + /)
 */
export function useKeyboardShortcuts() {
  const [isKeyboardShortcutsOpen, setIsKeyboardShortcutsOpen] = useAtom(
    keyboardShortcutsOverlayAtom
  )

  useEffect(() => {
    // Bind Command + / to toggle keyboard shortcuts overlay
    Mousetrap.bind('command+/', () => {
      setIsKeyboardShortcutsOpen(prev => !prev)
      return false // Prevent default browser behavior
    })

    return () => {
      Mousetrap.unbind('command+/')
    }
  }, [setIsKeyboardShortcutsOpen])

  return {
    isKeyboardShortcutsOpen,
    setIsKeyboardShortcutsOpen
  }
}
