import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  InputGroup,
  InputLeftElement,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { IntegrationService } from '@sequencehq/core-models'
import { borderColor } from '@sequencehq/core-theme'
import { GreyGrey50, Lato16Regular } from '@sequencehq/design-tokens'
import { CheckboxField, TextInputField } from '@sequencehq/forms'
import { MutationFormProps } from 'components/Form/types'
import { ImportCustomersProgress } from 'components/ImportCustomers/ImportCustomersProgress'
import Spinner from 'components/Loading/Spinner'
import { useDispatch } from 'features/store'
import { integrationName } from 'lib/integrations/integrationName'
import { noReturn } from 'lib/noReturn'
import { FC, useState } from 'react'
import { AlertTriangle, RefreshCw, Search } from 'react-feather'
import { Form } from 'react-final-form'

export interface SelectImportCustomersProps {
  service: IntegrationService
}

interface FormValues {
  customerIds: string[]
  searchValue: string
}

export const SelectImportCustomersForm: FC<
  MutationFormProps & SelectImportCustomersProps
> = ({ service, submitForm, formId }) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [customerIds, setCustomerIds] = useState<string[]>([])

  const integrationCustomersQuery = useQuery(
    dashboard20240730Client.getAllIntegrationCustomers
  )

  const integrationCustomersValue = integrationCustomersQuery.data

  const customers = integrationCustomersValue?.customers
    .find(list => list.key === service)
    ?.value?.filter(customer => !customer.isLinked)

  if (!integrationCustomersValue || !customers) {
    return <Spinner></Spinner>
  }

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{ customerIds: [], searchValue: '' }}
      onSubmit={async values => {
        setSubmitting(true)
        setCustomerIds(
          customers
            .filter((customer, index) => !!values.customerIds[index])
            .map(customer => customer.id)
        )
        await submitForm({
          service: service,
          customerIds: customers
            .filter((customer, index) => !!values.customerIds[index])
            .map(customer => customer.id)
        })
      }}
      mutators={{
        selectAll: ([all], state, { changeValue }) => {
          changeValue(state, 'customerIds', () =>
            all
              ? customers.map(customer => !customer.invalidReason)
              : customers.map(() => false)
          )
        }
      }}
      render={({ handleSubmit, values, form }) => {
        return submitting ? (
          <ImportCustomersProgress
            service={service}
            customerIds={customerIds}
            setComplete={complete => setSubmitting(!complete)}
          ></ImportCustomersProgress>
        ) : (
          <>
            <Flex
              as="form"
              data-testid="selectImportCustomers.form"
              px={5}
              py={5}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
              id={formId}
            >
              <Flex flexDirection="row">
                <InputGroup
                  alignItems="center"
                  position="relative"
                  outline="none"
                  border="none"
                  height="40px"
                >
                  <InputLeftElement
                    pointerEvents="none"
                    width="20px"
                    height="20px"
                  >
                    <Flex flexDirection="row">
                      <Box width="18px"></Box>
                      <Flex flexDirection="column">
                        <Box height="20px"></Box>
                        <Search color={GreyGrey50} width="20px" height="20px" />
                      </Flex>
                    </Flex>
                  </InputLeftElement>
                  <TextInputField
                    variant="plain"
                    placeholder="Search customers"
                    {...Lato16Regular}
                    height="autp"
                    lineHeight="24px"
                    paddingLeft="40px"
                    fieldName="searchValue"
                  />
                </InputGroup>
                <Box width="20px"></Box>
                <Popover trigger="hover" variant="fieldInfo">
                  <PopoverTrigger>
                    <Button
                      variant="secondary"
                      width="40px"
                      height="40px"
                      padding="5px"
                      isLoading={integrationCustomersQuery.isPending}
                      onClick={() => integrationCustomersQuery.refetch()}
                    >
                      <RefreshCw size={15}></RefreshCw>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    backgroundColor="gray.90"
                    borderColor="none"
                    width="fit-content"
                  >
                    <PopoverBody padding="8px">
                      <Text color="white" fontSize="14px">
                        Reload customers from {integrationName(service)}
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
              <Box height="22px"></Box>
              <Flex flexDirection="row">
                <Checkbox
                  onChange={event =>
                    void form.mutators.selectAll(event.target.checked)
                  }
                >
                  <Text fontSize="12px" fontWeight="700" color="gray.60">
                    SELECT ALL (
                    {
                      customers.filter(customer => !customer.invalidReason)
                        .length
                    }{' '}
                    valid)
                  </Text>
                </Checkbox>
                <Flex grow="1"></Flex>
                <Text color="gray.60">
                  ({values.customerIds.filter(customer => customer).length} of{' '}
                  {customers.length})
                </Text>
                <Box width="20px"></Box>
              </Flex>
              <Box height="14px"></Box>
              <Divider color={borderColor}></Divider>
              {customers.map((customer, index) => (
                <Box
                  key={index}
                  hidden={
                    !!values.searchValue &&
                    values.searchValue != '' &&
                    !customer.companyName
                      .toLowerCase()
                      .includes(values.searchValue.toLowerCase())
                  }
                >
                  <Box height="14px"></Box>
                  <Flex flexDirection="row">
                    <CheckboxField
                      fieldName={`customerIds[${index}]`}
                      disabled={Boolean(customer.invalidReason)}
                    >
                      <Text fontSize="14px" fontWeight="700">
                        {customer.companyName}
                      </Text>
                      <Box width="8px"></Box>
                      <Text fontSize="14px" color="gray.60">
                        {customer.country}
                      </Text>
                    </CheckboxField>
                    {customer.invalidReason && (
                      <Popover trigger="hover" variant="fieldInfo">
                        <PopoverTrigger>
                          <Box as={Link} href={customer.externalUrl} isExternal>
                            <AlertTriangle
                              size={18}
                              color="#D1992A"
                            ></AlertTriangle>
                          </Box>
                        </PopoverTrigger>
                        <PopoverContent
                          backgroundColor="gray.90"
                          borderColor="none"
                        >
                          <PopoverBody padding="8px">
                            <Text color="white" fontSize="14px">
                              {customer.invalidReason} Update the customer in{' '}
                              {integrationName(service)} to import.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                    <Box width="20px"></Box>
                  </Flex>

                  <Box height="8px"></Box>
                  <Divider color={borderColor}></Divider>
                </Box>
              ))}
            </Flex>
          </>
        )
      }}
    />
  )
}
