import {
  Badge,
  KebabMenuItem,
  MenuItemBuilder,
  Modal,
  ModalContextProvider,
  Toast
} from '@sequencehq/core-components'
import {
  InvoicePaymentStatus,
  toInvoicePaymentStatusBadgeProps
} from '@sequencehq/core-models'
import { openOverlay } from 'features/overlay'
import { UseToastOptions, Box, Flex, Text } from '@chakra-ui/react'
import { DownloadInvoicePdfProps } from 'InvoiceEditor/components/Header/useInvoicePdfDownload'
import { Dashboardv20240509Api } from '@sequencehq/api/dashboard/v20240509'
import { RedRed60 } from '@sequencehq/design-tokens'
import { ArchiveInvoiceModal } from 'components/Invoices/modals/ArchiveInvoice.tsx'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice

const finalizeAndSendBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: invoice.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Send Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeAndSendInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName,
            billingScheduleId: invoice.billingScheduleId,
            customerEmails: invoice.customerEmails
          }
        })
      ),
    testId: `invoices.${invoice.id}.menu.finalizeAndSend`
  }
}

export const archiveInvoiceBuilder: MenuItemBuilder<Invoice> = (
  invoice: Invoice,
  options = {}
) => {
  return {
    status: ['IN_PROGRESS', 'DRAFT'].includes(invoice.status)
      ? 'LIVE'
      : 'DISABLED',
    action: () => {},
    label: (
      <ModalContextProvider>
        <Modal.Trigger>
          {triggerModal => {
            return (
              <KebabMenuItem>
                <Flex
                  flexDirection="row"
                  align="center"
                  gap={2}
                  width="100%"
                  onClick={triggerModal}
                  data-testid="kebab-menu-archive-button"
                >
                  <Text color={RedRed60}>Delete invoice</Text>
                </Flex>
              </KebabMenuItem>
            )
          }}
        </Modal.Trigger>
        <ArchiveInvoiceModal
          invoiceId={invoice.id}
          onSuccess={options.onSuccess}
        />
      </ModalContextProvider>
    ),
    testId: `invoices.${invoice.id}.menu.archive`
  }
}

export const finalizeBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: invoice.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Finalize Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName
          }
        })
      ),
    testId: `invoices.${invoice.id}.menu.finalize`
  }
}

export const sendBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: invoice.status === 'FINAL' ? 'LIVE' : 'DISABLED',
    label: 'Send Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'sendInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName,
            billingScheduleId: invoice.billingScheduleId,
            customerEmails: invoice.customerEmails
          }
        })
      ),
    testId: `invoices.${invoice.id}.menu.send`
  }
}

const previewBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: 'LIVE',
    action: ({ navigate }) => navigate(`/invoices/${invoice.id}`),
    label: invoice.status === 'DRAFT' ? 'View/Edit invoice' : 'View invoice',
    testId: `invoices.${invoice.id}.menu.preview`
  }
}

const downloadBuilder: (
  downloadPdf: (props: DownloadInvoicePdfProps) => void
) => MenuItemBuilder<Invoice> = download => (invoice: Invoice) => {
  return {
    status: 'LIVE',
    action: () => {
      download({
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        invoiceStatus: invoice.status,
        customerId: invoice.customerId
      })
    },
    label: 'Download invoice as PDF',
    testId: `invoices.${invoice.id}.menu.download`
  }
}

export const voidBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'DRAFT' || invoice.status === 'FINAL'
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'voidInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName
          }
        })
      ),
    label: (
      <KebabMenuItem>
        <Box color={RedRed60}>Void Invoice</Box>
      </KebabMenuItem>
    ),
    testId: `invoices.${invoice.id}.menu.void`
  }
}

const paidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: async () => {
      const putStatusRes =
        await dashboard20240730Client.putInvoicePaymentStatus({
          invoiceId: invoice.id,
          paymentStatus: 'PAID'
        })

      if (putStatusRes.error) {
        toast({
          id: `paid${invoice.invoiceNumber || ''}`,
          position: 'bottom',
          isClosable: true,
          render: () => (
            <Toast
              type="error"
              title={`Invoice ${
                invoice.invoiceNumber || ''
              } could not be updated`}
              isClosable={true}
            ></Toast>
          )
        })
        return
      }

      toast({
        id: `paid${invoice.invoiceNumber || ''}`,
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoice ${invoice.invoiceNumber || ''} marked as paid`}
            isClosable={true}
          ></Toast>
        )
      })
    },
    label: invoicePaymentStatusMenuLabel('PAID'),
    testId: `invoices.${invoice.id}.menu.markAsPaid`
  }
}

const partiallyPaidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: async () => {
      const putStatusRes =
        await dashboard20240730Client.putInvoicePaymentStatus({
          invoiceId: invoice.id,
          paymentStatus: 'PARTIALLY_PAID'
        })

      if (putStatusRes.error) {
        toast({
          id: `paid${invoice.invoiceNumber || ''}`,
          position: 'bottom',
          isClosable: true,
          render: () => (
            <Toast
              type="error"
              title={`Invoice ${
                invoice.invoiceNumber || ''
              } could not be updated`}
              isClosable={true}
            ></Toast>
          )
        })
        return
      }

      toast({
        id: `paid${invoice.invoiceNumber || ''}`,
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoice ${invoice.invoiceNumber || ''} marked as partially paid`}
            isClosable={true}
          ></Toast>
        )
      })
    },
    label: invoicePaymentStatusMenuLabel('PARTIALLY_PAID'),
    testId: `invoices.${invoice.id}.menu.markAsPartiallyPaid`
  }
}

const uncollectibleBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: async () => {
      const putStatusRes =
        await dashboard20240730Client.putInvoicePaymentStatus({
          invoiceId: invoice.id,
          paymentStatus: 'UNCOLLECTIBLE'
        })

      if (putStatusRes.error) {
        toast({
          id: `paid${invoice.invoiceNumber || ''}`,
          position: 'bottom',
          isClosable: true,
          render: () => (
            <Toast
              type="error"
              title={`Invoice ${
                invoice.invoiceNumber || ''
              } could not be updated`}
              isClosable={true}
            ></Toast>
          )
        })
        return
      }

      toast({
        id: `paid${invoice.invoiceNumber || ''}`,
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoice ${invoice.invoiceNumber || ''} marked as uncollectible`}
            isClosable={true}
          ></Toast>
        )
      })
    },
    label: invoicePaymentStatusMenuLabel('UNCOLLECTIBLE'),
    testId: `invoices.${invoice.id}.menu.markAsUncollectible`
  }
}

const unpaidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: async () => {
      const putStatusRes =
        await dashboard20240730Client.putInvoicePaymentStatus({
          invoiceId: invoice.id,
          paymentStatus: 'UNPAID'
        })

      if (putStatusRes.error) {
        toast({
          id: `paid${invoice.invoiceNumber || ''}`,
          position: 'bottom',
          isClosable: true,
          render: () => (
            <Toast
              type="error"
              title={`Invoice ${
                invoice.invoiceNumber || ''
              } could not be updated`}
              isClosable={true}
            ></Toast>
          )
        })
        return
      }

      toast({
        id: `paid${invoice.invoiceNumber || ''}`,
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoice ${invoice.invoiceNumber || ''} marked as unpaid`}
            isClosable={true}
          ></Toast>
        )
      })
    },
    label: invoicePaymentStatusMenuLabel('UNPAID'),
    testId: `invoices.${invoice.id}.menu.markAsUnpaid`
  }
}

export const menuItemBuilders: (
  successToast: (options: UseToastOptions) => void,
  downloadPdf: (props: DownloadInvoicePdfProps) => void
) => MenuItemBuilder<Invoice>[] = (toast, download) => [
  previewBuilder,
  finalizeAndSendBuilder,
  finalizeBuilder,
  sendBuilder,
  voidBuilder,
  paidBuilder(toast),
  partiallyPaidBuilder(toast),
  uncollectibleBuilder(toast),
  unpaidBuilder(toast),
  downloadBuilder(download),
  archiveInvoiceBuilder
]

const invoicePaymentStatusMenuLabel = (status: InvoicePaymentStatus) => (
  <KebabMenuItem>
    <Flex direction={'row'} lineHeight="28px">
      Mark as <Box w="1"></Box>{' '}
      <Badge {...toInvoicePaymentStatusBadgeProps({ status })} />
    </Flex>
  </KebabMenuItem>
)
