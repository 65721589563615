import { ElementType, FC, ReactNode } from 'react'
import type { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { LinkEntityWidget } from '../LinkEntityWidget'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export const LinkProductWidget: FC<{
  entityIcon?: ReactNode
  externalEntityLabel?: string
  sequenceEntityLabel?: string
  productId: string
  service: IntegrationServices
  canUnlink?: boolean
  canLink?: boolean
  noLinkFoundText?: string
  linkModal?: ElementType<{
    productId: string
  }>
}> = props => {
  const serviceName = getIntegrationName(props.service)

  const { data: product, isPending } = useQuery(
    dashboardv99990101Client.getProduct,
    {
      id: props.productId
    },
    {
      select: data => {
        if (!data) {
          return null
        }

        const currentLinkedAccount = data.integrationIds.find(
          int =>
            int.service === props.service &&
            int.externalEntityType === 'PRODUCT'
        )

        return {
          name: data.name,
          linkedAccount: currentLinkedAccount
            ? {
                value: currentLinkedAccount.id,
                href: currentLinkedAccount.url,
                label: `ID: ${currentLinkedAccount.id}`,
                canUnlink: props.canUnlink ?? false,
                isPending: currentLinkedAccount.isPending
              }
            : null
        }
      }
    }
  )

  const LinkModal = props.linkModal

  const sequenceEntityLabel = props.sequenceEntityLabel ?? product?.name ?? ''

  const externalEntityLabel =
    props.externalEntityLabel ?? product?.linkedAccount?.label ?? ''

  return (
    <LinkEntityWidget
      data-testid={`${props.service}.linkProduct`}
      showLinkButton={!!props.linkModal}
      externalEntityLabel={externalEntityLabel}
      sequenceEntityLabel={sequenceEntityLabel}
      isLoaded={!isPending}
      serviceName={serviceName}
      linkedAccount={product?.linkedAccount ? product?.linkedAccount : null}
      noLinkFoundText={props.noLinkFoundText}
      linkModal={
        LinkModal ? <LinkModal productId={props.productId} /> : undefined
      }
    />
  )
}
