import { Box, Flex, Tooltip, keyframes } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  Lato14Bold,
  Lato13Regular,
  GreyGrey60
} from '@sequencehq/design-tokens'
import { Card, InputSelectControlField } from '@sequencehq/core-components'
import { Dashboardv99990101CommonModels } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useState } from 'react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
const spinAnimation = `${spin} 1s linear infinite`

type QuickbooksAccount =
  Dashboardv99990101CommonModels.QuickbooksAccount.QuickbooksAccount

interface Props {
  fieldsConfig: {
    defaultAccount: {
      value: string
      onChange: (value: string) => void
    }
  }
  quickbooksAccounts: QuickbooksAccount[]
}

export const DefaultQuickBooksAccountCard = ({
  fieldsConfig,
  quickbooksAccounts
}: Props) => {
  const [isRefreshing, setIsRefreshing] = useState(false)
  const syncQuickbooksAccountsMutator = useMutation(
    dashboard20240730Client.postSyncQuickbooksAccounts
  )

  const quickbooksAccountsOptions = quickbooksAccounts.map(account => ({
    value: account.id,
    label: account.name ?? account.id
  }))

  return (
    <Card>
      <Flex
        data-testid="quickbooksIntegration.defaultAccount"
        gap={4}
        flexDirection="column"
      >
        <Flex gap={1} flexDirection="column">
          <Box {...Lato14Bold}>Accounts</Box>
          <Box {...Lato13Regular} color={GreyGrey70}>
            Set a default account for unmapped invoice line items or products.
            To map products to QuickBooks Items, edit the product directly.
          </Box>
        </Flex>
        <Flex
          backgroundColor={GreyGrey10}
          borderRadius={6}
          alignItems="center"
          alignContent="center"
          width="100%"
          p={4}
          // Input select control fields have a bottom 16px padding to account for possible validation errors
          // this pb={0} mitigates that in the layout.
          pb={0}
          gap={2}
        >
          <InputSelectControlField
            width="400px"
            label="Default account name"
            data-testid="quickbooksIntegration.defaultAccount.account"
            placeholder="Select default account"
            options={quickbooksAccountsOptions}
            initialValue={fieldsConfig.defaultAccount.value}
            onChange={fieldsConfig.defaultAccount.onChange}
            disabled={isRefreshing}
          />
          <Tooltip label="Trigger a refresh" placement="top" hasArrow>
            <Box
              height="16px"
              width="16px"
              marginTop="8px"
              cursor="pointer"
              as="span"
              animation={isRefreshing ? spinAnimation : 'none'}
              display="inline-flex"
              onClick={async () => {
                setIsRefreshing(true)

                await syncQuickbooksAccountsMutator.mutateAsync()

                setTimeout(() => {
                  setIsRefreshing(false)
                }, 1000)
              }}
              data-testid="quickbooksIntegration.defaultAccount.refreshAccounts"
              aria-label="Refresh accounts"
            >
              <ArrowPathIcon color={GreyGrey60} width={16} height={16} />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
    </Card>
  )
}
