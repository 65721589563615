import { Tooltip } from '@visx/xychart'
import { XYChartItem } from './XYChart'
import { primaryColor } from './theme'

export interface XYChartTooltipProps<T extends XYChartItem> {
  renderTooltip: (data: T) => React.ReactNode
}

export const XYChartTooltip = <T extends XYChartItem>({
  renderTooltip
}: XYChartTooltipProps<T>) => {
  return (
    <Tooltip<T>
      showVerticalCrosshair
      snapTooltipToDatumY
      verticalCrosshairStyle={{
        stroke: primaryColor,
        strokeWidth: 1
      }}
      /**
       * This ensures the tooltip appears correctly,
       * even when rendered in a modal/drawer.
       */
      style={{
        position: 'absolute',
        zIndex: 2000
      }}
      renderTooltip={({ tooltipData }) => {
        if (!tooltipData || !tooltipData.nearestDatum) {
          return null
        }

        return renderTooltip(tooltipData.nearestDatum.datum)
      }}
    />
  )
}
