import { useNotifications } from 'lib/hooks/useNotifications'
import { INITIAL_PRICING_EDITOR_STATE } from './pricingEditor.constants'
import {
  UpdatePricingEditorData,
  PricingEditorDomainInterface,
  PricingEditorPortImplementation,
  PricingEditorReducerState,
  PricingModel,
  RecursivePartial,
  PricingEditorPortErrors
} from './pricingEditor.domain.types'
import { pricingEditorReducer } from './pricingEditor.reducer'
import { useCallback, useMemo, useReducer } from 'react'

type UsePricingEditorDomain = (props: {
  ports: PricingEditorPortImplementation
  afterSave?: (data: { price: any }) => void
}) => PricingEditorDomainInterface

export const usePricingEditorDomain: UsePricingEditorDomain = props => {
  const { displayNotification } = useNotifications()
  const [state, dispatch] = useReducer(
    pricingEditorReducer,
    INITIAL_PRICING_EDITOR_STATE
  )

  const queries = useMemo(() => {
    return {
      ...state.queries,
      rawData: {
        configuration: state.configuration,
        data: state.data,
        editor: state.editor,
        initialData: state.initialData
      }
    }
  }, [state])

  const load = useCallback(async () => {
    const response = await props.ports.in.load()

    if (!response.data) {
      return response
    }

    dispatch({
      type: 'load',
      payload: response.data
    })
    return response
  }, [props.ports.in])

  const updateEditor = useCallback(
    (editorSettings: Partial<PricingEditorReducerState['editor']>) => {
      dispatch({
        type: 'updateEditor',
        payload: editorSettings
      })
    },
    []
  )

  const updatePricingEditorData = useCallback(
    (newData: RecursivePartial<UpdatePricingEditorData>) => {
      dispatch({
        type: 'updatePricingEditorData',
        payload: newData
      })
    },
    []
  )

  const updateConfiguration = useCallback(
    (configuration: Partial<PricingEditorReducerState['configuration']>) => {
      dispatch({
        type: 'updateConfiguration',
        payload: configuration
      })
    },
    []
  )

  const updateProduct = useCallback(
    (newData: Partial<PricingEditorReducerState['data']['product']>) => {
      dispatch({
        type: 'updateProduct',
        payload: newData
      })
    },
    []
  )

  const save = useCallback(async () => {
    if (!state.editor.valid || !state.editor.externalLedgersValid) {
      dispatch({
        type: 'updateEditor',
        payload: {
          showValidationErrors: true,
          /**
           * When the ledgers have an error, they may be hidden away! Expand the additional
           * options if they have an error to make sure the Xero errors can be spotted.
           */
          showAdditionalFields: state.editor.showAdditionalFields
            ? true
            : !state.editor.externalLedgersValid
        }
      })
      return false
    }

    const result = await props.ports.out.save(queries)

    if (
      !result.success &&
      result.error ===
        PricingEditorPortErrors.ErrorOnListPriceSaveButProductCreated
    ) {
      /**
       * We need to consider this a success for downstream use cases,
       * as we have created the product successfully. Trying again will
       * duplicate the product.
       */
      props.afterSave?.(result.data)
      return true
    }

    if (!result.success) {
      displayNotification(
        result.error === PricingEditorPortErrors.ErrorOnProductSave
          ? 'Failed to save product'
          : 'Failed to save price',
        {
          type: 'error'
        }
      )
      return false
    }

    if (props.afterSave && result.success) {
      props.afterSave(result.data)
    }

    return true
  }, [
    props.afterSave,
    props.ports.out.save,
    queries,
    state.editor.valid,
    state.editor.externalLedgersValid,
    state.editor.showAdditionalFields,
    displayNotification
  ])

  const updateListPrice = useCallback((listPriceId: string) => {
    dispatch({
      type: 'updateListPrice',
      payload: listPriceId
    })
  }, [])

  const updatePricingModel = useCallback((pricingModel: PricingModel) => {
    dispatch({
      type: 'updatePricingModel',
      payload: pricingModel
    })
  }, [])

  return {
    queries,
    mutators: {
      external: {
        in: {
          load
        },
        out: {
          save
        }
      },
      common: {
        updateConfiguration,
        updateEditor,
        updateProduct,
        updatePricingEditorData,
        updateListPrice,
        updatePricingModel
      }
    }
  }
}
