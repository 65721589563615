import { StandardLayout } from '@sequencehq/core-components'
import { RevenueRecognitionDashboardHeader } from './RevenueRecognitionDashboardHeader'
import { RevenueRecognitionDashboardContent } from './content/RevenueRecognitionDashboardContent'

export const RevenueRecognitionDashboard = () => {
  return (
    <StandardLayout
      header={<RevenueRecognitionDashboardHeader />}
      content={<RevenueRecognitionDashboardContent />}
    />
  )
}
