import { Currency } from '@sequencehq/core-models'
import { Toast } from '@sequencehq/core-components'
import { ToastId, useToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { useQuery } from '@sequencehq/api/utils'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useState } from 'react'

type EnabledCurrenciesResponse =
  | {
      status: 'SUCCESS'
      enabledCurrencies: Currency[]
    }
  | {
      status: 'ERROR'
    }
  | {
      status: 'LOADING'
    }

type UseCurrenciesArgs = {
  toastOnError?: boolean
}

export const useEnabledCurrencies = ({
  toastOnError
}: UseCurrenciesArgs = {}): EnabledCurrenciesResponse => {
  const toast = useToast()
  const [activeErrorToast, setActiveErrorToast] = useState<ToastId | null>(null)
  // Possible next steps
  // - Enable currency sorting / prioritization

  const enabledCurrenciesRes = useQuery(
    dashboard20240730Client.getSequenceAccountSettings,
    undefined,
    {
      select: res => res?.enabledCurrencies
    }
  )

  if (enabledCurrenciesRes.isLoading) {
    if (activeErrorToast) {
      toast.close(activeErrorToast)
      setActiveErrorToast(null)
    }

    return {
      status: 'LOADING'
    }
  }

  if (enabledCurrenciesRes.isSuccess) {
    const enabledCurrencies = enabledCurrenciesRes.data

    if (enabledCurrencies?.length) {
      return {
        status: 'SUCCESS',
        enabledCurrencies: enabledCurrencies
      }
    }
  }

  if (toastOnError && !activeErrorToast) {
    Sentry.captureException('Failed to load enabled currencies')
    const toastId = toast({
      position: 'bottom',
      isClosable: false,
      render: () => (
        <Toast
          type="error"
          title="Failed to load enabled currencies"
          description="Please refresh page to try again"
          isClosable={false}
        />
      )
    })
    setActiveErrorToast(toastId)
  }

  return {
    status: 'ERROR'
  }
}

export const useDefaultCurrency = (): Currency | undefined => {
  const toast = useToast()
  // Possible next steps
  // - Enable currency sorting / prioritization

  const enabledCurrenciesRes = useQuery(
    dashboard20240730Client.getSequenceAccountSettings,
    undefined,
    {
      select: res => res?.enabledCurrencies
    }
  )

  if (enabledCurrenciesRes.isError) {
    toast({
      position: 'bottom',
      isClosable: false,
      render: () => (
        <Toast
          type="error"
          title="Failed to load default currency"
          description="Please refresh page to try again"
          isClosable={false}
        />
      )
    })
  }

  return enabledCurrenciesRes.data?.[0]
}
