import { Font } from '@react-pdf/renderer'

export const registerReactPdfFonts = () => {
  const BASE_URL: string = 'https://dashboard.sequencehq.com'

  Font.register({
    family: 'Lato',
    fonts: [
      {
        src: `${BASE_URL}/fonts/Lato-Regular.ttf`,
        fontStyle: 'normal',
        fontWeight: 'normal'
      },
      {
        src: `${BASE_URL}/fonts/Lato-Bold.ttf`,
        fontStyle: 'normal',
        fontWeight: 'bold'
      },
      {
        src: `${BASE_URL}/fonts/Lato-Italic.ttf`,
        fontStyle: 'italic',
        fontWeight: 'normal'
      },
      {
        src: `${BASE_URL}/fonts/Lato-BoldItalic.ttf`,
        fontStyle: 'italic',
        fontWeight: 'bold'
      }
    ]
  })
}
