import { StandardLayout } from '@sequencehq/core-components'
import { BillingScheduleHomeHeader } from './BillingScheduleHomeHeader'
import { BillingScheduleHomeContent } from './BillingScheduleHomeContent/BillingScheduleHomeContent'

export const BillingScheduleHome = () => {
  return (
    <StandardLayout
      header={<BillingScheduleHomeHeader />}
      content={<BillingScheduleHomeContent />}
    />
  )
}
