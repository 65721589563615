import { Flex, GridItem } from '@chakra-ui/react'
import { PriceModel } from '@sequencehq/core-models'
import { ProductPriceTiersTable } from 'modules/Products/drawers/ProductPrice/components/ProductPriceTiersTable'
import ProductPriceAdditionalInfo from 'modules/Products/drawers/ProductPrice/components/ProductPriceAdditionalInfo'
import { ProductPriceMeta } from 'modules/Products/drawers/ProductPrice/components/ProductPriceMeta'
import Spinner from 'components/Loading/Spinner'
import { ProductPriceBillingSchedules } from 'modules/Products/drawers/ProductPrice/components/ProductPriceBillingSchedules'
import { ListPrice, Product } from 'modules/Products/types'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const ProductPriceContent = ({
  price,
  product,
  fetchMetadata = true
}: {
  price: PriceModel | ListPrice
  product?: Product
  fetchMetadata?: boolean
}) => {
  const metadataQueryEnabled = !!fetchMetadata
  const metadataQuery = useQuery(
    dashboard20240730Client.getPriceMetadata,
    {
      id: price.id
    },
    { enabled: metadataQueryEnabled }
  )

  if (metadataQuery.isPending && metadataQueryEnabled) {
    return <Spinner />
  }

  const priceMetadata = metadataQuery.data

  return (
    <GridItem gridArea="content" overflow="auto">
      <Flex
        gap="24px"
        padding="24px"
        direction="column"
        data-testid="pricingEditor.review"
      >
        <ProductPriceMeta price={price} product={product} />
        <ProductPriceTiersTable price={price} />
        <ProductPriceAdditionalInfo price={price} />

        {priceMetadata?.billingSchedules &&
          priceMetadata?.billingSchedules.length > 0 && (
            <ProductPriceBillingSchedules priceMetadata={priceMetadata} />
          )}
      </Flex>
    </GridItem>
  )
}
