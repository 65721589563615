import {
  Box,
  Button,
  Center,
  Flex,
  Skeleton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/16/solid'
import { Badge } from '@sequencehq/core-components'
import {
  CreditBalanceModel,
  creditUnitTypeLabelMap,
  toCreditGrantStatusBadgeProps,
  transformCreditGrantExpiry
} from '@sequencehq/core-models'
import {
  GreyGrey30,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { localDateWithFormat } from '@sequencehq/formatters'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const CreditGrantTable = () => {
  const { customer } = useCustomerContext()
  const creditGrantsQuery = useQuery(
    dashboard20240730Client.getCreditGrantsForCustomer,
    {
      customerId: customer.id
    }
  )
  const allCreditGrantQuery = useQuery(
    dashboard20240730Client.getAllCreditGrants,
    {
      customerId: customer.id,
      limit: 12
    }
  )

  const creditBalances = creditGrantsQuery?.data
  const creditGrants = allCreditGrantQuery?.data

  if (!creditGrants || !creditBalances) {
    return (
      <Center height="200px" width="100%" mt={4}>
        <Spinner />
      </Center>
    )
  }
  return (
    <>
      <TableContainer
        borderRadius="lg"
        overflow="hidden"
        border={`1px solid ${GreyGrey30}`}
        mt={4}
        width="100%"
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)"
      >
        <Table variant="v2" width="100%">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th width={120}>Status</Th>
              <Th width={120}>Balance</Th>
              <Th width={120}>Type</Th>
              <Th width={200}>Usage Metric</Th>
              <Th width={120}>Issued</Th>
              <Th width={120}>Expiry date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {creditGrants.items.length > 0 ? (
              creditGrants.items.map(creditGrant => {
                const loadedBalance: CreditBalanceModel | undefined =
                  creditBalances.items.find(
                    balance => balance.id === creditGrant.id
                  )

                const balance = loadedBalance
                  ? `${
                      loadedBalance.balance
                    } / ${creditGrant.amount.toString()}`
                  : `${creditGrant.amount.toString()} / ${creditGrant.amount.toString()}`

                return (
                  <Tr key={creditGrant.id}>
                    <Td>
                      <Text {...Lato13Bold} color="#5F5CFF">
                        {creditGrant.name}
                      </Text>
                    </Td>
                    <Td>
                      <Flex gap={2} alignItems="center">
                        <Badge
                          {...toCreditGrantStatusBadgeProps({
                            status: transformCreditGrantExpiry(
                              creditGrant.expiryDate
                            ),
                            size: 'sm'
                          })}
                        />
                      </Flex>
                    </Td>
                    <Td>{balance}</Td>
                    <Td>
                      {creditUnitTypeLabelMap[creditGrant.creditUnitType]}
                    </Td>
                    <Td>
                      {creditGrant.metricId ? (
                        <CreditGrantUsageMetric
                          metricId={creditGrant.metricId}
                        />
                      ) : (
                        '-'
                      )}
                    </Td>
                    <Td>
                      {creditGrant.createdAt
                        ? localDateWithFormat(creditGrant.createdAt, 'dd/MM/yy')
                        : '-'}
                    </Td>
                    <Td>
                      {creditGrant.expiryDate
                        ? localDateWithFormat(
                            creditGrant.expiryDate,
                            'dd/MM/yy'
                          )
                        : 'Never'}
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <Tr
                sx={{
                  cursor: 'auto!important',
                  _hover: {
                    bgColor: 'inherit!important'
                  }
                }}
              >
                <Td colSpan={7}>
                  <EmptyContent />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {creditGrants.items.length > 12 && (
        <Button
          mt={2}
          {...Lato13Bold}
          variant="component-library-ghost"
          rightIcon={<ArrowRightIcon color="#5F5CFF" height={16} width={16} />}
          as={Link}
          to={`/credit-notes?customerId=${customer.id}`}
        >
          View all credit grants
        </Button>
      )}
    </>
  )
}

const EmptyContent = () => {
  return (
    <Box width="400px" margin="12px auto" textAlign="center">
      <Text {...Lato13Bold} color="gray.90">
        No credit grants to display
      </Text>
      <Text {...Lato13Regular} color="gray.80">
        You have not created credit grants for this customer yet.
      </Text>
    </Box>
  )
}

const CreditGrantUsageMetric = ({ metricId }: { metricId: string }) => {
  const usageMetricQuery = useQuery(
    dashboard20240730Client.getUsageMetric,
    {
      id: metricId ?? ''
    },
    {
      enabled: !!metricId
    }
  )

  const metricName = useMemo(() => {
    const usageMetric = usageMetricQuery.data
    return usageMetric
      ? `${usageMetric.name} (${usageMetric.aggregationType.toLowerCase()})`
      : '-'
  }, [usageMetricQuery.data])

  if (usageMetricQuery.isPending) {
    return <Skeleton width="100px" height="20px" />
  }

  return <>{metricName}</>
}
