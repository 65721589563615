import {
  Box,
  Center,
  Flex,
  Spinner,
  Tooltip,
  useToast,
  UseToastOptions
} from '@chakra-ui/react'
import { KebabMenu } from '@sequencehq/core-components'
import { toMoney } from '@sequencehq/core-models'
import { borderColor } from '@sequencehq/core-theme'
import { localDateWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableCellInvoiceStatus,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { useInvoicePdfDownload } from 'InvoiceEditor/components/Header/useInvoicePdfDownload'
import { menuItemBuilders } from 'components/Invoices/menuItemBuilders'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { InvoicesViewSettingsHeader } from 'components/Invoices/InvoicesViewSettingsHeader'
import { FC, useState } from 'react'
import { InvoicesSegmentedFilters } from 'components/Invoices/InvoicesSegmentedFilters'
import { addPaddingToIntegrationLogo, getLogo } from 'lib/integrations/logos'
import { formatDateRange, formatDistanceToNow } from '@sequencehq/utils/dates'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { InvoicesFilters, InvoicesPropFilters } from '../types'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { BulkActionsInFlight } from 'modules/BulkActions/BulkActionsInFlight'
import { useDispatch } from 'features/store'
import { openOverlay } from 'features/overlay'
import RectangleStackIcon from '@heroicons/react/24/solid/RectangleStackIcon'
import { ActiveFilter } from '@sequencehq/tables'
import { useInvoicesMagicTable } from './useInvoicesMagicTable'
import { useNavigate } from 'react-router-dom'
import { useIsOnSavedViewPage } from 'SavedViews/savedViews.router'
import { InvoiceBulkActions } from '../InvoiceBulkActions'
import {
  GreyGrey30,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  YellowYellow10,
  YellowYellow60,
  YellowYellow80
} from '@sequencehq/design-tokens'
import { SelectAllInvoices } from './SelectAllInvoices'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice
type InvoicesView = {
  model: Invoice
  controlFilters: InvoicesFilters
  propFilters: InvoicesPropFilters
}

type Props = {
  onSaveFilters?: (activeFilters: ActiveFilter[]) => void
  onUpdateSavedFilters?: (activeFilters: ActiveFilter[]) => void
  showSegmentedFilters?: boolean
  forcedActiveFilters?: ActiveFilter[]
  onFiltersApiQueryParamsChanged: (newParams: string) => void
  lockedControls: boolean
}

export const InvoicesMagicTable: FC<Props> = ({
  onSaveFilters,
  onUpdateSavedFilters,
  showSegmentedFilters = false,
  forcedActiveFilters = [],
  onFiltersApiQueryParamsChanged,
  lockedControls
}) => {
  const flags = useFlags()
  const toast = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { downloadPdf } = useInvoicePdfDownload()
  const isSavedView = useIsOnSavedViewPage()
  const [disableBulkActionCheckboxes, setDisableBulkActionCheckboxes] =
    useState<boolean>(false)

  const successToast = (options: UseToastOptions) => {
    if (options.id) {
      toast(options)
    }
  }

  const {
    isLoading,
    filters,
    activeFilters,
    sortBy,
    onChangeActiveFilters,
    onChangeSortBy,
    selectedInvoices,
    setSelectedInvoices,
    infiniteQuery,
    selectAll
  } = useInvoicesMagicTable({
    forcedActiveFilters,
    onFiltersApiQueryParamsChanged
  })

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    )
  }

  const additionalToolbarComponentsV3 = showSegmentedFilters
    ? {
        additionalToolbarComponentsV3: {
          start: <InvoicesSegmentedFilters />
        }
      }
    : {}

  return (
    <CurrentUserId>
      {userId => (
        <>
          <MagicTable<InvoicesView>
            additionalTableHeaderRow={({ visibleTableWidth }) => {
              if (!selectAll.showPrompt) {
                return null
              }

              return (
                <SelectAllInvoices
                  onSelectAll={selectAll.onSelectAll}
                  showClearSelection={selectAll.showClearSelection}
                  onClearSelection={selectAll.onClearSelection}
                  visibleTableWidth={visibleTableWidth}
                  loading={selectAll.loading}
                  error={selectAll.error}
                  totalInvoices={selectAll.totalInvoices}
                  totalSelectedInvoices={selectAll.totalSelectedInvoices}
                />
              )
            }}
            disableCheckboxes={disableBulkActionCheckboxes || selectAll.loading}
            activeFilters={activeFilters}
            onChangeActiveFilters={onChangeActiveFilters}
            sortBy={sortBy}
            onChangeSortBy={onChangeSortBy}
            entityNamePlural="invoices"
            sequenceUserId={userId}
            infiniteQuery={infiniteQuery}
            rowPath={(row: InvoicesView['model']) => `/invoices/${row.id}`}
            columns={[
              {
                id: 'grossTotal',
                header: 'Amount',
                accessorFn: row => ({
                  currency: row.currency,
                  value: row.grossTotal
                }),
                cell: value => (
                  <MagicTableCell
                    text={toMoney(
                      value.getValue<{
                        currency: Invoice['currency']
                        value: Invoice['grossTotal']
                      }>()
                    )}
                    textAlign={value.column.columnDef?.meta?.textAlign}
                  />
                ),
                enableSorting: true,
                meta: { textAlign: 'right' }
              },
              {
                id: 'currency',
                header: 'Currency',
                accessorKey: 'currency',
                cell: value => {
                  const currency = value.getValue<Invoice['currency']>()

                  return <MagicTableCell text={currency} />
                }
              },
              {
                id: 'status',
                header: 'Status',
                accessorFn: row => ({
                  invoiceStatus: row.status,
                  paymentStatus: row.paymentStatus
                }),
                cell: value => {
                  const { invoiceStatus, paymentStatus } = value.getValue<{
                    invoiceStatus: Invoice['status']
                    paymentStatus: Invoice['paymentStatus']
                  }>()

                  return (
                    <MagicTableCellInvoiceStatus
                      invoiceStatus={invoiceStatus}
                      paymentStatus={paymentStatus}
                    />
                  )
                }
              },
              {
                id: 'invoiceNumber',
                header: 'Invoice #',
                accessorKey: 'invoiceNumber',
                cell: value => {
                  const invoiceNumber =
                    value.getValue<Invoice['invoiceNumber']>()

                  if (!invoiceNumber) {
                    return null
                  }

                  return <MagicTableCell text={invoiceNumber} />
                }
              },
              {
                id: 'customerLegalCompanyName',
                header: 'Customer',
                accessorFn: row => ({
                  customerId: row.customerId,
                  customerLegalCompanyName: row.customerLegalCompanyName
                }),
                cell: value => {
                  const { customerId, customerLegalCompanyName } =
                    value.getValue<{
                      customerId: Invoice['customerId']
                      customerLegalCompanyName: Invoice['customerLegalCompanyName']
                    }>()

                  return (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      height="40px"
                      pl="6px"
                    >
                      <CustomerPreviewCardPill
                        customerId={customerId}
                        variant="ghost"
                        alternativeName={customerLegalCompanyName}
                      />
                    </Flex>
                  )
                },
                enableSorting: true
              },
              {
                id: 'billingPeriod',
                header: 'Billing Period',
                accessorFn: row => ({
                  billingPeriod: row.billingPeriod
                }),
                cell: value => {
                  const { billingPeriod } = value.getValue<{
                    billingPeriod: Invoice['billingPeriod']
                  }>()

                  return (
                    <MagicTableCell
                      text={
                        billingPeriod
                          ? formatDateRange({
                              from: new Date(billingPeriod.start),
                              to: new Date(billingPeriod.endInclusive)
                            })
                          : ''
                      }
                    />
                  )
                }
              },
              {
                id: 'issueDate',
                header: 'Sent',
                accessorKey: 'issueDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['issueDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                )
              },
              {
                id: 'dueDate',
                header: 'Due',
                accessorKey: 'dueDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['dueDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                )
              },
              {
                id: 'accountingDate',
                header: 'Date',
                accessorKey: 'accountingDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['accountingDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                ),
                enableSorting: true
              },
              {
                id: 'lastRecalculated',
                header: 'Last Recalculated',
                accessorKey: 'calculatedAt',
                cell: row => {
                  const calculatedAt = row.getValue<Invoice['calculatedAt']>()

                  return (
                    <MagicTableCell
                      text={
                        calculatedAt
                          ? formatDistanceToNow(new Date(calculatedAt), {
                              addSuffix: true
                            })
                          : ''
                      }
                    />
                  )
                }
              },
              {
                id: 'linkedServices',
                header: 'Links',
                accessorKey: 'linkedServices',
                cell: row => {
                  const linkedServices =
                    row.getValue<Invoice['linkedServices']>()

                  if (!linkedServices.length) {
                    return null
                  }

                  return (
                    <MagicTableCellEmpty>
                      <Flex gap="4px" overflow="hidden">
                        {linkedServices.map(linkedService => (
                          <Tooltip
                            key={linkedService.externalService}
                            label={linkedService.externalService.replace(
                              /_/g,
                              ''
                            )}
                          >
                            <Center
                              boxSize="24px"
                              bg={borderColor}
                              borderRadius="24px"
                            >
                              {getLogo(
                                linkedService.externalService,
                                addPaddingToIntegrationLogo(
                                  linkedService.externalService
                                )
                                  ? 18
                                  : undefined
                              )}
                            </Center>
                          </Tooltip>
                        ))}
                      </Flex>
                    </MagicTableCellEmpty>
                  )
                }
              }
            ]}
            kebabMenu={(model: Invoice, props) => (
              <KebabMenu
                data-testid={`invoices.${model.id}.menu`}
                isLazy
                menuItems={menuItemBuilders(successToast, downloadPdf)
                  .map(builder => builder(model))
                  .filter(builder => builder.status === 'LIVE')}
                flags={flags}
                {...props}
              />
            )}
            filters={filters}
            emptyStateUnfiltered={entityNamePlural => (
              <MagicTableEmptyState
                entityNamePlural={entityNamePlural}
                imageSrc="/img/empty/invoices.png"
                learnMoreText="Auto-invoice customers with billing schedules or create a one-off invoice"
                learnMoreHref="https://docs.sequencehq.com/invoicing/invoicing-customers"
                buttonNew={{
                  label: 'New invoice',
                  onClick: () => {
                    dispatch(
                      openOverlay({
                        content: 'createInvoiceModal',
                        data: {
                          customer: undefined
                        }
                      })
                    )
                  }
                }}
              />
            )}
            emptyStateFiltered={(entityNamePlural, clearFilters) => (
              <MagicTableFilterEmptyState
                entityNamePlural={entityNamePlural}
                entityIcon={RectangleStackIcon}
                actionClearFilters={clearFilters}
                actionViewAll={() => navigate('/invoices')}
                variant={isSavedView ? 'SAVED_VIEW' : 'TABLE'}
              />
            )}
            bulkActions={{
              selectedItems: selectedInvoices,
              onSelectedItemsChange: setSelectedInvoices,
              bar: (selectedRows: Invoice[], clearSelectedRows: () => void) => {
                if (flags.disableBulkActions || selectAll.loading) {
                  return undefined
                }

                const bulkActionBar = (
                  <InvoiceBulkActions
                    selectedRows={selectedRows}
                    clearSelectedRows={clearSelectedRows}
                    popoverOffset={[0, 0]}
                  >
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    />
                  </InvoiceBulkActions>
                )

                if (!flags.useBulkActionsV2Frontend) {
                  return bulkActionBar
                }
                return (
                  <BulkActionsInFlight
                    fallback={bulkActionBar}
                    selectionCount={selectedRows.length}
                    onBulkActionCountChange={bulkActionCount => {
                      setDisableBulkActionCheckboxes(bulkActionCount > 0)
                    }}
                  />
                )
              }
            }}
            viewSettingsHeader={<InvoicesViewSettingsHeader />}
            onSaveFilters={
              onSaveFilters ? () => onSaveFilters?.(activeFilters) : undefined
            }
            onUpdateSavedFilters={
              onUpdateSavedFilters
                ? () => onUpdateSavedFilters?.(activeFilters)
                : undefined
            }
            lockedControls={lockedControls}
            {...additionalToolbarComponentsV3}
          />
        </>
      )}
    </CurrentUserId>
  )
}
