import uniq from 'lodash/fp/uniq'
import { BulkAction } from './types'

export function allIds(
  bulkAction: Pick<
    BulkAction,
    'requestedIds' | 'completedIds' | 'failedIds' | 'skippedIds'
  >
): Array<string> {
  return uniq(
    [
      ...bulkAction.requestedIds,
      ...bulkAction.completedIds,
      ...bulkAction.skippedIds,
      ...bulkAction.failedIds
    ].sort()
  )
}

export function allCompletedIds(
  bulkAction: Pick<BulkAction, 'completedIds' | 'failedIds' | 'skippedIds'>
): Array<string> {
  return uniq(
    [
      ...bulkAction.completedIds,
      ...bulkAction.failedIds,
      ...bulkAction.skippedIds
    ].sort()
  )
}

export function allSuccessfulIds(
  bulkAction: Pick<BulkAction, 'completedIds'>
): Array<string> {
  return uniq([...bulkAction.completedIds].sort())
}

export function allFailedIds(
  bulkAction: Pick<BulkAction, 'failedIds'>
): Array<string> {
  return uniq([...bulkAction.failedIds].sort())
}
