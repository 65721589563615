import pick from 'lodash/fp/pick'
import { dequal } from 'dequal'
import { ListPrice, VariantPrice } from 'modules/Products/types'

/**
 * We only want to check properties relevant to the 'pricing structure'
 * for our price dedupe check, rather than all data in the price.
 */
const pickCheckablePriceProperties = pick([
  'structure',
  'currency',
  'productId',
  'name',
  'externalIds',
  'billingFrequency',
  'billingType'
])

export const getUniquePrices = (
  prices: Array<ListPrice | VariantPrice>
): Array<ListPrice | VariantPrice> => {
  return prices.reduce((acc: Array<ListPrice | VariantPrice>, price) => {
    const matchingPrice = Object.values(acc).find(existingPrice =>
      dequal(
        pickCheckablePriceProperties(existingPrice),
        pickCheckablePriceProperties(price)
      )
    )

    if (matchingPrice) {
      return acc
    }

    return [...acc, price]
  }, [])
}
