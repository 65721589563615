/**
 * Animation constants for the NavigationV3 components
 * These values are used to ensure consistent animations across all navigation elements
 */

import { SystemStyleObject } from '@chakra-ui/react'

export const NAVIGATION_ANIMATION = {
  // Width transition for the navigation container
  WIDTH_TRANSITION: '0.3s ease',

  // Text fade transitions
  FADE_TRANSITION: '0.25s ease',
  FADE_OUT_DURATION: '0.2s',
  FADE_IN_DURATION: '0.2s',
  FADE_IN_DELAY: '0.1s',
  FADE_OUT_DELAY: '0s',

  // Easing functions - optimized for performance
  EASE_OUT: 'cubic-bezier(0.16, 1, 0.3, 1)',
  EASE_IN: 'cubic-bezier(0.32, 0, 0.67, 0)',

  // Tooltip delays
  TOOLTIP_DELAY: 500,

  // Text transition
  TEXT_TRANSITION: '0.2s ease-out'
}

/**
 * CSS keyframes for fade animations
 * These can be used in the sx prop of Chakra UI components
 */
export const FADE_KEYFRAMES = {
  '@keyframes fadeOut': {
    '0%': { opacity: 1, transform: 'translateZ(0) scale(1)' },
    '100%': { opacity: 0, transform: 'translateZ(0) scale(0.95)' }
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'translateZ(0) scale(0.95)' },
    '100%': { opacity: 1, transform: 'translateZ(0) scale(1)' }
  },
  '@keyframes slideRight': {
    '0%': { transform: 'translateZ(0) translateX(-5px)' },
    '100%': { transform: 'translateZ(0) translateX(0)' }
  },
  '@keyframes slideLeft': {
    '0%': { transform: 'translateZ(0) translateX(5px)' },
    '100%': { transform: 'translateZ(0) translateX(0)' }
  }
}

/**
 * Creates animation styles for the navigation container based on collapsed state
 * @param isCollapsed Whether the navigation is collapsed
 * @returns Object with animation styles for the sx prop
 */
export function getNavigationAnimationStyles(
  isCollapsed: boolean
): SystemStyleObject {
  return {
    ...FADE_KEYFRAMES,
    // Base styles for all child elements
    '& *': {
      // Prevent text from wrapping during transition
      whiteSpace: 'nowrap'
    },
    // Styles for text elements with the nav-text class
    '& .nav-text': {
      // Performance optimizations
      willChange: 'opacity, transform',
      // When collapsed, fade out text with a slight delay
      ...(isCollapsed
        ? {
            opacity: 0,
            transform: 'translateZ(0) scale(0.95)',
            transition: `opacity ${NAVIGATION_ANIMATION.FADE_OUT_DURATION} ${NAVIGATION_ANIMATION.EASE_OUT} ${NAVIGATION_ANIMATION.FADE_OUT_DELAY}, transform ${NAVIGATION_ANIMATION.FADE_OUT_DURATION} ${NAVIGATION_ANIMATION.EASE_OUT} ${NAVIGATION_ANIMATION.FADE_OUT_DELAY}`
          }
        : {
            // When expanded, fade in text with a slight delay
            opacity: 1,
            transform: 'translateZ(0) scale(1)',
            transition: `opacity ${NAVIGATION_ANIMATION.FADE_IN_DURATION} ${NAVIGATION_ANIMATION.EASE_IN} ${NAVIGATION_ANIMATION.FADE_IN_DELAY}, transform ${NAVIGATION_ANIMATION.FADE_IN_DURATION} ${NAVIGATION_ANIMATION.EASE_IN} ${NAVIGATION_ANIMATION.FADE_IN_DELAY}`
          })
    },
    // Respect user's preference for reduced motion
    '@media (prefers-reduced-motion: reduce)': {
      '& .nav-text': {
        transition: 'none',
        transform: 'none'
      }
    }
  }
}
