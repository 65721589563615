import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  endOfMonth,
  startOfMonth,
  parse,
  format
} from '@sequencehq/utils/dist/dates'

const formatDateForUrl = (date: Date) => {
  return format(date, 'yyyy-MM-dd')
}

const parseDateFromUrl = (str: string) => {
  return parse(str, 'yyyy-MM-dd', new Date())
}

export const useSelectedDateRange = (): {
  dateRange: [Date, Date]
  setDateRange: (range: [Date, Date]) => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  const startDateFromUrl = searchParams.get('startDate')
  const endDateFromUrl = searchParams.get('endDate')

  const getDateRange = useCallback((): [Date, Date] => {
    if (!startDateFromUrl || !endDateFromUrl) {
      return [startOfMonth(new Date()), endOfMonth(new Date())]
    }

    return [
      parseDateFromUrl(startDateFromUrl),
      parseDateFromUrl(endDateFromUrl)
    ]
  }, [startDateFromUrl, endDateFromUrl])

  const setDateRange = useCallback(
    (range: [Date, Date]) => {
      setSearchParams(
        params => {
          params.set('startDate', formatDateForUrl(range[0]))
          params.set('endDate', formatDateForUrl(range[1]))
          return params
        },
        { replace: true }
      )
    },
    [setSearchParams]
  )

  const dateRange = getDateRange()

  return {
    dateRange,
    setDateRange
  }
}
