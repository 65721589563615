import { Flex } from '@chakra-ui/react'
import { RadioButtonGroup } from '@sequencehq/core-components'
import { useMagicTableToolbarContext } from '@sequencehq/tables'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type ActiveQuoteFilter = 'all' | 'draft' | 'published' | 'accepted' | 'executed'

export const QuotesSegmentedFilters = () => {
  const [activeFilter, setActiveFilter] = useState<ActiveQuoteFilter>('all')
  const [searchParams] = useSearchParams()

  const ctx = useMagicTableToolbarContext()

  useEffect(() => {
    const sortBy = searchParams.get('sortBy')
    const status = searchParams.get('status')

    if (sortBy === 'updatedAt_desc' && !status) {
      setActiveFilter('all')
    } else if (status === 'DRAFT') {
      setActiveFilter('draft')
    } else if (status === 'PUBLISHED') {
      setActiveFilter('published')
    } else if (status === 'ACCEPTED') {
      setActiveFilter('accepted')
    } else if (status === 'EXECUTED') {
      setActiveFilter('executed')
    } else {
      setActiveFilter('all')
    }
  }, [searchParams])

  const handleFilterChange = useCallback(
    (value: string) => {
      switch (value) {
        case 'all':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'status',
            values: []
          })
          break
        case 'draft':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'status',
            values: ['DRAFT']
          })
          break
        case 'published':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'status',
            values: ['PUBLISHED']
          })
          break
        case 'accepted':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'status',
            values: ['ACCEPTED']
          })
          break
        case 'executed':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'status',
            values: ['EXECUTED']
          })
          break
        default:
          break
      }
    },
    [ctx.functions]
  )

  const disabled = useMemo(
    () => !ctx.queries.canChangeFilters(),
    [ctx.queries.canChangeFilters]
  )

  return (
    <Flex alignItems="start">
      <RadioButtonGroup
        onChange={handleFilterChange}
        initialValue={activeFilter}
        value={activeFilter}
        options={[
          { value: 'all', label: 'All', disabled },
          { value: 'draft', label: 'Draft', disabled },
          { value: 'published', label: 'Published', disabled },
          { value: 'accepted', label: 'Signed', disabled },
          { value: 'executed', label: 'Executed', disabled }
        ]}
      />
    </Flex>
  )
}
