import { useMemo } from 'react'
import { InputSelectControl } from '@sequencehq/core-components'
import { required } from '@sequencehq/validation'
import { useQuery } from '@sequencehq/api/utils'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { currencyToName } from '@sequencehq/core-models'
import { match } from 'ts-pattern'
import { FormFieldConfig } from '@sequencehq/utils'
import { useForm } from '@sequencehq/utils'

type CurrencyFormFields = FormFieldConfig<{
  currency: string
}>

const CurrencyInputSelectControl = (
  props: React.ComponentProps<typeof InputSelectControl> & CurrencyFormFields
) => {
  const allProps = {
    ...props,
    initialValue: props.value,
    options: props.options,
    'data-testid': 'createNew.currency',
    placeholder: props.placeholder
  }

  return <InputSelectControl {...allProps} />
}

export const useQuickBooksCustomerFields = ({
  skip = false
}: {
  skip?: boolean
}) => {
  const queriesEnabled = !skip
  const { data: accountSettings, isFetching: fetchingSequenceAccount } =
    useQuery(dashboard20240730Client.getSequenceAccountSettings, undefined, {
      enabled: queriesEnabled
    })

  const { data: accountPreferences, isFetching: fetchingAccountPreferences } =
    useQuery(
      dashboard20240730Client.getQuickbooksAccountPreferences,
      undefined,
      {
        enabled: queriesEnabled
      }
    )

  const currencyOptions = useMemo(
    () =>
      accountSettings?.enabledCurrencies?.map(ccy => ({
        value: ccy,
        label: `${ccy} - ${currencyToName[ccy]}`
      })) ?? [],
    [accountSettings?.enabledCurrencies]
  )

  const serviceSpecificFields = useMemo(
    () => [
      {
        property: 'currency',
        validation: [required],
        initialValue: '',
        component: CurrencyInputSelectControl,
        options: currencyOptions,
        placeholder:
          queriesEnabled &&
          (fetchingAccountPreferences || fetchingSequenceAccount)
            ? 'Loading...'
            : 'Select currency'
      }
    ],
    [
      currencyOptions,
      fetchingAccountPreferences,
      fetchingSequenceAccount,
      queriesEnabled
    ]
  )

  const multiCurrencyEnabled = accountPreferences?.multiCurrencyEnabled ?? false
  const homeCurrency = accountPreferences?.homeCurrency ?? ''

  const serviceSpecificInitialValues = serviceSpecificFields.reduce(
    (acc, curr) => {
      const initialValue = match(curr.property)
        .with('currency', () =>
          curr.initialValue ? curr.initialValue : homeCurrency
        )
        .otherwise(() => curr.initialValue)

      return {
        ...acc,
        [curr.property]: initialValue
      }
    },
    {} as Record<(typeof serviceSpecificFields)[number]['property'], string>
  )

  const serviceSpecificProperties = useMemo(() => {
    return serviceSpecificFields.map(field => ({
      property: field.property,
      validation: [...field.validation],
      options: currencyOptions,
      disabled: () =>
        match(field.property)
          .with('currency', () => !multiCurrencyEnabled)
          .otherwise(() => false)
    }))
  }, [multiCurrencyEnabled, currencyOptions, serviceSpecificFields])

  const { fields: serviceSpecificFormFields, queries: serviceSpecificQueries } =
    useForm({
      value: serviceSpecificInitialValues,
      fieldConfiguration: serviceSpecificProperties
    })
  if (skip) {
    return {
      serviceSpecificFields: [],
      serviceSpecificFormFields: {},
      serviceSpecificQueries: {
        isValid: true
      }
    }
  }
  return {
    serviceSpecificFields,
    serviceSpecificFormFields,
    serviceSpecificQueries
  }
}
