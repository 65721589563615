import { Flex } from '@chakra-ui/react'
import { MutationFormProps } from 'components/Form/types'
import { UpdateCreditNoteStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import { noReturn } from 'lib/noReturn'
import { QueryParams } from 'lib/types'
import { FC } from 'react'
import { Form } from 'react-final-form'

const FinalizeCreditNoteForm: FC<
  MutationFormProps<QueryParams> & UpdateCreditNoteStatusFormProps
> = ({ id, customerName, submitForm, formId, onSuccess }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          }).then(() => {
            onSuccess?.()
          })
        ]

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Lock this credit note and prevent editing. Once finalized, you can send the credit note to ${customerName}.`}
          </Flex>
        )
      }}
    />
  )
}

export default FinalizeCreditNoteForm
