import { Box, Flex } from '@chakra-ui/react'
import Spinner from 'components/Loading/Spinner'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  IntegrationServices,
  useMutation,
  useQuery,
  useRunInvalidationForMutation
} from '@sequencehq/api/dist/utils'
import { Button, Skeleton } from '@sequencehq/core-components'
import {
  GreyGrey10,
  GreyGrey40,
  GreyGrey60,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { ExistingEntityLink } from '../LinkEntityWidget/ExistingEntityLink'
import { UnlinkInvoiceModal } from './UnlinkInvoiceModal'
import { useNotifications } from 'lib/hooks/useNotifications'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'

interface LinkedInvoiceWidgetProps {
  invoiceId: string
  service: IntegrationServices
}

const useLinkedInvoiceWidget = (props: {
  service: IntegrationServices
  invoiceId: string
}) => {
  const serviceName = getIntegrationName(props.service)
  const { displayNotification } = useNotifications()
  const runMutationInvalidation = useRunInvalidationForMutation()

  const invoiceQuery = useQuery(
    dashboard20240730Client.getInvoice,
    { id: props.invoiceId },
    {
      select: invoice => {
        if (!invoice) {
          return null
        }

        const externalInvoice = invoice.linkedServices.find(
          ls => ls.externalService === props.service
        )

        return {
          customerId: invoice.customerId,
          statusAllowsLink: ['SENT', 'FINAL', 'VOIDED'].includes(
            invoice.status
          ),
          linkedInvoice: externalInvoice
            ? {
                id: externalInvoice.externalId,
                url: externalInvoice.externalUrl
              }
            : null,
          stripeEnabled: Boolean(invoice.paymentOptions?.includes('LINK'))
        }
      }
    }
  )

  const customerQuery = useQuery(
    dashboardv99990101Client.getCustomer,
    { id: invoiceQuery.data?.customerId ?? '' },
    {
      enabled: Boolean(invoiceQuery.data?.customerId),
      select: customer => {
        if (!customer) {
          return null
        }

        const customerLink = customer.integrationIds.find(
          int => int.service === props.service
        )

        return customerLink
      }
    }
  )

  const linkingMutation = useMutation(
    dashboard20240730Client.postPushInvoiceToExternalService
  )

  const linkInvoice = () => {
    linkingMutation.mutate(
      {
        invoiceId: props.invoiceId,
        service: props.service
      },
      {
        onError: () => {
          displayNotification(`Failed to sync invoice to ${serviceName}`, {
            type: 'error'
          })
        },
        onSuccess: () => {
          displayNotification(`Invoice synced to ${serviceName}`, {
            type: 'success'
          })
          /**
           * The external URL doesn't appear immediately, so wait a little while
           * and then bust the cache to ensure we load in the updated data.
           */
          setTimeout(() => {
            runMutationInvalidation(
              dashboard20240730Client.postPushInvoiceToExternalService
            )
          }, 2000)
        }
      }
    )
  }

  return {
    loading: invoiceQuery.isPending,
    serviceName,
    invoiceStatusAllowsLink: invoiceQuery.data?.statusAllowsLink,
    linkedInvoice: invoiceQuery.data?.linkedInvoice,
    link: {
      disabled: !customerQuery.data || linkingMutation.isPending,
      onClick: linkInvoice,
      linking: linkingMutation.isPending
    },
    stripeEnabled: invoiceQuery.data?.stripeEnabled ?? false
  }
}

const NotReadyToLink = (props: { stripeEnabled: boolean }) => (
  <Flex
    flexDirection="column"
    gap="4px"
    padding="12px"
    backgroundColor={props.stripeEnabled ? 'transparent' : GreyGrey10}
    borderRadius="8px"
    border={props.stripeEnabled ? `1px solid ${GreyGrey40}` : 'none'}
  >
    <Box {...Lato13Bold} color={GreyGrey80}>
      Invoice
    </Box>
    <Box
      {...Lato13Regular}
      color={GreyGrey60}
      data-testid="linkInvoiceWidget.willSyncWhenFinal"
    >
      This invoice will be synced once it's finalized in Sequence.
    </Box>
    {props.stripeEnabled && <PaymentStatusWarning />}
  </Flex>
)

const PaymentStatusWarning = () => (
  <Box
    {...Lato13Regular}
    data-testid="linkInvoiceWidget.paymentStatusWarning"
    backgroundColor={GreyGrey10}
    borderRadius="8px"
    padding="8px"
  >
    <Flex gap="8px" alignItems="top">
      <Flex flex="10%">
        <InformationCircleIcon height="16px" width="16px" />
      </Flex>
      <Flex direction="column">
        Payment status will update automatically when payments are collected via
        Stripe.
      </Flex>
    </Flex>
  </Box>
)

export const LinkedInvoiceWidget = (props: LinkedInvoiceWidgetProps) => {
  const hook = useLinkedInvoiceWidget(props)

  if (hook.loading) {
    return <Skeleton height="104px" width="100%" />
  }

  if (!hook.invoiceStatusAllowsLink) {
    return <NotReadyToLink stripeEnabled={hook.stripeEnabled} />
  }

  if (hook.linkedInvoice) {
    return (
      <ExistingEntityLink
        data-testid={`linkInvoiceWidget.${props.service}.linked`}
        externalEntityLabel="Invoice"
        serviceName={hook.serviceName}
        unlinkModal={
          <UnlinkInvoiceModal
            service={props.service}
            invoiceId={props.invoiceId}
          />
        }
        linkedEntity={{
          label: `ID: ${hook.linkedInvoice.id}`,
          href: hook.linkedInvoice.url,
          canUnlink: true,
          isPending: false
        }}
        warnings={hook.stripeEnabled ? [<PaymentStatusWarning />] : []}
      />
    )
  }

  return (
    <Flex
      flexDirection="column"
      gap="12px"
      padding="12px"
      backgroundColor={hook.stripeEnabled ? 'transparent' : GreyGrey10}
      borderRadius="8px"
      border={hook.stripeEnabled ? `1px solid ${GreyGrey40}` : 'none'}
      data-testid={`linkInvoiceWidget.${props.service}`}
    >
      <Flex direction="column" gap="4px">
        <Box {...Lato13Bold} color={GreyGrey80}>
          Invoice
        </Box>
        <Box {...Lato13Regular} color={GreyGrey60}>
          This invoice has not yet been synced to {hook.serviceName}.
        </Box>
      </Flex>
      <Button
        variant="secondary"
        onClick={hook.link.onClick}
        disabled={hook.link.disabled}
        data-testid={`linkInvoiceWidget.${props.service}.syncInvoice`}
      >
        {hook.link.linking ? (
          <Flex gap="8px">
            <Spinner height="16px" width="16px" color={GreyGrey60} />
            Syncing...
          </Flex>
        ) : (
          'Sync invoice'
        )}
      </Button>
      {hook.stripeEnabled && <PaymentStatusWarning />}
    </Flex>
  )
}
