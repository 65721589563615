import { HStack, Kbd, Text } from '@chakra-ui/react'
import { FC } from 'react'
import {
  GreyGrey40,
  GreyGrey60,
  GreyGrey80,
  Lato13Regular,
  Lato12Bold,
  ShadowXs
} from '@sequencehq/design-tokens'

interface ShortcutKeyProps {
  keys: string[]
  isSimultaneous?: boolean
}

export const ShortcutKey: FC<ShortcutKeyProps> = ({
  keys,
  isSimultaneous = false
}) => (
  <HStack spacing="4px" flexShrink={0} alignItems="center">
    {keys.map((key, keyIndex) => (
      <>
        <Kbd
          key={`key-${keyIndex}`}
          width="20px"
          height="20px"
          minW="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="white"
          color={GreyGrey80}
          borderColor={GreyGrey40}
          borderWidth="1px"
          borderRadius="4px"
          textTransform="uppercase"
          boxShadow={ShadowXs}
          {...Lato12Bold}
          fontSize="11px"
        >
          {key}
        </Kbd>
        {keyIndex < keys.length - 1 && (
          <Text key={`text-${keyIndex}`} {...Lato13Regular} color={GreyGrey60}>
            {isSimultaneous ? '+' : 'then'}
          </Text>
        )}
      </>
    ))}
  </HStack>
)
