import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'
import { useQuotePdfDownload } from '@sequencehq/quote-content'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ViewMode } from '../../QuoteEditorHeader/useQuoteEditorHeader'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type UseHeader = (hook: QuoteEditorPreviewProps) => {
  exitBackButton: {
    onClick: () => void
  }
  downloadPdfButton: {
    onClick: () => void
  }
  viewMode: {
    current: ViewMode
    onChange: (mode: string) => void
    options: Array<{
      value: ViewMode
      label: string
      disabled?: boolean
      'data-testid'?: string
    }>
  }
  cubeContext: ReturnType<typeof useCubeContext>
}

export const useHeader: UseHeader = hook => {
  const navigate = useNavigate()
  const cubeContext = useCubeContext()

  const fetchPdfQuoteRendererData = useCallback(
    () => hook.pdfRendererData,
    [hook.pdfRendererData]
  )

  const pdfQuoteDownloadHook = useQuotePdfDownload({
    notificationHook: useNotifications,
    fetchPdfQuoteRendererData,
    assetLoader: async ({ assetId }) => {
      const res = await dashboard20240730Client.getAsset({
        id: assetId
      })

      if (!res.data || res.error) {
        return undefined
      }

      return { urlSigned: res.data.url }
    }
  })

  const onSelectEdit = useCallback(() => {
    navigate(`/quotes/${hook.quoteId}`)
  }, [hook.quoteId, navigate])

  return {
    exitBackButton: {
      onClick: onSelectEdit
    },
    downloadPdfButton: {
      onClick: () => {
        void pdfQuoteDownloadHook.onClick()
      }
    },
    viewMode: {
      current: ViewMode.Preview,
      onChange: (mode: string) => {
        if (mode === ViewMode.Edit) {
          onSelectEdit()
        }
      },
      options: [
        {
          value: ViewMode.Edit,
          label: 'Edit',
          'data-testid': 'preview.quoteEditor.viewMode.edit'
        },
        {
          value: ViewMode.Preview,
          label: 'Preview',
          'data-testid': 'preview.quoteEditor.viewMode.preview',
          disabled:
            !cubeContext.queries.availableFeatures.quote.preview.available
              .enabled
        }
      ]
    },
    cubeContext
  }
}
