import { performExtractionAtom } from 'ContractExtraction/atoms'
import { useAtom } from 'jotai'

type UsePerformContractExtraction = () => (assetId: string) => void

export const usePerformContractExtraction: UsePerformContractExtraction =
  () => {
    const [, performExtraction] = useAtom(performExtractionAtom)
    return performExtraction
  }
