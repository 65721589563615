import { match } from 'ts-pattern'
import {
  CustomerContact,
  CustomerContactBillingPreference
} from '../domain/CustomerContacts.domain.types'
import invariant from 'tiny-invariant'

export function setPrimaryBillingContactById(
  contacts: CustomerContact[],
  newPrimaryBillingContactId: string
): CustomerContact[] {
  return setPrimaryBillingContact(
    contacts,
    contact => contact.id === newPrimaryBillingContactId
  )
}

export function setPrimaryBillingContact(
  contacts: CustomerContact[],
  predicate: (contact: CustomerContact) => boolean
): CustomerContact[] {
  const primaryBillingContact = contacts.find(predicate)

  invariant(primaryBillingContact, 'Primary billing contact not found')

  return contacts.map(contact => ({
    ...contact,
    billingPreference: match(contact)
      .with(primaryBillingContact, () => 'PRIMARY')
      .with({ billingPreference: 'PRIMARY' }, () => 'STANDARD')
      .otherwise(
        () => contact.billingPreference
      ) as CustomerContactBillingPreference
  }))
}
