import { usePricingEditorRoot } from './usePricingEditor.root'
import { PricingEditorProvider } from '../communication'
import { EditorMode, PricingEditorPortErrors, Product } from '../domain'
import { ListPriceLayout } from './layout/listPrice/ListPriceLayout'
import { VariantPriceLayout } from './layout/variantPrice/VariantPriceLayout'
import {
  Button,
  Modal,
  ModalContextProvider
} from '@sequencehq/core-components'
import { Outlet } from 'react-router-dom'
import NotFound from 'components/ErrorPages/NotFound'
import PageError from 'components/ErrorPages/PageError'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { Flex } from '@chakra-ui/react'
import { VariantPrice } from '../communication/external/v1/ports'

const ErrorModal = (props: {
  error: PricingEditorPortErrors
  onCancel?: () => void
}) => {
  return (
    <ModalContextProvider isOpen>
      <Modal
        variant="drawer"
        onClose={props.onCancel}
        data-testid="priceEditor.error"
      >
        <Modal.Header showCloseButton withBorder>
          Error opening pricing editor
        </Modal.Header>
        <Modal.Content padding={0}>
          {props.error === PricingEditorPortErrors.NotFound ? (
            <NotFound />
          ) : (
            <PageError />
          )}
        </Modal.Content>
        <Modal.Footer withBorder>
          <Flex justifyContent="flex-end" width="100%">
            <Button
              onClick={props.onCancel}
              variant="secondary"
              data-testid="priceEditor.error.cancel"
            >
              Close
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </ModalContextProvider>
  )
}

type CommonProps = {
  initialMode: EditorMode
  currency?: Currency
  variantPriceId?: string
  onSave?: (data: { price: any }) => void
  onCancel?: () => void
}

/**
 * PricingEditorRoot
 * @param existingPrice - (Variant only) the existing price to load into the editor. Must be
 * provided when the defaultPricePorts are disabled and editing a price.
 * @param disableDefaultPricePorts - (Variant only) disable default loading and saving for the price entity.
 * The calling component/module will need to load and save the price entity itself. Supporting
 * data for the price editor, such as list prices, will still function.
 * @returns
 */
export const PricingEditorRoot = (
  props:
    | ({
        variant: 'variant'
        existingPrice?: VariantPrice
        existingProduct?: Product
        disableDefaultPricePorts?: boolean
        disableDefaultProductPorts?: boolean
      } & CommonProps)
    | ({ variant: 'list' } & CommonProps)
) => {
  const { domain, ready, error } = usePricingEditorRoot({
    variant: props.variant,
    initialMode: props.initialMode,
    currency: props.currency,
    variantPriceId: props.variantPriceId,
    onSave: props.onSave,
    existingPrice:
      props.variant === 'variant' ? props.existingPrice : undefined,
    existingProduct:
      props.variant === 'variant' ? props.existingProduct : undefined,
    disableDefaultPricePorts:
      props.variant === 'variant' ? props.disableDefaultPricePorts : undefined,
    disableDefaultProductPorts:
      props.variant === 'variant' ? props.disableDefaultProductPorts : undefined
  })

  if (error) {
    return <ErrorModal error={error} onCancel={props.onCancel} />
  }

  return (
    <PricingEditorProvider value={domain}>
      <ModalContextProvider isOpen>
        <>
          {props.variant === 'list' && (
            <ListPriceLayout
              loading={!ready}
              initialMode={props.initialMode}
              onCancel={props.onCancel}
            />
          )}
          {props.variant === 'variant' && (
            <VariantPriceLayout
              onCancel={props.onCancel}
              loading={!ready}
              variantPriceId={props.variantPriceId}
            />
          )}
        </>
        <Outlet />
      </ModalContextProvider>
    </PricingEditorProvider>
  )
}
