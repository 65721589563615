import { XMarkIcon } from '@heroicons/react/24/solid'
import {
  Button,
  SequenceKebabMenu,
  SequenceMenuItem
} from '@sequencehq/core-components'
import {
  AttachmentReducerAction,
  AttachmentReducerState,
  isServerAsset
} from './attachmentReducer'
import { FC, ReactNode } from 'react'
import { AttachmentPdfPreview } from './AttachmentPdfPreview'
import { Box, Center, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey80,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { ExtractionStatusToast } from 'ContractExtraction/index'
import { ContractExtractionBanner } from 'ContractExtraction/components/ContractExtractionBanner'

export const AttachmentPreview: FC<{
  state: AttachmentReducerState
  dispatch: React.Dispatch<AttachmentReducerAction>
  disableControls?: boolean
}> = ({ state, dispatch, disableControls }) => {
  const { attachmentToPreview: attachment } = state

  const uploadedAttachments = (state.attachments ?? []).filter(isServerAsset)

  if (!attachment) {
    return null
  }

  return (
    <Flex flexDirection="column" height="100%">
      <Header
        onClickClose={() => {
          dispatch({ type: 'PREVIEW_ATTACHMENT', attachment: null })
        }}
        onClickDelete={() => {
          dispatch({
            type: 'DELETE_ATTACHMENT',
            attachment
          })
        }}
        disableControls={disableControls}
        sectionBottom={
          <ContractExtractionBanner
            asset={attachment}
            attachments={uploadedAttachments}
          />
        }
      />
      <Box flexGrow={1} position="relative">
        <AttachmentPdfPreview
          assetId={attachment.id}
          url={attachment.url}
          fileName={attachment.fileName}
        />
        <ExtractionStatusToast />
      </Box>
    </Flex>
  )
}

const Header: FC<{
  onClickClose: VoidFunction
  onClickDelete: VoidFunction
  disableControls?: boolean
  sectionBottom?: ReactNode
}> = ({ onClickClose, onClickDelete, disableControls, sectionBottom }) => {
  return (
    <Flex
      flexDirection="column"
      px="8px"
      py="12px"
      borderBottom={`1px solid ${GreyGrey30}`}
    >
      <Flex alignItems="center" justifyContent="space-between" px="8px">
        <Text {...Lato13Bold} color={GreyGrey80}>
          Preview
        </Text>

        <Flex gap="8px">
          <SequenceKebabMenu
            variant="ghost"
            alignment="bottom-right"
            buttonSize="small"
            items={[
              <SequenceMenuItem
                key="delete"
                uuid="delete"
                label="Delete attachment"
                isDisabled={!!disableControls}
                onClick={e => {
                  e.stopPropagation()
                  onClickDelete()
                }}
                style={{ color: 'red' }}
              />
            ]}
          />
          <Button
            variant="ghost"
            size="24px"
            onClick={onClickClose}
            aria-label="Close preview"
          >
            <Center>
              <XMarkIcon width="16px" height="16px" color={GreyGrey60} />
            </Center>
          </Button>
        </Flex>
      </Flex>

      {sectionBottom}
    </Flex>
  )
}
