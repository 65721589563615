import { Text } from '@chakra-ui/react'
import { IdentificationIcon, UserIcon } from '@heroicons/react/16/solid'
import ViewColumnsIcon from '@heroicons/react/24/solid/ViewColumnsIcon'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  MagicTableFilterConfig,
  useMagicTableInfiniteQuery,
  useLinkMagicTableWithSearchParams,
  ActiveFilter
} from '@sequencehq/tables'
import { useLoadAllCustomersWithAliases } from 'components/UsageEvents/useLoadAllCustomersWithAliases'
import { QuotesFilters } from 'modules/Quotes/types'
import { useCallback, useEffect, useMemo, useState } from 'react'

type UseQuotesMagicTable = ({
  forcedActiveFilters,
  onFiltersApiQueryParamsChanged
}: {
  forcedActiveFilters?: ActiveFilter[]
  onFiltersApiQueryParamsChanged?: (newParams: string) => void
}) => {
  filters: MagicTableFilterConfig<QuotesFilters>[]
  activeFilters: ActiveFilter[]
  sortBy: string | null
  onChangeActiveFilters: (newActiveFilters: ActiveFilter[]) => void
  onChangeSortBy: (sortBy: string | null) => void
  infiniteQuery: {
    data?: any
    isFetching: boolean
    hasNextPage: boolean
    isFetchingNextPage: boolean
    fetchNextPage: () => void
    isLoading: boolean
  }
}

export const useQuotesMagicTable: UseQuotesMagicTable = ({
  forcedActiveFilters = [],
  onFiltersApiQueryParamsChanged
}) => {
  const { customersWithAliases } = useLoadAllCustomersWithAliases()

  // Function to fetch users for the owner filter
  const fetchUserOptions = useCallback(async () => {
    try {
      // Fetch users from the organization
      const response = await dashboard20240730Client.getUsers({
        limit: 100
      })
      const users = response.data?.items || []

      // Map users to options format
      return users.map(user => ({
        value: user.id,
        label: user.email || 'Unknown User'
      }))
    } catch (error) {
      console.error('Error fetching users:', error)
      return []
    }
  }, [])

  const filtersConfig: MagicTableFilterConfig<QuotesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options:
        customersWithAliases?.map(customer => ({
          value: customer.id,
          label: customer.customerName
        })) ?? [],
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'createdBy',
      label: 'Owner',
      icon: UserIcon,
      options: [],
      optionsFunc: fetchUserOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'status',
      label: 'Status',
      icon: ViewColumnsIcon,
      options: [
        { value: 'DRAFT', label: 'Draft' },
        { value: 'PUBLISHED', label: 'Published' },
        { value: 'ACCEPTED', label: 'Signed' },
        { value: 'EXECUTED', label: 'Executed' }
      ],
      format: (value: string) => <Text>{value}</Text>
    },
    {
      paramName: 'includeArchived',
      type: 'toggle',
      label: 'Show archived quotes'
    }
  ]

  // Apply disabled state to filters that match forcedActiveFilters
  const filters = filtersConfig.map(filter => ({
    ...filter,
    disabled: !!forcedActiveFilters.find(
      forcedFilter => forcedFilter.paramName === filter.paramName
    )
  }))

  const {
    activeFilters: searchActiveFilters,
    onChangeActiveFilters: onChangeSearchActiveFilters,
    sortBy,
    onChangeSortBy
  } = useLinkMagicTableWithSearchParams(filters)

  // Combine forced filters with search filters
  const activeFilters = useMemo(
    () => [...forcedActiveFilters, ...searchActiveFilters],
    [forcedActiveFilters, searchActiveFilters]
  )

  // Custom handler for filter changes that respects forced filters
  const onChangeActiveFilters = useCallback(
    (newActiveFilters: ActiveFilter[]) => {
      // Only update filters that aren't in forcedActiveFilters
      onChangeSearchActiveFilters(
        newActiveFilters.filter(
          filter =>
            !forcedActiveFilters.find(
              forcedFilter => forcedFilter.paramName === filter.paramName
            )
        )
      )
    },
    [onChangeSearchActiveFilters, forcedActiveFilters]
  )

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getQuotes,
    filters,
    activeFilters,
    sortBy
  )

  const [prevQueryParams, setPrevQueryParams] = useState<string>('')

  useEffect(() => {
    if (onFiltersApiQueryParamsChanged) {
      const queryParams = new URLSearchParams()

      activeFilters.forEach(filter => {
        if (filter.type === 'multiSelect' && Array.isArray(filter.values)) {
          filter.values.forEach(value => {
            queryParams.append(filter.paramName, value)
          })
        } else if (filter.type === 'toggle' && filter.value === true) {
          queryParams.set(filter.paramName, 'true')
        }
      })

      const nextQueryParams = queryParams.toString()

      // Only update if the params have changed to prevent unnecessary rerenders
      if (nextQueryParams !== prevQueryParams) {
        setPrevQueryParams(nextQueryParams)
        onFiltersApiQueryParamsChanged(nextQueryParams)
      }
    }
  }, [activeFilters, onFiltersApiQueryParamsChanged, prevQueryParams])

  return {
    filters,
    activeFilters,
    sortBy,
    onChangeActiveFilters,
    onChangeSortBy,
    infiniteQuery
  }
}
