import {
  createFormModal,
  apiPackageAdapter99990101
} from 'components/Form/createFormModal'
import SendTestCreditNoteEmailForm from 'components/SendTestEmail/SendTestCreditNoteEmailForm'
import { SendCreditNoteTestEmailFormProps } from 'components/SendTestEmail/types'
import { Toast } from '@sequencehq/core-components'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { QueryParams } from 'lib/types'

const SendTestCreditNoteEmailModal = createFormModal<
  QueryParams,
  SendCreditNoteTestEmailFormProps
>({
  FormComponent: SendTestCreditNoteEmailForm,
  useMutation: apiPackageAdapter99990101<
    { id: string; testEmail: string },
    typeof dashboardv99990101Client.postSendTestCreditNote
  >('postSendTestCreditNote', ({ id, testEmail }) => ({
    id,
    testEmail
  })),
  modalTitle: 'Send Test Credit Note Email',
  successMessage: 'Email sent',
  submitTitle: 'Send Test',
  formId: 'sendTestCreditNoteEmailForm',
  successToast: () => (
    <Toast type="success" title={`Test email sent`} isClosable={true}></Toast>
  )
})

export default SendTestCreditNoteEmailModal
