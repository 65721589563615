import { Button, Flex, Input, Text } from '@chakra-ui/react'
import { Lato16Bold } from '@sequencehq/design-tokens'
import { useStytchB2BClient } from '@stytch/react/dist/b2b'
import { Section } from 'modules/LoginApp/components/common/Section'
import { TextDialog } from 'modules/LoginApp/components/common/TextDialog'
import { isEmailDisallowed } from 'modules/LoginApp/helpers'
import { motion, useAnimation } from 'framer-motion'
import { SetStateAction, useEffect, useState, Dispatch } from 'react'
import { LoginPageStage } from './LoginPage'

type Props = {
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  setStage: Dispatch<SetStateAction<LoginPageStage>>
}

export const EmailLoginForm = ({ email, setEmail, setStage }: Props) => {
  const [showTip, setShowTip] = useState(false)
  const stytch = useStytchB2BClient()
  const controls = useAnimation()

  useEffect(() => {
    if (
      email.includes('@') &&
      email.includes('.') &&
      !email.endsWith('.') &&
      isEmailDisallowed(email)
    ) {
      setShowTip(true)
    } else {
      setShowTip(false)
    }
  }, [email])

  const shakeForm = () => {
    void controls.start({
      x: [0, -4, 8, -8, 4, 0],
      transition: { duration: 0.5 }
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (!email) {
      shakeForm()

      return
    }

    await stytch.magicLinks.email.discovery.send({
      email_address: email,
      discovery_redirect_url: `${window.location.origin}/auth/callback`,
      login_template_id: 'magic_link_login_email'
    })

    setStage('CHECK_EMAIL')
  }

  return (
    <Section as={motion.section} animate={controls}>
      <Text {...Lato16Bold} textAlign="center" color="gray.90">
        Sign in to your account
      </Text>

      <Flex
        as="form"
        onSubmit={e => {
          void handleSubmit(e)
        }}
        flexDirection="column"
        gap="16px"
      >
        <Input
          variant="formInput2"
          onChange={e => setEmail(e.target.value)}
          value={email}
          placeholder="name@work-email.com"
          type="email"
          onInvalid={() => shakeForm()}
          isRequired
        />

        {showTip && (
          <TextDialog>
            <strong>Use your work email:</strong> We suggest using your work
            email to sign in to Sequence.
          </TextDialog>
        )}
        <Button variant="primary2" width="100%" type="submit">
          Sign in with email
        </Button>
      </Flex>
    </Section>
  )
}
