import { Box, Button, Flex, Tooltip } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey30,
  GreyGrey50,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80
} from '@sequencehq/design-tokens'
import { Wordmark } from '@sequencehq/core-components'
import { ShareFeedbackModal } from 'components/ShareFeedbackModal/ShareFeedbackModal'
import { useFooter } from 'components/NavigationV3/components/Footer/useFooter'
import { SequenceMenu, SequenceMenuItemType } from '@sequencehq/core-components'
import { useKeyboardShortcuts } from '../../../../lib/keyboardShortcuts/useKeyboardShortcuts'
import { useNavigationV3Context } from 'components/NavigationV3/hooks/useNavigationV3Context'

import QuestionMarkCircleIcon from '@heroicons/react/20/solid/QuestionMarkCircleIcon'
import ArrowUpRightIcon from '@heroicons/react/16/solid/ArrowUpRightIcon'
import BookOpenIcon from '@heroicons/react/16/solid/BookOpenIcon'
import AcademicCapIcon from '@heroicons/react/16/solid/AcademicCapIcon'
import NewspaperIcon from '@heroicons/react/16/solid/NewspaperIcon'
import FlagIcon from '@heroicons/react/16/solid/FlagIcon'
import CommandLineIcon from '@heroicons/react/16/solid/CommandLineIcon'
import { KeyboardShortcutsOverlay } from 'components/KeyboardShortcutsOverlay/KeyboardShortcutsOverlay'

export const Footer = () => {
  const { feedbackModal } = useFooter()
  const { isKeyboardShortcutsOpen, setIsKeyboardShortcutsOpen } =
    useKeyboardShortcuts()
  const { data } = useNavigationV3Context()
  const { isNavigationCollapsed } = data

  const items: SequenceMenuItemType[] = [
    {
      label: 'Keyboard shortcuts',
      iconLeft: <CommandLineIcon width="16px" height="16px" color="inherit" />,
      onClick: () => setIsKeyboardShortcutsOpen(true),
      divider: true
    },
    {
      label: 'Documentation',
      iconLeft: <BookOpenIcon width="16px" height="16px" color="inherit" />,
      iconRight: (
        <ArrowUpRightIcon width="16px" height="16px" color="inherit" />
      ),
      onClick: () => window.open('https://docs.sequence.so', '_blank')
    },
    {
      label: 'Academy',
      iconLeft: <AcademicCapIcon width="16px" height="16px" color="inherit" />,
      iconRight: (
        <ArrowUpRightIcon width="16px" height="16px" color="inherit" />
      ),
      onClick: () => window.open('https://academy.sequence.so', '_blank')
    },
    {
      label: 'Changelog',
      iconLeft: <NewspaperIcon width="16px" height="16px" color="inherit" />,
      iconRight: (
        <ArrowUpRightIcon width="16px" height="16px" color="inherit" />
      ),
      onClick: () => window.open('https://sequence.so/changelog', '_blank')
    },
    {
      label: 'Share feedback',
      iconLeft: <FlagIcon width="16px" height="16px" color="inherit" />,
      onClick: feedbackModal.toggle
    }
  ]

  return (
    <>
      <Flex
        width="100%"
        p={isNavigationCollapsed ? '4px' : '8px'}
        justifyContent={isNavigationCollapsed ? 'center' : 'space-between'}
        alignItems="center"
      >
        {!isNavigationCollapsed && (
          <Box p="8px" position="relative" top="1px">
            <Wordmark
              width="74px"
              height="16px"
              color={GreyGrey50}
              data-testid="sequence-logo"
            />
          </Box>
        )}

        <SequenceMenu
          menuButton={
            <Button
              variant="unstyled"
              display="flex"
              width="28px"
              height="28px"
              minWidth="initial"
              color={GreyGrey60}
              _hover={{ color: GreyGrey70, background: GreyGrey20 }}
              _active={{ color: GreyGrey80, background: GreyGrey30 }}
              _focus={{ color: GreyGrey80, background: GreyGrey30 }}
              data-testid="help-menu-button"
            >
              <QuestionMarkCircleIcon
                width="20px"
                height="20px"
                color="inherit"
              />
            </Button>
          }
          items={items}
          alignment="top-left"
        />

        {feedbackModal.visible && (
          <ShareFeedbackModal onClose={feedbackModal.toggle} />
        )}
      </Flex>

      <KeyboardShortcutsOverlay
        isOpen={isKeyboardShortcutsOpen}
        onClose={() => setIsKeyboardShortcutsOpen(false)}
      />
    </>
  )
}
