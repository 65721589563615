import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import SendInvoiceForm from 'components/UpdateInvoiceStatus/SendInvoiceForm'
import {
  UpdateAndSendInvoiceStatusFormProps,
  UpdateAndSendCreditNoteStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import { QueryParams } from 'lib/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

const SendInvoiceModal = createFormModal<
  QueryParams,
  UpdateAndSendInvoiceStatusFormProps
>({
  FormComponent: SendInvoiceForm,
  useMutation: apiPackageAdapter20240730<
    UpdateAndSendCreditNoteStatusFormProps,
    typeof dashboard20240730Client.postSendInvoice
  >('postSendInvoice', ({ id }) => ({ id })),
  modalTitle: 'Send Invoice',
  successMessage: 'Invoice sent',
  submitTitle: 'Send Invoice',
  formId: 'sendInvoiceForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Invoice ${
        response.invoiceNumber ? response.invoiceNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue',
  errorText: 'Failed to send invoice'
})

export default SendInvoiceModal
