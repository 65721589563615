import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  CompoundInputFieldNew,
  TagInput,
  TagInputProps
} from '@sequencehq/forms'
import { FC, useState } from 'react'

type TagValue = {
  id: string
  value: string
}

type UsageEventTypeInputProps = {
  fieldName: string
}

export const UsageEventTypeInput: FC<UsageEventTypeInputProps> = ({
  fieldName
}) => {
  const [query, setQuery] = useState('')

  const { data } = useQuery(dashboard20240730Client.getUniqueUsageEventTypes, {
    search: query
  })

  const eventTypes = data?.items.map(v => ({ id: v, value: v })) ?? []

  return (
    <CompoundInputFieldNew<TagInputProps<TagValue>>
      fieldName={fieldName}
      fieldLabel="Event type"
      Component={TagInput}
      placeholder="Select event type"
      items={eventTypes}
      query={query}
      setQuery={setQuery}
      itemToContent={item => ({ title: item.value })}
    />
  )
}
