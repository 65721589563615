import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { EditorMode } from 'common/drawers/PricingEditor/domain'
import { NEW_PRICE_PATTERN } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useCallback, useMemo, useState } from 'react'

export const useListPriceLayout = (props: {
  initialMode: EditorMode
  onCancel?: () => void
}) => {
  const context = usePricingEditorDomainContext()
  const [submitting, setSubmitting] = useState(false)

  const nextStep = useMemo(() => {
    const index = context.queries.rawData.editor.steps.findIndex(
      step => step.mode === context.queries.rawData.configuration.mode
    )
    return context.queries.rawData.editor.steps[index + 1]
  }, [
    context.queries.rawData.configuration.mode,
    context.queries.rawData.editor.steps
  ])

  const previousStep = useMemo(() => {
    const index = context.queries.rawData.editor.steps.findIndex(
      step => step.mode === context.queries.rawData.configuration.mode
    )
    return context.queries.rawData.editor.steps[index - 1]
  }, [
    context.queries.rawData.configuration.mode,
    context.queries.rawData.editor.steps
  ])

  const title = useMemo(() => {
    if (props.initialMode === EditorMode.ADD_PRODUCT) {
      return 'Add product'
    }

    if (context.queries.rawData.editor.loaded) {
      return context.queries.rawData.data.pricingEditorData.common.id.match(
        NEW_PRICE_PATTERN
      )
        ? `Add list price to ${context.queries.rawData.data.product.name}`
        : `Edit list price for ${context.queries.rawData.data.product.name}`
    }

    return props.initialMode === EditorMode.CREATE
      ? 'Add list price'
      : 'Edit list price'
  }, [
    context.queries.rawData.data.pricingEditorData.common.id,
    context.queries.rawData.data.product.name,
    context.queries.rawData.editor.loaded,
    props.initialMode
  ])

  const cancelText = useMemo(() => {
    if (previousStep) {
      return 'Back'
    }

    if (context.queries.rawData.configuration.mode === EditorMode.REVIEW) {
      return 'Close'
    }

    return 'Cancel'
  }, [context.queries.rawData.configuration.mode, previousStep])

  const submitText = useMemo(() => {
    if (context.queries.rawData.configuration.mode !== EditorMode.REVIEW) {
      return 'Next'
    }

    if (
      context.queries.rawData.data.pricingEditorData.common.id.match(
        NEW_PRICE_PATTERN
      )
    ) {
      return 'Create list price'
    }

    return 'Update list price'
  }, [
    context.queries.rawData.configuration.mode,
    context.queries.rawData.data.pricingEditorData.common.id
  ])

  const onSave = useCallback(async () => {
    setSubmitting(true)
    const result = await context.mutators.external.out.save()
    setSubmitting(false)
    return result
  }, [context.mutators.external.out])

  const onReview = useCallback(() => {
    if (!context.queries.rawData.editor.valid) {
      context.mutators.common.updateEditor({
        showValidationErrors: true
      })
      return
    }

    if (nextStep) {
      context.mutators.common.updateConfiguration({
        mode: nextStep?.mode
      })
    }
  }, [nextStep, context.mutators.common, context.queries.rawData.editor.valid])

  const onAddProduct = useCallback(() => {
    if (!context.queries.rawData.editor.valid) {
      context.mutators.common.updateEditor({
        showValidationErrors: true
      })
      return
    }

    context.mutators.common.updateEditor({
      showValidationErrors: false
    })

    if (nextStep) {
      context.mutators.common.updateConfiguration({
        mode: nextStep?.mode
      })
    }

    context.mutators.common.updatePricingEditorData({
      common: {
        name: context.queries.rawData.data.product.name
      }
    })
  }, [
    context.mutators.common,
    nextStep,
    context.queries.rawData.data.product.name,
    context.queries.rawData.editor.valid
  ])

  const showBreadcrumbs = useMemo(
    () => context.queries.availableFeatures.common.breadcrumb.available.visible,
    [context.queries.availableFeatures.common.breadcrumb.available.visible]
  )

  const pricingModel = useMemo(
    () => context.queries.rawData.data.pricingEditorData.common.pricingModel,
    [context.queries.rawData.data.pricingEditorData.common.pricingModel]
  )

  const closeOnSubmit = useMemo(() => {
    return (
      context.queries.rawData.editor.valid &&
      context.queries.rawData.configuration.mode === EditorMode.REVIEW
    )
  }, [
    context.queries.rawData.configuration.mode,
    context.queries.rawData.editor.valid
  ])

  const editorMode = useMemo(
    () => context.queries.rawData.configuration.mode,
    [context.queries.rawData.configuration.mode]
  )

  const submitAction = useMemo(() => {
    if (
      context.queries.availableFeatures.listPrice.addProduct.available.visible
    ) {
      return {
        action: onAddProduct,
        disabled:
          !context.queries.availableFeatures.listPrice.addProduct.available
            .enabled,
        text: submitText
      }
    }

    if (context.queries.availableFeatures.common.review.available.visible) {
      return {
        action: onReview,
        disabled:
          !context.queries.availableFeatures.common.review.available.enabled,
        text: submitText
      }
    }

    return {
      action: () => {
        void onSave()
      },
      disabled:
        !context.queries.availableFeatures.common.save.available.enabled ||
        submitting,
      text: submitText
    }
  }, [
    context.queries.availableFeatures.common.review.available.enabled,
    context.queries.availableFeatures.common.review.available.visible,
    context.queries.availableFeatures.common.save.available.enabled,
    context.queries.availableFeatures.listPrice.addProduct.available.enabled,
    context.queries.availableFeatures.listPrice.addProduct.available.visible,
    onAddProduct,
    onReview,
    onSave,
    submitText,
    submitting
  ])

  const onCancel = useCallback(() => {
    props.onCancel?.()
  }, [props.onCancel])

  const onBack = useCallback(() => {
    if (!previousStep) {
      onCancel()
      return
    }

    return context.mutators.common.updateConfiguration({
      mode: previousStep.mode
    })
  }, [previousStep, context.mutators.common, onCancel])

  return {
    cancelText,
    closeOnSubmit,
    editorMode,
    pricingModel,
    showBreadcrumbs,
    title,
    submitAction,
    onBack,
    onCancel
  }
}
