import { Flex, Box, Grid, GridItem } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey70,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NAVIGATION_ANIMATION } from 'components/NavigationV3/constants/animation'
import { useTransitionState } from '../hooks/useTransitionState'
import { getStorageItem, setStorageItem } from 'lib/utils/storageUtils'

export const NavSection: FC<
  PropsWithChildren<{
    id: string
    title: string
    expandOnMatchingPath?: (pathname: string) => boolean
    isCollapsed?: boolean
    defaultIsExpanded?: boolean
    'data-testid'?: string
  }>
> = ({
  id,
  title,
  expandOnMatchingPath,
  isCollapsed = false,
  defaultIsExpanded = true,
  children,
  'data-testid': dataTestId
}) => {
  const location = useLocation()
  const [isExpanded, setIsExpanded] = useState(() =>
    getStorageItem(`nav-section-${id}`, defaultIsExpanded)
  )
  const { isTransitioning, startTransition } = useTransitionState(300)

  // biome-ignore lint/correctness/useExhaustiveDependencies: check location.pathname only
  useEffect(() => {
    if (!expandOnMatchingPath?.(location.pathname) || isExpanded) {
      return
    }

    setIsExpanded(true)
  }, [location.pathname])

  const handleToggle = () => {
    // Only toggle expansion when not collapsed
    if (!isCollapsed) {
      startTransition()
      setIsExpanded(expanded => {
        const newState = !expanded
        setStorageItem(`nav-section-${id}`, newState)
        return newState
      })
    }
  }

  // Always show content when collapsed, regardless of expanded state
  const shouldShowContent = isCollapsed || isExpanded

  // To prevent layout thrashing, store computed values
  const contentStyles = {
    overflow: 'hidden',
    maxHeight: shouldShowContent ? '1000px' : '0',
    transition: `max-height 0.3s ease, opacity 0.2s ease`,
    opacity: isTransitioning ? 0.9 : 1,
    transform: 'translateZ(0)',
    willChange: isTransitioning ? 'max-height, opacity' : 'none'
  }

  return (
    <>
      <Grid
        data-testid={dataTestId}
        templateColumns={isCollapsed ? 'auto' : 'auto 1fr auto'}
        alignItems="center"
        px={2}
        py={1}
        color={GreyGrey60}
        cursor={isCollapsed ? 'default' : 'pointer'}
        height="24px"
        _hover={{
          color: !isCollapsed ? GreyGrey70 : GreyGrey60
        }}
        transition="color 0.2s ease"
        sx={{
          willChange: isCollapsed ? 'none' : 'color',
          transform: isCollapsed ? 'none' : 'translateZ(0)'
        }}
        onClick={handleToggle}
      >
        {!isCollapsed && (
          <GridItem
            {...Lato13Bold}
            color="inherit"
            fontSize="11px"
            textTransform="uppercase"
            className="nav-text"
            style={{
              transformOrigin: 'left center',
              willChange: 'opacity, transform'
            }}
          >
            {title}
          </GridItem>
        )}
        {isCollapsed ? (
          <GridItem
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box
              width="16px"
              height="1px"
              bg={GreyGrey30}
              borderRadius="1px"
              transition={`width ${NAVIGATION_ANIMATION.WIDTH_TRANSITION}`}
            />
          </GridItem>
        ) : (
          <GridItem
            display="flex"
            justifyContent="flex-end"
            style={{
              transition: 'transform 0.2s ease',
              transform: `translateZ(0) rotate(${isExpanded ? '0' : '0'}deg)`,
              willChange: 'transform'
            }}
          >
            {isExpanded ? (
              <ChevronUpIcon
                height="16px"
                width="16px"
                color={GreyGrey70}
                style={{
                  transition: 'transform 0.2s ease',
                  transform: 'translateZ(0)',
                  willChange: 'transform'
                }}
              />
            ) : (
              <ChevronDownIcon
                height="16px"
                width="16px"
                color={GreyGrey70}
                style={{
                  transition: 'transform 0.2s ease',
                  transform: 'translateZ(0)',
                  willChange: 'transform'
                }}
              />
            )}
          </GridItem>
        )}
      </Grid>
      {shouldShowContent && (
        <Flex flexDir="column" gap="1px" width="100%" style={contentStyles}>
          {children}
        </Flex>
      )}
    </>
  )
}
