import { pdf } from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'
import { InvoiceModel } from '@sequencehq/core-models'
import { ReactPdfInvoice } from '@sequencehq/invoice-content'
import { getPortalDomain, registerReactPdfFonts } from '@sequencehq/utils'
import { useFetchDataForLocalPdfInvoice } from 'InvoiceEditor/components/Header/useFetchDataForLocalPdfInvoice'
import { invoiceEditorApiAdapter } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { downloadBlob } from '@sequencehq/utils'
import { getEnvironment, isProductionEnv } from 'lib/environment/environment'
import exhaustiveGuard from 'lib/exhaustiveGuard/exhaustiveGuard'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type DownloadInvoicePdfProps = {
  invoiceId: InvoiceModel['id']
  invoiceNumber: InvoiceModel['invoiceNumber']
  invoiceStatus: InvoiceModel['status']
  customerId: InvoiceModel['customerId']
}

type UseInvoicePdfDownload = () => {
  downloadPdf: (props: DownloadInvoicePdfProps) => void
}

registerReactPdfFonts()

export const useInvoicePdfDownload: UseInvoicePdfDownload = () => {
  const notifications = useNotifications()

  const portalDomain = getPortalDomain(getEnvironment())

  const { fetchData } = useFetchDataForLocalPdfInvoice()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-pdf',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-pdf-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('PDF download failed', {
      id: 'download-pdf-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  /**
   * Main functions
   */
  const localGeneratePdf = useCallback(
    async ({
      invoiceId,
      invoiceNumber,
      customerId
    }: {
      invoiceId: InvoiceModel['id']
      invoiceNumber: InvoiceModel['invoiceNumber']
      customerId: InvoiceModel['customerId']
    }) => {
      try {
        const pdfInvoiceData = await fetchData({ invoiceId, customerId })

        const invoiceProps = {
          invoice: {
            ...pdfInvoiceData.invoice,
            customerPortalEnabled:
              pdfInvoiceData.merchant.customerPortalEnabled,
            creditBalances: pdfInvoiceData.creditBalances
          },
          lineItems: pdfInvoiceData.lineItems,
          lineItemGroups: pdfInvoiceData.lineItemGroups,
          merchantDetails: pdfInvoiceData.merchantDetails,
          creditBalances: pdfInvoiceData.creditBalances,
          showNonProdWatermark: !isProductionEnv(),
          urlRootApi: import.meta.env.VITE_API_ORIGIN,
          urlRootCustomerPortal: portalDomain || '',
          defaultDueDateDays: pdfInvoiceData.merchant.defaultDueDateDays,
          isCustomerPortalEnabled:
            pdfInvoiceData.merchant.customerPortalEnabled,
          isRenderedInBrowser: true,
          subAccountUsageBreakdown:
            invoiceEditorApiAdapter.in.subAccountUsageBreakdown(
              pdfInvoiceData.subAccountUsageBreakdown
            )
        }

        /**
         * NOTE @ihutc:
         * When using .toBlob to generate a PDF, react-pdf doesn't appear to fire `setState` or returns the PDF before
         * `setState` has a chance to run. This means the code to set the payment details page number on the first page
         * of the invoice doesn't work.
         *
         * To get around this, I've rendered the PDF twice. On the first run, `setExternalPaymentDetailsPageNumber` is
         * passed in and called by ReactPdfInvoice to set the value of `paymentDetailsPageNumber`.
         *
         * In the second run, the value of `paymentDetailsPageNumber` is passed to the ReactPdfInvoice component so it
         * can be populated on the first page of the PDF.
         */

        let paymentDetailsPageNumber: number | undefined = undefined

        const setPaymentDetailsPageNumber = (pageNumber: number) => {
          paymentDetailsPageNumber = pageNumber
        }

        await pdf(
          <ReactPdfInvoice
            {...invoiceProps}
            setExternalPaymentDetailsPageNumber={setPaymentDetailsPageNumber}
          />
        ).toBlob()

        const blob = await pdf(
          <ReactPdfInvoice
            {...invoiceProps}
            externalPaymentDetailsPageNumber={paymentDetailsPageNumber}
          />
        ).toBlob()

        downloadBlob(invoiceNumber ? `${invoiceNumber}.pdf` : 'Invoice.pdf')(
          blob
        )

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [fetchData, portalDomain, showToastError, showToastSuccess]
  )

  const remoteGeneratePdf = useCallback(
    async ({
      invoiceId,
      invoiceNumber
    }: {
      invoiceId: InvoiceModel['id']
      invoiceNumber: InvoiceModel['invoiceNumber']
    }) => {
      try {
        const invoicePdfRes = await dashboard20240730Client.getInvoicePdf({
          id: invoiceId
        })

        if (!invoicePdfRes.data) {
          throw new Error('An error occurred while downloading the invoice')
        }

        downloadBlob(invoiceNumber ? `${invoiceNumber}.pdf` : 'Invoice.pdf')(
          invoicePdfRes.data
        )

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [showToastError, showToastSuccess]
  )

  const downloadPdf = useCallback(
    ({
      invoiceId,
      invoiceNumber,
      invoiceStatus,
      customerId
    }: DownloadInvoicePdfProps) => {
      showToastPreparing()

      switch (invoiceStatus) {
        case 'DRAFT':
        case 'IN_PROGRESS':
        case 'FINAL':
          void localGeneratePdf({ customerId, invoiceId, invoiceNumber })
          break
        case 'SENT':
        case 'VOIDED':
          void remoteGeneratePdf({ invoiceId, invoiceNumber })
          break
        default:
          return exhaustiveGuard(invoiceStatus)
      }
    },
    [localGeneratePdf, remoteGeneratePdf, showToastPreparing]
  )

  return { downloadPdf }
}
