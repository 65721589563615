import { FC, ReactNode } from 'react'
import type { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { LinkEntityWidget } from '../LinkEntityWidget'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export const AccountLinkWidget: FC<{
  entityIcon?: ReactNode
  externalEntityLabel: string
  sequenceEntityLabel: string
  productId: string
  service: IntegrationServices
  noLinkFoundText?: string
}> = props => {
  const serviceName = getIntegrationName(props.service)

  const { data: product, isPending } = useQuery(
    dashboardv99990101Client.getProduct,
    {
      id: props.productId
    },
    {
      select: data => {
        if (!data) {
          return null
        }

        const currentLinkedAccount = data.integrationIds.find(
          int =>
            int.service === props.service &&
            int.externalEntityType === 'LEDGER_ACCOUNT'
        )

        return {
          name: data.name,
          linkedAccount: currentLinkedAccount
            ? {
                value: currentLinkedAccount.id,
                href: currentLinkedAccount.url,
                label: `ID: ${currentLinkedAccount.id}`,
                canUnlink: false,
                isPending: currentLinkedAccount.isPending
              }
            : null
        }
      }
    }
  )

  return (
    <LinkEntityWidget
      data-testid={`${props.service}.accountLink`}
      externalEntityLabel={props.externalEntityLabel}
      sequenceEntityLabel={props.sequenceEntityLabel}
      isLoaded={!isPending}
      showLinkButton={false}
      serviceName={serviceName}
      linkedAccount={product?.linkedAccount ? product?.linkedAccount : null}
      noLinkFoundText={props.noLinkFoundText}
    />
  )
}
