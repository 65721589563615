import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import {
  QuickBooksConfig,
  QuickBooksTaxCodeMapping
} from 'modules/Integrations/integrationsConfig/quickBooks/quickbooks.integration.config'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

export const quickBooksConfigurationMutator = async (
  data: QuickBooksConfig
): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  const postMappings = Object.values<QuickBooksTaxCodeMapping>(
    data.taxCodeMappings
  )
    .map(mapping => {
      if (mapping.taxCodeId) {
        return dashboardv99990101Client.postQuickbooksTaxCodeMapping({
          taxCategoryId: mapping.taxCategoryId,
          taxCodeId: mapping.taxCodeId
        })
      }

      return null
    })
    .filter(Boolean) as Promise<unknown>[]

  const [enabledRes] = await Promise.all([
    dashboardv20240509Client.putIntegration({
      service: 'QuickBooks_Online',
      body: {
        enabled: data.importCustomerWorkflow
      }
    }),
    dashboardv99990101Client.putDefaultQuickbooksTaxCodes({
      exempt: data.taxExemptTaxCode ? data.taxExemptTaxCode : undefined,
      taxable: data.taxableTaxCode ? data.taxableTaxCode : undefined,
      reverseCharged: data.reverseChargedTaxCode
        ? data.reverseChargedTaxCode
        : undefined
    }),
    dashboardv99990101Client.putDefaultQuickbooksAccount({
      standardId: data.defaultAccount
    }),
    ...postMappings
  ])

  if (enabledRes.error) {
    return {
      success: false,
      error: IntegrationsPortErrors.Other
    }
  }

  return {
    success: true,
    error: null
  }
}
