import { Box } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { downloadBlob } from '@sequencehq/utils'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

type CreditNoteProps = {
  creditNoteId: string
  creditNoteNumber: string
}

const CreditNotePdf: FC<CreditNoteProps> = ({
  creditNoteId,
  creditNoteNumber
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    dashboard20240730Client
      .getCreditNotePdf({ id: creditNoteId })
      .then(res => {
        if (!res.data) {
          throw new Error('An error occurred while downloading the credit note')
        }
        downloadBlob(`${creditNoteNumber}.pdf`)(res.data)
      })
      .catch(e => Sentry.captureException(e))

    navigate(-1)
  }, [creditNoteId, creditNoteNumber, navigate])

  return <></>
}

const CreditNoteParamsHandler: FC = () => {
  const { creditNoteId, creditNoteNumber } = useParams<CreditNoteProps>()
  if (!creditNoteId) {
    return <Box>Missing credit note parameters</Box>
  }
  return (
    <CreditNotePdf
      creditNoteId={creditNoteId}
      creditNoteNumber={creditNoteNumber || ''}
    />
  )
}

export default CreditNoteParamsHandler
