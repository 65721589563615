import { useState, useEffect, useCallback } from 'react'
import {
  dashboardv20240509Client,
  Dashboardv20240509Api
} from '@sequencehq/api/dashboard/v20240509'

type CustomerAliasList =
  Dashboardv20240509Api.GetCustomerAliases.CustomerAliasList

export const useFetchCustomerAliases = (customerId: string) => {
  const [customerAliases, setCustomerAliases] =
    useState<CustomerAliasList | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchCustomerAliases = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const customerAliasesData: Dashboardv20240509Api.GetCustomerAliases.Response =
        await dashboardv20240509Client.getCustomerAliases({
          id: customerId
        })

      setCustomerAliases(customerAliasesData.data ?? null)
    } catch (e) {
      setError(e as Error)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [customerId])

  useEffect(() => {
    void fetchCustomerAliases()
  }, [fetchCustomerAliases])

  return { customerAliases, loading, error, refetch: fetchCustomerAliases }
}
