import { Box, Circle, Flex, Link, Text, useToast } from '@chakra-ui/react'
import { Toast } from '@sequencehq/core-components'
import { AuthenticationDocsLink } from 'lib/docsLink/authentication'
import { FC, useState } from 'react'
import { Clipboard, Eye } from 'react-feather'

export interface CreateApiKeyResponseProps {
  clientId: string
  name: string
  secret: string
}

export const CreateApiKeyResponse: FC<CreateApiKeyResponseProps> = ({
  clientId,
  name,
  secret
}) => {
  const [hidden, setHidden] = useState<boolean>(true)
  const toast = useToast()

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex flexDirection="column" alignItems="left">
        <Flex textAlign="left">
          <Text textStyle="formLabel">Client Name:</Text>
          <Box width="10px"></Box>
          <Text>{name}</Text>
        </Flex>
        <Box height="30px"></Box>
        <Flex textAlign="left">
          <Text textStyle="formLabel">Client ID:</Text>
          <Box width="10px"></Box>
          <Text>{clientId}</Text>
        </Flex>
        <Box height="20px"></Box>
        <Flex>
          <Flex flexDirection="column">
            <Box height="10px"></Box>

            <Flex>
              <Text textStyle="formLabel">Secret:</Text>
              <Box width="10px"></Box>
              <Text width="280px">
                {hidden ? '*'.repeat(secret.length) : secret}
              </Text>
            </Flex>
            <Box height="10px"></Box>
          </Flex>
          <Box width="10px"></Box>

          <Circle backgroundColor="#F0F4F7" size="37px" cursor="pointer">
            <Clipboard
              color="#667085"
              size="20px"
              onClick={() => {
                void navigator.clipboard.writeText(secret).then(() => {
                  toast({
                    id: 'copied',
                    position: 'bottom',
                    duration: 6000,
                    render: () => (
                      <Toast
                        title="Copied"
                        type="success"
                        isClosable={false}
                      ></Toast>
                    )
                  })
                })
              }}
            ></Clipboard>
          </Circle>
          <Box width="10px"></Box>
          <Circle backgroundColor="#F0F4F7" size="37px" cursor="pointer">
            <Eye
              data-testid="apiKeyCreated.show"
              color="#667085"
              size="20px"
              onClick={() => setHidden(value => !value)}
            ></Eye>
          </Circle>
        </Flex>
      </Flex>
      <Box height="20px"></Box>
      <Text paddingRight="40px" paddingLeft="40px" textAlign="center">
        Make a note of this secret. You will not be able to retrieve it if it is
        lost.
      </Text>
      <Text
        paddingRight="40px"
        paddingLeft="40px"
        paddingTop="20px"
        textAlign="center"
      >
        See{' '}
        <Link href={AuthenticationDocsLink} isExternal color="indigo.50">
          here
        </Link>{' '}
        for details on how to authenticate your requests.
      </Text>
      <Text
        paddingRight="40px"
        paddingLeft="40px"
        paddingTop="20px"
        textAlign="center"
      >
        <Link
          onClick={() => {
            void navigator.clipboard
              .writeText(window.btoa(`${clientId}:${secret}`))
              .then(() => {
                toast({
                  id: 'copied',
                  position: 'bottom',
                  duration: 6000,
                  render: () => (
                    <Toast
                      title="Copied"
                      type="success"
                      isClosable={false}
                    ></Toast>
                  )
                })
              })
          }}
          color="indigo.50"
        >
          Copy base64 encoded token to clipboard.
        </Link>
      </Text>
    </Flex>
  )
}
