import {
  useContractExtractionResult,
  useSetContractId
} from 'ContractExtraction/index'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type UseContractExtractionConnector = () => undefined

export const useContractExtractionConnector: UseContractExtractionConnector =
  () => {
    const flags = useFlags()
    const navigate = useNavigate()
    const cubeContext = useCubeContext()

    const setContractId = useSetContractId()
    const extractionResult = useContractExtractionResult()

    useEffect(() => {
      setContractId(cubeContext.queries.rawData.data.schedule.contractId)
    }, [cubeContext.queries.rawData.data.schedule.contractId, setContractId])

    useEffect(() => {
      if (!extractionResult || !flags?.useContractIntakePrototype) {
        return
      }

      navigate(`/billing-schedules/${extractionResult.billingScheduleId}`)
    }, [extractionResult, navigate, flags])
  }
