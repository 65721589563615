import { Box, Grid, Flex, Text, Center } from '@chakra-ui/react'
import { Button, SequenceMenu } from '@sequencehq/core-components'
import {
  RedRed60,
  Lato12Bold,
  Lato14Regular,
  GreyGrey60,
  GreyGrey10,
  Lato14Bold
} from '@sequencehq/design-tokens'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import { FC, Fragment } from 'react'
import { minimumRowActions, productRowActions } from './pricingTableRowActions'
import { usePricingTable } from './usePricingTable'
import { toBillingFrequencyLabel } from '@sequencehq/utils'
import { ExtractedDataIndicator } from 'ContractExtraction/index'

const styles = {
  tableHeader: {
    ...Lato12Bold,
    color: GreyGrey60,
    textTransform: 'uppercase' as const,
    border: 'none',
    padding: '10px 8px'
  },
  tableCell: {
    alignItems: 'center',
    ...Lato14Regular,
    border: 'none',
    textAlign: 'left' as const,
    padding: '8px'
  }
}

export const PricingTableCompact: FC<{
  phaseId: string
}> = props => {
  const { validationErrors, pricingGroups } = usePricingTable({
    phaseId: props.phaseId
  })

  const columnWidths = '226px 162px 156px 142px 20px'

  return (
    <Box data-testid="phase.pricingTable">
      <Box {...Lato14Bold} padding="16px 8px 8px 8px">
        Pricing
      </Box>
      <Box
        marginBottom="8px"
        border={validationErrors.length ? `1px solid ${RedRed60}` : ''}
        borderRadius="8px"
      >
        <Grid templateColumns={columnWidths} columnGap="8px">
          <Flex {...styles.tableHeader}>PRODUCT</Flex>
          <Flex {...styles.tableHeader}>FREQUENCY</Flex>
          <Flex {...styles.tableHeader}>DISCOUNT</Flex>
          <Flex {...styles.tableHeader} justifyContent="flex-end">
            PRICE
          </Flex>
          <Flex {...styles.tableHeader}></Flex>
        </Grid>
        {pricingGroups.length > 0 &&
          pricingGroups.map(group => {
            return (
              <Fragment
                key={`group${
                  group.minimumRow?.id || group.pricingRows[0]?.id
                }}`}
              >
                {group.minimumRow && (
                  <Grid
                    templateColumns={columnWidths}
                    columnGap="8px"
                    alignItems="center"
                    key={`group${
                      group.minimumRow?.id || group.pricingRows[0]?.id
                    }}`}
                    _hover={{
                      backgroundColor: GreyGrey10
                    }}
                  >
                    <Flex
                      {...styles.tableCell}
                      style={{ textWrap: 'wrap' }}
                      onClick={group.minimumRow.actions.edit.handler}
                      {...Lato14Bold}
                    >
                      <ExtractedDataIndicator
                        fields={[
                          `billingSchedule.phases[${props.phaseId}].minimums[0].*`
                        ]}
                        position="inline"
                      >
                        {group.minimumRow.name}
                      </ExtractedDataIndicator>
                    </Flex>
                    <Flex {...styles.tableCell} color={GreyGrey60}>
                      {toBillingFrequencyLabel(
                        group.minimumRow.billingFrequency
                      )}
                    </Flex>
                    <Flex {...styles.tableCell}></Flex>
                    <Flex {...styles.tableCell} justifyContent="flex-end">
                      {group.minimumRow.value}
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      maxWidth="36px"
                      height="36px"
                      padding="0px"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <SequenceMenu
                        alignment="bottom-right"
                        menuButton={
                          <Button variant="ghost" size="24px">
                            <Center flexShrink="0">
                              <EllipsisHorizontalIcon
                                width="16px"
                                height="16px"
                                color={GreyGrey60}
                              />
                            </Center>
                          </Button>
                        }
                        items={minimumRowActions(group.minimumRow)}
                      />
                    </Flex>
                  </Grid>
                )}
                {group.pricingRows.map(row => (
                  <Grid
                    data-test-has-minimum={Boolean(group.minimumRow)}
                    key={row.id}
                    templateColumns={columnWidths}
                    columnGap="8px"
                    cursor="pointer"
                    borderRadius="8px"
                    minHeight="36px"
                    onClick={row.onClick}
                    _hover={{
                      backgroundColor: GreyGrey10
                    }}
                    data-testid={`cube.priceRow.${row.id}`}
                  >
                    <Flex
                      {...styles.tableCell}
                      style={{ textWrap: 'wrap' }}
                      paddingLeft={group.minimumRow ? '16px' : '8px'}
                    >
                      {/* Show the contract extraction sparkle if:
                          - This row shows a price that was extracted
                          - This row represents a global discount and there is a discount in fieldsMetadata
                          - This row shows a discounted price and there is a discount in fieldsMetadata
                      */}
                      <ExtractedDataIndicator
                        fields={[
                          `billingSchedule.prices[${row.id}].*`,
                          ...(row.isGlobalDiscountRow || row.discount
                            ? [
                                `billingSchedule.phases[${props.phaseId}].discounts[0].*`
                              ]
                            : [])
                        ]}
                        position="inline"
                      >
                        {row.name}
                      </ExtractedDataIndicator>
                    </Flex>
                    <Flex {...styles.tableCell} color={GreyGrey60}>
                      {toBillingFrequencyLabel(row.billingFrequency)}
                    </Flex>
                    <Flex {...styles.tableCell}>
                      {row.discount ? (
                        <Flex
                          key={row.discount.id}
                          alignContent="center"
                          gap="4px"
                          _hover={{
                            opacity: 0.5
                          }}
                          onClick={e => {
                            e.stopPropagation()
                            row.availableFeatures.canEditDiscount?.handler()
                          }}
                        >
                          {row.discount.label}
                        </Flex>
                      ) : null}
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      justifyContent="flex-end"
                      gap="6px"
                    >
                      {row.price !== row.preDiscountPrice && (
                        <Text
                          textDecoration="line-through"
                          textAlign="right"
                          color={GreyGrey60}
                        >
                          {row.preDiscountPrice}
                        </Text>
                      )}
                      <Text textAlign="right">{row.price}</Text>
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      maxWidth="36px"
                      height="100%"
                      padding="0px"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <SequenceMenu
                        alignment="bottom-right"
                        data-testid={`cube.priceRow.${row.id}.menu`}
                        menuButton={
                          <Button variant="ghost" size="24px">
                            <Center flexShrink="0">
                              <EllipsisHorizontalIcon
                                width="16px"
                                height="16px"
                                color={GreyGrey60}
                              />
                            </Center>
                          </Button>
                        }
                        items={productRowActions(row)}
                      />
                    </Flex>
                  </Grid>
                ))}
              </Fragment>
            )
          })}
      </Box>
    </Box>
  )
}
