import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { SequenceMenuItem } from '@sequencehq/core-components'
import { useNotifications } from 'lib/hooks/useNotifications'
import invariant from 'tiny-invariant'

const useDuplicateQuote = () => {
  const notifications = useNotifications()

  const duplicationMutation = useMutation(
    dashboard20240730Client.postQuoteDuplicate
  )

  const duplicateQuote = async (quoteId: string): Promise<string | null> => {
    notifications.displayNotification('Duplicating quote...', {
      type: 'info'
    })
    try {
      const duplicatedQuote = await duplicationMutation.mutateAsync({
        id: quoteId
      })

      invariant(duplicatedQuote, 'Quote not found')

      notifications.displayNotification('Quote duplicated', {
        type: 'success'
      })
      return duplicatedQuote.id
    } catch {
      notifications.displayNotification('Unable to duplicate quote', {
        type: 'error'
      })
      return null
    }
  }

  return {
    duplicateQuote,
    inProgress: duplicationMutation.isPending
  }
}

export const DuplicateQuoteMenuItem = (props: {
  quoteId: string
  disabled?: boolean
  onSuccess?: (newQuoteId: string) => void
}) => {
  const { duplicateQuote } = useDuplicateQuote()
  return (
    <SequenceMenuItem
      uuid="duplicate-quote"
      label="Duplicate quote"
      data-testid={`quotes.${props.quoteId}.menuItem.duplicate`}
      isDisabled={props.disabled}
      onClick={async () => {
        const res = await duplicateQuote(props.quoteId)
        if (res) {
          props.onSuccess?.(res)
        }
      }}
    />
  )
}
