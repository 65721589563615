import { Box, Flex } from '@chakra-ui/react'
import Spinner from 'components/Loading/Spinner'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  IntegrationServices,
  useApiQueryClient,
  useMutation,
  useQuery,
  useRunInvalidationForMutation
} from '@sequencehq/api/dist/utils'
import { Button, Skeleton } from '@sequencehq/core-components'
import {
  GreyGrey10,
  GreyGrey60,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { ExistingEntityLink } from '../LinkEntityWidget/ExistingEntityLink'
import { UnlinkCreditNoteModal } from './UnlinkCreditNoteModal'
import { useNotifications } from 'lib/hooks/useNotifications'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

interface LinkedCreditNoteWidgetProps {
  creditNoteId: string
  service: IntegrationServices
}

const useLinkedCreditNoteWidget = (props: {
  service: IntegrationServices
  creditNoteId: string
}) => {
  const serviceName = getIntegrationName(props.service)
  const { displayNotification } = useNotifications()
  const apiQueryClient = useApiQueryClient()

  const creditNoteQuery = useQuery(
    dashboardv99990101Client.getCreditNote,
    { id: props.creditNoteId },
    {
      select: creditNote => {
        if (!creditNote) {
          return null
        }

        const externalCreditNote = creditNote.linkedServices.find(
          ls => ls.externalService === props.service
        )

        return {
          customerId: creditNote.customerId,
          statusAllowsLink: ['SENT', 'FINAL'].includes(creditNote.status),
          linkedCreditNote: externalCreditNote
            ? {
                id: externalCreditNote.externalId,
                url: externalCreditNote.externalUrl
              }
            : null
        }
      }
    }
  )

  const customerQuery = useQuery(
    dashboardv99990101Client.getCustomer,
    { id: creditNoteQuery.data?.customerId ?? '' },
    {
      enabled: Boolean(creditNoteQuery.data?.customerId),
      select: customer => {
        if (!customer) {
          return null
        }

        const customerLink = customer.integrationIds.find(
          int => int.service === props.service
        )

        return customerLink
      }
    }
  )

  const linkingMutation = useMutation(
    dashboard20240730Client.postPushCreditNoteToExternalService
  )
  const runMutationInvalidation = useRunInvalidationForMutation()
  const linkCreditNote = () => {
    linkingMutation.mutate(
      {
        creditNoteId: props.creditNoteId,
        service: props.service
      },
      {
        onError: () => {
          displayNotification(`Failed to sync credit note to ${serviceName}`, {
            type: 'error'
          })
        },
        onSuccess: () => {
          displayNotification(`Credit note synced to ${serviceName}`, {
            type: 'success'
          })
          /**
           * The external URL doesn't appear immediately, so wait a little while
           * and then bust the cache to ensure we load in the updated data.
           */
          setTimeout(() => {
            runMutationInvalidation(
              dashboard20240730Client.postPushCreditNoteToExternalService
            )
          }, 2000)
        }
      }
    )
  }

  return {
    loading: creditNoteQuery.isPending,
    serviceName,
    creditNoteStatusAllowsLink: creditNoteQuery.data?.statusAllowsLink,
    linkedCreditNote: creditNoteQuery.data?.linkedCreditNote,
    link: {
      disabled: !customerQuery.data || linkingMutation.isPending,
      onClick: linkCreditNote,
      linking: linkingMutation.isPending
    }
  }
}

const NotReadyToLink = () => (
  <Flex
    flexDirection="column"
    gap="4px"
    padding="12px"
    backgroundColor={GreyGrey10}
    borderRadius="8px"
  >
    <Box {...Lato13Bold} color={GreyGrey80}>
      Credit note
    </Box>
    <Box
      {...Lato13Regular}
      color={GreyGrey60}
      data-testid="linkCreditNoteWidget.willSyncWhenFinal"
    >
      This credit note will be synced once it's issued in Sequence.
    </Box>
  </Flex>
)

export const LinkedCreditNoteWidget = (props: LinkedCreditNoteWidgetProps) => {
  const hook = useLinkedCreditNoteWidget(props)

  if (hook.loading) {
    return <Skeleton height="104px" width="100%" />
  }

  if (!hook.creditNoteStatusAllowsLink) {
    return <NotReadyToLink />
  }

  if (hook.linkedCreditNote) {
    return (
      <ExistingEntityLink
        data-testid={`linkCreditNoteWidget.${props.service}.linked`}
        externalEntityLabel="CreditNote"
        serviceName={hook.serviceName}
        unlinkModal={
          <UnlinkCreditNoteModal
            service={props.service}
            creditNoteId={props.creditNoteId}
          />
        }
        linkedEntity={{
          label: `ID: ${hook.linkedCreditNote.id}`,
          href: hook.linkedCreditNote.url,
          canUnlink: true,
          isPending: false
        }}
      />
    )
  }

  return (
    <Flex
      flexDirection="column"
      gap="4px"
      padding="12px"
      backgroundColor={GreyGrey10}
      borderRadius="8px"
      data-testid={`linkCreditNoteWidget.${props.service}`}
    >
      <Box {...Lato13Bold} color={GreyGrey80}>
        Credit note
      </Box>
      <Box {...Lato13Regular} color={GreyGrey60} marginBottom="4px">
        This credit note has not yet been synced to {hook.serviceName}.
      </Box>
      <Button
        variant="secondary"
        onClick={hook.link.onClick}
        disabled={hook.link.disabled}
        data-testid={`linkCreditNoteWidget.${props.service}.syncCreditNote`}
      >
        {hook.link.linking ? (
          <Flex gap="8px">
            <Spinner height="16px" width="16px" color={GreyGrey60} />
            Syncing...
          </Flex>
        ) : (
          'Sync credit note'
        )}
      </Button>
    </Flex>
  )
}
