import { Box, Flex, Grid } from '@chakra-ui/react'
import { Card, Skeleton, Switch, Banner } from '@sequencehq/core-components'
import { Lato14Bold } from '@sequencehq/design-tokens'
import { WorkflowManagement } from 'modules/Integrations/integrationsConfig/common/WorkflowManagement'
import { ConfigurationManagementProps } from 'Integrations/domain'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useForm } from '@sequencehq/utils'
import {
  QuickBooksConfig,
  QuickBooksTaxCodeMapping
} from 'Integrations/integrationsConfig/quickBooks/quickbooks.integration.config'
import { useMemo } from 'react'
import deepmerge from 'deepmerge'
import sequenceLogo from 'Integrations/integrationsConfig/common/sequenceLogo.svg'
import { DefaultQuickBooksAccountCard } from 'Integrations/integrationsConfig/quickBooks/QuickBooksConfigurationManagement/DefaultQuickBooksAccountCard'
import { TaxCodesCard } from 'Integrations/integrationsConfig/quickBooks/QuickBooksConfigurationManagement/TaxCodesCard'
import invariant from 'tiny-invariant'
import { useQuery } from '@sequencehq/api'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { QBO_ACCOUNT_TYPES_ALLOWING_ITEM_PUSH } from 'Integrations/integrationsConfig/quickBooks/constants'

export const QuickBooksConfigurationManagement = (
  props: ConfigurationManagementProps
) => {
  invariant(props.integration.id === 'QuickBooks_Online', 'Invalid integration')
  const flags = useFlags()

  const configuration = props.integration.configuration as QuickBooksConfig

  const mappings = configuration?.taxCodeMappings as Record<
    string,
    QuickBooksTaxCodeMapping
  >

  const taxCodeMappingFields: { property: `taxCodeMappings.${string}` }[] =
    Object.keys(mappings).map(taxCategoryId => ({
      property: `taxCodeMappings.${taxCategoryId}`
    }))

  const { fields } = useForm({
    value: (configuration ?? {
      importCustomerWorkflow: false,
      taxExemptTaxCode: '',
      taxableTaxCode: '',
      reverseChargedTaxCode: '',
      defaultAccount: '',
      taxCodeMappings: {}
    }) as QuickBooksConfig,
    fieldConfiguration: [
      {
        property: 'importCustomerWorkflow'
      },
      {
        property: 'taxExemptTaxCode'
      },
      {
        property: 'taxableTaxCode'
      },
      {
        property: 'reverseChargedTaxCode'
      },
      {
        property: 'defaultAccount'
      },
      {
        property: 'taxCodeMappings'
      },
      ...taxCodeMappingFields
    ],
    onChange: newData => {
      props.updateConfiguration(newData)
    },
    onValidationStateChange: isValid => {
      props.updateValidationState(isValid)
    }
  })

  const fieldsConfig = useMemo(() => {
    return deepmerge(fields, {
      importCustomerWorkflow: {
        logoUrls: {
          from: props.integration.smallLogoUrl,
          to: sequenceLogo
        },
        status: props.integration.configuration?.importCustomerWorkflow
          ? ('ACTIVE' as const)
          : ('INACTIVE' as const)
      }
    })
  }, [fields, props.integration])

  const {
    taxExemptTaxCode,
    taxableTaxCode,
    reverseChargedTaxCode,
    importCustomerWorkflow,
    defaultAccount,
    ...dynamicTaxCodeFields
  } = fieldsConfig

  const defaultTaxCodeFields = {
    taxExemptTaxCode,
    taxableTaxCode,
    reverseChargedTaxCode
  }

  const { data: qboAccountsData, isLoading: isLoadingQboAccounts } = useQuery(
    dashboardv99990101Client.getQuickbooksAccounts,
    undefined,
    {
      enabled:
        flags.useQuickBooksProductLinking &&
        props.integration.state === 'CONNECTED'
    }
  )
  const { data: quickbooksTaxCodesData, isLoading: isLoadingQboTaxCodes } =
    useQuery(dashboardv99990101Client.getQuickbooksTaxCodes, undefined, {
      enabled:
        flags.useQuickBooksProductLinking &&
        props.integration.state === 'CONNECTED'
    })

  const quickbooksAccounts =
    qboAccountsData?.items?.filter(account =>
      QBO_ACCOUNT_TYPES_ALLOWING_ITEM_PUSH.includes(account.type ?? '')
    ) ?? []

  const quickbooksTaxCodes = quickbooksTaxCodesData?.items ?? []

  // Check if default account and tax codes are set
  const isDefaultAccountMissing = !defaultAccount.value
  const hasAllTaxCodeDefaults = [
    taxExemptTaxCode,
    taxableTaxCode,
    reverseChargedTaxCode
  ].every(({ value }) => value?.length)

  // Show warning banner if any required settings are missing
  const showWarningBanner =
    flags.useQuickBooksProductLinking &&
    (isDefaultAccountMissing || !hasAllTaxCodeDefaults)

  const getWarningMessage = () => {
    const hasOnlyDefaultAccountMissing =
      isDefaultAccountMissing && hasAllTaxCodeDefaults

    const hasOnlyTaxCodesMissing =
      !isDefaultAccountMissing && !hasAllTaxCodeDefaults

    if (hasOnlyDefaultAccountMissing) {
      return 'Set a default account code for accurate invoice syncing.'
    }

    if (hasOnlyTaxCodesMissing) {
      return 'Map customer tax codes (exempt, reverse charged and fallback) for accurate invoice syncing.'
    }

    return 'Set a default account code and map customer tax codes (exempt, reverse charged and fallback) for accurate invoice syncing.'
  }

  if (isLoadingQboAccounts || isLoadingQboTaxCodes) {
    return (
      <Flex flexDirection="column" gap="20px" width="100%">
        <Skeleton width="100%" height="165px" />
        <Skeleton width="100%" height="174px" />
        <Skeleton width="100%" height="476px" />
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" gap="20px" width="100%">
      {showWarningBanner && (
        <Banner
          variant="warning"
          alignIcon="center"
          data-testid="quickbooks.configuration.warning"
        >
          {getWarningMessage()}
        </Banner>
      )}
      <Card>
        <Grid gridRowGap="16px">
          <Box {...Lato14Bold}>Customers</Box>
          <WorkflowManagement
            logoUrls={fieldsConfig.importCustomerWorkflow.logoUrls}
            status={fieldsConfig.importCustomerWorkflow.status}
            label="Link customers to QuickBooks"
            description="Enable customer linking in the customers page"
            onClick={() => {
              fieldsConfig.importCustomerWorkflow.onChange(
                !fieldsConfig.importCustomerWorkflow.value
              )
            }}
          >
            <Flex alignContent="center" onClick={e => e.stopPropagation()}>
              <Switch
                data-testId="switch.linkCustomerWorkflow"
                checked={fieldsConfig.importCustomerWorkflow.value}
                onChange={fieldsConfig.importCustomerWorkflow.onChange}
              />
            </Flex>
          </WorkflowManagement>
        </Grid>
      </Card>
      {flags.useQuickBooksProductLinking && (
        <>
          <DefaultQuickBooksAccountCard
            quickbooksAccounts={quickbooksAccounts}
            fieldsConfig={fieldsConfig}
          />
          <TaxCodesCard
            taxCodeDefaultsFieldsConfig={defaultTaxCodeFields}
            taxCodeMappingFieldsConfig={dynamicTaxCodeFields}
            mappings={mappings}
            quickbooksTaxCodes={quickbooksTaxCodes}
          />
        </>
      )}
    </Flex>
  )
}
