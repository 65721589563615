import { useSequenceInfiniteQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { HOURS } from '@sequencehq/utils/dates'
import { useEffect } from 'react'

/**
 * We use a one hour stale time, because cache busting will result in this value being refreshed
 * anyway. At this stage, all interactions with the customer and alias data is done via the API
 * package.
 */
export const usePrefetchCustomerAndAliases = () => {
  const customersInfiniteQuery = useSequenceInfiniteQuery(
    dashboard20240730Client.getCustomers,
    {
      limit: 100
    },
    {
      staleTime: 1 * HOURS,
      maxPages: 30
    }
  )

  const customerAliasesInfiniteQuery = useSequenceInfiniteQuery(
    dashboard20240730Client.getCustomerAliases,
    {
      limit: 100
    },
    {
      staleTime: 1 * HOURS,
      maxPages: 30
    }
  )

  /**
   * Handle the refetching of all the customer data
   */
  useEffect(() => {
    if (
      customersInfiniteQuery.infiniteQuery.hasNextPage &&
      !customersInfiniteQuery.infiniteQuery.isPending
    ) {
      customersInfiniteQuery.infiniteQuery.fetchNextPage()
    }
  }, [
    customersInfiniteQuery,
    /** Needed to trigger the useEffect and exhaust the entire set of customers */
    customersInfiniteQuery.infiniteQuery.data?.pages.length
  ])

  /**
   * Handle the refetching of all the customer alias data
   */
  useEffect(() => {
    if (
      customerAliasesInfiniteQuery.infiniteQuery.hasNextPage &&
      !customerAliasesInfiniteQuery.infiniteQuery.isPending
    ) {
      customerAliasesInfiniteQuery.infiniteQuery.fetchNextPage()
    }
  }, [
    customerAliasesInfiniteQuery,
    /** Needed to trigger the useEffect and exhaust the entire set of aliases */
    customerAliasesInfiniteQuery.infiniteQuery.data?.pages.length
  ])
}
