import { InsightsUsageMetricResponse } from '@sequencehq/core-models'
import { useMemo, useState } from 'react'
import { useFetchUsageMetrics } from 'lib/hooks/useFetchUsageMetrics.ts'
import { format } from '@sequencehq/utils/dates'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

interface DateRange {
  start: Date
  end: Date
}

export const useUsageMetricsWidget = (dateRange: DateRange) => {
  const [usageMetricId, setUsageMetricId] = useState('')

  const { metrics: usageMetrics, loading: isOptionsLoading } =
    useFetchUsageMetrics()

  const usageDataQuery = useQuery(
    dashboard20240730Client.getInsightsUsageData,
    {
      usageMetricId,
      dateFrom: format(dateRange.start, 'yyyy-MM-dd'),
      dateTo: format(dateRange.end, 'yyyy-MM-dd')
    },
    {
      enabled: Boolean(usageMetricId)
    }
  )

  const usageMetricDataValue = usageDataQuery.data

  const { total, data } = useMemo(() => {
    if (!usageMetricDataValue) {
      return {
        total: 0,
        data: []
      }
    }

    const { total: usageTotal, usageData } = usageMetricDataValue

    return {
      total: Number(usageTotal) || 0,
      data: usageData.map(({ periodStart, amount }) => ({
        date: periodStart,
        value: Number(amount) || 0
      }))
    }
  }, [usageMetricDataValue])

  const unitTickValues = useMemo(() => {
    if (!data || data.length === 0) {
      return [0, 0]
    }

    const max = Math.max(...data.map(d => d.value))
    return [0, max / 2, max]
  }, [data])

  const usageMetricOptions = useMemo(() => {
    const options = usageMetrics?.map(({ id, name }) => ({
      value: id,
      label: name
    }))

    if (!usageMetricId && options && options.length > 0) {
      setUsageMetricId(options[0].value)
    }

    return options
  }, [usageMetricId, usageMetrics])

  const usageMetricTypeField = useMemo(
    () => ({
      value: usageMetricId,
      label: usageMetricOptions?.find(option => option.value === usageMetricId)
        ?.label,
      onChange: setUsageMetricId,
      options: usageMetricOptions || []
    }),
    [usageMetricId, usageMetricOptions, setUsageMetricId]
  )

  const chart = useMemo(
    () => ({
      data,
      total,
      unit: '',
      unitTickValues,
      tooltipFormat: (value: number) =>
        `${value.toLocaleString('en-US')} events`
    }),
    [data, total, unitTickValues]
  )

  return {
    isLoading: usageDataQuery.isPending || isOptionsLoading,
    usageMetricTypeField,
    chart
  }
}
