import { Grid } from '@chakra-ui/react'
import {
  Modal,
  ModalContextProvider,
  Skeleton
} from '@sequencehq/core-components'
import { ReactNode, useMemo } from 'react'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
import { UsageRecognitionDrawerLayout } from './recognitionDrawerLayouts/UsageRecognitionDrawerLayout'
import { match } from 'ts-pattern'
import { MilestoneRecognitionDrawerLayout } from './recognitionDrawerLayouts/milestone/MilestoneRecognitionDrawerLayout'
import { StraightLineRecognitionDrawerLayout } from './recognitionDrawerLayouts/StraightLineRecognitionDrawerLayout'
import { ErrorBoundary } from '@sentry/react'
import PageError from 'components/ErrorPages/PageError'

const useRecognitionMethod = (props: {
  scheduleId: string
}):
  | {
      isLoading: true
      recognitionMethod: null
    }
  | {
      isLoading: false
      recognitionMethod: Dashboardv99990101Api.GetDeferredRevenueSchedule.DeferredRecognitionMethod
    } => {
  const scheduleQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedule,
    { id: props.scheduleId },
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          recognitionMethod: res.recognitionMethod
        }
      }
    }
  )

  if (scheduleQuery.error) {
    throw new Error('Deferred revenue data could not be loaded')
  }

  if (scheduleQuery.isPending) {
    return {
      isLoading: true,
      recognitionMethod: null
    }
  }

  invariant(
    scheduleQuery.data,
    'Deferred revenue schedule data should be available at this point'
  )

  return {
    isLoading: false,
    recognitionMethod: scheduleQuery.data.recognitionMethod
  }
}

/**
 * This component serves as a 'switch' for the different recognition methods
 * and their content. Having the switch here, rather than a distinct modal per
 * recognition method, allows us to keep the modal logic nice and colocated,
 * and easier to use at the callsite.
 * @param props
 * @returns
 */
export const DevRefScheduleDetailDrawerContent = (props: {
  defRevScheduleId: string
}) => {
  const { isLoading, recognitionMethod } = useRecognitionMethod({
    scheduleId: props.defRevScheduleId
  })

  const ContentToRender = useMemo(() => {
    if (isLoading) {
      return (
        <Grid gridTemplateRows={'auto 1fr auto'}>
          <Skeleton height="140px" />
          <Skeleton height="160px" />
          <Skeleton height="32px" />
        </Grid>
      )
    }

    return match(recognitionMethod)
      .with('USAGE', () => {
        return (
          <UsageRecognitionDrawerLayout
            defRevScheduleId={props.defRevScheduleId}
          />
        )
      })
      .with('STRAIGHT_LINE', () => {
        return (
          <StraightLineRecognitionDrawerLayout
            defRevScheduleId={props.defRevScheduleId}
          />
        )
      })
      .with('MILESTONE', () => {
        return (
          <MilestoneRecognitionDrawerLayout
            defRevScheduleId={props.defRevScheduleId}
          />
        )
      })
      .exhaustive()
  }, [isLoading, recognitionMethod, props.defRevScheduleId])

  return ContentToRender
}

export const DefRevScheduleDetailDrawer = (props: {
  trigger: (openFn: () => void) => ReactNode
  defRevScheduleId: string
}) => {
  return (
    <ModalContextProvider>
      <Modal
        variant="drawer"
        unmountOnClose
        data-testid="revrec.defRevDetail.modal"
      >
        <ErrorBoundary fallback={PageError}>
          <DevRefScheduleDetailDrawerContent
            defRevScheduleId={props.defRevScheduleId}
          />
        </ErrorBoundary>
      </Modal>
      <Modal.Trigger>{openFn => props.trigger(openFn)}</Modal.Trigger>
    </ModalContextProvider>
  )
}
