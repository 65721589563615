import { DeleteApiKeyForm } from 'components/DeleteApiKey'
import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { QueryParams } from 'lib/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export interface DeleteApiKeyFormProps {
  id: string
  name: string
}
export const DeleteApiKeyModal = createFormModal<
  QueryParams,
  DeleteApiKeyFormProps
>({
  FormComponent: DeleteApiKeyForm,
  useMutation: apiPackageAdapter20240730<
    {
      id: string
    },
    typeof dashboard20240730Client.deleteOAuthClient
  >('deleteOAuthClient', ({ id }) => ({ id })),
  modalTitleFromProps: () => 'Delete API key',
  successMessage: 'API key deleted',
  submitTitle: 'Delete',
  formId: 'deleteApiKeyForm',
  successToast: (response, inputs) => (
    <Toast
      title="API key deleted"
      type="success"
      description={`API key ${inputs.name} deleted`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})
