import { Flex, Button, Text } from '@chakra-ui/react'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'
import { Link } from 'react-router-dom'

import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey80,
  GreyWhite,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { Badge, RadioButtonGroup } from '@sequencehq/core-components'
import { useHeader } from './useHeader.tsx'
import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'
import { formatQuoteTitle } from 'Cube/utils/formatQuoteTitle'

// Constants for reusable values
const HEADER_HEIGHT = '48px'
const TITLE_MAX_WIDTH = '450px'
const CONTAINER_MAX_WIDTH = '60%'

const styles = {
  title: {
    ...Lato14Bold,
    color: GreyGrey80
  },
  archivedTitle: {
    ...Lato14Bold,
    color: GreyGrey60
  }
}

type Props = {
  hook: QuoteEditorPreviewProps
}

// Extracted component for the title section
type TitleSectionProps = {
  title: string
  isArchived: boolean
  hasUnpublishedChanges: boolean
  isExpired: boolean
}

const TitleSection = ({
  title,
  isArchived,
  hasUnpublishedChanges,
  isExpired
}: TitleSectionProps) => (
  <Flex
    position="absolute"
    left="50%"
    transform="translateX(-50%)"
    gap="4px"
    alignItems="center"
    maxWidth={CONTAINER_MAX_WIDTH}
  >
    <Text
      {...Lato14Regular}
      color={GreyGrey60}
      isTruncated
      display="flex"
      alignItems="center"
    >
      <Text as={Link} color="inherit" to="/quotes" display="inline-block">
        Quotes
      </Text>
      <Text as="span" mx="4px">
        /
      </Text>
      <Text
        as="span"
        style={isArchived ? styles.archivedTitle : styles.title}
        maxWidth={TITLE_MAX_WIDTH}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        display="inline-block"
        title={title}
      >
        {title}
      </Text>
    </Text>

    {hasUnpublishedChanges && (
      <Badge sentiment="highlight" size="md" textTransform="none">
        Unpublished changes
      </Badge>
    )}
    {isExpired && (
      <Badge sentiment="error" size="md" textTransform="none">
        Expired
      </Badge>
    )}
  </Flex>
)

export const Header = ({ hook }: Props) => {
  const { downloadPdfButton, viewMode, cubeContext } = useHeader(hook)

  const title = formatQuoteTitle({
    domainTitle: cubeContext.queries.rawData.data.common.title,
    isArchived: cubeContext.queries.rawData.data.common.isArchived
  })

  const hasUnpublishedChanges =
    cubeContext.queries.availableFeatures.quote.deleteLatestDraft.available
      .visible
  const isExpired = cubeContext.queries.rawData.data.quote.isExpired

  return (
    <Flex
      background={GreyWhite}
      borderBottom={`${GreyGrey30} 1px solid`}
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      px="16px"
      height={HEADER_HEIGHT}
      position="relative"
    >
      <Flex gap="16px" alignItems="center">
        <RadioButtonGroup
          options={viewMode.options}
          value={viewMode.current}
          onChange={viewMode.onChange}
          data-testid="preview.quoteEditor.viewMode"
        />
      </Flex>

      <Flex gap="8px">
        <Button
          variant="component-library-secondary"
          leftIcon={
            <ArrowDownTrayIcon width="16px" height="16px" color="inherit" />
          }
          iconSpacing="4px"
          onClick={downloadPdfButton.onClick}
        >
          Download PDF
        </Button>
      </Flex>

      <TitleSection
        title={title}
        isArchived={cubeContext.queries.rawData.data.common.isArchived}
        hasUnpublishedChanges={hasUnpublishedChanges}
        isExpired={isExpired}
      />
    </Flex>
  )
}
