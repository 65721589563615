import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useProductPriceOutletConnector = () => {
  const navigate = useNavigate()
  const {
    data: { price, product }
  } = useProductContext()

  const onClose = useCallback(() => {
    navigate(`/products/${product.id}`)
  }, [navigate, product.id])

  return {
    onClose,
    price
  }
}
