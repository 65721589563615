import { DeleteNotificationPolicyForm } from 'components/DeleteNotificationPolicy/DeleteNotificationPolicyForm'
import {
  apiPackageAdapter20240730,
  createFormModal
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { QueryParams } from 'lib/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type DeleteNotificationPolicyFormProps = {
  id: string
  name: string
}

export const DeleteNotificationPolicyModal = createFormModal<
  QueryParams,
  DeleteNotificationPolicyFormProps
>({
  FormComponent: DeleteNotificationPolicyForm,
  useMutation: apiPackageAdapter20240730<
    { id: string },
    typeof dashboard20240730Client.deleteNotificationPolicy
  >('deleteNotificationPolicy', ({ id }) => ({ id })),
  modalTitleFromProps: () => 'Delete notification policy',
  successMessage: 'Notification policy deleted',
  submitTitle: 'Delete',
  formId: 'deleteNotificationPolicyForm',
  successToast: response => (
    <Toast
      title="Notification policy deleted"
      type="success"
      description={`Notification policy ${response.name} deleted`}
      isClosable={true}
    ></Toast>
  )
})
