import {
  createFormModal,
  apiPackageAdapter99990101
} from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { UpdateAndSendCreditNoteStatusFormProps } from 'components/UpdateInvoiceStatus/types'
import SendCreditNoteForm from 'components/UpdateInvoiceStatus/SendCreditNoteForm'
import { QueryParams } from 'lib/types'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

const SendCreditNoteModal = createFormModal<
  QueryParams,
  UpdateAndSendCreditNoteStatusFormProps
>({
  FormComponent: SendCreditNoteForm,
  useMutation: apiPackageAdapter99990101<
    UpdateAndSendCreditNoteStatusFormProps,
    typeof dashboardv99990101Client.postSendCreditNote
  >('postSendCreditNote', ({ id }) => ({ id })),
  modalTitle: 'Send Credit Note',
  successMessage: 'Credit Note sent',
  submitTitle: 'Send Credit Note',
  formId: 'sendCreditNoteForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Credit Note ${
        response.creditNoteNumber ? response.creditNoteNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue',
  errorText: 'Failed to send credit note'
})

export default SendCreditNoteModal
