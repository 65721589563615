import { Flex, Grid, Text } from '@chakra-ui/react'
import {
  Badge,
  Button,
  SequenceKebabMenu,
  SequenceKebabMenuItem
} from '@sequencehq/core-components'
import { toBillingScheduleStatusBadgeProps } from '@sequencehq/core-models'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey80,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useBillingScheduleHeader } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleHeader/useBillingScheduleHeader'

import { CubeStatus } from 'modules/Cube/domain/cube.domain.types'
import { scheduleStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { ConfirmStartScheduleModal } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleHeader/modals/ConfirmStartSchedule/ConfirmStartScheduleModal'
import { Blocker } from 'common/components/Blocker'
import { SteppedConfirmStartModal } from './modals/SteppedConfirmStartModal/SteppedConfirmStartModal'
import { ArchiveScheduleMenuItem } from 'common/menuItems/schedules/ArchiveScheduleMenuItem'
import { DuplicateScheduleMenuItem } from 'common/menuItems/schedules/DuplicateScheduleMenuItem'
import { useNavigate } from 'react-router-dom'

export const BillingScheduleHeader = () => {
  const {
    title,
    status,
    save,
    cancel,
    saveDraft,
    blocker,
    isArchived,
    modals,
    onArchive,
    scheduleId
  } = useBillingScheduleHeader()
  const navigate = useNavigate()
  const menuItems = [
    ...(status === CubeStatus.ScheduleUnsaved
      ? []
      : [
          <DuplicateScheduleMenuItem
            key="duplicate-schedule"
            data-testid={`schedule.menuItem.duplicate`}
            scheduleId={scheduleId}
            onSuccess={(newScheduleId: string) =>
              navigate(`/billing-schedules/${newScheduleId}`)
            }
          />
        ]),
    ...(isArchived || status === CubeStatus.ScheduleUnsaved
      ? []
      : [
          (({ setIsOpen }) => (
            <ArchiveScheduleMenuItem
              key="archive-schedule"
              data-testid={`schedule.menuItem.archive`}
              scheduleId={scheduleId}
              onSuccess={() => {
                setIsOpen?.(false)
                onArchive()
              }}
            />
          )) as SequenceKebabMenuItem
        ])
  ]

  return (
    <Grid
      templateColumns={`1fr auto`}
      gridArea="header"
      position="sticky"
      top={0}
      left={0}
      h={47}
      borderBottom={`1px solid ${GreyGrey30}`}
      alignItems="center"
      px="16px"
      columnGap="24px"
    >
      <Flex alignItems="center" gap="8px">
        <Text {...Lato16Bold} color={isArchived ? GreyGrey60 : GreyGrey80}>
          {title}
        </Text>
        {status !== CubeStatus.ScheduleUnsaved && (
          <Badge
            {...toBillingScheduleStatusBadgeProps({
              status: scheduleStatusAdapter.out(status) ?? 'DRAFT'
            })}
          />
        )}
      </Flex>

      <Flex alignItems="center" gap="8px">
        <Button variant="secondary" onClick={cancel.onClick}>
          Cancel
        </Button>
        {saveDraft.show && (
          <Button
            data-testid="schedule.saveDraft"
            variant="secondary"
            onClick={saveDraft.onClick}
            disabled={saveDraft.disabled}
          >
            {saveDraft.text}
          </Button>
        )}
        {save.show && (
          <Button
            variant="primary"
            data-testid="schedule.start"
            onClick={save.onClick}
            disabled={save.disabled}
          >
            {save.text}
          </Button>
        )}
        {Boolean(menuItems.length) && (
          <SequenceKebabMenu
            data-testid={`schedule.menu`}
            buttonSize="small"
            alignment="bottom-right"
            items={menuItems}
          />
        )}
      </Flex>
      {modals.confirmStart.active && (
        <ConfirmStartScheduleModal
          onClose={modals.confirmStart.onClose}
          onConfirm={modals.confirmStart.onConfirm}
          submitting={modals.confirmStart.submitting}
        />
      )}
      {modals.steppedConfirmStart.active && (
        <SteppedConfirmStartModal
          onClose={modals.steppedConfirmStart.onClose}
          onConfirm={modals.steppedConfirmStart.onConfirm}
          submitting={modals.steppedConfirmStart.submitting}
          billingScheduleQueries={
            modals.steppedConfirmStart.billingScheduleQueries
          }
        />
      )}
      <Blocker
        name="billing schedule editor"
        dirty={blocker.enabled}
        ignoreBlock={blocker.ignoreBlock}
        submitting={blocker.submitting}
      />
    </Grid>
  )
}
