import LinkExpiredPage from 'modules/LoginApp/components/LinkExpiredPage'
import LoginPage from './components/LoginPage/LoginPage'
import SelectWorkspacePage from 'modules/LoginApp/components/SelectWorkspacePage'
import NoWorkspacePage from 'modules/LoginApp/components/NoWorkspacePage'
import ErrorPage from 'modules/LoginApp/components/ErrorPage'
import { Outlet, RouteObject, createBrowserRouter } from 'react-router-dom'

const AppContainer = () => (
  <>
    <Outlet />
  </>
)

export const loginRoutes: RouteObject[] = [
  {
    path: '/',
    element: <AppContainer />,
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'callback', element: <SelectWorkspacePage /> },
      { path: 'expired', element: <LinkExpiredPage /> },
      { path: 'select-workspace', element: <SelectWorkspacePage /> },
      { path: 'no-workspace', element: <NoWorkspacePage /> },
      { path: 'error', element: <ErrorPage /> }
    ]
  }
]

export const loginRouter = createBrowserRouter(loginRoutes, {
  basename: '/auth'
})
