import { DefaultReactSuggestionItem } from '@blocknote/react'
import { Icon } from '@chakra-ui/react'
import {
  BanknotesIcon,
  ListBulletIcon,
  PhotoIcon,
  Bars4Icon
} from '@heroicons/react/16/solid'
import { Heading1Icon } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomSuggestionMenu/Heading1Icon'
import { Heading2Icon } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomSuggestionMenu/Heading2Icon'
import { Heading3Icon } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomSuggestionMenu/Heading3Icon'
import { NumberedListIcon } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomSuggestionMenu/NumberedListIcon'
import { blocknoteSchema } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/blocknoteSchema'

function insertOrReplaceBlocks(
  editor: typeof blocknoteSchema.BlockNoteEditor,
  blocks: (typeof blocknoteSchema.PartialBlock)[]
) {
  // Block that the text cursor is currently in.
  const currentBlock = editor.getTextCursorPosition().block

  // Always insert after the current block
  editor.insertBlocks(blocks, currentBlock, 'after')
  const nextBlock = editor.getTextCursorPosition().nextBlock
  nextBlock && editor.setTextCursorPosition(nextBlock)
}

const heading1MenuItem = (editor: typeof blocknoteSchema.BlockNoteEditor) => ({
  title: 'Heading 1',
  onItemClick: () => {
    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'heading',
      props: {
        level: 1
      }
    }
    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={Heading1Icon} />
})

const heading2MenuItem = (editor: typeof blocknoteSchema.BlockNoteEditor) => ({
  title: 'Heading 2',
  onItemClick: () => {
    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'heading',
      props: {
        level: 2
      }
    }

    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={Heading2Icon} />
})

const heading3MenuItem = (editor: typeof blocknoteSchema.BlockNoteEditor) => ({
  title: 'Heading 3',
  onItemClick: () => {
    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'heading',
      props: {
        level: 3
      }
    }

    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={Heading3Icon} />
})

const paragraphMenuItem = (editor: typeof blocknoteSchema.BlockNoteEditor) => ({
  title: 'Paragraph',
  onItemClick: () => {
    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'paragraph'
    }

    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={Bars4Icon} />
})

const bulletListMenuItem = (
  editor: typeof blocknoteSchema.BlockNoteEditor
) => ({
  title: 'Bullet list',
  onItemClick: () => {
    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'bulletListItem'
    }
    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={ListBulletIcon} />
})

const numberListMenuItem = (
  editor: typeof blocknoteSchema.BlockNoteEditor
) => ({
  title: 'Numbered list',
  onItemClick: () => {
    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'numberedListItem'
    }

    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={NumberedListIcon} />
})

const imageMenuItem = (editor: typeof blocknoteSchema.BlockNoteEditor) => ({
  title: 'Image',
  onItemClick: () => {
    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'image'
    }

    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={PhotoIcon} />
})

const phasesSlashMenuItem = (
  editor: typeof blocknoteSchema.BlockNoteEditor,
  addPhaseBlockEnabled: boolean
) => ({
  title: 'Pricing',
  onItemClick: () => {
    if (!addPhaseBlockEnabled) {
      return
    }

    const block: typeof blocknoteSchema.PartialBlock = {
      type: 'phases'
    }

    insertOrReplaceBlocks(editor, [block])
  },
  icon: <Icon as={BanknotesIcon} />
})

export const getCustomSuggestionMenuItems = (
  editor: typeof blocknoteSchema.BlockNoteEditor,
  addPhaseBlockEnabled: boolean
): DefaultReactSuggestionItem[] => [
  heading1MenuItem(editor),
  heading2MenuItem(editor),
  heading3MenuItem(editor),
  paragraphMenuItem(editor),
  bulletListMenuItem(editor),
  numberListMenuItem(editor),
  phasesSlashMenuItem(editor, addPhaseBlockEnabled),
  imageMenuItem(editor)
]
