import { Flex, Spinner } from '@chakra-ui/react'
import { Button, TextFieldInput } from '@sequencehq/core-components'
import { ActiveFilter } from '@sequencehq/tables'
import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { savedViewPath } from 'SavedViews/savedViews.router'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { savedViewsPorts } from 'SavedViews/savedViews.ports'
import { SAVED_VIEWS_STALE_TIME } from 'SavedViews/savedViews.constants'
import { SavedViewEntityType } from 'SavedViews/savedViews.types'

export const SavedViewEditor: FC<{
  activeFilters: ActiveFilter[]
  onDismiss: () => void
  forceNewSavedView?: boolean
  entityType?: SavedViewEntityType
}> = ({
  activeFilters,
  onDismiss,
  forceNewSavedView,
  entityType = 'INVOICES'
}) => {
  const { id: savedViewId } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { displayNotification } = useNotifications()

  const { data } = useQuery(dashboard20240730Client.getSavedViews, undefined, {
    select: data => {
      if (!data) {
        return null
      }

      return savedViewsPorts.savedViews.fromServer(data)
    },
    staleTime: SAVED_VIEWS_STALE_TIME
  })
  const postSavedViewMutator = useMutation(
    dashboard20240730Client.postSavedView
  )
  const putSavedViewMutator = useMutation(dashboard20240730Client.putSavedView)

  const isUpdating =
    postSavedViewMutator.isPending || putSavedViewMutator.isPending

  const savedView = data?.find(savedView => savedView.id === savedViewId)

  const [name, setName] = useState<string>(
    forceNewSavedView ? '' : savedView?.name ?? ''
  )
  const isSaveDisabled = useMemo(
    () => name.length === 0 || isUpdating,
    [name, isUpdating]
  )

  const onSubmit = useCallback(async () => {
    if (!name || isUpdating) {
      return
    }

    if (savedView && !forceNewSavedView) {
      const resPutSavedView = await putSavedViewMutator.mutateAsync({
        id: savedView.id,
        body: savedViewsPorts.savedView.toServer({
          name,
          entityType: savedView.entityType,
          activeFilters: savedView.activeFilters
        })
      })

      if (!resPutSavedView) {
        displayNotification('Failed to update saved view', { type: 'error' })
        return
      }

      displayNotification('Saved view updated', { type: 'success' })
      onDismiss()
      return
    }

    const resPostSavedView = await postSavedViewMutator.mutateAsync({
      body: savedViewsPorts.savedView.toServer({
        name,
        entityType,
        activeFilters
      })
    })

    if (!resPostSavedView) {
      displayNotification('Failed to create saved view', { type: 'error' })
      return
    }

    displayNotification('Saved view created', { type: 'success' })
    navigate(savedViewPath(resPostSavedView.id))
  }, [
    activeFilters,
    name,
    navigate,
    savedView,
    onDismiss,
    forceNewSavedView,
    postSavedViewMutator.mutateAsync,
    putSavedViewMutator.mutateAsync,
    displayNotification,
    isUpdating,
    entityType
  ])

  return (
    <Flex gap="8px" width="100%">
      <TextFieldInput
        value={name}
        onChange={e => setName(e.target.value)}
        autoFocus
        onKeyDown={e => e.key === 'Enter' && onSubmit()}
        maxLength={64}
        disabled={isUpdating}
        placeholder="Name"
      />
      <Button variant="secondary" onClick={onDismiss} disabled={isUpdating}>
        Cancel
      </Button>
      <Button variant="primary" disabled={isSaveDisabled} onClick={onSubmit}>
        {isUpdating ? (
          <Spinner height="16px" width="16px" color={GreyGrey60} />
        ) : (
          'Save'
        )}
      </Button>
    </Flex>
  )
}
