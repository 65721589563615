import { Currency } from '@sequencehq/core-models'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api'

export type ApiMerchantDetails =
  DashboardApi20240730.GetInvoiceMerchantDetails.InvoiceMerchantDetails
type UseLoadMerchantDetails = (props: {
  currency?: Currency
  country?: any
  state?: any
  invoiceMerchantDetails?: ApiMerchantDetails
}) => {
  loading: boolean
  data?: {
    merchantDetails: ApiMerchantDetails | null
  }
}

export const useLoadMerchantDetails: UseLoadMerchantDetails = props => {
  const enabled = Boolean(
    props.currency && props.country && !props.invoiceMerchantDetails
  )
  const invoiceMerchantDetailsQuery = useQuery(
    dashboard20240730Client.getInvoiceMerchantDetails,
    {
      currency: props.currency ?? 'GBP',
      country: props.country,
      state: props.state
    },
    {
      enabled
    }
  )

  return {
    loading: enabled ? invoiceMerchantDetailsQuery.isPending : false,
    error: invoiceMerchantDetailsQuery.isError,
    data: {
      merchantDetails:
        props.invoiceMerchantDetails ?? invoiceMerchantDetailsQuery.data ?? null
    }
  }
}
