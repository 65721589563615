import { Box, Flex, Grid } from '@chakra-ui/react'
import { Skeleton } from '@sequencehq/core-components'
import {
  GreyGrey20,
  GreyGrey40,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  Lato12Regular,
  Lato16Bold,
  ShadowXs
} from '@sequencehq/design-tokens'

import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { Currency, useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import capitalize from 'lodash/fp/capitalize'
import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'
import replace from 'lodash/fp/replace'
import { toMoney } from '@sequencehq/utils'

const TotalsBox = (props: {
  title: string
  total: number
  accountNames: string[]
  currency: Currency
  'data-testid'?: string
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="8px"
      minHeight="140px"
      height="100%"
      padding="32px 0"
      borderRadius="6px"
      border={`1px solid ${GreyGrey40}`}
      shadow={ShadowXs}
      data-testid={props['data-testid']}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="4px"
      >
        <Box {...Lato12Regular} color={GreyGrey60}>
          {props.title}
        </Box>
        <Box {...Lato16Bold} color={GreyGrey80}>
          {toMoney({ value: props.total.toString(), currency: props.currency })}
        </Box>
      </Flex>
      <Flex
        gap="4px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {props.accountNames.map(accountName => (
          <Box
            key={accountName}
            backgroundColor={GreyGrey20}
            {...Lato12Regular}
            fontWeight="600"
            color={GreyGrey70}
            padding="4px 6px"
            borderRadius="6px"
          >
            {accountName}
          </Box>
        ))}
      </Flex>
    </Flex>
  )
}

type UseJournalDetailTotals = (props: {
  journalId: string
}) =>
  | {
      data: {
        entries: Dashboardv99990101Api.GetJournal.GetJournalResponse['entries']
        totals: {
          debit: {
            amount: number
            ledgerAccounts: Array<Dashboardv99990101Api.GetJournal.LedgerAccountName>
          }
          credit: {
            amount: number
            ledgerAccounts: Array<Dashboardv99990101Api.GetJournal.LedgerAccountName>
          }
        }
        currency: Currency
      }
      isLoading: false
    }
  | {
      data: null
      isLoading: true
    }

export const useJournalDetailTotals: UseJournalDetailTotals = (props: {
  journalId: string
}) => {
  const selectedCurrency = useSelectedCurrency()
  const journalQuery = useQuery(
    dashboardv99990101Client.getJournal,
    { id: props.journalId },
    {
      select: journal => {
        if (!journal) {
          return null
        }

        return {
          entries: journal.entries,
          totals: {
            debit: journal.entries.reduce(
              (acc, entry) => {
                if (entry.direction === 'DEBIT') {
                  return {
                    amount: acc.amount + entry.amount,
                    ledgerAccounts: [...acc.ledgerAccounts, entry.account.name]
                  }
                }
                return acc
              },
              { amount: 0, ledgerAccounts: [] } as {
                amount: number
                ledgerAccounts: Array<Dashboardv99990101Api.GetJournal.LedgerAccountName>
              }
            ),
            credit: journal.entries.reduce(
              (acc, entry) => {
                if (entry.direction === 'CREDIT') {
                  return {
                    amount: acc.amount + entry.amount,
                    ledgerAccounts: [...acc.ledgerAccounts, entry.account.name]
                  }
                }
                return acc
              },
              { amount: 0, ledgerAccounts: [] } as {
                amount: number
                ledgerAccounts: Array<Dashboardv99990101Api.GetJournal.LedgerAccountName>
              }
            )
          }
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (journalQuery.error) {
    throw new Error('Journal totals could not be loaded')
  }

  if (journalQuery.isPending || selectedCurrency.isLoading) {
    return {
      isLoading: true,
      data: null
    }
  }

  invariant(journalQuery.data, 'Data should be defined, if loaded')

  return {
    isLoading: false,
    data: {
      ...journalQuery.data,
      currency: selectedCurrency.currency
    }
  }
}

const gridProps = {
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '12px',
  alignItems: 'center'
}

const formatAccountNames = map(compose(replace('_', ' '), capitalize))

export const JournalDetailTotals = (props: {
  journalId: string
}) => {
  const { isLoading, data } = useJournalDetailTotals({
    journalId: props.journalId
  })

  if (isLoading) {
    return (
      <Grid {...gridProps}>
        <Skeleton height="140px" width="100%" />
        <Skeleton height="140px" width="100%" />
      </Grid>
    )
  }

  return (
    <Grid {...gridProps}>
      <TotalsBox
        title="Debit"
        total={data.totals.debit.amount}
        accountNames={formatAccountNames(data.totals.debit.ledgerAccounts)}
        currency={data.currency}
        data-testid="revrec.journalDetail.totals.debit"
      />
      <TotalsBox
        title="Credit"
        total={data.totals.credit.amount}
        accountNames={formatAccountNames(data.totals.credit.ledgerAccounts)}
        currency={data.currency}
        data-testid="revrec.journalDetail.totals.credit"
      />
    </Grid>
  )
}
