import { required } from '@sequencehq/validation'
import { FormFields, useForm } from '@sequencehq/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import deepmerge from 'deepmerge'
import { getEnvironment } from 'lib/environment/environment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api'

export type FeedbackType = 'feedback' | 'issue'
export type IssueSeverity = '1' | '2' | '3'

type ShareFeedbackModel = {
  feedbackType: FeedbackType
  message: string
  issueSeverity: IssueSeverity | null
}

type APIFeedbackFormat = {
  type: 'feedback' | 'issue'
  message: string
  severity: '1' | '2' | '3' | '4'
  meta: Record<string, unknown>
}

const initialModelValue: ShareFeedbackModel = {
  feedbackType: 'feedback',
  message: '',
  issueSeverity: null
}

const modelToApi = (
  model: ShareFeedbackModel,
  meta: Record<string, unknown>
): APIFeedbackFormat => {
  if (model.feedbackType === 'feedback') {
    return {
      type: 'feedback',
      severity: '4',
      message: model.message,
      meta
    }
  }

  if (!model.issueSeverity) {
    throw new Error('Issue severity is required')
  }

  return {
    type: 'issue',
    severity: model.issueSeverity,
    message: model.message,
    meta
  }
}

export const adapters = {
  out: modelToApi
}

type UseShareFeedbackModal = (props: { onClose: () => void }) => {
  queries: {
    isSaving: boolean
    fieldsConfig: {
      feedbackType: FormFields<ShareFeedbackModel>['feedbackType']
      message: FormFields<ShareFeedbackModel>['message']
      issueSeverity: Omit<
        FormFields<ShareFeedbackModel>['issueSeverity'],
        'options'
      > & {
        options: {
          value: IssueSeverity
          label: string
          description: string
        }[]
      }
    }
    feedbackType: ShareFeedbackModel['feedbackType']
  }
  mutators: {
    saveFeedback: () => Promise<void>
    closeModal: () => void
  }
}

export const useShareFeedbackModal: UseShareFeedbackModal = props => {
  const notifications = useNotifications()
  const [isSaving, setIsSaving] = useState(false)
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const selfQuery = useQuery(dashboardv99990101Client.getSelf)

  const self = selfQuery.data

  const metadata = useMemo(() => {
    if (!self) {
      return {
        env: getEnvironment(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }

    return {
      userEmail: self.sequenceUser.email,
      userId: self.sequenceUser.id,
      sequenceAccounts: self.sequenceAccounts,
      env: getEnvironment(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }, [self])

  const form = useForm<ShareFeedbackModel>({
    value: initialModelValue,
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'feedbackType',
        options: [
          {
            value: 'feedback',
            label: 'Submit feedback'
          },
          {
            value: 'issue',
            label: 'Report an issue'
          }
        ],
        validation: [required]
      },
      {
        property: 'message',
        validation: [required]
      },
      {
        property: 'issueSeverity',
        validation: [required],
        disabled: model => model.formData.feedbackType !== 'issue'
      }
    ]
  })

  const saveFeedback = useCallback(async () => {
    if (!form.queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    setIsSaving(true)

    try {
      const result = await fetch(
        'https://shq-slack-shortcut.glitch.me/dashboard-feedback',
        {
          method: 'POST',
          body: JSON.stringify(adapters.out(form.queries.formData, metadata)),
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${
              import.meta.env.VITE_DASHBOARD_FEEDBACK_API_KEY
            }`
          }
        }
      )

      if (!result.ok) {
        throw new Error(result.statusText)
      }

      notifications.displayNotification('Feedback shared', {
        type: 'success',
        description:
          'Your feedback is shared with the Sequence team and they’ll pick this up as soon as possible.'
      })
      props.onClose()
    } catch {
      notifications.displayNotification('Failed to share feedback', {
        type: 'error',
        description:
          'We were unable to share your feedback. Please try again later.'
      })
      return
    } finally {
      setIsSaving(false)
    }
  }, [notifications, form.queries.formData, props, form.queries.isValid])

  const closeModal = useCallback(() => {
    props.onClose()
  }, [props])

  const fieldsConfig = useMemo(() => {
    return deepmerge(form.fields, {
      issueSeverity: {
        options: [
          {
            value: '3' as IssueSeverity,
            label: 'Minor',
            description:
              'A small issue that doesn’t require immediate attention'
          },
          {
            value: '2' as IssueSeverity,
            label: 'Medium',
            description: 'An annoying issue, but I can continue'
          },
          {
            value: '1' as IssueSeverity,
            label: 'Urgent',
            description: 'This is preventing me from using Sequence'
          }
        ]
      }
    })
  }, [form.fields])

  useEffect(() => {
    setShowValidationErrors(false)
  }, [form.queries.formData.feedbackType])

  return {
    queries: {
      isSaving,
      fieldsConfig,
      feedbackType: form.queries.formData.feedbackType
    },
    mutators: {
      saveFeedback,
      closeModal
    }
  }
}
