import { Link } from 'react-router-dom'

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'

import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey80,
  GreyWhite,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { QuoteTemplateEditorKebabMenu } from './QuoteTemplateEditorKebabMenu'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { formatQuoteTitle } from 'Cube/utils/formatQuoteTitle'

const styles = {
  title: {
    ...Lato14Bold,
    color: GreyGrey80
  },
  archivedTitle: {
    ...Lato14Bold,
    color: GreyGrey60
  }
}

const useQuoteTemplateEditorHeader = () => {
  const cubeContext = useCubeContext()

  return {
    isArchived: false,
    title: formatQuoteTitle({
      domainTitle: cubeContext.queries.rawData.data.common.title,
      isArchived: cubeContext.queries.rawData.data.common.isArchived
    }),
    save: {
      visible:
        cubeContext.queries.availableFeatures.quote.saveTemplate.available
          .visible,
      disabled:
        !cubeContext.queries.availableFeatures.quote.saveTemplate.available
          .enabled,
      onClick: () => cubeContext.mutators.external.out.save()
    }
  }
}

export const QuoteTemplateEditorHeader = () => {
  const headerHook = useQuoteTemplateEditorHeader()

  return (
    <Grid
      data-testid="quoteTemplate.editor.header"
      gridTemplateColumns="1fr auto 1fr"
      gridTemplateAreas={'"status title options"'}
      width="100%"
      height="48px"
      alignItems="center"
      borderBottom={`1px solid ${GreyGrey30}`}
      backgroundColor={GreyWhite}
      padding="0 16px"
      position="relative"
    >
      <Box gridArea="status"></Box>
      <Flex gridArea="title" gap="4px" alignItems="center">
        <Text {...Lato14Regular} color={GreyGrey60}>
          <Text as={Link} color="inherit" to="/quotes">
            Quote Templates
          </Text>{' '}
          /{' '}
          <Text
            as="span"
            style={headerHook.isArchived ? styles.archivedTitle : styles.title}
          >
            {headerHook.title}
          </Text>
        </Text>
      </Flex>

      <Flex gap="8px" gridArea="options" justifyContent="flex-end">
        {headerHook.save.visible && (
          <Button
            variant="component-library-primary"
            data-testid="quoteTemplate.editor.header.save"
            onClick={headerHook.save.onClick}
            isDisabled={headerHook.save.disabled}
          >
            Save template
          </Button>
        )}

        <QuoteTemplateEditorKebabMenu />
      </Flex>
    </Grid>
  )
}
