import {
  Button as ChakraButton,
  forwardRef,
  IconButton
} from '@chakra-ui/react'
import { FC } from 'react'

type PropsWithChildren = {
  children: React.ReactNode
  leadingIcon?: React.ReactElement
  trailingIcon?: React.ReactElement
}
type PropsWithIcon = { icon: React.ReactElement; 'aria-label': string }

interface BaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'ghost'
    | 'destructive'
    | 'unstyled'
    | 'ai'
  onClick?: () => void
  disabled?: boolean
  active?: boolean
  size?: string
  'data-testid'?: string
  fullWidth?: boolean
}

type Props = BaseProps & (PropsWithChildren | PropsWithIcon)

export const Button: FC<Props> = forwardRef<Props, 'button'>((props, ref) => {
  const {
    children,
    variant,
    disabled,
    active,
    size,
    fullWidth,
    ...otherProps
  } = props

  const sharedProps = {
    disabled: false,
    active: false,
    type: 'button' as BaseProps['type'],
    isDisabled: disabled,
    isActive: active,
    width: fullWidth ? '100%' : size,
    height: size,
    variant: `component-library-${
      variant === 'destructive' ? 'warning' : variant ?? 'primary'
    }`,
    ref,
    ...otherProps
  }

  if (isIconButtonProps(props)) {
    return (
      <IconButton
        {...sharedProps}
        aria-label={props['aria-label']}
        width={size ?? '32px'}
        height={size ?? '32px'}
      />
    )
  }

  return (
    <ChakraButton
      {...sharedProps}
      leftIcon={props.leadingIcon}
      rightIcon={props.trailingIcon}
    >
      {children}
    </ChakraButton>
  )
})

const isIconButtonProps = (props: Props): props is PropsWithIcon =>
  'icon' in props
