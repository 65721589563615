import { apiQueryClient } from 'features/api/apiQueryClient'
import {
  fetchBillingScheduleAggregate,
  getBillingScheduleAggregateKey
} from './utils/useBillingScheduleAggregate'
import { LoaderFunction } from 'react-router-dom'

const requestWithTimeout =
  (timeout: number) =>
  (request: Promise<unknown>): Promise<unknown> =>
    Promise.race([request, new Promise(res => setTimeout(res, timeout))])

export const prefetchBillingScheduleHomeData = async (
  billingScheduleId: string
) => {
  await apiQueryClient.prefetchQuery({
    queryKey: getBillingScheduleAggregateKey(billingScheduleId),
    queryFn: () => fetchBillingScheduleAggregate(billingScheduleId),
    staleTime: 60000
  })
}

export const billingScheduleHomeLoader = async ({
  params
}: Parameters<LoaderFunction>[0]) => {
  const billingScheduleId = params.id

  if (!billingScheduleId) {
    return true
  }

  await requestWithTimeout(500)(
    prefetchBillingScheduleHomeData(billingScheduleId)
  )

  return true
}
