import { Grid, GridItem } from '@chakra-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  Lato13Bold,
  YellowYellow50
} from '@sequencehq/design-tokens'
import { createElement } from 'react'
import { NavTooltip } from 'components/NavigationV3/components/NavTooltip'
import { NAVIGATION_ANIMATION } from 'components/NavigationV3/constants/animation'

interface IntegrationsNavLinkProps {
  onClick?: () => void
  active?: boolean
  logo: React.ComponentType<{ height: string; width: string }>
  label: string
  status?: 'active' | 'warning'
  isCollapsed?: boolean
}

/**
 * TODO: Reconcile this NavLink with the SubNavLink used in NavigationsV3,
 * and the link in the integrations browser modal.
 * @param props
 * @returns
 */
export const IntegrationsNavLink = (props: IntegrationsNavLinkProps) => {
  const { onClick, active, logo, label, status, isCollapsed = false } = props

  // Create the logo element once to avoid re-renders
  const logoElement = createElement(logo, {
    height: '16px',
    width: '16px'
  })

  const navLink = (
    <Grid
      padding="0 8px"
      templateColumns={isCollapsed ? 'auto' : 'auto 1fr auto'}
      onClick={onClick}
      height="32px"
      gap="8px"
      borderRadius="6px"
      alignContent="center"
      alignItems="center"
      justifyContent={'flex-start'}
      cursor="pointer"
      color={active ? GreyGrey90 : GreyGrey70}
      backgroundColor={active ? GreyGrey20 : 'transparent'}
      style={{
        willChange: 'width, transform',
        transform: 'translateZ(0)',
        transition: 'background-color 0.2s ease'
      }}
      _hover={{
        backgroundColor: GreyGrey20
      }}
    >
      <GridItem
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="16px"
        height="16px"
        position="relative"
        data-testid={`navlink-logo-${label.replace(/\s+/g, '-').toLowerCase()}`}
        alignSelf="center"
        style={{
          willChange: 'transform',
          transform: 'translateZ(0)',
          transition: 'transform 0.3s ease'
        }}
      >
        {logoElement}
      </GridItem>
      {!isCollapsed && (
        <>
          <GridItem
            {...Lato13Bold}
            color={active ? GreyGrey90 : GreyGrey70}
            data-testid={`navlink.${label}`}
            className="nav-text"
          >
            {label}
          </GridItem>
          {status === 'warning' && (
            <GridItem className="nav-text">
              <ExclamationTriangleIcon
                data-testid="navlink-warning"
                height="16px"
                width="16px"
                style={{
                  position: 'relative',
                  top: '1px'
                }}
                color={YellowYellow50}
              />
            </GridItem>
          )}
        </>
      )}
    </Grid>
  )

  // Wrap the entire navLink in a tooltip when collapsed, consistent with other navigation components
  if (isCollapsed) {
    return (
      <NavTooltip label={label} isDisabled={!isCollapsed}>
        {navLink}
      </NavTooltip>
    )
  }

  return navLink
}
