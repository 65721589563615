import { StandardLayout } from '@sequencehq/core-components'
import { DeferredRevenueHeader } from './DeferredRevenueHeader'
import { DeferredRevenueContent } from './content/DeferredRevenueContent'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { DeferredRevenueDashboard } from '../deferredRevenueDashboard/DeferredRevenueDashboard.root'

export const DeferredRevenue = () => {
  const flags = useFlags()

  if (flags?.showRevRecV2) {
    return <DeferredRevenueDashboard />
  }

  return (
    <StandardLayout
      header={<DeferredRevenueHeader />}
      content={<DeferredRevenueContent />}
    />
  )
}
