import { pdf } from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'
import { CreditNoteModel } from '@sequencehq/core-models'
import { ReactPdfCreditNote } from '@sequencehq/invoice-content'
import { downloadBlob, registerReactPdfFonts } from '@sequencehq/utils'
import { isProductionEnv } from 'lib/environment/environment'
import exhaustiveGuard from 'lib/exhaustiveGuard/exhaustiveGuard'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'
import { useFetchDataForLocalPdfCreditNote } from './useFetchDataForLocalPdfCreditNote'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type DownloadCreditNotePdfProps = {
  creditNoteId: CreditNoteModel['id']
  creditNoteNumber: CreditNoteModel['creditNoteNumber']
  creditNoteStatus: CreditNoteModel['status']
}

type UseCreditNotePdfDownload = () => {
  downloadPdf: (props: DownloadCreditNotePdfProps) => void
}

registerReactPdfFonts()

export const useCreditNotePdfDownload: UseCreditNotePdfDownload = () => {
  const notifications = useNotifications()

  const { fetchData } = useFetchDataForLocalPdfCreditNote()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-pdf',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-pdf-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('PDF download failed', {
      id: 'download-pdf-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  /**
   * Main functions
   */
  const localGeneratePdf = useCallback(
    async ({
      creditNoteId,
      creditNoteNumber
    }: {
      creditNoteId: CreditNoteModel['id']
      creditNoteNumber: CreditNoteModel['creditNoteNumber']
    }) => {
      try {
        const pdfCreditNoteData = await fetchData({ creditNoteId })

        const blob = await pdf(
          <ReactPdfCreditNote
            creditNote={pdfCreditNoteData.creditNote}
            lineItems={pdfCreditNoteData.lineItems}
            lineItemGroups={pdfCreditNoteData.lineItemGroups}
            merchantDetails={pdfCreditNoteData.merchantDetails}
            showNonProdWatermark={!isProductionEnv()}
            isCustomerPortalEnabled={
              pdfCreditNoteData.merchant.customerPortalEnabled
            }
            isRenderedInBrowser={true}
          />
        ).toBlob()

        downloadBlob(
          creditNoteNumber ? `${creditNoteNumber}.pdf` : 'Credit Note.pdf'
        )(blob)

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [fetchData, showToastError, showToastSuccess]
  )

  const remoteGeneratePdf = useCallback(
    async ({
      creditNoteId,
      creditNoteNumber
    }: {
      creditNoteId: CreditNoteModel['id']
      creditNoteNumber: CreditNoteModel['creditNoteNumber']
    }) => {
      try {
        const creditNotePdfRes = await dashboard20240730Client.getCreditNotePdf(
          {
            id: creditNoteId
          }
        )

        if (!creditNotePdfRes.data) {
          throw new Error('An error occurred while downloading the credit note')
        }

        downloadBlob(
          creditNoteNumber ? `${creditNoteNumber}.pdf` : 'Credit Note.pdf'
        )(creditNotePdfRes.data)

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [showToastError, showToastSuccess]
  )

  const downloadPdf = useCallback(
    ({
      creditNoteId,
      creditNoteNumber,
      creditNoteStatus
    }: DownloadCreditNotePdfProps) => {
      showToastPreparing()

      switch (creditNoteStatus) {
        case 'IN_PROGRESS':
        case 'DRAFT':
          void localGeneratePdf({ creditNoteId, creditNoteNumber })
          break
        case 'FINAL':
        case 'SENT':
        case 'VOIDED':
          void remoteGeneratePdf({ creditNoteId, creditNoteNumber })
          break
        default:
          return exhaustiveGuard(creditNoteStatus)
      }
    },
    [localGeneratePdf, remoteGeneratePdf, showToastPreparing]
  )

  return { downloadPdf }
}
