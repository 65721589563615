import { useState, useEffect, useCallback } from 'react'

/**
 * A custom hook to manage transition states with automatic cleanup
 * @param duration - The duration of the transition in milliseconds
 * @returns An object with isTransitioning state and startTransition function
 */
export function useTransitionState(duration = 300) {
  const [isTransitioning, setIsTransitioning] = useState(false)

  // Automatically reset transition state after duration
  useEffect(() => {
    if (!isTransitioning) return

    const timer = setTimeout(() => {
      setIsTransitioning(false)
    }, duration)

    return () => clearTimeout(timer)
  }, [isTransitioning, duration])

  // Function to start a transition
  const startTransition = useCallback(() => {
    setIsTransitioning(true)
  }, [])

  return {
    isTransitioning,
    startTransition
  }
}
