import { Avatar, Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import { Currency, CustomerModel } from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import { CurrencyInputWrapper } from 'components/CurrencyInputWrapper/CurrencyInputWrapper'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { ModalForm } from 'components/Modal'
import { Toast } from '@sequencehq/core-components'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { handleFormResponseV2 } from 'lib/formValidation'
import { FC, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { Label } from '@sequencehq/forms'
import { useDefaultCurrency } from 'components/CurrencySettings/useCurrencies'
import { PaymentCollection } from 'components/Invoices/modals/CreateInvoice/PaymentCollection'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications'

type InvoiceFormValues = {
  currency: Currency
  customerId: string
  stripePayments: boolean
}

interface CreateInvoiceFormProps {
  customer: CustomerModel | undefined
  variant?: 'v2'
}

const CreateInvoiceForm: FC<CreateInvoiceFormProps> = ({
  customer,
  variant
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { displayNotification } = useNotifications()
  const toast = useToast()
  const defaultCurrency = useDefaultCurrency()
  const [stripeIntegration, setStripeIntegration] =
    useState<DashboardApi20240730.GetIntegration.Integration | null>(null)

  useEffect(() => {
    const fetchIntegration = async () => {
      const stripeIntegrationResponse =
        await dashboard20240730Client.getIntegration('Stripe')
      if (!stripeIntegrationResponse.error) {
        setStripeIntegration(stripeIntegrationResponse.data)
      }
    }
    void fetchIntegration()
  }, [])

  const handleSuccess = (invoiceId: string) => {
    toast({
      id: 'newInvoice',
      position: 'bottom',
      isClosable: true,
      render: () => (
        <Toast
          type="success"
          title={`New draft invoice added`}
          isClosable={true}
        ></Toast>
      )
    })

    navigate(`/invoices/${invoiceId}`)
    dispatch(closeOverlay())
  }

  if (!defaultCurrency) {
    return <Spinner />
  }

  return (
    <Form<InvoiceFormValues>
      keepDirtyOnReinitialize
      initialValues={{
        currency: defaultCurrency,
        customerId: customer?.id,
        stripePayments: false
      }}
      onSubmit={async (values, form) => {
        const { stripePayments } = values

        const createInvoiceRes = await dashboard20240730Client.postInvoice({
          currency: values.currency,
          customerId: values.customerId,
          customerBillingAddress: undefined,
          customerEmails: undefined,
          customerLegalCompanyName: undefined,
          customerShippingAddress: undefined,
          dueDate: undefined,
          memo: undefined,
          paymentOptions: ['BANK_TRANSFER'],
          purchaseOrderNumber: undefined
        })

        if (createInvoiceRes.error) {
          displayNotification('Could not create invoice', {
            type: 'error'
          })
          return handleFormResponseV2(
            createInvoiceRes,
            form.getRegisteredFields()
          )
        }

        const invoiceId = createInvoiceRes.data?.id

        if (!stripePayments && invoiceId) {
          handleSuccess(invoiceId)
        }

        if (stripePayments && invoiceId) {
          const createInvoiceSettingsRes =
            await dashboard20240730Client.postInvoiceSettings({
              invoiceId: invoiceId,
              paymentProvider: 'STRIPE'
            })
          if (createInvoiceSettingsRes.error) {
            return handleFormResponseV2(
              createInvoiceSettingsRes,
              form.getRegisteredFields()
            )
          }

          handleSuccess(invoiceId)
        }
      }}
      render={formProps => (
        <ModalForm
          variant={variant}
          {...formProps}
          title="Create draft invoice"
          submitLabel="Save draft invoice"
        >
          <CurrencyInputWrapper width="100%" />
          <Box height={5} />
          {customer ? (
            <>
              <Label>Customer</Label>
              <Box height={2} />
              <Flex flexDirection="row" alignItems="center">
                <Avatar name={customer.legalName} mr={2} size="sm" />
                <Text>{customer.legalName}</Text>
              </Flex>
            </>
          ) : (
            <CustomerComboInputComplete
              fieldName="customerId"
              addNewAction={() => navigate('/invoices/customers/new')}
              validate={required}
            />
          )}
          {stripeIntegration?.enabled && (
            <>
              <Box height={5} />
              <PaymentCollection
                oneOff={true}
                labelText="Add a payment link to a Stripe-hosted checkout page."
              />
            </>
          )}
        </ModalForm>
      )}
    />
  )
}

export default CreateInvoiceForm
