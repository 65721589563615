import { Flex, Grid, Skeleton, Text } from '@chakra-ui/react'
import { Badge } from '@sequencehq/core-components'
import {
  PriceModel,
  PricingStructureModel,
  toBillingFrequencyLabel,
  toBillingType,
  toMoney
} from '@sequencehq/core-models'
import {
  GreyGrey100,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import capitalize from 'lodash/capitalize'
import kebabCase from 'lodash/kebabCase'
import ClipboardIcon from '@heroicons/react/16/solid/ClipboardIcon'
import { Link } from 'react-router-dom'
import { pricingTypeLabel } from 'modules/Products/formatters'
import {
  enforceMinimumPrecision,
  priceModelAdapter,
  toPriceSummary
} from '@sequencehq/utils'
import { ListPrice, Product } from 'modules/Products/types'
import usageCalculationPeriodMapper from 'common/drawers/PricingEditor/utils/usageCalculationPeriodMapper.ts'
import { usageCalculationPeriodLabels } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants.ts'
import compose from 'lodash/fp/compose'
import replace from 'lodash/fp/replace'
import { useQuery } from '@sequencehq/api/dist/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { NEW_PRICE_PATTERN } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useSeatMetrics } from 'modules/Seats/SeatMetric/useSeatMetrics'
import { useNotifications } from 'lib/hooks/useNotifications'

const PRICING_TYPES_WITH_PERCENTAGE = ['LINEAR', 'GRADUATED', 'VOLUME']

type SeatBasedProrationStrategy =
  | 'USE_MAXIMUM'
  | 'PRORATE_INCREMENTS'
  | 'PRORATE_ALL_CHANGES'
  | 'USE_FIRST'

// Human-readable labels for seat proration strategies
const seatProrationLabels: Record<SeatBasedProrationStrategy, string> = {
  USE_MAXIMUM: 'No proration (charge full amount)',
  PRORATE_INCREMENTS: 'Prorate added seats only',
  PRORATE_ALL_CHANGES: 'Prorate added and removed seats',
  USE_FIRST: 'Use first' // Fallback mapping
}
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

const deriveIsPricePercentage = (
  structure?: PricingStructureModel | ListPrice['structure']
): boolean => {
  if (!structure) {
    return false
  }

  if (structure.pricingType === 'VOLUME') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'GRADUATED') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'LINEAR') {
    return structure.isPricePercentage
  }

  return false
}

const isSeatBasedGraduated = (
  structure?: PricingStructureModel | ListPrice['structure']
): boolean => {
  if (!structure || structure.pricingType !== 'SEAT_BASED') {
    return false
  }

  return !!structure.tiers && structure.tiers.length > 0
}

export const ProductPriceMeta = ({
  price,
  product
}: {
  price: PriceModel | ListPrice
  product?: Product
}) => {
  const { displayNotification } = useNotifications()

  const { data: taxCategoriesData } = useQuery(
    dashboardv99990101Client.getTaxCategories
  )

  const taxCategoryForProduct = taxCategoriesData?.items?.find(
    taxCategory => taxCategory.id === product?.taxCategoryId
  )

  const handleCopy = () => {
    void navigator.clipboard.writeText(price.id)
    displayNotification('Price ID copied', {
      type: 'success',
      isClosable: true,
      duration: 2000
    })
  }

  const transformedPrice =
    'integrationIds' in price ? price : priceModelAdapter.out(price)

  return (
    <Flex flexDirection="column">
      <Text {...Lato14Bold} color={GreyGrey100}>
        Details
      </Text>
      <Grid
        templateColumns={`${product?.revenueRecognitionMethod ? '176px' : '160px'} 1fr`}
        gap={3}
        mt={4}
        color={GreyGrey70}
      >
        {product && (
          <>
            <Text {...Lato13Regular}>Product name</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {product.name}
            </Text>

            {product.label && (
              <>
                <Text {...Lato13Regular}>Product description</Text>
                <Text {...Lato13Regular} color={GreyGrey90}>
                  {product.label}
                </Text>
              </>
            )}

            {product.revenueRecognitionMethod && (
              <>
                <Text {...Lato13Regular}>Revenue recognition method</Text>
                <Text
                  {...Lato13Regular}
                  color={GreyGrey90}
                  data-testid="pricingEditor.review.revenueRecognitionMethod"
                >
                  {compose(
                    replace('_', ' '),
                    capitalize
                  )(product.revenueRecognitionMethod)}
                </Text>
              </>
            )}

            {taxCategoryForProduct && (
              <>
                <Text {...Lato13Regular}>Tax category</Text>
                <Text
                  {...Lato13Regular}
                  color={GreyGrey90}
                  data-testid="pricingEditor.review.taxCategoryName"
                >
                  {taxCategoryForProduct.name}
                </Text>
              </>
            )}
          </>
        )}
        <Text {...Lato13Regular}>Pricing model</Text>
        <Text {...Lato13Regular} color={GreyGrey90}>
          {price.structure.pricingType === 'SEAT_BASED'
            ? `Seat-based → ${isSeatBasedGraduated(price.structure) ? 'Graduated' : 'Linear'} pricing`
            : capitalize(
                kebabCase(pricingTypeLabel(price.structure.pricingType))
              ) + ' pricing'}
        </Text>

        <Text {...Lato13Regular}>Billing frequency</Text>
        <Text {...Lato13Regular} color={GreyGrey90}>
          {toBillingFrequencyLabel(price.billingFrequency)} /{' '}
          {toBillingType(price.billingType)}
        </Text>

        {price.structure.pricingType !== 'ONE_TIME' &&
          price.structure.pricingType !== 'FIXED' &&
          price.structure.pricingType !== 'SEAT_BASED' && (
            <PriceUsageMetric usageMetricId={price.structure.usageMetricId} />
          )}

        {price.structure.pricingType === 'SEAT_BASED' && (
          <PriceSeatMetric
            seatMetricId={
              'seatMetricId' in price.structure
                ? price.structure.seatMetricId
                : price.structure.seatTypeId
            }
          />
        )}

        {PRICING_TYPES_WITH_PERCENTAGE.includes(
          price.structure.pricingType
        ) && (
          <>
            <Text {...Lato13Regular}>Type</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {deriveIsPricePercentage(price.structure)
                ? 'Percentage'
                : 'Fixed'}
            </Text>
          </>
        )}

        {/* Seat-based pricing additional fields */}
        {price.structure.pricingType === 'SEAT_BASED' && (
          <>
            <Text {...Lato13Regular}>Minimum seats</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {'contractedMinimumSeats' in price.structure
                ? price.structure.contractedMinimumSeats
                : price.structure.includedSeats}
            </Text>

            <Text {...Lato13Regular}>Seat proration</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {price.structure.prorationStrategy in seatProrationLabels
                ? seatProrationLabels[
                    price.structure
                      .prorationStrategy as SeatBasedProrationStrategy
                  ]
                : capitalize(kebabCase(price.structure.prorationStrategy))}
            </Text>

            {'prorateFlatFees' in price.structure && (
              <>
                <Text {...Lato13Regular}>Flat fee proration</Text>
                <Text {...Lato13Regular} color={GreyGrey90}>
                  {price.structure.prorateFlatFees ? 'Enabled' : 'Disabled'}
                </Text>
              </>
            )}

            {'overagesBillingFrequency' in price.structure &&
              price.structure.overagesBillingFrequency && (
                <>
                  <Text {...Lato13Regular}>Overage frequency</Text>
                  <Text {...Lato13Regular} color={GreyGrey90}>
                    {toBillingFrequencyLabel(
                      price.structure.overagesBillingFrequency
                    )}
                  </Text>
                </>
              )}
          </>
        )}

        <GraduatedPricingFields price={price} />

        {price.structure.pricingType === 'LINEAR' ? (
          <LinearPricingFields price={price} />
        ) : price.structure.pricingType === 'PACKAGE' ? (
          <PackagePricingFields price={price} />
        ) : price.structure.pricingType === 'SEAT_BASED' &&
          !isSeatBasedGraduated(price.structure) ? (
          <>
            <Text {...Lato13Regular}>Price per seat</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {toMoney({
                currency: price.currency,
                value: price.structure.pricePerSeat
              })}
            </Text>
          </>
        ) : (
          <>
            <Text {...Lato13Regular}>Price</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {toPriceSummary(transformedPrice)}
            </Text>
          </>
        )}

        {!price.id.match(NEW_PRICE_PATTERN) && (
          <>
            <Text {...Lato13Regular}>Price ID</Text>
            <Flex gap={2}>
              <Badge
                sentiment="neutral"
                containerStyle={{
                  height: '16px',
                  padding: 1
                }}
              >
                {price.id}
              </Badge>
              <ClipboardIcon
                width="14px"
                color="gray.60"
                onClick={handleCopy}
                cursor="pointer"
              />
            </Flex>
          </>
        )}
      </Grid>
    </Flex>
  )
}

const PriceSeatMetric = ({ seatMetricId }: { seatMetricId: string }) => {
  const { metrics, loading } = useSeatMetrics()

  const seatMetric = useMemo(() => {
    if (!metrics) return null
    return metrics.find(metric => metric.id === seatMetricId)
  }, [metrics, seatMetricId])

  if (loading) {
    return (
      <>
        <Text {...Lato13Regular}>Seat metric</Text>
        <Skeleton w="100px" />
      </>
    )
  }

  if (!seatMetric) {
    return (
      <>
        <Text {...Lato13Regular}>Seat metric</Text>
        <Text {...Lato13Regular} color={GreyGrey90}>
          Not found
        </Text>
      </>
    )
  }

  // Link to the general seat metrics page
  const linkToSeatMetricsPage = `/seats`

  return (
    <>
      <Text {...Lato13Regular}>Seat metric</Text>
      <Link to={linkToSeatMetricsPage}>
        <Text {...Lato13Bold} color={IndigoIndigo50}>
          {seatMetric.label}
        </Text>
      </Link>
    </>
  )
}

const PriceUsageMetric = ({ usageMetricId }: { usageMetricId: string }) => {
  const usageMetricQuery = useQuery(dashboard20240730Client.getUsageMetric, {
    id: usageMetricId
  })

  const usageMetricValue = usageMetricQuery.data

  if (usageMetricQuery.isPending || !usageMetricValue) {
    return (
      <>
        <Text {...Lato13Regular}>Usage metric</Text>
        <Skeleton w="100px" />
      </>
    )
  }

  const linkToUsageMetric = `/usage-metrics/${usageMetricValue.id}`

  return (
    <>
      <Text {...Lato13Regular}>Usage metric</Text>
      <Link to={linkToUsageMetric}>
        <Text {...Lato13Bold} color={IndigoIndigo50}>
          {usageMetricValue.name}
        </Text>
      </Link>
    </>
  )
}

const LinearPricingFields = ({ price }: { price: PriceModel | ListPrice }) => {
  const { structure, currency } = price

  if (structure.pricingType !== 'LINEAR') {
    return null
  }

  return (
    <>
      {structure.isPricePercentage ? (
        <>
          <Text {...Lato13Regular}>Percentage</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {enforceMinimumPrecision(2)(structure.pricePerUnit)}%
          </Text>
        </>
      ) : (
        <>
          <Text {...Lato13Regular}>Price per unit</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.pricePerUnit })}
          </Text>
        </>
      )}
      {structure.minPrice && (
        <>
          <Text {...Lato13Regular}>Min. Price</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.minPrice })}
          </Text>
        </>
      )}
      {structure.maxPrice && (
        <>
          <Text {...Lato13Regular}>Max. Price</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.maxPrice })}
          </Text>
        </>
      )}
    </>
  )
}

const PackagePricingFields = ({ price }: { price: PriceModel | ListPrice }) => {
  const { structure, currency } = price

  if (structure.pricingType !== 'PACKAGE') {
    return null
  }

  return (
    <>
      {structure.pricePerPackage && (
        <>
          <Text {...Lato13Regular}>Price per package</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.pricePerPackage })}
          </Text>
        </>
      )}
      {structure.packageSize && (
        <>
          <Text {...Lato13Regular}>Package size</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {structure.packageSize}
          </Text>
        </>
      )}
    </>
  )
}

const GraduatedPricingFields = ({
  price
}: {
  price: ListPrice | PriceModel
}) => {
  const { structure } = price

  if (structure.pricingType !== 'GRADUATED') {
    return null
  }

  const usageCalculationPeriodValue =
    usageCalculationPeriodMapper.fromApiPricePropertiesToUsageCalculationPeriodDropdown(
      {
        usageCalculationPeriod:
          'usageCalculationPeriod' in price
            ? price.usageCalculationPeriod
            : undefined,
        usageCalculationMode: structure.usageCalculationMode
      },
      price.billingFrequency
    )

  return (
    <>
      <Text {...Lato13Regular}>Usage calculation period</Text>
      <Text {...Lato13Regular} color={GreyGrey90}>
        {usageCalculationPeriodLabels[usageCalculationPeriodValue]}
      </Text>
    </>
  )
}
