import { useAtom } from 'jotai'
import {
  ExtractionData,
  loadableExtractionResultAtom
} from 'ContractExtraction/atoms'

type UseContractExtractionResult = () => ExtractionData | null

export const useContractExtractionResult: UseContractExtractionResult = () => {
  const [extractionResult] = useAtom(loadableExtractionResultAtom)

  if (extractionResult.state !== 'hasData' || !extractionResult.data) {
    return null
  }

  return extractionResult.data
}
