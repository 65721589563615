import { Box, Button, Circle, Flex, Text } from '@chakra-ui/react'
import { ApiKeysView } from 'components/ApiKeysSettings/types'
import SettingsTab from 'components/SettingsPage/SettingsTab'
import {
  ColumnConfig,
  VirtualTableConfig,
  VirtualTable,
  adaptApiToVirtualTable
} from '@sequencehq/tables'
import { openOverlay } from 'features/overlay'
import { AppDispatch, useDispatch, useSelector } from 'features/store'
import { flow } from 'fp-ts/lib/function'
import { FC, memo } from 'react'
import { Key } from 'react-feather'
import { INITIAL_RESULT_SIZE } from 'lib/constants'
import { CopyButton } from '@sequencehq/core-components'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

const copyableCell = (value: string) => {
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        {value}
        <CopyButton value={value} />
      </Flex>
    </>
  )
}

type ApiOAuth = DashboardApi20240730.GetOAuthClients.OAuthClientList['items'][0]
const tableConfig = (
  dispatch: AppDispatch
): VirtualTableConfig<ApiKeysView> => {
  const columns: ColumnConfig<ApiOAuth>[] = [
    {
      heading: 'Name',
      formatter: flow(({ name }) => (
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Circle backgroundColor="#F0F4F7" size="24px">
            <Key color="#667085" width="14px" height="14px"></Key>
          </Circle>
          <Flex minWidth="8px"></Flex>
          <Flex flexDirection="column">
            <Text textOverflow="ellipsis">{name}</Text>
          </Flex>
        </Flex>
      )),
      width: '240px',
      minWidth: '280px'
    },
    {
      heading: 'Client ID',
      formatter: flow(({ id }) => id, copyableCell),
      width: '334px'
    },
    {
      heading: 'Pinned API version',
      formatter: flow(({ pinnedApiVersion }) => pinnedApiVersion),
      width: '150px'
    },
    {
      formatter: flow(({ id, name }) => (
        <Button
          variant="warning"
          size="sm"
          onClick={() =>
            dispatch(
              openOverlay({
                content: 'deleteApiKeyModal',
                data: { id, name }
              })
            )
          }
        >
          Delete
        </Button>
      )),
      width: '125px'
    }
  ]

  return {
    columns
  }
}

const ApiKeysSettings: FC = memo(() => {
  const dispatch = useDispatch()

  return (
    <SettingsTab
      title="Manage API Keys"
      summarySection={
        <Flex flexDirection="row">
          <Circle backgroundColor="#F0F4F7" size="37px">
            <Key color="#667085"></Key>
          </Circle>
          <Box width="8px"></Box>
          <Flex flexDirection="column">
            <Box height="12px"></Box>
            <Text>Easily create and manage API keys</Text>
          </Flex>
          <Flex grow="1"></Flex>
          <Button
            variant="primary"
            onClick={() =>
              dispatch(
                openOverlay({
                  content: 'createApiKeyModal'
                })
              )
            }
          >
            New API Key
          </Button>
        </Flex>
      }
    >
      <Box height="40px"></Box>
      <Flex px="200px" data-testid="apiKeys.table">
        <VirtualTable
          useLazyQuery={() =>
            //@ts-expect-error - adapter hackery for a deprecated component
            adaptApiToVirtualTable(dashboard20240730Client.getOAuthClients)
          }
          tableConfig={tableConfig(dispatch)}
          initialResultSize={INITIAL_RESULT_SIZE}
          width="100%"
        />
      </Flex>
    </SettingsTab>
  )
})

export default ApiKeysSettings
