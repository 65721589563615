import { Box } from '@chakra-ui/react'
import { GreyGrey30, GreyWhite } from '@sequencehq/design-tokens'
import { useState } from 'react'

export const ScheduleTimeline = () => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Box
      borderTop={`1px solid ${GreyGrey30}`}
      backgroundColor={GreyWhite}
      padding="16px"
      cursor="pointer"
      height={expanded ? '378px' : '48px'}
      transition={'height ease-out 0.25s'}
      onClick={() => setExpanded(!expanded)}
    >
      I'm the schedule timeline!
    </Box>
  )
}
