import { Box, Flex } from '@chakra-ui/react'
import { GreyGrey10 } from '@sequencehq/design-tokens'
import {
  DateDurationConfig,
  DateDurationField,
  DatePickerField,
  DayOfMonthField,
  defaultDurationOptions
} from '@sequencehq/core-components'
import { FC, useMemo } from 'react'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { DisabledReasonType, Duration } from 'Cube/domain/cube.domain.types'
import { quoteStatusAdapter } from 'Cube/utils/status.adapter'
import { usePhaseDetails } from './usePhaseDetails'
import isEqual from 'lodash/fp/isEqual'
import { CompleteMilestonePhase } from './CompleteMilestonePhase/CompleteMilestonePhase'
import { PhaseRecurrenceSwitch } from './PhaseRecurrenceSwitch'
import { ExtractedDataIndicator } from 'ContractExtraction/index'

const usePhaseDurationSelection = (props: {
  phaseId: string
}) => {
  const cubeContext = useCubeContext()
  const phaseDetails = usePhaseDetails({ phaseId: props.phaseId })

  /**
   * Fields configuration
   */

  const phaseDurationValue = useMemo(() => {
    if (phaseDetails.resolvedPhase.dates.duration === 'OPEN_ENDED') {
      return undefined
    }

    if (phaseDetails.resolvedPhase.dates.duration === 'MILESTONE') {
      /**
       * We use this duration as the value to translate a milestone
       * to as this means the 'custom date' option will load with
       * today when selected, if already on the Milestone option.
       *
       * This need to translate to a duration for the value is a little
       * weird, and shows that the date duration input use case has extended
       * to values that don't strictly have a duration that can be modelled
       * correctly. Maybe we should allow for passing in a string value
       * to the input as an option alongside a date or duration?
       *
       * However, this is one to visit more in isolation. The extension to
       * the input to provide the matched config covers this usecase mostly
       * well enough, and extending the input further to cover this one
       * particular case may lead to an even more complicated field than
       * is necessary!
       */
      return {
        days: 0
      }
    }
    return phaseDetails.resolvedPhase.dates.duration
  }, [phaseDetails.resolvedPhase.dates.duration])

  const scheduleDateRange = {
    value: phaseDurationValue,
    minDate: phaseDetails.previousPhaseEndDate,
    durations: [
      ...defaultDurationOptions.filter(
        d => !['CUSTOM', 'FOREVER'].includes(d.value)
      ),
      {
        diff: {
          days: 0
        },
        label: 'Milestone based',
        value: 'MILESTONE'
      },
      defaultDurationOptions.find(d => d.value === 'FOREVER'),
      defaultDurationOptions.find(d => d.value === 'CUSTOM')
    ]
      .filter((d): d is DateDurationConfig => Boolean(d))
      .filter(durationConfig => {
        /**
         * Remove milestone, forever, and custom if they are not available
         */
        if (durationConfig.value === 'FOREVER' && !phaseDetails.isLastPhase) {
          return false
        }

        if (
          durationConfig.value === 'MILESTONE' &&
          !cubeContext.queries.availableFeatures.common.milestoneBilling
            .available.enabled &&
          /**
           * Account for the scenario where milestone was already set and the flag
           * is now off. It's 'trap door' state - once the value is changed it
           * can't go back - but it keeps things looking correct.
           */
          !isEqual(phaseDurationValue)({ days: 0 })
        ) {
          return false
        }

        if (
          durationConfig.value === 'CUSTOM' &&
          !cubeContext.queries.availableFeatures.common.customPhaseDurations
            .available.enabled
        ) {
          return false
        }

        return true
      }),
    fromDate: phaseDetails.resolvedPhase.dates.absolute.start,
    onChangeDuration: (
      newDuration: Duration | undefined,
      durationConfig?: DateDurationConfig
    ) => {
      if (durationConfig?.value === 'MILESTONE') {
        cubeContext.mutators.updateData({
          phases: {
            [phaseDetails.resolvedPhase.id]: {
              duration: 'MILESTONE'
            }
          }
        })
        return
      }

      if (durationConfig?.value === 'FOREVER') {
        cubeContext.mutators.updateData({
          phases: {
            [phaseDetails.resolvedPhase.id]: {
              duration: 'OPEN_ENDED'
            }
          }
        })
        return
      }

      cubeContext.mutators.updateData({
        phases: {
          [phaseDetails.resolvedPhase.id]: {
            duration: newDuration
          }
        }
      })
    },
    onChangeStartDate: (newStartDate: Date | undefined) => {
      cubeContext.mutators.updateData({
        common: {
          startDate: newStartDate
        }
      })
    },
    validationErrors: {
      startDate: phaseDetails.isFirstPhase
        ? cubeContext.queries.validation.activeValidationResults?.common
            .startDate ?? []
        : [],
      endDate:
        cubeContext.queries.validation.activeValidationResults?.common.phases?.[
          props.phaseId
        ]?.duration ?? []
    },
    disabled: {
      start:
        !phaseDetails.availableFeaturesForPhase.dates.editStart.available
          .enabled,
      end:
        !phaseDetails.availableFeaturesForPhase.dates.editDuration.available
          .enabled
    }
  }

  const recurrenceDayOfMonth = {
    hidden:
      !phaseDetails.isFirstPhase ||
      // TODO: replace with availableFeatures query
      Boolean(
        quoteStatusAdapter.out(cubeContext.queries.rawData.data.common.status)
      ) ||
      cubeContext.queries.quote.isATemplate,
    value: cubeContext.queries.rawData.data.schedule.recurrenceDayOfMonth,
    onChange: (newValue: number) =>
      cubeContext.mutators.updateData({
        schedule: {
          recurrenceDayOfMonth: newValue
        }
      }),
    validationErrors:
      cubeContext.queries.validation.activeValidationResults?.schedule
        .recurrenceDayOfMonth ?? [],
    disabled:
      !cubeContext.queries.availableFeatures.schedule.recurrenceDayOfMonth
        .available.enabled,
    disabledReason:
      cubeContext.queries.availableFeatures.schedule.recurrenceDayOfMonth.reasons.find(
        reason =>
          reason.reasonType === DisabledReasonType.NonMonthlyPricesPresent
      )
        ? 'Billing date selection is only available when all products are billed monthly'
        : ''
  }

  return {
    scheduleDateRange,
    recurrenceDayOfMonth
  }
}

export const PhaseDurationSelection: FC<{
  phaseId: string
  durationInputRef: React.RefObject<HTMLDivElement>
}> = ({ phaseId, durationInputRef }) => {
  const { scheduleDateRange, recurrenceDayOfMonth } = usePhaseDurationSelection(
    { phaseId }
  )

  return (
    <Box
      backgroundColor={GreyGrey10}
      borderRadius="6px"
      gap="8px"
      padding="12px 8px"
    >
      <Flex gap="16px">
        <DatePickerField
          data-testid="phase.startDate"
          variant="new"
          width="226px"
          label="Start date"
          placeholder="Not set"
          validationErrors={scheduleDateRange.validationErrors.startDate}
          minDate={scheduleDateRange.minDate}
          value={scheduleDateRange.fromDate}
          onChange={scheduleDateRange.onChangeStartDate}
          isDisabled={scheduleDateRange.disabled.start}
          styles={{
            wrapper: {
              marginBottom: 0
            }
          }}
          suffix={
            <ExtractedDataIndicator
              fields={[`billingSchedule.phases[${phaseId}].startDate`]}
              position="inline"
            />
          }
        />

        <Flex gap={2} alignItems="flex-end">
          <DateDurationField
            inputRef={durationInputRef}
            data-testid="phase.duration"
            width="226px"
            label="Duration"
            durations={scheduleDateRange.durations}
            mode="relative"
            validationErrors={scheduleDateRange.validationErrors.endDate}
            fromDate={scheduleDateRange.fromDate}
            value={scheduleDateRange.value}
            onChange={scheduleDateRange.onChangeDuration}
            isDisabled={scheduleDateRange.disabled.end}
            styles={{
              wrapper: {
                marginBottom: 0
              }
            }}
            height="32px"
            inputStyleVariant="new"
            trailingIcon={
              <ExtractedDataIndicator
                fields={[`billingSchedule.phases[${phaseId}].endDate`]}
                position="inline"
              />
            }
          />

          <CompleteMilestonePhase phaseId={phaseId} />
        </Flex>

        <Box paddingTop="32px" height="100%">
          <PhaseRecurrenceSwitch phaseId={phaseId} />
        </Box>
      </Flex>

      {!recurrenceDayOfMonth.hidden && (
        <DayOfMonthField
          styles={{
            wrapper: {
              marginBottom: 0,
              marginTop: '16px'
            }
          }}
          data-testid="phase.recurrenceDayOfMonth"
          width="226px"
          tooltip={{
            title: 'Proration',
            content:
              'Automatically prorate charges for partial billing periods. If you bill monthly on the 1st, but want to start charging a new customer mid-month, Sequence prorates the first billing period (e.g. 14th-31st Jan).'
          }}
          fieldTooltip={{
            label: recurrenceDayOfMonth.disabledReason,
            placement: 'top'
          }}
          label="Start billing period every"
          {...recurrenceDayOfMonth}
          isDisabled={recurrenceDayOfMonth.disabled}
          suffix={
            <ExtractedDataIndicator
              fields={['billingSchedule.recurrenceDayOfMonth']}
              position="inline"
            />
          }
        />
      )}
    </Box>
  )
}
