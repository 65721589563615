import { useNotifications } from 'lib/hooks/useNotifications'
import omit from 'lodash/omit'
import { useAddTaxRegistration } from 'components/Customers/hooks/useAddTaxRegistration'
import { useUpdateTaxRegistration } from 'components/Customers/hooks/useUpdateTaxRegistration'
import type { CountriesAlpha2, State } from '@sequencehq/api/commonEnums'
import { useMutation } from '@sequencehq/api/utils'
import {
  type Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { getAvalaraAddressError } from 'components/Customers/utils/getAvalaraAddressError'
import type { Customer } from 'Customer/types'
import { useCustomerContext } from './useCustomerContext'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'

type UpdateCustomer = Dashboardv99990101Api.PutCustomer.UpdateCustomerBody & {
  showTaxRegistration: boolean
  taxCountry: CountriesAlpha2
  taxState?: State
  taxIdentifier?: string
  taxRegistrationId?: string
}

export type UpdateCustomerErrorType = 'INVALID_AVALARA_ADDRESS' | 'OTHER'

export type UpdateCustomerError = {
  type: UpdateCustomerErrorType
}

export const useCustomerRoot = ({
  refetchCustomer,
  refetchCustomerAliases
}: {
  refetchCustomer: () => Promise<void>
  refetchCustomerAliases: () => Promise<void>
}) => {
  const customerCtx = useCustomerContext()
  const notifications = useNotifications()
  const updateCustomer = useMutation(dashboardv99990101Client.putCustomer)
  const { addTaxRegistration } = useAddTaxRegistration()
  const { updateTaxRegistration } = useUpdateTaxRegistration()

  const deleteCustomerAliases = async (
    customerAliases: Array<{ id: string }>
  ) => {
    await Promise.all(
      customerAliases.map(alias =>
        dashboardv20240509Client.deleteCustomerAlias({ id: alias.id })
      )
    )
  }

  const editCustomer = async (
    id: string,
    customer: UpdateCustomer
  ): Promise<{
    customer: Customer | null
    error: UpdateCustomerError | null
  }> => {
    const filterEmptyValues = (obj: object): object => {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([, value]) => value !== '' && value !== undefined)
          .map(([key, value]) =>
            value && typeof value === 'object' && !Array.isArray(value)
              ? [key, filterEmptyValues(value as object)]
              : [key, value]
          )
      )
    }

    /**
     * WARNING: some of these values, like customerAliases, will cause a
     * server error when saving them as empty arrays. It's therefore critical
     * to strip them out when empty!
     */
    const filteredAliases = customer.customerAliases?.filter(Boolean) ?? []
    const customerBody = filterEmptyValues({
      ...omit(customer, [
        'taxIdentifier',
        'taxCountry',
        'taxState',
        'showTaxRegistration',
        'customerAliases'
      ]),
      ...(filteredAliases.length ? { customerAliases: filteredAliases } : {})
    }) as Dashboardv99990101Api.PutCustomer.UpdateCustomerBody

    try {
      let loadingToastId: string | number | undefined
      const loadingToastTimeout = setTimeout(() => {
        loadingToastId = notifications.displayNotification(
          'Updating customer...',
          {
            type: 'info',
            duration: undefined
          }
        )
      }, 200)

      if (
        customerCtx.customerAliases.length &&
        !customerBody.customerAliases?.length
      ) {
        await deleteCustomerAliases(customerCtx.customerAliases)
      }
      const saveResult = await updateCustomer.mutateAsync({
        id,
        body: customerBody
      })

      clearTimeout(loadingToastTimeout)
      if (loadingToastId !== undefined) {
        notifications.hideNotification(loadingToastId)
      }

      if (customer.showTaxRegistration && !!customer.taxIdentifier) {
        const taxRegistrationBody = {
          taxIdentifier: customer.taxIdentifier,
          country: customer.taxCountry,
          state: ['US', 'CA'].includes(customer.taxCountry)
            ? customer.taxState
            : undefined,
          customerId: id
        }

        const taxRegistrationResult = customer.taxRegistrationId
          ? await updateTaxRegistration(
              customer.taxRegistrationId,
              taxRegistrationBody
            )
          : await addTaxRegistration(taxRegistrationBody)

        if (!taxRegistrationResult) {
          notifications.displayNotification(
            customer.taxRegistrationId
              ? 'Failed to update tax registration for customer'
              : 'Failed to create tax registration for customer',
            {
              type: 'error'
            }
          )
          return { customer: saveResult, error: null }
        }
      }

      notifications.displayNotification('Customer updated', {
        type: 'success'
      })
      await refetchCustomer()
      await refetchCustomerAliases()
      return { customer: saveResult, error: null }
    } catch (e) {
      let errorType: UpdateCustomerErrorType = 'OTHER'

      if (getAvalaraAddressError(e)) {
        errorType = 'INVALID_AVALARA_ADDRESS'
      }

      return {
        customer: null,
        error: {
          type: errorType
        }
      }
    }
  }

  return {
    functions: {
      editCustomer
    }
  }
}
