import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

export const useBillingScheduleId = () => {
  const { id } = useParams<{ id: string }>()

  invariant(id, 'Billing schedule ID is required')

  return id
}
