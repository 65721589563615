import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Customer } from './types'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { useNotifications } from 'lib/hooks/useNotifications'
import { integrationName } from 'lib/integrations/integrationName'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { emailToName } from 'CustomerContacts/index'

type UseCustomerIntegrationLinkForm = (props: {
  customer: Customer | Omit<Customer, 'contacts'>
  integrationService: IntegrationServices
}) => {
  fields: FormFields<{
    integrationId: string
  }>
  ready: boolean
  submitting: boolean
  onCancel: () => void
  onSubmit: () => Promise<void>
}

type IntegrationCustomer = {
  id: string
  companyName: string
  isLinked: boolean
}

export const useCustomerIntegrationLinkForm: UseCustomerIntegrationLinkForm = ({
  customer,
  integrationService
}) => {
  const [integrationCustomers, setIntegrationCustomers] = useState<
    Array<IntegrationCustomer>
  >([])
  const [customerContacts, setCustomerContacts] = useState<
    Customer['contacts']
  >([])
  const [ready, setReady] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const notifications = useNotifications()

  // Unfortunately need this to fully close the overlay via the global dispatch system we still use
  const dispatch = useDispatch()

  const loadData = useCallback(async () => {
    const integrationCustomersResponse =
      await dashboard20240730Client.getIntegrationCustomersService(
        integrationService
      )

    if ('contacts' in customer) {
      if (
        integrationCustomersResponse.error ||
        !integrationCustomersResponse.data
      ) {
        return
      }

      setIntegrationCustomers(integrationCustomersResponse.data.items)
      setCustomerContacts(customer.contacts)
      setReady(true)
      return
    }

    const enrichedCustomerResponse = await dashboardv99990101Client.getCustomer(
      {
        id: customer.id
      }
    )

    const hasError = [
      integrationCustomersResponse,
      enrichedCustomerResponse
    ].some(response => response.error)

    if (
      hasError ||
      !integrationCustomersResponse.data ||
      !enrichedCustomerResponse.data
    ) {
      return
    }

    setIntegrationCustomers(integrationCustomersResponse.data.items)
    setCustomerContacts(enrichedCustomerResponse.data.contacts)
    setReady(true)
  }, [customer.id, integrationService])

  useEffect(() => void loadData(), [loadData])

  const initialIntegrationId = useMemo(() => {
    const existingIntegrationId = customer.integrationIds.find(
      integrationId => integrationId.service === integrationService
    )

    if (existingIntegrationId) {
      return existingIntegrationId.id
    }

    return ''
  }, [customer.integrationIds, integrationService])

  const { fields } = useForm({
    value: {
      integrationId: initialIntegrationId
    },
    fieldConfiguration: [
      {
        property: 'integrationId',
        validation: [required]
      }
    ]
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      integrationId: {
        ...fields.integrationId,
        options: integrationCustomers.map(({ id, companyName, isLinked }) => ({
          value: id,
          label: companyName,
          disabled: isLinked
        }))
      }
    }
  }, [fields, integrationCustomers])

  const onSubmit = useCallback(async () => {
    notifications.displayNotification(
      `Linking customer to ${integrationName(integrationService)}`
    )
    setSubmitting(true)
    const res = await dashboardv99990101Client.putCustomer({
      id: customer.id,
      body: {
        legalName: customer.legalName,
        taxStatus: customer.taxStatus,
        address: customer.address,
        contacts: customerContacts.map(contact => ({
          ...contact,
          name: contact.name ?? emailToName(contact.email)
        })),
        integrationIds: [
          ...customer.integrationIds,
          {
            service: integrationService,
            id: fields.integrationId.value
          }
        ]
      }
    })

    if (res.error || !res.data) {
      setSubmitting(false)
      notifications.displayNotification(
        `There was an error linking the customer to ${integrationName(
          integrationService
        )}`,
        {
          type: 'error'
        }
      )
    }

    setSubmitting(false)
    notifications.displayNotification(
      `Customer linked to ${integrationName(integrationService)}`,
      {
        type: 'success'
      }
    )
    dispatch(closeOverlay())
  }, [
    customer.address,
    customer.id,
    customer.integrationIds,
    customer.legalName,
    customer.taxStatus,
    customerContacts,
    dispatch,
    fields.integrationId.value,
    integrationService,
    notifications
  ])

  const onCancel = useCallback(() => dispatch(closeOverlay()), [dispatch])

  return {
    fields: enhancedFields,
    ready,
    submitting,
    onCancel,
    onSubmit
  }
}
