import { isSequenceApiError, isSequenceApiErrorResponse } from '@sequencehq/api'
import type { ErrorResponse, SequenceApiError } from '@sequencehq/api'

export const getAvalaraAddressError = (error: unknown) => {
  if (
    !isSequenceApiError(error) ||
    !isSequenceApiErrorResponse(error.response)
  ) {
    return false
  }

  const errorKeys =
    error.response.error.errors?.map(err => err.key).filter(Boolean) ?? []

  return errorKeys.includes('avalara')
}
