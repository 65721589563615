import { Currency } from '@sequencehq/api/utils/commonEnums'
import { useSearchParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { useCallback, useEffect, useMemo } from 'react'
import { useAvailableCurrencies } from 'RevenueRecognition/view/utils/useAvailableCurrencies'

/**
 * Grab the current selected currency from the query params,
 * or the default sequence account currency if none selected.
 * @returns
 */
export const useSelectedCurrency = ():
  | {
      currency: null
      setCurrency: (currency: Currency) => void
      isLoading: true
    }
  | {
      currency: Currency
      setCurrency: (currency: Currency) => void
      isLoading: false
    } => {
  const [searchParams, setSearchParams] = useSearchParams()

  /**
   * The logic here is that we want to grab the currency from the search params,
   * but the search params can only contain a value which is found in the enabled
   * currencies list.
   *
   * If no search params are set, we then use the enabled list to set a default.
   */
  const searchParamsCurrency = searchParams.get('currency') as Currency
  const { availableCurrencies, isLoading: isAvailableCurrenciesLoading } =
    useAvailableCurrencies()

  const currency = useMemo(() => {
    if (isAvailableCurrenciesLoading || !availableCurrencies) {
      return null
    }

    if (
      searchParamsCurrency &&
      availableCurrencies.includes(searchParamsCurrency)
    ) {
      return searchParamsCurrency
    }

    return availableCurrencies[0]
  }, [availableCurrencies, isAvailableCurrenciesLoading, searchParamsCurrency])

  /**
   * Update the currency, and sync it to the search params.
   */
  const setCurrency = useCallback(
    (newCurrency: Currency) => {
      const currentSearchParams = [...searchParams.entries()].reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value
        }),
        {}
      )

      setSearchParams({
        ...currentSearchParams,
        currency: newCurrency
      })
    },
    [searchParams, setSearchParams]
  )

  /**
   * If we've loaded in without a currency set in the search params, update
   * the search params, as appropriate, once we have resolved the applicable
   * default currency.
   */
  useEffect(() => {
    if (!currency || isAvailableCurrenciesLoading) {
      return
    }

    if (searchParamsCurrency === currency) {
      return
    }

    setCurrency(currency)
  }, [
    searchParamsCurrency,
    isAvailableCurrenciesLoading,
    currency,
    setCurrency
  ])

  invariant(
    currency || isAvailableCurrenciesLoading,
    'Available currencies must be configured'
  )

  /**
   * This method of returning keeps the type happy, which also allows
   * us to clearly communicate that currency will be null until we have
   * loaded it in.
   */
  if (!currency) {
    return {
      currency,
      setCurrency,
      isLoading: true
    }
  }

  return {
    currency: currency as Currency,
    setCurrency,
    isLoading: false
  }
}
