import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey70,
  GreyGrey30,
  Lato13Regular,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { Skeleton } from '@sequencehq/core-components'

const SummaryInfoRow = ({
  title,
  value,
  borderBottom = true,
  isLoading = false
}: {
  title: string
  value: React.ReactNode | string
  borderBottom?: boolean
  isLoading?: boolean
}) => {
  if (isLoading) {
    return (
      <Flex
        height="32px"
        px="4px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Skeleton height="16px" width="30%" />
        <Skeleton height="16px" width="30%" />
      </Flex>
    )
  }

  return (
    <Flex
      height="32px"
      px="4px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={borderBottom ? `1px solid ${GreyGrey30}` : 'none'}
    >
      <Text {...Lato13Regular} color={GreyGrey70}>
        {title}
      </Text>
      {typeof value === 'string' ? (
        <Text {...Lato13Regular}>{value}</Text>
      ) : (
        value
      )}
    </Flex>
  )
}

type SummaryProps = {
  rows: {
    title: string
    value: React.ReactNode | string
  }[]
  isLoading?: boolean
}

export const Summary = ({ rows, isLoading }: SummaryProps) => {
  return (
    <Flex flexDirection="column">
      <Flex px="4px" pt="4px" pb="8px">
        <Text {...Lato13Bold}>Summary</Text>
      </Flex>
      {rows.map((row, index) => (
        <SummaryInfoRow
          key={row.title}
          title={row.title}
          value={row.value}
          borderBottom={index !== rows.length - 1}
          isLoading={isLoading}
        />
      ))}
    </Flex>
  )
}
