import { developerLog } from '@sequencehq/utils'
import {
  IntegrationsActions,
  IntegrationsReducerState,
  PostActionStage
} from 'modules/Integrations/domain/integrations.domain.types'
import { match } from 'ts-pattern'
import * as actions from 'modules/Integrations/domain/actionHandlers'
import * as postActionStages from 'modules/Integrations/domain/postActionStages'
import compose from 'lodash/fp/compose'
import { Reducer } from 'react'
import { INITIAL_EXAMPLE_STATE } from 'modules/Integrations/domain/integrations.constants'

const handleAction =
  (prevState: IntegrationsReducerState) => (action: IntegrationsActions) => {
    return match(action)
      .with({ type: 'load' }, matchedAction =>
        actions.load(prevState)(matchedAction)
      )
      .with({ type: 'updateEditor' }, matchedAction =>
        actions.updateEditor(prevState)(matchedAction)
      )
      .with({ type: 'updateData' }, matchedAction =>
        actions.updateData(prevState)(matchedAction)
      )
      .with({ type: 'reloadData' }, matchedAction =>
        actions.reloadData(prevState)(matchedAction)
      )
      .exhaustive()
  }

const logStage =
  (stageName: string): PostActionStage =>
  () =>
  prevState => {
    developerLog(
      `[Integrations] After post action stage: %c${stageName}`,
      'color: lime;',
      {
        state: prevState
      }
    )
    return prevState
  }

const handlePostActionStages: PostActionStage = ctx => prevState => {
  /**
   * Since this is a compose, the actual order is bottom to top!
   */
  return compose(
    logStage('Queries')(ctx),
    postActionStages.queries(ctx)
  )(prevState)
}

export const integrationsReducer: Reducer<
  IntegrationsReducerState,
  IntegrationsActions
> = (prevState = INITIAL_EXAMPLE_STATE, action) => {
  developerLog(
    `%c[Integrations]======== Start of reducer for action ${action.type} =========`,
    'color: cyan;',
    {
      initialState: prevState,
      action
    }
  )

  const postActionState = handleAction(prevState)(action)

  developerLog(
    `%c[Integrations]======== beginning of post action functionality for action ${action.type} =========`,
    'color: lime;',
    {
      postActionState
    }
  )

  const finalState = handlePostActionStages({
    preActionState: prevState,
    action
  })(postActionState)

  developerLog(
    `%c[Integrations]======== End of reducer for action ${action.type} =========`,
    'color: hotpink;',
    {
      finalState
    },
    `
    `
  )

  return finalState
}
