import {
  NEW_DISCOUNT_PREFIX,
  NEW_MINIMUM_PREFIX,
  NEW_PHASE_PREFIX
} from 'modules/Cube/domain/cube.constants'
import {
  Phase,
  ResolvedPhase,
  DisabledReasonType,
  PhasePriceMetadata
} from 'modules/Cube/domain/cube.domain.types'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import findLastIndex from 'lodash/fp/findLastIndex'
import isEqual from 'lodash/fp/isEqual'
import omit from 'lodash/fp/omit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { scheduleStatusAdapter } from 'Cube/utils/status.adapter'
import { isAfter, isBefore } from '@sequencehq/utils/dist/dates'

type usePricingWidget = () => {
  phases: (ResolvedPhase & {
    expanded: boolean
    focusDurationOnLoad: boolean
  })[]
  addPhase: {
    disabled: boolean
    handler: () => void
    disabledReason: string
  }
  updatePhaseExpanded: (phaseId: Phase['id']) => (expanded: boolean) => void
}

/**
 * By default, we will expand the phase that was the last to become active. Or,
 * if no phases would be active (i.e. they're all in the future), expand the
 * first one.
 * @param sortedPhases
 */
export const getDefaultActivePhaseIndex = (
  sortedPhases: Pick<ResolvedPhase, 'dates'>[],
  dateToCompare: Date = new Date()
): number => {
  const activePhaseIndex = findLastIndex(
    (phase: Pick<ResolvedPhase, 'dates'>) => {
      if (!phase.dates.absolute.start) {
        return false
      }

      if (!isAfter(dateToCompare, phase.dates.absolute.start)) {
        return false
      }

      /**
       * An undefined end date is an 'open ended' phase, so this is
       * effectively always 'active' if the start date is in the past.
       */
      return (
        !phase.dates.absolute.end ||
        isBefore(dateToCompare, phase.dates.absolute.end)
      )
    }
  )(sortedPhases)

  return activePhaseIndex === -1 ? 0 : activePhaseIndex
}

export const usePricingWidget: usePricingWidget = () => {
  const cubeContext = useCubeContext()
  /**
   * We manage the phase expanded state at this level so that we can control
   * the closing of phases when, for example, creating a new one.
   */

  const sortedPhases = useMemo(() => {
    return cubeContext.queries.orderedPhases
  }, [cubeContext])

  const [phaseBehaviour, setPhaseBehaviour] = useState<
    Array<{
      id: string
      expanded: boolean
      focusDurationOnLoad: boolean
    }>
  >(
    sortedPhases.map(({ id }, idx) => ({
      id,
      expanded: getDefaultActivePhaseIndex(sortedPhases) === idx,
      focusDurationOnLoad: false
    }))
  )

  /**
   * If the phases change (e.g. we saved the phases and gained new ids) then
   * reconstruct the phase behaviour array with the new ids, and retain the old
   * behaviour as much as possible. This means that if we have a phase expanded
   * and then save, we wont auto-close that phase on the reload.
   */
  useEffect(() => {
    const currentIds = phaseBehaviour.map(({ id }) => id)
    const sortedPhaseIds = sortedPhases.map(({ id }) => id)

    if (!isEqual(currentIds)(sortedPhaseIds)) {
      setPhaseBehaviour(
        sortedPhaseIds.map((id, idx) => {
          /**
           * This cascase allows us to pick up existing behaviour based on id if
           * we, say, delete a phase in the middle.
           */
          const existingBehaviour =
            phaseBehaviour.find(prevBehaviour => prevBehaviour.id === id) ||
            phaseBehaviour[idx]
          return {
            id,
            expanded: existingBehaviour?.expanded ?? false,
            focusDurationOnLoad: false
          }
        })
      )
    }
  }, [sortedPhases, phaseBehaviour])

  const enhancedPhaseData = useMemo(() => {
    return sortedPhases.map((phase, idx) => ({
      ...phase,
      /**
       * Always expand the phase if there is only one phase
       * currently available.
       */
      expanded:
        sortedPhases.length === 1
          ? true
          : phaseBehaviour?.[idx]?.expanded ?? false,
      /**
       * We never retain focusDuration - it's only set once when
       * adding the phase. We could probably do with something
       * a bit more elegant than this quasi-trap state.
       */
      focusDurationOnLoad: phaseBehaviour?.[idx]?.focusDurationOnLoad ?? false
    }))
  }, [phaseBehaviour, sortedPhases])

  /**
   * TODO: Move to the domain
   */
  const addPhaseHandler = useCallback(() => {
    if (
      !cubeContext.queries.availableFeatures.common.addPhase.available.enabled
    ) {
      return
    }

    const previousPhase = sortedPhases[sortedPhases.length - 1]
    /**
     * Create the new prices, and discounts, for this phase.
     * If the price is already a persisted price, don't clone to a new
     * id. This path will, in particular, make for more simple behaviour
     * with the unified price editor.
     */
    const newPrices = previousPhase.prices.map(price => {
      return {
        ...price,
        id: price.id,
        oldPriceId: price.id
      }
    })

    const newPriceMetadata = previousPhase.phasePriceMetadata
      .map(metadata => ({
        priceId: newPrices.find(
          ({ oldPriceId }) => oldPriceId === metadata.priceId
        )?.id,
        arrCalculation: metadata.arrCalculation ?? 'INCLUDE'
      }))
      .filter((m): m is PhasePriceMetadata => Boolean(m.priceId))

    const newDiscounts = cubeContext.queries.resolvedPhases[
      previousPhase.id
    ]?.discounts.map(discount => ({
      ...discount,
      id: `${NEW_DISCOUNT_PREFIX}${crypto.randomUUID()}`,
      priceIds: discount.priceIds
        .map(
          priceId =>
            newPrices.find(newPrice => newPrice.oldPriceId === priceId)?.id
        )
        .filter((priceId): priceId is string => Boolean(priceId))
    }))

    const newMinumums = cubeContext.queries.resolvedPhases[
      previousPhase.id
    ]?.minimums.map(minimum => ({
      ...minimum,
      id: `${NEW_MINIMUM_PREFIX}${crypto.randomUUID()}`,
      scope: {
        ...minimum.scope,
        priceIds: minimum.scope.priceIds
          .map(
            priceId =>
              newPrices.find(newPrice => newPrice.oldPriceId === priceId)?.id
          )
          .filter((priceId): priceId is string => Boolean(priceId))
      }
    }))

    const previousPhaseOpenEnded = previousPhase.dates.duration === 'OPEN_ENDED'
    const newPhase: Phase = {
      id: `${NEW_PHASE_PREFIX}${crypto.randomUUID()}`,
      name: '',
      priceIds: newPrices.map(({ id }) => id),
      discountIds: newDiscounts.map(({ id }) => id),
      duration: previousPhaseOpenEnded
        ? 'OPEN_ENDED'
        : {
            years: 1,
            days: -1
          },
      minimumIds: newMinumums.map(({ id }) => id),
      recurrencePreference: previousPhase.recurrencePreference,
      phasePriceMetadata: newPriceMetadata
    }

    cubeContext.mutators.updateData({
      common: {
        phaseIds: [
          ...cubeContext.queries.rawData.data.common.phaseIds,
          newPhase.id
        ]
      },
      phases: {
        [previousPhase.id]: {
          duration: !previousPhaseOpenEnded
            ? previousPhase.dates.duration
            : {
                years: 1,
                days: -1
              }
        },
        [newPhase.id]: newPhase
      },
      prices: arrayToIdKeyedObject(newPrices.map(omit('oldPriceId'))),
      discounts: arrayToIdKeyedObject(newDiscounts),
      minimums: arrayToIdKeyedObject(newMinumums)
    })

    setPhaseBehaviour(prev => [
      ...prev,
      {
        id: newPhase.id,
        expanded: true,
        focusDurationOnLoad: true
      }
    ])
  }, [cubeContext, sortedPhases])

  const updatePhaseExpanded = useCallback(
    (phaseId: Phase['id']) => (expanded: boolean) => {
      const idx = phaseBehaviour.findIndex(({ id }) => id === phaseId)
      setPhaseBehaviour(prev => [
        ...prev.slice(0, idx),
        { ...prev[idx], expanded },
        ...prev.slice(idx + 1)
      ])
    },
    [phaseBehaviour]
  )

  const addPhaseDisabledText = useMemo(() => {
    const REASON_MAP: Partial<Record<DisabledReasonType, string>> = {
      [DisabledReasonType.InvalidStatus]:
        'Cannot add a phase with the current status',
      [DisabledReasonType.PhaseHasNoPrices]:
        'Add prices to the latest phase to be able to add a new one'
    }

    if (
      cubeContext.queries.availableFeatures.common.addPhase.available.enabled
    ) {
      return ''
    }

    const cubeVariantLabel: string = scheduleStatusAdapter.out(
      cubeContext.queries.rawData.data.common.status
    )
      ? 'schedule'
      : 'quote'

    return (
      REASON_MAP[
        cubeContext.queries.availableFeatures.common.addPhase.reasons[0]
          ?.reasonType
      ] ?? `Cannot add a new phase to this ${cubeVariantLabel}`
    )
  }, [
    cubeContext.queries.availableFeatures.common.addPhase,
    cubeContext.queries.rawData.data.common.status
  ])

  return {
    phases: enhancedPhaseData,
    addPhase: {
      handler: addPhaseHandler,
      disabled:
        !cubeContext.queries.availableFeatures.common.addPhase.available
          .enabled,
      disabledReason: addPhaseDisabledText
    },
    updatePhaseExpanded
  }
}
