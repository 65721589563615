export interface ShortcutGroup {
  title: string
  shortcuts: Array<{
    keys: string[]
    description: string
    isSimultaneous?: boolean
    route?: string
  }>
}

// Single source of truth for all keyboard shortcuts
export const navigationShortcuts = [
  { keys: ['g', 'h'], description: 'Go to Home', route: '/home' },
  {
    keys: ['g', 's'],
    description: 'Go to Schedules',
    route: '/billing-schedules'
  },
  { keys: ['g', 'c'], description: 'Go to Customers', route: '/customers' },
  {
    keys: ['g', 'i'],
    description: 'Go to Invoices',
    route: '/invoices?sortBy=accountingDate_desc'
  },
  {
    keys: ['g', 'x'],
    description: 'Go to Credit notes',
    route: '/credit-notes'
  },
  { keys: ['g', 'q'], description: 'Go to Quotes', route: '/quotes' },
  { keys: ['g', 'p'], description: 'Go to Products', route: '/products' },
  {
    keys: ['g', 'u'],
    description: 'Go to Usage events',
    route: '/usage-events'
  },
  {
    keys: ['g', 'm'],
    description: 'Go to Usage metrics',
    route: '/usage-metrics'
  },
  {
    keys: ['g', 'e'],
    description: 'Go to Seats events',
    route: '/seat-events'
  },
  {
    keys: ['g', 'b'],
    description: 'Go to Seats balances',
    route: '/seat-balances'
  },
  { keys: ['g', 't'], description: 'Go to Seats metrics', route: '/seats' },
  {
    keys: ['g', 'r'],
    description: 'Go to Revenue recognition',
    route: '/revenue-recognition'
  },
  {
    keys: ['g', 'd'],
    description: 'Go to Deferred revenue',
    route: '/revenue-recognition/deferred-revenue'
  },
  { keys: ['g', 'v'], description: 'Go to Insights', route: '/insights' }
]

// Navigation UI shortcuts
export const navigationUIShortcuts = [
  { keys: ['['], description: 'Collapse navigation sidebar' },
  { keys: [']'], description: 'Expand navigation sidebar' }
]

export const leftColumnGroups: ShortcutGroup[] = [
  {
    title: 'General',
    shortcuts: [
      {
        keys: ['⌘', '/'],
        description: 'View keyboard shortcuts',
        isSimultaneous: true
      }
    ]
  },
  {
    title: 'Navigation',
    shortcuts: navigationShortcuts
  },
  {
    title: 'Navigation UI',
    shortcuts: navigationUIShortcuts
  }
]

export const rightColumnGroups: ShortcutGroup[] = []
