import {
  FC,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer
} from 'react'
import { match } from 'ts-pattern'
import * as Sentry from '@sentry/react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type SeatType = DashboardApi20240730.GetSeatMetrics.SeatMetric
type SeatTypeState = {
  seatType: SeatType | undefined
  seatTypeId: string | undefined
}

type SeatTypeContextType = {
  state: SeatTypeState
  dispatch: (action: SeatTypeAction) => void
}

const SeatTypeContext = createContext<SeatTypeContextType | undefined>(
  undefined
)

type SeatTypeAction =
  | {
      type: 'setSeatTypeId'
      seatTypeId: string | undefined
    }
  | {
      type: 'setSeatType'
      seatType: SeatType | undefined
    }

const seatTypeReducer = (state: SeatTypeState, action: SeatTypeAction) => {
  return match(action)
    .with({ type: 'setSeatTypeId' }, ({ seatTypeId }) => ({
      ...state,
      seatTypeId,
      seatType: state.seatTypeId === seatTypeId ? state.seatType : undefined
    }))
    .with({ type: 'setSeatType' }, ({ seatType }) => {
      return seatType?.id === state.seatTypeId
        ? {
            ...state,
            seatType
          }
        : state
    })
    .exhaustive()
}

type SeatTypeProviderProps = {
  children: ReactNode
}

export const SeatTypeProvider: FC<SeatTypeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<SeatTypeState, SeatTypeAction>>(
    seatTypeReducer,
    {
      seatType: undefined,
      seatTypeId: undefined
    }
  )
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  useEffect(() => {
    if (!state.seatTypeId) {
      return dispatch({ type: 'setSeatType', seatType: undefined })
    }

    dashboard20240730Client
      .getSeatMetric({ id: state.seatTypeId })
      .then(res => {
        if (res.error || !res.data) {
          throw new Error('Could not fetch seat type')
        }
        dispatch({
          type: 'setSeatType',
          seatType: res.data
        })
      })
      .catch(err => Sentry.captureException(err))
  }, [state.seatTypeId])

  return (
    <SeatTypeContext.Provider value={value}>
      {children}
    </SeatTypeContext.Provider>
  )
}

export const useSeatType = () => {
  const context = useContext(SeatTypeContext)
  if (context === undefined) {
    throw new Error('useSeatType must be used within a SeatTypeProvider')
  }
  return context
}
