import { Tooltip, TooltipProps } from '@chakra-ui/react'
import { GreyGrey80 } from '@sequencehq/design-tokens'
import { ReactNode } from 'react'
import { NAVIGATION_ANIMATION } from 'components/NavigationV3/constants/animation'

interface NavTooltipProps {
  label: string
  children: ReactNode
  isDisabled?: boolean
  placement?: TooltipProps['placement']
}

/**
 * A standardized tooltip component for navigation items when collapsed
 */
export const NavTooltip = ({
  label,
  children,
  isDisabled = false,
  placement = 'right'
}: NavTooltipProps) => {
  if (isDisabled) {
    return <>{children}</>
  }

  return (
    <Tooltip
      label={label}
      placement={placement}
      hasArrow
      openDelay={NAVIGATION_ANIMATION.TOOLTIP_DELAY}
      bg={GreyGrey80}
      color="white"
    >
      {children}
    </Tooltip>
  )
}
