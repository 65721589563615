import { Box, Grid } from '@chakra-ui/react'
import { useComplexQuery } from '@sequencehq/api'
import {
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  Lato13Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import {
  fetchBillingScheduleAggregate,
  getBillingScheduleAggregateKey
} from 'modules/BillingScheduleHome/utils/useBillingScheduleAggregate'
import { useBillingScheduleId } from 'modules/BillingScheduleHome/utils/useBillingScheduleId'
import { ReactNode } from 'react'

const useScheduleDetailsSection = () => {
  const billingScheduleId = useBillingScheduleId()

  const billingScheduleAggregateQuery = useComplexQuery({
    queryFn: () => fetchBillingScheduleAggregate(billingScheduleId),
    queryKey: getBillingScheduleAggregateKey(billingScheduleId),
    staleTime: 60000,
    select: data => {
      return {
        customerId: data.billingSchedule.customerId
      }
    }
  })

  return {
    isLoading: billingScheduleAggregateQuery.isPending,
    customerId: billingScheduleAggregateQuery.data?.customerId
  }
}

const DetailItem = ({
  label,
  content
}: { label: ReactNode; content: ReactNode }) => {
  return (
    <Grid
      height="32px"
      columnGap="16px"
      templateColumns="158px 1fr"
      alignItems="center"
    >
      <Box {...Lato13Regular} color={GreyGrey70}>
        {label}
      </Box>
      <Box {...Lato13Regular} color={GreyGrey80}>
        {content}
      </Box>
    </Grid>
  )
}

export const ScheduleDetailsSection = () => {
  const hook = useScheduleDetailsSection()

  return (
    <Box height="232px">
      <Box {...Lato16Bold} marginBottom="16px">
        Schedule details
      </Box>
      <Grid templateColumns="1fr 1fr" gap="16px">
        <Box>
          <DetailItem
            label="Customer"
            content={
              hook.customerId ? (
                <CustomerPreviewCardPill customerId={hook.customerId} />
              ) : (
                <Box color={GreyGrey60}>No customer set</Box>
              )
            }
          />
        </Box>
        <Box></Box>
      </Grid>
    </Box>
  )
}
