import { useEffect, useMemo, useRef } from 'react'
import { InlineToast, InlineToastState } from '@sequencehq/core-components'
import { useAtom } from 'jotai'
import { loadableExtractionResultAtom } from 'ContractExtraction/atoms'
import { match } from 'ts-pattern'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const ExtractionStatusToast = () => {
  const flags = useFlags()

  const [extractionResult] = useAtom(loadableExtractionResultAtom)
  const hasLoadingStarted = useRef<boolean>(false)

  // extractionResult.state is initially 'hasData' before loading,
  // so check if loading has started before showing the success toast
  useEffect(() => {
    if (extractionResult.state === 'loading') {
      hasLoadingStarted.current = true
    }
  }, [extractionResult.state])

  const toastState: InlineToastState | undefined = useMemo(() => {
    return match({
      state: extractionResult.state,
      hasLoadingStarted: hasLoadingStarted.current
    })
      .with(
        { state: 'loading' },
        (): InlineToastState => ({
          variant: 'LOADING',
          text: 'Analyzing contract',
          persistent: true
        })
      )
      .with(
        { state: 'hasData', hasLoadingStarted: true },
        (): InlineToastState => ({
          variant: 'SUCCESS',
          text: 'Analysis successful'
        })
      )
      .with({ state: 'hasData', hasLoadingStarted: false }, () => undefined)
      .with(
        { state: 'hasError' },
        (): InlineToastState => ({
          variant: 'FAILURE',
          text: 'Error extracting information'
        })
      )
      .exhaustive()
  }, [extractionResult])

  if (!flags?.useContractIntakePrototype) {
    return null
  }

  return <InlineToast state={toastState} />
}
