import { Flex } from '@chakra-ui/react'
import { Button, SequenceKebabMenu } from '@sequencehq/core-components'
import { EnvelopeIcon, EyeIcon } from '@heroicons/react/16/solid'
import { RedRed50 } from '@sequencehq/design-tokens'
import { CreditNoteIssueDialog } from 'CreditNotes/components/dialog/CreditNoteIssue'
import type { CreditNoteStatus } from 'CreditNotes/types'
import { CREDIT_NOTE_STATUSES } from 'CreditNotes/constants'
import { VoidCreditNoteDialog } from './dialog/VoidCreditNoteDialog'
import { useMutation } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useNotifications } from 'lib/hooks/useNotifications'
import { SendTestCreditNoteDialog } from './dialog/SendTestCreditNoteDialog'
import { useCreditNotePdfDownload } from 'CreditNotesList/useCreditNotePdfDownload'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useCreditNoteQuery } from 'CreditNotes/hooks/useCreditNote.ts'
import { ViewCustomerPortalButton } from 'components/ViewCustomerPortalButton/ViewCustomerPortalButton.tsx'

const useCreditNoteActions = (creditNoteId: string) => {
  const notifications = useNotifications()
  const { data: creditNote } = useCreditNoteQuery(creditNoteId)

  const saveCreditNoteMutation = useMutation(
    dashboardv99990101Client.putCreditNote
  )

  const issueCreditNoteMutation = useMutation(
    dashboardv99990101Client.postFinalizeAndSendCreditNote,
    {
      onSuccess: () => {
        notifications.displayNotification('Credit note issued', {
          type: 'success'
        })
      },
      onError: () => {
        notifications.displayNotification('Failed to issue credit note', {
          type: 'error'
        })
      }
    }
  )
  const voidCreditNoteMutation = useMutation(
    dashboardv99990101Client.postVoidCreditNote,
    {
      onSuccess: () => {
        notifications.displayNotification('Credit note voided', {
          type: 'success'
        })
      },
      onError: () => {
        notifications.displayNotification('Failed to void credit note', {
          type: 'error'
        })
      }
    }
  )
  const sendTestCreditNoteMutation = useMutation(
    dashboardv99990101Client.postSendTestCreditNote,
    {
      onSuccess: () => {
        notifications.displayNotification('Test credit note sent', {
          type: 'success'
        })
      },
      onError: () => {
        notifications.displayNotification('Failed to send test credit note', {
          type: 'error'
        })
      }
    }
  )

  const issueCreditNote = {
    mutate: () => {
      issueCreditNoteMutation.mutate({
        id: creditNoteId
      })
    },
    isLoading: issueCreditNoteMutation.isPending
  }

  const voidCreditNote = {
    mutate: () => {
      voidCreditNoteMutation.mutate({
        id: creditNoteId
      })
    },
    isLoading: voidCreditNoteMutation.isPending
  }

  const sendTestCreditNote = {
    mutate: (testEmail: string) => {
      sendTestCreditNoteMutation.mutate({
        id: creditNoteId,
        testEmail
      })
    },
    isLoading: sendTestCreditNoteMutation.isPending
  }

  const grantCreditAction = {
    mutate: (shouldGrantCredit: boolean) => {
      saveCreditNoteMutation.mutate({
        id: creditNoteId,
        body: {
          ...creditNote,
          settings: {
            generateCashCreditGrant: shouldGrantCredit ? 'ENABLED' : 'DISABLED'
          }
        }
      })
    },
    isLoading: saveCreditNoteMutation.isPending
  }

  return {
    issueCreditNote,
    sendTestCreditNote,
    voidCreditNote,
    grantCreditAction
  }
}

interface Props {
  creditNoteId: string
  creditNoteNumber: string | undefined
  creditNoteStatus: CreditNoteStatus
  onPreviewCreditNotePDF: () => void
  customerLegalCompanyName: string
  customerEmails: string[]
}

export const CreditNoteDetailsHeaderActions = ({
  creditNoteId,
  creditNoteNumber,
  creditNoteStatus,
  onPreviewCreditNotePDF,
  customerLegalCompanyName,
  customerEmails
}: Props) => {
  const flags = useFlags()
  const { data: creditNote } = useCreditNoteQuery(creditNoteId)

  const {
    issueCreditNote,
    sendTestCreditNote,
    voidCreditNote,
    grantCreditAction
  } = useCreditNoteActions(creditNoteId)

  const { downloadPdf } = useCreditNotePdfDownload()

  const isDraft = creditNoteStatus === CREDIT_NOTE_STATUSES.DRAFT
  const customerPortalPath = `/customers/${creditNote?.customerId}/credit-notes/${creditNoteId}`

  const showGrantCredits =
    isDraft &&
    flags.creditNoteCreditGrantsEnabled &&
    creditNote?.currency &&
    creditNote.netTotal

  return (
    <Flex gap={2} alignItems="center" alignContent="center">
      <Button
        variant="secondary"
        leadingIcon={<EyeIcon width="16px" height="16px" />}
        onClick={onPreviewCreditNotePDF}
      >
        Preview credit note PDF
      </Button>

      {[CREDIT_NOTE_STATUSES.SENT, CREDIT_NOTE_STATUSES.FINAL].includes(
        creditNoteStatus
      ) && <ViewCustomerPortalButton path={customerPortalPath} />}

      {isDraft && (
        <CreditNoteIssueDialog
          onConfirm={issueCreditNote.mutate}
          isIssuingCreditNote={issueCreditNote.isLoading}
          customerLegalCompanyName={customerLegalCompanyName}
          customerEmails={customerEmails}
          showGrantCredits={showGrantCredits}
          creditNoteId={creditNoteId}
          onCreditGrantChange={grantCreditAction.mutate}
          isGrantingCredit={grantCreditAction.isLoading}
          trigger={
            <Button leadingIcon={<EnvelopeIcon width="16px" height="16px" />}>
              Issue credit note
            </Button>
          }
        />
      )}

      <VoidCreditNoteDialog
        key="void-dialog"
        onConfirm={voidCreditNote.mutate}
        isSubmitting={voidCreditNote.isLoading}
        trigger={openVoidCreditNoteDialog => (
          <SendTestCreditNoteDialog
            key="send-test-email-dialog"
            onConfirm={sendTestCreditNote.mutate}
            isSubmitting={sendTestCreditNote.isLoading}
            trigger={openSendTestCreditNoteDialog => {
              return (
                <SequenceKebabMenu
                  variant="ghost"
                  width={226}
                  alignment="bottom-right"
                  items={[
                    isDraft && {
                      label: 'Send test email',
                      onClick: openSendTestCreditNoteDialog
                    },
                    {
                      label: 'Download credit note PDF',
                      onClick: () =>
                        downloadPdf({
                          creditNoteId,
                          creditNoteNumber,
                          creditNoteStatus
                        }),
                      divider: isDraft
                    },
                    isDraft && {
                      label: 'Void credit note',
                      onClick: openVoidCreditNoteDialog,
                      style: { color: RedRed50 },
                      divider: creditNoteStatus === CREDIT_NOTE_STATUSES.VOIDED
                    }
                  ].filter(Boolean)}
                />
              )
            }}
          />
        )}
      />
    </Flex>
  )
}
