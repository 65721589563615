import { loadProduct } from './entityLoaders/loadProduct'
import { loadAccountSettings } from './entityLoaders/loadAccountSettings'
import { loadLedgerIntegrations } from './entityLoaders/loadLedgerIntegrations'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { loadListPrices } from '../variant.api.v1/entityLoaders'
import {
  PricingEditorPortErrors,
  Product
} from 'common/drawers/PricingEditor/domain'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Dashboardv99990101CommonModels } from '@sequencehq/api/dist/clients/dashboard/v99990101'

type DataErrorResponse<T> =
  | {
      data: T
      error: null
    }
  | {
      data: null
      error: PricingEditorPortErrors
    }

export type v1ApiLoadedCommonData = {
  accountSettings: DataErrorResponse<Dashboardv20240509Api.GetSequenceAccountSettings.SequenceAccount>
  xeroIntegration: DataErrorResponse<DashboardApi20240730.GetIntegration.XeroIntegration>
  listPrices: Dashboardv20240509Api.GetListPrices.ListPricesList['items']
  product: DataErrorResponse<Dashboardv99990101CommonModels.Product.Product> & {
    taxCategoryName?: string
  }
}

export const loadCommonData = async (args: {
  productId: string
  existingProduct?: Product
  disableDefaultProductPorts?: boolean
}): Promise<v1ApiLoadedCommonData> => {
  /**
   * When the product port is disabled, skip hitting endpoints that require a real product ID and set
   * some sensible defaults
   */
  if (args.disableDefaultProductPorts && args.existingProduct) {
    const [accountSettingsResponse, integrationsResponse] = await Promise.all([
      loadAccountSettings(),
      loadLedgerIntegrations()
    ])

    return Promise.resolve({
      accountSettings: accountSettingsResponse,
      xeroIntegration: integrationsResponse,
      listPrices: [],
      product: {
        data: {
          ...args.existingProduct,
          integrationIds: [],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        },
        error: null
      }
    })
  }

  const [
    accountSettingsResponse,
    integrationsResponse,
    listPricesResponse,
    productResponse
  ] = await Promise.all([
    loadAccountSettings(),
    loadLedgerIntegrations(),
    loadListPrices({ productId: args.productId }),
    loadProduct(args.productId)
  ])

  return Promise.resolve({
    accountSettings: accountSettingsResponse,
    xeroIntegration: integrationsResponse,
    listPrices: listPricesResponse,
    product: productResponse
  })
}
