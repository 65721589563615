import { FC, memo } from 'react'
import { OverlayCreateCreditNoteModal } from 'features/overlay/overlay'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CreditNotesView } from './types'
import { menuItemBuilders } from './creditNotesTableConfig'
import {
  MagicTableCell,
  MagicTableCellInvoiceStatus,
  MagicTable,
  useMagicTableInfiniteQuery,
  MagicTableFilterConfig,
  useLinkMagicTableWithSearchParams,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import {
  CreditNoteStatus,
  creditNoteStatus,
  toMoney,
  toCreditNoteStatusBadgeProps
} from '@sequencehq/core-models'
import {
  formatISODate,
  localDateWithFormat,
  pastRelativeToDate
} from '@sequencehq/formatters'
import { Badge, KebabMenu } from '@sequencehq/core-components'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { Flex, Text } from '@chakra-ui/react'
import Page from 'components/Page'
import { useCreditNotePdfDownload } from './useCreditNotePdfDownload'
import { CreditNotesExportToCsv } from './CreditNotesExportToCsv'
import {
  CalendarIcon,
  IdentificationIcon,
  ViewColumnsIcon
} from '@heroicons/react/16/solid'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { CreditNote } from 'CreditNotes/types.ts'
import { formatDateRange } from '@sequencehq/utils/dist/dates'
import ReceiptRefundIcon from '@heroicons/react/24/solid/ReceiptRefundIcon'
import { openOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { CreditNotesSegmentedFilters } from './CreditNotesSegmentedFilters'
import { useNavigate } from 'react-router-dom'
import { useLoadAllCustomersWithAliases } from 'components/UsageEvents/useLoadAllCustomersWithAliases'

const CreditNotes: FC = memo(() => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const flags = useFlags()
  const { downloadPdf } = useCreditNotePdfDownload()

  const { customersWithAliases } = useLoadAllCustomersWithAliases()

  const overlayPayload: OverlayCreateCreditNoteModal = {
    content: 'createCreditNoteModal',
    data: {
      customer: undefined
    }
  }

  const statusFilterOptions = () => {
    return (Object.keys(creditNoteStatus.keys) as CreditNoteStatus[]).map(
      status => ({
        label: toCreditNoteStatusBadgeProps({ status }).children,
        value: status
      })
    )
  }

  const sentFilterOptions = [
    {
      label: '1 week ago',
      value: pastRelativeToDate(new Date(), { weeks: 1 })
    },
    {
      label: '1 month ago',
      value: pastRelativeToDate(new Date(), { months: 1 })
    },
    {
      label: '3 months ago',
      value: pastRelativeToDate(new Date(), { months: 3 })
    },
    {
      label: '6 months ago',
      value: pastRelativeToDate(new Date(), { months: 6 })
    }
  ]

  const filters: MagicTableFilterConfig<CreditNotesView['controlFilters']>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options:
        customersWithAliases?.map(customer => ({
          label: customer.customerName,
          value: customer.id
        })) ?? [],
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'creditNoteStatus',
      options: statusFilterOptions(),
      format: status => (
        <Badge
          {...toCreditNoteStatusBadgeProps({
            status: status as CreditNoteStatus
          })}
        />
      ),
      label: 'Status',
      optionsSortFn: () => 0,
      icon: ViewColumnsIcon
    },
    {
      type: 'date',
      paramName: 'sent',
      paramNameBefore: 'sentBefore',
      paramNameAfter: 'sentAfter',
      optionsBefore: sentFilterOptions,
      optionsAfter: sentFilterOptions,
      format: (_value, label) => label,
      label: 'Sent',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    }
  ]

  const { activeFilters, onChangeActiveFilters, sortBy, onChangeSortBy } =
    useLinkMagicTableWithSearchParams(filters)

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboardv99990101Client.getCreditNotes,
    filters,
    activeFilters,
    sortBy
  )

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Credit Notes"
          addNewConfig={{
            buttonText: 'New credit note',
            overlayPayload
          }}
          paddingBottom={0}
          menuButtons={<CreditNotesExportToCsv />}
        >
          <CurrentUserId>
            {userId => (
              <MagicTable<CreditNotesView>
                activeFilters={activeFilters}
                onChangeActiveFilters={onChangeActiveFilters}
                sortBy={sortBy}
                onChangeSortBy={onChangeSortBy}
                entityNamePlural="credit notes"
                infiniteQuery={infiniteQuery}
                rowPath={(row: CreditNotesView['model']) =>
                  `/credit-notes/${row.id}`
                }
                sequenceUserId={userId}
                columns={[
                  {
                    id: 'grossTotal',
                    header: 'Amount',
                    accessorFn: row => ({
                      currency: row.currency,
                      value: row.grossTotal
                    }),
                    cell: value => (
                      <MagicTableCell
                        text={toMoney(
                          value.getValue<{
                            currency: CreditNote['currency']
                            value: CreditNote['grossTotal']
                          }>()
                        )}
                        textAlign={value.column.columnDef.meta?.textAlign}
                      />
                    ),
                    meta: { textAlign: 'right' }
                  },
                  {
                    id: 'status',
                    header: 'Status',
                    accessorKey: 'status',
                    cell: value => (
                      <MagicTableCellInvoiceStatus
                        invoiceStatus={value.getValue<CreditNote['status']>()}
                      />
                    )
                  },
                  {
                    id: 'creditNoteNumber',
                    header: 'Credit Note #',
                    accessorKey: 'creditNoteNumber',
                    cell: value => (
                      <MagicTableCell
                        text={
                          value.getValue<CreditNote['creditNoteNumber']>() ||
                          'Pending'
                        }
                      />
                    )
                  },
                  {
                    id: 'customer',
                    header: 'Customer',
                    accessorFn: row => ({
                      customerId: row.customerId
                    }),
                    cell: value => {
                      const { customerId } = value.getValue<{
                        customerId: CreditNote['customerId']
                      }>()

                      return (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          height="40px"
                          pl="6px"
                        >
                          <CustomerPreviewCardPill
                            customerId={customerId}
                            variant="ghost"
                          />
                        </Flex>
                      )
                    }
                  },
                  {
                    id: 'billingPeriod',
                    header: 'Billing Period',
                    accessorFn: row => ({
                      billingPeriodStart: row.billingPeriodStart,
                      billingPeriodEnd: row.billingPeriodEnd
                    }),
                    cell: value => {
                      const { billingPeriodStart, billingPeriodEnd } =
                        value.getValue<{
                          billingPeriodStart: CreditNote['billingPeriodStart']
                          billingPeriodEnd: CreditNote['billingPeriodEnd']
                        }>()

                      return (
                        <MagicTableCell
                          text={
                            billingPeriodStart && billingPeriodEnd
                              ? formatDateRange({
                                  from: new Date(billingPeriodStart),
                                  to: new Date(billingPeriodEnd)
                                })
                              : ''
                          }
                        />
                      )
                    }
                  },
                  {
                    id: 'issueDate',
                    header: 'Sent',
                    accessorKey: 'issueDate',
                    cell: value => (
                      <MagicTableCell
                        text={
                          localDateWithFormat(
                            value.getValue<CreditNote['issueDate']>(),
                            'd MMM'
                          ) || ''
                        }
                      />
                    )
                  }
                ]}
                emptyStateUnfiltered={entityNamePlural => (
                  <MagicTableEmptyState
                    entityNamePlural={entityNamePlural}
                    imageSrc="/img/empty/invoices.png"
                    learnMoreText="Use credit notes to make corrections to previously issued invoices"
                    learnMoreHref="https://docs.sequencehq.com/invoicing/credit-notes"
                    buttonNew={{
                      label: 'New credit note',
                      onClick: () => dispatch(openOverlay(overlayPayload))
                    }}
                  />
                )}
                emptyStateFiltered={(entityNamePlural, clearFilters) => (
                  <MagicTableFilterEmptyState
                    entityNamePlural={entityNamePlural}
                    entityIcon={ReceiptRefundIcon}
                    actionClearFilters={clearFilters}
                    actionViewAll={() => navigate('/credit-notes')}
                    variant="TABLE"
                  />
                )}
                kebabMenu={(model: CreditNote, props) => (
                  <KebabMenu
                    data-testid={`creditNotes.${model.id}.menu`}
                    menuItems={menuItemBuilders(downloadPdf)
                      .map(builder => builder(model))
                      .filter(builder => builder.status === 'LIVE')}
                    flags={flags}
                    {...props}
                  />
                )}
                filters={filters}
                additionalToolbarComponentsV3={{
                  start: <CreditNotesSegmentedFilters />
                }}
              />
            )}
          </CurrentUserId>
        </Page>
      </Flex>
    </Flex>
  )
})

export default CreditNotes
