import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/16/solid'
import { RadioButtonGroup } from '@sequencehq/core-components'
import { CustomerModel } from '@sequencehq/core-models'
import { Lato13Bold, Lato14Bold } from '@sequencehq/design-tokens'
import { CreditGrantTable } from './tables/CreditGrantTable'
import { CreditNoteTable } from './tables/CreditNoteTable'
import { InvoiceTable } from './tables/InvoiceTable'
import { openOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import exhaustiveGuard from 'lib/exhaustiveGuard/exhaustiveGuard'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'

type ActiveBillingTable = 'invoices' | 'credit-notes' | 'credit-grants'

export const BillingTables = () => {
  const { customer } = useCustomerContext()
  const dispatch = useDispatch()
  const [activeTable, setActiveTable] = useState<ActiveBillingTable>('invoices')

  const isArchived = Boolean(customer.archivedAt)

  const renderCreateButton = useCallback(() => {
    switch (activeTable) {
      case 'invoices':
        return (
          <Button
            {...Lato13Bold}
            variant="component-library-ghost"
            leftIcon={
              <PlusIcon
                color={isArchived ? undefined : '#5F5CFF'}
                height={16}
                width={16}
              />
            }
            isDisabled={isArchived}
            onClick={() =>
              dispatch(
                openOverlay({
                  content: 'createInvoiceModal',
                  variant: 'v2',
                  data: {
                    customer: customer as unknown as CustomerModel
                  }
                })
              )
            }
          >
            Create invoice
          </Button>
        )
      case 'credit-notes':
        return (
          <Button
            {...Lato13Bold}
            variant="component-library-ghost"
            leftIcon={
              <PlusIcon
                color={isArchived ? undefined : '#5F5CFF'}
                height={16}
                width={16}
              />
            }
            isDisabled={isArchived}
            onClick={() =>
              dispatch(
                openOverlay({
                  content: 'createCreditNoteModal',
                  variant: 'v2',
                  data: {
                    customer: customer as unknown as CustomerModel
                  }
                })
              )
            }
          >
            Create credit note
          </Button>
        )
      case 'credit-grants':
        return (
          <Button
            data-testid={`${customer.id}.credit-grants.create`}
            {...Lato13Bold}
            variant="component-library-ghost"
            leftIcon={
              <PlusIcon
                color={isArchived ? undefined : '#5F5CFF'}
                height={16}
                width={16}
              />
            }
            isDisabled={isArchived}
            {...(!isArchived && {
              as: Link,
              to: `./credit-grant`
            })}
          >
            Create credit grant
          </Button>
        )
      default:
        return exhaustiveGuard(activeTable)
    }
  }, [activeTable, customer, dispatch, isArchived])

  const renderTable = useCallback(() => {
    switch (activeTable) {
      case 'invoices':
        return <InvoiceTable />
      case 'credit-notes':
        return <CreditNoteTable />
      case 'credit-grants':
        return <CreditGrantTable />
      default:
        return exhaustiveGuard(activeTable)
    }
  }, [activeTable])

  return (
    <Box p={4}>
      <Text {...Lato14Bold}>Billing</Text>
      <Flex justifyContent="space-between" alignItems="center" mt={4}>
        <RadioButtonGroup
          data-testid={`${customer.id}`}
          onChange={value => setActiveTable(value as ActiveBillingTable)}
          initialValue={activeTable}
          options={[
            { value: 'invoices', label: 'Invoices' },
            { value: 'credit-notes', label: 'Credit notes' },
            { value: 'credit-grants', label: 'Credit grants' }
          ]}
        />
        {renderCreateButton()}
      </Flex>
      {renderTable()}
    </Box>
  )
}
