import { useCallback } from 'react'

import { pdf } from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'

import { useFetchDataForLocalPdfCreditNote } from 'CreditNotesList/useFetchDataForLocalPdfCreditNote'
import { CreditNoteModel } from '@sequencehq/core-models'
import { ReactPdfCreditNote } from '@sequencehq/invoice-content'
import { isProductionEnv } from 'lib/environment/environment.ts'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const useGeneratePDF = () => {
  const { fetchData } = useFetchDataForLocalPdfCreditNote()

  const generateLocalPDF = useCallback(
    async ({
      id: creditNoteId
    }: Pick<CreditNoteModel, 'id' | 'creditNoteNumber'>) => {
      try {
        const pdfCreditNoteData = await fetchData({ creditNoteId })

        return await pdf(
          <ReactPdfCreditNote
            creditNote={pdfCreditNoteData.creditNote}
            lineItems={pdfCreditNoteData.lineItems}
            lineItemGroups={pdfCreditNoteData.lineItemGroups}
            merchantDetails={pdfCreditNoteData.merchantDetails}
            showNonProdWatermark={!isProductionEnv()}
            isCustomerPortalEnabled={
              pdfCreditNoteData.merchant.customerPortalEnabled
            }
            isRenderedInBrowser={true}
          />
        ).toBlob()
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [fetchData]
  )

  const generateRemotePDF = useCallback(
    async ({
      id: creditNoteId
    }: Pick<CreditNoteModel, 'id' | 'creditNoteNumber'>) => {
      try {
        const creditNotePdfRes = await dashboard20240730Client.getCreditNotePdf(
          {
            id: creditNoteId
          }
        )

        if (!creditNotePdfRes.data) {
          throw new Error('An error occurred while downloading the credit note')
        }
        return creditNotePdfRes.data
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    []
  )

  return {
    generateRemotePDF,
    generateLocalPDF
  }
}
