import { Box, Grid, Img } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { ReactNode } from 'react'
import { Pill, PopoverWrapper, Skeleton } from '@sequencehq/core-components'
import capitalize from 'lodash/fp/capitalize'
import compose from 'lodash/fp/compose'
import replace from 'lodash/fp/replace'
import { Link } from 'react-router-dom'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
import { CustomerPreviewCard } from 'Customer/components/CustomerPreviewCard'
import {
  CalculatorIcon,
  CalendarIcon,
  CubeIcon,
  IdentificationIcon,
  RectangleStackIcon
} from '@heroicons/react/16/solid'
import { getLogoSrc } from 'Customer/utils/logo'
import { formatDateRange } from '@sequencehq/utils/dates'

const RowItem = (props: {
  label: string
  content: ReactNode
  'data-testid'?: string
}) => {
  return (
    <Grid
      gridTemplateColumns={'128px 1fr'}
      width="100%"
      gridColumnGap="10px"
      alignItems="center"
      height="32px"
      {...Lato13Regular}
      data-testid={props['data-testid']}
    >
      <Box color={GreyGrey60}>{props.label}</Box>
      <Box {...Lato13Regular} color={GreyGrey80}>
        {props.content}
      </Box>
    </Grid>
  )
}

type UseDeferredRevenueSchedules = (props: { scheduleId: string }) =>
  | {
      summary: {
        id: string
        customer: {
          id: string
          name: string
          domain?: string
        }
        product: {
          id: string
          name: string
        }
        invoice: {
          id: string
          name: string
        }
        recognitionTerm?: {
          start: Date
          end: Date
        }
        method: Dashboardv99990101Api.GetDeferredRevenueSchedules.DeferredRecognitionMethod
      }
      isLoading: false
    }
  | {
      summary: null
      isLoading: true
    }

/**
 * Data acquisition for the summary
 * @param props
 * @returns
 */
export const useDefRevScheduleSummary: UseDeferredRevenueSchedules = (props: {
  scheduleId: string
}) => {
  const scheduleQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedule,
    { id: props.scheduleId },
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          id: res.id,
          customer: res.customer,
          product: res.product,
          invoice: res.invoice,
          method: res.recognitionMethod,
          recognitionTerm: res.recognitionTerm && {
            start: new Date(res.recognitionTerm.start),
            end: new Date(res.recognitionTerm.endInclusive)
          }
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (scheduleQuery.error) {
    throw new Error('Schedule summary could not be loaded')
  }

  if (scheduleQuery.isPending) {
    return {
      isLoading: true,
      summary: null
    }
  }

  invariant(scheduleQuery.data, 'Summary should be defined, if loaded')

  return {
    isLoading: false,
    summary: scheduleQuery.data
  }
}

export const DefRevScheduleSummary = (props: { defRevScheduleId: string }) => {
  const { isLoading, summary } = useDefRevScheduleSummary({
    scheduleId: props.defRevScheduleId
  })

  return (
    <Box>
      <Box {...Lato13Bold} color={GreyGrey80} marginBottom="8px">
        Summary
      </Box>
      {isLoading ? (
        <Skeleton height="160px" width="100%" />
      ) : (
        <>
          <RowItem
            label="Customer"
            data-testid="revrec.defrevDetail.customer"
            content={
              <PopoverWrapper
                popover={
                  <CustomerPreviewCard customerId={summary.customer.id} />
                }
                offset={[-4, 4]}
                openDelay={850}
              >
                <Link to={`/customers/${summary.customer.id}`}>
                  <Pill
                    icon={
                      summary.customer.domain ? (
                        <Img
                          height="16px"
                          width="16px"
                          src={getLogoSrc({
                            domain: summary.customer.domain
                          })}
                        />
                      ) : (
                        <IdentificationIcon
                          height="16px"
                          width="16px"
                          color={GreyGrey70}
                        />
                      )
                    }
                    interactive
                    variant="ghost"
                  >
                    {summary.customer.name}
                  </Pill>
                </Link>
              </PopoverWrapper>
            }
          />
          <RowItem
            data-testid="revrec.defrevDetail.product"
            label="Product"
            content={
              <Link to={`/products/${summary.product.id}`}>
                <Pill
                  icon={
                    <CubeIcon height="16px" width="16px" color={GreyGrey70} />
                  }
                  interactive
                  variant="ghost"
                >
                  {summary.product.name}
                </Pill>
              </Link>
            }
          />
          <RowItem
            label="Invoice"
            data-testid="revrec.defrevDetail.invoice"
            content={
              <Link to={`/invoices/${summary.invoice.id}`}>
                <Pill
                  icon={
                    <RectangleStackIcon
                      height="16px"
                      width="16px"
                      color={GreyGrey70}
                    />
                  }
                  interactive
                  variant="ghost"
                >
                  {summary.invoice.name}
                </Pill>
              </Link>
            }
          />
          <RowItem
            label="Recognition method"
            data-testid="revrec.defrevDetail.method"
            content={
              <Pill
                icon={
                  <CalculatorIcon
                    height="16px"
                    width="16px"
                    color={GreyGrey70}
                  />
                }
                variant="ghost"
              >
                {compose(capitalize, replace('_', ' '))(summary.method)}
              </Pill>
            }
          />
          {summary.recognitionTerm && (
            <RowItem
              label="Period"
              data-testid="revrec.defrevDetail.recognitionTerm"
              content={
                <Pill
                  icon={
                    <CalendarIcon
                      height="16px"
                      width="16px"
                      color={GreyGrey70}
                    />
                  }
                  variant="ghost"
                >
                  {formatDateRange({
                    from: summary.recognitionTerm.start,
                    to: summary.recognitionTerm.end,
                    formatStr: {
                      month: 'MMMM'
                    }
                  })}
                </Pill>
              }
            />
          )}
        </>
      )}
    </Box>
  )
}
