import {
  GreyGrey50,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { usePhaseActions } from './usePhaseActions'
import { Popover, SwitchLineInput } from '@sequencehq/core-components'
import { Box, Flex, Link } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { ExtractedDataIndicator } from 'ContractExtraction/index'

export const PhaseRecurrenceSwitch = (props: { phaseId: string }) => {
  const phaseActions = usePhaseActions({ phaseId: props.phaseId })

  if (!phaseActions.phaseRecurrencePreference.visible) {
    return null
  }

  return (
    <Flex
      {...Lato13Regular}
      color={GreyGrey90}
      gap="8px"
      alignItems="center"
      height="100%"
    >
      <ExtractedDataIndicator
        fields={[
          `billingSchedule.phases[${props.phaseId}].recurrencePreference`
        ]}
        position="inline"
      >
        <SwitchLineInput
          data-testid="phase.recurrencePreference"
          isDisabled={!phaseActions.phaseRecurrencePreference.available}
          value={phaseActions.phaseRecurrencePreference.value === 'RESET'}
          onChange={newValue =>
            phaseActions.phaseRecurrencePreference.handler(
              newValue ? 'RESET' : 'CONTINUE_FROM_PREVIOUS_PHASE'
            )
          }
          text={'Reset billing period'}
        />
      </ExtractedDataIndicator>
      <Popover
        content={
          <Box>
            <Box {...Lato13Bold} color={GreyGrey90} marginBottom="8px">
              Reset billing period
            </Box>
            <Box {...Lato13Regular} color={GreyGrey90}>
              Enable this to start new billing periods for all prices from here.
              Otherwise, existing billing periods will continue as before.
              <Link
                href="https://docs.sequencehq.com/billing/billing-schedule-phases#reset-billing-periods"
                isExternal
                color={IndigoIndigo50}
                marginLeft="4px"
              >
                Learn more
              </Link>
            </Box>
          </Box>
        }
      >
        <InformationCircleIcon height="16px" width="16px" color={GreyGrey50} />
      </Popover>
    </Flex>
  )
}
