import { useAtom } from 'jotai'

import { PdfViewerAnnotation } from '@sequencehq/pdf-viewer'

import { visiblePreviewFieldPathsAtom } from '../atoms'
import { useCurrentContractMetadata } from './useContractMetadata'
import { fieldsMetadataToAnnotations } from 'ContractExtraction/adapters/fieldsMetadataToAnnotations'
import { useCurrentContract } from './useContract'

type UseContractExtractionAnnotations = ({
  assetId
}: {
  assetId: string
}) => {
  annotations: PdfViewerAnnotation[]
  visibleAnnotationIds: string[] | undefined
}

export const useContractExtractionAnnotations: UseContractExtractionAnnotations =
  ({ assetId }) => {
    const { data: contractData } = useCurrentContract()
    const fieldsMetadata = useCurrentContractMetadata()
    const [visiblePreviewFieldPaths] = useAtom(visiblePreviewFieldPathsAtom)

    const isShowingContractAsset = contractData?.assetId === assetId

    const allAnnotations =
      isShowingContractAsset && fieldsMetadata
        ? fieldsMetadataToAnnotations({
            fieldsMetadata,
            pages: contractData.pages
          })
        : []

    // filter out visible field paths without valid annotations
    const allAnnotationIds = allAnnotations.map(a => a.id)
    const validFieldPathAnnotations = visiblePreviewFieldPaths?.filter(f =>
      allAnnotationIds.includes(f)
    )

    return {
      annotations: allAnnotations,
      visibleAnnotationIds:
        isShowingContractAsset && validFieldPathAnnotations?.length
          ? validFieldPathAnnotations
          : undefined
    }
  }
