import { SequenceMenuItem } from '@sequencehq/core-components'
import { useNavigate } from 'react-router-dom'

export const EditQuoteTemplateMenuItem = (props: {
  quoteTemplateId: string
}) => {
  const navigate = useNavigate()

  return (
    <SequenceMenuItem
      uuid="edit-quote-template"
      data-testid={`quoteTemplates.${props.quoteTemplateId}.menuItem.edit`}
      label="Edit template"
      onClick={() => navigate(`/quote-templates/${props.quoteTemplateId}`)}
    />
  )
}
