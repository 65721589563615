import { Customer } from '../types'

const LOGO_DEV_KEY = import.meta.env.VITE_LOGO_DEV_KEY

const SKIP_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'yahoo.co.uk',
  'yahoo.co.jp',
  'hotmail.com',
  'hotmail.co.uk',
  'outlook.com',
  'live.com',
  'live.co.uk',
  'live.nl',
  'aol.com',
  'icloud.com',
  'me.com',
  'mac.com',
  'msn.com',
  'protonmail.com',
  'zoho.com'
]

export function getLogoSrc(customer: Pick<Customer, 'domain'>) {
  const shouldSkipDomain = SKIP_DOMAINS.includes(customer.domain ?? '')

  if (!customer.domain || shouldSkipDomain) {
    return undefined
  }

  return `https://img.logo.dev/${customer.domain}?token=${LOGO_DEV_KEY}&format=png`
}
