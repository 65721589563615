import {
  Currency,
  DiscountModel,
  toDiscountAmount,
  toMoney,
  toPriceSummary
} from '@sequencehq/core-models'
import {
  Phase,
  Minimum,
  Price,
  DisabledReasonType,
  ValidationResult,
  BillingFrequency,
  PhasePriceMetadata
} from 'modules/Cube/domain/cube.domain.types'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { NEW_PRICE_PATTERN } from 'modules/Cube/domain/cube.constants'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import deepmerge from 'deepmerge'
import { calculateDiscountedPrice, priceModelAdapter } from '@sequencehq/utils'
import { getMonthBillingStartedForAnnualFreq } from './getMonthBillingStartedForAnnualFreq'

type AvailableFeature<T = void> = {
  handler: (arg: T) => void
  disabled: boolean
  disabledReason?: string
  hidden?: boolean
}

export type PricingRow = {
  id: string
  name: string
  isNewPrice?: boolean
  billingFrequency?: BillingFrequency
  recurrenceDayOfMonth?: number
  monthRecurrenceStarted?: number
  billingType: 'IN_ADVANCE' | 'IN_ARREARS' | null
  discount?: {
    id: DiscountModel['id']
    label: string
  }
  price: string
  preDiscountPrice?: string
  phasePriceMetadata?: PhasePriceMetadata
  availableFeatures: Partial<{
    canEdit: AvailableFeature
    canEditPriceMetadata: AvailableFeature<Omit<PhasePriceMetadata, 'priceId'>>
    canDelete: AvailableFeature
    canEditDiscount: AvailableFeature
    canDeleteDiscount: AvailableFeature
  }>
  onClick: () => void
  isGlobalDiscountRow: boolean
}

export type MinimumRow = {
  id: Minimum['id']
  name: string
  billingFrequency: BillingFrequency
  recurrenceDayOfMonth?: number
  value: string
  actions: {
    edit: {
      handler: () => void
      available: boolean
    }
    delete: {
      handler: () => void
      available: boolean
    }
  }
}

export type PricingGroup = {
  minimumRow?: MinimumRow
  pricingRows: PricingRow[]
}

type UsePricingTable = (props: {
  phaseId: Phase['id']
}) => {
  showArrCalculation: boolean
  pricingGroups: PricingGroup[]
  validationErrors: ValidationResult[]
}

export const getPriceGroups =
  (activeVersionMinimums: Minimum[]) =>
  (
    activeVersionPrices: Price[]
  ): {
    minimum?: Minimum
    prices: Price[]
  }[] =>
    Object.values(
      activeVersionPrices.reduce((acc, price) => {
        if (!('usageMetricId' in price.structure)) {
          return deepmerge(acc, {
            'no-minimum': {
              prices: [price]
            }
          })
        }

        const matchingMinimum = activeVersionMinimums.find(
          minimum =>
            minimum.scope.target === 'allUsage' ||
            minimum.scope.priceIds.includes(price.id)
        )

        if (!matchingMinimum) {
          return deepmerge(acc, {
            'no-minimum': {
              prices: [price]
            }
          })
        }

        return deepmerge(acc, {
          [matchingMinimum.id]: {
            minimum: matchingMinimum,
            prices: [price]
          }
        })
      }, {})
    )

const EDIT_PRICE_DISABLED_REASONS: Partial<Record<DisabledReasonType, string>> =
  {
    [DisabledReasonType.TieredPriceTransition]:
      'Change the start and/or end date of this phase to be able to edit this tiered price',
    [DisabledReasonType.CompletedSchedule]:
      "Cannot edit a completed schedule's prices",
    [DisabledReasonType.CompletedPhase]:
      "Cannot edit a completed phase's prices"
  }

const EDIT_DISCOUNT_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.PhaseEndTransition]:
    'Change the start and/or end date of this phase to be able to edit this discount',
  [DisabledReasonType.PhaseStartTransition]:
    'Change the start and/or end date of this phase to be able to edit this discount',
  [DisabledReasonType.CompletedSchedule]:
    "Cannot edit a completed schedule's discounts",
  [DisabledReasonType.CompletedPhase]:
    "Cannot edit a completed phase's discounts"
}

export const usePricingTable: UsePricingTable = props => {
  const cubeContext = useCubeContext()
  const navigate = useNavigate()

  const resolvedPhase = useMemo(() => {
    return cubeContext.queries.resolvedPhases[props.phaseId]
  }, [props.phaseId, cubeContext.queries.resolvedPhases])

  const activeVersionPrices: Price[] = useMemo(() => {
    if (!resolvedPhase) {
      return []
    }
    return cubeContext.queries.resolvedPhases[resolvedPhase.id]?.prices
  }, [resolvedPhase, cubeContext.queries.resolvedPhases])

  const activeVersionMinimums: Minimum[] = useMemo(() => {
    if (!resolvedPhase) {
      return []
    }
    return cubeContext.queries.resolvedPhases[resolvedPhase.id]?.minimums
  }, [cubeContext, resolvedPhase])

  const globalDiscountForPhase =
    cubeContext.queries.resolvedPhases[resolvedPhase.id].globalDiscount

  const pricingGroups = useMemo(() => {
    if (!resolvedPhase) {
      return []
    }

    const priceGroups = getPriceGroups(activeVersionMinimums)(
      activeVersionPrices
    )

    const enhancedPriceGroups: PricingGroup[] = priceGroups.map(group => {
      const enhancedMinimumRow = group.minimum
        ? {
            id: group.minimum.id,
            name: group.minimum.name || 'Minimum commitment',
            billingFrequency: group.prices[0]?.billingFrequency,
            recurrenceDayOfMonth: resolvedPhase.recurrenceDayOfMonth,
            value: toMoney({
              value: group.minimum.value,
              currency: group.prices[0]?.currency
            }),
            actions: {
              edit: {
                handler: () =>
                  navigate(
                    `./minimum-editor/${props.phaseId}/${group.minimum?.id}`
                  ),
                available:
                  cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
                    .phase.minimum.edit.available.enabled
              },
              delete: {
                handler: () =>
                  group.minimum?.id &&
                  cubeContext.mutators.deleteMinimum(resolvedPhase.id)(
                    group.minimum.id
                  ),
                available:
                  cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
                    .phase.minimum.delete.available.enabled
              }
            }
          }
        : undefined

      const enhancedPricesRows = group.prices
        .map((price): PricingRow | null => {
          const discountForPrice = cubeContext.queries.resolvedPhases[
            resolvedPhase.id
          ].discounts.find(d => d.priceIds.includes(price.id))
          const globalDiscountActive = globalDiscountForPhase

          const availableFeaturesForThisProduct =
            cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
              ?.products[price.productId]

          if (!availableFeaturesForThisProduct) {
            return null
          }

          const availableProductFeatures =
            cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
              .products[price.productId]

          const discountAppliedToThisProduct =
            globalDiscountForPhase || discountForPrice

          const preDiscountPrice = toPriceSummary(priceModelAdapter.in(price))

          const processedPrice =
            calculateDiscountedPrice({
              price: priceModelAdapter.in(price),
              discount: discountAppliedToThisProduct
            }) ?? preDiscountPrice

          const phasePriceMetadata = resolvedPhase.phasePriceMetadata.find(
            metadata => metadata.priceId === price.id
          )

          return {
            id: price.id,
            name: `${price.name}`,
            isNewPrice: Boolean(price.id.match(NEW_PRICE_PATTERN)),
            billingFrequency: price.billingFrequency,
            recurrenceDayOfMonth: resolvedPhase.recurrenceDayOfMonth,
            monthRecurrenceStarted: getMonthBillingStartedForAnnualFreq({
              phases: cubeContext.queries.orderedPhases,
              phaseId: resolvedPhase.id
            }),
            billingType: price.billingType,
            discount:
              discountForPrice && !globalDiscountActive
                ? {
                    id: discountForPrice.id,
                    label: toDiscountAmount(
                      discountForPrice.discountCalculationType,
                      discountForPrice.amount,
                      cubeContext.queries.selectedCurrency as Currency
                    )
                  }
                : undefined,
            preDiscountPrice,
            price: processedPrice,
            onClick: () =>
              navigate(`./price-editor/${resolvedPhase.id}/${price.productId}`),
            phasePriceMetadata,
            availableFeatures: {
              canEdit: {
                disabled: !availableProductFeatures?.edit.available.enabled,
                disabledReason:
                  EDIT_PRICE_DISABLED_REASONS[
                    availableProductFeatures?.edit.reasons[0]?.reasonType
                  ] ?? '',
                handler: () =>
                  navigate(
                    `./price-editor/${resolvedPhase.id}/${price.productId}`
                  )
              },
              canEditPriceMetadata: {
                disabled:
                  !availableProductFeatures?.editPhasePriceMetadata.available
                    .enabled,
                hidden:
                  !availableProductFeatures?.editPhasePriceMetadata.available
                    .visible,
                handler: (
                  newPriceMetadata: Omit<PhasePriceMetadata, 'priceId'>
                ) => {
                  cubeContext.mutators.updateData({
                    phases: {
                      [resolvedPhase.id]: {
                        phasePriceMetadata: phasePriceMetadata
                          ? resolvedPhase.phasePriceMetadata.map(metadata =>
                              metadata.priceId === price.id
                                ? {
                                    ...metadata,
                                    ...newPriceMetadata
                                  }
                                : metadata
                            )
                          : [
                              ...resolvedPhase.phasePriceMetadata,
                              { priceId: price.id, ...newPriceMetadata }
                            ]
                      }
                    }
                  })
                }
              },
              canDelete: {
                disabled: !availableProductFeatures?.delete.available.enabled,
                handler: () =>
                  cubeContext.mutators.deletePrice(resolvedPhase.id)(price.id)
              },
              ...(discountForPrice
                ? {
                    canEditDiscount: {
                      disabled:
                        !availableProductFeatures?.editDiscount.available
                          .enabled,
                      disabledReason:
                        EDIT_DISCOUNT_DISABLED_REASONS[
                          availableProductFeatures?.editDiscount.reasons[0]
                            ?.reasonType
                        ] ?? '',
                      handler: () => {
                        navigate(
                          `./discount-editor/${props.phaseId}/${discountForPrice?.id}`
                        )
                      }
                    },
                    canDeleteDiscount: {
                      disabled:
                        !availableProductFeatures?.deleteDiscount.available,
                      disabledReason:
                        EDIT_DISCOUNT_DISABLED_REASONS[
                          availableProductFeatures?.deleteDiscount.reasons[0]
                            ?.reasonType
                        ] ?? '',
                      handler: () => {
                        cubeContext.mutators.deleteDiscount(
                          discountForPrice.id
                        )(price.id)
                      }
                    }
                  }
                : {})
            },
            isGlobalDiscountRow: false
          }
        })
        .filter((row): row is PricingRow => row !== null)
        .sort((a, b) => (a.name > b.name ? 1 : -1))

      return {
        minimumRow: enhancedMinimumRow,
        pricingRows: enhancedPricesRows
      }
    })

    const globalDiscountRow: PricingGroup[] = globalDiscountForPhase
      ? [
          {
            pricingRows: [
              {
                id: globalDiscountForPhase.id,
                name: 'Global discount',
                billingType: null,
                discount: {
                  id: globalDiscountForPhase.id,
                  label: toDiscountAmount(
                    globalDiscountForPhase.discountCalculationType,
                    globalDiscountForPhase.amount,
                    (cubeContext.queries.selectedCurrency as Currency) ??
                      cubeContext.queries.rawData.configuration.currency.default
                  )
                },
                availableFeatures: {
                  canEditDiscount: {
                    disabled:
                      !cubeContext.queries.availableFeatures.phases[
                        resolvedPhase.id
                      ].phase.discount.edit.available.enabled,
                    disabledReason:
                      EDIT_DISCOUNT_DISABLED_REASONS[
                        cubeContext.queries.availableFeatures.phases[
                          resolvedPhase.id
                        ].phase.discount.edit?.reasons[0]?.reasonType
                      ] ?? '',
                    handler: () =>
                      navigate(
                        `./discount-editor/${props.phaseId}/${globalDiscountForPhase.id}`
                      )
                  },
                  canDeleteDiscount: {
                    disabled:
                      !cubeContext.queries.availableFeatures.phases[
                        resolvedPhase.id
                      ].phase.discount.delete.available.enabled,
                    disabledReason:
                      EDIT_DISCOUNT_DISABLED_REASONS[
                        cubeContext.queries.availableFeatures.phases[
                          resolvedPhase.id
                        ].phase.discount.delete?.reasons[0]?.reasonType
                      ] ?? '',
                    handler: () =>
                      cubeContext.mutators.deleteDiscount(
                        globalDiscountForPhase.id
                      )()
                  }
                },
                price: '',
                onClick: () =>
                  navigate(
                    `./discount-editor/${props.phaseId}/${globalDiscountForPhase.id}`
                  ),
                isGlobalDiscountRow: true
              }
            ]
          }
        ]
      : []

    return [...enhancedPriceGroups, ...globalDiscountRow]
  }, [
    resolvedPhase,
    activeVersionMinimums,
    navigate,
    cubeContext.queries,
    activeVersionPrices,
    cubeContext.mutators,
    props.phaseId,
    globalDiscountForPhase
  ])

  const validationErrors = useMemo(() => {
    return [
      ...(cubeContext.queries.validation.activeValidationResults?.common
        .phases?.[props.phaseId]?.prices ?? [])
    ]
  }, [cubeContext.queries.validation.activeValidationResults, props.phaseId])

  return {
    pricingGroups: pricingGroups,
    validationErrors,
    showArrCalculation:
      cubeContext.queries.rawData.configuration.features
        .phasePriceMetadataEditing
  }
}
