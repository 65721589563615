import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import {
  GreyGrey10,
  GreyGrey20,
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo10,
  IndigoIndigo50,
  Lato12Bold,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useEffect, useState } from 'react'
import { useJournalReportDetail } from './useJournalReportDetail'
import { useSelectedDates } from './selectorHooks'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { Link } from 'react-router-dom'
import { JournalDetailDrawer } from './JournalDetailDrawer/JournalDetailDrawer'
import capitalize from 'lodash/fp/capitalize'
import compose from 'lodash/fp/compose'
import replace from 'lodash/fp/replace'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { Skeleton } from '@sequencehq/core-components'
import { toMoney } from '@sequencehq/utils'

const cellBorderStyle = {
  _notLast: { borderRight: `1px solid ${GreyGrey30}` }
}
const pulseAnimation = (active?: boolean) => ({
  transition: 'background-color 0.35s ease-out',
  backgroundColor: active ? `${IndigoIndigo10} !important` : 'transparent'
})

const HeaderRow = (props: { highlightColumns?: boolean }) => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr boxShadow={`0 1px 0 ${GreyGrey30}`} borderBottom="none !important">
    <Th
      width="100%"
      {...cellBorderStyle}
      {...pulseAnimation(props.highlightColumns)}
    >
      Customer
    </Th>
    <Th
      width="100%"
      {...cellBorderStyle}
      {...pulseAnimation(props.highlightColumns)}
    >
      Product
    </Th>
    <Th
      width="100%"
      {...cellBorderStyle}
      {...pulseAnimation(props.highlightColumns)}
    >
      Invoice
    </Th>
    <Th
      width="100%"
      {...cellBorderStyle}
      {...pulseAnimation(props.highlightColumns)}
    >
      Type
    </Th>
    <Th width="100%" {...cellBorderStyle}>
      Ledger account
    </Th>
    <Th width="153px" {...cellBorderStyle}>
      Debit
    </Th>
    <Th width="153px">Credit</Th>
  </Tr>
)

const TableRowGroup = (props: {
  onClick: () => void
  currency: Currency
  journalType?: 'MANUAL' | 'SYSTEM'
  customer?: {
    id: string
    name: string
  }
  product?: {
    id: string
    name: string
  }
  invoice?: {
    id: string
    name: string
  }
  ledgerAccounts: Array<{
    name: string
    debit?: number
    credit?: number
  }>
  highlightColumns?: boolean
  'data-testid'?: string
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const totalRows = props.ledgerAccounts.length

  return props.ledgerAccounts.map((ledgerAccount, index) => (
    <Tr
      onClick={props.onClick}
      key={index}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered ? GreyGrey10 : 'transparent'
      }}
      data-testid={index === 0 && props['data-testid']}
    >
      {index === 0 ? (
        <>
          <Td
            width="100%"
            {...cellBorderStyle}
            rowSpan={totalRows}
            verticalAlign="top"
            paddingLeft="4px !important"
            paddingTop="6px !important"
            {...pulseAnimation(props.highlightColumns)}
          >
            {props.customer && (
              <CustomerPreviewCardPill
                customerId={props.customer.id}
                variant="ghost"
              />
            )}
          </Td>
          <Td
            width="153px"
            {...cellBorderStyle}
            rowSpan={totalRows}
            verticalAlign="top"
            {...pulseAnimation(props.highlightColumns)}
          >
            {props.product && (
              <Link
                to={`/products/${props.product.id}`}
                style={{
                  color: IndigoIndigo50,
                  ...Lato13Regular
                }}
              >
                {props.product.name}
              </Link>
            )}
          </Td>
          <Td
            width="153px"
            {...cellBorderStyle}
            rowSpan={totalRows}
            verticalAlign="top"
            {...pulseAnimation(props.highlightColumns)}
          >
            {props.invoice && (
              <Link
                to={`/invoices/${props.invoice.id}`}
                style={{
                  color: IndigoIndigo50,
                  ...Lato13Regular
                }}
              >
                {props.invoice.name}
              </Link>
            )}
          </Td>
          <Td
            width="153px"
            {...cellBorderStyle}
            rowSpan={totalRows}
            verticalAlign="top"
            {...pulseAnimation(props.highlightColumns)}
          >
            {props.journalType
              ? {
                  MANUAL: 'Adjustment journal',
                  SYSTEM: 'Core journal'
                }[props.journalType]
              : ''}
          </Td>
        </>
      ) : null}
      <Td width="100%" {...cellBorderStyle}>
        <Flex
          height="24px"
          alignItems="center"
          borderRadius="6px"
          backgroundColor={GreyGrey20}
          padding="0 6px"
          {...Lato12Bold}
          color={GreyGrey70}
          width="fit-content"
        >
          {compose(replace('_', ' '), capitalize)(ledgerAccount.name)}
        </Flex>
      </Td>
      <Td width="153px" color={GreyGrey90} {...cellBorderStyle}>
        {ledgerAccount.debit
          ? toMoney({
              currency: props.currency,
              value: ledgerAccount.debit.toString()
            })
          : null}
      </Td>
      <Td width="153px" color={GreyGrey90}>
        {ledgerAccount.credit
          ? toMoney({
              currency: props.currency,
              value: ledgerAccount.credit.toFixed()
            })
          : null}
      </Td>
    </Tr>
  ))
}

const TotalRow = (totals: {
  currency: Currency
  debit: number
  credit: number
  totalRecords: number
}) => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr boxShadow={`0 -1px 0 ${GreyGrey30}`} borderTop="none !important">
    <Td
      width="100%"
      {...Lato13Bold}
      colSpan={5}
      {...cellBorderStyle}
      color={GreyGrey90}
      cursor="default"
      data-testid="revrec.detail.expandedTable.totalRow"
    >
      {totals.totalRecords !== 1
        ? `${totals.totalRecords} records`
        : '1 record'}
    </Td>
    <Td width="153px" {...cellBorderStyle} cursor="default">
      <Text {...Lato13Bold} color={GreyGrey90}>
        {toMoney({ currency: totals.currency, value: totals.debit.toString() })}
      </Text>
    </Td>
    <Td width="153px" {...Lato13Bold} color={GreyGrey90} cursor="default">
      <Text {...Lato13Bold} color={GreyGrey90}>
        {toMoney({
          currency: totals.currency,
          value: totals.credit.toString()
        })}
      </Text>
    </Td>
  </Tr>
)

const PlaceholderTotalRow = () => (
  <Tr>
    <Td
      width="100%"
      colSpan={5}
      {...Lato13Bold}
      {...cellBorderStyle}
      color={GreyGrey90}
      cursor="default"
    >
      <Skeleton />
    </Td>
    <Td width="153px" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
    <Td width="153px" {...Lato13Bold} cursor="default">
      <Skeleton />
    </Td>
  </Tr>
)

const PlaceholderRowGroup = () => (
  <>
    <Tr>
      <Td width="100%" rowSpan={3} {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="153px" rowSpan={3} {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="100%" rowSpan={3} {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="100%" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="153px" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="100%" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="153px" {...cellBorderStyle}>
        <Skeleton />
      </Td>
    </Tr>
    <Tr>
      <Td width="100%" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="100%" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="153px" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="153px" color={GreyGrey90}>
        <Skeleton />
      </Td>
    </Tr>
    <Tr>
      <Td width="100%" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="100%" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="153px" {...cellBorderStyle}>
        <Skeleton />
      </Td>
      <Td width="153px" color={GreyGrey90}>
        <Skeleton />
      </Td>
    </Tr>
  </>
)

export const JournalReportExpandedTable = (props: {
  pulseExpandedColumnsOnMount?: boolean
}) => {
  const { currency } = useSelectedCurrency()
  const dates = useSelectedDates()
  const { detail, isLoading } = useJournalReportDetail({
    currency,
    dates
  })

  /**
   * This allows us to have a 'pulse' on the 'new' columns for this
   * expanded table when mounting. We control this in the parent
   * because there are some scenarios in which we don't want to pulse,
   * such if we load straight into an expanded view.
   */
  const [highlightColumns, setHighlightColumns] = useState<boolean>(
    props.pulseExpandedColumnsOnMount ?? false
  )
  useEffect(() => {
    if (props.pulseExpandedColumnsOnMount && highlightColumns) {
      setHighlightColumns(false)
    }
  }, [highlightColumns, props.pulseExpandedColumnsOnMount])

  return (
    <TableContainer
      data-testid="revrec.detail.expandedTable"
      overflowX="auto"
      overflowY="auto"
      /**
       * We need to set a max height, rather than height, to prevent the
       * footer extending all of the way down the page when the table is
       * short!
       */
      maxHeight="100%"
      borderRadius="lg"
      border={`1px solid ${GreyGrey30}`}
    >
      <Table variant="v2" width="100%">
        <Thead position="sticky" insetBlockStart={0}>
          <HeaderRow highlightColumns={highlightColumns} />
        </Thead>
        <Tbody>
          {isLoading ? (
            <>
              <PlaceholderRowGroup />
              <PlaceholderRowGroup />
              <PlaceholderRowGroup />
            </>
          ) : (
            detail.groups.map(group => (
              <JournalDetailDrawer
                key={group.journalId}
                journalId={group.journalId}
                trigger={open => (
                  <TableRowGroup
                    data-testid={`revrec.detail.expandedTable.${group.journalId}`}
                    onClick={open}
                    currency={detail.currency}
                    customer={group.customer}
                    invoice={group.invoice}
                    product={group.product}
                    journalType={group.journalType}
                    ledgerAccounts={group.ledgerAccounts}
                    highlightColumns={highlightColumns}
                  />
                )}
              />
            ))
          )}
        </Tbody>
        <Tfoot position="sticky" insetBlockEnd={0}>
          {isLoading ? (
            <PlaceholderTotalRow />
          ) : (
            <TotalRow
              currency={detail.currency}
              debit={detail.totals.debit}
              credit={detail.totals.credit}
              totalRecords={detail.groups.length}
            />
          )}
        </Tfoot>
      </Table>
    </TableContainer>
  )
}
