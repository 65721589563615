import { Box, Flex } from '@chakra-ui/react'
import { MutationFormProps } from 'components/Form/types'
import { RecalculateInvoiceFormProps } from 'components/RecalculateInvoice/types'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

type FormValues = {
  value: string
}

const RecalculateInvoiceForm: FC<
  MutationFormProps & RecalculateInvoiceFormProps
> = ({ id, formId, submitForm }) => {
  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      onSubmit={async () => {
        await submitForm({
          id
        })
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            <Box px={10}>
              Recalculating the invoice will update line items based on the
              latest usage data.
            </Box>
          </Flex>
        )
      }}
    />
  )
}

export default RecalculateInvoiceForm
