import * as entityLoaders from './entityLoaders'
import * as commonEntityLoaders from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders'
import { useCallback } from 'react'
import { useNotifications } from 'lib/hooks/useNotifications'
import * as Sentry from '@sentry/react'
import { ApiData } from '../adapters/quoteTemplate.adapters.in'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  CorePortErrors,
  CubePortImplementationProp
} from 'modules/Cube/domain/cube.domain.types.ts'

type UseLoadQuoteTemplateEditor = () => (
  ctx: CubePortImplementationProp
) => (
  quoteTemplateId?: string
) => Promise<{ data: ApiData | null; error: CorePortErrors | null }>

export const useLoadQuoteTemplateEditor: UseLoadQuoteTemplateEditor = () => {
  const { displayNotification } = useNotifications()
  const quoteTemplateLoader = entityLoaders.useLoadQuoteTemplate()
  const productsLoader = commonEntityLoaders.useLoadProducts()
  const listPricesLoader = commonEntityLoaders.useLoadListPrices()
  const allProductsLoader = commonEntityLoaders.useLoadAllProducts()
  const enabledCurrenciesLoader = commonEntityLoaders.useLoadEnabledCurrencies()
  const userLoader = entityLoaders.useLoadUser()

  const loadQuoteTemplate = useCallback(
    () =>
      async (
        quoteTemplateId?: string
      ): Promise<{
        data: Pick<ApiData, 'quoteTemplate' | 'presentation'> | null
        error: CorePortErrors | null
      }> => {
        if (!quoteTemplateId) {
          return {
            data: null,
            error: CorePortErrors.Other
          }
        }

        const loadedQuoteTemplate = await quoteTemplateLoader(quoteTemplateId)

        if (!loadedQuoteTemplate.data || loadedQuoteTemplate.error) {
          return {
            data: null,
            error: loadedQuoteTemplate.error || CorePortErrors.Other
          }
        }

        return {
          data: {
            quoteTemplate: loadedQuoteTemplate.data,
            presentation: loadedQuoteTemplate.data.template.presentation
          },
          error: null
        }
      },
    [quoteTemplateLoader]
  )

  const load = useCallback(
    () => async (quoteTemplateId?: string) => {
      try {
        const loadedQuoteTemplate = await loadQuoteTemplate()(quoteTemplateId)

        if (!loadedQuoteTemplate.data || loadedQuoteTemplate.error) {
          return {
            data: null,
            error: loadedQuoteTemplate.error || CorePortErrors.Other
          }
        }

        const { quoteTemplate, presentation } = loadedQuoteTemplate.data

        const [
          listPricesLoaderData,
          allProductsLoaderData,
          enabledCurrenciesData,
          createdByUser
        ] = await Promise.all([
          listPricesLoader(),
          allProductsLoader(),
          enabledCurrenciesLoader(),
          userLoader(quoteTemplate.template.createdBy)
        ])

        const pricesData =
          quoteTemplate.phases
            ?.flatMap(({ prices }) => prices)
            .map(price => ({
              ...price,
              currency: quoteTemplate.template.currency as Currency
            })) ?? []
        const productsLoaderData = await productsLoader({
          prices: pricesData
        })

        return {
          data: {
            quoteTemplate,
            presentation,
            prices: pricesData,
            listPrices: listPricesLoaderData,
            products: [
              ...productsLoaderData.products,
              ...allProductsLoaderData
            ],
            currencies: enabledCurrenciesData,
            merchantBranding: null,
            createdByUser,
            /**
             * These collections are always empty for templates.
             */
            customers: [],
            integrations: [],
            contacts: []
          },
          error: null
        }
      } catch (e) {
        displayNotification("Couldn't load quote template", {
          type: 'error'
        })

        Sentry.captureException(e)

        return {
          data: null,
          error: CorePortErrors.Other
        }
      }
    },
    [
      allProductsLoader,
      enabledCurrenciesLoader,
      displayNotification,
      listPricesLoader,
      loadQuoteTemplate,
      productsLoader,
      userLoader
    ]
  )

  return load
}
