import { useMutation } from '@sequencehq/api'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { contractIdAtom } from 'ContractExtraction/atoms'
import { FieldMetadata, FieldMetadataStatus } from 'ContractExtraction/types'
import { useAtom } from 'jotai'
import { match } from 'ts-pattern'
import { useCurrentContractMetadata } from './useContractMetadata'
import { useCallback } from 'react'
import { isMatchedFieldPath } from 'ContractExtraction/utils/isMatchedFieldPath'

export const useMarkExtractedFieldsAsAccepted = () => {
  const [contractId] = useAtom(contractIdAtom)
  const fieldsMetadata = useCurrentContractMetadata()

  const mutation = useMutation(dashboardv99990101Client.patchContract)

  return useCallback(
    (fieldPaths: string[]) => {
      if (!fieldsMetadata) {
        return
      }

      mutation.mutate({
        id: contractId ?? '',
        body: {
          fieldsMetadata: transformFieldsToAccepted(
            fieldsMetadata ?? [],
            fieldPaths
          )
        }
      })
    },
    [contractId, fieldsMetadata, mutation]
  )
}

export function transformFieldsToAccepted(
  fieldMetadata: FieldMetadata[],
  fieldPaths: string[]
) {
  return fieldMetadata.map(field => {
    if (
      !fieldPaths.some(pattern =>
        isMatchedFieldPath({ fieldPath: field.fieldPath, pattern })
      )
    ) {
      return field
    }

    return {
      ...field,
      status: match<FieldMetadataStatus, FieldMetadataStatus>(field.status)
        .with('EXISTING_UNAPPROVED', () => 'EXISTING_APPROVED')
        .with('NEW_UNAPPROVED', () => 'NEW_APPROVED')
        .otherwise(() => field.status)
    }
  })
}
