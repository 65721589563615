import { Grid, GridItem } from '@chakra-ui/react'
import { BillingScheduleHomeInspector } from './BillingScheduleHomeInspector'
import { ScheduleDetailsSection } from './sections/ScheduleDetails'
import { InvoicesSection } from './sections/Invoices'
import { PricingSection } from './sections/Pricing'
import { ScheduleTimeline } from './timeline/ScheduleTimeline'

export const BillingScheduleHomeContent = () => {
  return (
    <Grid
      templateColumns="1fr auto"
      templateAreas={`"main sidebar"`}
      gridArea="content"
      height="100%"
      overflow="hidden"
    >
      <GridItem gridArea="main" width="100%" height="100%" overflow="hidden">
        <Grid
          templateAreas={`
              "sections"
              "timeline"
        `}
          templateRows={`1fr auto`}
          justifyItems="center"
          height="100%"
          overflow="hidden"
        >
          <Grid
            overflow="auto"
            gridArea="sections"
            templateColumns={`1fr 1024px 1fr`}
            templateAreas={`
              ". content ."
            `}
            height="100%"
            width="100%"
          >
            <GridItem
              padding="16px"
              width="1024px"
              display="flex"
              flexDirection="column"
              gap="20px"
              gridArea="content"
            >
              <ScheduleDetailsSection />
              <PricingSection />
              <InvoicesSection />
            </GridItem>
          </Grid>
          <GridItem gridArea="timeline" width="100%">
            <ScheduleTimeline />
          </GridItem>
        </Grid>
      </GridItem>

      <GridItem gridArea="sidebar" zIndex="10">
        <BillingScheduleHomeInspector />
      </GridItem>
    </Grid>
  )
}
